import { PublishManifestDoc, TaskingState } from "../models/PublishManifest";
import { Fetch } from "../types";

export interface BundleClient {
  get(manifest: PublishManifestDoc): Promise<string>;
  upsert(manifestID: string, ts: TaskingState): Promise<URL>;
  delete(manifest: PublishManifestDoc): Promise<void>;
}

const cloudfrontDomain = "https://d1lw12zfwta4ws.cloudfront.net";

function bundleURL(manifestID: string, hash: string) {
  return `${cloudfrontDomain}/bundles/${manifestID}/${hash}.json`;
}

export class S3BundleClient implements BundleClient {
  fetch: Fetch;

  constructor(f: Fetch) {
    if (typeof window !== "undefined") {
      this.fetch = f.bind(window);
    } else {
      this.fetch = f;
    }
  }

  private async hash(str: string) {
    const encoder = new TextEncoder();
    const buf = encoder.encode(str);
    const bytes = await crypto.subtle.digest("SHA-1", buf);

    const hashArray = Array.from(new Uint8Array(bytes));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  }

  async get(manifest: PublishManifestDoc): Promise<string> {
    if (!manifest?.bundleAddress) {
      return Promise.resolve(null);
    }

    let res: Response;
    try {
      res = await this.fetch(manifest.bundleAddress);
    } catch (e) {
      return Promise.resolve(null);
    }

    if (res) {
      return res.text();
    }

    return Promise.resolve(null);
  }

  async upsert(manifestID: string, ts: TaskingState) {
    if (!manifestID) {
      throw new Error("Manifest ID is required");
    }
    const serialized = JSON.stringify(ts);

    const contentHash = await this.hash(serialized);

    const { uploadURL } = await this.fetch(
      "/.netlify/functions/bundle_upload_url",
      {
        method: "POST",
        body: JSON.stringify({ manifestID, contentHash }),
      }
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`Failed to get upload URL: ${res.statusText}`);
      }

      return res.json();
    });

    try {
      await this.fetch(uploadURL, {
        method: "PUT",
        body: serialized,
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(`Failed to upload bundle: ${e}`);
    }

    const addr = bundleURL(manifestID, contentHash);
    const u = new URL(addr);

    return u;
  }

  delete(): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
