import { Table } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { DCSGroupData } from "../lib/types";

type Props = {
  className?: string;
  group: DCSGroupData;
};

const LoadoutContainer = styled.div`
  padding-bottom: 36px;
`;

const InfoContainer = styled.div`
  padding-bottom: 8px;
`;

function Loadouts({ group, className }: Props) {
  const maxPylons = _.max(_.map(group.units, (u) => u.payload.length));
  return (
    <div className={className}>
      {_.map(group.units, (u, i) => (
        <LoadoutContainer key={u.name}>
          <InfoContainer>
            {group.name} - {i + 1}
          </InfoContainer>
          <Table>
            <thead>
              <tr>
                {_.times(maxPylons, (i) => (
                  <td align="center" key={i}>
                    {i + 1}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {_.map(u.payload, (weapon, i) => (
                  <td align="center" key={i}>
                    {weapon}
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </LoadoutContainer>
      ))}
    </div>
  );
}

export default styled(Loadouts)`
  td {
    font-size: 12px;
  }
`;
