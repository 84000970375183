import { Box, Tab, Tabs, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useListKneeboards } from "../../contexts/KneeboardManagerContext";
import { useTaskingState } from "../../contexts/TaskingMapContext";
import { ASSET_VIEWER_PARAM } from "../../lib/types";
import Flex from "../Flex";
import AssetViewer from "./AssetViewer";

type Props = {
  className?: string;
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ width: "100%", marginLeft: 8, marginTop: 8 }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Flex wide center>
          {children}
        </Flex>
      )}
    </div>
  );
}

const StyledTab = styled(Tab)`
  &.MuiButtonBase-root {
    white-space: nowrap;
    justify-content: center;
    align-items: flex-start;
  }

  .tab-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

function AssetPreview({ className }: Props) {
  const {
    state: {
      manifest,
      assetViewer: { open },
    },
  } = useTaskingState();

  const history = useHistory();
  const { data: assets } = useListKneeboards(manifest.id);
  const [selected, setSelected] = React.useState(0);

  React.useEffect(() => {
    let params = new URLSearchParams(history.location.search);
    if (open) {
      params.set(ASSET_VIEWER_PARAM, "true");
    } else {
      params.delete(ASSET_VIEWER_PARAM);
    }

    history.replace({
      pathname: history.location.pathname,
      search: params.toString(),
    });
  }, [open]);

  return (
    <Box
      p={0}
      sx={{
        flexGrow: 1,
        display: "flex",
        // height: 1080,
      }}
      className={className}
    >
      <Flex column>
        <Box p={1} marginRight={2}>
          <Flex align>
            <Box marginRight={1}></Box>
            <Box style={{ textAlign: "left" }}>
              <h3>Uploaded Kneeboards</h3>
            </Box>
          </Flex>
        </Box>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selected}
          onChange={(ev, v) => setSelected(v)}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider", overflow: "auto" }}
        >
          {_.map(assets, (a, i) => (
            <StyledTab
              value={i}
              style={{ textAlign: "left" }}
              key={a.displayName}
              label={<span className="tab-text">{a.displayName}</span>}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </Flex>

      <>
        {_.map(assets, (a, i) => (
          <TabPanel value={selected} index={i} key={a.displayName}>
            <AssetViewer
              manifest={manifest}
              style={{ border: "1px solid black" }}
              asset={a}
            />
          </TabPanel>
        ))}
      </>

      <div style={{ flexGrow: 2 }}>
        {assets?.length === 0 && (
          <Flex center wide>
            <Box marginTop={4}>
              <Typography fontStyle="italic">No Kneeboards Uploaded</Typography>
            </Box>
          </Flex>
        )}
      </div>
    </Box>
  );
}

export default styled(AssetPreview).attrs({
  className: AssetPreview.name,
})`
  ${AssetViewer} img {
    max-width: 100%;
  }

  height: 100%;
`;
