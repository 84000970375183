import { Button } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { modules } from "../../lib/data/modules";
import DataTable from "../DataTable";
import Section from "../Section";

type Props = {
  className?: string;
  onClose: () => void;
};

function RadioHelp({ className, onClose }: Props) {
  const rows = _.map(modules, (v) => {
    return {
      name: v.label,
      radios: v.radioPresetOrder
        .map((o) => o.replace("MIXED", "V/UHF"))
        .join(", ")
        .replace(/^, /, ""),
    };
  });

  return (
    <Section
      title="Radio Preset Help"
      className={className}
      actions={[<Button onClick={onClose}>Close</Button>]}
    >
      <p>
        Frag Orders does a "best effort" when setting radios for a module. It
        attempts to keep radios with similar frequecy band capabilities
        synchronized with each other.
      </p>
      <p>
        The following table shows the order in which radios are set for each
        module. <b>V/UHF</b> radios will recieve the <b>V/UHF</b> radio
        frequencies. In modules with several V/UHF radios, the first V/UHF radio
        will recieve the UHF frequencies.
      </p>
      <DataTable
        columns={[
          { value: "name", label: "Module" },
          { value: "radios", label: "Programmable Radios" },
        ]}
        rows={rows}
      />
    </Section>
  );
}

export default styled(RadioHelp).attrs({ className: RadioHelp.name })``;
