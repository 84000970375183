import * as React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.text.primary};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export default function FileDropzone({
  onFileDrop,
  text = "Drag and drop your mission file here",
}) {
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      // TODO this fires twice: https://stackoverflow.com/questions/48610679/react-dropzone-ondrop-firing-twice
      onFileDrop(acceptedFiles);
    },
    [onFileDrop]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  return (
    <div>
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>{text}</p>
      </Container>
    </div>
  );
}
