import _ from "lodash";
import { Feature } from "ol";
import { extend } from "ol/extent";
import { Geometry } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { useEffect, useRef } from "react";
import { useMap } from "./MapCanvas";

// Keeping this empty for now to avoid a rabbit hole of updating this everywhere.
export interface FOMapFeature {
  geometry: Geometry;
}

type Props = {
  features: FOMapFeature[];
  style: (feature: any) => any;
  name?: string;
  displayName?: string;
  foLayerID?: string;
  filterTag?: string;
  hideable?: boolean;
};

export default function MapLayer({
  features,
  style,
  name,
  foLayerID,
  filterTag,
  hideable,
}: Props) {
  const source = useRef<VectorSource>();
  const layer = useRef<VectorLayer<VectorSource>>();
  const map = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    const f = _.map(features, (f) => new Feature({ ...f }));

    source.current = new VectorSource({ features: f });

    layer.current = new VectorLayer({
      source: source.current,
      style,
    });

    layer.current.set("name", name);

    layer.current.set("hideable", hideable);

    if (foLayerID) {
      layer.current.set("uniqID", foLayerID);
    }

    if (filterTag) {
      layer.current.set("filterTag", filterTag);
    }

    map.addLayer(layer.current);
    map.renderSync();

    const extent = source.current.getExtent();
    if (extent) {
      const view = map.getView();
      const current = view.calculateExtent(map.getSize());
      const next = extend(current, extent);
      map.getView().fit(next);
      view.adjustZoom(-0.01);
    }
  }, [map]);

  useEffect(() => {
    if (!source.current) {
      return;
    }
    const existing = source.current.getFeatures();

    _.each(existing, (mapFeature) => {
      source.current.removeFeature(mapFeature);
    });

    _.each(features, (fe) => {
      const f = new Feature({ ...fe });
      source.current.addFeature(f);
    });
  }, []);

  return null;
}
