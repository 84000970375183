import { Feature } from "ol";
import { circular } from "ol/geom/Polygon";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Fill, Stroke, Style, Text as TextStyle } from "ol/style";
import { shipThreatRanges } from "../data/ships";
import { Theater, convertDCSToLatLong } from "../map";
import { DCSGroupData, TaskingMapLayerName, UnitCategory } from "../types";

enum SAMType {
  SA6 = "SA6",
  SA2 = "SA2",
  SA3 = "SA3",
  Patriot = "Patriot",
  HAWK = "Hawk",
  SA5 = "SA5",
  SA10 = "SA10",
  SA11 = "SA11",
  SA15 = "SA15",
  SA8 = "SA8",
}
const nmToMeters = 1852;

const SAM_RADARS = {
  "Kub 1S91 str": SAMType.SA6,
  SNR_75V: SAMType.SA2,
  "snr s-125 tr": SAMType.SA3,
  "Patriot str": SAMType.Patriot,
  "Hawk tr": SAMType.HAWK,
  RPC_5N62V: SAMType.SA5,
  "S-300PS 40B6M tr": SAMType.SA10,
  "SA-11 Buk LN 9A310M1": SAMType.SA11,
  "Tor 9A331": SAMType.SA15,
  "Osa 9A33 ln": SAMType.SA8,
};

const threatDistances = {
  [SAMType.SA6]: 13,
  [SAMType.SA2]: 22,
  [SAMType.SA3]: 9.6,
  [SAMType.Patriot]: 53,
  [SAMType.HAWK]: 19,
  [SAMType.SA5]: 80,
  [SAMType.SA10]: 35,
  [SAMType.SA11]: 18,
  [SAMType.SA15]: 6,
  [SAMType.SA8]: 5,
};

function getThreatRadius(sam: SAMType): number {
  let miles = threatDistances[sam] || 0;

  return miles * nmToMeters;
}

function getShipThreatRadius(type: string): number {
  return shipThreatRanges[type] || 0;
}

export function getSAMType(type: string): SAMType | null {
  return SAM_RADARS[type] || null;
}

export function createSurfaceThreatLayer(
  theater: Theater,
  groups: DCSGroupData[]
) {
  const features = [];

  for (const group of groups) {
    for (const unit of group.units) {
      const samType = getSAMType(unit.type);

      if (samType || group.category === UnitCategory.Ship) {
        const { lat, long } = convertDCSToLatLong(theater, unit);
        const point = [long, lat];

        const radius =
          group.category === UnitCategory.Ship
            ? getShipThreatRadius(unit.type)
            : getThreatRadius(samType);

        const geometry = circular(point, radius);
        const f = new Feature({
          geometry,
          radius,
          type: unit.type,
          designation: samType,
          unselectable: true,
        });

        features.push(f);
        // Don't create multiple ring features
        break;
      }
    }
  }

  const layer = new VectorLayer({
    source: new VectorSource({
      features,
    }),
    style: () => {
      return [
        new Style({
          stroke: new Stroke({
            color: "red",
            width: 2,
          }),
          fill: new Fill({
            color: "rgb(255, 0, 0, .1)",
          }),
          text: new TextStyle({
            overflow: true,
            font: '14px "Roboto","sans-serif"',
            placement: "point",
            // text: feature.get("designation"),
            fill: new Fill({ color: "black" }),
            offsetY: 24,
          }),
        }),
      ];
    },
  });

  layer.set("name", TaskingMapLayerName.Threats);

  return layer;
}
