import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { DCSGroupData } from "../../lib/types";

type Props = {
  className?: string;
  group: DCSGroupData;
};

function OpforGroup({ className, group }: Props) {
  const rows = _.countBy(group.units, "type");

  return (
    <div className={className}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Unit Type</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(rows, (count, type) => (
            <TableRow key={type}>
              <TableCell>{type}</TableCell>
              <TableCell>{count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default styled(OpforGroup).attrs({ className: OpforGroup.name })`
  th,
  td {
    padding: 4px;
  }

  tbody td {
    font-size: 12px;
  }
`;
