export const shipThreatRanges: { [key: string]: number } = {
  ALBATROS: 16000.0,
  ara_vdm: 5000.0,
  "BDK-775": 6000.0,
  CastleClass_01: 3000.0,
  CVN_71: 25000.0,
  CVN_72: 25000.0,
  CVN_73: 25000.0,
  CVN_75: 25000.0,
  CV_1143_5: 12000.0,
  "Dry-cargo ship-1": 0.0,
  "Dry-cargo ship-2": 0.0,
  ELNYA: 0.0,
  Forrestal: 25000.0,
  HandyWind: 0.0,
  HarborTug: 0.0,
  Higgins_boat: 1000.0,
  hms_invincible: 74000.0,
  IMPROVED_KILO: 0.0,
  KILO: 0.0,
  KUZNECOW: 12000.0,
  La_Combattante_II: 4000.0,
  "leander-gun-achilles": 8000.0,
  "leander-gun-andromeda": 140000.0,
  "leander-gun-ariadne": 100000.0,
  "leander-gun-condell": 100000.0,
  "leander-gun-lynch": 140000.0,
  LHA_Tarawa: 20000.0,
  LST_Mk2: 4000.0,
  MOLNIYA: 2000.0,
  MOSCOW: 75000.0,
  NEUSTRASH: 12000.0,
  PERRY: 100000.0,
  PIOTR: 190000.0,
  REZKY: 16000.0,
  santafe: null,
  Schnellboot_type_S130: 4000.0,
  Seawise_Giant: 0.0,
  Ship_Tilde_Supply: 0.0,
  SOM: 0.0,
  speedboat: 1000.0,
  Stennis: 25000.0,
  TICONDEROG: 100000.0,
  Type_052B: 30000.0,
  Type_052C: 100000.0,
  Type_054A: 45000.0,
  Type_071: 150000.0,
  Type_093: 40000.0,
  Uboat_VIIC: 4000.0,
  USS_Arleigh_Burke_IIa: 100000.0,
  USS_Samuel_Chase: 7000.0,
  VINSON: 15000.0,
  ZWEZDNY: 0.0,
};
