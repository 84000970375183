import { FirebaseError } from "firebase/app";
import JSZip from "jszip";
import _ from "lodash";
import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useRouteMatch } from "react-router-dom";

import { useBundleClient } from "../contexts/BundleClientContext";
import { FragOrderContext } from "../contexts/FragOrderContext";
import { useGetAssetManager } from "../contexts/KneeboardManagerContext";
import { MizFileContext } from "../contexts/MizFileContext";
import { useNotification } from "../contexts/NotifcationContext";
import {
  GroupWithPlan,
  useGroupsWithPlans,
  usePlanManager,
} from "../contexts/PlanManagerContext";
import { usePlanManagerV2 } from "../contexts/PlanManagerV2";
import {
  PublishStatusQueryKey,
  useGetPublishManager,
  useGetPublishStatus,
} from "../contexts/PublishManagerContext";
import { useTaskingState } from "../contexts/TaskingMapContext";
import { useUser } from "../contexts/UserContext";
import { getTypeForGroup } from "../lib/data/modules";
import { applyExternalData } from "../lib/jobs/apply_external_data";
import { applyPlans } from "../lib/jobs/apply_plans";
import { injectAirbaseKneeboard } from "../lib/jobs/inject_airbase_kneeboard";
import injectAssets from "../lib/jobs/inject_assets";
import { injectBriefingTextKneeboard } from "../lib/jobs/inject_briefing_text";
import { injectCommsCard } from "../lib/jobs/inject_comms_cards";
import { injectControlPointsCard } from "../lib/jobs/inject_control_points_card";
import { injectLineupCards } from "../lib/jobs/inject_lineup_cards";
import { injectMasterFreqCard } from "../lib/jobs/inject_master_freq_card";
import { injectRouteDetails } from "../lib/jobs/inject_route_details";
import { injectSupportAssetsKneeboard } from "../lib/jobs/inject_support_assets_kneeboard";
import applyRadioPresets from "../lib/jobs/radio_presets";
import { ExternalDataRecord } from "../lib/models/ExternalDataRecord";
import { FragOrderDoc } from "../lib/models/FragOrder";
import {
  AirbasesKneeboardSettings,
  GroupLineupCardSettings,
  Job,
  JobType,
  RouteDetailKneeboardSettings,
} from "../lib/models/Job";
import { MizFile } from "../lib/models/MizFile";
import { Plan, PlanStatus } from "../lib/models/Plan";
import { PlanItem, PlanItemType } from "../lib/models/PlanItem";
import { PublishOpts, TaskingState } from "../lib/models/PublishManifest";
import { RadioPresetConfig } from "../lib/models/RadioPresetConfig";
import { defaultJobSettings } from "../lib/services/FragOrderClient";
import { NewJobRunner } from "../lib/services/JobRunner";
import { NewLuaRuntime } from "../lib/services/LuaRuntime/LuaRuntime";
import { AppRoutes, Coalition, DCSGroupData } from "../lib/types";
import { downloadZip } from "../lib/zip";
import { useExternalDataReader } from "./external_data";

export const FragOrdersQueryKey = "FragOrders";

export function useListFragOrders(page: number) {
  const { currentUser } = useUser();
  const client = useContext(FragOrderContext);
  return useQuery(
    [FragOrdersQueryKey, page],
    () => client.list(currentUser.id, 10, page),
    { retry: false, cacheTime: Infinity, staleTime: Infinity }
  );
}

export function useGetFragOrder(id: string) {
  if (!id) {
    throw new Error("FragOrder ID is required");
  }
  const client = useContext(FragOrderContext);
  return useQuery<FragOrderDoc, FirebaseError>(
    [FragOrdersQueryKey, id],
    () => client.get(id),
    { retry: false }
  );
}

export function useSaveFragOrder() {
  const queryClient = useQueryClient();
  const { currentUser } = useUser();
  const client = useContext(FragOrderContext);

  return (name: string, miz: MizFile, discordGuildID: string) =>
    client.save(currentUser.id, name, miz, discordGuildID).then((result) => {
      queryClient.invalidateQueries([FragOrdersQueryKey]);
      return result;
    });
}

export function useUpdateFragOrder() {
  const { currentUser } = useUser();
  const queryClient = useQueryClient();
  const client = useContext(FragOrderContext);

  return (fo: FragOrderDoc, coalitions: Coalition[], miz?: MizFile) =>
    client.update(currentUser.id, coalitions, fo, miz).then((next) => {
      queryClient.invalidateQueries([FragOrdersQueryKey, fo.id]);
      queryClient.invalidateQueries([PublishStatusQueryKey]);

      return next;
    });
}

export function usePublishFragOrder() {
  const { currentUser } = useUser();
  const client = useContext(FragOrderContext);

  const qc = useQueryClient();
  return (
    fragOrder: FragOrderDoc,
    opts: PublishOpts,
    coalitions: Coalition[]
  ) =>
    client.publish(currentUser.id, fragOrder, coalitions, opts).then(() => {
      qc.invalidateQueries([FragOrdersQueryKey]);
      qc.invalidateQueries([PublishStatusQueryKey]);
    });
}

export function useApplyJobs() {
  const ms = useContext(MizFileContext);
  const mgr = usePlanManager();
  const pub = useGetPublishManager();
  const am = useGetAssetManager();
  const bc = useBundleClient();
  const ed = useExternalDataReader();
  const pm2 = usePlanManagerV2();
  const { currentUser } = useUser();

  return async (fo: FragOrderDoc) => {
    if (!fo.jobSettings) {
      fo.jobSettings = defaultJobSettings;
    }

    const status = await pub.getPublishStatus(fo.id);

    const runner = NewJobRunner(ms, NewLuaRuntime);

    for (const j of fo.jobs) {
      let job: Job;
      let tasking: TaskingState;
      let data: ExternalDataRecord[];
      let cfg: RadioPresetConfig;
      let planItems: PlanItem[];

      for (const man of status) {
        let plans: Plan[];

        if (man.version >= 2) {
          const res = await bc.get(man);
          tasking = JSON.parse(res);
        } else {
          tasking = JSON.parse(man.taskingData);
        }

        if (man.version >= 3) {
          const res = await pm2.list(man.id);
          plans = planItemsToPlans(man.coalition, tasking.plannedGroups, res);
        } else {
          const plansRes = await mgr.listPlans(fo.id);
          plans = _.filter(plansRes, { status: PlanStatus.Submitted });
        }

        switch (j) {
          case JobType.ApplyPlans:
            job = {
              description: j,
              luaFunc: applyPlans(plans),
            };
            break;

          case JobType.InjectAirbasesKneeboard:
            job = {
              description: j,
              zipFunc: injectAirbaseKneeboard(
                tasking,
                fo.jobSettings[j] as AirbasesKneeboardSettings
              ),
            };
            break;

          case JobType.InjectSupportAssetsKneeboard:
            job = {
              description: j,
              zipFunc: injectSupportAssetsKneeboard(tasking),
            };
            break;

          case JobType.GroupLineupCard:
            data = await ed.list(fo.id, man.coalition);
            planItems = await pm2.list(man.id);
            job = {
              description: j,
              zipFunc: injectLineupCards(
                tasking,
                plans,
                fo.jobSettings[j] as GroupLineupCardSettings,
                fo.jobSettings[JobType.RadioPresetConfig] as string,
                data,
                planItems
              ),
            };
            break;

          case JobType.GroupCommsCard:
            job = {
              description: j,
              zipFunc: injectCommsCard(tasking, plans),
            };
            break;

          case JobType.BriefingText:
            job = {
              description: j,
              zipFunc: injectBriefingTextKneeboard(tasking, man.title),
            };
            break;

          case JobType.Assets:
            job = {
              description: j,
              zipFunc: injectAssets(currentUser.id, man.id, am),
            };
            break;

          case JobType.RadioPresetConfig:
            cfg = JSON.parse(fo.jobSettings[j] as string);

            job = {
              description: j,
              luaFunc: applyRadioPresets(cfg, {
                coalition: man.coalition,
                groups: tasking.plannedGroups,
              }),
            };
            break;

          case JobType.RadioPresetConfigKneeboard:
            cfg = JSON.parse(
              fo.jobSettings[JobType.RadioPresetConfig] as string
            );
            job = {
              description: j,
              zipFunc: injectMasterFreqCard(cfg),
            };
            break;

          case JobType.ControlPointsCard:
            job = {
              description: j,
              zipFunc: injectControlPointsCard(tasking),
            };
            break;

          case JobType.ApplyExternalData:
            data = await ed.list(fo.id, man.coalition);

            job = {
              description: j,
              luaFunc: applyExternalData(data),
            };
            break;

          case JobType.InjectRouteDetailCards:
            job = {
              description: j,
              zipFunc: injectRouteDetails(
                tasking,
                plans,
                fo.jobSettings[j] as RouteDetailKneeboardSettings
              ),
            };
        }

        runner.add(job);
      }
    }

    return runner.run(fo);
  };
}

export function useApplyAndDownload() {
  const { success, error } = useNotification();
  const apply = useApplyJobs();

  return async (fo: FragOrderDoc) => {
    let zip: JSZip;

    try {
      zip = await apply(fo);
    } catch (e) {
      error(e.message);
      throw e;
    }

    return downloadZip(zip, fo.mizFileName)
      .then(() => {
        success("Updated mission file downloaded.");
      })
      .catch((e) => {
        error(e.message);
        throw e;
      });
  };
}

export function useFindGroupsOfType(type: string): GroupWithPlan[] {
  const { state } = useTaskingState();

  const { data: groups } = useGroupsWithPlans(
    state.manifest.fragOrderID,
    state.tasking.plannedGroups
  );

  return _.filter(groups, (g) => type === _.get(g, ["units", 0, "type"]));
}

// We cannot do coalition-specific kneeboard gen,
// so avoid leaking lineup cards etc to the enemy.
// Users can still generate from within the public FO and download manually.
export function useKneeboardInjectionProhibited(fragOrderID) {
  const { data: status } = useGetPublishStatus(fragOrderID);

  // More than 1 coalition published. Prohibit.
  return status && status.length > 1;
}

export function useRemoveFragOrder() {
  const c = useContext(FragOrderContext);
  const qc = useQueryClient();
  return (doc: FragOrderDoc) =>
    c.remove(doc).then(() => {
      qc.invalidateQueries([FragOrdersQueryKey]);
    });
}

export function useAdminGetFragOrderID() {
  const match = useRouteMatch(`${AppRoutes.FragOrderDetail}/:id`);

  const { id } = match.params as { id: string };

  return id;
}

export function useAdminGetCurrentFragOrder() {
  const id = useAdminGetFragOrderID();

  return useGetFragOrder(id);
}

export function useAdminGetManifestsForFragOrder() {
  const id = useAdminGetFragOrderID();

  return useGetPublishStatus(id);
}

export function useGetCurrentManifestID() {
  let match = useRouteMatch(`${AppRoutes.PublicFragOrder}/:id`);

  if (!match) {
    match = useRouteMatch(`${AppRoutes.StandaloneSignupsPage}/:id`);
  }

  const { id } = match.params as { id: string };

  return id;
}

export function useAdminGetCurrentManifests() {
  const { data: fo } = useAdminGetCurrentFragOrder();

  return useGetPublishStatus(fo?.id);
}

export function planItemsToPlans(
  coalition: Coalition,
  groups: DCSGroupData[],
  planItems: PlanItem[]
): Plan[] {
  const result: Plan[] = [];

  for (const group of groups) {
    if (!group) {
      console.error("No group found for plan item");
      continue;
    }
    const waypointItems = _.filter(planItems, { group: group?.name });

    const nextWaypoints = [...group?.waypoints];

    for (const item of waypointItems) {
      const wp = JSON.parse(item.payload);

      const match = _.find(nextWaypoints, { number: wp.number });

      if (match) {
        nextWaypoints.splice(nextWaypoints.indexOf(match), 1, wp);
      }
    }

    const radioCfg = _.find(planItems, {
      group: group.name,
      type: PlanItemType.RadioPresets,
    });

    const note = _.find(planItems, {
      group: group.name,
      type: PlanItemType.PlanNote,
    });

    const laserConfg = _.find(planItems, {
      group: group.name,
      type: PlanItemType.LaserCodes,
    });

    const laserPayload = JSON.parse(laserConfg?.payload || "{}") as Record<
      string,
      string
    >[];

    const navPoints = _.filter(planItems, {
      group: group.name,
      type: PlanItemType.NavPoint,
    });

    const navPointPayloads = _.map(navPoints, (w) => JSON.parse(w.payload));

    const plan: Plan = {
      coalition,
      fragOrderID: "",
      groupName: group.name,
      groupCategory: group.category,
      waypoints: nextWaypoints,

      status: PlanStatus.Submitted,
      radioOverrides: radioCfg?.payload,
      moduleName: getTypeForGroup(group) as any,
      plannerNotes: JSON.parse(note?.payload || "{}")?.text,
      laserConfig: laserPayload,
      navPoints: navPointPayloads,
      // TODO: Add annotations
      annotations: [],
    };

    result.push(plan);
  }

  return result;
}
