import { Avatar } from "@mui/material";
import { APIGuild } from "discord-api-types/v10";
import * as React from "react";
import styled from "styled-components";
import Flex from "./Flex";

type Props = {
  guild: APIGuild;
  className?: string;
  size?: number;
};

function DiscordGuildLabel({ className, guild, size = 24 }: Props) {
  return (
    <Flex className={className} start align>
      <Avatar
        sx={{ width: size, height: size }}
        alt={guild?.name}
        src={`https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}.png?size=$${size}`}
      />

      <div style={{ marginLeft: 4 }}>{guild?.name}</div>
    </Flex>
  );
}

export default styled(DiscordGuildLabel).attrs({
  className: DiscordGuildLabel.name,
})`
  .MuiAvatar-root {
    padding-top: 0;
  }
`;
