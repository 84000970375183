import { RecordType } from "aws-sdk/clients/acm";
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { docs } from "../db_util";
import {
  ExternalDataRecord,
  ExternalDataRecordDoc,
} from "../models/ExternalDataRecord";
import { JobType } from "../models/Job";
import { Coalition } from "../types";
import { FragOrderClient } from "./FragOrderClient";

const ExternalDataRecordsCollection = "ExternalDataRecords";

export interface ExternalDataReader {
  list: (
    fragOrderID: string,
    coalition: Coalition
  ) => Promise<ExternalDataRecordDoc[]>;
  get: (id: string) => Promise<ExternalDataRecordDoc>;
}

export interface ExternalDataWriter {
  upsert: (userID: string, record: ExternalDataRecord) => Promise<any>;
  delete: (userID: string, record: ExternalDataRecordDoc) => Promise<void>;
}

export interface ExternalDataManager
  extends ExternalDataReader,
    ExternalDataWriter {}

export class DefaultExternalDataManager
  implements ExternalDataReader, ExternalDataWriter {
  db: Firestore;
  fo: FragOrderClient;

  constructor(db: Firestore, fo: FragOrderClient) {
    this.db = db;
    this.fo = fo;
  }

  async list(fragOrderID: string, coalition: Coalition) {
    const q = query(
      collection(this.db, ExternalDataRecordsCollection),
      where("fragOrderID", "==", fragOrderID),
      where("coalition", "==", coalition)
    );

    const qs = await getDocs(q);

    return docs<ExternalDataRecordDoc>(qs);
  }

  async get(id: string) {
    const docRef = doc(this.db, ExternalDataRecordsCollection, id);
    const docSnap = await getDoc(docRef);

    return { ...docSnap.data(), id } as ExternalDataRecordDoc;
  }

  async getByType(fragOrderID: string, type: RecordType) {
    const q = query(
      collection(this.db, ExternalDataRecordsCollection),
      where("fragOrderID", "==", fragOrderID),
      where("type", "==", type)
    );

    return (await getDocs(q)).docs;
  }

  async delete(userID: string, record: ExternalDataRecordDoc) {
    const existing = await this.getByType(record.fragOrderID, record.type);
    if (existing.length === 1) {
      await this.fo.removeJob(
        userID,
        record.fragOrderID,
        JobType.ApplyExternalData
      );
    }
    return await deleteDoc(
      doc(this.db, ExternalDataRecordsCollection, record.id)
    );
  }

  async upsert(userID: string, record: ExternalDataRecordDoc) {
    const t = new Date().getTime();

    const existing = await this.getByType(record.fragOrderID, record.type);
    if (existing.length > 0) {
      const docRef = doc(
        this.db,
        ExternalDataRecordsCollection,
        existing[0].id
      );

      return setDoc(docRef, {
        ...record,
        created_by_uid: userID,
        updated_at_timestamp: t,
      });
    }

    await this.fo.addJob(
      userID,
      record.fragOrderID,
      JobType.ApplyExternalData,
      null
    );

    const colRef = collection(this.db, ExternalDataRecordsCollection);
    return addDoc(colRef, {
      ...record,
      created_by_uid: userID,
      created_at_timestamp: t,
      updated_at_timestamp: t,
    });
  }
}
