import JSZip from "jszip";
import { renderControlPointsCard } from "../../components/Kneeboards/kneeboard_control_points";
import { MizJobFunc } from "../models/Job";
import { TaskingState } from "../models/PublishManifest";

export const injectControlPointsCard = (
  tasking: TaskingState
): MizJobFunc => async (zip: JSZip) => {
  const composer = await renderControlPointsCard(tasking);
  const blob = await composer.renderToBlob();
  zip = zip.file(`KNEEBOARD/IMAGES/FragOrders_Control_Points.png`, blob);
  return zip;
};
