import { APIGuild, APIGuildMember, APIRole } from "discord-api-types/v10";
import { DiscordError } from "../../../netlify/functions/common";
import { Fetch } from "../types";

export interface DiscordClient {
  listGuilds(): Promise<APIGuild[]>;
  listMembers(guildID: string): Promise<APIGuildMember[]>;
  searchMembers(guildID: string, query: any): Promise<APIGuildMember[]>;
  checkBotStatus(guildID: string): Promise<boolean>;
  listGuildRoles(guildID: string): Promise<APIRole[]>;
  getGuildMemberForUser(
    guildID: string,
    token?: string
  ): Promise<APIGuildMember>;
}

const GUILD_PATH = "/.netlify/functions/discord_guilds";
const MEMBERS_PATH = "/.netlify/functions/discord_guild_members";
const MEMBERS_SEARCH_PATH = "/.netlify/functions/discord_guild_members_search";
const BOT_CHECK_PATH = "/.netlify/functions/discord_bot_check";
const ROLES_PATH = "/.netlify/functions/discord_guild_roles";
const USER_ROLES_PATH = "/.netlify/functions/discord_user_roles";

export interface DiscordMemberGetter {
  getGuildMemberForUser(
    guildID: string,
    token?: string
  ): Promise<APIGuildMember>;
}

class discord implements DiscordClient, DiscordMemberGetter {
  fetch: Fetch;

  constructor(f: Fetch) {
    this.fetch = f.bind(window);
  }

  private req(path: string, params?: any) {
    return this.fetch(path, params).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    });
  }

  listGuilds() {
    return this.req(GUILD_PATH);
  }

  listMembers(guildID: string) {
    return this.req(MEMBERS_PATH, {
      method: "POST",
      body: JSON.stringify({ guildID }),
    });
  }

  searchMembers(guildID: string, query: any) {
    return this.req(MEMBERS_SEARCH_PATH, {
      method: "POST",
      body: JSON.stringify({ guildID, query }),
    });
  }

  async checkBotStatus(guildID: string) {
    try {
      await this.req(BOT_CHECK_PATH, {
        method: "POST",
        body: JSON.stringify({ guildID }),
      });
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async listGuildRoles(guildID: string) {
    return this.req(ROLES_PATH, {
      method: "POST",
      body: JSON.stringify({ guildID }),
    });
  }

  async getGuildMemberForUser(guildID: string) {
    return this.req(USER_ROLES_PATH, {
      method: "POST",
      body: JSON.stringify({ guildID }),
    });
  }
}

export function NewDiscordClient(f: Fetch = window.fetch): DiscordClient {
  const d = new discord(f);

  return d;
}

class discordServerClient implements DiscordMemberGetter {
  fetch: Fetch;

  constructor(f: Fetch) {
    this.fetch = f.bind(this);
  }

  async getGuildMemberForUser(guildID: string, token?: string) {
    const guildMemberResult = await this.fetch(
      `https://discord.com/api/users/@me/guilds/${guildID}/member`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    const memberData = (await guildMemberResult.json()) as APIGuildMember &
      DiscordError;

    if (memberData.code) {
      throw new Error(memberData.message);
    }

    return memberData;
  }
}

export function NewDiscordServerClient(f: Fetch): DiscordMemberGetter {
  const d = new discordServerClient(f);

  return d;
}
