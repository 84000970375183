import JSZip from "jszip";
import { renderAirbasesKneeboard } from "../../components/Kneeboards/kneeboard_airbases";

import { AirbasesKneeboardSettings, MizJobFunc } from "../models/Job";
import { TaskingState } from "../models/PublishManifest";

export const injectAirbaseKneeboard = (
  tasking: TaskingState,
  jobSettings?: AirbasesKneeboardSettings
): MizJobFunc => async (zip: JSZip) => {
  const composer = await renderAirbasesKneeboard(tasking, jobSettings);
  const blob = await composer.renderToBlob();

  return zip.file("KNEEBOARD/IMAGES/Airbases.png", blob);
};
