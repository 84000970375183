import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import Flex from "../../Flex";

type Props = {
  className?: string;
  eta: number;
  onChange: (eta: number, valid: boolean) => void;
  valid: boolean;
};

function TimeOnTargetInput({
  className,
  eta,
  onChange,
  valid: isValid,
}: Props) {
  const hours = Math.floor(eta / 60 / 60);
  const minutes = Math.floor((eta - hours * 60 * 60) / 60);
  const seconds = eta - hours * 60 * 60 - minutes * 60;

  return (
    <div className={className}>
      <Box paddingBottom={1}>
        <Typography fontSize={12}>Time on Target (from start)</Typography>
      </Box>
      <Flex align>
        <FormControl>
          <TextField
            error={!isValid}
            onChange={(ev) => {
              let val = parseInt(ev.target.value);
              if (isNaN(val)) {
                val = 0;
              }

              const nextEta = val * 3600 + minutes * 60 + seconds;

              onChange(nextEta, isValid);
            }}
            type="number"
            label="Hours"
            value={hours}
            style={{ width: 72 }}
            size="small"
            variant="outlined"
            inputProps={{
              "data-testid": "time-on-target-input-hours",
            }}
          />
        </FormControl>
        <Box marginX={1}>
          <Typography>:</Typography>
        </Box>
        <FormControl>
          <TextField
            error={!isValid}
            onChange={(ev) => {
              let val = parseInt(ev.target.value);
              if (isNaN(val)) {
                val = 0;
              }

              const nextEta = hours * 60 * 60 + val * 60 + seconds;

              onChange(nextEta, isValid);
            }}
            type="number"
            label="Minutes"
            value={minutes}
            style={{ width: 72 }}
            size="small"
            variant="outlined"
            inputProps={{
              "data-testid": "time-on-target-input-minutes",
            }}
          />
        </FormControl>
        <Box marginX={1}>
          <Typography>:</Typography>
        </Box>
        <FormControl>
          <TextField
            error={!isValid}
            onChange={(ev) => {
              let val = parseInt(ev.target.value);
              if (isNaN(val)) {
                val = 0;
              }

              const nextEta = hours * 60 * 60 + minutes * 60 + val;

              onChange(nextEta, isValid);
            }}
            type="number"
            label="Seconds"
            value={seconds}
            style={{ width: 72 }}
            size="small"
            variant="outlined"
            inputProps={{
              "data-testid": "time-on-target-input-seconds",
            }}
          />
        </FormControl>
      </Flex>
      {!isValid && (
        <FormHelperText error>
          Must be after previous waypoint Time on Target
        </FormHelperText>
      )}
    </div>
  );
}

export default styled(TimeOnTargetInput).attrs({
  className: TimeOnTargetInput.name,
})``;
