import _ from "lodash";
import { modules } from "../data/modules";

import {
  Coalition,
  DCSRadioModulation,
  DCSWaypoint,
  FirebaseMetadata,
  NavTargetPoint,
  UnitCategory,
} from "../types";

export enum PlanStatus {
  None = "None",
  Draft = "Draft",
  Submitted = "Submitted",
  Invalid = "Invalid",
  Deleted = "Deleted",
}

export type RadioChannelOverride = {
  frequency: string;
  modulation: DCSRadioModulation;
  description?: string;
};

export interface PlanAnnotation {
  time: number;
  text: string;
}

export interface Plan {
  coalition: Coalition;
  fragOrderID: string;
  groupName: string;
  groupCategory: UnitCategory;
  waypoints?: DCSWaypoint[];
  status: PlanStatus;
  radioOverrides?: string;
  moduleName: keyof typeof modules;
  plannerNotes?: string;
  laserConfig?: LaserConfig;
  navPoints?: NavTargetPoint[];
  annotations?: PlanAnnotation[];
}

export type PlanDoc = Plan &
  FirebaseMetadata & { submitterDisplayText: string };

export type LaserConfig = Record<string, string>[];

export type PlanLike = Plan & { updated_at_timestamp?: number };

type Result = Record<string, PlanDoc>;

export function combinePlans(plans: PlanLike[]): PlanDoc[] {
  const sorted = _.sortBy(plans, "updated_at_timestamp");
  const result = _.reduce(
    sorted,
    (r: Result, plan: PlanDoc) => {
      if (!r[plan.groupName]) {
        r[plan.groupName] = plan;
        return r;
      }

      const existing = r[plan.groupName];

      if (existing) {
        const keys = _.keys(plan);

        for (const key of keys) {
          const newVal = plan[key];

          if (Array.isArray(newVal) && newVal.length === 0) {
            // array is empty, skip
            continue;
          }

          if (newVal && !existing[key]) {
            existing[key] = newVal;
          }

          // If the plan has a value and the dupe as a value,
          // use the plan that has the more recent 'updated_at_timestamp' for this field.
          if (newVal && existing[key]) {
            if (plan.updated_at_timestamp > existing.updated_at_timestamp) {
              existing[key] = newVal;
            }
          }
        }
      }

      return r;
    },
    {} as Result
  );

  const resultValues = _.values(result) as PlanDoc[];

  return resultValues;
}
