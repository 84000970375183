import { BaseFeature } from "./BaseFeature";

export default class NavPointFeature extends BaseFeature {
  group(): string {
    return this.feature.get("group");
  }

  textComment(): string {
    return this.feature.get("point").textComment;
  }

  index(): number {
    return this.feature.get("point").index;
  }
}
