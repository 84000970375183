import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useListKneeboards } from "../../contexts/KneeboardManagerContext";
import { useGetPlans } from "../../contexts/PlanManagerContext";
import {
  useApplyAndDownload,
  useGetFragOrder,
  useUpdateFragOrder,
} from "../../hooks/frag_orders";
import { JobType } from "../../lib/models/Job";
import { PlanStatus } from "../../lib/models/Plan";
import { Coalition } from "../../lib/types";
import DataTable from "../DataTable";
import Section from "../Section";

type Props = {
  className?: string;
  id: string;
};

function JobsPage({ className, id }: Props) {
  const [running, setRunning] = React.useState(false);
  const { data: fragOrder, isLoading } = useGetFragOrder(id);
  const { data: plans } = useGetPlans(id);
  const { data: assets } = useListKneeboards();
  const apply = useApplyAndDownload();
  const update = useUpdateFragOrder();

  React.useEffect(() => {
    if (!plans) {
      return;
    }

    // We don't have a good way to automatically add a job to the frag order when someone submits a plan.
    // Check here and add the job if someone has submitted a plan.
    // This also allows for backwards compat.
    const hasPlansJob = _.includes(fragOrder.jobs, JobType.ApplyPlans);
    const hasValidPlans = !!_.find(plans, { status: PlanStatus.Submitted });

    if (hasValidPlans && !hasPlansJob) {
      const jobs = _.concat(fragOrder.jobs || [], JobType.ApplyPlans);
      update(
        {
          ...fragOrder,
          jobs,
        },
        _.map(plans, "coaltion")
      );
    }
  }, [plans]);

  React.useEffect(() => {
    if (!assets) {
      return;
    }

    const hasAssets = assets.length > 0;
    const hasAssetsJob = _.includes(fragOrder.jobs, JobType.ApplyPlans);

    if (hasAssets && !hasAssetsJob) {
      const jobs = _.concat(fragOrder.jobs || [], JobType.Assets);
      update(
        {
          ...fragOrder,
          jobs,
        },
        // We only do kneeboard injection on single-coalition manifests
        [Coalition.Blue]
      );
    }
  }, [assets]);

  const handleRun = async () => {
    setRunning(true);
    await apply(fragOrder).finally(() => setRunning(false));
  };

  return (
    <Section loading={isLoading} title="Jobs" className={className}>
      <p>
        These jobs will be run on your mission and compiled into a new .miz file
      </p>
      <Box marginBottom={2}>
        <DataTable
          columns={[{ value: (j: string) => j, label: "Description" }]}
          rows={fragOrder?.jobs}
        />
      </Box>
      <LoadingButton
        disabled={!fragOrder?.jobs || fragOrder.jobs.length === 0}
        variant="contained"
        loading={running}
        onClick={handleRun}
      >
        Run Jobs and Download
      </LoadingButton>
    </Section>
  );
}

export default styled(JobsPage).attrs({ className: JobsPage.name })``;
