import { useQuery, useQueryClient } from "react-query";
import Stripe from "stripe";
import { useSubManager } from "../contexts/ServicesContext";
import { useUser } from "../contexts/UserContext";
import {
  SubscriptionDoc,
  VolumeRedeemRecord,
} from "../lib/models/Subscription";
import { PriceID } from "../lib/services/SubscriptionManager";

const subCacheKey = "payment";
export function useGetSubscriptionDetails() {
  const mgr = useSubManager();

  return useQuery<
    {
      subscription: SubscriptionDoc;
      details: Stripe.Subscription;
    },
    { message: string }
  >(
    [subCacheKey],
    () => {
      return mgr.get();
    },
    {
      retry: false,
      retryDelay: 1000,
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
}

export function useGetSubPortalURL() {
  const mgr = useSubManager();

  return (sessionID) => mgr.getPortalURL(sessionID);
}

export function useCreateCheckoutSession() {
  const mgr = useSubManager();
  const { currentUser, subscription } = useUser();

  return async (priceID: PriceID) => {
    return mgr.createCheckoutSession(
      currentUser.id,
      priceID,
      !subscription?.trialCompleted
    );
  };
}

export function useListVolumeSubs(parent: SubscriptionDoc) {
  const mgr = useSubManager();

  return useQuery<SubscriptionDoc[], { message: string }>(
    ["volume"],
    () => {
      return mgr.listVolumeSubs(parent);
    },
    {
      refetchOnWindowFocus: true,
      cacheTime: 10000,
      staleTime: 10000,
    }
  );
}

export function useCreateRedeemRecord() {
  const mgr = useSubManager();
  const qc = useQueryClient();

  return async (parent: SubscriptionDoc) => {
    return mgr.generateVolumeRedeemCode(parent).then(() => {
      qc.invalidateQueries("volume");
    });
  };
}

export function useGetVolumeRecordByCode(code: string) {
  const mgr = useSubManager();

  return useQuery<VolumeRedeemRecord, { message: string }>(
    ["volume", code],
    () => {
      return mgr.getVolumeRecordByCode(code);
    },
    {
      retry: false,
      retryDelay: 1000,
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
}

export function useRedeemVolumeCode() {
  const mgr = useSubManager();
  const qc = useQueryClient();

  return async (code: string) => {
    return mgr.redeemVolumeCode(code).then(() => {
      qc.invalidateQueries("volume");
    });
  };
}

export function useRevokeGroupLicense() {
  const mgr = useSubManager();
  const qc = useQueryClient();

  return async (subID: string) => {
    return mgr.revokeGroupLicense(subID).then(() => {
      qc.invalidateQueries("volume");
    });
  };
}
