import {
  get,
  getGroupInMission,
  setChannelPresetsForRadios,
  setFreqForGroup,
} from "../mission";
import { LuaJobFunc } from "../models/Job";
import { RadioPresetConfig } from "../models/RadioPresetConfig";
import { overridesToPresets } from "../radios";
import { Coalition, DCSGroupData, LuaTable } from "../types";

const applyRadioPresets = (
  presets: RadioPresetConfig,
  opts: { coalition: Coalition; groups: DCSGroupData[] }
): LuaJobFunc => (mission: LuaTable) => {
  const formatted = [presets.UHF, presets.VHF, presets["V/UHF"], presets.FM];
  const p = overridesToPresets(formatted);

  for (const group of opts.groups) {
    const g = getGroupInMission(
      mission,
      opts.coalition,
      group.category,
      group.name
    );

    if (!g) {
      throw new Error(`Group ${group.name} not found in mission`);
    }

    setFreqForGroup(g, p);

    const units = get(g, ["units"]);
    for (const unit of units.numValues) {
      if (!unit) {
        continue;
      }
      setChannelPresetsForRadios(unit, p);
    }
  }
};

export default applyRadioPresets;
