import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import Flex from "./Flex";

type ColResolver = (row: any, colNum: number, rowNum: number) => any;

const EmptyRow = styled(TableRow)<{ colSpan: number }>`
  td {
    text-align: center;
  }
`;

export type Column = {
  label: string | React.ReactElement;
  value: string | ColResolver;
  width?: number;
  style?: {
    // @ts-ignore
    th?: React.StyleHTMLAttributes;
    // @ts-ignore
    td?: React.StyleHTMLAttributes;
  };
};

export type DataTableProps = {
  className: string;
  columns: Column[];
  rows: any;
  selectedRow?: number;
  emptyMessage?: string;
  edit?: (row: any, index: number) => React.ReactElement;
  editingRows?: number[];
} & TableProps;

function DataTable({
  columns,
  rows,
  className,
  selectedRow,
  emptyMessage = "No Data",
  edit,
  editingRows,
  ...rest
}: DataTableProps) {
  return (
    <Table {...rest} className={className}>
      <TableHead>
        <TableRow>
          {_.map(columns, (c, i) => (
            <TableCell style={c.style?.th} key={i} scope="col" width={c.width}>
              <strong>{c.label}</strong>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.length > 0 ? (
          _.map(rows, (r, rowNum: number) => {
            const isEditing = _.includes(editingRows, rowNum);
            return (
              <TableRow
                key={rowNum}
                className={selectedRow === rowNum ? "row-selected" : ""}
              >
                {_.map(columns, (c, colNum) => (
                  <TableCell
                    style={c.style?.td}
                    key={colNum}
                    scope="row"
                    width={c.width}
                  >
                    {isEditing && edit && typeof edit === "function"
                      ? () => edit(r, rowNum)
                      : null}
                    {!isEditing && typeof c.value === "function"
                      ? c.value(r, colNum, rowNum)
                      : // @ts-ignore
                        r[c.value]}
                  </TableCell>
                ))}
              </TableRow>
            );
          })
        ) : (
          <EmptyRow colSpan={columns.length}>
            <TableCell colSpan={columns.length}>
              <Flex center align>
                <Typography fontStyle="italic" style={{ color: "grey" }}>
                  {emptyMessage}
                </Typography>
              </Flex>
            </TableCell>
          </EmptyRow>
        )}
      </TableBody>
    </Table>
  );
}

export default styled(DataTable).attrs({ className: DataTable.name })`
  td {
    text-overflow: ellipsis;
  }
`;
