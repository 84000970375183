import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { Module, modules } from "../../lib/data/modules";
import { RadioChannelOverride } from "../../lib/models/Plan";
import { formatFreq } from "../../lib/radios";
import { DCSRadioModulation } from "../../lib/types";
import { styles } from "./kneeboard_utils";

type Props = {
  group?: GroupWithPlan;
};

const divStyle = {
  display: "flex",
  margin: 8,
  fontFamily: "sans-serif",
  height: "100%",
  width: "98%",
  fontSize: 14,
  flexWrap: "wrap",
  justifyContent: "space-between",
};

const cellStyle = {
  borderBottom: "1px solid gray",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export default function KneeboardCommsCard({ group }: Props) {
  const type = _.first(group.units).type;
  const module: Module = modules[type];
  const radios = _.first(group.units).radios;
  const radioCfg = module?.radios;

  if (!radioCfg || radioCfg.length === 0) {
    return (
      <div style={{ ...(divStyle as any), justifyContent: "center" }}>
        <p>Kneeboard not supported for {type}</p>
      </div>
    );
  }
  const plannedPresets = group.plan?.radioOverrides;
  const overrides: RadioChannelOverride[] = plannedPresets
    ? JSON.parse(plannedPresets)
    : null;

  return (
    <div style={divStyle as any}>
      <div>
        <h2 style={{ fontSize: 18 }}>
          {group.name} - {module?.label || type} - Comms Card
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {_.map(radioCfg, (r, idx) => {
          return (
            <div
              style={{ maxWidth: 320, marginRight: 8, marginBottom: 8 }}
              key={idx}
            >
              <div
                style={{
                  fontSize: 14,
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <b>{r.label}</b>
              </div>
              <Table style={styles.table as any}>
                <TableHead style={styles.th as any}>
                  {/* <TableRow>
                  <TableCell
                    style={{ textAlign: "center", width: 300 }}
                    colSpan={4}
                  >
                    {r.label}
                  </TableCell>
                </TableRow> */}
                  <TableRow>
                    <TableCell style={{ width: 32 }}>Chan</TableCell>
                    <TableCell style={{ width: 64 }}>Freq</TableCell>
                    <TableCell style={{ width: 32 }}>AM/FM</TableCell>
                    <TableCell style={{ width: 72 }}>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.times(r.presetCount || 20, (t) => {
                    const planned: RadioChannelOverride = _.get(overrides, [
                      idx,
                      t,
                    ]);
                    const fallback = _.get(radios, [idx, t]);

                    let freq = planned ? planned.frequency : fallback.frequency;
                    let mod = planned
                      ? planned.modulation
                      : fallback.modulation;
                    let desc = planned ? planned.description : "";

                    return (
                      <TableRow
                        style={{
                          backgroundColor: t % 2 === 1 ? "#ffe194" : "#ffffff",
                        }}
                        key={t}
                      >
                        <TableCell style={{ ...(cellStyle as any) }}>
                          {t + 1}
                        </TableCell>
                        <TableCell style={cellStyle as any}>
                          {formatFreq(freq)}
                        </TableCell>
                        <TableCell style={cellStyle as any}>
                          {mod === DCSRadioModulation.FM ? "FM" : "AM"}
                        </TableCell>
                        <TableCell style={cellStyle as any}>{desc}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          );
        })}
      </div>
    </div>
  );
}
