import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { APIGuild } from "discord-api-types/v10";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useDiscordBotAuthURL, useGuildBotStatus } from "../../hooks/discord";
import {
  useAdminGetCurrentFragOrder,
  useAdminGetManifestsForFragOrder,
  useUpdateFragOrder,
} from "../../hooks/frag_orders";
import DiscordGuildSelect from "../DiscordGuildSelect";
import Flex from "../Flex";

type Props = {
  className?: string;
};

function NoDiscordGuildCallToAction({ className }: Props) {
  const [associating, setAssociating] = React.useState(false);
  const [discordGuild, setDiscord] = React.useState<APIGuild>(null);
  const { data: botDetected, isLoading } = useGuildBotStatus(discordGuild?.id);
  const { data: botAuthURL } = useDiscordBotAuthURL();
  const { data: fragOrder } = useAdminGetCurrentFragOrder();
  const { data: manifests } = useAdminGetManifestsForFragOrder();
  const { success, error } = useNotification();
  const update = useUpdateFragOrder();

  const handleAssociate = () => {
    setAssociating(true);
    const ok = () => success("Frag Order updated successfully");
    const errHandler = (err) => error(err.message);
    const final = () => setAssociating(false);

    return update(
      { ...fragOrder, discordGuildID: discordGuild.id },
      _.map(manifests, "coalition")
    )
      .then(ok)
      .catch(errHandler)
      .finally(final);

    // onAssociate(discordGuild.id).then(ok).catch(errHandler).finally(final);
  };

  return (
    <Flex className={className} wide center>
      <Box m={2}>
        <Flex wide center>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Alert
              style={{ display: botDetected ? "none" : "flex" }}
              severity="warning"
            >
              No Discord Server associated with this Frag Order.
            </Alert>
          )}
        </Flex>
        <Flex>
          <Box m={2}>
            <Typography>
              Frag Order permissions are managed via Discord users and roles.
              Select one of your Discord Servers below to manage which users can
              interact with this Frag Order.
            </Typography>
          </Box>
        </Flex>

        <Box m={2}>
          <Flex wide center>
            <DiscordGuildSelect
              value={discordGuild?.id}
              onChange={(id, guild) => setDiscord(guild)}
            />
          </Flex>
        </Box>
        <Box m={2}>
          <Flex wide center>
            <div>
              <Box marginY={2}>
                {discordGuild && botDetected && (
                  <Alert severity="success">
                    Frag Orders Bot detected for server{" "}
                    <q>{discordGuild?.name}</q>
                  </Alert>
                )}
                {discordGuild && !botDetected && (
                  <Alert severity="error">
                    <AlertTitle>No Frag Orders bot detected</AlertTitle>
                    You will need admin permissions for this Discord Server to
                    add the bot.
                  </Alert>
                )}
              </Box>
              <Flex wide center>
                {discordGuild && botDetected && (
                  <div>
                    <div>
                      <Typography>
                        It looks like the Frag Orders bot has already been added
                        to this Discord server. You can click the button below
                        to associate this Frag Order with the selected Discord
                        server.
                      </Typography>
                    </div>
                    <Box marginY={2}>
                      <Flex wide center>
                        <LoadingButton
                          loading={associating}
                          onClick={handleAssociate}
                          variant="contained"
                        >
                          Associate with Discord Server
                        </LoadingButton>
                      </Flex>
                    </Box>
                  </div>
                )}
                {discordGuild && !botDetected && (
                  <div>
                    <div>
                      <Typography>
                        The Frag Orders bot was not detected for the selected
                        Discord server. Click the button below to add the bot to
                        your server.
                      </Typography>
                    </div>
                    <Box marginY={2}>
                      <Flex wide center>
                        <LoadingButton
                          disabled={!discordGuild}
                          variant="contained"
                          href={botAuthURL}
                          target="_blank"
                        >
                          Add Frag Orders Bot
                        </LoadingButton>
                      </Flex>
                    </Box>
                  </div>
                )}
              </Flex>
            </div>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default styled(NoDiscordGuildCallToAction).attrs({
  className: NoDiscordGuildCallToAction.name,
})``;
