import * as React from "react";
import styled from "styled-components";
import Page from "./Page";

type Props = {
  className?: string;
  message: string;
  stack?: string;
};

function ErrorPage({ className, message, stack }: Props) {
  return (
    <Page className={className} title="Error">
      <h3>Something went wrong.</h3>
      <pre>{message}</pre>
      <pre>{stack}</pre>
    </Page>
  );
}

export default styled(ErrorPage).attrs({ className: ErrorPage.name })``;
