import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { Module, RadioFreqRange, modules } from "../../lib/data/modules";
import { RadioChannelOverride } from "../../lib/models/Plan";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import DataTable from "../DataTable";

type Props = {
  className?: string;
  group: GroupWithPlan;
  presets: RadioPresetConfig;
};

function RadioPresetTable({ className, group, presets }: Props) {
  const type = _.first(group.units).type;
  const module: Module = modules[type];

  if (!module) {
    return null;
  }

  const plannedPresets = group.plan?.radioOverrides;

  const plannedOverrides: RadioChannelOverride[][] = plannedPresets
    ? JSON.parse(plannedPresets)
    : null;

  const uhfIndex = module?.radioPresetOrder.indexOf(RadioFreqRange.UHF);
  const vhfIndex = module?.radioPresetOrder.indexOf(RadioFreqRange.VHF);
  const mixedIndex = module?.radioPresetOrder.indexOf(RadioFreqRange.MIXED);

  let overrides = [];

  if (presets && uhfIndex !== -1) {
    overrides[uhfIndex] = presets.UHF;
  }

  if (presets && mixedIndex !== -1) {
    overrides[mixedIndex] = presets["V/UHF"];
  }

  if (presets && vhfIndex !== -1) {
    overrides[vhfIndex] = presets.VHF;
  }

  if (overrides.length === 0 && plannedOverrides?.length > 0) {
    overrides = plannedOverrides;
  }

  const radioRows = [];
  _.each(overrides, (radio, n) => {
    _.each(radio, (o, i) => {
      if (!radioRows[i]) {
        radioRows[i] = [];
      }

      radioRows[i][n] = o;
    });
  });

  const cols = [];

  _.each(module.radios, (r) => {
    cols.push({
      label: r.short,
      value: (o: RadioChannelOverride[], colN: number) => {
        const ov = o[colN - 1];
        return (
          <span>
            {ov?.frequency || ""}&nbsp;&nbsp;&nbsp;&nbsp;{ov?.description || ""}
          </span>
        );
      },
    });
  });

  // const comCols = _.map(module?.radios, (r) => ({
  //   label: r.short,
  //   value: (o: RadioChannelOverride[], colN: number) => {
  //     const ov = o[colN - 1];
  //     return ov?.description || ov?.frequency;
  //   },
  // }));

  return (
    <div className={className}>
      <DataTable
        columns={[
          {
            value: (o: RadioChannelOverride, colN: number, rowN: number) =>
              rowN + 1,
            label: "Chan",
            width: 1,
          },
          ...cols,
        ]}
        rows={radioRows}
      />
    </div>
  );
}

export default styled(RadioPresetTable).attrs({
  className: RadioPresetTable.name,
})`
  width: 100%;
`;
