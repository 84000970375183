import JSZip from "jszip";
import { LuaTable } from "../types";

export type LuaJobFunc = (mission: LuaTable) => void;
export type MizJobFunc = (file: JSZip) => Promise<JSZip>;

export enum JobType {
  ApplyPlans = "Apply Plans",
  InjectAirbasesKneeboard = "Inject Airbases Kneeboard",
  GroupLineupCard = "Inject Generated Lineup Cards",
  GroupCommsCard = "Inject Generated Communications Kneeboard",
  BriefingText = "Inject Briefing Text Kneeboard",
  Assets = "Inject Uploaded Kneeboards",
  RadioPresetConfig = "Configure Radio Presets",
  RadioPresetConfigKneeboard = "Inject Radio Presets Kneeboard",
  ControlPointsCard = "Inject Control Points Kneeboard",
  InjectSupportAssetsKneeboard = "Inject Support Assets Kneeboard",
  ApplyExternalData = "Apply Batch Edit Data",
  InjectBullsCompass = "Inject Bulls Compass Kneeboard",
  InjectRouteDetailCards = "Inject Route Detail Kneeboards",
}

export type GroupLineupCardSettings = {
  showTimeOnTarget: boolean;
  showDistance: boolean;
  showAltitude: boolean;
  showCoords: boolean;
  showFreqs: boolean;
};

export type AirbasesKneeboardSettings = {
  showAirbaseFreqs: boolean;
};

export type JobSettingsData = Record<JobType, JobSettings>;

export type JobSettings =
  | GroupLineupCardSettings
  | AirbasesKneeboardSettings
  | RouteDetailKneeboardSettings
  | string;

export type Job = {
  description: string;
  luaFunc?: LuaJobFunc;
  zipFunc?: MizJobFunc;
  settings?: JobSettings;
};

export type JobConfig = {
  type: JobType;
  settings?: JobSettings;
  displayName?: string;
  data?: string;
};

export type RouteDetailKneeboardSettings = {
  showBullsCompass: boolean;
  showDistances: boolean;
};
