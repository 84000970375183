import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import {
  useTaskingState,
  useTogglePlanningMode,
} from "../../contexts/TaskingMapContext";
import { FOWindow, useWindowManager } from "../../lib/services/WindowManager";
import NoShadowStateButton from "./NoShadowStateButton";

type Props = {
  className?: string;
};

function PlanningModeButton({ className }: Props) {
  const { state } = useTaskingState();
  const plan = useTogglePlanningMode();
  const wm = useWindowManager();

  const handlePlanningToggle = () => {
    if (state.planningMode) {
      plan(false);
      wm.handleClose({ id: FOWindow.Planning, type: FOWindow.Planning });
    } else {
      plan(true);
      wm.addWindow({ id: FOWindow.Planning, type: FOWindow.Planning });
    }
  };

  return (
    <Tooltip className={className} title="Toggle Planning Mode">
      <NoShadowStateButton
        active={state.planningMode}
        onClick={handlePlanningToggle}
      >
        <GpsFixedIcon />
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(PlanningModeButton).attrs({
  className: PlanningModeButton.name,
})``;
