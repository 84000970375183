import * as React from "react";
import styled from "styled-components";
import { useListSignups } from "../../hooks/signups";
import { PublishManifestDoc } from "../../lib/models/PublishManifest";
import DataTable from "../DataTable";
import Section from "../Section";

type Props = {
  className?: string;
  manifest: PublishManifestDoc;
};

function SignupTable({ className, manifest }: Props) {
  const { data: signups } = useListSignups(manifest?.id);

  if (!signups) {
    return null;
  }

  return (
    <Section title="Signups" className={className}>
      <DataTable
        columns={[
          {
            label: "Mission Role",
            value: "missionRole",
          },
          {
            label: "Discord User",
            value: "discordNickname",
          },
        ]}
        rows={signups}
      />
    </Section>
  );
}

export default styled(SignupTable).attrs({ className: SignupTable.name })``;
