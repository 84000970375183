import React from "react";
import Page from "./Page";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import Flex from "./Flex";

function LoadingPage() {
  return (
    <Page>
      <Flex wide center align>
        <CircularProgress />
      </Flex>
    </Page>
  );
}

export default styled(LoadingPage)``;
