import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { ExternalDataRecord } from "../../lib/models/ExternalDataRecord";
import {
  AirbasesKneeboardSettings,
  GroupLineupCardSettings,
  JobSettings,
  JobType,
  RouteDetailKneeboardSettings,
} from "../../lib/models/Job";
import { TaskingState } from "../../lib/models/PublishManifest";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import { ImageComposer } from "../../lib/services/ImageComposer";
import { renderAirbasesKneeboard } from "./kneeboard_airbases";
import { renderControlPointsCard } from "./kneeboard_control_points";
import { renderSupportAssetsKneeboard } from "./kneeboard_support_assets";

import _ from "lodash";
import { planItemsToPlans } from "../../hooks/frag_orders";
import { PlanItem } from "../../lib/models/PlanItem";
import { Coalition } from "../../lib/types";
import { renderBullsCompass } from "./kneeboard_bulls_compass";
import { renderMasterFrequencyCard } from "./kneeboard_frequencies";
import { renderLineupCard } from "./kneeboard_lineup";
import { renderRouteDetail } from "./kneeboard_route_detail";
import {
  renderBriefingTextKneeboard,
  renderCommsCard,
} from "./kneeboard_utils";

export async function renderKneeboard(
  j: JobType,
  tasking: TaskingState,
  jobData: {
    group?: GroupWithPlan;
    title?: string;
    radioConfig?: RadioPresetConfig;
  },
  settings: JobSettings,
  externalData?: ExternalDataRecord[],
  planItems?: PlanItem[]
) {
  let composer: ImageComposer;

  const p = planItemsToPlans(Coalition.Blue, [jobData.group], planItems);

  if (p) {
    _.set(jobData, ["group", "plan"], _.first(p));
  }

  switch (j) {
    case JobType.InjectAirbasesKneeboard:
      composer = await renderAirbasesKneeboard(
        tasking,
        settings as AirbasesKneeboardSettings
      );

      break;

    case JobType.InjectSupportAssetsKneeboard:
      composer = await renderSupportAssetsKneeboard(tasking);
      break;

    case JobType.GroupLineupCard:
      composer = await renderLineupCard(
        tasking,
        jobData.group,
        settings as GroupLineupCardSettings,
        jobData.radioConfig as string,
        externalData,
        planItems
      );

      break;

    case JobType.GroupCommsCard:
      composer = await renderCommsCard(jobData.group);

      break;
    case JobType.BriefingText:
      composer = await renderBriefingTextKneeboard(tasking, jobData.title);

      break;

    case JobType.RadioPresetConfigKneeboard:
      composer = await renderMasterFrequencyCard(
        JSON.parse(jobData.radioConfig as string) as RadioPresetConfig
      );

      break;

    case JobType.ControlPointsCard:
      composer = await renderControlPointsCard(tasking);

      break;

    case JobType.InjectBullsCompass:
      composer = await renderBullsCompass(tasking);

      break;

    case JobType.InjectRouteDetailCards:
      composer = await renderRouteDetail(
        tasking,
        jobData.group,
        settings as RouteDetailKneeboardSettings
      );

      break;
    default:
      break;
  }

  return composer;
}

export async function getPreview(
  j: JobType,
  tasking: TaskingState,
  jobData: {
    group?: GroupWithPlan;
    title?: string;
    radioConfig?: RadioPresetConfig;
  },
  settings: JobSettings,
  externalData?: ExternalDataRecord[],
  planItems?: PlanItem[]
) {
  const cmp = await renderKneeboard(
    j,
    tasking,
    jobData,
    settings,
    externalData,
    planItems
  );

  return await cmp.renderToURL();
}
