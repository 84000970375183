import React, { createContext, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useBundleClient } from "../contexts/BundleClientContext";
import { useDiscordClient } from "../contexts/DiscordClientContext";
import {
  useGetCurrentManifest,
  usePublishReader,
} from "../contexts/PublishReaderContext";
import { useUser } from "../contexts/UserContext";
import { SignupRecord, SignupRecordDoc } from "../lib/models/SignupRecord";
import { SignupAuthorizerImpl } from "../lib/services/SignupAuthorizer";
import {
  PublicSignupClient,
  SignupClient,
} from "../lib/services/SignupManager";

const SignupClientContext = createContext<PublicSignupClient>(null);

export function useSignupClient() {
  return useContext(SignupClientContext);
}

export function SignupClientProvider({
  api,
  children,
}: {
  api: SignupClient;
  children: React.ReactNode;
}) {
  return (
    <SignupClientContext.Provider value={api}>
      {children}
    </SignupClientContext.Provider>
  );
}

export function useManifestHasSignupsEnabled() {
  const { data: m } = useGetCurrentManifest();

  return m?.signupsEnabled;
}

export function usePublicListSignupRules() {
  const { data: m } = useGetCurrentManifest();

  return m?.signupRules;
}

const SignupsQueryKey = "signups";

export function useSubmitSignup(manifestID: string) {
  const api = useSignupClient();
  const qc = useQueryClient();

  return (record: SignupRecord, discordNickName: string) =>
    api
      .signup(manifestID, record, discordNickName)
      .then(() => qc.invalidateQueries(SignupsQueryKey));
}

export function useRemoveSignup() {
  const api = useSignupClient();
  const qc = useQueryClient();

  return (record: SignupRecordDoc) =>
    api.remove(record.id).then(() => qc.invalidateQueries(SignupsQueryKey));
}

export function useListSignupRoles() {
  const pub = usePublishReader();
  const discord = useDiscordClient();
  const { currentUser } = useUser();
  const bc = useBundleClient();
  const { data: m } = useGetCurrentManifest();
  const authorizer = new SignupAuthorizerImpl(pub, discord, bc);

  return useQuery(["signup-authorizer", m?.id], () => {
    if (!m) {
      return Promise.resolve([]);
    }

    return authorizer.getAvailableRoles(currentUser?.id, m);
  });
}

export function useListSignups(manifestID: string) {
  const api = useSignupClient();

  return useQuery<SignupRecordDoc[]>([SignupsQueryKey, manifestID], () => {
    if (!manifestID) {
      return Promise.resolve([]);
    }
    return api.list(manifestID);
  });
}
