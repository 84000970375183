import _ from "lodash";
import { Duration } from "luxon";
import { Collection, Feature, Map } from "ol";
import { Geometry, Point } from "ol/geom";
import { Modify } from "ol/interaction";
import { ModifyEvent } from "ol/interaction/Modify";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import React from "react";
import { waypointStyle } from "../../components/MapCanvas/RouteLayer";
import {
  convertFeatureToDCSWaypoint,
  GroupWithPlan,
} from "../../contexts/PlanManagerContext";
import {
  TaskingMapActions,
  TaskingMapActionTypes,
} from "../../contexts/TaskingMapContext";
import { addWaypointDropInteraction } from "../layers/interactions/modify_waypoints";
import { convertDCSToLatLong, convertLatLongToDCS, Theater } from "../map";
import { getWaypointDistance } from "../measurements";
import { PlanStatus } from "../models/Plan";
import { getDistanceFromLatLonInNautical } from "../time";
import {
  AltitudeType,
  DCSGroupData,
  DCSWaypoint,
  FeatureClass,
} from "../types";

// @ts-ignore
import { APIUser } from "discord-api-types/v10";
import "ol/ol.css";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import { PlanItemType } from "../models/PlanItem";
import { PublishManifest } from "../models/PublishManifest";
import PlanWaypointManager from "./PlanManagerV2/PlanWaypointManager";

function calcETA(f: Feature<Point>, speed: number, ary: Feature<Point>[]) {
  const num = f.get("number");
  const prev = _.find(ary, (f2) => f2.get("number") === num - 1);
  if (!prev) {
    return 0;
  }

  const [long, lat] = f.getGeometry().getCoordinates();
  const p1 = { long, lat };

  const [prevLong, prevLat] = prev?.getGeometry()?.getCoordinates();

  const dist = getDistanceFromLatLonInNautical(p1, {
    long: prevLong,
    lat: prevLat,
  });

  const defaultSpeed = speed;
  const hour = dist / defaultSpeed;
  const d = Duration.fromObject({
    hour,
  });

  return d.toMillis() / 1000 + prev.get("eta");
}

const DEFAULT_FIXED_WING_SPEED = 270;

export function setCalculatedDataOnFeature(
  theater: Theater,
  f: Feature<Point>,
  i: number,
  ary: Feature<Point>[]
) {
  const eta = calcETA(f, DEFAULT_FIXED_WING_SPEED, ary);

  const [long, lat] = f.getGeometry().getCoordinates();

  const coords = convertLatLongToDCS(theater, { long, lat });

  const all = _.map(ary, (p) => {
    const props = p.getProperties() as DCSWaypoint;
    const [long, lat] = p.getGeometry().getCoordinates();

    const { x, y } = convertLatLongToDCS(theater, { long, lat });

    return {
      ...props,
      x,
      y,
    };
  }) as DCSWaypoint[];

  const fakeWP = { number: i, ...coords };

  const { leg, total } = getWaypointDistance(theater, fakeWP, all);

  f.set("legDistance", leg);
  f.set("totalDistance", total);

  f.set("eta", eta);
}

export const configurePointFeature = (group: string, theater: Theater) => (
  f: Feature<Point>,
  i,
  ary: Feature<Point>[]
) => {
  f.set("number", i);
  f.set("group", group);
  f.set("alt", 0);
  f.set("featureClass", FeatureClass.Waypoint);
  const name = f.get("name");
  const nameIsNumber = !_.isNaN(parseInt(name));

  if (nameIsNumber || !name) {
    f.set("name", i.toString());
  }

  f.set("title", `${group} Waypoint ${i}`);

  setCalculatedDataOnFeature(theater, f, i, ary);
};

// There is a weird bug where the state gets stale.
// We return the whole waypoint here to make sure the ETA is up to date.
export type StrangeETABugReturnValue = DCSWaypoint & { waypoint: DCSWaypoint };

export interface PlanningController {
  inAddMode: boolean;
  sources: VectorSource[];

  init(theater: Theater, groups: GroupWithPlan[]): void;
  toggleAddMode(enabled: boolean, group?: string, origin?: number): void;
  toggleModify(enabled: boolean): void;
  getWaypoint(group: string, number: number): Feature<Point>;
  findWaypointForGroup(group: string, number: number): Feature<Point>;
  getWaypointsForGroup(group: string): StrangeETABugReturnValue[];
  removeWaypoint(group: string, number: number): void;
  setWaypointDetails(
    group: string,
    number: number,
    details: {
      text: string;
      altitude: number;
      altitudeType: AltitudeType;
      eta: number;
    }
  );

  getPlanningLayers(): VectorLayer<VectorSource>[];
  findSource(group: string): VectorSource;

  copyWaypoints(
    fromGroup: string,
    toGroup: string,
    unplannableGroups?: DCSGroupData[]
  ): void;

  setWaypointCoords(group: string, number: number, coords: [number, number]);
}

const sourceNameKey = "sourceName";

export class DefaultPlanningController implements PlanningController {
  map: Map;
  theater: Theater;
  dispatch: React.Dispatch<TaskingMapActions>;
  inAddMode: boolean;
  panning: false;
  panListener: any;
  rightClickListener: any;
  colorMap: Record<string, string>;

  sources: VectorSource[]; // Styled source layers, one per group. These are the source of truth for waypoints.
  draw: any; // Current draw interaction. Remove it from the map on toggleAdd(false)
  modify: Modify; // A modify interaction that hold all current features.
  layers: VectorLayer<VectorSource>[]; // Store layers just to turn them on and off

  manifest: PublishManifest;
  mgr: PlanWaypointManager;
  user: APIUser;

  onSuccess: () => void;
  onError: (e: string) => void;

  constructor(
    mgr: PlanWaypointManager,
    manifest: PublishManifest,
    onSuccess: () => void,
    onError: (e: string) => void
  ) {
    this.mgr = mgr;
    this.manifest = manifest;
    this.onSuccess = onSuccess;
    this.onError = onError;
  }

  public init(theater: Theater, groups: GroupWithPlan[]) {
    if (!this.map) {
      // Handle when the user clicks "planning mode" before the map is ready
      return;
    }
    this.sources = [];
    this.layers = [];
    this.theater = theater;

    const mapLayers = this.map.getLayers().getArray();

    for (const g of groups) {
      const layer = _.find(
        mapLayers,
        (l) => l.get("name") === g.name
      ) as VectorLayer<VectorSource>;

      if (layer) {
        const source = layer.getSource() as VectorSource;
        source.set(sourceNameKey, g.name);
        this.sources.push(source);
        this.layers.push(layer);
        layer.setStyle(waypointStyle(this.colorMap[g.name], 10, "diamond"));
      }
    }

    this.refreshModify();
  }

  public toggleAddMode(enabled: boolean, group?: string, origin?: number) {
    // Toggling addMode comes from an external source, so we need a public API.
    if (!enabled && this.draw) {
      this.map.removeInteraction(this.draw);
      delete this.draw;
      this.inAddMode = false;
      this.refreshModify();
      return;
    }

    if (enabled) {
      this.dispatch({
        type: TaskingMapActionTypes.enableWaypointDropMode,
      });
    }

    if (enabled && !this.draw) {
      const source = this.findSource(group);

      if (!source) {
        throw new Error("no source found");
      }

      this.draw = addWaypointDropInteraction(
        this.map,
        source,
        group,
        origin,
        this.theater
      );
      this.inAddMode = true;

      this.draw.on("drawend", (e) => {
        if (this.manifest.version < 3) {
          this.handlePlanModified(
            e.feature.get("group"),
            e.feature.get("number")
          );
        } else {
          // Special case for PlanManagerV2
          this.handleWaypointAdded(e.feature);
        }
      });
    }
  }

  public getWaypoint(group: string, number: number) {
    const source = this.findSource(group);
    if (!source) {
      throw new Error(`No source found for ${group}`);
    }
    return this.findWaypoint(source, number) as Feature<Point>;
  }

  findSource(group: string) {
    return _.find(this.sources, (s) => s.get(sourceNameKey) === group);
  }

  private findWaypoint(
    source: VectorSource<Feature>,
    number: number
  ): Feature<Point> {
    if (!source) {
      return null;
    }
    const features = source.getFeatures();

    const feature = _.find(features, (f) => f.get("number") === number);
    return feature as Feature<Point>;
  }

  public findWaypointForGroup(group: string, number: number) {
    return this.findWaypoint(this.findSource(group), number);
  }

  public getWaypointsForGroup(group: string): StrangeETABugReturnValue[] {
    const s = this.findSource(group);

    if (!s) {
      return null;
    }

    return _.map(s.getFeatures(), (f) =>
      f.getProperties()
    ) as StrangeETABugReturnValue[];
  }

  public async removeWaypoint(group: string, number: number) {
    if (this.manifest.version >= 3) {
      try {
        await this.mgr.removeWaypoint(this.manifest.id, group, number);
        this.onSuccess();
      } catch (e) {
        this.onError(e.message);
        return;
      }
    } else {
      this.handlePlanModified(group, number);
    }

    // Waypoints will be removed externally
    const source = this.findSource(group);
    const feature = this.findWaypoint(source, number);
    const sorted = _.sortBy(source.getFeatures(), (f) => f.get("number"));

    _.each(sorted, configurePointFeature(group, this.theater));

    source.removeFeature(feature);
  }

  public setWaypointDetails(
    group: string,
    number: number,
    details: {
      text: string;
      altitude: number;
      altitudeType: AltitudeType;
      eta: number;
    }
  ) {
    const source = this.findSource(group);

    if (!source) {
      throw new Error(`source undefined for ${group}`);
    }

    const feature = this.findWaypoint(source, number);

    if (!feature) {
      throw new Error(`Waypoint feature ${number} not found`);
    }

    feature.set("name", details.text);
    feature.set("altitude", details.altitude);
    feature.set("altitudeType", details.altitudeType);
    feature.set("eta", details.eta);

    // Handling a weird bug where the state gets stale.
    // Not sure why we store the whole waypoint...
    feature.set("waypoint", { ...feature.get("waypoint"), ETA: details.eta });

    this.handlePlanModified(group, number);
  }

  public toggleModify(enabled: boolean) {
    if (this.modify) {
      this.modify.setActive(enabled);
    }

    for (const layer of this.layers) {
      const color = this.colorMap[layer.get("name")];
      const style = enabled
        ? waypointStyle(color, 10, "diamond")
        : waypointStyle(color, 5);

      layer.setStyle(style);
    }
  }

  private refreshModify() {
    // When a waypoint is added, modified or removed,
    // remove modify from the map and add a new interaction with the current features.
    if (this.modify) {
      this.map.removeInteraction(this.modify);
      delete this.modify;
    }

    let features: Feature<Geometry>[] = [];

    for (const source of this.sources) {
      for (const feature of source.getFeatures()) {
        if (feature.get("number") !== 0) {
          features.push(feature);
        }
      }
    }

    const featuresCollection = new Collection(features);
    this.modify = new Modify({
      style: modifyStyle,
      features: featuresCollection,
    });

    this.modify.on("modifyend", (e: ModifyEvent) => {
      const f = _.first(e.features.getArray()) as Feature<Point>;

      const source = this.findSource(f.get("group"));
      const allFeatures = source.getFeatures() as Feature<Point>[];

      const prev = _.find(
        allFeatures,
        (w) => w.get("number") === f.get("number") - 1
      );

      if (prev) {
        const d = getDistanceFromLatLonInNautical(
          {
            long: prev.getGeometry().getCoordinates()[0],
            lat: prev.getGeometry().getCoordinates()[1],
          },
          {
            long: f.getGeometry().getCoordinates()[0],
            lat: f.getGeometry().getCoordinates()[1],
          }
        );

        f.set("legDistance", d);
      }

      const next = _.find(
        allFeatures,
        (w) => w.get("number") === f.get("number") + 1
      );

      if (next) {
        const d = getDistanceFromLatLonInNautical(
          {
            long: f.getGeometry().getCoordinates()[0],
            lat: f.getGeometry().getCoordinates()[1],
          },
          {
            long: next.getGeometry().getCoordinates()[0],
            lat: next.getGeometry().getCoordinates()[1],
          }
        );

        next.set("legDistance", d);
      }

      this.handlePlanModified(f.get("group"), f.get("number"));
    });

    this.map.addInteraction(this.modify);
  }

  private async v2UpdateWaypoint(
    groupName: string,
    feature: Feature<Geometry>
  ) {
    // V3 and higher uses the PlanManagerV2 to auto submit waypoints.
    if (this.manifest.version >= 3) {
      try {
        await this.mgr.upsertWaypoint(
          this.manifest.id,
          this.user,
          groupName,
          convertFeatureToDCSWaypoint(this.theater)(feature) as DCSWaypoint
        );
      } catch (e) {
        const isPlanningConflict = _.includes(
          e.message,
          "Missing or insufficient permissions"
        );

        if (isPlanningConflict) {
          const existing = await this.mgr.get(
            this.manifest.id,
            PlanItemType.Waypoint,
            groupName,
            feature.get("number").toString()
          );
          this.onError(
            "This waypoint has already been planned by another user: " +
              existing?.createdByUserName
          );

          return;
        }

        this.onError(e.message);
      }
    }
  }

  private handlePlanModified(groupName: string, number: number) {
    this.dispatch({
      type: TaskingMapActionTypes.setPlanStatus,
      groupName,
      status: PlanStatus.Draft,
    });

    if (number !== null) {
      const f = this.findWaypointForGroup(groupName, number);

      if (f) {
        this.v2UpdateWaypoint(groupName, f);
      }
    } else {
      // Need to update all waypoints in the group
      const source = this.findSource(groupName);
      const features = source.getFeatures();

      for (const f of features) {
        this.v2UpdateWaypoint(groupName, f);
      }
    }

    this.onSuccess();
  }

  private handleWaypointAdded(feature: Feature<Point>) {
    this.v2UpdateWaypoint(feature.get("group"), feature);
    this.onSuccess();
  }

  public getPlanningLayers() {
    return this.layers;
  }

  public copyWaypoints(
    fromGroup: string,
    toGroup: string,
    unplannableGroups: DCSGroupData[]
  ): void {
    const fromSource = this.findSource(fromGroup);

    let wps: Feature<Geometry>[] = [];

    if (fromSource) {
      wps = fromSource.getFeatures();
    } else {
      const waypoints = _.find(unplannableGroups, { name: fromGroup })
        .waypoints;

      wps = _.map(waypoints, (w) => {
        const { lat, long } = convertDCSToLatLong(this.theater, w);
        const geometry = new Point([long, lat]);
        return new Feature({ ...w, geometry });
      });
    }

    const toGroupSource = this.findSource(toGroup);

    if (!toGroupSource) {
      throw new Error(`No source found for ${toGroup}`);
    }

    toGroupSource.forEachFeature((f) => {
      toGroupSource.removeFeature(f);
    });

    const next = _.map(wps, (f) => f.clone()) as Feature<Point>[];

    for (const f of next) {
      f.set("group", toGroup);
      toGroupSource.addFeature(f);
    }

    const layer = this.getLayerForGroup(toGroup);

    layer.setStyle(waypointStyle(this.colorMap[toGroup], 10, "diamond"));

    this.handlePlanModified(toGroup, null);
    this.refreshModify();
  }

  private getLayerForGroup(group: string) {
    return _.find(this.layers, (l) => l.get("name") === group);
  }

  public setWaypointCoords(
    group: string,
    number: number,
    coords: [number, number]
  ) {
    const source = this.findSource(group);
    const feature = this.findWaypoint(source, number);
    feature.getGeometry().setCoordinates(coords);
    this.handlePlanModified(group, number);
  }
}

export function NewPlanningController(
  map: Map,
  dispatch: React.Dispatch<TaskingMapActions>,
  colorMap: Record<string, string>,
  manifest: PublishManifest,
  mgr: PlanWaypointManager,
  user: APIUser,
  onSuccess: () => void,
  onError: (e: string) => void
): PlanningController {
  const c = new DefaultPlanningController(mgr, manifest, onSuccess, onError);
  c.inAddMode = false;
  c.dispatch = dispatch;
  c.map = map;
  c.colorMap = colorMap;
  c.user = user;
  return c;
}

export const modifyStyle = (f: Feature) => {
  const v: Feature = _.first(f.get("features"));
  const g = v.get("group");

  return new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.7)",
      }),
      fill: new Fill({
        color: "rgba(0, 0, 0, 0.4)",
      }),
    }),
    text: new Text({
      text: `${g} - Drag to modify`,
      font: "12px Roboto,sans-serif",
      fill: new Fill({
        color: "rgba(255, 255, 255, 1)",
      }),
      backgroundFill: new Fill({
        color: "rgba(0, 0, 0, 0.7)",
      }),
      padding: [2, 2, 2, 2],
      textAlign: "left",
      offsetX: 15,
    }),
  });
};
