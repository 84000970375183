import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { Circle, LineString, Point, Polygon } from "ol/geom";
import React from "react";
import ControlMeasure from "../../components/ControlMeasureHopup/ControlMeasureHopup";
import Airbase from "../../components/FeatureHopup/Airbase";
import Bullseye from "../../components/FeatureHopup/Bullseye";
import ControlPoint from "../../components/FeatureHopup/ControlPoint";
import FlyableGroup from "../../components/FeatureHopup/FlyableGroup";
import NavPoint from "../../components/FeatureHopup/NavPoint";
import OPFORGroup from "../../components/FeatureHopup/OPFORGroup";
import Static from "../../components/FeatureHopup/Static";
import SupportAsset from "../../components/FeatureHopup/SupportAsset";
import Waypoint from "../../components/FeatureHopup/Waypoint";
import { FeatureClass } from "../types";
import { FragOrderMapFeature } from "./FragOrderMapFeature";

export class BaseFeature implements FragOrderMapFeature {
  feature: Feature<Point | LineString | Polygon> = null;

  constructor(f: Feature<Point | LineString | Polygon>) {
    this.feature = f;

    this.name = this.name.bind(this);
    this.coordinates = this.coordinates.bind(this);
    this.render = this.render.bind(this);
    this.properties = this.properties.bind(this);
    this.title = this.title.bind(this);
    this.class = this.class.bind(this);
    this.selectable = this.selectable.bind(this);
  }

  name() {
    return this.feature.get("name");
  }

  coordinates(): Coordinate {
    const geom = this.feature.getGeometry();

    if (geom.getType() === "LineString") {
      return (geom as LineString).getCoordinateAt(0.5);
    }

    if (geom.getType() === "Polygon") {
      return (geom as Polygon).getInteriorPoint().getCoordinates();
    }
    if (geom.getType() === "Circle") {
      return (geom as Circle).getCenter();
    }

    return (geom as Point).getCoordinates();
  }

  render(): React.ReactElement {
    const type: FeatureClass = this.feature.get("featureClass");

    const props = this.feature.getProperties();

    switch (type) {
      case FeatureClass.FlyableGroup:
        return React.createElement(FlyableGroup, props);

      case FeatureClass.SupportAsset:
        return React.createElement(SupportAsset, props);

      case FeatureClass.Airbase:
        return React.createElement(Airbase, props);

      case FeatureClass.OPFORGroups:
        return React.createElement(OPFORGroup, props);

      case FeatureClass.Waypoint:
        return React.createElement(Waypoint, props);

      case FeatureClass.NavPoint:
        return React.createElement(NavPoint, props);

      case FeatureClass.Static:
        return React.createElement(Static, props);

      case FeatureClass.Bullseye:
        return React.createElement(Bullseye, props);

      case FeatureClass.ControlPoint:
        return React.createElement(ControlPoint, props);

      case FeatureClass.ControlMeasure:
        return React.createElement(ControlMeasure, props);

      default:
        return null;
    }
  }

  properties(): { [x: string]: any } {
    return this.feature.getProperties();
  }

  title() {
    return this.feature.get("name");
  }

  class() {
    return this.feature.get("featureClass");
  }

  selectable() {
    // using the inverse so that this is an opt-out instead of opt-in
    const unselectable = this.feature.get("unselectable");
    return typeof unselectable === "undefined" || unselectable === false;
  }
}

export interface WaypointLike {
  group: string;
}
