import * as React from "react";
import { FragOrderClient } from "../lib/services/FragOrderClient";

type FragOrderContextType = FragOrderClient;

type Props = {
  children?: any;
  client: FragOrderClient;
};

export const FragOrderContext = React.createContext<FragOrderContextType>(
  {} as FragOrderContextType
);

export function FragOrderProvider({ client, children }: Props) {
  const value = client;

  return (
    <FragOrderContext.Provider value={value}>
      {children}
    </FragOrderContext.Provider>
  );
}
