import {
  initializeTestEnvironment,
  RulesTestEnvironment,
} from "@firebase/rules-unit-testing";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
} from "firebase/firestore";
import { createMemoryHistory } from "history";
import _ from "lodash";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import { NotificationProvider } from "../contexts/NotifcationContext";
import { UserContext } from "../contexts/UserContext";
import { convertDictionaryToJS, getMission } from "./lua";
import { MizFile } from "./models/MizFile";
import { defaultPublishOpts } from "./models/PublishManifest";
import { FragOrdersCollection } from "./services/FragOrderClient";
import { MizStorer } from "./services/MizStorer";
import { getMizMetadata, MizMetadata } from "./tasking";
const fs = require("fs");

export async function seed(
  env: RulesTestEnvironment,
  col: string,
  data: any
): Promise<DocumentReference> {
  let ref: DocumentReference;

  await env.withSecurityRulesDisabled(async (ctx) => {
    const db = ctx.firestore();

    ref = await addDoc(collection(db, col), data);
    return;
  });

  return ref;
}

export async function adminGet<T>(
  env: RulesTestEnvironment,
  col: string,
  id: string
) {
  let d: T;

  await env.withSecurityRulesDisabled(async (ctx) => {
    const db = ctx.firestore();

    const ref = doc(db, col, id);
    d = (await getDoc(ref)).data() as T;

    if (d) {
      // @ts-ignore
      d.id = ref.id;
    }
  });

  return d;
}

export async function adminDelete(
  env: RulesTestEnvironment,
  col: string,
  id: string
) {
  await env.withSecurityRulesDisabled(async (ctx) => {
    const db = ctx.firestore();

    const ref = doc(db, col, id);
    await deleteDoc(ref);
  });
}

export async function setupMiz(ms: MizStorer): Promise<MizFile> {
  const fo = {
    id: "src/lib/services/__tests__/fixtures/PlanningDemo.miz",
    name: "My Frag Order",
  };

  const miz = await ms.download(fo.id);
  const [mission, dictionary, warehouses] = await getMission(miz);
  const metadata = getMizMetadata(
    mission,
    warehouses,
    convertDictionaryToJS(dictionary)
  );

  return {
    // @ts-ignore
    file: { name: "myfile.miz", size: 0 },
    metadata,
  };
}

const projectId = process.env.FIREBASE_PROJECT_ID || "dcsmmp";

export async function initTestEnv() {
  return initializeTestEnvironment({
    projectId,
    firestore: {
      host: "localhost",
      port: 8080,
      rules: fs.readFileSync("firestore.rules", "utf8"),
    },
  });
}

export async function newFO(env: RulesTestEnvironment, userID: string) {
  const t = new Date().getTime();
  return seed(env, FragOrdersCollection, {
    name: "myfo",
    mizFileName: "mymission.miz",
    created_by_uid: userID,
    created_at_timestamp: t,
    updated_at_timestamp: t,
    mizFileSize: 0,
    mizMetadata: {} as MizMetadata,
    publishOpts: defaultPublishOpts(),
    discordGuildID: "999",
  });
}

interface Props {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
  children: React.ReactNode;
}

export default function Compose(props: Props) {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
}

export const withContext = (contexts: any[]) => {
  return (component: React.ReactElement) => {
    const tree = _.reduce(
      contexts,
      (r: any[], c) => {
        const [Ctx, props] = c;

        r.push((otherProps: any) => <Ctx {...props} {...otherProps} />);
        return r;
      },
      []
    );

    return <Compose components={tree}>{component}</Compose>;
  };
};

export function defaultContexts() {
  return [
    [QueryClientProvider, { client: new QueryClient() }],
    [NotificationProvider, {}],
    [
      UserContext.Provider,
      {
        value: {
          currentUser: { id: "6868" },
        },
      },
    ],
  ];
}

export function withDefaultContexts(
  component: React.ReactElement,
  url?: string,
  additionalContexts?: any[]
) {
  const history = createMemoryHistory();

  history.push(url || "");
  return withContext([...defaultContexts(), ...additionalContexts])(
    <Router history={history}>{component}</Router>
  );
}

export function randString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
