import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  ModalProps,
  Select,
  Typography,
} from "@mui/material";
import { APIRole } from "discord-api-types/v10";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { getLabelForModule, getTypeForGroup } from "../../lib/data/modules";
import { TaskingState } from "../../lib/models/PublishManifest";
import { SignupRoleType, SignupRule } from "../../lib/models/SignupRecord";
import DataTable from "../DataTable";
import Flex from "../Flex";
import ModalContent from "../ModalContent";
import Section from "../Section";

type Props = {
  className?: string;
  onImportSubmit: (rules: SignupRule[]) => Promise<any>;
  tasking: TaskingState;
  members: APIRole[];
  modalProps: ModalProps;
};

function ImporGroupsDialog({
  className,
  tasking,
  onImportSubmit,
  members,
  modalProps,
}: Props) {
  const [busy, setBusy] = React.useState(false);
  const { success, error } = useNotification();

  const [defaultDiscordRole, setRole] = React.useState<APIRole>(
    _.find(members, { name: "@everyone" })
  );

  const rows: SignupRule[] = _.map(tasking.plannedGroups, (group) => ({
    missionRole: group.name,
    discordID: `r_${defaultDiscordRole?.id}`,
    discordLabel: defaultDiscordRole?.name,
    signupRoleType: SignupRoleType.Aircrew,
    coalition: "Blue",
    module: getTypeForGroup(group),
    slotCount: group.units.length,
  }));

  const handleSubmit = () => {
    setBusy(true);

    onImportSubmit(rows)
      .then(() => {
        success("Signup rules imported successfully.");
        modalProps.onClose({}, "escapeKeyDown");
      })
      .catch((e) => {
        error("Error importing signup rules: " + e.message);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  if (!members) {
    return null;
  }

  return (
    <div className={className}>
      <Modal {...modalProps}>
        <ModalContent>
          <Section title="Import Roles from Miz">
            <Box my={2}>
              <Typography>
                Importing rules from Miz will create a new signup rule for each
                flyable group in the .miz file.
              </Typography>
            </Box>

            <Box marginBottom={2}>
              <Flex wide>
                <FormControl sx={{ width: 480 }}>
                  <InputLabel id="role-select-label">Discord Role</InputLabel>
                  <Select
                    labelId="role-select-label"
                    label="Discord Role"
                    variant="outlined"
                    style={{ width: "50%" }}
                    value={defaultDiscordRole?.id}
                    onChange={(e) => {
                      const m = _.find(members, { id: e.target.value });
                      setRole(m);
                    }}
                  >
                    {_.map(members, (member) => (
                      <MenuItem key={member.id} value={member.id}>
                        {member.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Select a signup role to limit signup access to these groups.
                  </FormHelperText>
                </FormControl>
              </Flex>
            </Box>
            <DataTable
              columns={[
                { value: "missionRole", label: "Group Name" },
                { value: "signupRoleType", label: "Role Type" },
                {
                  value: () => defaultDiscordRole?.name,
                  label: "Discord Role",
                },
                {
                  value: (r: SignupRule) => getLabelForModule(r.module),
                  label: "Module",
                },
                {
                  value: "slotCount",
                  label: "Slot Count",
                },
                { value: "coalition", label: "Coalition" },
              ]}
              rows={rows}
            />
            <Box marginTop={2}>
              <Flex wide between>
                <LoadingButton
                  loading={busy}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Import
                </LoadingButton>
                <Button
                  onClick={() => modalProps.onClose({}, "escapeKeyDown")}
                  variant="outlined"
                >
                  Close
                </Button>
              </Flex>
            </Box>
          </Section>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default styled(ImporGroupsDialog).attrs({
  className: ImporGroupsDialog.name,
})``;
