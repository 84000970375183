import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  useBlurFeature,
  useTaskingState,
  useToggleControlMeasureEdit,
  useUserCanDrawControlMeasures,
} from "../../contexts/TaskingMapContext";
import {
  useDeleteControlMeasure,
  useIsControlMeasureOwner,
  useUpdateControlMeasure,
} from "../../hooks/control_measures";
import { ControlMeasureDoc } from "../../lib/models/ControlMeasure";
import KeyValueTable from "../KeyValueTable";
import Editor, { FormState } from "./Editor";

type Props = {
  className?: string;
} & ControlMeasureDoc;

function ControlMeasureHopup({ className, ...controlMeasure }: Props) {
  const [submitting, setSubmitting] = React.useState(false);

  const {
    state: { editingControlMeasure },
  } = useTaskingState();
  const isOwner = useIsControlMeasureOwner();
  const canEdit = isOwner(controlMeasure) && useUserCanDrawControlMeasures();
  const blur = useBlurFeature();
  const update = useUpdateControlMeasure();
  const { success, error } = useNotification();
  const toggleGeomEdit = useToggleControlMeasureEdit();
  const remove = useDeleteControlMeasure();

  const handleRemove = React.useCallback(() => {
    if (
      window.confirm("Are you sure you want to delete this control measure?")
    ) {
      blur();
      remove(controlMeasure)
        .then(() => success("Control measure deleted"))
        .catch((err) => {
          console.error(err);
          error(err.message);
        });
    }
  }, [controlMeasure]);

  const handleSubmit = React.useCallback(
    (vals: FormState) => {
      setSubmitting(true);
      toggleGeomEdit(null);

      const next = {
        ...controlMeasure,
        ...vals,
        name: vals.name,
        color: vals.color,
        geometry: vals.geometry,
      } as ControlMeasureDoc;

      update(next)
        .then(() => {
          success("Control measure updated");
        })
        .catch((err) => {
          console.error(err);
          error(err.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [controlMeasure, editingControlMeasure]
  );

  const handleEdit = React.useCallback(() => {
    toggleGeomEdit(editingControlMeasure ? null : controlMeasure);
  }, [editingControlMeasure, controlMeasure]);

  return (
    <div className={className}>
      {!canEdit && (
        <KeyValueTable
          columns={2}
          pairs={[
            {
              key: "Geometry Type",
              value: controlMeasure.geometry.getType(),
            },
          ]}
        />
      )}
      {canEdit && controlMeasure && (
        <Editor
          submitting={submitting}
          onSubmit={handleSubmit}
          onEdit={handleEdit}
          onDelete={handleRemove}
          controlMeasure={controlMeasure}
          editing={!!editingControlMeasure}
        />
      )}
    </div>
  );
}

export default styled(ControlMeasureHopup).attrs({
  className: ControlMeasureHopup.name,
})``;
