import { APIUser } from "discord-api-types/v10";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDocs,
  Query,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { QueryClient } from "react-query";
import { docs } from "../../db_util";
import { PlanItem, PlanItemDoc, PlanItemType } from "../../models/PlanItem";

export const PlanItemCollection = "PlanItems";

export default class PlanManagerV2 {
  private db: Firestore;
  private qc?: QueryClient;

  constructor(db: Firestore, qc?: QueryClient) {
    this.db = db;
    this.qc = qc;
  }

  async list(
    manifestID: string,
    type?: PlanItemType,
    group?: string
  ): Promise<PlanItem[]> {
    const args = [
      collection(this.db, PlanItemCollection),
      where("manifestID", "==", manifestID),
    ];

    if (type) {
      args.push(where("type", "==", type));
    }

    if (group) {
      args.push(where("group", "==", group));
    }

    const q = query.call(this, ...args) as Query;

    return docs<PlanItem>(await getDocs(q));
  }

  async get(
    manifestID: string,
    type: PlanItemType,
    group: string,
    uniqID: string
  ): Promise<PlanItemDoc> {
    const q = query(
      collection(this.db, PlanItemCollection),
      where("manifestID", "==", manifestID),
      where("group", "==", group),
      where("type", "==", type),
      where("uniqID", "==", uniqID)
    );

    const querySnapshot = await getDocs(q);

    const items = docs<PlanItemDoc>(querySnapshot);

    if (items.length === 0) {
      throw new Error("PlanItem not found");
    }

    return items[0];
  }

  async add(user: APIUser, item: PlanItem) {
    validate(item);

    const t = new Date().getTime();

    const itemDoc: PlanItemDoc = {
      ...item,
      created_at_timestamp: t,
      updated_at_timestamp: t,
      created_by_uid: user.id,
      createdByUserName: user.username,
      uniqID: item.uniqID,
    };

    return addDoc(collection(this.db, PlanItemCollection), itemDoc);
  }

  async update(item: PlanItemDoc) {
    validate(item);
    const t = new Date().getTime();

    item.updated_at_timestamp = t;

    const ref = doc(this.db, PlanItemCollection, item.id);
    return await setDoc(ref, item);
  }

  async remove(item: PlanItemDoc) {
    const ref = doc(this.db, PlanItemCollection, item.id);

    return deleteDoc(ref);
  }
}

const validate = (item: PlanItem) => {
  if (!item.manifestID) {
    throw new Error("manifestID is required");
  }

  if (!item.group) {
    throw new Error("group is required");
  }

  if (!item.type) {
    throw new Error("type is required");
  }
};
