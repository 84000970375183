import _ from "lodash";
import { PublishManifestDoc, TaskingState } from "../models/PublishManifest";
import { SignupRecord, SignupRoleType } from "../models/SignupRecord";
import { BundleClient } from "./BundleClient";
import { DiscordMemberGetter } from "./DiscordClient";
import { PublishReader } from "./PublishManager";

export interface SignupGroupLister {
  getAvailableRoles(
    userID: string,
    manifest: PublishManifestDoc
  ): Promise<string[]>;
}

export interface SignupAuthorizer extends SignupGroupLister {
  authorize(
    userID: string,
    manifest: PublishManifestDoc,
    record: SignupRecord
  ): Promise<boolean>;
}

export class SignupAuthorizerImpl implements SignupAuthorizer {
  pub: PublishReader;
  discord: DiscordMemberGetter;
  bc: BundleClient;

  constructor(
    pub: PublishReader,
    discord: DiscordMemberGetter,
    bc: BundleClient
  ) {
    this.pub = pub;
    this.discord = discord;
    this.bc = bc;
  }

  private async getRuleData(userID: string, manifest: PublishManifestDoc) {
    if (!manifest) {
      throw new Error("No manifest found");
    }
    const res = await this.bc.get(manifest);
    const tasking = JSON.parse(res) as TaskingState;
    const groups = tasking.plannedGroups;
    const rules = manifest.signupRules;
    const member = await this.discord.getGuildMemberForUser(
      manifest.discordGuildID
    );

    return { groups, rules, member };
  }

  async getAvailableRoles(userID: string, manifest: PublishManifestDoc) {
    const { groups, rules, member } = await this.getRuleData(userID, manifest);

    const availableGroups: string[] = [];

    for (const group of groups) {
      const relevantRules = _.filter(
        rules,
        (r) => r.missionRole === group.name
      );

      const idMatch = _.find(relevantRules, (r) => {
        if (r.discordID.startsWith("r_")) {
          return false;
        }
        return r.discordID.replace("u_", "") === member?.user?.id;
      });

      if (idMatch) {
        availableGroups.push(group.name);
        continue;
      }

      const roleMatch = _.find(relevantRules, (r) => {
        if (r.discordID.startsWith("u_")) {
          return false;
        }

        if (r.discordLabel === "@everyone") {
          return true;
        }

        return _.includes(member?.roles, r.discordID.replace("r_", ""));
      });

      if (roleMatch) {
        availableGroups.push(group.name);
        continue;
      }
    }

    const otherRules = _.filter(
      rules,
      (r) => r.signupRoleType !== SignupRoleType.Aircrew
    );

    for (const rule of otherRules) {
      if (rule.discordLabel === "@everyone") {
        availableGroups.push(rule.missionRole);
        continue;
      }

      const idMatch = rule.discordID.replace("u_", "") === member?.user?.id;

      if (idMatch) {
        availableGroups.push(rule.missionRole);
        continue;
      }

      const roleMatch = _.includes(
        member?.roles,
        rule.discordID.replace("r_", "")
      );

      if (roleMatch) {
        availableGroups.push(rule.missionRole);
        continue;
      }
    }

    return availableGroups;
  }

  async authorize(
    userID: string,
    manifest: PublishManifestDoc,
    record: SignupRecord
  ): Promise<boolean> {
    const groups = await this.getAvailableRoles(userID, manifest);

    return _.includes(groups, record.missionRole);
  }
}
