import { Link } from "@mui/material";
import MousePosition from "ol/control/MousePosition";
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { addMousePositionControl } from "../../lib/layers/interactions/mouse_position";
import DiscordLogo from "../DiscordLogo";
import Flex from "../Flex";
import { useMap } from "./MapCanvas";
import { TerrainHeightLayer } from "./TerrainHeightLayer";

function CursorPosition({ className }) {
  const controlRef = React.useRef<MousePosition>();
  const divRef = React.useRef();
  const map = useMap();

  useEffect(() => {
    if (!map || !divRef.current) {
      return;
    }

    if (controlRef.current) {
      // Already have a control
      return;
    }

    controlRef.current = addMousePositionControl(map, divRef.current);

    return () => {
      map.removeControl(controlRef.current);
    };
  }, [divRef.current, map]);

  return (
    <Flex wide align between className={className}>
      <div className="coords-container">
        <Flex>
          <pre ref={divRef} />
        </Flex>
      </div>

      <TerrainHeightLayer />
      <Flex align between style={{ width: 64, marginRight: 20 }}>
        <Link target="_blank" href="https://discord.gg/mgdk8H9zhX">
          <DiscordLogo />
        </Link>
      </Flex>
    </Flex>
  );
}

export default styled(CursorPosition).attrs({ className: CursorPosition.name })`
  position: absolute;
  bottom: 0px;

  z-index: 3;
  background-color: white;
  /* Safari abs position + flex bug */

  .coords-container {
    padding: 4px;

    width: 100%;
    pre {
      margin: 0;
      padding: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
