import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useSoloGroup,
  useTaskingState,
  useToggleLayer,
} from "../../contexts/TaskingMapContext";
import { layerID, layerNameFromID } from "../../lib/layer_utils";
import { DCSGroupData, TaskingMapLayerName } from "../../lib/types";
import NoShadowStateButton from "./NoShadowStateButton";

type Props = {
  className?: string;
  group: DCSGroupData;
};

function SoloGroupRouteButton({ className, group }: Props) {
  const {
    state: { disabledLayers, tasking },
  } = useTaskingState();

  const focus = useSoloGroup();
  const toggle = useToggleLayer();

  const layerName = TaskingMapLayerName.PlayerGroups;

  const relevantDisabledLayers = _.filter(disabledLayers, (l) => {
    return layerNameFromID(l) === layerName;
  });

  const id = layerID(layerName, [group?.name]);

  const diff = _.difference(relevantDisabledLayers, [id]);

  const isSoloed =
    disabledLayers.length > 0 &&
    !_.includes(disabledLayers, id) &&
    diff.length === disabledLayers.length;

  const handleToggle = React.useCallback(() => {
    if (isSoloed) {
      const layers = _.map(tasking.plannedGroups, (g) => {
        return layerID(TaskingMapLayerName.PlayerGroups, [g.name]);
      });

      toggle(layers, true);
      return;
    }

    focus(group?.name);
  }, [group?.name, isSoloed]);

  if (!group) {
    return null;
  }

  return (
    <Tooltip className={className} title="Solo Route">
      <NoShadowStateButton onClick={handleToggle}>
        <SearchIcon />
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(SoloGroupRouteButton).attrs({
  className: SoloGroupRouteButton.name,
})``;
