import _ from "lodash";
import milsymbol from "milsymbol";
import { Point } from "ol/geom";
import { Fill, Icon, Style, Text as TextStyle } from "ol/style";
import * as React from "react";
import { Airbase } from "../../lib/data/airbases";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import { formatFreq } from "../../lib/radios";
import { FeatureClass, TaskingMapLayerName } from "../../lib/types";
import MapLayer, { FOMapFeature } from "./MapLayer";

type Props = {
  theater: Theater;
  bases: Airbase[];
  isOpfor: boolean;
  color?: string;
  renderFrequencies?: boolean;
};

// Used for getting the extent for the map
export const toAirbaseFeature = (theater, isOpfor) => (b: Airbase) => {
  const { long, lat } = convertDCSToLatLong(theater, { x: b.X, y: b.Z });
  const geometry = new Point([long, lat]);

  const iff = isOpfor ? "H" : "F";

  return {
    name: b.Name,
    featureClass: FeatureClass.Airbase,
    airbase: b,
    geometry,
    title: b.Name,
    freq: b.UHF || b.VHF,
    tacan: b.TACAN,
    sidc: b.UnitId ? `S${iff}SPCLCV----` : `S${iff}GPI-----H-`,
  };
};

const style = (isOpfor, color?: string, renderFrequences?: boolean) => (
  feature
) => {
  const freq = renderFrequences ? feature.get("freq") : null;

  let label = `${feature.get("name")}\n${freq ? formatFreq(freq) : ""}\n${
    feature.get("tacan") ? " " + feature.get("tacan") : ""
  }`;

  let colorOverride = color || "blue";

  if (isOpfor) {
    label = feature.get("name");
    colorOverride = "red";
  }

  return [
    new Style({
      image: new Icon({
        opacity: 1,
        src:
          "data:image/svg+xml;utf8," +
          new milsymbol.Symbol(
            feature.get("sidc"),
            {
              size: 24,
              // staffComments: feature.get("name").toUpperCase(),
            },
            { infoSize: 48 }
          ).asSVG(),
        scale: 1,
      }),
      text: new TextStyle({
        overflow: true,
        font: "bold 14px Roboto, sans-serif",
        placement: "point",
        fill: new Fill({ color: colorOverride }),
        text: label,
        offsetY: 28,
      }),
    }),
  ];
};

export default function AirbaseLayer({
  bases,
  theater,
  isOpfor,
  color,
  renderFrequencies = true,
}: Props) {
  const features: FOMapFeature[] = _.map(
    bases,
    toAirbaseFeature(theater, isOpfor)
  );

  return (
    <MapLayer
      features={features}
      style={style(isOpfor, color, renderFrequencies)}
      name={
        isOpfor ? TaskingMapLayerName.OPFORBases : TaskingMapLayerName.Bases
      }
      hideable
    />
  );
}
