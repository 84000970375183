import { Box, BoxProps } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  children?: any;
} & BoxProps;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0,
};

function Content({ className, children, ...rest }: Props) {
  return (
    <Box {...rest} sx={modalStyle} className={className}>
      {children}
    </Box>
  );
}

const Styled = styled(Content).attrs({ className: Content.name })``;

// eslint-disable-next-line
const ModalContent = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Styled {...props} />
));

export default ModalContent;
