import { FirebaseMetadata } from "../types";

export enum UserPreferenceType {
  RadioPresetConfig = "RadioPresetConfig",
}

export class UserPreference {
  name: string;
  type: UserPreferenceType;
  payload: string;
}

export type UserPreferenceDoc = UserPreference & FirebaseMetadata;
