import { APIGuild, APIGuildMember } from "discord-api-types/v10";
import * as React from "react";
import { createContext } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "../hooks/misc";
import { DiscordClient } from "../lib/services/DiscordClient";

const DiscordClientContext = createContext<DiscordClient>(null);

export default function DiscordClientProvider({
  discord,
  children,
}: {
  discord: DiscordClient;
  children: any;
}) {
  return (
    <DiscordClientContext.Provider value={discord}>
      {children}
    </DiscordClientContext.Provider>
  );
}

export const DiscordGuildQueryKey = "guilds";

export function useDiscordClient() {
  return React.useContext(DiscordClientContext);
}

const fetchOnceOpts = {
  retry: false,
  cacheTime: Infinity,
  staleTime: Infinity,
};

export function useGetDiscordGuilds() {
  const client = useDiscordClient();

  return useQuery<APIGuild[]>(
    [DiscordGuildQueryKey],
    () => client.listGuilds(),
    fetchOnceOpts
  );
}

export function useListGuildMembers(guildID) {
  const client = useDiscordClient();

  return useQuery<APIGuildMember[], Error>(
    [DiscordGuildQueryKey, guildID],
    () => client.listMembers(guildID),
    fetchOnceOpts
  );
}

export function useSearchDiscordGuildMembers(guildID: string) {
  const client = useDiscordClient();
  const [value, setValue] = React.useState<string>("");
  const debouncedValue = useDebounce<string>(value, 500);

  const { data, isLoading, error } = useQuery<APIGuildMember[], Error>(
    [DiscordGuildQueryKey, guildID, debouncedValue],
    () => {
      if (!value) {
        return null;
      }
      return client.searchMembers(guildID, debouncedValue);
    },
    fetchOnceOpts
  );

  return {
    data,
    isLoading,
    error,
    value,
    setValue,
  };
}
