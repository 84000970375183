import PaletteIcon from "@mui/icons-material/ColorLens";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import { Geometry } from "ol/geom";
import * as React from "react";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";
import { useHandleEditControlMeasure } from "../../hooks/control_measures";
import { ControlMeasureDoc } from "../../lib/models/ControlMeasure";
import Flex from "../Flex";
import StateButton from "../FragOrderNavigation/StateButton";
import PointDetailsForm from "./PointDetailsForm";

export interface FormState {
  name: string;
  color: string;
  geometry: Geometry;
  dirty: boolean;
  ident?: string;
  free?: string;
  icon?: string;
  hostile?: boolean;
  ring?: number;
}

type EditorProps = {
  onSubmit: ({ name, color, geometry }: FormState) => void;
  onEdit: () => any;
  onDelete: () => any;
  controlMeasure: ControlMeasureDoc;
  submitting: boolean;
  editing: boolean;
  className?: string;
};

const Editor = styled(
  ({
    onSubmit,
    submitting,
    onEdit,
    controlMeasure,
    editing,
    onDelete,
    className,
  }: EditorProps) => {
    const pickeAnchorRef = React.useRef(null);
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const {
      name,
      color,
      geometry,
      ident = "",
      free = "",
      icon = "",
      hostile = true,
      ring = 0,
    } = controlMeasure;

    const [formState, setState] = React.useState<FormState>({
      name,
      color,
      dirty: false,
      geometry,
      ident,
      free,
      icon,
      hostile,
      ring,
    });

    const reset = useHandleEditControlMeasure((newGeom: Geometry) => {
      setState({
        ...formState,
        dirty: true,
        geometry: newGeom,
      });
    });

    const handleSubmit = (e) => {
      e.preventDefault();

      if (onSubmit) {
        setState({
          ...formState,
          dirty: false,
        });
        onSubmit(formState);
      }
    };

    const handleCancel = () => {
      reset();
      setState({
        name: controlMeasure.name,
        color: controlMeasure.color,
        geometry: controlMeasure.geometry,
        dirty: false,
      });
    };

    React.useEffect(() => {
      // Reset the form state if the control measure changes
      setState({
        ...formState,
        name,
        color,
        geometry,
        dirty: false,
        ident,
        free,
        icon,
        hostile,
        ring,
      });
    }, [name, color]);

    return (
      <div className={className}>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <FormGroup>
              <TextField
                onChange={(e) =>
                  setState({
                    ...formState,
                    name: e.target.value,
                    dirty: true,
                  })
                }
                label="Name"
                value={formState.name}
              />
            </FormGroup>
            <Box mt={1}>
              <Flex wide align>
                <FormControl>
                  <FormLabel>Color</FormLabel>
                  <Flex align>
                    <Tooltip title="Edit Color">
                      <StateButton
                        style={{
                          boxShadow: "none",
                          border: "1px solid #ccc",
                          marginRight: 0,
                        }}
                        ref={pickeAnchorRef}
                        onClick={() => setColorPickerOpen(!colorPickerOpen)}
                      >
                        <PaletteIcon />
                      </StateButton>
                    </Tooltip>

                    <Box
                      ml={1}
                      style={{
                        display: "inline-block",
                        backgroundColor: formState.color,
                        height: 24,
                        width: 24,
                      }}
                    />
                  </Flex>

                  <Popover
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: { overflow: "hidden" },
                    }}
                    anchorEl={pickeAnchorRef.current}
                    open={colorPickerOpen}
                    onClose={() => setColorPickerOpen(false)}
                  >
                    <HexColorPicker
                      color={formState.color}
                      onChange={(newColor) => {
                        setState({
                          ...formState,
                          color: newColor,
                          dirty: true,
                        });
                      }}
                    />
                  </Popover>
                </FormControl>
                <Box ml={4}>
                  <FormControl>
                    <FormLabel>Geometry</FormLabel>
                    <Flex align>
                      <Tooltip title="Edit Geometry">
                        <StateButton
                          onClick={onEdit}
                          active={!!editing}
                          style={{
                            boxShadow: "none",
                            border: "1px solid #ccc",
                            marginRight: 0,
                          }}
                        >
                          <EditIcon />
                        </StateButton>
                      </Tooltip>
                      <Box ml={1}>{geometry.getType()}</Box>
                    </Flex>
                  </FormControl>
                </Box>
              </Flex>
            </Box>
          </Box>
          {geometry.getType() === "Point" && (
            <PointDetailsForm
              {...formState}
              onChange={(key: string, val: any) => {
                setState({
                  ...formState,
                  [key]: val,
                  dirty: true,
                });
              }}
            />
          )}

          <Box mt={2}>
            <Flex align wide>
              <LoadingButton
                loading={submitting}
                disabled={!formState.dirty}
                variant="contained"
                type="submit"
              >
                Save
              </LoadingButton>
              <Box ml={1}>
                <Button
                  disabled={!formState.dirty}
                  variant="outlined"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Box>

              <LoadingButton
                color="error"
                style={{ marginLeft: "auto" }}
                variant="outlined"
                onClick={onDelete}
              >
                Delete
              </LoadingButton>
            </Flex>
          </Box>
        </form>
      </div>
    );
  }
)``;

export default Editor;
