import * as React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ErrorPage from "../components/ErrorPage";
import LoadingPage from "../components/LoadingPage";
import TaskingMap from "../components/TaskingMap/TaskingMap";
import { useGetBundleForManifest } from "../contexts/PublishManagerContext";
import { useListManifesets } from "../contexts/PublishReaderContext";
import { TaskingMapProvider } from "../contexts/TaskingMapContext";
import { useUser } from "../contexts/UserContext";
import { useListControlMeasures } from "../hooks/control_measures";
import { plannableGroupsForUser } from "../lib/models/PublishManifest";

function PublicFragOrder({ className }) {
  const { currentUser } = useUser();

  const { id } = useParams<any>();

  const { data: manifest, isLoading, error } = useListManifesets(id);
  const { data: tasking, isLoading: isLoadingBundle } = useGetBundleForManifest(
    manifest
  );
  const isLoadingTasking = !tasking || isLoadingBundle;

  const {
    data: controlMeasures,
    isLoading: isLoadingControlMeasures,
  } = useListControlMeasures(id);

  if (isLoading || isLoadingControlMeasures || isLoadingTasking) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  return (
    <div className={className}>
      <TaskingMapProvider
        tasking={tasking}
        publish={manifest}
        plannableGroups={plannableGroupsForUser(manifest, currentUser)}
        controlMeasures={controlMeasures}
      >
        <TaskingMap />
      </TaskingMapProvider>
    </div>
  );
}

export default styled(PublicFragOrder)`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;
