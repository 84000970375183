import { Map } from "ol";
import { Extent } from "ol/extent";
import * as React from "react";

import { MapImager } from "../lib/services/MapImager";
import { MapCanvasContext } from "./MapCanvas/MapCanvas";
import { createOSM } from "./MapCanvas/OSMLayer";

type Props = {
  className?: string;
  children: any;
  extent: Extent;
  height?: number;
  width?: number;
  onMount?: (ctrl: MapImager) => void;
  disableRender?: boolean;
  zoomAdjust?: number;
};

export default function MiniMap({
  height,
  width,
  children,
  extent,
  onMount,
  disableRender,
  zoomAdjust = -0.2,
}: Props) {
  const map = React.useRef<Map>(null);
  const divRef = React.useRef<HTMLDivElement>(null);
  const [, setReady] = React.useState(false);

  React.useEffect(() => {
    if (!divRef.current || map.current) {
      return;
    }

    (async () => {
      const m = new Map({ target: divRef.current });
      const osmLayer = createOSM();
      m.addLayer(osmLayer);
      const v = m.getView();
      v.fit(extent);
      v.adjustZoom(zoomAdjust);
      map.current = m;
      m.renderSync();

      osmLayer.once("postrender", () => {
        setReady(true);
        if (onMount) {
          onMount(new MapImager(m));
        }
      });
    })();
  }, [divRef.current]);

  return (
    <MapCanvasContext.Provider value={map.current}>
      <div
        style={{
          position: disableRender ? "fixed" : "relative",
          visibility: disableRender ? "hidden" : "visible",
        }}
      >
        <div
          style={{
            height: height || "100%",
            width: width || "100%",
            position: "fixed",
          }}
          ref={divRef}
        >
          {children}
        </div>
      </div>
    </MapCanvasContext.Provider>
  );
}
