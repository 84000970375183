import { Box, Popover, PopoverProps } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import {
  AirbasesKneeboardSettings,
  GroupLineupCardSettings,
  JobSettings,
  JobType,
  RouteDetailKneeboardSettings,
} from "../../lib/models/Job";
import { defaultJobSettings } from "../../lib/services/FragOrderClient";
import AirbaseNavCardSettings from "./AirbaseNavCardSettings";
import KneeboardRouteDetailSettings from "./KneeboardRouteDetailSettings";
import LineupCardSettings from "./LineupCardSettings";

type Props = {
  className?: string;
  job: JobSettings;
  jobType: JobType;
  onSettingChange: (jobType: JobType, opts: JobSettings) => any;
} & PopoverProps;

type ComponentProps = {
  settings?: JobSettings;
  onChange: (opts: JobSettings) => void;
};

function component(j: JobType, props?: ComponentProps) {
  switch (j) {
    case JobType.GroupLineupCard:
      return (
        <LineupCardSettings
          {...props}
          settings={props.settings as GroupLineupCardSettings}
        />
      );

    case JobType.InjectAirbasesKneeboard:
      return (
        <AirbaseNavCardSettings
          {...props}
          settings={props.settings as AirbasesKneeboardSettings}
        />
      );

    case JobType.InjectRouteDetailCards:
      return (
        <KneeboardRouteDetailSettings
          {...props}
          settings={props.settings as RouteDetailKneeboardSettings}
        />
      );

    default:
      return null;
  }
}

function KneeboardSettings({ jobType, job, onSettingChange, ...rest }: Props) {
  const handleChange = (opts: JobSettings) => {
    onSettingChange(jobType, opts);
  };

  if (!job) {
    job = defaultJobSettings[jobType];
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      {...rest}
    >
      <Box p={2}>
        {component(jobType, { onChange: handleChange, settings: job })}
      </Box>
    </Popover>
  );
}

export default styled(KneeboardSettings).attrs({
  className: KneeboardSettings.name,
})``;
