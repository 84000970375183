import _ from "lodash";
import Map from "ol/Map";

import { Extent } from "ol/extent";
import "ol/ol.css";
import { useGeographic } from "ol/proj";
import * as React from "react";
import styled from "styled-components";
import {
  DrawMode,
  TaskingMapActionTypes,
  TaskingMapContext,
} from "../../contexts/TaskingMapContext";
import { eventIsRightClick } from "../../lib/layers/interactions/helpers";
import createMap from "../../lib/layers/map";
import { Theater, getCentroid } from "../../lib/map";
import FeatureHopup from "../FeatureHopup/FeatureHopup";
import CursorPosition from "./CursorPosition";
import LayerControl from "./LayerControl";

useGeographic();

export type LayerGroup = {
  title: string;
  layers: any[];
  colorized?: boolean;
};

export type Props = {
  className?: string;
  theater: Theater;
  width?: number;
  height?: number;
  initialExtent?: Extent;
  children?: any;
  offsetLeft?: number;
};

export const MapCanvasContext = React.createContext(null);

export function useMap(): Map {
  return React.useContext(MapCanvasContext);
}

function MapCanvas({
  className,
  width,
  height,
  initialExtent,
  children,
}: Props) {
  const { dispatch, state } = React.useContext(TaskingMapContext);
  const divRef = React.useRef(null);
  const map = React.useRef<Map>(null);
  const panning = React.useRef(false);

  React.useEffect(() => {
    (async () => {
      const m = await createMap(divRef.current);
      const v = m.getView();

      if (initialExtent) {
        const ne = _.map(initialExtent, (e) => e + 0);

        v.fit(ne);
        // Zoom out just a little bit to ensure the whole flight plan is visible
        v.adjustZoom(-0.2);
      } else {
        const center = getCentroid(state.tasking.theater);

        v.centerOn(center, m.getSize(), [1, 1]);
        v.adjustZoom(-2);
      }

      m.on("pointermove", (e) => {
        panning.current = e.dragging;
      });

      m.on("pointerup" as any, (e) => {
        if (eventIsRightClick(e) && !panning.current) {
          // Turn things off on right click (that isn't a pan)
          dispatch({
            type: TaskingMapActionTypes.setDrawMode,
            mode: DrawMode.None,
          });
        }
      });

      map.current = m;

      dispatch({ type: TaskingMapActionTypes.mapReady });
    })();
  }, []);

  const layerGroups = React.Children.map(children, (c) => ({
    title: c.props.name,
    layers: c.props.layers,
  }));

  const dimensions =
    width && height
      ? {
          width,
          height,
        }
      : null;

  return (
    <div className={className}>
      <MapCanvasContext.Provider value={map.current}>
        {children}
        <LayerControl layers={layerGroups} />
        <FeatureHopup mapRef={map} />
        {dimensions ? (
          <div className="fo-map" style={dimensions} ref={divRef} />
        ) : (
          <div className="fo-map" ref={divRef} />
        )}

        {/* <DrawingTools mapRef={map} /> */}
        <CursorPosition />
      </MapCanvasContext.Provider>
    </div>
  );
}

export default styled(MapCanvas).attrs({ className: MapCanvas.name })`
  background-color: white;
  position: relative;
  z-index: 0;
  width: 100%;

  .fo-map {
    height: 100vh;
    width: calc(100vw - ${(props) => props.offsetLeft || 0}px);
  }

  .ol-tooltip {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 2px;
  }

  .ruler-active {
    background-color: white;
    color: blue;
  }

  .ol-attribution {
    display: none;
  }

  .ol-zoom {
    top: 50vh;
    left: unset;
    right: 8px;
  }
`;
