import _ from "lodash";
import { Point } from "ol/geom";
import * as React from "react";
import { convertDCSToLatLong, Theater } from "../../lib/map";
import { toInstallationSymbol } from "../../lib/symbols";
import {
  DCSGroupData,
  FeatureClass,
  TaskingMapLayerName,
} from "../../lib/types";
import { milIconLayerStyle } from "./GroupLayer";
import MapLayer from "./MapLayer";

type Props = {
  theater: Theater;
  groups: DCSGroupData[];
};

export default function OPFORStatics({ theater, groups }: Props) {
  const features = _.map(groups, (g) => {
    const { long, lat } = convertDCSToLatLong(theater, {
      x: g.x,
      y: g.y,
    });

    const sidc = toInstallationSymbol(true);

    return {
      name: g.name,
      geometry: new Point([long, lat]),
      featureClass: FeatureClass.Static,
      sidc,
      group: g,
      //   elevation: unit?.alt,
    };
  });
  return (
    <MapLayer
      features={features}
      displayName="Statics"
      name={TaskingMapLayerName.OPFORStatics}
      style={milIconLayerStyle(24)}
      hideable
    />
  );
}
