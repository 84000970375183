import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useMap } from "../components/MapCanvas/MapCanvas";
import {
  DrawMode,
  TaskingMapContext,
  useSetDrawMode,
} from "../contexts/TaskingMapContext";
import addFreehand from "../lib/layers/interactions/freehand_draw";
import addMeasure from "../lib/layers/interactions/measure";
import addPointDrop from "../lib/layers/interactions/point_drop";

type DrawRef = {
  draw: any;
  clear: () => void;
};

export default function useManageDrawings() {
  const map = useMap();
  const {
    state: { drawMode },
  } = React.useContext(TaskingMapContext);
  const setDrawMode = useSetDrawMode();

  const prev = React.useRef<DrawMode>();
  // const [colorPickActive, setColorPickActive] = React.useState(false);
  const measureRef = React.useRef<DrawRef>(null);
  const pointDropRef = React.useRef<DrawRef>(null);
  const freeHandRef = React.useRef(null);

  useHotkeys("esc", () => {
    prev.current = DrawMode.None;
    // setColorPickActive(false);
    setDrawMode(DrawMode.None);
  });

  useHotkeys(
    "space",
    (e) => {
      // Pan on spacebar hold
      if (e.type === "keydown") {
        setDrawMode(DrawMode.None);
      }

      if (e.type === "keyup") {
        setDrawMode(prev.current);
      }
    },
    { keydown: true, keyup: true }
  );

  React.useEffect(() => {
    if (!map) {
      return;
    }

    const measure = addMeasure(map);
    measureRef.current = measure;
    measure.draw.setActive(false);

    const freeHand = addFreehand(map);
    freeHandRef.current = freeHand;
    freeHand.draw.setActive(false);

    const pointDrop = addPointDrop(map);
    pointDropRef.current = pointDrop;
    pointDrop.draw.setActive(false);
  }, [map]);

  React.useEffect(() => {
    switch (drawMode) {
      case DrawMode.Ruler:
        freeHandRef.current?.draw.setActive(false);
        pointDropRef.current?.draw.setActive(false);
        measureRef.current?.draw.setActive(true);
        break;
      case DrawMode.Freehand:
        freeHandRef.current?.draw.setActive(true);
        pointDropRef.current?.draw.setActive(false);
        measureRef.current?.draw.setActive(false);
        break;
      case DrawMode.PointDrop:
        freeHandRef.current?.draw.setActive(false);
        pointDropRef.current?.draw.setActive(true);
        measureRef.current?.draw.setActive(false);
        break;
      default:
        freeHandRef.current?.draw.setActive(false);
        pointDropRef.current?.draw.setActive(false);
        measureRef.current?.draw.finishDrawing();
        measureRef.current?.draw.setActive(false);
    }
  }, [drawMode]);

  // React.useEffect(() => {
  //   freeHandRef.current?.setStyle({
  //     stroke: new Stroke({
  //       color: color,
  //       width: 10,
  //     }),
  //   });
  // }, [color]);

  return () => {
    freeHandRef.current?.clear();
    measureRef.current?.clear();
    pointDropRef.current?.clear();
  };
}
