import _ from "lodash";
import * as React from "react";
import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Asset, AssetType } from "../lib/models/Asset";
import { JobType } from "../lib/models/Job";
import {
  PublishManifest,
  PublishManifestDoc,
} from "../lib/models/PublishManifest";
import { AssetManager } from "../lib/services/AssetManager";

import { getPreview } from "../components/Kneeboards/kneeboard_preview";
import { useListExternalData } from "../hooks/external_data";
import { useUpdateFragOrder } from "../hooks/frag_orders";
import { FragOrderDoc } from "../lib/models/FragOrder";
import { RadioPresetConfig } from "../lib/models/RadioPresetConfig";
import { GroupWithPlan, useGroupsWithPlans } from "./PlanManagerContext";
import { useListPlanItems } from "./PlanManagerV2";
import { useGetBundleForManifest } from "./PublishManagerContext";
import { useUser } from "./UserContext";

type KneeboardManagerContextType = AssetManager;

type Props = {
  children?: any;
  manager: AssetManager;
};

export const KneeboardManagerContext = React.createContext<
  KneeboardManagerContextType
>({} as KneeboardManagerContextType);

export function KneeboardManagerProvider({ manager, children }: Props) {
  return (
    <KneeboardManagerContext.Provider value={manager}>
      {children}
    </KneeboardManagerContext.Provider>
  );
}

export function useUploadKneeboard(fragOrder: FragOrderDoc) {
  const { currentUser } = useUser();
  const qc = useQueryClient();
  const mgr = useContext(KneeboardManagerContext);
  const update = useUpdateFragOrder();

  return async (manifests: PublishManifest[], files: File[]) => {
    const work = [];

    for (const manifest of manifests) {
      if (!_.includes(fragOrder.jobs, JobType.Assets)) {
        await update(
          {
            ...fragOrder,
            jobs: [...(fragOrder?.jobs || []), JobType.Assets],
          },
          [manifest.coalition]
        );
      }

      for (const file of files) {
        const asset: Asset = {
          manifestID: manifest.id,
          name: file.name,
          type: AssetType.Kneeboard,
          displayName: file.name,
        };

        work.push(mgr.upload(currentUser.id, asset, file));
      }
    }

    return Promise.all(work).then(() => qc.invalidateQueries([queryKey]));
  };
}

const queryKey = "kneeboards";
export function useListKneeboards(manifestID?: string) {
  const mgr = useContext(KneeboardManagerContext);

  return useQuery<Asset[]>([queryKey, manifestID], () => {
    if (!manifestID) {
      return Promise.resolve(null) as any;
    }
    return mgr.list(manifestID);
  });
}

export function useGetAssetURL(asset: Asset, manifest?: PublishManifestDoc) {
  const mgr = useContext(KneeboardManagerContext);

  const { data: tasking } = useGetBundleForManifest(manifest);

  const { data: groups } = useGroupsWithPlans(
    manifest?.fragOrderID,
    tasking?.plannedGroups
  );

  const { data: planItems } = useListPlanItems(manifest?.id);

  const { data: externalData, isLoading } = useListExternalData(
    manifest?.fragOrderID,
    manifest?.coalition
  );

  return useQuery<string, any>(
    ["asset_url", asset?.id, isLoading],
    async () => {
      if (!asset) {
        return Promise.resolve(null);
      }

      if (asset.jobType) {
        let group: GroupWithPlan;
        let radioConfig: RadioPresetConfig;

        if (asset.jobType) {
          if (
            asset.jobType === JobType.GroupCommsCard ||
            asset.jobType === JobType.GroupLineupCard ||
            asset.jobType === JobType.InjectRouteDetailCards
          ) {
            const { groupName: name } = JSON.parse(asset.jobData);
            group = _.find(groups, { name });
          }
        }

        if (asset.jobType === JobType.RadioPresetConfigKneeboard) {
          radioConfig = JSON.parse(asset.jobData);
        }

        const url = await getPreview(
          asset.jobType,
          tasking,
          {
            title: manifest?.title,
            group,
            radioConfig,
          },
          asset.jobSettings,
          externalData,
          planItems
        );

        return url;
      }

      return mgr.url(manifest.created_by_uid, asset);
    },
    { retry: false, cacheTime: Infinity, staleTime: Infinity }
  );
}

export function useRemoveAsset() {
  const qc = useQueryClient();
  const mgr = useContext(KneeboardManagerContext);

  return (a: Asset) =>
    mgr.remove(a).then(() => qc.invalidateQueries([queryKey]));
}

export function useGetAssetManager(): AssetManager {
  return useContext(KneeboardManagerContext);
}
