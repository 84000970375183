import { parse } from "csv-parse/lib/sync";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import FileDropzone from "../Dropzone";
import Flex from "../Flex";
import Page from "../Page";
import Section from "../Section";

type Props = {
  className?: string;
};

const numbers = ["lat", "lon", "x", "y", "z"];

function AirbaseData({ className }: Props) {
  const [result, setResult] = React.useState<any>(null);

  const handleDrop = async (files: File[]) => {
    const file = files[0];

    const d = await file.text();

    const result = parse(d, { columns: true });

    const keyed = _.keyBy(result, "id");

    // Uppercase each key
    const out = _.reduce(
      keyed,
      (acc, value, idx) => {
        _.each(value, (v, k) => {
          if (k === "displayName") {
            k = "name";
          }

          let val;

          if (_.includes(numbers, k)) {
            val = parseFloat(v);
          } else {
            val = v;
          }
          _.set(acc, [idx, _.capitalize(k)], val);
        });

        return acc;
      },
      {}
    );

    setResult(out);
  };

  return (
    <Page className={className}>
      <Section title="Airbase Data">
        <p>Upload airbase data files</p>
        <FileDropzone onFileDrop={handleDrop} />
        <Flex wide>
          <pre>{result && JSON.stringify(result, null, 2)}</pre>
        </Flex>
      </Section>
    </Page>
  );
}

export default styled(AirbaseData).attrs({ className: AirbaseData.name })``;
