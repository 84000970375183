import { Box, Tab, Tabs } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  GroupWithPlan,
  useSubmitPlan,
} from "../../contexts/PlanManagerContext";
import { TaskingMapContext } from "../../contexts/TaskingMapContext";
import { LaserConfig, RadioChannelOverride } from "../../lib/models/Plan";
import Flex from "../Flex";

import _ from "lodash";
import {
  isPermissionError,
  useUpsertPlanItem,
} from "../../contexts/PlanManagerV2";
import { modules } from "../../lib/data/modules";
import { PlanItem, PlanItemDoc, PlanItemType } from "../../lib/models/PlanItem";
import FuelPlanner from "./FuelPlanner";
import LaserCodeInput from "./LaserCodeInput";
import MissionNotesInput from "./MissionNotesInput";
import RadioPresetForm from "./RadioPresetForm";

type Props = {
  className?: string;
  group: GroupWithPlan;
};

const TabContent = styled(({ children, value, index, className }) => {
  if (value !== index) {
    return null;
  }

  return <div className={className}>{children}</div>;
})``;

type DataEntrySubmission = {
  notes: string | null;
  radio: RadioChannelOverride[] | null;
  laser: LaserConfig | null;
  group: GroupWithPlan | null;
};

function PlanningDataEntry({ className, group }: Props) {
  const [tab, setTab] = React.useState(0);
  const {
    state: { manifest, tasking },
    controller,
  } = React.useContext(TaskingMapContext);
  const { success, error } = useNotification();
  const upsertPlanItem = useUpsertPlanItem(manifest?.id);

  const type = _.get(group, ["units", 0, "type"]);
  const module = modules[type] || type;
  const label = _.get(module, ["label"], type);

  const submitPlan = useSubmitPlan(
    manifest,
    tasking.theater,
    controller.current
  );

  const handleDataEntrySubmit = ({
    group,
    radio,
    notes,
    laser,
  }: DataEntrySubmission) => {
    if (manifest && manifest.version >= 3) {
      let itemType: PlanItemType;
      let payload: string;

      if (radio) {
        itemType = PlanItemType.RadioPresets;
        payload = JSON.stringify(radio);
      } else if (laser) {
        itemType = PlanItemType.LaserCodes;
        payload = JSON.stringify(laser);
      } else if (notes) {
        itemType = PlanItemType.PlanNote;
        payload = JSON.stringify({ text: notes });
      }

      const data: PlanItem = {
        type: itemType,
        group: group.name,
        manifestID: manifest.id,
        uniqID: group.name,
        payload,
      };

      return upsertPlanItem(data as PlanItemDoc)
        .then(() => {
          success(`Data entry submitted for ${group.name} `);
        })
        .catch((err) => {
          if (isPermissionError(err.message)) {
            error("This item has been submitted by another user");
            return;
          }
          error(err.message);
          console.error(err);
        });
    }

    return submitPlan(group, radio, notes, laser)
      .then(() => {
        success(`Data entry submitted for ${group.name} `);
      })
      .catch((err) => {
        error(err.message);
        console.error(err);
      });
  };

  if (!group) {
    return null;
  }

  return (
    <div className={className}>
      <Box
        p={2}
        // paddingLeft={2}
        style={{ width: "100%", boxSizing: "border-box" }}
      >
        <Flex align wide between>
          <h3 style={{ marginTop: 0 }}>
            Data Entry for {group.name} - {label}
          </h3>
        </Flex>
        <Tabs
          variant="fullWidth"
          onChange={(e, val) => setTab(val)}
          value={tab}
          indicatorColor="primary"
        >
          <Tab label="Notes" />

          <Tab
            disabled={!manifest?.publishOpts.allowPlannerRadioFreqs}
            label="Radios"
          />
          <Tab
            disabled={!manifest?.publishOpts.allowPlannersLaserCodes}
            label="Laser Codes"
          />
          <Tab label="Fuel Planner" />
        </Tabs>

        <TabContent value={tab} index={0}>
          <MissionNotesInput
            plan={group?.plan}
            groupName={group.name}
            onSubmit={(notes: string) =>
              handleDataEntrySubmit({ group, radio: null, notes, laser: null })
            }
          />
        </TabContent>

        <TabContent value={tab} index={1}>
          <RadioPresetForm
            onSubmit={(ovr) =>
              handleDataEntrySubmit({
                group,
                radio: ovr,
                notes: null,
                laser: null,
              })
            }
            group={group}
          />
        </TabContent>

        <TabContent value={tab} index={2}>
          <LaserCodeInput
            onSubmit={(laser) =>
              handleDataEntrySubmit({
                group,
                radio: null,
                notes: null,
                laser,
              })
            }
            group={group}
          />
        </TabContent>

        <TabContent value={tab} index={3}>
          <FuelPlanner groupName={group.name} />
        </TabContent>
      </Box>
    </div>
  );
}

export default styled(PlanningDataEntry).attrs({
  className: PlanningDataEntry.name,
})`
  width: 100%;

  .MuiTabs-root,
  .MuiTabs-scroller {
    min-width: 640px !important;
  }

  ${RadioPresetForm} {
    overflow: auto;
  }
`;
