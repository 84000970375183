import { CircularProgress } from "@mui/material";
import qs from "query-string";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ErrorPage from "../components/ErrorPage";
import Flex from "../components/Flex";
import Page from "../components/Page";
import { useSubManager } from "../contexts/ServicesContext";
import { AppRoutes } from "../lib/types";

function StripeCallback({ className }) {
  const history = useHistory();
  const [error, setError] = React.useState(null);
  const { session_id } = qs.parse(window.location.search);
  const mgr = useSubManager();

  React.useEffect(() => {
    mgr
      .recordSessionResult(session_id as string)
      .then(() => {
        history.push(AppRoutes.UserSettings);
      })
      .catch((err) => setError(err));
  }, []);

  return (
    <Page className={className}>
      <Flex wide center>
        {error ? <ErrorPage message={error.message} /> : <CircularProgress />}
      </Flex>
    </Page>
  );
}

export default styled(StripeCallback)``;
