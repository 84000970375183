import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FOWindow } from "../../lib/services/WindowManager";
import NoShadowStateButton from "./NoShadowStateButton";

type Props = {
  className?: string;
  groupName: string;
};

function PlanningDataEntryButton({ className, groupName }: Props) {
  const history = useHistory();

  const handleToggle = React.useCallback(() => {
    const q = new URLSearchParams(window.location.search);
    q.set("window", FOWindow.DataEntry);
    q.set("group", encodeURIComponent(groupName));

    history.push({
      search: q.toString(),
      location: window.location.pathname,
    });
  }, []);

  return (
    <Tooltip className={className} title="Open Data Entry">
      <NoShadowStateButton onClick={handleToggle}>
        <EditIcon />
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(PlanningDataEntryButton).attrs({
  className: PlanningDataEntryButton.name,
})``;
