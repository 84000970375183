import { Alert, Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useIsSubscribed } from "../contexts/UserContext";
import Flex from "./Flex";
import Section from "./Section";
import SubscriptionCTA from "./Subscription/SubscriptionCTA";

type Props = {
  className?: string;
  children: any;
  blockedHeader?: string;
};

function SubscriptionGate({ className, children, blockedHeader }: Props) {
  const isSubscribed = useIsSubscribed();

  if (!isSubscribed) {
    return (
      <div className={className}>
        <Section title={blockedHeader}>
          <Flex wide center>
            <Flex align column>
              <Alert severity="warning">
                This feature is only available to Frag Orders Premium
                subscribers.
              </Alert>
              <Box p={2}>
                <SubscriptionCTA />
              </Box>
            </Flex>
          </Flex>
        </Section>
      </div>
    );
  }

  return children;
}

export default styled(SubscriptionGate).attrs({
  className: SubscriptionGate.name,
})`
  a {
    text-decoration: none;
  }
`;
