import {
  Box,
  CircularProgress,
  FormControlLabel,
  Modal,
  Switch,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  useAdminGetCurrentFragOrder,
  useUpdateFragOrder,
} from "../../hooks/frag_orders";
import { JobType } from "../../lib/models/Job";
import { RadioChannelOverride } from "../../lib/models/Plan";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import { Coalition, FreqBand } from "../../lib/types";
import DocsHelpIcon from "../DocsHelpIcon";
import Flex from "../Flex";
import ModalContent from "../ModalContent";
import RadioChannelInput from "../Planning/RadioPresetForm/RadioChannelInput";
import Section from "../Section";
import RadioHelp from "./RadioHelp";

type Props = {
  className?: string;
};

function AdminRadios({ className }: Props) {
  const [, setSubmitting] = React.useState(false);
  const [submittingPlanners, setSubmittingPlanners] = React.useState(false);
  const [radioHelpOpen, setRadioHelpOpen] = React.useState(false);
  const { success, error } = useNotification();
  const { data: fo } = useAdminGetCurrentFragOrder();
  const update = useUpdateFragOrder();

  const handleSubmit = (vals: RadioChannelOverride[][]) => {
    const jobSettings: RadioPresetConfig = {
      UHF: vals[0],
      "V/UHF": vals[1],
      VHF: vals[2],
    };

    return update(
      {
        ...fo,
        jobs: _.uniq([...(fo?.jobs || []), JobType.RadioPresetConfig]),
        // @ts-ignore
        jobSettings: {
          ...(fo?.jobSettings || {}),
          [JobType.RadioPresetConfig]: JSON.stringify(jobSettings),
        },
      },
      [Coalition.Blue]
    )
      .then(() => {
        success("Radio frequencies submited");
      })
      .catch((e) => {
        error(
          "Error submitting radio frequencies: " + e.message || "Unknown error"
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleAllowPlanners = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmittingPlanners(true);
    return update(
      {
        ...fo,
        publishOpts: {
          ...fo.publishOpts,
          allowPlannerRadioFreqs: e.target.checked,
        },
      },
      [Coalition.Blue]
    )
      .then(() => {
        success("Planner radio frequency permissions updated");
      })
      .catch((e) => {
        error(
          "Error updating planner radio frequency permissions: " + e.message ||
            "Unknown error"
        );
      })
      .finally(() => {
        setSubmittingPlanners(false);
      });
  };

  const cfg = _.get(fo, `jobSettings.${JobType.RadioPresetConfig}`, null);
  const parsed = React.useMemo(() => (cfg ? JSON.parse(cfg) : null), [cfg]);

  const presets = parsed
    ? [parsed.UHF || [], parsed["V/UHF"] || [], parsed.VHF || []]
    : null;

  return (
    <div className={className}>
      <Section
        title={
          <Flex wide align>
            <div>RadioPresets</div>
            <Box ml={1} pt="4px">
              <DocsHelpIcon
                tooltip="Radio Presets Help"
                onClick={(e) => {
                  e.preventDefault();
                  setRadioHelpOpen(true);
                }}
                to=""
              />
            </Box>
          </Flex>
        }
        actions={[
          <FormControlLabel
            key="allow-planner"
            style={{ height: 24 }}
            control={
              submittingPlanners ? (
                <CircularProgress
                  size={24}
                  style={{ marginRight: 8 }}
                  key="submitting"
                />
              ) : (
                <Switch
                  disabled={submittingPlanners}
                  onChange={handleAllowPlanners}
                  checked={fo?.publishOpts?.allowPlannerRadioFreqs || false}
                />
              )
            }
            label="Allow planners to set radio frequencies"
          />,
        ]}
      >
        <Box>
          <RadioChannelInput
            disabled={!!fo?.publishOpts?.allowPlannerRadioFreqs}
            radios={[
              {
                label: "UHF",
                presetCount: 20,
                bands: [FreqBand.UHF],
                short: "UHF",
              },
              {
                label: "V/UHF",
                presetCount: 20,
                bands: [FreqBand.UHF, FreqBand.VHF],
                short: "V/UHF",
              },
              {
                label: "VHF",
                presetCount: 20,
                bands: [FreqBand.VHF],
                short: "VHF",
              },
              // {
              //   label: "FM",
              //   presetCount: 20,
              //   bands: [FreqBand.VHF_FM],
              //   short: "FM",
              // },
            ]}
            onSubmit={handleSubmit}
            presets={presets}
            enableUserPreferenceActions
          />
        </Box>
      </Section>
      <Modal open={radioHelpOpen} onClose={() => setRadioHelpOpen(false)}>
        <ModalContent style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <RadioHelp onClose={() => setRadioHelpOpen(false)} />
        </ModalContent>
      </Modal>
    </div>
  );
}

export default styled(AdminRadios).attrs({ className: AdminRadios.name })`
  ${RadioChannelInput} {
    /* max-width: 1024px; */
  }
`;
