import _ from "lodash";
import milsymbol from "milsymbol";
import { Point } from "ol/geom";
import { Fill, Icon, Stroke, Style, Text as TextStyle } from "ol/style";
import * as React from "react";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import { toMilSymbolString } from "../../lib/symbols";
import { DCSGroupData, FeatureClass } from "../../lib/types";
import MapLayer from "./MapLayer";

export type GroupLayerProps = {
  theater: Theater;
  groups: DCSGroupData[];
  name: string;
  isOpfor?: boolean;
  size?: number;
  titleOverride?: string;
  style?: (...args: any[]) => any;
};

export const milIconLayerStyle = (size = 24, displayName?: boolean) => (
  feature
) => {
  const icon =
    "data:image/svg+xml;utf8," +
    new milsymbol.Symbol(feature.get("sidc"), {
      size,
    }).asSVG();

  return [
    new Style({
      image: new Icon({
        opacity: 1,
        src: icon,
        scale: 1,
      }),
      text: new TextStyle({
        overflow: true,
        font: 'bold 14px "Arial", "Arial Unicode MS", "sans-serif"',
        placement: "point",
        fill: new Fill({ color: "#000" }),
        text: displayName ? feature.get("name") : null,
        offsetY: 36,
      }),
    }),
    new Style({
      stroke: new Stroke({}),
    }),
  ];
};

export default function GroupLayer({
  groups,
  theater,
  name,
  isOpfor,
  size = 24,
  titleOverride = null,
  style = milIconLayerStyle(size),
}: GroupLayerProps) {
  const features = _.map(groups, (group) => {
    const { long, lat } = convertDCSToLatLong(theater, {
      x: group.x,
      y: group.y,
    });

    let title = group.name;

    if (titleOverride) {
      title = titleOverride;
    }

    return {
      name: group.name,
      geometry: new Point([long, lat]),
      featureClass: isOpfor ? FeatureClass.OPFORGroups : null,
      title,
      sidc: toMilSymbolString(
        isOpfor,
        group.category,
        group.supportType,
        _.map(group.units, "type")
      ),
      group,
    };
  });

  return <MapLayer features={features} style={style} name={name} hideable />;
}
