import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import {
  TaskingMapActionTypes,
  TaskingMapContext,
} from "../contexts/TaskingMapContext";
import { getTACAN } from "../lib/mission";
import { formatFreq } from "../lib/radios";
import { DCSGroupData, TaskingMapLayerName } from "../lib/types";
import DataTable from "./DataTable";

type Props = {
  className?: string;
  assets: DCSGroupData[];
  onClick?: (g: DCSGroupData) => void;
};

function SupportAssetsTable({ className, assets, onClick }: Props) {
  return (
    <DataTable
      size="small"
      stickyHeader
      className={className}
      columns={[
        {
          label: "Name",
          value: onClick
            ? (b) => (
                <Button
                  style={{ textAlign: "left" }}
                  onClick={(e) => {
                    e.preventDefault();
                    onClick(b);
                  }}
                >
                  {b.name}
                </Button>
              )
            : "name",
        },
        {
          label: "Frequency",
          value: (g: DCSGroupData) => formatFreq(g.channel.frequency),
        },
        {
          label: "TACAN",
          value: (g: DCSGroupData) => getTACAN(g.units[0], g),
        },
      ]}
      rows={assets}
    />
  );
}

const wrapped = (...props) => {
  const { state, dispatch } = React.useContext(TaskingMapContext);

  return (
    <SupportAssetsTable
      assets={state.tasking.supportAssets}
      onClick={(a: DCSGroupData) => {
        dispatch({
          type: TaskingMapActionTypes.focusFeature,
          layerName: TaskingMapLayerName.SupportAssets,
          name: a.name,
          otherFeatures: [],
        });
      }}
      {...props}
    />
  );
};

export default styled(wrapped).attrs({
  className: SupportAssetsTable.name,
})``;
