import { APIUser } from "discord-api-types/v10";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { modules } from "../data/modules";
import { docs } from "../db_util";
import {
  LaserConfig,
  PlanDoc,
  PlanStatus,
  RadioChannelOverride,
} from "../models/Plan";
import { Coalition, DCSWaypoint, NavTargetPoint, UnitCategory } from "../types";
import { Fetcher } from "./Fetcher";

export const PlanCollection = "Plans";

interface UpsertOpts {
  user: APIUser;
  fragOrderID: string;
  groupName: string;
  category: UnitCategory;
  moduleName: keyof typeof modules;
  coalition: Coalition;
}

export interface PlanManager {
  listPlans(fragOrderID: string): Promise<PlanDoc[]>;
  upsertPlan(
    opts: UpsertOpts,
    waypoints?: DCSWaypoint[],
    ovr?: RadioChannelOverride[],
    plannerNotes?: string,
    laserConfig?: LaserConfig,
    navPoints?: NavTargetPoint[]
  ): Promise<PlanDoc>;
  invalidatePlan(fragOrderID: string, plan: PlanDoc): Promise<PlanDoc>;
  removePlan(fragOrderID: string, plan: PlanDoc): Promise<PlanDoc>;
}

class manager {
  db: Firestore;
  fetcher: Fetcher;

  async listPlans(fragOrderID: string): Promise<PlanDoc[]> {
    const q = query(
      collection(this.db, PlanCollection),
      where("fragOrderID", "==", fragOrderID)
    );

    const querySnapshot = await getDocs(q);

    return docs<PlanDoc>(querySnapshot);
  }

  async getPlanForGroup(
    fragOrderID: string,
    groupName: string,
    userID: string
  ) {
    const plans = await this.listPlans(fragOrderID);

    return _.find(plans, {
      groupName,
      // status: PlanStatus.Submitted,
      created_by_uid: userID,
    });
  }

  async upsertPlan(
    opts: UpsertOpts,
    waypoints?: DCSWaypoint[],
    ovr?: RadioChannelOverride[],
    plannerNotes?: string,
    laserConfig?: LaserConfig,
    navPoints?: NavTargetPoint[]
  ) {
    const t = new Date().getTime();
    const existing = await this.getPlanForGroup(
      opts.fragOrderID,
      opts.groupName,
      opts.user.id
    );

    const nextDoc: PlanDoc = existing
      ? { ...existing, updated_at_timestamp: t }
      : ({
          id: null,
          coalition: opts.coalition,
          groupCategory: opts.category,
          groupName: opts.groupName,
          fragOrderID: opts.fragOrderID,
          created_by_uid: opts.user.id,
          created_at_timestamp: t,
          submitterDisplayText: opts.user.username,
          moduleName: opts.moduleName,
        } as PlanDoc);

    nextDoc.updated_at_timestamp = t;
    nextDoc.status = PlanStatus.Submitted;

    if (ovr) {
      nextDoc.radioOverrides = JSON.stringify(ovr);
    }

    if (waypoints) {
      nextDoc.waypoints = waypoints;
    }

    if (laserConfig) {
      nextDoc.laserConfig = laserConfig;
    }

    if (!_.isUndefined(plannerNotes)) {
      nextDoc.plannerNotes = plannerNotes;
    }

    if (navPoints) {
      nextDoc.navPoints = navPoints;
    }

    if (existing) {
      await setDoc(doc(this.db, PlanCollection, existing.id), nextDoc);
      return nextDoc;
    }

    const docref = await addDoc(collection(this.db, PlanCollection), nextDoc);

    return { ...nextDoc, id: docref.id };
  }

  async invalidatePlan(fragOrderID: string, plan: PlanDoc): Promise<PlanDoc> {
    const next: PlanDoc = {
      ...plan,
      updated_at_timestamp: new Date().getTime(),
      status: PlanStatus.Invalid,
    };

    return this.fetcher.req("/.netlify/functions/admin_update_plan", {
      method: "POST",
      body: JSON.stringify({ fragOrderID, plan: next }),
    });
  }

  async removePlan(fragOrderID: string, plan: PlanDoc): Promise<PlanDoc> {
    const next: PlanDoc = {
      ...plan,
      updated_at_timestamp: new Date().getTime(),
      status: PlanStatus.Deleted,
    };

    return this.fetcher.req("/.netlify/functions/admin_update_plan", {
      method: "POST",
      body: JSON.stringify({ fragOrderID, plan: next }),
    });
  }
}

export function NewPlanManager(db: Firestore, fetcher: Fetcher): PlanManager {
  const mgr = new manager();
  mgr.db = db;
  mgr.fetcher = fetcher;
  return mgr;
}
