import { createContext } from "react";
import { SubscriptionManager } from "../lib/services/SubscriptionManager";

interface ServicesContextType {
  subs: SubscriptionManager;
}

import * as React from "react";

const ServicesContext = createContext<ServicesContextType>(null);

type Props = {
  subs: SubscriptionManager;
  children?: any;
};

export function ServicesProvider({ subs, children }: Props) {
  return (
    <ServicesContext.Provider value={{ subs }}>
      {children}
    </ServicesContext.Provider>
  );
}

export function useSubManager() {
  const ctx = React.useContext(ServicesContext);
  return ctx.subs;
}
