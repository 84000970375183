import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import * as React from "react";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DataTable from "../components/DataTable";
import Flex from "../components/Flex";
import Link from "../components/Link";
import Page from "../components/Page";
import Section from "../components/Section";
import Timestamp from "../components/Timestamp";
import { useNotification } from "../contexts/NotifcationContext";
import { useListFragOrders, useRemoveFragOrder } from "../hooks/frag_orders";
import { FragOrderDoc } from "../lib/models/FragOrder";
import { AppRoutes } from "../lib/types";

function FragOrders({ className }) {
  const [page, setPage] = React.useState(0);
  const [deleteDoc, setConfirmOpen] = React.useState<FragOrderDoc | null>(null);
  const [removing, setRemoving] = React.useState(null);
  const { data, isLoading } = useListFragOrders(page);
  const rm = useRemoveFragOrder();
  const { success, error } = useNotification();

  const handleDelete = (doc: FragOrderDoc) => {
    setRemoving(doc.id);
    rm(doc)
      .then(() => {
        success(`${doc.name} removed successfully`);
        setConfirmOpen(null);
      })
      .catch((err) => error(err.message))
      .finally(() => {
        setRemoving(null);
      });
  };

  const handleConfirmOpen = (doc: FragOrderDoc) => {
    setConfirmOpen(doc);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(null);
  };

  return (
    <Page
      className={className}
      title="My Frag Orders"
      actions={
        <Flex>
          <Button
            component={
              React.forwardRef((props, ref) => (
                <Link innerRef={ref} {...props} />
              )) as any
            }
            to={AppRoutes.NewFragOrder}
            variant="contained"
          >
            Create Frag Order
          </Button>
          {/* <div style={{ marginLeft: 8 }}>
            <Button href={botAuthURL} variant="outlined">
              Add to Discord Server
            </Button>
          </div> */}
        </Flex>
      }
      loading={isLoading}
    >
      <Section>
        <DataTable
          columns={[
            {
              label: "Name",
              value: (f: FragOrderDoc) => (
                <Link to={`${AppRoutes.FragOrderDetail}/${f.id}/details`}>
                  {f.name}
                </Link>
              ),
            },
            { label: "ID", value: "id" },
            {
              label: "Created",
              value: (f: FragOrderDoc) => (
                <Timestamp time={f.created_at_timestamp} />
              ),
            },
            {
              label: "",
              value: (f: FragOrderDoc) => (
                <LoadingButton
                  loading={removing === f.id}
                  variant="outlined"
                  color="error"
                  onClick={() => handleConfirmOpen(f)}
                >
                  Delete
                </LoadingButton>
              ),
            },
          ]}
          rows={data}
        />

        <Box paddingY={1}>
          <Flex wide center>
            <IconButton disabled={page === 0} onClick={() => setPage(page - 1)}>
              <NavigateBeforeIcon fontSize="small" />
            </IconButton>
            <IconButton
              disabled={data?.length < 10}
              onClick={() => setPage(page + 1)}
            >
              <NavigateNextIcon fontSize="small" />
            </IconButton>
          </Flex>
        </Box>
      </Section>
      {/* <Box marginTop={2}>
        <Section title="Plannable Frag Orders"></Section>
      </Box> */}
      <ConfirmationDialog
        title="Delete Frag Order"
        message={`Are you sure you want to delete ${deleteDoc?.name}?`}
        onClose={handleConfirmClose}
        open={!!deleteDoc}
      >
        <Button onClick={handleConfirmClose}>Cancel</Button>
        <LoadingButton
          loading={!!removing}
          color="error"
          variant="contained"
          onClick={() => handleDelete(deleteDoc)}
          autoFocus
        >
          Delete {deleteDoc?.name}
        </LoadingButton>
      </ConfirmationDialog>
    </Page>
  );
}

export default styled(FragOrders)`
  margin-bottom: 16px;

  td {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    .MuiButton-root {
    }

    td:first-of-type {
      max-width: 16px;
    }

    td {
      padding: 8px;
    }

    h2 {
      font-size: 18px;
    }
  }
`;
