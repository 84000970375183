import * as React from "react";
import styled from "styled-components";
import { useTaskingState } from "../../contexts/TaskingMapContext";
import { convertDCSToLatLong, formatLatLong } from "../../lib/map";
import { DCSGroupData } from "../../lib/types";

type Props = {
  className?: string;
  group: DCSGroupData;
  //   elevation: number;
};

function Static({ className, group }: Props) {
  const {
    state: {
      tasking: { theater },
    },
  } = useTaskingState();

  const { long, lat } = convertDCSToLatLong(theater, {
    x: group.x,
    y: group.y,
  });

  const f = formatLatLong([long, lat]);

  return (
    <div className={className}>
      <div>
        <pre>{f.join("\n")}</pre>
      </div>
    </div>
  );
}

export default styled(Static).attrs({ className: Static.name })``;
