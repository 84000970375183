import * as lua from "../../../../vendor/lua-in-js";
import { getASTForEnv, getLuaEnv, LuaFunc, printMission } from "../../lua";
import { LuaTable } from "../../types";
import { LuaRuntime } from "./LuaRuntime";

interface Script {
  exec: any;
}

interface LuaEnv {
  parse: (script: string) => Script;
  parseFile: (path: string) => Script;
  loadLib: (name: string, value: lua.Table) => void;
}

export class JSLuaRuntime implements LuaRuntime {
  luaEnv: LuaEnv;

  init(luaStr: string, varName: string = "mission") {
    const env = getLuaEnv(luaStr, varName);

    this.luaEnv = env;
  }

  run(customFunc: LuaFunc, luaVar: string = "mission") {
    const customLib = new lua.Table({ customFunc });
    this.luaEnv.loadLib("customLib", customLib);

    this.luaEnv.parse(`customLib.customFunc(${luaVar})`).exec();
  }

  print() {
    return printMission(this.luaEnv);
  }

  client(varName: string): LuaTable {
    return getASTForEnv(this.luaEnv, varName);
  }
}
