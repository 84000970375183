import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { PriceID } from "../../lib/services/SubscriptionManager";

export default function PriceCard({
  priceTitle,
  amount,
  trialUnavailable,
  onClick,
  priceID,
  discountText,
}: {
  priceTitle: string;
  amount: string;
  trialUnavailable: boolean;
  priceID?: PriceID;
  onClick: (p: PriceID) => void;
  discountText?: string;
}) {
  return (
    <Box
      style={{
        border: "1px solid #b2b2b2",
        borderRadius: "4px",
      }}
      padding={2}
      textAlign="center"
    >
      <Box marginY={1}>
        <Typography fontWeight={600} fontSize={18}>
          {priceTitle}
        </Typography>
      </Box>
      {!trialUnavailable && (
        <Box marginX={1}>
          <Typography fontSize={24}>14 days free</Typography>
        </Box>
      )}

      {!trialUnavailable && (
        <Box marginBottom={2}>
          Then <b>{amount}</b>
        </Box>
      )}

      {trialUnavailable && (
        <Box marginBottom={2}>
          <Typography fontWeight={600} fontSize={24}>
            {amount}
          </Typography>
        </Box>
      )}

      {discountText && (
        <Box marginBottom={2}>
          <b>{discountText}</b>
        </Box>
      )}

      <Button variant="contained" onClick={() => onClick(priceID)}>
        {trialUnavailable ? "Subscribe" : "Start Trial"}
      </Button>
    </Box>
  );
}
