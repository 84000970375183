import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { docs } from "../db_util";
import { UserPreference, UserPreferenceDoc } from "../models/UserPreference";

const UserPreferenceCollection = "UserPreferences";

export class UserPreferenceManager {
  db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  async list(userID: string): Promise<UserPreferenceDoc[]> {
    const q = query(
      collection(this.db, UserPreferenceCollection),
      where("created_by_uid", "==", userID)
    );

    const snap = await getDocs(q);

    const data = await docs<UserPreferenceDoc>(snap);

    return data;
  }

  async upsert(userID: string, preference: UserPreference): Promise<any> {
    const t = new Date().getTime();

    const newDoc = {
      ...preference,
      created_by_uid: userID,
      updated_at_timestamp: t,
    } as UserPreferenceDoc;

    const rows = await this.list(userID);

    const existing = _.find(rows, { name: preference.name });

    if (!existing) {
      newDoc.created_at_timestamp = t;
      return addDoc(collection(this.db, UserPreferenceCollection), newDoc);
    }

    const updated = {
      ...existing,
      ...preference,
      updated_at_timestamp: t,
    };

    return setDoc(doc(this.db, UserPreferenceCollection, existing.id), updated);
  }

  async remove(preference: UserPreferenceDoc): Promise<void> {
    const ref = doc(this.db, UserPreferenceCollection, preference.id);

    return deleteDoc(ref);
  }
}
