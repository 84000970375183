import { Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import {
  useFocusFeature,
  useGetColorMap,
  useTaskingState,
  useToggleLayer,
} from "../../contexts/TaskingMapContext";
import { modules } from "../../lib/data/modules";
import { layerID } from "../../lib/layer_utils";
import { DCSGroupData, TaskingMapLayerName } from "../../lib/types";
import DataTable from "../DataTable";
import Link from "../Link";
import OpenDataWindow from "../NavigationButtons/OpenDataWindowButton";
import PlayerLabelControlButton from "../NavigationButtons/PlayerLayerControlButton";
import SoloGroupRouteButton from "../NavigationButtons/SoloGroupRouteButton";
import TogglePlayerLayersButton from "../NavigationButtons/TogglePlayerLayersButton";

type Props = {
  className?: string;
  groups: GroupWithPlan[] | DCSGroupData[];
  disabledLayers: string[];
  onLinkClick: (ev: React.MouseEvent, name: string) => void;
  onToggleAll: (allChecked: boolean) => void;
  onGroupSelect: (g: DCSGroupData) => void;
  layerName: TaskingMapLayerName;
};

export function PlayerGroups({ className, groups, onLinkClick }: Props) {
  const colorMap = useGetColorMap();

  return (
    <Box className={className}>
      <DataTable
        stickyHeader
        size="small"
        columns={[
          {
            label: "Name",
            // width: 25,
            value: (g) => (
              <Link
                to=""
                onClick={(ev) => {
                  ev.preventDefault();
                  onLinkClick(ev, g.name);
                }}
              >
                <Button variant="text" style={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      width: 96,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    fontSize={12}
                    fontWeight={500}
                    component="span"
                    color={colorMap[g.name]}
                  >
                    {g.name}
                  </Typography>
                </Button>
              </Link>
            ),
          },
          {
            label: "Type",
            value: (g) => _.get(modules, [g.units[0].type, "label"]),
            // width: 25,
          },
          {
            label: "",
            value: (g: DCSGroupData) => <OpenDataWindow group={g} />,
            width: 25,
          },
          {
            label: "",
            value: (g: DCSGroupData) => <SoloGroupRouteButton group={g} />,
            width: 25,
          },
          {
            label: <TogglePlayerLayersButton />,
            value: (g: DCSGroupData) => <PlayerLabelControlButton group={g} />,
            width: 25,
          },
        ]}
        rows={_.sortBy(groups, "name")}
      />
    </Box>
  );
}

function PlayerGroupsContainer({ className }) {
  const {
    state: {
      disabledLayers,
      tasking: { plannedGroups },
    },
  } = useTaskingState();
  const toggle = useToggleLayer();

  const handleLinkClick = useFocusFeature();

  const handleGroupSelect = (g: DCSGroupData) => {
    toggle([layerID(TaskingMapLayerName.PlayerGroups, [g.name])]);
  };

  const handleToggleAll = (checked: boolean) => {
    const layers = _.map(plannedGroups, (g) => {
      return layerID(TaskingMapLayerName.PlayerGroups, [g.name]);
    });

    toggle(layers, !checked);
  };

  return (
    <PlayerGroups
      onLinkClick={(ev, name) => handleLinkClick("Player Groups", name)}
      onToggleAll={handleToggleAll}
      onGroupSelect={handleGroupSelect}
      disabledLayers={disabledLayers}
      groups={plannedGroups}
      layerName={TaskingMapLayerName.PlayerGroups}
      className={className}
    />
  );
}

export default styled(PlayerGroupsContainer).attrs({
  className: PlayerGroups.name,
})`
  ${Link} {
    text-decoration: none;
  }

  thead th:nth-child(5) {
    padding-left: 4px;
    text-align: left;
  }

  td {
    button {
      margin-right: 0;
    }

    padding: 8px 4px;
  }
`;
