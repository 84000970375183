import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Box, Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGetCurrentManifest } from "../../contexts/PublishReaderContext";
import { useUser } from "../../contexts/UserContext";
import { useGetGuildMemberForUser } from "../../hooks/discord";
import {
  useListSignupRoles,
  useListSignups,
  useRemoveSignup,
  useSubmitSignup,
} from "../../hooks/signups";
import { getLabelForModule, modules } from "../../lib/data/modules";
import {
  SignupRecord,
  SignupRecordDoc,
  SignupRoleType,
  SignupRule,
} from "../../lib/models/SignupRecord";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";
import SignupCard from "./SignupCard";

type Props = {
  className?: string;
};

function moduleHasMultiCrew(m: string) {
  return _.get(modules, [m, "hasMultiCrew"], false);
}

function PublicSignup({ className }: Props) {
  const { data: manifest } = useGetCurrentManifest();
  const { currentUser } = useUser();
  const [submitting, setSubmitting] = React.useState<{
    rule: SignupRule;
    copilot: boolean;
  }>(null);
  const { success, error } = useNotification();
  const { data: guildUser } = useGetGuildMemberForUser(
    manifest?.discordGuildID
  );
  const { data: signups } = useListSignups(manifest?.id);

  const submit = useSubmitSignup(manifest?.id);
  const remove = useRemoveSignup();

  const { data: availableGroups } = useListSignupRoles();

  const handleSignup = (rule: SignupRule, copilot: boolean) => {
    setSubmitting({ rule, copilot });

    const record: SignupRecord = {
      missionRole: rule.missionRole,
      discordID: currentUser?.id,
      discordNickname: currentUser?.username,
      copilot,
      manifestID: manifest.id,
    };

    submit(record, guildUser?.nick)
      .then(() => {
        success("Signup submitted");
      })
      .catch((e) => {
        error("Error submitting signup: " + e.message);
      })
      .finally(() => {
        setSubmitting(null);
      });
  };

  const handleRemove = (record: SignupRecordDoc) => {
    const r = _.find(manifest?.signupRules, {
      missionRole: record.missionRole,
    });
    setSubmitting({ rule: r, copilot: record.copilot });

    remove(record)
      .then(() => {
        success("Signup removed");
      })
      .catch((e) => {
        error("Error removing signup: " + e.message);
      })
      .finally(() => {
        setSubmitting(null);
      });
  };

  const cardGroups = _.groupBy(manifest?.signupRules, "module");
  const signupGroups = _.groupBy(signups, "missionRole");

  return (
    <div className={className}>
      <Box p={2}>
        <Flex wide>
          <Box paddingLeft={4}>
            {_.map(cardGroups, (rules, m) => {
              return (
                <React.Fragment key={m}>
                  <Box marginY={2}>
                    <Typography
                      // sx={{ mt: 0.5, ml: 2 }}
                      color="text.secondary"
                      display="block"
                      variant="caption"
                      fontWeight={600}
                    >
                      {getLabelForModule(m) || "Other Roles"}
                    </Typography>
                    <Divider />
                  </Box>
                  <Grid container key={m} spacing={2}>
                    {_.map(rules, (r) => {
                      const signup = _.find(signups, {
                        missionRole: r.missionRole,
                        discordID: currentUser?.id,
                      });
                      const userIsSignedUp = !!signup;
                      const userIsCopilot = userIsSignedUp && signup?.copilot;

                      return (
                        <Grid item key={r.missionRole}>
                          <SignupCard
                            rule={r}
                            signups={signupGroups[r.missionRole]}
                            actions={
                              <>
                                <LoadingButton
                                  loading={
                                    submitting?.rule === r &&
                                    !submitting?.copilot
                                  }
                                  disabled={
                                    !_.includes(availableGroups, r.missionRole)
                                  }
                                  onClick={() =>
                                    userIsSignedUp
                                      ? handleRemove(signup)
                                      : handleSignup(r, false)
                                  }
                                  color={`${userIsSignedUp ? "error" : "info"}`}
                                >
                                  {userIsSignedUp
                                    ? "Remove Signup"
                                    : `Sign Up as ${
                                        r.signupRoleType ===
                                        SignupRoleType.Aircrew
                                          ? "Pilot"
                                          : "Controller"
                                      }`}
                                </LoadingButton>
                                {!userIsSignedUp &&
                                  moduleHasMultiCrew(r.module) && (
                                    <LoadingButton
                                      loading={
                                        submitting?.rule === r &&
                                        submitting?.copilot
                                      }
                                      onClick={() =>
                                        userIsSignedUp && userIsCopilot
                                          ? handleRemove(signup)
                                          : handleSignup(r, true)
                                      }
                                      disabled={
                                        !_.includes(
                                          availableGroups,
                                          r.missionRole
                                        )
                                      }
                                    >
                                      Sign Up as Co-pilot
                                    </LoadingButton>
                                  )}
                              </>
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Box>
        </Flex>
      </Box>

      {/* <DataTable
        columns={[
          { value: "name", label: "Name" },
          //   { value: "status", label: "Status" },
          {
            value: (g: DCSGroupData) => {
              return (
                <Button
                  onClick={() => handleSubmit(g)}
                  disabled={!_.includes(availableGroups, g.name)}
                >
                  Signup
                </Button>
              );
            },
            label: "",
          },
        ]}
        rows={groups}
      /> */}
    </div>
  );
}

export default styled(PublicSignup).attrs({ className: PublicSignup.name })`
  background-color: #ecf1f5;
  /* height: 100%; */

  ${KeyValueTable} {
    .MuiGrid-item {
      display: flex;
      /* width: 99%; */
      justify-content: space-between;
      padding-left: 8px;
    }
  }
`;
