import { Alert, Box, CircularProgress } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useGetAssetURL } from "../../contexts/KneeboardManagerContext";
import { Asset } from "../../lib/models/Asset";
import { PublishManifestDoc } from "../../lib/models/PublishManifest";

type Props = {
  className?: string;
  asset: Asset;
  style?: any;
  manifest?: PublishManifestDoc;
};

function AssetViewer({ className, asset, manifest, style }: Props) {
  const { data, isLoading, error } = useGetAssetURL(asset, manifest);

  return (
    <Box className={className}>
      {error && <Alert severity="error">{error.message}</Alert>}
      {isLoading && (
        <CircularProgress style={{ position: "absolute", top: 64 }} />
      )}
      {!isLoading && !error && (
        <div className={className}>
          <img style={style} src={data} />
        </div>
      )}
    </Box>
  );
}

export default styled(AssetViewer).attrs({ className: AssetViewer.name })`
  position: relative;
`;
