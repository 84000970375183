import { Button } from "@mui/material";
import { stringify } from "csv-stringify/dist/cjs/sync.cjs";
import { saveAs } from "file-saver";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useBundleClient } from "../../contexts/BundleClientContext";
import { useNotification } from "../../contexts/NotifcationContext";
import { useAdminGetCurrentManifests } from "../../hooks/frag_orders";
import { RecordType } from "../../lib/models/ExternalDataRecord";
import { TaskingState } from "../../lib/models/PublishManifest";
import { Coalition } from "../../lib/types";
import { rowsFromTasking } from "./utils";

type Props = {
  className?: string;
  coalition: Coalition;
  recordType: RecordType;
};

function DownloadTemplateButton({ className, coalition, recordType }: Props) {
  const bc = useBundleClient();
  const { data: manifests } = useAdminGetCurrentManifests();
  const { success, error } = useNotification();

  const handleDownloadTemplate = React.useCallback(async () => {
    try {
      const manifest = _.find(manifests, { coalition });
      const bundle = await bc.get(manifest);
      const tasking = JSON.parse(bundle) as TaskingState;

      if (!tasking) {
        throw new Error("No tasking found");
      }

      let rows = [];

      switch (recordType) {
        case RecordType.AircraftAdminDetails:
          rows = rowsFromTasking(tasking);
          break;

        case RecordType.GroupAdminDetails:
          // Currently unused. Left over from external data
          _.each(tasking.plannedGroups, (group) => {
            rows.push({
              groupName: group.name,
              auxFrequency: 251,
              tacanChannelLow: "11X",
              remarks: "Some remarks...",
            });
          });

          break;

        default:
          break;
      }

      const csv = stringify(rows, { header: true });

      saveAs(
        new Blob([csv], { type: "text/csv" }),
        `${recordType}Template.csv`
      );
      success("Downloaded template");
    } catch (e) {
      console.error(e);
      error(e.message);
    }
  }, [manifests, coalition, recordType]);

  return (
    <Button
      className={className}
      onClick={handleDownloadTemplate}
      variant="outlined"
    >
      Download CSV
    </Button>
  );
}

export default styled(DownloadTemplateButton).attrs({
  className: DownloadTemplateButton.name,
})``;
