import { Alert, Box } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useAdminGetCurrentFragOrder,
  useAdminGetCurrentManifests,
} from "../../hooks/frag_orders";
import PlanningManagement from "./PlanningManagement";
import { PlanningManagementProvider } from "./PlanningManagementContext";
import PlanningStatus from "./PlanningStatus";
import PlanStatusV2 from "./PlanStatusV2";

function Wrapper(props) {
  const { data: fragOrder, isLoading, error } = useAdminGetCurrentFragOrder();
  const {
    data: manifests,
    isLoading: manifestsLoading,
  } = useAdminGetCurrentManifests();

  if (isLoading || manifestsLoading) {
    return null;
  }

  if (error) {
    console.error(error);
    return <Alert severity="error">{error.message}</Alert>;
  }

  const isNewPlanItemSchema = _.some(manifests, (m) => m.version >= 3);

  return (
    <PlanningManagementProvider>
      <Box marginBottom={2}>
        {isNewPlanItemSchema ? (
          <PlanStatusV2 />
        ) : (
          <PlanningStatus fragOrder={fragOrder} />
        )}
      </Box>
      <Box marginBottom={2}>
        <PlanningManagement {...props} fragOrder={fragOrder} />
      </Box>
    </PlanningManagementProvider>
  );
}

export default styled(Wrapper).attrs({ className: Wrapper.name })``;
