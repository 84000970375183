import _ from "lodash";
import milsymbol from "milsymbol";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { circular } from "ol/geom/Polygon";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, Icon, Stroke, Style, Text } from "ol/style";
import * as React from "react";
import { useListControlMeasures } from "../../hooks/control_measures";
import { useGetCurrentManifestID } from "../../hooks/frag_orders";
import { useSyncLayers } from "../../hooks/map";
import { nauticalMilesToMeters } from "../../lib/map";
import { ControlMeasure } from "../../lib/models/ControlMeasure";
import { FeatureClass, TaskingMapLayerName } from "../../lib/types";
import { useMap } from "./MapCanvas";
import MapLayer from "./MapLayer";

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
}

const style = () => (feature: Feature) => {
  const color = hexToRgb(feature.get("color"));

  let textColor = "#fff";
  let textOffset = 0;
  let geom = feature.getGeometry();

  const styles = [];

  if (geom.getType() === "LineString") {
    textColor = "#000";
  }

  if (geom.getType() === "Point") {
    textColor = "#000";
    textOffset = 24;
    const iconStr = feature.get("icon");
    if (iconStr) {
      const icon =
        "data:image/svg+xml;utf8," +
        new milsymbol.Symbol(iconStr, {
          size: 20,
        }).asSVG();

      styles.push(
        new Style({
          image: new Icon({
            src: icon,
            scale: 1,
          }),
        })
      );
    } else {
      styles.push(
        new Style({
          image: new Circle({
            radius: 10,
            fill: new Fill({
              color,
            }),
            stroke: new Stroke({
              color,
              width: 2,
            }),
          }),
        })
      );
    }
  } else {
    const color = hexToRgb(feature.get("color"));
    const transparent = color.concat([0.5]);

    styles.push(
      new Style({
        fill: new Fill({ color: transparent }),
        stroke: new Stroke({ color, width: 2 }),
      })
    );
  }

  const ident = feature.get("ident");
  const free = feature.get("free");

  if (ident || free) {
    styles.push(
      new Style({
        text: new Text({
          font: "16px Calibri,sans-serif",
          fill: new Fill({ color: textColor }),
          text: `${ident} ${free}`,
          offsetY: -textOffset,
        }),
      })
    );
  }

  styles.push(
    new Style({
      text: new Text({
        font: "16px Calibri,sans-serif",
        fill: new Fill({ color: textColor }),
        text: feature.get("name"),
        offsetY: textOffset,
      }),
    })
  );

  return styles;
};

type Props = {
  measures: ControlMeasure[];
};

export const ControlMeasuresRenderLayer = ({ measures }: Props) => {
  return (
    <MapLayer
      features={measures}
      style={style()}
      name={TaskingMapLayerName.ControlMeasures}
    />
  );
};

export default function WrappedControlMeasuresLayer() {
  const { registerLayer } = useSyncLayers();
  const sourceRef = React.useRef<VectorSource>();

  const manifestID = useGetCurrentManifestID();
  const { data: controlMeasures } = useListControlMeasures(manifestID);

  const map = useMap();

  React.useEffect(() => {
    if (!map) {
      return;
    }

    if (!sourceRef.current) {
      sourceRef.current = new VectorSource();
    }

    const layer = new VectorLayer({
      source: sourceRef.current,
      style: style(),
    });

    layer.set("name", TaskingMapLayerName.ControlMeasures);
    layer.set("hideable", true);

    map.addLayer(layer);

    registerLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [map]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    if (controlMeasures.length === 0) {
      sourceRef.current.clear();
      return;
    }

    if (!sourceRef.current) {
      sourceRef.current = new VectorSource();
    }

    const rings = [];
    const features = _.map(controlMeasures, (cm) => {
      if (cm.ring) {
        rings.push(
          new Feature({
            filterTag: TaskingMapLayerName.ControlMeasures,
            color: cm.color,
            unselectable: true,
            geometry: circular(
              (cm.geometry as Point).getCoordinates(),
              nauticalMilesToMeters(cm.ring)
            ),
          })
        );
      }

      return new Feature({
        ...cm,
        geometry: cm.geometry,
        featureClass: FeatureClass.ControlMeasure,
        originalGeometry: cm.geometry.clone(),
      });
    });

    sourceRef.current.clear(true);

    sourceRef.current.addFeatures([...rings, ...features]);
  }, [map, controlMeasures]);

  return null;
}
