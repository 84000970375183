import * as React from "react";
import { GroupDetails } from "../../lib/models/ExternalDataRecord";
import { formatFreq, formatTACAN } from "../../lib/radios";

type Props = {
  groupDetails: GroupDetails;
  styles: any;
};

export default function KneeboardLineupGroupDetails({
  styles,
  groupDetails: details,
}: Props) {
  const formattedTacan = formatTACAN(details?.tacanChannelLow);
  const aux = formatFreq(details?.auxFrequency);

  return (
    <div>
      <table style={styles.table as any}>
        <thead>
          <tr>
            <th style={styles.th as any}>Group Name</th>
            <th style={styles.th}>Aux Freq</th>
            <th style={styles.th}>TACAN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={4} style={styles.td as any}>
              {details?.groupName}
            </td>
            <td style={styles.td as any}>{aux}</td>
            <td style={styles.td}>{formattedTacan}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
