import _ from "lodash";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { Module, modules } from "../data/modules";
import { LaserConfig } from "./Plan";

export class DCSPlayerGroup {
  private group: GroupWithPlan;

  constructor(group: GroupWithPlan) {
    this.group = group;
  }

  laserCodes(): LaserConfig {
    const arr = [];

    if (this.group.plan?.laserConfig) {
      return this.group.plan.laserConfig;
    }

    if (!_.some(this.group.units, (u) => u.aircraftProps)) {
      for (const u of this.group.units) {
        const codes = {
          LaserCode: u.laserCode.join(""),
        };
        arr.push(codes);
      }

      return arr;
    }

    for (const unit of this.group.units) {
      const t = unit.type;
      const m = modules[t];

      if (!m) {
        continue;
      }

      const codes = {};

      for (const formatter in m.laserConfigFields) {
        const f = m.laserConfigFields[formatter];
        const code = f.formatter(unit);
        if (code) {
          codes[f.fieldName] = code;
        }
      }

      arr.push(codes);
    }

    return arr;
  }

  dcsModule(): Module {
    const unit = this.group.units[0];
    const t = unit.type;
    const m = modules[t];

    if (!m) {
      return null;
    }

    return m;
  }
}
