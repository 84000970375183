import LoadingButton from "@mui/lab/LoadingButton";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  usePublishFragOrder,
  useUpdateFragOrder,
} from "../../hooks/frag_orders";
import { FragOrderDoc } from "../../lib/models/FragOrder";
import { PublishManifestDoc } from "../../lib/models/PublishManifest";
import { Coalition } from "../../lib/types";
import PublishOptionsForm, { FormState } from "../PublishOptionsForm";
import Section from "../Section";

type Props = {
  className?: string;
  fragOrder: FragOrderDoc;
  manifests: PublishManifestDoc[];
};

function getCoalitions(fs: FormState): Coalition[] {
  const arr = [];

  if (fs.publishBlue) {
    arr.push(Coalition.Blue);
  }

  if (fs.publishRed) {
    arr.push(Coalition.Red);
  }

  if (fs.publishNeutral) {
    arr.push(Coalition.Neutrals);
  }

  return arr;
}

function PublishOptions({ className, manifests, fragOrder }: Props) {
  const publish = usePublishFragOrder();
  const updateFO = useUpdateFragOrder();
  const [loading, setLoading] = React.useState(false);
  const { success, error } = useNotification();
  const isPublished = manifests && manifests.length > 0;

  const handlePublish = async (opts: FormState) => {
    setLoading(true);

    const coalitions = getCoalitions(opts);

    if (isPublished) {
      updateFO(
        {
          ...fragOrder,
          publishOpts: opts,
        },
        coalitions
      )
        .then(() => {
          success("Frag Order updated successfully");
        })
        .catch((e) => {
          console.error(e);
          error(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      publish(fragOrder, opts, coalitions)
        .then(() => {
          success("Frag Order published successfully");
        })
        .catch((e) => {
          console.error(e);
          error(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Section className={className} title="Publish Options">
      <PublishOptionsForm
        initialState={{
          ...fragOrder?.publishOpts,
          publishBlue: true,
          publishRed: !!_.find(manifests, { coalition: Coalition.Red }),
          publishNeutral: false,
        }}
        onSubmit={handlePublish}
      >
        <LoadingButton loading={loading} variant="contained" type="submit">
          {isPublished ? "Update" : "Publish"}
        </LoadingButton>
      </PublishOptionsForm>
    </Section>
  );
}

export default styled(PublishOptions).attrs({
  className: PublishOptions.name,
})``;
