import { MizMetadata } from "../tasking";
import { AppRoutes, FirebaseMetadata } from "../types";
import { JobSettingsData, JobType } from "./Job";
import { PublishOpts } from "./PublishManifest";

export class FOAsset {
  name: string;
  type: string;
}

export class FragOrder {
  id: string;
  name: string;
  mizFileName: string;
  mizFileSize: number;
  mizMetadata: MizMetadata;
  publishOpts: PublishOpts;
  discordGuildID: string;
  jobs?: JobType[];
  jobSettings?: JobSettingsData;
}

export type FragOrderDoc = FragOrder & FirebaseMetadata;

export function fragOrderNavURL(fo: FragOrderDoc) {
  return `${AppRoutes.FragOrderDetail}/${fo.id}/publish`;
}
