import _ from "lodash";
import { RadioChannelOverride } from "../../../lib/models/Plan";

export function toPresets(formState): RadioChannelOverride[][] {
  const presets: RadioChannelOverride[][] = [];

  _.each(formState, (v, k) => {
    let [radioIdx, chan, key] = k.split(".");

    if (!presets[radioIdx]) {
      presets[radioIdx] = [];
    }

    if (!presets[radioIdx][chan]) {
      presets[radioIdx][chan] = {};
    }

    if (key === "frequency") {
      presets[radioIdx][chan]["frequency"] = v;
    }

    if (key === "modulation") {
      presets[radioIdx][chan]["modulation"] = v === "FM" ? 1 : 0;
    }

    if (key === "description") {
      presets[radioIdx][chan]["description"] = v;
    }
  });

  return presets;
}
