import _ from "lodash";
import { DCSDrawingObject, LuaTable } from "./types";

export enum DCSDrawingLayers {
  Author = "Author",
  Common = "Common",
  Neutrals = "Neutrals",
  Red = "Red",
  Blue = "Blue",
}

export function getDrawingObjects(
  missionAST: LuaTable,
  layers: DCSDrawingLayers[]
): DCSDrawingObject[] {
  const m = [];
  const drawings = missionAST.strValues?.drawings?.strValues.layers;
  if (!drawings) {
    return [];
  }

  _.each(drawings.numValues, (d) => {
    if (!d) {
      return;
    }

    if (!_.includes(layers, d.strValues.name as any)) {
      return;
    }

    const cleaned = _.compact(
      _.map(
        d.strValues.objects.numValues,
        (o) =>
          o && {
            ...o.strValues,
            points: _.compact(
              _.map(o.strValues.points?.numValues, (p) => {
                return p && p.strValues;
              })
            ),
          }
      )
    );

    _.each(cleaned, (c) => m.push(c));
  });

  return m;
}
