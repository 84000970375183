import _, { each } from "lodash";
import * as lua from "../../vendor/lua-in-js";
import bases, { Airbase } from "./data/airbases";
import { Module, modules, RadioFreqRange } from "./data/modules";
import weaponIDs from "./data/weapon_ids";
import { DCSI18n } from "./lua";
import { Theater } from "./map";
import { DCSPreset } from "./radios";
import {
  AirbaseType,
  Coalition,
  DCSChannelConfig,
  DCSGroupData,
  DCSNavPoint,
  DCSUnit,
  DCSWaypoint,
  DCSZoneData,
  NavTargetPoint,
  UnitCategory,
  WaypointType,
} from "./types";

type LuaTable = {
  numValues: LuaTable[];
  strValues: {
    [key: string]: LuaTable;
  };
};

const formatPath = (addr) => {
  let path = [];
  for (const key of addr) {
    if (typeof key === "number") {
      path.push("numValues");
    } else {
      path.push("strValues");
    }
    path.push(key);
  }
  return path;
};

export const get = (table: LuaTable, addr) => {
  return _.get(table, formatPath(addr));
};

export const get2 = (table: LuaTable, addr) => {
  const result = get(table, addr);

  if (result && result.strValues && result.strValues.length > 0) {
    return result.strValues;
  }

  if (result && result.numValues && result.numValues.length > 0) {
    return _.compact(result.numValues);
  }
};

export const setChannelPresetsForUnit = (
  unit: LuaTable,
  presets: DCSPreset[]
) => {
  // @ts-ignore
  const type = unit.strValues.type as string;

  const module: Module = modules[type];

  if (!module) {
    return;
  }

  const radioCfg = module.radios;

  if (radioCfg.length === 0) {
    throw new Error(`no programmable radios found`);
  }

  for (let i = 0; i < radioCfg.length; i++) {
    const radio = get(unit, ["Radio", i + 1]);

    if (!radio) {
      throw new Error(`invalid radio index given for ${type}: index ${i}`);
    }

    for (let j = 0; j < presets[i].channels.length + 1; j++) {
      const desired = presets[i];
      const chanIdx = j + 1;
      radio.strValues.channels.numValues[chanIdx] = desired.channels[j];
      radio.strValues.modulations.numValues[chanIdx] = desired.modulations[j];

      if (radio.strValues.channelsNames) {
        radio.strValues.channelsNames.numValues[chanIdx] =
          desired.channelsNames[j];
      }
    }
  }
};

export function setChannelPresetsForRadios(
  unit: LuaTable,
  presets: DCSPreset[]
) {
  // @ts-ignore
  const type = unit.strValues.type as string;

  const m: Module = modules[type];

  if (!m) {
    console.error(`No module found for ${type}`);
    return;
  }

  const uhfPresets = presets[0];
  const vhfPresets = presets[1];
  const mixedPresets = presets[2];

  const uhfRadioIndex = m.radioPresetOrder.indexOf(RadioFreqRange.UHF);
  const hasUhfradio = uhfRadioIndex !== -1;

  const mixedRadioIndex = m.radioPresetOrder.indexOf(RadioFreqRange.MIXED);

  const hasMixedRadio = mixedRadioIndex !== -1;

  const vhfRadioIndex = m.radioPresetOrder.indexOf(RadioFreqRange.VHF);

  const hasVHFRadio = vhfRadioIndex !== -1;

  if (hasUhfradio) {
    const idx = uhfRadioIndex + 1;

    let radio = get(unit, ["Radio", idx]);
    if (!radio) {
      radio = new lua.Table({
        channels: new lua.Table(),
        modulations: new lua.Table(),
        channelsNames: new lua.Table(),
      }) as LuaTable;
      if (!unit.strValues.Radio) {
        unit.strValues.Radio = new lua.Table() as LuaTable;
      }
      unit.strValues.Radio.numValues[idx] = radio;
    }

    const isOH58 = type === "OH58D";

    for (let j = 0; j < uhfPresets.channels.length + 1; j++) {
      let chanIdx = j + 1;

      if (isOH58) {
        // Special case for OH58D.
        // The first channel is the M or manual frequency, so skip it.
        // So increment the channel number by 1
        if (chanIdx === 1) {
          console.log("skipping", uhfPresets.channels[j]);
          continue;
        }
      }

      radio.strValues.channels.numValues[chanIdx] = uhfPresets.channels[j];

      if (radio.strValues.modulations) {
        radio.strValues.modulations.numValues[chanIdx] =
          uhfPresets.modulations[j];
      }

      if (radio.strValues.channelsNames) {
        radio.strValues.channelsNames.numValues[chanIdx] =
          uhfPresets.channelsNames[j];
      }
    }
  }

  if (hasMixedRadio) {
    const idx = mixedRadioIndex + 1;

    let radio = get(unit, ["Radio", idx]);
    if (!radio) {
      radio = new lua.Table({
        channels: new lua.Table(),
        modulations: new lua.Table(),
        channelsNames: new lua.Table(),
      }) as LuaTable;
      if (!unit.strValues.Radio) {
        unit.strValues.Radio = new lua.Table() as LuaTable;
      }
      unit.strValues.Radio.numValues[idx] = radio;
    }

    for (let m = 0; m < mixedPresets.channels.length + 1; m++) {
      const chanIdx = m + 1;

      radio.strValues.channels.numValues[chanIdx] = mixedPresets.channels[m];

      if (radio.strValues.modulations) {
        radio.strValues.modulations.numValues[chanIdx] =
          mixedPresets.modulations[m];
      }

      if (radio.strValues.channelsNames) {
        radio.strValues.channelsNames.numValues[chanIdx] =
          mixedPresets.channelsNames[m];
      }
    }
  }

  if (hasVHFRadio) {
    const idx = vhfRadioIndex + 1;
    let radio = get(unit, ["Radio", idx]);
    if (!radio) {
      radio = new lua.Table({
        channels: new lua.Table(),
        modulations: new lua.Table(),
        channelsNames: new lua.Table(),
      }) as LuaTable;
      if (!unit.strValues.Radio) {
        unit.strValues.Radio = new lua.Table() as LuaTable;
      }
      unit.strValues.Radio.numValues[idx] = radio;
    }

    const isOH58 = type === "OH58D";

    for (let j = 0; j < vhfPresets.channels.length + 1; j++) {
      let chanIdx = j + 1;

      if (isOH58) {
        // Special case for OH58D.
        // The first channel is the M or manual frequency, so skip it.
        // So increment the channel number by 1
        if (chanIdx === 1) {
          continue;
        }
      }

      radio.strValues.channels.numValues[chanIdx] = vhfPresets.channels[j];

      if (radio.strValues.modulations) {
        radio.strValues.modulations.numValues[chanIdx] =
          vhfPresets.modulations[j];
      }

      if (radio.strValues.channelsNames) {
        radio.strValues.channelsNames.numValues[chanIdx] =
          vhfPresets.channelsNames[j];
      }
    }
  }
}

export const setFreqForGroup = (group: LuaTable, presets: DCSPreset[]) => {
  if (!presets) {
    throw new Error("No presets defined");
  }

  const type = group.strValues.units.numValues[1].strValues.type as any;

  // DCS ME is weird about setting the first channel of the "primary" UHF radio to the group freq.
  // Match it here, else chan 1 will get set to the lowest possible if a VHF radio is in pos[0].
  const uhfPreset = _.find(presets, (p) => {
    const highest = _.max(p.channels);
    return highest >= 225;
  });

  if (!uhfPreset) {
    return;
  }

  if (type === "OH58D") {
    // Special case for OH58D
    // The first channel is the M or manual frequency, so skip it.
    return;
  }

  group.strValues.frequency = uhfPreset.channels[0] as any;
  group.strValues.radioSet = true as any;
};

export const setAirframePresets = (
  coalition: Coalition,
  typeName: string,
  presets
) => (mission: LuaTable) => {
  // Warning, USA is hardcoded country
  const groupsAddr = ["coalition", coalition, "country", 31, "plane", "group"];
  const groups = get(mission, groupsAddr);

  for (const group of groups.numValues) {
    // Lua is 1 based,  so our arrays start at 1.
    // The first element will be undefined.
    if (!group) {
      continue;
    }
    const units = get(group, ["units"]);
    const groupType = get(units, [1, "type"]);

    if (groupType === typeName) {
      setFreqForGroup(group, presets);

      for (const unit of units.numValues) {
        if (!unit) {
          continue;
        }

        setChannelPresetsForUnit(unit, presets);
      }
    }
  }
};

export const findGroupInMission = (
  coalition: Coalition,
  category: UnitCategory,
  name: string,
  mission: LuaTable,
  dictionary: DCSI18n
) => {
  const groups = getGroupsOfType(coalition, category, mission, dictionary);

  for (const group of groups) {
    if (group.name === name) {
      return group;
    }
  }
};

export const forEachGroup = (
  mission: LuaTable,
  coalition: Coalition,
  category: UnitCategory,
  fn: (group: LuaTable) => void
) => {
  const countries = getCountriesForCoalition(coalition, mission);

  _.each(countries, (country) => {
    const groupsAddr = [category, "group"];
    const groups = get(country, groupsAddr);

    if (!groups) {
      return;
    }

    for (const group of groups.numValues) {
      if (!group) {
        continue;
      }

      fn(group);
    }
  });
};

export function getGroupInMission(
  mission: LuaTable,
  coalition: Coalition,
  category: UnitCategory,
  name: string
) {
  let group = null;

  forEachGroup(mission, coalition, category, (g) => {
    // @ts-ignore
    if ((g.strValues.name as string) === name) {
      group = g;
    }
  });

  return group;
}

export const getCountriesForCoalition = (
  coalition: Coalition,
  mission: LuaTable
) => {
  const lookup = {};
  const result = [];
  const countryLookup = get(mission, ["coalitions", coalition]);

  _.each(countryLookup.numValues, (v) => {
    if (typeof v === "undefined") {
      return;
    }

    lookup[v] = true as any;
  });

  const countries = get(mission, ["coalition", coalition, "country"]);

  each(countries.numValues, (c) => {
    if (!c) {
      return;
    }

    if (typeof lookup[c.strValues.id] !== "undefined") {
      result.push(c);
    }
  });

  return result;
};

export const setPlayerRadioPresets = (coalition, presets) => (
  mission: LuaTable
) => {
  // TODO fix this copy pase
  const countries = getCountriesForCoalition(coalition, mission);

  _.each(countries, (country) => {
    const groupsAddr = ["plane", "group"];
    const groups = get(country, groupsAddr);

    if (!groups) {
      return;
    }

    for (const group of groups.numValues) {
      if (!group) {
        continue;
      }

      setFreqForGroup(group, presets);
      const units = get(group, ["units"]);

      for (const unit of units.numValues) {
        if (!unit) {
          continue;
        }

        const skill = unit.strValues.skill;
        if (skill === "Client" || skill === "Player") {
          setChannelPresetsForUnit(unit, presets);
        }
      }
    }
  });
};

export const formatRadioData = (unit: LuaTable): DCSChannelConfig[][] => {
  const r: DCSChannelConfig[][] = [];
  const radios = get(unit, ["Radio"]);

  if (!radios) {
    // FARPs have special keys to define their radio config
    if ((unit.strValues.category as any) === "Heliports") {
      return [
        [
          {
            frequency: unit.strValues.heliport_frequency as any,
            modulation: unit.strValues.heliport_modulation as any,
            name: "",
          },
        ],
      ];
    }

    return [
      [
        {
          frequency: unit.strValues.frequency as any,
          modulation: unit.strValues.modulation as any,
          name: "",
        },
      ],
    ];
  }

  for (const radio of radios.numValues) {
    if (!radio) {
      continue;
    }

    const modulations = radio.strValues?.modulations?.numValues;
    const channels = radio?.strValues?.channels?.numValues;
    const channelsNames = radio?.strValues?.channelsNames?.numValues;

    if (!modulations || !channels) {
      continue;
    }

    const radioData: DCSChannelConfig[] = [];

    for (let c = 0; c < channels.length; c++) {
      const frequency = channels[c] as any;
      const modulation = modulations && (modulations[c] as any);
      const name = channelsNames && channelsNames[c];
      if (!frequency) {
        continue;
      }

      radioData.push({
        frequency,
        modulation,
        name,
      });
    }

    r.push(radioData);
  }

  return r;
};

const formatLaserCode = (u: LuaTable): number[] => {
  const propObj = get(u, ["AddPropAircraft"]);

  let laserCode = [];
  if (propObj) {
    // TODO: not sure why get or get2 isn't working here.
    // Needs some test coverage.
    laserCode = [
      propObj.strValues.LaserCode1000 || propObj.strValues.LGB1000,
      propObj.strValues.LaserCode100 || propObj.strValues.LGB100,
      propObj.strValues.LaserCode10 || propObj.strValues.LGB10,
      propObj.strValues.LaserCode1 || propObj.strValues.LGB1,
    ];
  }

  return laserCode;
};

function getAirdrome(g: LuaTable) {
  const points = get2(g, ["route", "points"]);

  for (const key in points) {
    const p = points[key];

    if (
      p.strValues.type === "TakeOffParking" ||
      p.strValues.type === "TakeOffParkingHot"
    ) {
      return p.strValues.airdromeId;
    }
  }
}

export const getGroupsOfType = (
  coalition: Coalition,
  category: UnitCategory,
  mission: LuaTable,
  dictionary: DCSI18n | null
): DCSGroupData[] => {
  if (!coalition) {
    throw new Error("no coalition specified");
  }
  const lookup = {};
  const result = [];
  const countryLookup = get(mission, ["coalitions", coalition]);

  _.each(countryLookup?.numValues, (v) => {
    if (typeof v === "undefined") {
      return;
    }

    lookup[v] = true as any;
  });

  const countries = get(mission, ["coalition", coalition, "country"]);

  each(countries.numValues, (c) => {
    if (!c) {
      return;
    }

    if (typeof lookup[c.strValues.id] !== "undefined") {
      if (c.strValues[category]) {
        const country = get(c, ["name"]);
        const groups = get2(c, [category, "group"]);

        _.each(groups, (g) => {
          const isPlayer =
            get(g, ["units", 1, "skill"]) === "Client" ||
            get(g, ["units", 1, "skill"]) === "Player";

          let name;

          // DCS 2.7: looks like dictionary may or may not exist
          if (dictionary) {
            name = dictionary[get(g, ["name"])];
          }

          if (!name) {
            name = get(g, ["name"]);
          }

          const firstPoint =
            g.strValues.route.strValues.points.numValues[1].strValues;

          let linkedUnitId = null;
          if (
            firstPoint.type === WaypointType.TakeOff ||
            firstPoint.type === WaypointType.TakeoffParking
          ) {
            linkedUnitId = firstPoint.linkUnit;
          }

          const tp = get2(g, ["NavTargetPoints"]);
          let navTargetPoints: NavTargetPoint[] | null = null;
          if (tp) {
            navTargetPoints = _.map(tp, (p) => ({
              index: p.strValues.index,
              textComment: p.strValues.text_comment,
              x: p.strValues.x,
              y: p.strValues.y,
            }));
          }

          const data: DCSGroupData = {
            coalition: coalition,
            category,
            country,
            name,
            channel: {
              frequency: g.strValues.frequency,
              modulation: g.strValues.modulation,
              name: "",
            },
            groupId: g.strValues.groupId,
            linkedUnitId,
            navTargetPoints,
            units: _.map(get2(g, ["units"]), (u) => {
              const laserCode = formatLaserCode(u);

              const radios = formatRadioData(u);

              let unitName;
              // 2.7 seems to have rolled back i18n?
              // Unit and group names may or may not be in the dictionary file.
              if (dictionary) {
                unitName = dictionary[u.strValues.name];

                if (!unitName) {
                  unitName = u.strValues.name;
                }
              }

              const payload = [];
              if (isPlayer) {
                if (u.strValues.payload.strValues.pylons.numValues.length > 1) {
                  const pylons = u.strValues.payload.strValues.pylons.numValues;
                  for (let i = 0; i < pylons.length; i++) {
                    const pylon = pylons[i];

                    if (!pylon) {
                      if (i === 0) {
                        continue;
                      }
                      payload.push(null);
                      continue;
                    }
                    const weapon = _.get(weaponIDs, [
                      pylon.strValues.CLSID,
                      "name",
                    ]);

                    payload.push(weapon);
                  }
                }
              }

              const props = {};
              const propObj = u.strValues.AddPropAircraft;

              if (propObj && propObj.strValues) {
                for (const p in propObj.strValues) {
                  props[p] = propObj.strValues[p];
                }
              }

              let datalinks = null;

              if (u.strValues.datalinks) {
                const links = u.strValues.datalinks;
                datalinks = {};
                for (const kind in links.strValues) {
                  datalinks[kind] = {};
                  const record = links.strValues[kind];

                  for (const key in record.strValues) {
                    const v = record.strValues[key];

                    if (key === "network") {
                      datalinks[kind]["network"] = {
                        donors: [],
                        teamMembers: [],
                      };

                      if (!v.strValues.donors) {
                        continue;
                      }

                      for (const d of v.strValues.donors.numValues) {
                        if (d) {
                          datalinks[kind].network.donors.push(d.strValues);
                        }
                      }

                      for (const tm of v.strValues.teamMembers.numValues) {
                        if (tm) {
                          datalinks[kind].network.teamMembers.push(
                            tm.strValues
                          );
                        }
                      }
                    }
                    if (key === "settings") {
                      datalinks[kind][key] = {};

                      for (const field in v.strValues) {
                        const element = v.strValues[field];

                        datalinks[kind][key][field] = element;
                      }
                    }
                  }
                }
              }

              return {
                unitId: u.strValues.unitId,
                type: get(u, ["type"]),
                x: get(u, ["x"]),
                y: get(u, ["y"]),
                name: unitName,
                callsign: get(u, ["callsign", "name"]) || null,
                laserCode,
                radios,
                payload,
                linkedUnitId,
                tailNumber: u.strValues.onboard_num,
                aircraftProps: props,
                datalinks,
                internalFuel: u?.strValues?.payload?.strValues?.fuel,
              };
            }),
            waypoints: getWaypointsForGroup(g, dictionary),
            x: get(g, ["x"]),
            y: get(g, ["y"]),
            hidden: get(g, ["hidden"]),
            hiddenOnMFD: get(g, ["hiddenOnMFD"]),
            hiddenOnPlanner: get(g, ["hiddenOnPlanner"]),
            airdromeId: getAirdrome(g),
            isPlayer,
          };

          result.push(data);
        });
      }
    }
  });

  return result;
};

export function getWaypointsForGroup(
  group: LuaTable,
  dictionary: DCSI18n
): DCSWaypoint[] {
  const waypoints = [];

  const points = get2(group, ["route", "points"]);

  _.each(points, (v, i) => {
    let name;

    if (dictionary) {
      name = dictionary[v.strValues.name];
    }

    if (!name) {
      name = v.strValues.name;
    }

    if (!name) {
      name = i.toString();
    }

    waypoints.push({ ...v.strValues, name, number: i });
  });

  return waypoints;
}

const removeHiddenUnits = (
  coalition: Coalition,
  cat: UnitCategory,
  mission: LuaTable
) => {
  const countries = get2(mission, ["coalition", coalition, "country"]);

  for (let c = 0; c < countries.length; c++) {
    const country = countries[c];
    if (!country) {
      continue;
    }

    const groups = get2(country, [cat, "group"]);
    if (!groups) {
      continue;
    }
    const visible = [undefined];
    for (let v = 0; v < groups.length; v++) {
      let group = groups[v];

      if (!group) {
        continue;
      }

      if (!group.strValues.hidden) {
        visible.push(group);
      }
    }

    country.strValues[cat].strValues.group.numValues = visible;
  }

  return mission;
};

export const removeAllHiddenUnits = () => (mission: LuaTable) => {
  let updated = mission;

  [Coalition.Blue, Coalition.Red].forEach((coalition) => {
    [
      UnitCategory.Vehicle,
      UnitCategory.Plane,
      UnitCategory.Ship,
      UnitCategory.Helicopter,
    ].forEach((category) => {
      updated = removeHiddenUnits(coalition, category, updated);
    });
  });

  return updated;
};

export function getTriggerZones(mission: LuaTable): DCSZoneData[] {
  const zones = mission.strValues.triggers.strValues.zones.numValues;

  const out = [];
  for (const zone of zones) {
    if (!zone) {
      continue;
    }

    const record = {
      name: zone.strValues.name,
      hidden: zone.strValues.hidden,
      verticies: null,
      type: zone.strValues.type,
      color: _.filter(
        zone.strValues.color.numValues,
        (n) => typeof n !== "undefined"
      ),
      origin: { x: zone.strValues.x, y: zone.strValues.y },
      radius: zone.strValues.radius,
    };

    record.verticies = [];
    if (zone.strValues.verticies) {
      for (const vertex of zone.strValues.verticies.numValues) {
        if (!vertex) {
          continue;
        }

        record.verticies.push({
          x: vertex.strValues.x,
          y: vertex.strValues.y,
        });
      }
    }

    out.push(record);
  }
  return out;
}

export const getBullseyeForCoalition = (
  coa: Coalition,
  mission: LuaTable
): { x: number; y: number } => {
  return mission.strValues.coalition.strValues[coa].strValues.bullseye
    .strValues as any;
};

export const getControlPointsForCoalition = (
  coa: Coalition,
  mission: LuaTable
): DCSNavPoint[] => {
  const tbl =
    mission.strValues.coalition.strValues[coa].strValues.nav_points.numValues;

  const points: DCSNavPoint[] = [];

  for (const point of tbl as any) {
    if (!point) {
      continue;
    }

    points.push({
      type: point.strValues.type,
      callsignStr: point.strValues.callsignStr as string,
      comment: point.strValues.comment,
      id: point.strValues.id,
      x: point.strValues.x,
      y: point.strValues.y,
    });
  }

  return points;
};

export const getBriefingForCoalition = (
  coa: Coalition,
  mission: LuaTable,
  dict: DCSI18n
): string => {
  const key = mission.strValues.descriptionText as any;

  return dict[key];
};

export const removeHiddenTriggerZones = () => (mission: LuaTable) => {
  const triggers = mission.strValues.triggers;

  const zones = triggers.strValues.zones;

  if (!zones) {
    return mission;
  }

  mission.strValues.triggers.strValues.zones.numValues = _.filter(
    zones.numValues,
    (z) => z && !z.strValues.hidden
  );
  // Because Lua is 1-based, the first element needs to be undefined.
  // The _.filter func above removed undefined.
  mission.strValues.triggers.strValues.zones.numValues.unshift(undefined);

  return mission;
};

export const removeTriggersAndActions = () => (mission: LuaTable) => {
  // Set this to a blank table
  mission.strValues.trig = new lua.Table() as LuaTable;
  mission.strValues.trigrules = new lua.Table() as LuaTable;
  return mission;
};

export type TemplateManifest = {
  lua: LuaTable;
  dictionary: DCSI18n;
};

export const copyGroupData = (
  coalition: Coalition,
  groupName: string,
  category: UnitCategory,
  template: TemplateManifest,
  missionDict: DCSI18n
) => (mission: LuaTable) => {
  const countries = getCountriesForCoalition(coalition, mission);

  for (let c = 0; c < countries.length; c++) {
    const country = countries[c];

    if (!country) {
      continue;
    }

    const mg = country.strValues[category];

    if (!mg) {
      continue;
    }

    const mgs = mg.strValues.group.numValues;

    for (let m = 0; m < mg.strValues.group.numValues.length; m++) {
      let mg = mgs[m];

      if (!mg) {
        continue;
      }

      let mgName = missionDict[mg.strValues.name];

      // DCS 2.7 is now inconsistent with i18n.
      // Older missions have names defined in the dictionary, newer ones seem to apply it directly.
      if (!mgName) {
        mgName = mg.strValues.name;
      }

      if (mgName !== groupName) {
        continue;
      }

      const tmplCountries = getCountriesForCoalition(coalition, template.lua);

      for (const tmplCountry of tmplCountries) {
        const tg = tmplCountry.strValues[category];
        if (!tg) {
          continue;
        }
        const templateGroups = tg.strValues.group.numValues;

        for (let t = 0; t < templateGroups.length; t++) {
          const templateGroup = templateGroups[t];

          if (!templateGroup) {
            continue;
          }

          let gName = template.dictionary[templateGroup.strValues.name];

          if (!gName) {
            gName = templateGroup.strValues.name;
          }

          if (gName === mgName) {
            // Convert waypoint names.
            // For some reason, adding entries to the dictionary does NOT work.
            // This means that waypoint names cannot be internationalized,
            // but we consider that an edge case anyway.
            for (
              let p = 0;
              p <
              templateGroup.strValues.route.strValues.points.numValues.length;
              p++
            ) {
              const point =
                templateGroup.strValues.route.strValues.points.numValues[p];

              if (!point) {
                continue;
              }

              let newName = template.dictionary[point.strValues.name];

              // Handle DCS 2.7 dctionary inconsistency
              if (newName) {
                point.strValues.name = newName;
              }
            }

            const originalGroup =
              mission.strValues.coalition.strValues[coalition].strValues.country
                .numValues[c + 1].strValues[category].strValues.group.numValues[
                m
              ];

            templateGroup.strValues.groupId = originalGroup.strValues.groupId;

            for (
              let i = 0;
              i < originalGroup.strValues.units.numValues.length;
              i++
            ) {
              const originalUnit = originalGroup.strValues.units.numValues[i];

              if (!originalUnit) {
                continue;
              }

              if (!templateGroup.strValues.units.numValues[i]) {
                continue;
              }

              templateGroup.strValues.units.numValues[i].strValues.unitId =
                originalUnit.strValues.unitId;
            }

            mission.strValues.coalition.strValues[
              coalition
            ].strValues.country.numValues[c + 1].strValues[
              category
            ].strValues.group.numValues[m] = templateGroup;
          }
        }
      }
    }
  }

  return mission;
};

export const removeHiddenStatics = () => (mission: LuaTable) => {
  let updated = mission;

  [Coalition.Blue, Coalition.Red].forEach((coalition) => {
    [UnitCategory.Static].forEach((category) => {
      updated = removeHiddenUnits(coalition, category, updated);
    });
  });

  return updated;
};

export type ImportOpts = {
  coalition: Coalition;
  categories: UnitCategory[];
  templates: { lua: LuaTable; dictionary: DCSI18n }[];
  missionDict: DCSI18n;
};

export const batchCopy = (opts: ImportOpts) => (mission: LuaTable) => {
  let updated = mission;

  _.each(opts.templates, (template) => {
    _.each(opts.categories, (cat: UnitCategory) => {
      const groups = findPlannedGroupsInTemplate(
        opts.coalition,
        cat,
        template.lua,
        template.dictionary
      );

      _.each(groups, (gData) => {
        updated = copyGroupData(
          opts.coalition,
          gData.name,
          cat,
          template,
          opts.missionDict
        )(updated);
      });
    });
  });

  return updated;
};

export const findPlannedGroupsInTemplate = (
  coalition: Coalition,
  category: UnitCategory,
  template: LuaTable,
  dictionary: DCSI18n
) => {
  const allGroups = getGroupsOfType(coalition, category, template, dictionary);
  const plannedGroups = _.filter(
    allGroups,
    (g) => g.isPlayer && g.waypoints.length > 1
  );

  return plannedGroups;
};

export const findPlannable = (
  coalition: Coalition,
  category: UnitCategory,
  template: LuaTable,
  dictionary: DCSI18n
) => {
  const allGroups = getGroupsOfType(coalition, category, template, dictionary);
  const plannedGroups = _.filter(
    allGroups,
    (g) => g.isPlayer && !g.hidden && !g.hiddenOnPlanner
  );

  return plannedGroups;
};

export function getAirbasesForCoalition(
  coalition: Coalition,
  warehousesData: LuaTable,
  theater: Theater
): Airbase[] {
  const basesForTheater = bases[theater];
  const output: Airbase[] = [];

  if (!basesForTheater) {
    throw new Error(`No bases defined for theater ${theater}`);
  }

  _.each(warehousesData.strValues.airports.numValues, (b, i) => {
    if (!b) {
      return;
    }

    if (!basesForTheater[i]) {
      // Strange mismatch case where the warehouse file has an airbase ID but the
      // DCS scripting API doesn't...
      return;
    }

    if (_.lowerCase(b.strValues.coalition as any) === coalition) {
      output.push({
        ...basesForTheater[i],
        Type: AirbaseType.Airfield,
        AirdromeId: i,
      });
    }
  });

  return output;
}

function isLHA(type: string) {
  return _.includes(type, "LHA");
}

// This type is provided by DCS. Each Nimitz carrier has a type like "CVN_71" or "CVN_74".
// TODO: support Kuz.
function isCarrier(type: string) {
  return _.includes(type, "CVN") || _.includes(type, "Stennis");
}

// FARPs can be lots of different types.
// This is naive for now.
function isFARP(s: DCSGroupData) {
  return !!s.units[0].radios[0][0].frequency;
}

// https://wiki.hoggitworld.com/view/DCS_command_activateBeacon
enum BeaconType {
  TACAN = 4,
}

export function getActionById(group: DCSGroupData, taskId: string) {
  for (const w of group.waypoints) {
    const tasks = (w as any).task.strValues.params.strValues.tasks.numValues;
    for (const st of tasks) {
      if (!st) {
        continue;
      }

      if (st.strValues.id === "WrappedAction") {
        const action = st.strValues.params.strValues.action.strValues;

        if (action.id === taskId) {
          return action;
        }
      }

      if (st.strValues.id === taskId) {
        return st.strValues;
      }

      const action = st.strValues.params.strValues.action;

      if (!action) {
        continue;
      }

      if ((action.strValues.id as any) === taskId) {
        return action.strValues;
      }
    }
  }
}

export function getTACAN(unit: DCSUnit, group: DCSGroupData) {
  let action: any = getActionById(group, "ActivateBeacon");

  let output = "";

  if (action) {
    let data;

    // Handle the various ways DCS mission tasks will get returned
    if (!action.type && action.params) {
      data = action.params.strValues;
    } else {
      data = action.strValues.params.strValues;
    }

    if (data.type === BeaconType.TACAN) {
      if (data.unitId === unit.unitId) {
        output = `${data.channel}${data.modeChannel} ${data.callsign}`;
      }
    }
  }

  return output;
}

export function getICLS(unit: DCSUnit, group: DCSGroupData) {
  let action: { id: string; params: LuaTable } = getActionById(
    group,
    "ActivateICLS"
  );

  return action ? `ICLS ${action.params.strValues.channel}` : "";
}

// Mission editor freqs are represented by large ints without decimals.
function formatLongFreq(freq: number) {
  return freq / 1000000;
}

function isUHF(freq: number) {
  return freq >= 225.0;
}

export function getCarriersAndFARPs(
  coalition: Coalition,
  mission: LuaTable,
  dictionary: DCSI18n
): Airbase[] {
  const output = [];
  const ships = getGroupsOfType(
    coalition,
    UnitCategory.Ship,
    mission,
    dictionary
  );

  _.each(ships, (g) => {
    _.each(g.units, (u) => {
      if (isCarrier(u.type) || isLHA(u.type)) {
        // Coverting from a DCSUnit to an interal Airbase type,
        // so the radio translation is a little weird.
        const freq = formatLongFreq(u.radios[0][0].frequency);
        output.push({
          Name: u.name,
          X: u.x,
          Y: 0,
          Z: u.y,
          Theater: "",
          Runways: "",
          UHF: isUHF(freq) ? freq : null,
          VHF: !isUHF(freq) ? freq : null,
          TACAN: getTACAN(u, g),
          VOR: "",
          ILS: [getICLS(u, g)],
          Elevation: "",
          "MF/HF": "",
          ICAO: "",
          UnitId: u.unitId,
          Type: isCarrier(u.type) ? AirbaseType.Carrier : AirbaseType.LHA,
        });
      }
    });
  });

  const statics = getGroupsOfType(
    coalition,
    UnitCategory.Static,
    mission,
    dictionary
  );

  _.each(statics, (s) => {
    if (isFARP(s)) {
      if (s.hidden) {
        return;
      }
      // DCS changed to allow statics to be in groups.
      // Each unit can be a separate parking spot, but it isn't really a speparate FARP.
      // Use the first for the pos and freq.
      const u = s.units[0];
      const freq = u.radios[0][0].frequency;
      output.push({
        Name: s.name,
        X: u.x,
        Y: 0,
        Z: u.y,
        Theater: "",
        Runways: "",
        UHF: isUHF(freq) ? freq : null,
        VHF: isUHF(freq) ? null : freq,
        TACAN: "",
        VOR: "",
        ILS: "",
        Elevation: "",
        "MF/HF": "",
        ICAO: "",
        Type: AirbaseType.FARP,
      });
    }
  });

  return output;
}

export enum SupportAction {
  AWACS = "AWACS",
  Tanker = "Tanker",
  FAC = "FAC",
}

function getGroupWithAction(
  coalition,
  missionAST: LuaTable,
  dictionary: DCSI18n,
  category: UnitCategory,
  action: SupportAction
): DCSGroupData[] {
  const planes = getGroupsOfType(coalition, category, missionAST, dictionary);

  const withSupportType = _.map(planes, (p) => ({ ...p, supportType: action }));

  return _.filter(withSupportType, (p) => {
    const a = getActionById(p, action);

    if (a) {
      return true;
    }
  });
}

export function getSupportAssets(
  coalition,
  missionAST: LuaTable,
  dictionary: DCSI18n
) {
  const awacs = getGroupWithAction(
    coalition,
    missionAST,
    dictionary,
    UnitCategory.Plane,
    SupportAction.AWACS
  );

  const tankers = getGroupWithAction(
    coalition,
    missionAST,
    dictionary,
    UnitCategory.Plane,
    SupportAction.Tanker
  );

  return [...awacs, ...tankers];
}
