import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import SubRouterTabs, { RouterTab } from "../SubRouterTabs";
import AirbaseData from "./AirbaseData";
import Subscriptions from "./Subscriptions";

type Props = {
  className?: string;
};

function SuperAdmin({ className }: Props) {
  const { url } = useRouteMatch();
  return (
    <div className={className}>
      <SubRouterTabs rootPath={`${url}/subscriptions`}>
        <RouterTab name="Subscriptions" path={`${url}/subscriptions`}>
          <Subscriptions />
        </RouterTab>
        <RouterTab name="Airbase Data" path={`${url}/airbases`}>
          <AirbaseData />
        </RouterTab>
      </SubRouterTabs>
    </div>
  );
}

export default styled(SuperAdmin).attrs({ className: SuperAdmin.name })``;
