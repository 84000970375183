import _ from "lodash";
import { Map } from "ol";
import { Coordinate } from "ol/coordinate";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import { Theater } from "./map";
import { cumulativeDistance, distance } from "./time";
import { DCSWaypoint, MinimalWaypoint } from "./types";

export const TERRAIN_HEIGHT_LAYER_NAME = "__internal_terrain_height";

// https://openlayers.org/en/latest/examples/interpolation.html
function getHeight(rgba: Uint8ClampedArray) {
  if (!rgba) {
    return 0;
  }
  return -10000 + (rgba[0] * 256 * 256 + rgba[1] * 256 + rgba[2]) * 0.1;
}

export function getTerrainHeightAtCoords(map: Map, coords: Coordinate) {
  // We get the terrain height layer here to keep the presentation uniform
  // with the over mouse position stuff.
  const elevationLayer = _.find(
    map.getLayers().getArray(),
    (l) => l.get("name") === TERRAIN_HEIGHT_LAYER_NAME
  );

  const px = map.getPixelFromCoordinate(coords);
  const d = (elevationLayer as TileLayer<XYZ>).getData(px);
  const elevation = getHeight(d as Uint8ClampedArray);

  return elevation;
}

export function getWaypointDistance(
  theater: Theater,
  wp: MinimalWaypoint,
  allwp: DCSWaypoint[]
) {
  const prev = _.find(allwp, { number: wp.number - 1 });

  let leg: number = null;
  let total: number = null;

  if (prev) {
    leg = distance(theater, prev, wp);
    total = cumulativeDistance(theater, wp, allwp);
  }

  return { leg, total };
}

// https://blog.onethinglab.com/openlayers-align-label-with-line-feature/
function radians(n) {
  return n * (Math.PI / 180);
}

function degrees(n) {
  return n * (180 / Math.PI);
}

export function bearing(start_lat, start_long, end_lat, end_long) {
  start_lat = radians(start_lat);
  start_long = radians(start_long);
  end_lat = radians(end_lat);
  end_long = radians(end_long);

  var dlong = end_long - start_long;

  var dphi = Math.log(
    Math.tan(end_lat / 2.0 + Math.PI / 4.0) /
      Math.tan(start_lat / 2.0 + Math.PI / 4.0)
  );
  if (Math.abs(dlong) > Math.PI) {
    if (dlong > 0.0) dlong = -(2.0 * Math.PI - dlong);
    else dlong = 2.0 * Math.PI + dlong;
  }

  return (degrees(Math.atan2(dlong, dphi)) + 360.0) % 360.0;
}

function bearingToRadians(br) {
  return radians((450 - br) % 360);
}

export function rotation(pt0, pt1, br) {
  var rotate = pt0[0] > pt1[0] ? Math.PI : 0;
  return bearingToRadians(br) + rotate;
}
