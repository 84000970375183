import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import saveAs from "file-saver";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../contexts/NotifcationContext";
import { useGetCurrentTaskingData } from "../contexts/PublishReaderContext";
import { useGetColorMap, useTaskingState } from "../contexts/TaskingMapContext";
import { useListControlMeasures } from "../hooks/control_measures";
import { toTacviewShapeXML } from "../lib/tacview";
import { DCSGroupData } from "../lib/types";
import GroupSelect from "./PlanningManager/GroupSelect";

type Props = {
  className?: string;
};

function TacviewExport({ className }: Props) {
  const {
    state: { manifest },
  } = useTaskingState();
  const { data } = useGetCurrentTaskingData();
  const { data: controlMeasures } = useListControlMeasures(manifest.id);
  const { success } = useNotification();
  const [formState, setFormState] = React.useState({
    groups: _.reduce(
      data?.plannedGroups,
      (acc, group) => {
        acc[group.name] = true;
        return acc;
      },
      {}
    ),
    controlMeasures: true,
  });

  const colorMap = useGetColorMap();

  const handleExport = React.useCallback(() => {
    const filteredGroups = _.filter(
      data?.plannedGroups,
      (g) => formState.groups[g.name]
    ) as DCSGroupData[];

    const result = toTacviewShapeXML(
      data.theater,
      filteredGroups,
      colorMap,
      formState.controlMeasures ? controlMeasures : null
    );

    saveAs(
      new Blob([result], { type: "text/xml" }),
      `${manifest.title}_TacviewObjects.xml`
    );

    success("Tacview Objects XML downloaded");
  }, [data, controlMeasures, formState]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.checked,
    });
  };

  const selectValue = _.filter(
    _.keys(formState.groups),
    (g) => formState.groups[g]
  ) as string[];

  return (
    <Box p={2} className={className}>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={handleExport}>
          Download Tacview Objects XML
        </Button>
      </Box>
      <form>
        <FormControl>
          <GroupSelect
            value={selectValue}
            groups={_.map(data?.plannedGroups, "name")}
            onChange={(groups) => {
              const next = _.reduce(
                groups,
                (acc, group) => {
                  acc[group] = true;
                  return acc;
                },
                {}
              );
              setFormState({ ...formState, groups: next });
            }}
          />
        </FormControl>

        <FormControl>
          <Box ml={2}>
            <FormLabel component="legend">Objects</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  name="controlMeasures"
                  checked={formState.controlMeasures}
                  onChange={handleFormChange}
                />
              }
              label="Include Control Measures"
            />
          </Box>
        </FormControl>
      </form>
    </Box>
  );
}

export default styled(TacviewExport).attrs({ className: TacviewExport.name })``;
