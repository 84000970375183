import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import { useEffect } from "react";
import { TERRAIN_HEIGHT_LAYER_NAME } from "../../lib/measurements";
import { useMap } from "./MapCanvas";

export function TerrainHeightLayer() {
  const map = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    const l = new TileLayer({
      opacity: 0,
      source: new XYZ({
        url:
          "https://api.maptiler.com/tiles/terrain-rgb/{z}/{x}/{y}.png?key=" +
          process.env.MAPTILER_KEY,
        tileSize: 512,
        maxZoom: 12,
        crossOrigin: "",
      }),
    });

    l.set("name", TERRAIN_HEIGHT_LAYER_NAME);

    map.addLayer(l);
  }, [map]);
  return null;
}
