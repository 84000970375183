import * as React from "react";
import { convertDCSToLatLong } from "../../lib/map";
import { TaskingState } from "../../lib/models/PublishManifest";
import { NewImageComposer } from "../../lib/services/ImageComposer";
import SharedNavPointsLayer from "../MapCanvas/SharedNavPointsLayer";
import { StickMapLayer, stickMapVectorLayer } from "../MapCanvas/StickMapLayer";
import ThreatLayer from "../MapCanvas/ThreatLayer";
import {
  KNEEBOARD_HEIGHT,
  KNEEBOARD_WIDTH,
  renderMap,
} from "./kneeboard_utils";

export async function renderBullsCompass(tasking: TaskingState) {
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);

  const { long, lat } = convertDCSToLatLong(tasking.theater, tasking.bullseye);

  const layer = stickMapVectorLayer([long, lat]);

  const extent = layer.getSource().getExtent();

  const mapImg = (await renderMap(
    extent,
    { height: KNEEBOARD_HEIGHT, width: KNEEBOARD_WIDTH },
    <>
      <ThreatLayer groups={tasking.opforVehicles} theater={tasking.theater} />
      <StickMapLayer
        theater={tasking.theater}
        visible
        bulls={tasking.bullseye}
      />
      <SharedNavPointsLayer
        theater={tasking.theater}
        bullseye={tasking.bullseye}
      />
    </>
  )) as Blob;

  composer.add(mapImg, { x: 0, y: 0 });

  return composer;
}
