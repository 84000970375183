import * as React from "react";
import { BundleClient } from "../lib/services/BundleClient";

const BundleClientContext = React.createContext<BundleClient>(null);

export function BundleClientContextProvider({ client, children }) {
  return (
    <BundleClientContext.Provider value={client}>
      {children}
    </BundleClientContext.Provider>
  );
}

export function useBundleClient() {
  return React.useContext(BundleClientContext);
}
