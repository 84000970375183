import * as React from "react";
import { useHistory } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import Page from "../components/Page";
import { useUser } from "../contexts/UserContext";
import { AppRoutes } from "../lib/types";

export default function Logout() {
  const history = useHistory();
  const { logout } = useUser();

  React.useEffect(() => {
    logout().then(() => {
      history.push(AppRoutes.Login);
    });
  }, []);
  return (
    <Page>
      <LoadingPage />
    </Page>
  );
}
