import JSZip from "jszip";
import { renderBriefingTextKneeboard } from "../../components/Kneeboards/kneeboard_utils";
import { MizJobFunc } from "../models/Job";
import { TaskingState } from "../models/PublishManifest";

export const injectBriefingTextKneeboard = (
  tasking: TaskingState,
  title: string
): MizJobFunc => async (zip: JSZip) => {
  const composer = await renderBriefingTextKneeboard(tasking, title);
  const blob = await composer.renderToBlob();

  return zip.file("KNEEBOARD/IMAGES/Briefing.png", blob);
};
