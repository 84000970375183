import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import { formatFreq } from "../../lib/radios";
import { DCSRadioModulation } from "../../lib/types";
import { styles } from "./kneeboard_utils";

type Props = {
  radioConfig: RadioPresetConfig;
};

const divStyle = {
  display: "flex",
  margin: 8,
  fontFamily: "sans-serif",
  height: "100%",
  width: "98%",
  fontSize: 14,
  flexWrap: "wrap",
  justifyContent: "space-between",
};

const cellStyle = {
  borderBottom: "1px solid gray",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export default function KneeboardMasterFreqTable({ radioConfig }: Props) {
  return (
    <div style={divStyle as any}>
      <div style={{ textAlign: "center", width: "100%" }}>
        <h2 style={{ fontSize: 18 }}>Frequencies</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {_.map(radioConfig, (v, k) => {
          return (
            <div
              style={{ maxWidth: 320, marginRight: 8, marginBottom: 8 }}
              key={k}
            >
              <div
                style={{
                  fontSize: 14,
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <b>{k}</b>
              </div>
              <Table style={styles.table as any}>
                <TableHead style={styles.th as any}>
                  {/* <TableRow>
                  <TableCell
                    style={{ textAlign: "center", width: 300 }}
                    colSpan={4}
                  >
                    {r.label}
                  </TableCell>
                </TableRow> */}
                  <TableRow>
                    <TableCell style={{ width: 24 }}>Chan</TableCell>
                    <TableCell style={{ width: 48 }}>Freq</TableCell>
                    <TableCell style={{ width: 32 }}>AM/FM</TableCell>
                    <TableCell style={{ width: 96 }}>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(v, (t, i) => {
                    let freq = t.frequency;
                    let mod = t.modulation;
                    let desc = t.description;

                    return (
                      <TableRow
                        style={{
                          backgroundColor: i % 2 === 1 ? "#ffe194" : "#ffffff",
                        }}
                        key={i}
                      >
                        <TableCell style={{ ...(cellStyle as any) }}>
                          {i + 1}
                        </TableCell>
                        <TableCell style={cellStyle as any}>
                          {formatFreq(freq)}
                        </TableCell>
                        <TableCell style={cellStyle as any}>
                          {mod === DCSRadioModulation.FM ? "FM" : "AM"}
                        </TableCell>
                        <TableCell style={cellStyle as any}>{desc}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          );
        })}
      </div>
    </div>
  );
}
