import { Feature } from "ol";
import { Point } from "ol/geom";
import { Icon, Style } from "ol/style";
import * as React from "react";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import { DCSNavPoint, FeatureClass } from "../../lib/types";
import MapLayer from "./MapLayer";
// @ts-ignore
import bullsBlue from "../../img/bulls-blue.svg";

type Props = {
  theater: Theater;
  bullseye: {
    x: number;
    y: number;
  };
  controlPoints?: DCSNavPoint[];
};

const style = () => () => {
  return [
    new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: bullsBlue,
        width: 32,
        height: 32,
      }),
    }),
  ];
};

export default function SharedNavPointsLayer({ theater, bullseye }: Props) {
  const { long, lat } = convertDCSToLatLong(theater, bullseye);

  const point = new Point([long, lat]);

  const feature = new Feature({ geometry: point });
  feature.set("featureClass", FeatureClass.Bullseye);
  feature.set("name", "Bullseye");
  feature.set("bullseye", bullseye);

  //   const ft = NewMapFeature(feature);

  return (
    <MapLayer
      name="Shared Nav Points"
      displayName="Shared Nav Points"
      features={[{ geometry: point, ...feature.getProperties() }]}
      style={style()}
      hideable
    />
  );
}
