import { Button } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useRemoveAsset } from "../../contexts/KneeboardManagerContext";
import { useNotification } from "../../contexts/NotifcationContext";
import { useAdminGetManifestsForFragOrder } from "../../hooks/frag_orders";
import { Asset } from "../../lib/models/Asset";
import { Coalition } from "../../lib/types";
import DataTable from "../DataTable";
import AssetViewerModal from "./AssetViewerModal";

type Props = {
  className?: string;
  assets: Asset[];
};

function AssetTable({ className, assets }: Props) {
  const remove = useRemoveAsset();
  const [preview, setPreview] = React.useState<Asset>(null);
  const { success, rawError: error } = useNotification();
  const { data: manifests } = useAdminGetManifestsForFragOrder();

  const handlePreview = (a: Asset) => {
    setPreview(a);
  };

  const handleRemove = (a: Asset) => {
    remove(a)
      .then(() => success("Asset removed"))
      .catch((err) => error(err));
  };

  return (
    <div className={className}>
      <DataTable
        columns={[
          { label: "Filename", value: "name" },
          { label: "Type", value: "type" },
          {
            label: "",
            value: (a: Asset) => (
              <Button variant="outlined" onClick={() => handlePreview(a)}>
                Preview
              </Button>
            ),
          },
          {
            label: "",
            value: (a: Asset) => (
              <Button
                onClick={() => handleRemove(a)}
                variant="text"
                color="error"
              >
                Remove
              </Button>
            ),
          },
        ]}
        rows={assets}
      />
      <AssetViewerModal
        open={!!preview}
        asset={preview}
        onClose={() => setPreview(null)}
        manifest={_.find(manifests, { coalition: Coalition.Blue })}
      />
    </div>
  );
}

export default styled(AssetTable).attrs({ className: AssetTable.name })``;
