import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useGetPublishStatus } from "../../contexts/PublishManagerContext";
import { useIsBotPresent } from "../../hooks/discord";
import { FragOrderDoc } from "../../lib/models/FragOrder";
import Flex from "../Flex";
import Section from "../Section";
import NoDiscordGuildCallToAction from "./NoDiscordGuildCallToAction";
import PlanningManagementForm from "./PlanningManagementForm";

type Props = {
  className?: string;
  fragOrder: FragOrderDoc;
};

function PlanningManagement({ className, fragOrder }: Props) {
  const showCTA = useIsBotPresent(fragOrder?.discordGuildID);
  const { data: manifests, isLoading } = useGetPublishStatus(fragOrder?.id);

  if (!isLoading && manifests.length === 0) {
    return (
      <Section title="Manage Planners">
        <Flex wide center>
          <Alert severity="warning">
            <AlertTitle>Not published</AlertTitle>
            Publish this Frag Order to add planners
          </Alert>
        </Flex>
      </Section>
    );
  }

  return (
    <Section title="Manage Planners">
      {showCTA ? (
        <NoDiscordGuildCallToAction />
      ) : (
        <form>
          {_.map(manifests, (pm) => {
            return (
              <Box marginBottom={6} key={pm.id}>
                <Typography color={pm.coalition} fontSize={18} fontWeight={600}>
                  {_.capitalize(pm.coalition)} Coalition Planners
                </Typography>
                <div>
                  <PlanningManagementForm
                    className={className}
                    fragOrderID={fragOrder?.id}
                    manifest={pm}
                    discordGuildID={fragOrder?.discordGuildID}
                  />
                </div>
              </Box>
            );
          })}
        </form>
      )}
    </Section>
  );
}

export default styled(PlanningManagement).attrs({
  className: PlanningManagement.name,
})``;
