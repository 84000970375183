import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ExternalDataManagerContext } from "../contexts/ExternalDataManagerContext";
import { useUser } from "../contexts/UserContext";
import {
  ExternalDataRecordDoc,
  RecordType,
} from "../lib/models/ExternalDataRecord";
import {
  ExternalDataManager,
  ExternalDataReader,
} from "../lib/services/ExternalDataManager";
import { Coalition } from "../lib/types";
import { FragOrdersQueryKey } from "./frag_orders";

function useExternalData() {
  return useContext<ExternalDataManager>(ExternalDataManagerContext);
}

export function useExternalDataReader(): ExternalDataReader {
  return useExternalData();
}

export function useExternalDataWriter(): ExternalDataManager {
  return useExternalData();
}

const queryKey = "externalData";

export function useListExternalData(fragOrderID: string, coa: Coalition) {
  const reader = useExternalDataReader();
  return useQuery([queryKey, fragOrderID, coa], () =>
    reader.list(fragOrderID, coa)
  );
}

export function useSubmitExternalData(fragOrderID: string) {
  const { currentUser } = useUser();
  const writer = useExternalDataWriter();
  const qc = useQueryClient();
  return (type: RecordType, coalition: Coalition, payload: string) =>
    writer
      .upsert(currentUser.id, {
        fragOrderID,
        coalition,
        type,
        payload,
        contentType: "application/csv",
      })
      .then(() => {
        qc.invalidateQueries([FragOrdersQueryKey]);
        qc.invalidateQueries([queryKey, fragOrderID]);
      });
}

export function useDeleteExternalData(fragOrderID: string) {
  const writer = useExternalDataWriter();
  const qc = useQueryClient();

  const { currentUser } = useUser();

  return (record: ExternalDataRecordDoc) =>
    writer.delete(currentUser.id, record).then(() => {
      qc.invalidateQueries([FragOrdersQueryKey]);
      qc.invalidateQueries([queryKey, fragOrderID]);
    });
}
