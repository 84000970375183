import _ from "lodash";
import * as React from "react";
import { Airbase } from "../../lib/data/airbases";
import {
  convertDCSToLatLong,
  feetToMeters,
  formatLatLong,
  metersToFeet,
  Theater,
} from "../../lib/map";
import { SupportAction } from "../../lib/mission";
import { AirbasesKneeboardSettings } from "../../lib/models/Job";

const latLong = (theater: Theater, b: Airbase) => {
  const { lat, long } = convertDCSToLatLong(theater, {
    x: b.X,
    y: b.Y,
  });
  const [n, e] = formatLatLong([long, lat], 3);
  return `${n}\n${e}`;
};

const styles = {
  p: {
    margin: 0,
  },
  heading: {
    fontWeight: 800,
  },
  th: {
    borderBottom: "1px solid #000000",
  },
  tr: (index: number) => ({
    backgroundColor: index % 2 === 1 ? "#ffe194" : "#ffffff",
  }),
  td: {
    overflowWrap: "break-word",
  },
};

type Props = {
  theater: Theater;
  bases: Airbase[];
  settings?: AirbasesKneeboardSettings;
};

type AirbaseOrSupport = Airbase & {
  supportType?: SupportAction;
  unitType?: string;
};

function KneeboardAirbasesTable({ theater, bases }: Props) {
  const rows: AirbaseOrSupport[] = [..._.take(bases, 10)];

  return (
    <div
      style={{
        height: "100%",
        // width: "100%",
        fontFamily: "monospace",
        fontSize: 14,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <h2 style={{ fontSize: 18, fontFamily: "sans-serif" }}>
          Airfield and Support Asset Navigation Card
        </h2>
      </div>
      <table
        style={{
          textAlign: "left",
          width: "100%",
          borderCollapse: "collapse",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr>
            <td style={styles.th} width={30}>
              <div style={styles.heading}>Name</div>
              <div>Coordinates</div>
            </td>
            <td style={styles.th} width={10}>
              <div style={styles.heading}>MSL (feet)</div>
              <div>Meters</div>
            </td>

            <td style={styles.th} width={10}>
              <div style={styles.heading}>TACAN</div>
              <div>VOR</div>
            </td>
            <td style={styles.th} width={30}>
              <div style={styles.heading}>Runways</div>
              <div>ILS</div>
            </td>
          </tr>
        </thead>
        <tbody>
          {_.map(rows, (b, i) => {
            const altStr =
              b.supportType === SupportAction.AWACS
                ? ""
                : b.Elevation?.replace("'", "");
            const isSupport = !!b.supportType;
            const supportNotes = isSupport
              ? `${b.supportType} (${b.unitType})`
              : "";

            return (
              <tr style={styles.tr(i)} key={b.Name}>
                <td>
                  <div style={styles.heading}>{b.Name}</div>
                  <div>
                    {b.supportType ? supportNotes : latLong(theater, b)}
                  </div>
                </td>
                <td>
                  <div style={styles.heading}>
                    {isSupport && b.supportType !== SupportAction.AWACS
                      ? // Round to the nearest 100 feet
                        Math.ceil(metersToFeet(parseInt(b.Elevation)) / 100) *
                        100
                      : altStr}
                  </div>
                  <div>
                    {altStr && !isSupport ? (
                      feetToMeters(parseInt(altStr)).toFixed(0)
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                </td>
                <td>
                  <div style={styles.heading}>
                    {b.TACAN || <span>&nbsp;</span>}
                  </div>
                  <div>{b.VOR || <span>&nbsp;</span>}</div>
                </td>
                <td>
                  <div style={styles.heading}>
                    {b.Runways || <span>&nbsp;</span>}
                  </div>
                  <div>{b.ILS || <span>&nbsp;</span>}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default KneeboardAirbasesTable;
