import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import { Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useNavPointController } from "../../contexts/TaskingMapContext";
import NoShadowStateButton from "./NoShadowStateButton";

type Props = {
  className?: string;
  groupName: string;
};

function NavTargetPointsButton({ className, groupName }: Props) {
  const nav = useNavPointController();

  const handleClick = () => {
    nav.toggleAddMode(true, groupName);
  };

  return (
    <Tooltip className={className} title="Add Nav Target Point">
      <NoShadowStateButton onClick={handleClick}>
        <ChangeHistoryIcon />
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(NavTargetPointsButton).attrs({
  className: NavTargetPointsButton.name,
})``;
