import { Tab, Tabs } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import AirbasesTable from "../AirbasesTable";
import SupportAssetsTable from "../SupportAssetsTable";
import TabPanel from "../TabPanel";
import PlayerGroups from "../TaskingMap/PlayerGroups";

type Props = {
  className?: string;
};

function SearchWindow({ className }: Props) {
  const [selected, setSelected] = React.useState(0);
  return (
    <div className={className}>
      <Tabs value={selected} onChange={(event, val) => setSelected(val)}>
        <Tab label="Player Groups" />
        <Tab label="Support Assets" />
        <Tab label="Airbases" />
      </Tabs>
      <TabPanel value={selected} index={0}>
        <PlayerGroups />
      </TabPanel>
      <TabPanel value={selected} index={1}>
        <SupportAssetsTable />
      </TabPanel>
      <TabPanel value={selected} index={2}>
        <AirbasesTable />
      </TabPanel>
    </div>
  );
}

export default styled(SearchWindow).attrs({ className: SearchWindow.name })`
  padding: 0;
`;
