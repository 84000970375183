import { QuerySnapshot } from "firebase/firestore";

export function docs<T>(qs: QuerySnapshot): T[] {
  const d: T[] = [];
  qs.forEach((doc) => {
    const data = doc.data() as T;
    d.push({ ...data, id: doc.id });
  });

  return d;
}
