import _ from "lodash";
import * as React from "react";
import { feetToMeters, metersToFeet, Theater } from "../../lib/map";
import { getTACAN, SupportAction } from "../../lib/mission";
import { AirbasesKneeboardSettings } from "../../lib/models/Job";
import { formatFreq } from "../../lib/radios";
import { DCSGroupData } from "../../lib/types";

const styles = {
  p: {
    margin: 0,
  },
  heading: {
    fontWeight: 800,
  },
  th: {
    borderBottom: "1px solid #000000",
  },
  tr: (index: number) => ({
    backgroundColor: index % 2 === 1 ? "#ffe194" : "#ffffff",
  }),
  td: {
    overflowWrap: "break-word",
  },
};

type Props = {
  theater: Theater;
  support: DCSGroupData[];
  settings?: AirbasesKneeboardSettings;
};

function KneeboardSupportAssets({ support, settings }: Props) {
  const assets = _.map(support, (s) => {
    const isSupport = !!s.supportType;

    const wps = _.get(s, "waypoints");

    const highestAlt =
      _.get(
        _.maxBy(wps, (u) => _.get(u, "alt", 0)),
        "alt"
      ) || "";
    const TACAN = getTACAN(s.units[0], s);

    return {
      supportType: s.supportType,
      Elevation: isSupport ? highestAlt?.toString() : "",
      TACAN,
      UHF: s.channel.frequency,
      X: s.x,
      Y: s.y,
      Name: s.name,
      unitType: _.get(s, "units[0].type"),
    };
  });

  return (
    <div
      style={{
        height: "100%",
        // width: "100%",
        fontFamily: "monospace",
        fontSize: 14,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <h2 style={{ fontSize: 18, fontFamily: "sans-serif" }}>
          Support Asset Card
        </h2>
      </div>
      <table
        style={{
          textAlign: "left",
          width: "100%",
          borderCollapse: "collapse",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr>
            <td style={styles.th} width={30}>
              <div style={styles.heading}>Name</div>
              <div>Type</div>
            </td>
            <td style={styles.th} width={10}>
              <div style={styles.heading}>MSL (feet)</div>
            </td>

            <td style={styles.th} width={10}>
              <div style={styles.heading}>UHF</div>
              <div>VHF</div>
            </td>
            <td style={styles.th} width={10}>
              <div>TACAN</div>
            </td>
          </tr>
        </thead>
        <tbody>
          {_.map(assets, (b, i) => {
            const altStr =
              b.supportType === SupportAction.AWACS
                ? ""
                : b.Elevation.replace("'", "");
            const isSupport = !!b.supportType;
            const supportNotes = isSupport
              ? `${b.supportType} (${b.unitType})`
              : "";

            return (
              <tr style={styles.tr(i)} key={b.Name}>
                <td>
                  <div style={styles.heading}>{b.Name}</div>
                  <div>{supportNotes}</div>
                </td>
                <td>
                  <div style={styles.heading}>
                    {isSupport && b.supportType !== SupportAction.AWACS
                      ? // Round to the nearest 100 feet
                        Math.ceil(metersToFeet(parseInt(b.Elevation)) / 100) *
                        100
                      : altStr}
                  </div>
                  <div>
                    {altStr && !isSupport ? (
                      feetToMeters(parseInt(altStr)).toFixed(0)
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                </td>
                <td
                  style={{
                    opacity: !isSupport && !settings?.showAirbaseFreqs ? 0 : 1,
                  }}
                >
                  <div style={styles.heading}>
                    {b.UHF ? formatFreq(b.UHF) : <span>&nbsp;</span>}
                  </div>
                  <div>&nbsp;</div>
                </td>
                <td>
                  <div style={styles.heading}>
                    {b.TACAN ? b.TACAN : <span>&nbsp;</span>}
                  </div>
                  <div>&nbsp;</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default KneeboardSupportAssets;
