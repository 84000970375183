import { Box, Button } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import * as React from "react";
import Stripe from "stripe";
import styled from "styled-components";
import { useGetSubPortalURL } from "../../hooks/services";
import { SubscriptionDoc } from "../../lib/models/Subscription";
import { PriceIDLookup } from "../../lib/services/SubscriptionManager";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";
import Timestamp from "../Timestamp";
import SubscriptionCTA from "./SubscriptionCTA";

type Props = {
  className?: string;
  subscription: SubscriptionDoc;
  stripeDetails: Stripe.Subscription;
};

function SubscriptionManagement({
  className,
  subscription: sub,
  stripeDetails: details,
}: Props) {
  const portalURL = useGetSubPortalURL();

  const priceID = _.get(details, "items.data[0].price.id");

  const lookup = _.invert(PriceIDLookup);

  const priceDisplayText = lookup[priceID as string];

  const subFields: { key: string; value: string | React.ReactElement }[] = [
    {
      key: "Subscription Status",
      value: sub?.enabled ? "Active" : "Inactive",
    },
    {
      key: "Plan",
      value: sub?.parentID
        ? "Group Volume License"
        : _.capitalize(priceDisplayText),
    },
    {
      key: "Message",
      value: sub?.message || null,
    },
  ];

  if (details?.current_period_end) {
    subFields.push({
      key: "Billing Period Ends",
      value: (
        <Timestamp
          relative={false}
          format={DateTime.DATE_SHORT}
          time={details?.current_period_end * 1000}
        />
      ),
    });
  }

  if (details?.trial_end && details?.status === "trialing") {
    subFields.push({
      key: "Trial Ends",
      value: (
        <Timestamp
          relative={false}
          format={DateTime.DATE_SHORT}
          time={details?.trial_end * 1000}
        />
      ),
    });
  }

  const handleManageClick = async () => {
    const u = await portalURL(sub?.stripeCustomerID);
    window.location.href = u;
  };

  return (
    <div className={className}>
      <Flex wide center>
        <KeyValueTable columns={3} pairs={subFields} />
      </Flex>
      {details && (
        <Box marginTop={2}>
          <Button onClick={handleManageClick} variant="outlined">
            Manage Subscription
          </Button>
        </Box>
      )}

      {!sub?.enabled && (
        <SubscriptionCTA trialUnavailable={sub?.trialCompleted} />
      )}
    </div>
  );
}

export default styled(SubscriptionManagement).attrs({
  className: SubscriptionManagement.name,
})``;
