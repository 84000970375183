import _ from "lodash";
import { Point } from "ol/geom";
import "ol/ol.css";
import * as React from "react";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { useGetColorMap } from "../../contexts/TaskingMapContext";
import { layerID } from "../../lib/layer_utils";
import { convertDCSToLatLong, Theater } from "../../lib/map";
import { PlanItem } from "../../lib/models/PlanItem";
import { toMilSymbolString } from "../../lib/symbols";
import {
  DCSGroupData,
  FeatureClass,
  NavTargetPoint,
  TaskingMapLayerName,
} from "../../lib/types";
import { milIconLayerStyle } from "./GroupLayer";
import MapLayer from "./MapLayer";
import NavPointLayer from "./NavPointLayer";
import RouteLayer from "./RouteLayer";

type Props = {
  groups: GroupWithPlan[];
  theater: Theater;
  layerName?: TaskingMapLayerName;
  colorOverride?: string;
  disableDistanceLabels?: boolean;
  disableWaypointLabels?: boolean;
  declutter?: boolean;
  renderIcon?: boolean;
  planItemNavPoints: PlanItem[];
};

export const toGroupFeature = (
  theater: Theater,
  featureClass: FeatureClass
) => (group: DCSGroupData) => {
  const { long, lat } = convertDCSToLatLong(theater, {
    x: group.x,
    y: group.y,
  });

  return {
    name: group.name,
    featureClass,
    group,
    geometry: new Point([long, lat]),
    sidc: toMilSymbolString(false, group.category, group.supportType),
  } as any;
};

export default function GroupsWithWaypoints({
  groups,
  theater,
  layerName,
  colorOverride,
  disableDistanceLabels,
  disableWaypointLabels,
  declutter,
  renderIcon = true,
  planItemNavPoints,
}: Props) {
  const colorMap = useGetColorMap();
  const features = renderIcon
    ? _.map(groups, toGroupFeature(theater, FeatureClass.FlyableGroup))
    : [];

  return (
    <>
      {_.map(groups, (g) => {
        const color = colorOverride || colorMap[g.name];
        const points =
          planItemNavPoints.length > 0
            ? (_.map(
                _.filter(planItemNavPoints, {
                  group: g.name,
                }),
                (item) => JSON.parse(item.payload) as NavTargetPoint
              ) as NavTargetPoint[])
            : [];

        return (
          <React.Fragment key={g.name}>
            <RouteLayer
              theater={theater}
              group={g}
              key={`${g.name}/route`}
              color={color}
              filterTag={layerID(layerName, [g.name])}
              disableDistanceLabels={disableDistanceLabels}
              disableWaypointLabels={disableWaypointLabels}
              declutter={declutter}
            />

            <NavPointLayer
              key={`${g.name}/nav`}
              theater={theater}
              color={color}
              group={g}
              filterTag={layerID(layerName, [g.name])}
              planItemNavPoints={points}
            />
          </React.Fragment>
        );
      })}
      <MapLayer
        features={features}
        style={milIconLayerStyle(24)}
        name={layerName}
      />
    </>
  );
}
