import * as React from "react";
import { ControlMeasureManager } from "../lib/services/ControlMeasureManager";

export const ControlMeasureContext = React.createContext<ControlMeasureManager>(
  null
);

type Props = {
  manager: ControlMeasureManager;
  children: React.ReactNode;
};

export function ControlMeasureProvider({ manager, children }: Props) {
  return (
    <ControlMeasureContext.Provider value={manager}>
      {children}
    </ControlMeasureContext.Provider>
  );
}
