import _ from "lodash";
import { Layer } from "ol/layer";
import { useEffect, useState } from "react";
import { useMap } from "../components/MapCanvas/MapCanvas";
import { useTaskingState } from "../contexts/TaskingMapContext";

export function useSyncLayers() {
  const { state } = useTaskingState();
  const map = useMap();
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const disabledLayers = state.disabledLayers;

    const layers = map.getLayers().getArray();

    for (const layer of layers) {
      const tag = layer.get("filterTag") || layer.get("name");

      if (tag && _.includes(disabledLayers, tag)) {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
      }
    }

    // Need to trigger a re-render here because map has changed.
    setLayers(layers);
  }, [map, state.disabledLayers]);

  return {
    layers,
    registerLayer: (layer: Layer) => {
      setLayers([...layers, layer]);
    },
  };
}
