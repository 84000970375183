import { Fetch } from "../types";

export interface Fetcher {
  // eslint-disable-next-line
  req<T>(path: string, params?: RequestInit, contentType?: string): Promise<T>;
}

class fetcher implements Fetcher {
  fetch: Fetch;

  constructor(f: Fetch) {
    this.fetch = f.bind(window);
  }

  // eslint-disable-next-line
  async req(path: string, params?: RequestInit) {
    const res = await this.fetch(path, params);

    if (!res.ok) {
      const b = await res.json();
      if (b.error) {
        throw new Error(b.error);
      }
      throw new Error(res.statusText);
    }

    if (res.headers.get("content-length") === "0") {
      return Promise.resolve();
    }

    return res.json();
  }
}

export function NewFetcher(f: Fetch): Fetcher {
  return new fetcher(f);
}
