import { Box, Grid, Typography } from "@mui/material";
import cx from "classnames";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { MizFile } from "../../lib/models/MizFile";
import Flex from "../Flex";

type Props = {
  className?: string;
  miz: MizFile;
  diff?: MizFile;
};

const cols = [
  { value: "flyableBLUFOR", label: "Flyable BLUFOR Groups" },
  { value: "flyableREDFOR", label: "Flyable REDFOR Groups" },
  { value: "vehiclesBLUFOR", label: "BLUFOR Vehicle Groups" },
  { value: "vehiclesREDFOR", label: "REDFOR Vehicle Groups" },
  { value: "drawingObjects", label: "Drawing Objects" },
  { value: "zones", label: "Trigger Zones" },
];

type StatsProps = {
  miz: MizFile;
  changed?: string[];
};

// https://gist.github.com/Yimiprod/7ee176597fef230d1451
export const difference = (object, base) => {
  return _.reduce(
    object,
    (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? difference(value, base[key])
            : value;
      }
      return result;
    },
    {}
  );
};

function Stats({ miz, changed }: StatsProps) {
  return (
    <Grid container spacing={{ xs: 1, md: 0 }}>
      {_.map(cols, (c) => (
        <Grid
          item
          className={cx({
            "miz-stat-value": true,
            changed: _.includes(changed, c.value),
          })}
          aria-label={c.label}
          key={c.value}
        >
          <Flex column wide align>
            <Typography fontSize={24} fontWeight={600}>
              {_.get(miz.metadata, c.value)}
            </Typography>
            <div>
              <Typography fontSize={12}>{c.label}</Typography>
            </div>
          </Flex>
        </Grid>
      ))}
    </Grid>
  );
}

function MizFileStatistics({ className, miz, diff }: Props) {
  const nameText = diff ? "Old Mission File: " : "";
  return (
    <div className={className}>
      <Box marginBottom={2}>
        <Box>
          <Typography fontWeight={600}>{nameText}</Typography>
        </Box>
        <Stats miz={miz} />
      </Box>

      <div style={{ display: diff ? "block" : "none" }}>
        <Box marginTop={2}>
          <Box marginTop={2}>
            <Typography fontWeight={600} color="#ed6c02">
              New Mission File:{" "}
            </Typography>{" "}
            {diff?.file.name}
          </Box>
          <Box>
            {diff && (
              <Stats
                miz={diff}
                changed={_.keys(difference(diff.metadata, miz.metadata))}
              />
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default styled(MizFileStatistics).attrs({
  className: MizFileStatistics.name,
})`
  .MuiBox-root {
    box-sizing: border-box;
  }

  .changed {
    color: ${(props) => props.theme.palette.warning.main};
  }
`;
