import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import * as React from "react";
import styled from "styled-components";
import { Theater, feetToMeters, metersToFeet } from "../../../lib/map";
import WaypointFeature from "../../../lib/models/WaypointFeature";
import {
  PlanningController,
  StrangeETABugReturnValue,
} from "../../../lib/services/PlanningController";
import { getTimeZoneOffset } from "../../../lib/tasking";
import { totFromEta } from "../../../lib/time";
import { AltitudeType } from "../../../lib/types";
import Flex from "../../Flex";
import TimeOnTargetInput from "./TimeOnTargetInput";

type Props = {
  className?: string;
  feature: WaypointFeature;
  controller: PlanningController;
  missionStart: DateTime;
  theater: Theater;
};

type inputVals = {
  text: any;
  altitude: string;
  altitudeType: any;
  eta: number;
};

function WaypointInput({
  className,
  controller,
  feature,
  missionStart,
  theater,
}: Props) {
  const [inputVals, setValsState] = React.useState<inputVals>(
    defaultVals(feature)
  );
  const wpNum = feature?.properties()?.number;
  const tot = totFromEta(missionStart, inputVals.eta || 0);
  const allWaypoints = controller?.getWaypointsForGroup(feature.group()) || [];
  const totValid = isValidToT(inputVals.eta, feature, allWaypoints);

  React.useEffect(() => {
    setValsState(defaultVals(feature));
  }, [feature, feature?.properties()?.eta]);

  const updateWaypoint = (vals: typeof inputVals) => {
    const altitude = formatAlt(vals.altitude);
    const meters = feetToMeters(altitude);

    const next = {
      ...vals,
      altitude: meters,
    };

    if (isValidToT(vals.eta, feature, allWaypoints)) {
      next.eta = vals.eta;
    }

    controller.setWaypointDetails(feature.group(), wpNum, next);
    setValsState({
      ...vals,
      eta: next.eta,
    });
  };

  return (
    <Box className={className} component="form" marginY={2}>
      <Box paddingBottom={1}>
        <Typography fontSize={12}>Waypoint Details</Typography>
      </Box>

      <Flex>
        <Box marginBottom={1}>
          <Box mr={1}>
            <FormControl>
              <TextField
                autoComplete="off"
                onBlur={(ev) => {
                  updateWaypoint({
                    ...inputVals,
                    text: ev.target.value,
                  });
                }}
                label="Name"
                id="name"
                value={inputVals.text}
                onChange={(ev) => {
                  setValsState({
                    ...inputVals,
                    text: ev.target.value,
                  });
                }}
                size="small"
                variant="outlined"
                placeholder="Name"
              />
            </FormControl>
          </Box>
        </Box>
        <Flex align>
          <Box marginRight={1}>
            <FormControl>
              <TextField
                type="number"
                label="Altitude"
                id="altitude"
                value={inputVals.altitude}
                onChange={(ev) => {
                  updateWaypoint({
                    ...inputVals,
                    altitude: ev.target.value,
                  });
                }}
                size="small"
                variant="outlined"
                placeholder="Altitude"
              />
            </FormControl>
          </Box>

          <Select
            size="small"
            value={
              inputVals.altitudeType === AltitudeType.AGL
                ? AltitudeType.AGL
                : AltitudeType.MSL
            }
            onChange={(ev) =>
              updateWaypoint({
                ...inputVals,
                altitudeType: ev.target.value,
              })
            }
          >
            <MenuItem value={AltitudeType.AGL}>AGL</MenuItem>
            <MenuItem value={AltitudeType.MSL}>MSL</MenuItem>
          </Select>
        </Flex>
      </Flex>

      <Box marginBottom={1}>
        <Box marginTop={1}>
          <TimeOnTargetInput
            valid={totValid}
            eta={inputVals.eta}
            onChange={(val) => {
              updateWaypoint({
                ...inputVals,
                eta: val,
              });
            }}
          />
          <Box marginY={1}>
            <Typography fontSize={12}>Time on Target (zulu/local)</Typography>
            <div>
              <Flex between wide>
                <Typography fontWeight={600}>
                  <span data-testid="zulu-time">
                    {tot
                      .minus({
                        hours: getTimeZoneOffset(theater),
                      })
                      .toFormat("HH:mm:ss")}
                  </span>
                  <span style={{ marginLeft: 8, marginRight: 8 }}>/</span>
                  <span data-testid="local-time">
                    {tot.toFormat("HH:mm:ss")}
                  </span>
                </Typography>
              </Flex>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default styled(WaypointInput).attrs({ className: WaypointInput.name })`
  width: 360px;
`;

function defaultVals(f: WaypointFeature): inputVals {
  const feature = f.properties();

  const alt =
    typeof feature?.altitude !== "undefined" ? feature?.altitude : "0";

  const num = feature?.number;

  const text =
    feature?.name && feature?.name !== num?.toString()
      ? feature?.name
      : num?.toString();

  return {
    text,
    altitude: metersToFeet(alt).toFixed(0).toString(),
    altitudeType: feature?.altitudeType || AltitudeType.AGL,
    eta: Math.floor(feature.eta),
  };
}

const formatAlt = (altString: string) => {
  return Math.floor(parseFloat(altString));
};

function isValidToT(
  eta: number,
  feature: WaypointFeature,
  waypoints: StrangeETABugReturnValue[]
) {
  const prev = _.find(waypoints, { number: feature.properties().number - 1 });

  if (!prev || !prev.waypoint?.ETA) {
    return eta > 0;
  }

  return eta > prev.waypoint.ETA;
}
