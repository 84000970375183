import { Feature, Map as MapOl } from "ol";
import { Point } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { Vector as VectorLayer } from "ol/layer";
import "ol/ol.css";
import { Vector as VectorSource } from "ol/source";
import { Fill, Icon, RegularShape, Stroke, Style, Text } from "ol/style";
import { formatLatLong, metersToFeet } from "../../map";
import { getTerrainHeightAtCoords } from "../../measurements";

const labelStyle = new Style({
  text: new Text({
    font: "16px Courier New,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    padding: [4, 4, 4, 4],
    textAlign: "left",
    textBaseline: "bottom",
    offsetY: -16,
    offsetX: -5,
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
  }),
});

const stroke = new Stroke({ color: "black", width: 2 });
const fill = new Fill({ color: "red" });
const cursorStyle = new Style({
  image: new RegularShape({
    fill: fill,
    stroke: stroke,
    points: 4,
    radius: 10,
    radius2: 0,
    angle: Math.PI / 4,
  }),
});

const styleFunc = (map: MapOl) => (feature: Feature<Point>) => {
  const geometry = feature.getGeometry();
  const type = geometry.getType();

  const styles = [style];

  if (type === "Point") {
    labelStyle.setGeometry(geometry);
    const coords = geometry.getCoordinates();
    const elevation = getTerrainHeightAtCoords(map, coords);

    const txt = formatLatLong([coords[0], coords[1]]);

    txt.push(`${metersToFeet(elevation).toFixed(0)} FT`);

    labelStyle.getText().setText(txt.join("\n"));
    styles.push(labelStyle);
  }
  return styles;
};

const svg =
  '<svg width="120" height="120" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
  '<circle cx="60" cy="60" r="5"/>' +
  "</svg>";

const style = new Style({
  fill: new Fill({
    color: "rgba(0, 0, 0, 1)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 1)",
    width: 10,
  }),
  image: new Icon({
    opacity: 1,
    src: "data:image/svg+xml;utf8," + svg,
    scale: 1,
  }),
});

export default function addPointDrop(map: MapOl) {
  const source = new VectorSource({ wrapX: false });
  const vector = new VectorLayer({
    source: source,
    style: styleFunc(map),
    zIndex: 2,
  });
  const draw = new Draw({
    source: source,
    type: "Point",
    style: cursorStyle,
  });

  map.addLayer(vector);
  map.addInteraction(draw);

  return { draw, clear: () => source.clear() };
}
