import { Map as MapOl } from "ol";
import { touchOnly } from "ol/events/condition";
import Draw from "ol/interaction/Draw";
import { Vector as VectorLayer } from "ol/layer";
import "ol/ol.css";
import { Vector as VectorSource } from "ol/source";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { eventIsLeftClick, eventIsMouseMove } from "./helpers";

const style = new Style({
  fill: new Fill({
    color: "rgba(0, 0, 0, 1)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 1)",
    width: 10,
  }),
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: "rgba(0, 0, 0, 1)",
    }),
  }),
});

export default function addFreehand(map: MapOl) {
  const source = new VectorSource();
  const vector = new VectorLayer({
    source: source,
    style,
    zIndex: 2,
  });
  const draw = new Draw({
    source: source,
    type: "LineString",
    style,
    freehand: true,
    freehandCondition: (event) => {
      if (touchOnly(event)) {
        return true;
      }

      return eventIsMouseMove(event) || eventIsLeftClick(event);
    },
  });
  map.addLayer(vector);
  map.addInteraction(draw);

  return { draw, clear: () => source.clear() };
}
