import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGroupsWithPlans } from "../../contexts/PlanManagerContext";
import {
  useAddPlanItem,
  useGetPlanItem,
  useUpdatePlanItem,
} from "../../contexts/PlanManagerV2";
import { useTaskingState } from "../../contexts/TaskingMapContext";
import { getTypeForGroup, Module, modules } from "../../lib/data/modules";
import { calcFuelPlanDetails } from "../../lib/fuel";
import { FuelPlan, PlanItemDoc, PlanItemType } from "../../lib/models/PlanItem";
import { cumulativeDistance } from "../../lib/time";
import { MinimalWaypoint } from "../../lib/types";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";

type Props = {
  className?: string;
  groupName: string;
  readonly?: boolean;
};

function FuelPlanner({ className, groupName, readonly }: Props) {
  const {
    state: { manifest, tasking },
  } = useTaskingState();
  const [saving, setSaving] = React.useState(false);
  const { success, error } = useNotification();
  const add = useAddPlanItem(manifest.id);
  const update = useUpdatePlanItem(manifest.id);

  const { data: groupsWithPlans } = useGroupsWithPlans(
    manifest.fragOrderID,
    tasking?.plannedGroups
  );

  const { data: item } = useGetPlanItem(
    manifest.id,
    groupName,
    PlanItemType.FuelPlan
  );

  const existing = React.useMemo<FuelPlan>(() => {
    if (!item) {
      return null;
    }

    return JSON.parse(item.payload);
  }, [item?.payload]);

  const isExisting = !!existing;

  const group = _.find(groupsWithPlans, { name: groupName });
  const t = getTypeForGroup(group);
  const mod: Module = modules[t];
  const defaultVals = mod?.fuel?.defaultValues || {};
  const lastWP = _.last(group.waypoints);
  const [formState, setFormState] = React.useState<FuelPlan>({
    groundSpeed: 0,
    fuelFlow: 0,
    bingo: 0,
    externalTanks: 0,
    ...defaultVals,
    targetWaypoint: lastWP.number,
  });

  React.useEffect(() => {
    if (existing) {
      setFormState(existing);
    } else {
      setFormState({
        ...defaultVals,
      } as FuelPlan);
    }
  }, [groupName, existing]);

  const endWP = _.find(group.waypoints, { number: formState.targetWaypoint });

  const distanceToTarget = formState.targetWaypoint
    ? cumulativeDistance(
        tasking.theater,
        endWP || ({} as MinimalWaypoint),
        group.waypoints
      )
    : 0;

  const firstUnitInternalFuel = _.get(group, ["units", 0, "internalFuel"]);
  const isWarnMismatchFuelLevels = !_.every(
    group.units,
    (u) => u.internalFuel === firstUnitInternalFuel
  );

  const {
    time,
    totalFuel,
    fuelAtTarget,
    loiterTime,
    fuelAtLanding,
  } = calcFuelPlanDetails(
    mod,
    tasking.theater,
    endWP,
    group.waypoints,
    formState,
    firstUnitInternalFuel
  );

  const handleSave = () => {
    setSaving(true);
    const plan = {
      group: groupName,
      type: "FuelPlan",
      manifestID: manifest.id,
      payload: JSON.stringify(formState),
    } as PlanItemDoc;

    const s = () => {
      success("Fuel Plan Saved");
    };
    const c = (e: Error) => {
      error(e.message);
    };

    const f = () => {
      setSaving(false);
    };

    if (!isExisting) {
      return add(plan).then(s).catch(c).finally(f);
    }

    return update({
      ...item,
      ...plan,
    } as PlanItemDoc)
      .then(s)
      .catch(c)
      .finally(f);
  };

  if (readonly && !existing) {
    return <Alert severity="warning">No fuel data found</Alert>;
  }

  if (!mod?.fuel) {
    return <Alert severity="warning">No fuel data found</Alert>;
  }

  return (
    <div className={className}>
      <Box p={2}>
        <h4 style={{ margin: 0 }}>
          {groupName} - {mod?.label} Fuel Plan
        </h4>
        <div>
          <Box my={2}>
            {isWarnMismatchFuelLevels && (
              <Alert severity="warning">
                Warning: Mismatched fuel levels detected in group. Using first
                unit's fuel level for calculations.
              </Alert>
            )}
            <FormControl size="small">
              <InputLabel>Target Waypoint</InputLabel>
              <Select
                disabled={readonly}
                size="small"
                style={{ minWidth: 210 }}
                label="Target Waypoint"
                value={formState.targetWaypoint}
                onChange={(ev) =>
                  setFormState({
                    ...formState,
                    targetWaypoint: ev.target.value as number,
                  })
                }
              >
                {_.map(group?.waypoints, (wp) => (
                  <MenuItem key={wp.number} value={wp.number}>
                    {wp.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div>
          <KeyValueTable
            columns={2}
            pairs={[
              {
                key: "Route Distance",
                value: `${distanceToTarget.toFixed(2)} NM`,
              },
              {
                key: "Round Trip",
                value: `${(distanceToTarget * 2).toFixed(2)} NM`,
              },
              {
                key: "Starting Fuel",
                value: totalFuel,
              },
              {
                key: "Engine Count",
                value: mod?.fuel?.engineCount,
              },
            ]}
          />
        </div>
        <Divider />
        <Box mt={2}>
          <form>
            <Box mb={2}>
              <Flex>
                <FormControl>
                  <TextField
                    disabled={readonly}
                    label="Ground Speed (knots)"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    value={formState.groundSpeed}
                    onChange={(ev) =>
                      setFormState({
                        ...formState,
                        groundSpeed: parseFloat(ev.target.value),
                      })
                    }
                  />
                </FormControl>
                <Box ml={2}>
                  <FormControl>
                    <TextField
                      disabled={readonly}
                      label="Fuel Flow (PPH)"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      value={formState.fuelFlow}
                      inputProps={{
                        step: 100,
                      }}
                      onChange={(ev) =>
                        setFormState({
                          ...formState,
                          fuelFlow: parseFloat(ev.target.value),
                        })
                      }
                    />
                  </FormControl>
                </Box>
              </Flex>
            </Box>

            <Box mb={2}>
              <Flex>
                <FormControl>
                  <TextField
                    disabled={readonly}
                    label="Bingo"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={formState.bingo}
                    inputProps={{
                      step: 100,
                    }}
                    onChange={(ev) =>
                      setFormState({
                        ...formState,
                        bingo: parseInt(ev.target.value),
                      })
                    }
                  />
                </FormControl>
                <Box ml={2}>
                  <FormControl>
                    <TextField
                      disabled={readonly}
                      type="number"
                      label="External Tanks"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      value={formState.externalTanks}
                      inputProps={{
                        max: mod?.fuel.maxExternalTanks || "3",
                      }}
                      onChange={(ev) => {
                        setFormState({
                          ...formState,
                          externalTanks: parseInt(ev.target.value),
                        });
                      }}
                    />
                  </FormControl>
                </Box>
              </Flex>
            </Box>
          </form>
        </Box>
        <Divider />
        <Box mb={2}>
          <KeyValueTable
            columns={4}
            pairs={[
              { key: "Travel Time (minutes)", value: time.toFixed(1) },
              {
                key: "Fuel at Target (pounds)",
                value: fuelAtTarget.toFixed(0),
              },
              { key: "Loiter Time (minutes)", value: loiterTime.toFixed(0) },
              {
                key: "Fuel at Landing (pounds)",
                value: fuelAtLanding.toFixed(0),
              },
            ]}
          />
        </Box>
        {!readonly && (
          <LoadingButton
            loading={saving}
            onClick={handleSave}
            variant="contained"
          >
            Save
          </LoadingButton>
        )}
      </Box>
    </div>
  );
}

export default styled(FuelPlanner).attrs({ className: FuelPlanner.name })``;
