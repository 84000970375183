const WHICH_RIGHT_CLICK = 3;
const WHICH_LEFT_CLICK = 1;
const WHICH_MOUSE_MOVE = 0;

export function eventIsLeftClick(event) {
  return event.originalEvent.which === WHICH_LEFT_CLICK;
}

export function eventIsMouseMove(event) {
  return event.originalEvent.which === WHICH_MOUSE_MOVE;
}

export function eventIsRightClick(event) {
  return event.originalEvent.which === WHICH_RIGHT_CLICK;
}
