import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  open?: boolean;
  onClose?: () => void;
  children?: any;
  message?: string;
  title?: string;
};

function ConfirmationDialog({
  className,
  onClose,
  open,
  children,
  message,
  title,
}: Props) {
  return (
    <div className={className}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{children}</DialogActions>
      </Dialog>
    </div>
  );
}

export default styled(ConfirmationDialog).attrs({
  className: ConfirmationDialog.name,
})``;
