import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useSubmitExternalData } from "../../hooks/external_data";
import { useAdminGetFragOrderID } from "../../hooks/frag_orders";
import { RecordType } from "../../lib/models/ExternalDataRecord";
import { Coalition } from "../../lib/types";
import CSVImport from "../CSVImport/CSVImport";
import ModalContent from "../ModalContent";
import Section from "../Section";
import DownloadTemplateButton from "./DownloadTemplateButton";

type Props = {
  className?: string;
  open: boolean;
  onClose: () => void;
};

function ImportModal({ className, open, onClose }: Props) {
  const fragOrderID = useAdminGetFragOrderID();
  const { success, error } = useNotification();
  const submit = useSubmitExternalData(fragOrderID);

  const [formData, setFormData] = React.useState({
    coalition: Coalition.Blue,
    recordType: RecordType.AircraftAdminDetails,
  });

  const handleSubmit = (rows) => {
    return submit(formData.recordType, formData.coalition, JSON.stringify(rows))
      .then(() => {
        onClose();
        success("Imported external data successfully");
      })
      .catch((e) => {
        console.error(e);
        error(e.message);
      });
  };

  return (
    <Modal className={className} open={open} onClose={onClose}>
      <ModalContent style={{ minWidth: 600 }}>
        <Section title="Import Batch Edit Data">
          <div>
            <div>
              <Box mr={2}>
                <CoalitionSelect
                  onChange={(ev) =>
                    setFormData({
                      ...formData,
                      coalition: ev.target.value as Coalition,
                    })
                  }
                  value={formData.coalition}
                />
              </Box>
            </div>
          </div>

          <CSVImport
            onSubmit={handleSubmit}
            onError={(err: Error) => error(err.message)}
            additionalActions={<DownloadTemplateButton {...formData} />}
            style={{ maxHeight: "80vh" }}
            previewStyle={{
              maxHeight: "60vh",
              overflowY: "auto",
            }}
          >
            <div>
              <p>Import data from a CSV file.</p>
            </div>

            {/* <Flex wide align>
              <Box mr={2}>
                <Box mr={2}>
                  <CoalitionSelect
                    onChange={(ev) =>
                      setFormData({
                        ...formData,
                        coalition: ev.target.value as Coalition,
                      })
                    }
                    value={formData.coalition}
                  />
                </Box>
              </Box>

              <FormControl>
                <FormLabel>Data Type</FormLabel>
                <Select
                  value={formData.recordType}
                  onChange={(ev) => {
                    setFormData({
                      ...formData,
                      recordType: ev.target.value as RecordType,
                    });
                  }}
                >
                  <MenuItem value={RecordType.GroupAdminDetails}>
                    Group Admin Details
                  </MenuItem>
                  <MenuItem value={RecordType.AircraftAdminDetails}>
                    Aircraft Admin Details
                  </MenuItem>
                </Select>
              </FormControl>
            </Flex> */}
          </CSVImport>
        </Section>
      </ModalContent>
    </Modal>
  );
}

function CoalitionSelect({ value, onChange }) {
  return (
    <FormControl>
      <FormLabel>Coalition</FormLabel>
      <Select value={value} onChange={onChange}>
        <MenuItem value={Coalition.Blue}>Blue</MenuItem>
        <MenuItem value={Coalition.Red}>Red</MenuItem>
      </Select>
    </FormControl>
  );
}
export default styled(ImportModal).attrs({ className: ImportModal.name })`
  min-width: 400px;
`;
