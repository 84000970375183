import * as React from "react";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import { NewHTMLImageGenerator } from "../../lib/services/HTMLImageGenerator";
import { NewImageComposer } from "../../lib/services/ImageComposer";
import KneeboardMasterFreqTable from "./KneeboardMasterFreqTable";
import { KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH } from "./kneeboard_utils";

export async function renderMasterFrequencyCard(cfg: RadioPresetConfig) {
  const g = NewHTMLImageGenerator();
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);
  const el = <KneeboardMasterFreqTable radioConfig={cfg} />;

  const blob = await g.renderToBlob(el, {
    height: KNEEBOARD_HEIGHT,
    width: KNEEBOARD_WIDTH - 8,
  });

  composer.add(blob, { x: 0, y: 0 });

  return composer;
}
