import { Alert, Box, Button, Paper, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGetPublishStatus } from "../../contexts/PublishManagerContext";
import {
  PublishManifestDoc,
  publishManifestNavURL,
} from "../../lib/models/PublishManifest";
import { AppRoutes } from "../../lib/types";
import DataTable from "../DataTable";
import Flex from "../Flex";
import Link from "../Link";
import LoadingPage from "../LoadingPage";

type Props = {
  className?: string;
  manifests: PublishManifestDoc[];
};

function PublishStatus({ className }: Props) {
  const { success } = useNotification();
  const { id } = useParams();

  const { data: manifests, isLoading } = useGetPublishStatus(id as string);

  return (
    <Paper className={className}>
      {isLoading && <LoadingPage />}
      {(!isLoading && !manifests) || manifests?.length === 0 ? (
        <div>
          <Alert severity="warning">
            This Frag Order has not been published
          </Alert>

          <Box marginTop={2}>
            <Flex wide center>
              <Link to={`${AppRoutes.FragOrderDetail}/${id}/publish`}>
                View Publish Options
              </Link>
            </Flex>
          </Box>
        </div>
      ) : (
        <>
          <div>
            <Typography fontSize={16} fontWeight={600}>
              Publish Status
            </Typography>
            <DataTable
              size="small"
              columns={[
                {
                  value: (pm: PublishManifestDoc) => _.capitalize(pm.coalition),
                  label: "Coalition",
                },
                {
                  value: (m: PublishManifestDoc) => `v${m.version}`,
                  label: "Version",
                },
                {
                  value: (pm: PublishManifestDoc) => (
                    <Button
                      color={pm.coalition === "red" ? "error" : "primary"}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          publishManifestNavURL(pm)
                        );

                        success("Copied to clipboard");
                      }}
                      variant="outlined"
                      size="small"
                    >
                      Copy Link
                    </Button>
                  ),
                  label: "",
                },
                {
                  value: (pm: PublishManifestDoc) => (
                    <Button
                      color={pm.coalition === "red" ? "error" : "primary"}
                      variant="contained"
                      target="_blank"
                      href={publishManifestNavURL(pm)}
                    >
                      View
                    </Button>
                  ),
                  label: "",
                },
              ]}
              rows={manifests}
            />
          </div>
        </>
      )}
    </Paper>
  );
}

export default styled(PublishStatus).attrs({ className: PublishStatus.name })`
  padding: 16px;
  thead {
    display: none;
  }
`;
