import _ from "lodash";
import styled from "styled-components";
import {
  useTaskingState,
  useToggleLayer,
} from "../../contexts/TaskingMapContext";
import LayerSelector, { Val } from "./LayerSelector";

import * as React from "react";
import { useSyncLayers } from "../../hooks/map";

export type LayerGroup = {
  title: string;
  layers: any[];
  colorized?: boolean;
};

type Props = {
  className?: string;
  layers: LayerGroup[];
};

function LayerControl({ className }: Props) {
  const { layers: ml } = useSyncLayers();
  const {
    state: { disabledLayers },
  } = useTaskingState();

  const toggle = useToggleLayer();

  const controllableLayers = _.filter(ml, (l) => {
    return !!l.get("hideable");
  });

  const layers = _.sortBy(
    _.map(controllableLayers, (l) => {
      return {
        label: l.get("name"),
        value: l.get("filterTag") || l.get("name"),
        enabled: !_.includes(disabledLayers, l.get("filterTag")),
      };
    }),
    "value"
  );

  const handleLayerSelect = (changed: Val) => {
    toggle([changed.value]);
  };

  return (
    <div className={className}>
      <LayerSelector
        heading="Layers"
        items={layers}
        selectedLayers={_.filter(
          layers,
          (l) => !_.includes(disabledLayers, l.value)
        )}
        onChange={handleLayerSelect}
      />
    </div>
  );
}

export default styled(LayerControl).attrs({ className: LayerControl.name })`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 36px;
  right: 16px;

  ${LayerSelector} {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    display: none;
  }
`;
