import { FormLabel, Typography } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

const Label = styled(({ className, children, name, required }) => (
  <FormLabel className={className} htmlFor={name} required={required}>
    <Typography>{children}</Typography>
  </FormLabel>
))`
  label {
    margin-bottom: 4px;
  }
`;

export default Label;
