import * as React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  onSubmit: (state: Record<string, string | number | boolean>) => any;
  children?: React.ReactNode;
};

type FormDataWithEntries = FormData & {
  entries: () => [string, string][];
};

const FastForm = React.forwardRef<HTMLFormElement, Props>(
  ({ className, children, onSubmit }: Props, ref) => {
    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      const form = ev.target as HTMLFormElement;
      const formData = new FormData(form) as FormDataWithEntries;

      const formJson = Object.fromEntries(formData.entries());

      onSubmit(formJson);
    };

    return (
      <form ref={ref} className={className} onSubmit={handleSubmit}>
        {children}
      </form>
    );
  }
);

export default styled(FastForm).attrs({ className: FastForm.name })``;
