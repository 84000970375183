import JSZip from "jszip";
import { Fetch } from "../types";

export interface MizStorer {
  save(docID: string, file: File): Promise<any>;
  download(docID: string): Promise<JSZip>;
}

class S3 {
  fetch: Fetch;
  constructor(f: Fetch) {
    this.fetch = f.bind(window);
  }

  async save(docID: string, file: File) {
    const { uploadURL } = await fetch("/.netlify/functions/miz_upload_url", {
      method: "POST",
      body: JSON.stringify({ docID }),
    }).then((res) => res.json());

    return this.fetch(uploadURL, {
      method: "PUT",
      // @ts-ignore
      body: file,
    });
  }

  async download(docID: string) {
    const { downloadURL } = await fetch(
      "/.netlify/functions/miz_download_url",
      {
        method: "POST",
        body: JSON.stringify({ docID }),
      }
    ).then((res) => res.json());

    const zip = await fetch(downloadURL, {
      headers: {
        "content-type": "application/zip",
      },
    }).then((res) => res.blob());

    return await JSZip.loadAsync(zip);
  }
}

export function NewMizStorer(f: Fetch): MizStorer {
  return new S3(f);
}
