import { Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import * as React from "react";
import DiscordLoginButton from "../components/DiscordLoginButton";
import Flex from "../components/Flex";
import Logo, { LogoVariant } from "../components/Logo";
// @ts-ignore
import hero from "../img/fo_hero_3.png";
// @ts-ignore
import Link from "../components/Link";
// @ts-ignore
import showoff from "../img/showoff.png";
import { AppRoutes } from "../lib/types";

// function Copyright(props: any) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const LoginHeader = styled(Flex)`
  background-color: white;
  height: 64px;
  box-sizing: border-box;

  .MuiButton-root {
    margin: 0;
  }
`;

function SignIn({ className }) {
  return (
    <div style={{ width: "100%" }}>
      <LoginHeader align wide center>
        <Flex wide>
          <Box marginLeft={1}>
            <Link to={AppRoutes.Home}>
              <Logo variant={LogoVariant.LeftAligned} width={120} />
            </Link>
          </Box>
        </Flex>

        <Flex wide end>
          <Box marginRight={1}>
            <DiscordLoginButton />
          </Box>
        </Flex>
      </LoginHeader>

      <Container className={className} component="main">
        <Paper>
          <Box
            p={2}
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Flex column>
              <Flex center wide>
                <h2>
                  Visualize and Plan your Multiplayer DCS World Missions with
                  Frag Orders
                </h2>
              </Flex>
              <Flex center wide>
                <img
                  className="intro-img"
                  height={468}
                  width={768}
                  src={showoff}
                />
              </Flex>

              <Box m={1}>
                <Typography textAlign="center" fontWeight={600} fontSize={18}>
                  For Mission Creators
                </Typography>

                <Box m={1}>
                  <b>Publish your mission:</b> Frag Orders extracts mission data
                  and publishes to a hosted web page so that mission
                  participants can see important information, such as waypoints,
                  threat rings, and enemy locations.
                </Box>
                <Box m={1}>
                  <b>Auto-generate kneeboards:</b> Automatically create briefing
                  materials using data extracted straight from the DCS .miz file
                  with the press of a button.
                </Box>
                <Box m={1}>
                  <b>Let the pilots plan:</b> Mission participants will be able
                  to drop waypoints, set radio frequencies, and add briefing
                  notes to their in-game kneeboards.
                </Box>
                <Box marginTop={2}>
                  <Typography textAlign="center" fontWeight={600} fontSize={18}>
                    For Mission Participants
                  </Typography>
                  <Box m={1}>
                    <b>Review the plan:</b> Start building your situational
                    awarness before the mission.
                  </Box>
                  <Box m={1}>
                    <b>Collaborate with your wingmen: </b> Frag Orders includes
                    planning and drawing tools to help organize and communicate
                    with your flight.
                  </Box>
                  <Box m={1}>
                    <b>Plan your flight:</b> Add and modify waypoints from the
                    planning interface that will be written to the .miz file.
                    Planners can also edit radio frequencies and add notes to
                    their in-game kneeboard.
                  </Box>
                </Box>
              </Box>
            </Flex>
            <Box>
              <h3>Watch the Tutorial</h3>
            </Box>
            <Flex center wide>
              <iframe
                src="https://www.youtube.com/embed/GquZeyn8qJs"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </Flex>

            <Box marginTop={4}>
              <Flex wide center>
                <h3>FAQ</h3>
              </Flex>

              <Box>
                <Box m={3}>
                  <Box m={1}>
                    <b>Q:</b> What is Frag Orders?
                  </Box>
                  <Box m={1}>
                    <b>A:</b> Frag Orders is a visualization and mission
                    planning tool for DCS World that focuses on planning
                    multiplayer missions.
                  </Box>
                </Box>
                <Box m={3}>
                  <Box m={1}>
                    <b>Q</b>: How does Frag Orders work?
                  </Box>
                  <Box m={1}>
                    <b>A:</b> When a mission creator uploads their DCS World
                    .miz file, Frag Orders parses the file and extracts the
                    relevant data for the Frag Order visualization. This data is
                    saved on the Frag Orders server. Once the Frag Order is
                    published, a public URL is created that can be distributed
                    to mission participants.
                  </Box>
                </Box>

                <Box m={3}>
                  <Box m={1}>
                    <b>Q:</b> As the mission creator, can I control which items
                    appear on the Frag Order map?
                  </Box>
                  <Box m={1}>
                    <b>A:</b> Yes, the mission creator can choose which data is
                    publish to the public Frag Order. For example, it is
                    possible to hide enemy groups to provide a more dynamic
                    experience for mission participants. Groups marked as
                    "hidden" in the DCS World mission editor are hidden in the
                    Frag Order map by default.
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Paper>
        <div className="background-container" />
      </Container>
    </div>
  );
}

export default styled(SignIn)`
  .background-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)),
      url(${hero});
    /* background-repeat: no-repeat; */
    z-index: -1;
  }

  .intro-img,
  iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  @media only screen and (min-width: 600px) {
    iframe {
      height: 480px;
      width: 640px;
    }
  }
`;
