import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { LineString, Point, Polygon } from "ol/geom";
import React from "react";
import { FeatureClass } from "../types";
import { BaseFeature } from "./BaseFeature";
import NavPointFeature from "./NavPointFeature";
import WaypointFeature from "./WaypointFeature";

export interface FragOrderMapFeature {
  name(): string;
  coordinates(): Coordinate;
  render(): React.ReactElement;
  properties(): { [x: string]: any };
  title(): string;
  class(): FeatureClass;
  selectable(): boolean;
}

export function NewMapFeature(
  f: Feature<Point | LineString | Polygon>
): FragOrderMapFeature {
  if (f.get("featureClass") === FeatureClass.Waypoint) {
    return new WaypointFeature(f as Feature<Point>);
  }

  if (f.get("featureClass") === FeatureClass.NavPoint) {
    return new NavPointFeature(f as Feature<Point>);
  }

  return new BaseFeature(f);
}
