import {
  Firestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { docs } from "../db_util";
import { SignupRecord } from "../models/SignupRecord";
import { Fetcher } from "./Fetcher";

const ADD_SIGNUP_PATH = "/.netlify/functions/add_signup";
const REMOVE_SIGNUP_PATH = "/.netlify/functions/remove_signup";

export const SignupRecordCollection = "SignupRecords";

export interface PublicSignupClient {
  signup(
    manifestID: string,
    record: SignupRecord,
    discordNickname: string
  ): Promise<void>;
  list(manifestID: string): Promise<SignupRecord[]>;
  remove(signupID: string): Promise<void>;
}

export class SignupClient implements PublicSignupClient {
  fetcher: Fetcher;
  db: Firestore;

  constructor(fetch: Fetcher, db: Firestore) {
    this.fetcher = fetch;
    this.db = db;
  }

  async signup(
    manifestID: string,
    record: SignupRecord,
    discordNickname: string
  ): Promise<void> {
    return this.fetcher.req(ADD_SIGNUP_PATH, {
      method: "POST",
      body: JSON.stringify({
        manifestID,
        record,
        discordNickname,
      }),
    });
  }

  async list(manifestID: string): Promise<SignupRecord[]> {
    const q = query(
      collection(this.db, SignupRecordCollection),
      where("manifestID", "==", manifestID)
    );

    const querySnapshot = await getDocs(q);

    return docs<SignupRecord>(querySnapshot);
  }

  async remove(signupID: string): Promise<void> {
    return this.fetcher.req(REMOVE_SIGNUP_PATH, {
      method: "POST",
      body: JSON.stringify({
        signupID,
      }),
    });
  }
}
