import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { GroupLineupCardSettings } from "../../lib/models/Job";

type Props = {
  className?: string;
  onChange?: (opts: GroupLineupCardSettings) => void;
  settings: GroupLineupCardSettings;
};

function LineupCardSettings({ className, onChange, settings }: Props) {
  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showTimeOnTarget}
            onChange={(ev) =>
              onChange({ ...settings, showTimeOnTarget: ev.target.checked })
            }
          />
        }
        label="Display Time-on-Target"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showDistance}
            onChange={(ev) =>
              onChange({ ...settings, showDistance: ev.target.checked })
            }
          />
        }
        label="Display Distance"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showAltitude}
            onChange={(ev) =>
              onChange({ ...settings, showAltitude: ev.target.checked })
            }
          />
        }
        label="Display Altitude"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showCoords}
            onChange={(ev) =>
              onChange({ ...settings, showCoords: ev.target.checked })
            }
          />
        }
        label="Display Coordinates"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showFreqs}
            onChange={(ev) =>
              onChange({ ...settings, showFreqs: ev.target.checked })
            }
          />
        }
        label="Display Comms Table"
      />
    </FormGroup>
  );
}

export default styled(LineupCardSettings).attrs({
  className: LineupCardSettings.name,
})``;
