import { APIUser } from "discord-api-types/v10";
import _ from "lodash";
import { Collection, Feature, Map } from "ol";
import { Geometry, Point } from "ol/geom";
import { Draw, Modify } from "ol/interaction";
import { ModifyEvent } from "ol/interaction/Modify";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {
  Circle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text as TextStyle,
} from "ol/style";
import React from "react";
import {
  formatLabel,
  navPointLayerName,
  navPointStyle,
} from "../../components/MapCanvas/NavPointLayer";
import {
  TaskingMapActions,
  TaskingMapActionTypes,
} from "../../contexts/TaskingMapContext";
import { eventIsRightClick } from "../layers/interactions/helpers";
import { convertLatLongToDCS, Theater } from "../map";
import { PlanStatus } from "../models/Plan";
import { PublishManifestDoc } from "../models/PublishManifest";
import { DCSGroupData, FeatureClass } from "../types";
import PlanNavPointManager from "./PlanManagerV2/PlanNavPointManager";

const sourceNameKey = "navSource";

export class NavPointController {
  map: Map;
  dispatch: React.Dispatch<TaskingMapActions>;
  colorMap: Record<string, string>;

  private sources: VectorSource[] = [];
  private layers: VectorLayer<VectorSource>[] = [];
  private modify: Modify;

  manifest: PublishManifestDoc;
  mgr: PlanNavPointManager;
  user: APIUser;
  theater: Theater;
  onSuccess: () => void;
  onError: (e: Error) => void;

  constructor(
    map: Map,
    dispatch: React.Dispatch<TaskingMapActions>,
    colorMap: Record<string, string>,
    manifest: PublishManifestDoc,
    manager: PlanNavPointManager,
    user: APIUser,
    theater: Theater,
    onSuccess: () => void,
    onError: (e: Error) => void
  ) {
    this.map = map;
    this.dispatch = dispatch;
    this.colorMap = colorMap;
    this.manifest = manifest;
    this.mgr = manager;
    this.user = user;
    this.theater = theater;
    this.onSuccess = onSuccess;
    this.onError = onError;
  }

  init(groups: DCSGroupData[]) {
    if (!this.map) {
      return;
    }

    const mapLayers = this.map.getLayers().getArray();

    for (const group of groups) {
      const layer = _.find(
        mapLayers,
        (layer) => layer.get("name") === navPointLayerName(group.name)
      ) as VectorLayer<VectorSource>;

      if (!layer) {
        continue;
      }

      const source = layer.getSource();
      source.set(sourceNameKey, group.name);
      this.sources.push(source);
      this.layers.push(layer);

      layer.setStyle(navPointModifyStyle(this.colorMap));
    }

    this.refreshModify();
  }

  private refreshModify() {
    if (this.modify) {
      this.map.removeInteraction(this.modify);
      delete this.modify;
    }

    const features: Feature<Geometry>[] = [];

    for (const source of this.sources) {
      features.push(...source.getFeatures());
    }

    const collection = new Collection(features);

    this.modify = new Modify({
      features: collection,
      style: modifyText,
    });

    this.modify.on("modifyend", (e: ModifyEvent) => {
      const feature = e.features.getArray()[0];
      const groupName = feature?.get("group");
      this.handlePlanUpdate(groupName, feature);
    });

    this.map.addInteraction(this.modify);
  }

  getPlannedNavPoints(groupName: string) {
    const source = _.find(
      this.sources,
      (source) => source.get(sourceNameKey) === groupName
    );

    if (!source) {
      return [];
    }

    return source.getFeatures() as Feature<Point>[];
  }

  toggleModify(enabled: boolean) {
    if (this.modify) {
      this.modify.setActive(enabled);
    }

    for (const layer of this.layers) {
      const groupName = layer.getSource().get(sourceNameKey);

      const color = this.colorMap[groupName];

      const style = enabled
        ? navPointModifyStyle(this.colorMap)
        : navPointStyle(color);

      layer.setStyle(style);
    }
  }

  draw: Draw;

  findSource(groupName: string) {
    return _.find(
      this.sources,
      (source) => source.get(sourceNameKey) === groupName
    );
  }

  findFeature(groupName: string, index: number) {
    const source = this.findSource(groupName);
    if (!source) {
      return;
    }

    return _.find(
      source.getFeatures(),
      (feature) => feature.get("point").index === index
    );
  }

  toggleAddMode(enabled: boolean, group: string) {
    if (!enabled && this.draw) {
      this.map.removeInteraction(this.draw);
      delete this.draw;
      this.refreshModify();
      return;
    }

    if (enabled) {
      this.dispatch({
        type: TaskingMapActionTypes.toggleNavPointMode,
        enabled: true,
        group,
      });
    }

    if (enabled && !this.draw) {
      const source = this.findSource(group);

      if (!source) {
        throw new Error("No source found for group");
      }

      const draw = new Draw({
        source,
        type: "Point",
        condition: (e) => {
          if (eventIsRightClick(e)) {
            return false;
          }
          return true;
        },
        style: drawTipStyle,
      });

      draw.on("drawstart", (e) => {
        const indexes = _.map(
          source.getFeatures(),
          (f) => f.get("point").index
        );

        const next = indexes.length === 0 ? 1 : _.max(indexes) + 1;
        e.feature.setProperties({
          name: `${group} - Nav Point ${next}`,
          featureClass: FeatureClass.NavPoint,
          group,
          point: { index: next, textComment: next.toString() },
        });
      });

      draw.on("drawend", (e) => {
        this.handlePlanUpdate(group, e.feature);
      });

      // @ts-ignore
      this.map.on("pointerup", (e) => {
        if (eventIsRightClick(e)) {
          this.map.removeInteraction(draw);
          this.dispatch({
            type: TaskingMapActionTypes.toggleNavPointMode,
            enabled: false,
            group,
          });
          this.refreshModify();
          return;
        }
      });

      this.map.addInteraction(draw);
    }
  }

  updateNavPointText(groupName: string, index: number, text: string) {
    const feature = this.findFeature(groupName, index);
    if (!feature) {
      return;
    }

    feature.setProperties({
      point: { ...feature.get("point"), textComment: text },
    });

    this.handlePlanUpdate(groupName, feature);
  }

  async deleteNavPoint(groupName: string, index: number) {
    const feature = this.findFeature(groupName, index);
    if (!feature) {
      return;
    }

    const source = this.findSource(groupName);
    if (!source) {
      return;
    }

    source.removeFeature(feature);
    if (this.manifest.version >= 3) {
      await this.mgr.deleteNavPoint(this.manifest.id, groupName, index);
    } else {
      this.handlePlanUpdate(groupName, feature);
    }
  }

  async handlePlanUpdate(groupName: string, feature: Feature<Geometry>) {
    // V2 and lower use central state
    this.dispatch({
      type: TaskingMapActionTypes.setPlanStatus,
      status: PlanStatus.Draft,
      groupName,
    });

    // V3 and higher use PlanManagerV2
    if (this.manifest.version >= 3) {
      const point = feature.get("point");
      const [long, lat] = (feature.getGeometry() as Point).getCoordinates();
      const coords = convertLatLongToDCS(this.theater, { lat, long });

      feature.get("point").x = coords.x;
      feature.get("point").y = coords.y;

      await this.mgr.upsertNavPoint(
        this.manifest.id,
        this.user,
        groupName,
        point
      );

      this.onSuccess();
    }
  }
}

const navPointModifyStyle = (colorMap) => (feature: Feature<Geometry>) => {
  const groupName = feature.get("group");
  const comment = feature.get("point")?.textComment?.toString() || "";

  const text = formatLabel(comment);

  return [
    new Style({
      image: new RegularShape({
        fill: new Fill({ color: colorMap[groupName] }),
        stroke: new Stroke({ color: "black" }),
        points: 4,
        radius: 12,
        angle: 0,
      }),
      text: new TextStyle({
        overflow: true,
        font: 'bold 18px "Roboto", "Arial Unicode MS", "sans-serif"',
        placement: "point",
        fill: new Fill({ color: colorMap[groupName] }),
        stroke: new Stroke({ color: "black", width: 2 }),
        text,
        offsetY: 20,
      }),
    }),
  ];
};

const modifyText = (f: Feature<Geometry>) => {
  const v: Feature = _.first(f.get("features"));
  const comment = v.get("point")?.textComment?.toString();
  const text = formatLabel(comment);
  const group = v.get("group");

  return [
    new Style({
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.7)",
        }),
        fill: new Fill({
          color: "rgba(0, 0, 0, 0.4)",
        }),
      }),
      text: new TextStyle({
        text: `${group}/${text} - Drag to move`,
        font: "14px Roboto,sans-serif",
        fill: new Fill({
          color: "rgba(255, 255, 255, 1)",
        }),
        backgroundFill: new Fill({
          color: "rgba(0, 0, 0, 0.7)",
        }),
        padding: [2, 2, 2, 2],
        textAlign: "left",
        offsetX: 15,
      }),
    }),
  ];
};

const drawTipStyle = new Style({
  image: new RegularShape({
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 1)",
      width: 2,
    }),
    points: 4,
    radius: 10,
    rotation: Math.PI / 2,
    angle: 0,
  }),
  text: new TextStyle({
    text: "Click to drop Nav Target Point. Right-click to cancel",
    font: "12px Roboto,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
    padding: [4, 4, 4, 4],
    textAlign: "left",
    offsetX: 15,
  }),
});
