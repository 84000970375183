import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Modal } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  PlanQueryKey,
  useGetPlans,
  usePlanManager,
} from "../../contexts/PlanManagerContext";
import { FragOrderDoc } from "../../lib/models/FragOrder";
import { PlanDoc, PlanStatus } from "../../lib/models/Plan";
import DataTable from "../DataTable";
import DocsHelpIcon from "../DocsHelpIcon";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";
import Link from "../Link";
import ModalContent from "../ModalContent";
import Section from "../Section";
import Timestamp from "../Timestamp";

type Props = {
  className?: string;
  fragOrder: FragOrderDoc;
};

function statusIcon(status: PlanStatus) {
  switch (status) {
    case PlanStatus.Invalid:
      return <CancelIcon color="error" />;
    case PlanStatus.Submitted:
      return <CheckCircleIcon color="success" />;
    default:
      return <PendingIcon />;
  }
}

function PlanningStatus({ className, fragOrder }: Props) {
  const queryClient = useQueryClient();
  const manager = usePlanManager();
  const { data: plans } = useGetPlans(fragOrder.id, {
    refetchOnWindowFocus: true,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });
  const { error } = useNotification();
  const [invalidating, setInvalidating] = React.useState<string>(null);
  const [deleting, setDeleting] = React.useState<string>(null);
  const [details, setDetails] = React.useState<PlanDoc>(null);

  const handleInvalidate = (p: PlanDoc) => {
    const ok = confirm(
      `Are you sure you want to invalidate this plan for group ${p.groupName}?`
    );

    if (!ok) {
      return;
    }

    setInvalidating(p.id);
    manager
      .invalidatePlan(fragOrder.id, p)
      .catch((e) => error(e.message))
      .finally(() => {
        queryClient.invalidateQueries([PlanQueryKey]);
        setInvalidating(null);
      });
  };

  const handleDelete = (p: PlanDoc) => {
    const ok = confirm(
      `Are you sure you want to invalidate this plan for group ${p.groupName}?`
    );

    if (!ok) {
      return;
    }

    setDeleting(p.id);
    manager
      .removePlan(fragOrder.id, p)
      .catch((e) => error(e.message))
      .finally(() => {
        queryClient.invalidateQueries([PlanQueryKey]);
        setDeleting(null);
      });
  };

  return (
    <Section
      title={
        <Flex wide>
          <div>Planning Status</div>
          <div style={{ marginLeft: 8, paddingTop: 2 }}>
            <DocsHelpIcon to="https://docs.fragorders.com/docs/managing-planners#handling-multiple-planners-for-a-single-group" />
          </div>
        </Flex>
      }
      className={className}
    >
      <DataTable
        columns={[
          {
            label: "Group",
            value: (p: PlanDoc) => (
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  setDetails(p);
                }}
              >
                {p.groupName}
              </Link>
            ),
          },
          {
            label: "Last Updated",
            value: (p: PlanDoc) => <Timestamp time={p.updated_at_timestamp} />,
          },
          {
            label: "Status",
            value: (p: PlanDoc) => (
              <Flex align>
                {statusIcon(p.status)}
                <Box paddingX={1}>{p.status}</Box>
              </Flex>
            ),
          },
          {
            label: "Submitted By",
            value: "submitterDisplayText",
          },
          {
            value: (p) => (
              <span style={{ color: p.coalition, fontWeight: 600 }}>
                {_.capitalize(p.coalition)}
              </span>
            ),
            label: "Coalition",
          },
          {
            label: "",
            value: (p: PlanDoc) => (
              <LoadingButton
                loading={invalidating === p.id}
                onClick={() => handleInvalidate(p)}
                variant="outlined"
                color="error"
                disabled={p.status === PlanStatus.Invalid}
              >
                Invalidate
              </LoadingButton>
            ),
          },
          {
            label: "",
            value: (p: PlanDoc) => (
              <LoadingButton
                loading={deleting === p.id}
                onClick={() => handleDelete(p)}
                variant="contained"
                color="error"
                // disabled={p.status === PlanStatus.Invalid}
              >
                Delete
              </LoadingButton>
            ),
          },
        ]}
        rows={_.sortBy(plans, "groupName")}
      />
      <Modal
        open={!!details}
        onClose={() => {
          setDetails(null);
        }}
      >
        <ModalContent style={{ width: 480 }}>
          <Box p={2}>
            <h3>Plan Details</h3>
            <KeyValueTable
              columns={2}
              pairs={[
                { key: "Group", value: details?.groupName },
                { key: "Submitted By", value: details?.submitterDisplayText },
                { key: "Waypoint Count", value: details?.waypoints?.length },
                { key: "Nav Point Count", value: details?.navPoints?.length },
                {
                  key: "Radio Channels Set",
                  value: details?.radioOverrides ? "Yes" : "No",
                },
                {
                  key: "Remarks Length",
                  value: details?.plannerNotes?.length,
                },
              ]}
            />
          </Box>
        </ModalContent>
      </Modal>
    </Section>
  );
}

export default styled(PlanningStatus).attrs({
  className: PlanningStatus.name,
})``;
