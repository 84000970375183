import * as React from "react";
import styled from "styled-components";
import { DCSNavPoint } from "../../lib/types";

type Props = {
  className?: string;
  point?: DCSNavPoint;
};

function ControlPoint({ className, point }: Props) {
  return (
    <div className={className}>
      <pre>{point.comment}</pre>
    </div>
  );
}

export default styled(ControlPoint).attrs({ className: ControlPoint.name })``;
