import { APIGuildMember, APIRole } from "discord-api-types/v10";
import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { useDiscordClient } from "../contexts/DiscordClientContext";
import { discordBotInviteURL } from "../contexts/UserContext";
import { DiscordID } from "../lib/models/SignupRecord";
import { useDebounce } from "./misc";

export const DiscordGuildQueryKey = "guilds";

export function useGetDiscordGuildMembers(guildID: string) {
  const discord = useDiscordClient();

  return useQuery<APIGuildMember[], Error>(
    [DiscordGuildQueryKey, guildID],
    () => discord.listMembers(guildID),
    { retry: false, cacheTime: Infinity, staleTime: Infinity }
  );
}

export function useSearchDiscordGuildMembers(guildID: string) {
  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(value, 500);
  const discord = useDiscordClient();

  const { data, isLoading, error } = useQuery<APIGuildMember[], Error>(
    [DiscordGuildQueryKey, guildID, debouncedValue],
    () => {
      if (!value) {
        return discord.listMembers(guildID);
      }
      return discord.searchMembers(guildID, value);
    },
    { retry: false, cacheTime: Infinity, staleTime: Infinity }
  );

  return {
    data,
    isLoading,
    error,
    value,
    setValue,
  };
}

export function useListGuildRoles(guildID: string, enabled: boolean = false) {
  const discord = useDiscordClient();

  return useQuery<APIRole[], Error>(
    [DiscordGuildQueryKey, guildID, enabled],
    () => {
      if (!enabled) {
        return [];
      }
      return discord.listGuildRoles(guildID);
    },
    { retry: false, cacheTime: Infinity, staleTime: Infinity, enabled }
  );
}

export function useDiscordBotAuthURL() {
  return useQuery(["botAuthURL"], () => discordBotInviteURL());
}

export function useGuildBotStatus(guildID: string) {
  const c = useDiscordClient();

  return useQuery(
    ["botCheck", guildID],
    () => {
      if (!guildID) {
        return;
      }
      return c.checkBotStatus(guildID);
    },
    {
      retry: false,
    }
  );
}

export function useIsBotPresent(discordGuildID: string) {
  const { data: botPresent, isLoading: isLoadingBotCheck } = useGuildBotStatus(
    discordGuildID
  );

  return !isLoadingBotCheck && !botPresent;
}

export type NormalizedDiscordMember = {
  id: DiscordID;
  name: string;
  color?: number;
};

export function useListDiscordMembersAndRoles(guildID: string) {
  const discord = useDiscordClient();

  return useQuery<NormalizedDiscordMember[], any>(
    ["membersAndRoles", guildID],
    async () => {
      const [members, roles] = await Promise.all([
        discord.listMembers(guildID),
        discord.listGuildRoles(guildID),
      ]);

      const normalizedMembers = _.map(members, (m) => ({
        id: `u_${m.user.id}`,
        name: m.nick || m.user.username,
      }));

      const normalizedRoles = _.map(roles, (r) => ({
        id: `r_${r.id}`,
        name: r.name.includes("@") ? r.name : `@${r.name}`,
        color: r.color,
      }));

      return _.concat(
        normalizedRoles,
        normalizedMembers
      ) as NormalizedDiscordMember[];
    }
  );
}

export function useGetGuildMemberForUser(guildID: string) {
  const discord = useDiscordClient();

  return useQuery<APIGuildMember, Error>(
    ["guildMember", guildID],
    () => {
      if (!guildID) {
        return null;
      }

      return discord.getGuildMemberForUser(guildID);
    },
    {
      retry: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
}
