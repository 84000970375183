import { FormControl, FormLabel, InputProps, Typography } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { FormContext } from "./ControlledForm";
import Input from "./Input";

export type FormInputProps = {
  name: string;
  className?: string;
  label?: string;
  component?: any;
  valuePropName?: string;
  formatter?: (v: string) => string;
  step?: any;
} & InputProps;

export const Label = styled(({ className, children, name, required }) => (
  <FormLabel className={className} htmlFor={name} required={required}>
    <Typography>{children}</Typography>
  </FormLabel>
))`
  label {
    margin-bottom: 4px;
  }
`;

function FormInput({
  className,
  label,
  name,
  component,
  valuePropName = "value",
  formatter,
  ...props
}: FormInputProps) {
  const Component = component || Input;

  return (
    <FormContext.Consumer>
      {({ handleChange, findValue }) => (
        <FormControl className={className}>
          <Label name={name} required={props.required}>
            {label}
          </Label>
          <Component
            id={name}
            variant="outlined"
            {...props}
            {...{ [valuePropName]: findValue(name) }}
            onChange={(ev) => {
              let v = ev;
              if (ev.target) {
                // type=number inputs are a bit weird. handling that here.
                v = ev.target[valuePropName];
              }

              handleChange(name, v);
            }}
            onBlur={() => {
              if (formatter) {
                const v = findValue(name);
                handleChange(name, formatter(v));
              }
            }}
          />
        </FormControl>
      )}
    </FormContext.Consumer>
  );
}

export default styled(FormInput).attrs({ className: FormInput.name })`
  min-width: 300px;
`;
