import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useListUserPreferences } from "../../../contexts/UserPreferencesManagerContext";
import { RadioChannelOverride } from "../../../lib/models/Plan";
import Flex from "../../Flex";

type Props = {
  className?: string;
  onSubmit: (chans: RadioChannelOverride[][]) => void;
};

function ImportFromPrefs({ className, onSubmit }: Props) {
  const { data: prefs } = useListUserPreferences();
  const [importMenuOpen, setImportOpen] = React.useState(false);
  const [importFrom, setImportFrom] = React.useState<string | null>(null);

  const opts = prefs || [];

  const handleSelect = (name: string) => {
    setImportFrom(name);
  };

  const handleImport = () => {
    const pref = _.find(opts, { name: importFrom });

    if (importFrom) {
      const d: RadioChannelOverride[][] = JSON.parse(pref.payload);
      onSubmit(d);
    }
  };

  return (
    <Flex align className={className}>
      <Button
        onClick={handleImport}
        style={{ marginRight: 4 }}
        variant="contained"
        disabled={!importFrom}
      >
        Import
      </Button>

      <FormControl size="small" fullWidth>
        <InputLabel id="demo-simple-select-label">Import from Saved</InputLabel>
        <Select
          open={importMenuOpen}
          onOpen={() => setImportOpen(true)}
          variant="outlined"
          onClose={() => setImportOpen(false)}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={importFrom || ""}
          label="Import from Saved"
          onChange={(e) => {
            handleSelect(e.target.value);
          }}
          placeholder="Import from Saved"
        >
          {_.map(opts, (c) => (
            <MenuItem key={c.name} value={c.name}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Flex>
  );
}

export default styled(ImportFromPrefs).attrs({
  className: ImportFromPrefs.name,
})`
  .MuiFormControl-root {
    width: 280px;
    box-sizing: border-box;
  }
`;
