import _ from "lodash";
import { Point } from "ol/geom";
import { Fill, RegularShape, Stroke, Style, Text as TextStyle } from "ol/style";
import * as React from "react";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import { DCSGroupData, FeatureClass, NavTargetPoint } from "../../lib/types";
import MapLayer from "./MapLayer";

interface Props {
  group: GroupWithPlan;
  theater: Theater;
  color: string;
  filterTag?: string;
  planItemNavPoints: NavTargetPoint[];
}

export const formatLabel = (comment: string) => {
  const text = comment?.split("\n")[0] || "Nav Point";
  return text;
};

export const navPointStyle = (color: string) => (feature) => {
  const comment = feature.get("point")?.textComment?.toString() || "";

  const text = formatLabel(comment);

  return [
    new Style({
      image: new RegularShape({
        fill: new Fill({ color }),
        stroke: new Stroke({ color: "black" }),
        points: 3,
        radius: 12,
      }),
      text: new TextStyle({
        overflow: true,
        font: 'bold 18px "Roboto", "Arial Unicode MS", "sans-serif"',
        placement: "point",
        fill: new Fill({ color }),
        stroke: new Stroke({ color: "black", width: 2 }),
        text,
        offsetY: 20,
      }),
    }),
  ];
};

const toNavPoint = (theater: Theater, group: DCSGroupData) => (
  point: NavTargetPoint
) => {
  const { long, lat } = convertDCSToLatLong(theater, point);
  const geometry = new Point([long, lat]);

  return {
    geometry,
    point,
    name: `${group.name} Nav Point ${point.index}`,
    // name: `nav/${group.name}/${point.index}`,
    featureClass: FeatureClass.NavPoint,
    group: group.name,
  };
};

export default function NavPointLayer({
  group,
  color,
  planItemNavPoints,
  filterTag,
  theater,
}: Props) {
  const planPoints = planItemNavPoints && planItemNavPoints.length > 0;

  const navPoints = group?.navTargetPoints || group?.plan?.navPoints;

  const collection = _.uniqBy(
    _.concat(navPoints || [], planPoints ? planItemNavPoints : []),
    "index"
  );

  const features = _.map(collection, toNavPoint(theater, group));

  return (
    <MapLayer
      features={features}
      style={navPointStyle(color)}
      filterTag={filterTag}
      name={navPointLayerName(group.name)}
    />
  );
}

export function navPointLayerName(groupName: string) {
  return `${groupName} Nav Points`;
}
