import * as React from "react";
import styled from "styled-components";
import { useIsBotPresent } from "../../hooks/discord";
import Section from "../Section";

import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useNotification } from "../../contexts/NotifcationContext";
import { useEnableSignup } from "../../contexts/PublishManagerContext";
import {
  useAdminGetCurrentFragOrder,
  useAdminGetCurrentManifests,
} from "../../hooks/frag_orders";
import { Coalition } from "../../lib/types";
import Flex from "../Flex";
import NoDiscordGuildCallToAction from "../PlanningManager/NoDiscordGuildCallToAction";
import SignupManagementForm from "./SignupManagementForm";
import SignupTable from "./SignupTable";

type Props = {
  className?: string;
};

function SignupManagement({ className }: Props) {
  const [busy, setBusy] = React.useState(false);
  const { data: fragOrder } = useAdminGetCurrentFragOrder();
  const { data: manifests } = useAdminGetCurrentManifests();
  const showCTA = useIsBotPresent(fragOrder?.discordGuildID);
  const update = useEnableSignup();
  const { success, error } = useNotification();

  const signupsEnabled = _.every(manifests, { signupsEnabled: true });

  const handleSignupsEnabledChange = (event) => {
    setBusy(true);

    Promise.all(
      _.map(manifests, (manifest) => update(manifest, event.target.checked))
    )
      .then(() => {
        success("Frag Order updated successfully");
      })
      .catch((e) => {
        error("Error enabling signups: " + e.message);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const blueManifest = _.find(manifests, { coalition: Coalition.Blue });

  return (
    <div className={className}>
      <Section
        title={
          <Flex wide between>
            <Typography fontSize={24} variant="h3">
              Manage Signup Rules
            </Typography>

            <Flex align>
              {busy && <CircularProgress size={24} />}
              <Box marginLeft={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={busy}
                        onChange={handleSignupsEnabledChange}
                        checked={signupsEnabled || false}
                        inputProps={{ "aria-label": "Enable Signups" }}
                      />
                    }
                    label="Enable Signups"
                  />
                </FormGroup>
              </Box>
            </Flex>
          </Flex>
        }
      >
        {showCTA && <NoDiscordGuildCallToAction />}
        {!showCTA && !signupsEnabled && (
          <Flex wide center>
            <Alert severity="info">
              <AlertTitle>Signups Disabled</AlertTitle>
              Enable signups to manage attendance via Frag Orders
            </Alert>
          </Flex>
        )}
        {!showCTA && signupsEnabled && (
          <Box marginTop={6}>
            <div>
              <SignupManagementForm manifest={blueManifest} />
            </div>
          </Box>
        )}
      </Section>
      {signupsEnabled && (
        <Box marginY={4}>
          <SignupTable manifest={blueManifest} />
        </Box>
      )}
    </div>
  );
}

export default styled(SignupManagement).attrs({
  className: SignupManagement.name,
})``;
