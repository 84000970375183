import WebAssetIcon from "@mui/icons-material/WebAsset";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useToggleGroupDataWindow,
  useWindowManager,
} from "../../lib/services/WindowManager";
import { DCSGroupData } from "../../lib/types";
import NoShadowStateButton from "./NoShadowStateButton";

type Props = {
  className?: string;
  group: DCSGroupData;
};

function OpenDataWindow({ className, group }: Props) {
  const win = useWindowManager();
  const openWindow = useToggleGroupDataWindow();

  if (!group) {
    return null;
  }

  return (
    <Tooltip className={className} title="View Data">
      <NoShadowStateButton
        active={!!_.find(win.windows, { id: group.name })}
        onClick={() => openWindow(group)}
      >
        <WebAssetIcon />
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(OpenDataWindow).attrs({
  className: OpenDataWindow.name,
})``;
