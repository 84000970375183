// @ts-ignore
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { getMission } from "./lua";

export function downloadMiz(
  zip: { entries: JSZip; name: string },
  mission: string,
  dictionary: string | null,
  suffix = "updated"
) {
  zip.entries.file("mission", mission);

  if (dictionary) {
    zip.entries.file("/l10n/DEFAULT/dictionary", dictionary);
  }

  zip.entries.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `${zip.name.replace(".miz", "")}_${suffix}.miz`);
  });
}

export function extractMission(
  files: File[] | File,
  cb: (
    mission: string,
    dictionary: string,
    name: string,
    entries: JSZip,
    warehouses: string
  ) => void,
  errCb?: (err: Error) => void
) {
  const file = files[0] || files;

  JSZip.loadAsync(file as Blob).then(
    (entries) => {
      getMission(entries, (miz, dict, warehouses) => {
        cb(miz, dict, file.name, entries, warehouses);
      });
    },
    (err) => {
      if (errCb) {
        errCb(err);
      } else {
        console.error(err);
      }
    }
  );
}

export async function downloadZip(
  zip: JSZip,
  mizName: string,
  suffix: string = "_updated.miz"
) {
  return zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `${mizName.replace(".miz", "")}_${suffix}`);
  });
}
