import { Box, Tab, Tabs, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  GroupWithPlan,
  useGroupsWithPlans,
} from "../../contexts/PlanManagerContext";
import { useListPlanItemsByGroup } from "../../contexts/PlanManagerV2";
import {
  TaskingMapContext,
  useFocusWaypoint,
} from "../../contexts/TaskingMapContext";
import { Module, modules } from "../../lib/data/modules";
import {
  getPlanItemPayload,
  PlanItemDoc,
  PlanItemType,
  PlanNote,
} from "../../lib/models/PlanItem";
import { TaskingState } from "../../lib/models/PublishManifest";
import { DCSUnit } from "../../lib/types";
import DataTable from "../DataTable";
import Loadouts from "../Loadouts";
import FuelPlanner from "../Planning/FuelPlanner";
import LaserConfigTable from "./LaserConfigTable";
import RadioPresetTable from "./RadioPresetTable";
import WaypointTable from "./WaypointTable";

type GroupDataTabsProps = {
  className?: string;
  initialTab?: number;
  group?: GroupWithPlan;
  tasking: TaskingState;
  onWaypointClick: (n: number) => void;
  planItems?: PlanItemDoc[];
};

const TabPanel = ({
  val,
  index,
  children,
}: {
  val: number;
  index: number;
  children: any;
}) => {
  if (val !== index) {
    return null;
  }

  return <Box>{children}</Box>;
};

const GroupDataTabs = styled(
  ({
    initialTab = 0,
    group,
    tasking,
    onWaypointClick,
    className,
    planItems,
  }: GroupDataTabsProps) => {
    const [tab, setTab] = React.useState(initialTab);
    const module = modules[group?.units[0].type] as Module;
    // const isPlannable = userCanPlanGroup(group.name);

    React.useEffect(() => {
      if (!module?.laserConfigFields && tab === 3) {
        setTab(0);
      }
    }, [module]);

    const remarks = _.find(planItems, {
      type: PlanItemType.PlanNote,
      group: group?.name,
    });

    const channels = _.get(
      _.map(
        _.filter(planItems, {
          type: PlanItemType.RadioPresets,
          group: group?.name,
        }),
        (c) => JSON.parse(c.payload)
      ),
      [0]
    );

    const parsedRemarks = remarks && (getPlanItemPayload(remarks) as PlanNote);

    // Handling both v2 and v3 plan notes
    const notes = parsedRemarks?.text || group?.plan?.plannerNotes;

    return (
      <div className={className}>
        <Tabs scrollButtons value={tab} onChange={(e, val) => setTab(val)}>
          <Tab label="Waypoints" />
          <Tab label="Notes" />

          <Tab label="Comms" />
          <Tab label="Loadouts" />
          <Tab label="Laser" />
          <Tab label="Datalinks" />
          <Tab label="Fuel" />
        </Tabs>
        <TabPanel index={0} val={tab}>
          <Box paddingY={1}>
            <WaypointTable
              onClick={onWaypointClick}
              tasking={tasking}
              waypoints={group?.waypoints}
            />
          </Box>
        </TabPanel>
        <TabPanel index={1} val={tab}>
          <Box p={1}>
            {notes ? (
              <pre style={{ whiteSpace: "pre-wrap" }}>{notes}</pre>
            ) : (
              <Typography fontStyle="italic">No planner notes</Typography>
            )}
          </Box>
        </TabPanel>

        <TabPanel index={2} val={tab}>
          <RadioPresetTable
            group={group}
            presets={
              tasking?.radioPresets || {
                UHF: _.get(channels, [0]),
                VHF: _.get(channels, [1]),
                "V/UHF": _.get(channels, [2]),
              }
            }
          />
        </TabPanel>
        <TabPanel index={3} val={tab}>
          <Box p={1}>
            <Loadouts group={group} />
          </Box>
        </TabPanel>
        <TabPanel index={4} val={tab}>
          <LaserConfigTable group={group} />
        </TabPanel>
        <TabPanel index={5} val={tab}>
          <DataTable
            columns={[
              {
                value: (u: DCSUnit, col: number, row: number) => {
                  return `${group.name}-${row + 1}`;
                },
                label: "Flight Member",
              },
              {
                value: (u: DCSUnit) => u.aircraftProps.STN_L16,
                label: "STN",
              },
            ]}
            rows={group.units}
          />
        </TabPanel>
        <TabPanel index={6} val={tab}>
          <FuelPlanner readonly groupName={group.name} />
        </TabPanel>
      </div>
    );
  }
)`
  td {
    padding: 8px;
  }

  .laser-table th {
    padding: 4px 8px;
  }
`;

export default styled(GroupDataTabs).attrs({ className: GroupDataTabs.name })``;

export function WrappedGroupDataTabs({ name }: { name: string }) {
  const {
    state: { tasking, manifest },
  } = React.useContext(TaskingMapContext);
  const { data: groups } = useGroupsWithPlans(
    manifest.fragOrderID,
    tasking.plannedGroups
  );
  const { data: planItems } = useListPlanItemsByGroup(manifest.id, name);

  const group = _.find(groups, { name });

  const select = useFocusWaypoint();

  const handleWaypointClick = (n: number) => {
    const wp = _.find(group.waypoints, { number: n });
    select(group.name, wp);
  };

  return (
    <GroupDataTabs
      group={group}
      tasking={tasking}
      onWaypointClick={handleWaypointClick}
      planItems={planItems}
    />
  );
}
