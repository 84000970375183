import maplibregl from "maplibre-gl";
import { PMTiles, Protocol } from "pmtiles";
import { useEffect } from "react";
import { useMap } from "./MapCanvas";

import { MapLibreLayer } from "@geoblocks/ol-maplibre-layer";
import { PMTILES_HOST, useGetStyles } from "../../hooks/pmtiles";

const PMTILES_URL = `${PMTILES_HOST}/20230918.pmtiles`;

export default function PMTilesLayer() {
  const map = useMap();
  const { data: style } = useGetStyles(PMTILES_URL);

  useEffect(() => {
    if (!map || !style) {
      return;
    }

    (async () => {
      const protocol = new Protocol();
      maplibregl.addProtocol("pmtiles", protocol.tile);

      const p = new PMTiles(PMTILES_URL);

      protocol.add(p);

      const layer = new MapLibreLayer({
        opacity: 0.7,
        mapLibreOptions: {
          style,
        },
      });

      layer.set("name", "PMTiles");
      layer.set("hideable", true);
      layer.setZIndex(-1);

      map.addLayer(layer);
    })();
  }, [map, style]);

  return null;
}
