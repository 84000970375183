import { useEffect } from "react";
import { createSurfaceThreatLayer } from "../../lib/layers/threats";
import { useMap } from "./MapCanvas";

export default function ThreatLayer({ groups, theater }) {
  const map = useMap();
  useEffect(() => {
    if (!map) {
      return;
    }

    const layer = createSurfaceThreatLayer(theater, groups);
    layer.set("hideable", true);

    map.addLayer(layer);
  }, [map]);
  return null;
}
