import { Box, Tab, Tabs, Typography } from "@mui/material";
import cx from "classnames";
import _ from "lodash";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { FeatureFlag, useFeatureFlags } from "../contexts/FeatureFlagsContext";
import Flex from "./Flex";
import Link from "./Link";
import SubscriptionGate from "./SubscriptionGate";

type Props = {
  className?: string;
  children?: any;
  rootPath: string;
};

type TabProps = {
  name: string;
  path: string;
  children: any;
  subscriberOnly?: boolean;
  featureFlag?: FeatureFlag;
  icon?: React.ReactElement;
  label?: string;
};

type PathConfig = {
  name: string;
  path: string;
  featureFlag?: FeatureFlag;
  icon?: any;
  label?: string;
};

const ForwardedLink = React.forwardRef((props, ref) => (
  <Link {...props} innerRef={ref} />
));

function findChildren(childrenProp) {
  const filtered = _.filter(childrenProp, (c) => !_.isNull(c));
  if (_.isArray(filtered)) {
    return filtered;
  }
  return [filtered];
}

function routesToIndex(routes: PathConfig[], pathname) {
  const index = _.findIndex(routes, (r) => pathname === r.path);
  return index === -1 ? 0 : index;
}

export function RouterTab({
  children,
  path,
  featureFlag,
  subscriberOnly,
  name,
  label,
}: TabProps) {
  const userFlags = useFeatureFlags();

  if (featureFlag && !_.includes(userFlags, featureFlag)) {
    return null;
  }

  return (
    <Route exact path={path}>
      {subscriberOnly ? (
        <SubscriptionGate blockedHeader={label || name}>
          {children}
        </SubscriptionGate>
      ) : (
        children
      )}
    </Route>
  );
}

function SubRouterTabs({ className, children, rootPath }: Props) {
  const childs = findChildren(children);
  const userFlags = useFeatureFlags();

  if (!_.get(childs, [0, "props", "path"])) {
    throw new Error("HashRouterTabs children must be of type HashRouterTab");
  }

  const routes: PathConfig[] = _.map(childs, (c: any) => {
    return {
      path: c?.props?.path,
      name: c?.props?.name,
      featureFlag: c?.props?.featureFlag,
      label: c?.props.label,
      icon: c?.props.icon,
    };
  });

  return (
    <Flex wide column className={className}>
      <Tabs
        indicatorColor="primary"
        value={routesToIndex(routes, window.location.pathname)}
      >
        {_.map(routes, (route, i) => (
          <Tab
            component={ForwardedLink as typeof Link}
            key={i}
            to={route.path}
            className={cx({
              hidden:
                route.featureFlag && !_.includes(userFlags, route.featureFlag),
              "active-tab": window.location.pathname.includes(route.path),
            })}
            label={route.label || <Typography>{route.name}</Typography>}
            icon={route.icon}
          />
        ))}
      </Tabs>
      <Box
        sx={{ borderTop: 2, borderColor: "divider", width: "100%" }}
        paddingTop={2}
      >
        <Switch>
          {children}
          <Redirect from="*" to={rootPath} />
        </Switch>
      </Box>
    </Flex>
  );
}

export default styled(SubRouterTabs).attrs({ className: SubRouterTabs.name })`
  .hidden {
    visibility: hidden;
  }
`;
