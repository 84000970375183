import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  useCreateRedeemRecord,
  useListVolumeSubs,
  useRevokeGroupLicense,
} from "../../hooks/services";
import {
  SubscriptionDoc,
  VolumeRedeemRecord,
  VolumeRedeemRecordDoc,
} from "../../lib/models/Subscription";
import { AppRoutes } from "../../lib/types";
import DataTable from "../DataTable";
import Flex from "../Flex";
import Section from "../Section";
import Timestamp from "../Timestamp";

type Props = {
  className?: string;
  parent: SubscriptionDoc;
};

function VolumeLicense({ className, parent }: Props) {
  const [creating, setCreating] = React.useState(false);
  const [revoking, setRevoking] = React.useState<VolumeRedeemRecordDoc | null>(
    null
  );
  const { data: subs, isLoading } = useListVolumeSubs(parent);
  const addSub = useCreateRedeemRecord();
  const revoke = useRevokeGroupLicense();
  const { success, error } = useNotification();

  const handleAdd = () => {
    setCreating(true);

    return addSub(parent)
      .then(() => {
        success("License added successfully");
      })
      .catch((e) => {
        error(e.message);
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const handleRevoke = (r: VolumeRedeemRecordDoc) => {
    setRevoking(r);

    return revoke(r.subscriptionID)
      .then(() => {
        success("License revoked successfully");
      })
      .catch((e) => {
        error(e.message);
      })
      .finally(() => {
        setRevoking(null);
      });
  };

  return (
    <Section
      className={className}
      loading={isLoading}
      title="Group Licenses"
      actions={[
        <Flex align key="usage">
          <Box mr={2}>Usage: {subs?.length} / 4 </Box>
          <LoadingButton
            loading={creating}
            variant="contained"
            key="add"
            onClick={handleAdd}
            disabled={subs?.length >= 4}
          >
            Add License
          </LoadingButton>
        </Flex>,
      ]}
    >
      <DataTable
        columns={[
          {
            label: "Claimed By",
            value: (v: VolumeRedeemRecord) =>
              v.redeemedByDisplayName || "Unclaimed",
          },
          {
            label: "Claimed At",
            value: (v: VolumeRedeemRecord) =>
              v.redeemedAt ? <Timestamp time={v.redeemedAt} /> : "-",
          },
          {
            label: "Redeem Link",
            value: (v: VolumeRedeemRecord) => (
              <Button
                variant="outlined"
                disabled={!!v.redeemedAt}
                onClick={() => {
                  const url = `${window.location.origin}${AppRoutes.RedeemCode}?code=${v.oneTimeCode}`;
                  navigator.clipboard.writeText(url);
                  success("Link copied to clipboard");
                }}
                size="small"
              >
                Copy Link
              </Button>
            ),
          },
          {
            value: (r: VolumeRedeemRecord) => <Timestamp time={r.createdAt} />,
            label: "Created At",
          },
          {
            value: (r: VolumeRedeemRecordDoc) => (
              <LoadingButton
                loading={revoking?.id === r.id}
                onClick={() => handleRevoke(r)}
                variant="outlined"
                color="error"
                disabled={!r.subscriptionID}
              >
                Revoke
              </LoadingButton>
            ),
            label: "",
          },
        ]}
        rows={subs || []}
      />
    </Section>
  );
}

export default styled(VolumeLicense).attrs({
  className: VolumeLicense.name,
})``;
