import { Alert, Button } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useDeleteUserPreference,
  useListUserPreferences,
} from "../../contexts/UserPreferencesManagerContext";
import {
  UserPreferenceDoc,
  UserPreferenceType,
} from "../../lib/models/UserPreference";
import DataTable from "../DataTable";
import Page from "../Page";
import Timestamp from "../Timestamp";

type Props = {
  className?: string;
};

function SavedRadioConfigs({ className }: Props) {
  const { data, isLoading, error } = useListUserPreferences();
  const remove = useDeleteUserPreference();

  const radioConfigs = _.filter(data, {
    type: UserPreferenceType.RadioPresetConfig,
  });

  const columns = [
    { value: "name", label: "Name" },
    { value: "type", label: "Type" },
    {
      value: (r: UserPreferenceDoc) => (
        <Timestamp time={r.created_at_timestamp} />
      ),
      label: "Created",
    },
    {
      value: (r: UserPreferenceDoc) => (
        <Timestamp time={r.updated_at_timestamp} />
      ),
      label: "Updated At",
    },
    {
      label: "",
      value: (r: UserPreferenceDoc) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this?")) {
              remove(r);
            }
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <Page loading={isLoading} className={className}>
      {error && (
        <Alert severity="error">
          There was an error loading your saved radio configurations.
        </Alert>
      )}
      <DataTable columns={columns} rows={radioConfigs} />
    </Page>
  );
}

export default styled(SavedRadioConfigs).attrs({
  className: SavedRadioConfigs.name,
})``;
