import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import LoadingPage from "./LoadingPage";

export default function AuthenticatedRoute({ component: Component, ...rest }) {
  const { currentUser, loading } = useUser();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}
