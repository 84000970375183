import { FirebaseMetadata } from "../types";

export enum SubscriptionOrigin {
  SuperAdmin = "SuperAdmin",
  Stripe = "Stripe",
  Volume = "Volume",
}

export const SubscriptionCollection = "Subscriptions";
export const VolumeRedeemCollection = "VolumeRedeemRecords";

export class Subscription {
  ownerDiscordID: string;
  ownerDisplayName: string;
  enabled: boolean;
  origin: SubscriptionOrigin;
  paymentSessionID: string;
  stripeCustomerID: string;
  message?: string;
  liveMode: boolean;
  trialCompleted: boolean;
  parentID?: string;
  isVolume?: boolean;
}

export type VolumeRedeemRecord = {
  createdByDiscordID: string;
  createdByDisplayName: string;
  createdAt: number;
  oneTimeCode: string;
  parentID: string;
  redeemedByDiscordID: string;
  redeemedByDisplayName: string;
  redeemedAt: number;
  subscriptionID: string;
};

export type VolumeRedeemRecordDoc = VolumeRedeemRecord & { id: string };

export type SubscriptionDoc = Subscription & FirebaseMetadata;
