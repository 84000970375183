import _ from "lodash";
import { getSAMType } from "./layers/threats";
import { SupportAction } from "./mission";
import { UnitCategory } from "./types";

function categoryToSymbold(
  cat: UnitCategory,
  support?: SupportAction
): [string, string] {
  switch (cat) {
    case UnitCategory.Vehicle:
      return ["G", SubCategory.Ground];

    case UnitCategory.Plane:
      return ["A", support ? SubCategory[support] : SubCategory.Fighter];

    case UnitCategory.Helicopter:
      return ["A", SubCategory.Helicopter];

    case UnitCategory.Static:
      return ["G", SubCategory.Static];

    case UnitCategory.Ship:
      return ["S", SubCategory.Destroyer];

    default:
      break;
  }
}

export function toInstallationSymbol(isOpfor: boolean) {
  return `S${isOpfor ? "H" : "F"}GPI-----H-`;
}

export enum SubCategory {
  "Ground" = "---",
  "Fighter" = "MFF",
  "Helicopter" = "MHA",
  "AWACS" = "MFRW",
  "Tanker" = "MFK",
  "Static" = "--",
  "AirDefense" = "UCDC",
  "Armor" = "UCA",
  "Infantry" = "UCI",
  "Artillery" = "UCF",
  "Recon" = "UCR",
  "Destroyer" = "CLDD",
}

export function toMilSymbolString(
  hostile: boolean,
  category: UnitCategory,
  support?: SupportAction,
  type?: string | string[]
) {
  let [cat, subCat] = categoryToSymbold(category, support);

  let isSAM = false;
  if (Array.isArray(type)) {
    isSAM = _.some(type, (t) => getSAMType(t));
  } else {
    isSAM = !!getSAMType(type);
  }

  if (isSAM) {
    subCat = SubCategory.AirDefense;
  }

  const out = ["S", hostile ? "H" : "F", cat, "P", subCat].join("");

  const p = out.padEnd(10, "-");

  return p;
}

export enum SymbolSet {
  Air = "A",
  Ground = "G",
}

export function toNATOSymbol(
  hostile: boolean,
  set: SymbolSet,
  sub: SubCategory
) {
  const out = ["S", hostile ? "H" : "F", set, "P", sub].join("");

  const p = out.padEnd(10, "-");

  return p;
}
