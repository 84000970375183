import { Button } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import {
  TaskingMapActionTypes,
  TaskingMapContext,
} from "../contexts/TaskingMapContext";
import { Airbase } from "../lib/data/airbases";
import { formatFreq } from "../lib/radios";
import { TaskingMapLayerName } from "../lib/types";
import DataTable from "./DataTable";

type Props = {
  airbases?: Airbase[];
  onClick?: (b: Airbase) => void;
  extra?: boolean;
};

export function AirbasesTable({ airbases, onClick, extra }: Props) {
  const cols = [
    {
      label: "Name",

      // value: (b: Airbase) => handleClick(b),
      value: (b: Airbase) =>
        onClick ? (
          <Button onClick={() => onClick(b)}>
            <span
              style={{
                width: 96,
                textAlign: "left",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {b.Name}
            </span>
          </Button>
        ) : (
          "Name"
        ),
    },
    {
      label: "Frequency",
      value: (a: Airbase) => {
        const freq = a.UHF || a.VHF;

        return freq ? formatFreq(freq) : "";
      },
    },
    { label: "TACAN", value: "TACAN" },
  ];

  if (extra) {
    cols.push({ label: "Runways", value: "Runways" });
    cols.push({
      label: "ILS",
      value: (a: Airbase) => {
        const ils = a.ILS;

        const s = ils.map((r) =>
          r.replace(/RWY/g, "").replace("/", ":  ").replace(/\/\d*/g, "")
        );

        return (
          <React.Fragment>
            {_.map(s, (s, i) => (
              <span key={i}>{s}</span>
            ))}
          </React.Fragment>
        );
      },
    });
  }
  return <DataTable stickyHeader size="small" columns={cols} rows={airbases} />;
}

export default () => {
  const { state, dispatch } = React.useContext(TaskingMapContext);

  return (
    <AirbasesTable
      airbases={state.tasking.airbases}
      onClick={(b: Airbase) => {
        dispatch({
          type: TaskingMapActionTypes.focusFeature,
          layerName: TaskingMapLayerName.Bases,
          name: b.Name,
          otherFeatures: [],
        });
      }}
    />
  );
};
