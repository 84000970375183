import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ContentPaste from "@mui/icons-material/ContentPaste";
import GetAppIcon from "@mui/icons-material/GetApp";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import styled from "styled-components";
import NavButton from "./NavButton";

import CategoryIcon from "@mui/icons-material/Category";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { Box } from "@mui/material";
import { useTaskingState } from "../../contexts/TaskingMapContext";
import { downloadImage } from "../../lib/files";
import { FOWindow } from "../../lib/services/WindowManager";
import Flex from "../Flex";
import LargeTooltip from "../LargeTooltip";
import Logo from "../Logo";
import { useMap } from "../MapCanvas/MapCanvas";
import WindowManager from "../WindowManager/WindowManager";
import StateButton from "./StateButton";

import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useManifestHasSignupsEnabled } from "../../hooks/signups";

type Props = {
  className?: string;
};

const NavContainer = styled(Flex)`
  position: absolute;
  top: 96px;
  left: 0;
  bottom: 0;
  z-index: 2;

  padding: 8px;
`;

function Navigation({ className }: Props) {
  const map = useMap();
  const { state } = useTaskingState();
  const hasSignups = useManifestHasSignupsEnabled();
  const handleDownloadMap = () => {
    const [width, height] = map.getSize();

    downloadImage(width, height, state.manifest.title);
  };

  return (
    <div className={className}>
      <NavContainer column>
        <NavButton foWindow={FOWindow.Search} tooltip="Search">
          <SearchIcon fontSize="large" />
        </NavButton>
        {/* <NavButton foWindow={FOWindow.Players} tooltip="Players">
          <FlightIcon fontSize="large" />
        </NavButton>
        <NavButton tooltip="Support Assets" foWindow={FOWindow.SupportAssets}>
          <SupportAgent fontSize="large" />
        </NavButton>
        <NavButton tooltip="Bases" foWindow={FOWindow.Bases}>
          <FlightLand fontSize="large" />
        </NavButton> */}

        {/* <DrawingTools /> */}
        <Box p={0} marginTop={2}>
          <LargeTooltip placement="right" title="Download Map as Image">
            <StateButton size="small" onClick={handleDownloadMap}>
              <CameraAltIcon fontSize="large" />
            </StateButton>
          </LargeTooltip>
        </Box>

        <Box p={0} marginTop={4}>
          <NavButton tooltip="Planning" foWindow={FOWindow.Planning}>
            <GpsFixedIcon fontSize="large" />
          </NavButton>
          <NavButton tooltip="Kneeboards" foWindow={FOWindow.Assets}>
            <ContentPaste fontSize="large" />
          </NavButton>
          <NavButton tooltip="Data Entry" foWindow={FOWindow.DataEntry}>
            <NoteAltIcon fontSize="large" />
          </NavButton>
          <NavButton tooltip="Aircraft Data" foWindow={FOWindow.AircraftData}>
            <ListIcon fontSize="large" />
          </NavButton>
          <NavButton
            tooltip="Control Measures"
            foWindow={FOWindow.ControlMeasures}
          >
            <CategoryIcon fontSize="large" />
          </NavButton>
          <NavButton
            tooltip="Timeline Editor"
            foWindow={FOWindow.TimelineEditor}
          >
            <AccessTimeIcon fontSize="large" />
          </NavButton>
        </Box>
        {hasSignups && (
          <Box p={0} marginTop={4}>
            <NavButton tooltip="Signup" foWindow={FOWindow.Signups}>
              <HowToRegIcon fontSize="large" />
            </NavButton>
          </Box>
        )}
        <NavButton tooltip="Tacview Export" foWindow={FOWindow.TacviewExport}>
          <GetAppIcon fontSize="large" />
        </NavButton>
      </NavContainer>
      <WindowManager />
    </div>
  );
}

export default styled(Navigation).attrs({ className: Navigation.name })`
  position: relative;

  ${Logo} {
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
    z-index: 3;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }

  @media only screen and (max-height: 700px) {
    display: none;
  }
`;
