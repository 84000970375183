import { Link } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import * as React from "react";
import styled from "styled-components";
import { TaskingState } from "../../lib/models/PublishManifest";
import { timeOnTarget } from "../../lib/time";
import { DCSWaypoint } from "../../lib/types";
import DataTable from "../DataTable";

type Props = {
  className?: string;
  styles?: {
    th: any;
    td: any;
    table: any;
  };
  waypoints: DCSWaypoint[];
  tasking: TaskingState;
  onClick?: (n: number) => void;
};

function WaypointTable({
  className,
  tasking,
  waypoints,
  onClick,
  styles = {} as any,
}: Props) {
  const cols = [
    {
      value: (wp: DCSWaypoint) => {
        if (onClick) {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                onClick(wp.number);
              }}
            >
              {wp.number}
            </Link>
          );
        }

        return wp.number;
      },

      label: "#",

      style: {
        th: styles.th,
        td: styles.td,
      },
    },
    {
      value: (wp: DCSWaypoint) => {
        const name = wp.name !== wp.number.toString() ? wp.name : "";

        if (onClick) {
          return (
            <Link
              onClick={() => {
                onClick(wp.number);
              }}
            >
              {name}
            </Link>
          );
        }

        return name;
      },

      label: "Name",

      style: {
        th: styles.th,
        td: styles.td,
      },
    },
    {
      value: (wp: DCSWaypoint) =>
        timeOnTarget(tasking, wp.ETA).toLocaleString(
          DateTime.TIME_24_WITH_SECONDS
        ),
      label: "Time on Target (Zulu)",

      style: {
        th: styles.th,
        td: styles.td,
      },
    },
    // {
    //   label: "Distance (NM)",
    //   style: {
    //     th: styles.th,
    //     td: styles.td,
    //   },
    //   width: 20,
    //   value: (wp: DCSWaypoint) => {
    //     const last = _.find(group.waypoints, { number: wp.number - 1 });

    //     if (!last) {
    //       return "";
    //     }

    //     return distance(tasking.theater, last, wp).toFixed(1);
    //   },
    // },
    // {
    //   value: (wp: DCSWaypoint) => {
    //     const last = _.find(group.waypoints, { number: wp.number - 1 });

    //     return last ? formatDuration(last, wp) : "";
    //   },
    //   label: "Duration (hh:mm:ss)",
    //   width: 20,
    //   style: {
    //     th: styles.th,
    //     td: styles.td,
    //   },
    // },
    // {
    //   label: "Ground Speed (knots)",
    //   width: 20,
    //   style: {
    //     th: styles.th,
    //     td: styles.td,
    //   },
    //   value: (wp: DCSWaypoint) => {
    //     const last = _.find(group.waypoints, { number: wp.number - 1 });

    //     if (!last) {
    //       return "";
    //     }

    //     const nauticalMiles = distance(tasking.theater, last, wp);

    //     const d = durationFromLast(last, wp);
    //     const ms = d.toMillis();
    //     const seconds = ms / 1000;
    //     const minutes = seconds / 60;
    //     const hours = minutes / 60;

    //     return Math.floor(nauticalMiles / hours);
    //   },
    // },
  ];

  return (
    <div className={className}>
      <DataTable
        stickyHeader
        style={{ ...(styles.table as any) }}
        columns={cols}
        rows={_.tail(waypoints)}
      />
    </div>
  );
}

export default styled(WaypointTable).attrs({ className: WaypointTable.name })`
  .MuiLink-root {
    cursor: pointer;
  }

  td {
    padding: 8px;
  }
`;
