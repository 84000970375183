import _ from "lodash";
import { Feature } from "ol";
import { Point } from "ol/geom";
import * as React from "react";
import { shipThreatRanges } from "../../lib/data/ships";
import { convertDCSToLatLong } from "../../lib/map";
import { toMilSymbolString } from "../../lib/symbols";
import {
  FeatureClass,
  TaskingMapLayerName,
  UnitCategory,
} from "../../lib/types";
import GroupLayer, { GroupLayerProps } from "./GroupLayer";

type Props = GroupLayerProps;

export default function OpforShipLayer({ groups, theater }: Props) {
  const features = [];

  _.each(groups, (g) => {
    const u = g.units[0];
    const { lat, long } = convertDCSToLatLong(theater, u);
    features.push(
      new Feature({
        geometry: new Point([long, lat]),
        featureClass: FeatureClass.OPFORGroups,
        sidc: toMilSymbolString(true, UnitCategory.Ship, null, u.type),
        name: _.get(u, "name"),
        threatRing: shipThreatRanges[_.get(u, "type")],
      })
    );
  });

  return (
    <GroupLayer
      name={TaskingMapLayerName.OPFORShips}
      groups={groups}
      isOpfor
      theater={theater}
      size={18}
    />
  );
}
