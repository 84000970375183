import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useSearchDiscordGuildMembers } from "../../hooks/discord";

type Props = {
  className?: string;
  guildID: string;
  displayName: string;
  discordID: string;
  onChange: (record: RecordInput) => void;
};

type RecordInput = {
  displayName: string;
  discordUserID: string;
};

function GuildMemberAutocomplete({
  className,
  guildID,
  displayName,
  onChange,
}: Props) {
  const {
    data: members,
    isLoading,
    value,
    setValue: search,
  } = useSearchDiscordGuildMembers(guildID);

  return (
    <div className={className}>
      <FormControl>
        <Autocomplete
          loading={isLoading}
          onChange={(ev, val) => {
            const u = _.find(
              members,
              (m) => m.nick === val || m?.user?.username === val
            );

            const discordUserID = _.get(u, ["user", "id"]);
            const displayName = u?.nick || u?.user.username;
            onChange({ displayName, discordUserID });
          }}
          renderInput={(p) => (
            <TextField
              style={{ width: 320 }}
              required
              onChange={(ev) => {
                search(ev.target.value);
              }}
              value={displayName || value}
              label="Username"
              InputProps={{
                endAdornment: <>{isLoading && <CircularProgress />}</>,
              }}
              {...p}
            />
          )}
          options={_.compact(
            _.map(members, (m) => m.nick || m?.user?.username)
          )}
        />
        <FormHelperText>Type to search for Discord members</FormHelperText>
      </FormControl>
    </div>
  );
}

export default styled(GuildMemberAutocomplete).attrs({
  className: GuildMemberAutocomplete.name,
})``;
