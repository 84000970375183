import { Box } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { userCanPlanGroup } from "../../contexts/TaskingMapContext";
import { Module, modules } from "../../lib/data/modules";
import { DCSGroupData } from "../../lib/types";
import Flex from "../Flex";
import DownloadDTCFileButton from "../NavigationButtons/DownloadDTCFileButton";
import OpenDataWindow from "../NavigationButtons/OpenDataWindowButton";
import PlanningModeButton from "../NavigationButtons/PlanningModeButton";
import SoloGroupRouteButton from "../NavigationButtons/SoloGroupRouteButton";

type Props = {
  className?: string;
  group: DCSGroupData;
};

function FlyableGroup({ className, group }: Props) {
  const type = _.get(group, ["units", 0, "type"]);
  const module: Module = modules[type];

  const isPlannable = userCanPlanGroup(group.name);

  return (
    <div className={className}>
      <Flex wide between align>
        <div>
          {group.units.length}x {module?.label || type}
        </div>
      </Flex>

      <Box marginY={2}>
        <Flex wide>
          <OpenDataWindow group={group} />
          <SoloGroupRouteButton group={group} />
          <DownloadDTCFileButton group={group} />

          {isPlannable && <PlanningModeButton />}
        </Flex>
      </Box>
    </div>
  );
}

export default styled(FlyableGroup).attrs({ className: FlyableGroup.name })``;
