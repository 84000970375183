import * as React from "react";
import styled from "styled-components";
import { useTaskingState } from "../../contexts/TaskingMapContext";
import { convertDCSToLatLong, formatLatLong } from "../../lib/map";

type Props = {
  className?: string;
  bullseye?: { x: number; y: number };
};

function Bullseye({ className, bullseye }: Props) {
  const {
    state: {
      tasking: { theater },
    },
  } = useTaskingState();
  const { long, lat } = convertDCSToLatLong(theater, bullseye);

  return (
    <div className={className}>
      <pre>{formatLatLong([long, lat]).join("\n")}</pre>
    </div>
  );
}

export default styled(Bullseye).attrs({ className: Bullseye.name })``;
