import * as React from "react";
import { createElement } from "react";
import * as ReactDOMServer from "react-dom/server";

type Props = {
  height: number;
  width: number;
  children: any;
};

function SVGWrapper({ height, width, children }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      {/* Note: reload the entire dev server if you change this file */}
      <foreignObject width={width} height={height}>
        {/* @ts-ignore */}
        <div xmlns="http://www.w3.org/1999/xhtml">{children}</div>
      </foreignObject>
    </svg>
  );
}

export type HTMLGeneratorOpts = {
  height: number;
  width: number;
  fillColor?: string;
};
export interface HTMLImageGenerator {
  renderToURL(el: React.ReactElement, opts: HTMLGeneratorOpts): Promise<string>;
  renderToBlob(el: React.ReactElement, opts: HTMLGeneratorOpts): Promise<Blob>;
}

class generator implements HTMLImageGenerator {
  async renderToURL(
    el: React.ReactElement,
    opts: HTMLGeneratorOpts
  ): Promise<string> {
    const url = await this.render(true, el, opts);
    return url as string;
  }

  async renderToBlob(
    el: React.ReactElement,
    opts: HTMLGeneratorOpts
  ): Promise<Blob> {
    const blob = await this.render(false, el, opts);
    return blob as Blob;
  }

  private render(
    asURL: boolean,
    el: React.ReactElement,
    opts: HTMLGeneratorOpts
  ) {
    return new Promise<string | Blob>((accept, reject) => {
      const canvas = document.createElement("canvas");
      canvas.height = opts.height;
      canvas.width = opts.width;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = opts.fillColor || "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const str = ReactDOMServer.renderToStaticMarkup(
        createElement(SVGWrapper, {
          height: canvas.height,
          width: canvas.width,
          children: el,
        })
      );

      const img = new Image();
      img.crossOrigin = "anonymous";
      img.height = canvas.height;
      img.addEventListener(
        "load",
        () => {
          ctx.drawImage(img, 0, 0);
          if (asURL) {
            accept(canvas.toDataURL("image/png"));
          } else {
            canvas.toBlob((blob) => {
              accept(blob);
            });
          }
        },
        false
      );
      img.onerror = reject;
      img.width = canvas.width;

      img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(str)}`;
    });
  }
}

export function NewHTMLImageGenerator() {
  const g = new generator();

  return g;
}
