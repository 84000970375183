import { Alert } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useListPlanItems } from "../../contexts/PlanManagerV2";
import { useAdminGetCurrentManifests } from "../../hooks/frag_orders";
import { Coalition } from "../../lib/types";
import DataTable from "../DataTable";
import DocsHelpIcon from "../DocsHelpIcon";
import Flex from "../Flex";
import Section from "../Section";
import Timestamp from "../Timestamp";

type Props = {
  className?: string;
};

function PlanStatusV2({ className }: Props) {
  const { data: manifests, isLoading } = useAdminGetCurrentManifests();
  const blueMan = _.find(manifests, { coalition: Coalition.Blue });
  const redMan = _.find(manifests, { coalition: Coalition.Red });
  const {
    data: blue,
    isLoading: blueLoading,
    error: blueError,
  } = useListPlanItems(blueMan?.id, {
    refetchOnWindowFocus: true,
    refetchInterval: 10000,
  });

  const {
    data: red,
    isLoading: redLoading,
    error: redError,
  } = useListPlanItems(redMan?.id, {
    refetchOnWindowFocus: true,
    refetchInterval: 10000,
  });

  if (isLoading || blueLoading || redLoading) {
    return null;
  }

  if (blueError || redError) {
    return (
      <Alert severity="error">
        {(blueError as any)?.message || (redError as any)?.message}
      </Alert>
    );
  }

  const all = _.compact(
    _.concat(
      [],
      _.map(blue, (b) => ({
        ...b,
        coalition: Coalition.Blue,
      })),
      _.map(red, (r) => ({
        ...r,
        coalition: Coalition.Red,
      }))
    )
  );

  return (
    <Section
      loading={blueLoading || redLoading}
      title={
        <Flex wide>
          <div>Planning Status</div>
          <div style={{ marginLeft: 8, paddingTop: 2 }}>
            <DocsHelpIcon to="https://docs.fragorders.com/docs/managing-planners#handling-multiple-planners-for-a-single-group" />
          </div>
        </Flex>
      }
      className={className}
    >
      <DataTable
        columns={[
          { value: "group", label: "Group" },
          { value: "type", label: "Type" },
          {
            value: "createdByUserName",
            label: "Created By",
          },
          {
            value: (item) => <Timestamp time={item.updated_at_timestamp} />,
            label: "Last Updated",
          },
        ]}
        rows={all}
      />
    </Section>
  );
}

export default styled(PlanStatusV2).attrs({ className: PlanStatusV2.name })``;

// Saving for when a conflict is actually possible
// function findConflicts(arr: PlanItem[]) {
//   const groupedItems = arr.reduce((acc, item) => {
//     const key = `${item.group}-${item.uniqID}-${item.type}`;
//     if (!acc[key]) {
//       acc[key] = [];
//     }
//     acc[key].push(item);
//     return acc;
//   }, {} as Record<string, PlanItem[]>);

//   const duplicates = Object.keys(groupedItems)
//     .filter((key) => groupedItems[key].length > 1)
//     .map((key) => ({
//       key,
//       count: groupedItems[key].length,
//       items: groupedItems[key],
//     }));

//   return duplicates;
// }
