import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  AlertTitle,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { AircraftDetails } from "../lib/models/ExternalDataRecord";
import Flex from "./Flex";

type ColResolver = (row: any, colNum: number, rowNum: number) => any;

type Validator = (value: any) => string | null;

type GlobalValidator = (rows: AircraftDetails[]) => ValidationError[];

type ValidFieldResult = {
  fieldName: string;
  errors: string[];
  rowIndex: number;
};

export type ValidationError = {
  severity: "error" | "warning";
  message: string;
};

type Column = {
  value: string | ColResolver;
  label: string;
  fieldType: "text" | "number" | "boolean";
  width?: number;
  editable?: boolean;
  validators?: Validator[];
};

type Props = {
  className?: string;
  columns: Column[];
  rows: any[];
  onSubmit: (rows: any[]) => Promise<any>;
  tableProps?: TableProps;
  globalValidators?: GlobalValidator[];
};

function EditableTable({
  className,
  rows,
  columns,
  onSubmit,
  globalValidators,
  tableProps = {},
}: Props) {
  const [invalidFields, setInvalidFields] = React.useState<ValidFieldResult[]>(
    []
  );
  const [globalErrors, setGlobalErrors] = React.useState<ValidationError[]>([]);

  const [editingRows, setEditingRows] = React.useState<any[]>([]);

  const handleEditSubmit = (row: any, index: number) => {
    setEditingRows((prev) => prev.filter((i) => i !== index));

    const updated = [...rows];
    updated[index] = editingRows[index];

    onSubmit(updated).then(() => {
      setEditingRows(_.filter(editingRows, (row, i) => i !== index));
    });
  };

  const handleDeleteSubmit = (row: any, index: number) => {
    if (window.confirm("Are you sure you want to delete this row?")) {
      setEditingRows((prev) => prev.filter((i) => i !== index));

      const updated = [...rows];
      updated.splice(index, 1);

      onSubmit(updated).then(() => {
        setEditingRows(_.filter(editingRows, (row, i) => i !== index));
      });
    }
  };

  const validate = React.useCallback(
    _.debounce((er: any[]) => {
      // Validate the rows
      const results: ValidFieldResult[] = [];

      _.each(er, (row, index) => {
        if (!row) {
          return;
        }

        _.each(columns, (col) => {
          let val;
          if (typeof col.value === "function") {
            val = col.value(row, index, index);
          } else {
            val = row[col.value as string];
          }

          if (col.validators) {
            _.each(col.validators, (validator) => {
              const result = validator(val);

              if (result) {
                results.push({
                  fieldName: col.value as string,
                  errors: [result],
                  rowIndex: index,
                });
              }
            });
          }
        });
      });

      setInvalidFields(results);
    }, 250),
    []
  );

  const globalValidate = React.useCallback(
    _.debounce((rows: AircraftDetails[]) => {
      const errors: ValidationError[] = [];

      if (rows.length === 0) {
        return;
      }

      // Run validators
      _.each(globalValidators, (validator) => {
        const result = validator(rows);

        if (result) {
          errors.push(...result);
        }
      });

      setGlobalErrors(errors);
    }, 500),
    []
  );

  React.useEffect(() => {
    if (editingRows.length === 0) {
      return;
    }

    validate(editingRows);
  }, [editingRows]);

  React.useEffect(() => {
    globalValidate(rows);
  }, [rows]);

  return (
    <div className={className}>
      {globalErrors.length > 0 && (
        <Alert severity="warning">
          <AlertTitle>Validation Errors</AlertTitle>
          <ul>
            {_.map(globalErrors, (e, i) => (
              <li key={i}>{e.message}</li>
            ))}
          </ul>
        </Alert>
      )}
      <Table {...tableProps}>
        <TableHead>
          <TableRow>
            {_.map(columns, (c, i) => (
              <TableCell key={i} scope="col" width={c.width}>
                <strong>{c.label}</strong>
              </TableCell>
            ))}
            {/* Two more cells for buttons */}
            <TableCell scope="col" />

            <TableCell scope="col" />
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(rows, (row, rowNum: number) => {
            const isEditing = !!_.get(editingRows, rowNum);

            return (
              <TableRow key={rowNum}>
                {_.map(columns, (col, colNum: number) => {
                  let val;

                  const isEditable = col.editable !== false;

                  if (isEditing) {
                    val = _.get(editingRows, [rowNum, col.value as string]);
                  } else {
                    val = _.isFunction(col.value)
                      ? col.value(row, colNum, rowNum)
                      : row[col.value];
                  }

                  const Component = inputType(col.fieldType);

                  const invalid = _.find(invalidFields, {
                    fieldName: col.value as string,
                    rowIndex: rowNum,
                  });

                  return (
                    <TableCell key={colNum}>
                      {isEditable && isEditing ? (
                        <Component
                          variant="outlined"
                          value={val}
                          error={invalid ? true : false}
                          helperText={invalid ? invalid.errors.join(", ") : ""}
                          onChange={(e) => {
                            const next = [...editingRows];
                            next[rowNum] = {
                              ...next[rowNum],
                              [col.value as string]:
                                col.fieldType === "number"
                                  ? parseInt(e.target.value)
                                  : e.target.value,
                            };

                            setEditingRows(next);
                          }}
                        />
                      ) : (
                        val
                      )}
                    </TableCell>
                  );
                })}
                {isEditing ? (
                  <>
                    <TableCell>
                      <Flex>
                        <Tooltip title="Submit">
                          <IconButton
                            color="success"
                            onClick={() => handleEditSubmit(row, rowNum)}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton
                            onClick={() =>
                              setEditingRows(
                                _.filter(editingRows, (row, i) => i !== rowNum)
                              )
                            }
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            const next = [...editingRows];
                            next[rowNum] = row;
                            setEditingRows(next);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleDeleteSubmit(row, rowNum)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>

                      {/* <Button
                        variant="outlined"
                        onClick={() => {
                          const next = [...editingRows];
                          next[rowNum] = row;
                          setEditingRows(next);
                        }}
                      >
                        Edit
                      </Button> */}
                    </TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function inputType(type: string): React.ComponentType<any> {
  switch (type) {
    case "text":
      return TextField;
    case "number":
      return TextField;
    case "boolean":
      return Switch;

    default:
      return TextField;
  }
}

export default styled(EditableTable).attrs({ className: EditableTable.name })`
  font-size: 10px;

  input {
    font-size: 14px;
  }
`;
