import { Map } from "ol";

export class MapImager {
  private map: Map;
  constructor(map: Map) {
    this.map = map;
  }

  async toImage(): Promise<Blob> {
    const [blob] = await mapToImage(this.map);
    return blob;
  }

  async toImageURL(): Promise<string> {
    const [, canvas] = await mapToImage(this.map);

    return canvas.toDataURL("image/png");
  }
}

export async function mapToImage(m: Map) {
  return new Promise<[Blob, HTMLCanvasElement]>((accept) => {
    m.once("rendercomplete", () => {
      const mapCanvas = document.createElement("canvas");
      const size = m.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext("2d");

      const nodes =
        m.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer") ||
        [];

      nodes.forEach((canvas) => {
        if (canvas.width > 0) {
          const opacity =
            canvas.parentNode.style.opacity || canvas.style.opacity;
          mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);

          const backgroundColor = canvas.parentNode.style.backgroundColor;
          if (backgroundColor) {
            mapContext.fillStyle = backgroundColor;
            mapContext.fillRect(0, 0, canvas.width, canvas.height);
          }

          let matrix;
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(",")
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }
          // Apply the transform to the export map context
          mapContext.setTransform(matrix);

          mapContext.drawImage(canvas, 0, 0);
        }
      });

      mapCanvas.toBlob((blob) => {
        accept([blob, mapCanvas]);
      });
    });

    m.renderSync();
  });
}
