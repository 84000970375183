import Stripe from "stripe";
import { SubscriptionDoc, VolumeRedeemRecord } from "../models/Subscription";
import { Fetcher } from "./Fetcher";

export interface SubscriptionManager {
  createCheckoutSession(
    userID: string,
    priceID: PriceID,
    trialAvailable: boolean
  ): Promise<{ url: string }>;
  recordSessionResult(sessionID: string): Promise<any>;
  get(): Promise<{
    subscription: SubscriptionDoc;
    details: Stripe.Subscription;
  }>;

  getPortalURL(sessionID: string): Promise<string>;

  listVolumeSubs(sub: SubscriptionDoc): Promise<SubscriptionDoc[]>;
  listReedemCodes(sub: SubscriptionDoc): Promise<VolumeRedeemRecord[]>;
  getVolumeRecordByCode(code: string): Promise<VolumeRedeemRecord>;

  generateVolumeRedeemCode(sub: SubscriptionDoc): Promise<string>;
  redeemVolumeCode(code: string): Promise<void>;

  revokeGroupLicense(subID: string): Promise<void>;
}

const StripePriceIDMonthly = process.env.STRIPE_PRICE_ID_MONTHLY;
const StripePriceIDYearly = process.env.STRIPE_PRICE_ID_YEARLY;
const StripePriceIDVolume = process.env.STRIPE_PRICE_ID_VOLUME;

export enum PriceID {
  Monthly = "monthly",
  Yearly = "yearly",
  Volume = "volume",
}

export const PriceIDLookup = {
  [PriceID.Monthly]: StripePriceIDMonthly,
  [PriceID.Yearly]: StripePriceIDYearly,
  [PriceID.Volume]: StripePriceIDVolume,
};

class sub implements SubscriptionManager {
  fetch: Fetcher;

  constructor(f: Fetcher) {
    this.fetch = f;
  }

  get(): Promise<{
    subscription: SubscriptionDoc;
    details: Stripe.Subscription;
  }> {
    return this.fetch.req("/.netlify/functions/get_subscription");
  }

  createCheckoutSession(
    userID: string,
    priceID: PriceID,
    trialAvailable: boolean
  ): Promise<{ url: string }> {
    const stripeID = PriceIDLookup[priceID];

    if (!stripeID) {
      throw new Error(`Invalid price ID: ${priceID}`);
    }

    return this.fetch.req("/.netlify/functions/create_payment_session", {
      method: "POST",
      body: JSON.stringify({ userID, priceID: stripeID, trialAvailable }),
    });
  }

  recordSessionResult(sessionID: string): Promise<any> {
    return this.fetch.req("/.netlify/functions/record_session_result", {
      method: "POST",
      body: JSON.stringify({ sessionID }),
    });
  }

  getPortalURL(customerID: string): Promise<string> {
    return this.fetch
      .req<{ url: string }>("/.netlify/functions/create_portal_session", {
        method: "POST",
        body: JSON.stringify({ customerID }),
      })
      .then((res) => res.url);
  }

  listVolumeSubs(parent: SubscriptionDoc): Promise<SubscriptionDoc[]> {
    return this.fetch.req(
      `/.netlify/functions/list_volume_subs?parent=${parent.id}`
    );
  }

  listReedemCodes(parent: SubscriptionDoc): Promise<VolumeRedeemRecord[]> {
    return this.fetch.req(
      `/.netlify/functions/list_redeem_codes?parent=${parent.id}`
    );
  }

  generateVolumeRedeemCode(parent: SubscriptionDoc): Promise<string> {
    return this.fetch.req(`/.netlify/functions/generate_redeem_code`, {
      method: "POST",
      body: JSON.stringify(parent),
    });
  }

  redeemVolumeCode(code: string): Promise<void> {
    return this.fetch.req("/.netlify/functions/redeem_code", {
      method: "POST",
      body: JSON.stringify({ code }),
    });
  }

  getVolumeRecordByCode(code: string): Promise<VolumeRedeemRecord> {
    return this.fetch.req(
      `/.netlify/functions/get_redeem_by_code?code=${code}`
    );
  }

  revokeGroupLicense(subscriptionID: string): Promise<void> {
    return this.fetch.req("/.netlify/functions/revoke_group_license", {
      method: "POST",
      body: JSON.stringify({ subscriptionID }),
    });
  }
}

export function NewSubscriptionManager(f: Fetcher): SubscriptionManager {
  return new sub(f);
}
