import { Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

const LargeTooltip = (props) => (
  <Tooltip
    arrow
    placement={props.placement || "top"}
    title={<span style={{ fontSize: "16px" }}>{props.title}</span>}
  >
    {props.children}
  </Tooltip>
);

export default styled(LargeTooltip).attrs({ className: LargeTooltip.name })``;
