import * as React from "react";
import { getEntireExtent } from "../../lib/extent";
import { AirbasesKneeboardSettings } from "../../lib/models/Job";
import { TaskingState } from "../../lib/models/PublishManifest";
import { NewHTMLImageGenerator } from "../../lib/services/HTMLImageGenerator";
import {
  ImageComposer,
  NewImageComposer,
} from "../../lib/services/ImageComposer";
import SharedNavPointsLayer from "../MapCanvas/SharedNavPointsLayer";
import SupportAssetLayer from "../MapCanvas/SupportAssetLayer";
import KneeboardSupportAssets from "./KneeboardSupportAssets";
import {
  KNEEBOARD_HEIGHT,
  KNEEBOARD_WIDTH,
  renderMap,
} from "./kneeboard_utils";

export async function renderSupportAssetsKneeboard(
  tasking: TaskingState,
  jobSettings?: AirbasesKneeboardSettings
): Promise<ImageComposer> {
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);

  if (!tasking.supportAssets || tasking.supportAssets.length === 0) {
    throw new Error(
      `No support assets to render. Please disable the "Inject Support Assets" kneeboard job to resolve this issue.`
    );
  }

  const el = (
    <KneeboardSupportAssets
      settings={jobSettings}
      theater={tasking.theater}
      support={tasking?.supportAssets}
    />
  );
  const g = NewHTMLImageGenerator();

  const tblBlob = await g.renderToBlob(el, {
    height: KNEEBOARD_HEIGHT / 2 + 100,
    width: KNEEBOARD_WIDTH,
  });

  let ext = getEntireExtent(tasking.theater, tasking?.supportAssets);

  const mapImg = (await renderMap(
    ext,
    {
      height: (KNEEBOARD_HEIGHT - 100) / 2,
      width: KNEEBOARD_WIDTH,
      zoomAdjust: -0.2,
    },
    <>
      <SupportAssetLayer
        theater={tasking.theater}
        groups={tasking.supportAssets}
        color="black"
      />

      <SharedNavPointsLayer
        theater={tasking.theater}
        bullseye={tasking.bullseye}
      />
    </>
  )) as Blob;

  composer.add(tblBlob, { x: 0, y: 0 });
  composer.add(mapImg, { x: 0, y: (KNEEBOARD_HEIGHT + 100) / 2 });

  return composer;
}
