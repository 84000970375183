import AddCircle from "@mui/icons-material/AddCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, FormControl, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useUpdateControlMeasureEditors } from "../../contexts/PublishManagerContext";
import {
  useAdminGetCurrentFragOrder,
  useAdminGetManifestsForFragOrder,
} from "../../hooks/frag_orders";
import {
  ControlMeasureEditor as DrawingEditor,
  PublishManifestDoc,
} from "../../lib/models/PublishManifest";
import DataTable from "../DataTable";
import Flex from "../Flex";
import GuildMemberAutocomplete from "../PlanningManager/GuildMemberAutocomplete";
import NoDiscordGuildCallToAction from "../PlanningManager/NoDiscordGuildCallToAction";
import Section from "../Section";
import SubmittedDrawingList from "./SubmittedDrawingList";

type Props = {
  className?: string;
};

function DrawingEditors({ className }: Props) {
  const [nextEditors, setNextEditors] = React.useState<DrawingEditor[]>([]);
  const [removing, setRemoving] = React.useState<DrawingEditor>(null);
  const [updating, setUpdating] = React.useState(false);

  const update = useUpdateControlMeasureEditors();
  const { data: fragOrder, isLoading } = useAdminGetCurrentFragOrder();
  const { data: manifests } = useAdminGetManifestsForFragOrder();
  const { error, success } = useNotification();

  const showCTA = !isLoading && !fragOrder?.discordGuildID;

  const handleSubmit = (e) => {
    e.preventDefault();
    setUpdating(true);

    const work = _.map(manifests, (manifest) => {
      const editors = _.filter(
        nextEditors,
        (ed) =>
          ed.discordUserID &&
          ed.displayName &&
          ed.coalition === manifest.coalition
      ) as DrawingEditor[];

      if (editors.length === 0) {
        return Promise.resolve();
      }

      const p = update(manifest, [
        ...(manifest?.controlMeasureEditors || []),
        ...editors,
      ]);

      return p;
    });

    return Promise.all(work)
      .then(() => {
        success("Successfully updated drawing editors");
      })
      .catch((err) => {
        console.error(err);
        error(err.message);
      })
      .finally(() => {
        setUpdating(false);
        setNextEditors([]);
      });
  };

  const handleChangeEditor = (index: number, editor: DrawingEditor) => {
    const next = [...nextEditors];
    next[index] = editor;
    setNextEditors(next);
  };

  const handleRemove = (
    manifest: PublishManifestDoc,
    editor: DrawingEditor
  ) => {
    setRemoving(editor);
    const next = _.reject(manifest.controlMeasureEditors, editor);
    return update(manifest, next).finally(() => setRemoving(null));
  };

  if (showCTA) {
    return (
      <Section title="Control Measure Editors">
        <Flex wide center>
          <Typography variant="h6">
            <NoDiscordGuildCallToAction />
          </Typography>
        </Flex>
      </Section>
    );
  }

  return (
    <div className={className}>
      <Section title="Control Measure Editors">
        {_.map(manifests, (manifest) => {
          const nextEditorsForCoalition = _.filter(nextEditors, {
            coalition: manifest.coalition,
          });
          return (
            <div key={manifest.id}>
              <Typography
                color={manifest.coalition}
                fontSize={18}
                fontWeight={600}
              >
                {_.capitalize(manifest.coalition)} Control Measure Editors
              </Typography>

              <DataTable
                columns={[
                  {
                    label: "Name",
                    value: "displayName",
                  },
                  {
                    label: "",
                    value: (r: DrawingEditor) => (
                      <LoadingButton
                        loading={
                          removing?.discordUserID === r.discordUserID &&
                          removing.coalition === manifest.coalition
                        }
                        onClick={() => {
                          handleRemove(manifest, r);
                        }}
                        variant="outlined"
                        color="error"
                      >
                        Remove
                      </LoadingButton>
                    ),
                  },
                ]}
                rows={manifest.controlMeasureEditors}
              />
              <div>
                {nextEditorsForCoalition.length > 0 &&
                  _.map(nextEditors, (editor, i) => {
                    return (
                      <Box my={2} key={i}>
                        <FormControl>
                          <GuildMemberAutocomplete
                            displayName={editor.displayName}
                            discordID={editor.discordUserID}
                            onChange={(editor) =>
                              handleChangeEditor(i, {
                                ...editor,
                                coalition: manifest.coalition,
                              })
                            }
                            guildID={fragOrder.discordGuildID}
                          />
                        </FormControl>
                      </Box>
                    );
                  })}
              </div>
              <Flex>
                <Box my={2}>
                  <Button
                    variant="outlined"
                    startIcon={<AddCircle />}
                    onClick={() => {
                      const next = [...nextEditors];
                      next.push({
                        displayName: "",
                        discordUserID: null,
                        coalition: manifest.coalition,
                      });
                      setNextEditors(next);
                    }}
                  >
                    Add Editor
                  </Button>
                </Box>
              </Flex>
            </div>
          );
        })}

        <Box my={2}>
          <LoadingButton
            loading={updating}
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={nextEditors.length === 0}
          >
            Submit
          </LoadingButton>
        </Box>
      </Section>
      <Box mt={2}>
        <Section title="Submitted Control Measures">
          <SubmittedDrawingList />
        </Section>
      </Box>
    </div>
  );
}

export default styled(DrawingEditors).attrs({
  className: DrawingEditors.name,
})``;
