import _ from "lodash";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

function Link({ href, to = "", ...rest }) {
  return href ? (
    <a {...rest} href={href} />
  ) : (
    <RouterLink to={to} {..._.omit(rest, "innerRef")} />
  );
}

export default styled(Link)``;
