import _ from "lodash";
import { Point } from "ol/geom";
import * as React from "react";
import styled from "styled-components";
import {
  userCanPlanGroup,
  useTaskingState,
} from "../../contexts/TaskingMapContext";
import { metersToFeet } from "../../lib/map";
import { AltitudeType, DCSWaypoint } from "../../lib/types";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";
import OpenDataWindowButton from "../NavigationButtons/OpenDataWindowButton";
import PlanningModeButton from "../NavigationButtons/PlanningModeButton";
import SoloGroupRouteButton from "../NavigationButtons/SoloGroupRouteButton";

type Props = {
  className?: string;
  waypoint: DCSWaypoint;
  geometry: Point;
  legDistance: number;
  totalDistance: number;
  group: string;
};

function Waypoint({
  className,
  waypoint,
  legDistance,
  totalDistance,
  group,
}: Props) {
  const { state } = useTaskingState();
  const isPlannable = userCanPlanGroup(group);

  const g = _.find(state.tasking.plannedGroups, { name: group });

  const pairs = [
    { key: "Name", value: waypoint?.name || waypoint?.number },
    {
      key: "Altitude",
      value: `${waypoint && metersToFeet(waypoint.alt).toFixed(0)} ${
        waypoint?.alt_type === AltitudeType.MSL ? "MSL" : "AGL"
      } `,
    },
  ];

  if (legDistance) {
    pairs.push({
      key: "Distance (leg)",
      value: `${legDistance?.toFixed(1)} NM`,
    });
  }

  if (totalDistance) {
    pairs.push({
      key: "Distance (total)",
      value: `${totalDistance?.toFixed(1)} NM`,
    });
  }

  return (
    <div className={className}>
      <KeyValueTable columns={2} pairs={pairs} />
      {isPlannable && (
        <Flex align>
          <OpenDataWindowButton group={g} />
          <SoloGroupRouteButton group={g} />
          <PlanningModeButton />
        </Flex>
      )}
    </div>
  );
}

export default styled(Waypoint).attrs({ className: Waypoint.name })`
  td {
    padding: 0;
    border-bottom: 0px;
  }

  pre {
    margin: 0;
  }

  tr:nth-child(2) {
    td {
      vertical-align: top;
    }
  }
`;
