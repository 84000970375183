import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, FormGroup, Modal } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import DataTable from "../DataTable";
import ErrorPage from "../ErrorPage";
import Flex from "../Flex";
import Form from "../Form";
import FormInput from "../FormInput";
import LoadingPage from "../LoadingPage";
import Section from "../Section";

import { useNotification } from "../../contexts/NotifcationContext";
import {
  useFetchSuperAdminInfo,
  useIsSuperAdmin,
  useListSubscriptions,
  useRemoveSub,
  useSubmitSubscription,
} from "../../contexts/SuperAdminServicesContext";
import {
  Subscription,
  SubscriptionDoc,
  SubscriptionOrigin,
} from "../../lib/models/Subscription";

type Props = {
  className?: string;
};

const ModalContent = styled.div`
  background: white;
  position: absolute;
  top: 36px;
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
  min-width: 720px;
  max-height: 1024px;
  overflow: auto;
`;

type FormState = {
  ownerDiscordID: string;
  ownerDisplayName: string;
  discordGuildID: string;
  enabled: boolean;
};

function Subscriptions({ className }: Props) {
  const [removing, setRemoving] = React.useState(null);
  const [submittingSub, setSubmittingSub] = React.useState(false);
  const [subCreateModalOpen, setSubCreateModal] = React.useState(false);
  const {
    data: subscriptions,
    isLoading: subsLoading,
  } = useListSubscriptions();
  const submit = useSubmitSubscription();
  const remove = useRemoveSub();
  const { success, error } = useNotification();
  const { data: info, isLoading: infoLoading } = useFetchSuperAdminInfo();

  const { data: isAdmin, isLoading } = useIsSuperAdmin();

  if (isLoading || subsLoading || infoLoading) {
    return <LoadingPage />;
  }

  if (!isAdmin) {
    return <ErrorPage message="Page Not Found" stack="" />;
  }

  const handleSubscriptionSubmit = (formState: FormState) => {
    setSubmittingSub(true);

    submit({
      ...formState,
      origin: SubscriptionOrigin.SuperAdmin,
    })
      .then(() => {
        success(`Sub created`);
        setSubCreateModal(false);
      })
      .catch((e) => error(e.message))
      .finally(() => setSubmittingSub(false));
  };

  const handleSubscriptionRemove = (ownerID: string) => {
    setRemoving(ownerID);

    remove(ownerID)
      .then(() => {
        success("Removed sub for " + ownerID);
      })
      .catch((err) => error(err.message))
      .finally(() => setRemoving(null));
  };

  const initialFormState: FormState = {
    enabled: true,
    ownerDiscordID: "",
    discordGuildID: "",
    ownerDisplayName: "",
  };

  return (
    <div className={className}>
      <Box marginBottom={2}>
        <Alert severity="warning">Super Admins Only</Alert>
      </Box>

      <Section title="Subscriptions">
        <DataTable
          columns={[
            { value: "ownerDisplayName", label: "Display Name" },
            {
              value: (sub: SubscriptionDoc) => (
                <a
                  href={`${info?.firebaseDashboardURL}/firestore/data/~2FSubscriptions~2F${sub.id}`}
                  target="_blank"
                >
                  {sub.id}
                </a>
              ),
              label: "ID",
            },
            {
              value: (sub: Subscription) =>
                sub.enabled ? "Enabled" : "Disabled",
              label: "Enabled",
            },
            { value: "origin", label: "Origin" },
            {
              value: (sub: Subscription) => (
                <a
                  target="_blank"
                  href={info?.s3Address.replace(
                    "{{USER_ID}}",
                    sub.ownerDiscordID
                  )}
                >
                  {sub.ownerDiscordID}
                </a>
              ),
              label: "Discord ID",
            },
            {
              value: (s: Subscription) => (
                <a
                  target="_blank"
                  href={`${info?.stripeDashboardURL}/customers/${s.stripeCustomerID}`}
                >
                  {s.stripeCustomerID}
                </a>
              ),
              label: "Stripe Customer ID",
            },
            {
              value: (sub: Subscription) => (
                <LoadingButton
                  disabled={
                    sub.origin === SubscriptionOrigin.Stripe ||
                    sub.origin === SubscriptionOrigin.Volume
                  }
                  loading={removing === sub.ownerDiscordID}
                  variant="outlined"
                  color="error"
                  onClick={() => handleSubscriptionRemove(sub.ownerDiscordID)}
                >
                  Remove
                </LoadingButton>
              ),
              label: "",
            },
          ]}
          rows={_.sortBy(subscriptions, "ownerDisplayName", "desc")}
        />
        <Flex wide>
          <Box marginY={2}>
            <Button onClick={() => setSubCreateModal(true)} variant="contained">
              Add
            </Button>
          </Box>
        </Flex>
      </Section>
      <Modal onClose={() => setSubCreateModal(false)} open={subCreateModalOpen}>
        <ModalContent>
          <Box p={2}>
            <h3>Add Subscription</h3>
            <Form
              initialState={initialFormState}
              onSubmit={handleSubscriptionSubmit}
            >
              <FormGroup>
                <FormInput
                  autoComplete="off"
                  type="text"
                  name="ownerDiscordID"
                  label="Discord User ID"
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  required
                  autoComplete="off"
                  name="ownerDisplayName"
                  label="Owner Display Name"
                />
              </FormGroup>
              <FormGroup>
                <FormInput
                  autoComplete="off"
                  name="discordGuildID"
                  label="Discord Guild ID"
                />
              </FormGroup>
              <Box marginY={2}>
                <LoadingButton
                  loading={submittingSub}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Form>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default styled(Subscriptions).attrs({ className: Subscriptions.name })`
  margin: 0 auto;

  max-width: 1440px;
`;
