import { TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

function Input({
  className,
  value,
  type,
  name,
  label,
  onChange,
  ...rest
}: any) {
  return (
    <TextField
      className={className}
      id={name}
      type={type}
      value={value}
      label={label}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
      inputProps={{
        step: rest.step,
        ...rest.inputProps,
      }}
    />
  );
}

export default styled(Input)``;
