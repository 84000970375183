export interface ImageComposer {
  add(blob: Blob, opts: { x: number; y: number });

  renderToBlob(): Promise<Blob>;
  renderToURL(): Promise<string>;
}

type ImageOpts = {
  x: number;
  y: number;
  height: number;
  width: number;
};

type ImageConfig = {
  data: Blob;
  opts: ImageOpts;
};

class composer implements ImageComposer {
  height: number;
  width: number;
  backgroundColor: string = "#ffffff";

  private images: ImageConfig[] = [];
  private canvas: HTMLCanvasElement;

  add(data: Blob, opts: ImageOpts) {
    this.images.push({ data, opts });
  }

  private async render(): Promise<void> {
    this.canvas = document.createElement("canvas");
    const canvas = this.canvas;

    canvas.height = this.height;
    canvas.width = this.width;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = this.backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    for (const cfg of this.images) {
      await writeBlob(canvas, cfg);
    }
  }

  async renderToBlob(): Promise<Blob> {
    await this.render();

    return canvasToBlob(this.canvas);
  }

  async renderToURL(): Promise<string> {
    await this.render();

    return this.canvas.toDataURL("image/png");
  }
}

async function canvasToBlob(canvas: HTMLCanvasElement) {
  return new Promise<Blob>((accept) => {
    canvas.toBlob((blob) => {
      accept(blob);
    });
  });
}

async function writeBlob(canvas: HTMLCanvasElement, cfg: ImageConfig) {
  return new Promise<void>((accept, reject) => {
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.height = canvas.height;
    img.addEventListener(
      "load",
      () => {
        ctx.drawImage(img, cfg.opts.x, cfg.opts.y);
        accept();
      },
      false
    );
    img.onerror = reject;
    img.width = canvas.width;

    img.src = URL.createObjectURL(cfg.data);
  });
}

export function NewImageComposer(height: number, width: number): ImageComposer {
  const c = new composer();

  c.height = height;
  c.width = width;
  return c;
}
