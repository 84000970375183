import { FirebaseMetadata } from "../types";
import { LaserConfig, RadioChannelOverride } from "./Plan";

export enum PlanItemType {
  FuelPlan = "FuelPlan",
  Waypoint = "Waypoint",
  PlanNote = "Note",
  RadioPresets = "RadioPresets",
  LaserCodes = "LaserCodes",
  NavPoint = "NavPoint",
}

export type FuelPlan = {
  groundSpeed: number;
  fuelFlow: number;
  bingo: number;
  externalTanks: number;
  targetWaypoint: number;
};

export type PlanNote = {
  text: string;
};

export interface PlanItem {
  group: string;
  type: PlanItemType;
  manifestID: string;
  // Identifies the item in the context of its type.
  // Example would be waypoint number.
  uniqID: string;
  payload: string;
  createdByUserName?: string;
  deleted?: boolean;
}

export function getPlanItemPayload(
  p: PlanItem
): FuelPlan | PlanNote | RadioChannelOverride[] | LaserConfig {
  switch (p.type) {
    case PlanItemType.FuelPlan:
      return JSON.parse(p.payload) as FuelPlan;
    case PlanItemType.PlanNote:
      return JSON.parse(p.payload) as PlanNote;
    case PlanItemType.RadioPresets:
      return JSON.parse(p.payload) as RadioChannelOverride[];
    case PlanItemType.LaserCodes:
      return JSON.parse(p.payload) as LaserConfig;
    default:
      throw new Error(`Unknown plan item type: ${p.type}`);
  }
}

export type PlanItemDoc = PlanItem & FirebaseMetadata;
