import { Box, Grid } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  pairs: { key: string; value: any }[];
  columns: number;
};

const Key = styled.div`
  font-weight: bold;
`;

const Value = styled.div`
  text-overflow: ellipsis;
  overflow: hidden !important;
`;

const Item = styled(Box)``;

function KeyValueTable({ className, pairs, columns }: Props) {
  return (
    <div role="list" className={className}>
      <Grid container spacing={2} columns={columns}>
        {_.map(pairs, (a, i) => {
          const { key, value } = a;

          const label = key;

          return (
            <Grid item key={i}>
              <Item p={1}>
                <Key aria-label={label}>{label}</Key>
                <Value>
                  {value || <span style={{ marginLeft: 2 }}>-</span>}
                </Value>
              </Item>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default styled(KeyValueTable).attrs({ className: "KeyValueTable" })`
  width: 100%;

  tr {
    height: 72px;
    border-bottom: none;
  }
`;
