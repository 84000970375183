import JSZip from "jszip";
import { renderMasterFrequencyCard } from "../../components/Kneeboards/kneeboard_frequencies";
import { MizJobFunc } from "../models/Job";
import { RadioPresetConfig } from "../models/RadioPresetConfig";

export const injectMasterFreqCard = (
  radioConfig: RadioPresetConfig
): MizJobFunc => async (zip: JSZip) => {
  const composer = await renderMasterFrequencyCard(radioConfig);
  const blob = await composer.renderToBlob();
  zip = zip.file(`KNEEBOARD/IMAGES/FragOrders_Master_Frequencies.png`, blob);
  return zip;
};
