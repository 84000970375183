import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { saveAs } from "file-saver";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../../contexts/NotifcationContext";
import { useUpsertUserPreference } from "../../../contexts/UserPreferencesManagerContext";
import { useAdminGetCurrentFragOrder } from "../../../hooks/frag_orders";
import { ModuleRadio } from "../../../lib/data/modules";
import { RadioChannelOverride } from "../../../lib/models/Plan";
import { UserPreferenceType } from "../../../lib/models/UserPreference";
import { formatFreq } from "../../../lib/radios";
import { DCSRadioModulation, FreqBand } from "../../../lib/types";
import FastForm from "../../FastForm/FastForm";
import FastInput from "../../FastForm/FastInput";
import Flex from "../../Flex";
import ImportFromPrefsMenu from "./ImportFromPrefsMenu";
import RadioPreferenceDialog from "./RadioPreferenceDialog";
import { preset_defaults } from "./preset_defaults";
import { toPresets } from "./utils";

type Props = {
  className?: string;
  radios: ModuleRadio[];
  presets?: RadioChannelOverride[][];
  onSubmit: (presets: RadioChannelOverride[][]) => Promise<any>;
  disabled?: boolean;
  enableUserPreferenceActions?: boolean;
};

const TableContainer = styled.div`
  max-height: 720px;
  overflow: auto;

  th {
    background-color: #fff;
  }
`;

function RadioChannelInput({
  className,
  onSubmit,
  radios,
  presets,
  disabled,
  enableUserPreferenceActions = false,
}: Props) {
  const { data: fo } = useAdminGetCurrentFragOrder();
  const [submitting, setSubmitting] = React.useState(false);
  const [prefDialogOpen, setPrefDialogOpen] = React.useState(false);
  const { success, error } = useNotification();
  const saveToPrefs = useUpsertUserPreference();

  const [savingPref, setSavingPref] = React.useState(false);

  const fileInputRef = React.useRef<HTMLInputElement>();
  const [, setImportOpen] = React.useState(false);
  const [state, setState] = React.useState(initialState(radios, presets));

  const handleSubmit = (vals) => {
    setSubmitting(true);
    const p = toPresets(vals);
    return onSubmit(p).finally(() => {
      setSubmitting(false);
    });
  };

  const handleImporFromFile = () => {
    setImportOpen(false);
    const inpt = fileInputRef?.current;

    inpt.onchange = () => {
      let files = Array.from(inpt.files);
      const f = files[0];

      f.text().then((val) => {
        const p: RadioChannelOverride[][] = JSON.parse(val);

        const next = initialState(radios, p);

        setState(next);

        inpt.value = null;
      });
    };

    inpt.click();
  };

  const handleSave = () => {
    const b = new Blob([JSON.stringify(toPresets(state), null, 2)]);
    saveAs(b, `FragOrders_Presets_${fo.name}.json`);
  };

  const handleSaveToPrefs = async (prefName: string) => {
    setSavingPref(true);

    return saveToPrefs(
      prefName,
      UserPreferenceType.RadioPresetConfig,
      toPresets(state)
    )
      .then(() => {
        success("Saved preset configuration");
      })
      .catch((e) => {
        console.error(e);
        error(
          "Error saving preset configuration: " + e.message || "Unknown error"
        );
      })
      .finally(() => {
        setSavingPref(false);
        setPrefDialogOpen(false);
      });
  };

  const handleImportFromPrefs = (chans: RadioChannelOverride[][]) => {
    const next = initialState(radios, chans);
    setState(next);
  };

  return (
    <div className={className}>
      <FastForm onSubmit={handleSubmit}>
        <TableContainer>
          <Flex wide between>
            {_.map(radios, (r, i: number) => (
              <Box key={i} paddingRight={4}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: "center" }} colSpan={4}>
                        {r.label}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ width: 10 }}>Chan</TableCell>
                      <TableCell style={{ width: 90 }}>Freq</TableCell>
                      <TableCell style={{ width: 10 }}>AM/FM</TableCell>
                      <TableCell style={{ width: 120 }}>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.times(r.presetCount || 20, (t) => {
                      const radio = radios[i];
                      if (!radio) {
                        return;
                      }
                      const chan = radio[t];
                      let mod = chan?.modulation;
                      if (!mod) {
                        const band = _.first(r.bands);

                        if (band === FreqBand.VHF_FM) {
                          mod = DCSRadioModulation.FM;
                        }
                      }

                      const name = toKey(i, t, "frequency");
                      const freqValue = state[name];
                      const descValue = state[toKey(i, t, "description")];

                      return (
                        <TableRow key={t}>
                          <TableCell>{t + 1}</TableCell>
                          <TableCell>
                            <FastInput
                              name={name}
                              type="number"
                              size="small"
                              step="any"
                              formatter={(v) => formatFreq(v)}
                              defaultValue={freqValue}
                              disabled={disabled}
                            />
                          </TableCell>
                          <TableCell>
                            <FastInput
                              disabled={disabled}
                              inputProps={{ readOnly: true }}
                              name={toKey(i, t, "modulation")}
                              defaultValue={
                                mod === DCSRadioModulation.FM ? "FM" : "AM"
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <FastInput
                              name={toKey(i, t, "description")}
                              size="small"
                              defaultValue={descValue}
                              disabled={disabled}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            ))}
          </Flex>
        </TableContainer>

        <Box marginY={2}>
          <Flex wide between align>
            <div>
              <LoadingButton
                loading={submitting}
                variant="contained"
                type="submit"
                disabled={disabled}
              >
                Submit
              </LoadingButton>

              <Button
                onClick={handleSave}
                style={{ marginLeft: 8 }}
                variant="outlined"
                disabled={disabled}
              >
                Export to File
              </Button>

              {enableUserPreferenceActions && (
                <Button
                  onClick={() => setPrefDialogOpen(true)}
                  variant="outlined"
                  style={{ marginLeft: 8 }}
                >
                  Save Presets
                </Button>
              )}
            </div>
            <div>
              <Flex align wide>
                {enableUserPreferenceActions && (
                  <Box marginRight={2}>
                    <ImportFromPrefsMenu onSubmit={handleImportFromPrefs} />
                  </Box>
                )}

                <div>
                  <Button
                    disabled={disabled}
                    onClick={handleImporFromFile}
                    variant="outlined"
                  >
                    Import from File
                  </Button>
                </div>
              </Flex>
            </div>
          </Flex>
        </Box>
      </FastForm>
      <input
        ref={fileInputRef}
        type="file"
        accept=".json"
        style={{ display: "none" }}
      />
      <RadioPreferenceDialog
        onClose={() => setPrefDialogOpen(false)}
        open={prefDialogOpen}
        onSubmit={handleSaveToPrefs}
        saving={savingPref}
      />
    </div>
  );
}

export default styled(RadioChannelInput).attrs({
  className: RadioChannelInput.name,
})`
  box-sizing: border-box;

  .MuiTable-root {
    min-width: 360px;

    th {
      text-align: left;
      padding: 0 4px;
    }

    td {
      padding: 4px;
    }

    input {
      font-size: 14px;
      padding: 4px;
    }
  }
`;

function toKey(radioIdx: number, chan: number, key: string) {
  return `${radioIdx}.${chan}.${key}`;
}

function initialState(
  radios: ModuleRadio[],
  overrides: RadioChannelOverride[][]
) {
  const s = {};

  if (!overrides) {
    // Reordering here because V/UHF is second
    overrides = [
      preset_defaults[0],
      preset_defaults[2],
      preset_defaults[1],
    ] as RadioChannelOverride[][];
  }

  _.each(radios, (r, i) => {
    const modulation = _.first(r?.bands) === FreqBand.VHF_FM ? 1 : 0;
    const count = radios[i]?.presetCount || 20;
    _.times(count, (t) => {
      const k = toKey(i, t, "frequency");

      const ovr = overrides && _.get(overrides, [i, t]);
      s[k] = formatFreq(_.get(ovr, "frequency") || r[t]?.frequency);

      const dKey = toKey(i, t, "description");
      s[dKey] = _.get(ovr, "description") || "";

      const mKey = toKey(i, t, "modulation");
      s[mKey] = _.get(ovr, "modulation") || modulation;
    });
  });

  return s;
}
