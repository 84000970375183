import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Icon, LinkProps, Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import Link from "./Link";

type Props = {
  className?: string;
  to?: string;
  tooltip?: string;
} & LinkProps;

function DocsHelpIcon({ className, to, tooltip = "View the docs" }: Props) {
  return (
    <Tooltip title={tooltip}>
      {/* intercept the refs with this div */}
      <div>
        <Link className={className} target="_blank" href={to}>
          <Icon>
            <HelpOutlineIcon fontSize="small" />
          </Icon>
        </Link>
      </div>
    </Tooltip>
  );
}

export default styled(DocsHelpIcon).attrs({ className: DocsHelpIcon.name })``;
