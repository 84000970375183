import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Avatar, Button, IconButton, Menu, MenuItem } from "@mui/material";
import cx from "classnames";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { AppMode } from "../App";
import { useUser } from "../contexts/UserContext";
import { AppRoutes } from "../lib/types";
import Flex from "./Flex";
import Link from "./Link";
import Logo from "./Logo";

type Props = {
  className?: string;
  mode: AppMode;
  hide?: boolean;
};

function AppHeader({ className, mode, hide }: Props) {
  const { currentUser, logout } = useUser();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const loginMatch = useRouteMatch(AppRoutes.Login);
  const publicMatch = useRouteMatch(AppRoutes.PublicFragOrder);

  // Hack to remove this from the page when on the sign in page.
  // SignIn page embeds it's own header for more control
  const shouldHide =
    typeof hide !== "undefined"
      ? hide
      : loginMatch?.path === AppRoutes.Login ||
        publicMatch?.path === AppRoutes.PublicFragOrder;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <div className={cx(className, { hide: shouldHide })}>
      <Flex wide between align>
        <Link to={AppRoutes.Home}>
          <Logo width={140} height={84} />
        </Link>

        {mode === AppMode.Admin && (
          <div>
            <Link href="https://docs.fragorders.com" target="_blank">
              <Button endIcon={<OpenInNewIcon />}>View the Docs</Button>
            </Link>

            {currentUser ? (
              <>
                <IconButton onClick={handleClick}>
                  <Avatar
                    alt={currentUser.username}
                    src={`https://cdn.discordapp.com/avatars/${currentUser?.id}/${currentUser.avatar}.png`}
                  />
                </IconButton>

                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      history.push(AppRoutes.UserSettings);
                      handleClose();
                    }}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : null}
          </div>
        )}
      </Flex>
    </div>
  );
}

export default styled(AppHeader).attrs({ className: AppHeader.name })`
  width: 100%;

  /* background: ${(props) => props.theme.palette.background.paper}; */

  &.hide {
    display: none;
  }

  .menu-item a.active {
    color: white;
  }



  @media only screen and (max-width: 600px) {
    ${Logo} img {
      height: 56px;
      width: 94px;
    }

  }
`;
