import { Circle, Point as GeomPoint, LineString, Polygon } from "ol/geom";
import { getDistance } from "ol/sphere";
import xml from "xml-js";
import { Theater, convertDCSToLatLong } from "./map";
import { ControlMeasure } from "./models/ControlMeasure";
import { DCSGroupData } from "./types";

interface Waypoint {
  Name: string;
  ShortName: string;
  Type?: string;
  Position: Position;
  Color: string;
}

interface Waypoints {
  Waypoint: Waypoint[];
}

interface Object {
  Name: string;
  Color: string;
  Position: Position;
  Altitude: number;
}

interface Objects {
  Objects: {
    Object: Object[];
    Waypoints: Waypoints;
    Border: Border[];
  };
}

interface Position {
  Latitude: number;
  Longitude: number;
  Altitude: number;
}

interface Point {
  Position: Position;
}

interface Border {
  Name?: string;
  Color: string;
  Height: number;
  Point: Point[];
}

type Shape = Object & {
  Shape: string;
  Size: {
    Width: number;
    Height: number;
    Length: number;
  };
  Orientation: {
    Pitch: number;
  };
  BaseSize: {
    Width: number;
    Height: number;
    Length: number;
  };
};

export function toTacviewShapeXML(
  theater: Theater,
  groups: DCSGroupData[],
  colorMap: Record<string, string>,
  controlMeasures?: ControlMeasure[]
) {
  const tree: Objects = {
    Objects: {
      Object: [],
      Waypoints: {
        Waypoint: [],
      },
      Border: [],
    },
  };

  for (const group of groups) {
    for (const waypoint of group.waypoints) {
      const { lat, long } = convertDCSToLatLong(theater, waypoint);

      const Color = colorMap[group.name];

      tree.Objects.Waypoints.Waypoint.push({
        Name: `${group.name} - ${waypoint.name}`,
        ShortName: `${group.name} - ${waypoint.name}`,
        Color,
        Position: {
          Latitude: lat,
          Longitude: long,
          Altitude: waypoint.alt,
        },
      });
    }
  }

  if (controlMeasures) {
    for (const cm of controlMeasures) {
      const geomType = cm.geometry.getType();

      if (geomType === "LineString") {
        const points: Point[] = [];
        const line = cm.geometry as LineString;
        for (const coord of line.getCoordinates()) {
          const [long, lat] = coord;
          points.push({
            Position: {
              Latitude: lat,
              Longitude: long,
              Altitude: 0,
            },
          });
        }

        tree.Objects.Border.push({
          Color: cm.color,
          Height: 10000,
          Point: points,
        });

        // Add a label
        tree.Objects.Object.push({
          Name: cm.name,
          Color: cm.color,
          Position: {
            Latitude: line.getCoordinateAt(0.5)[1],
            Longitude: line.getCoordinateAt(0.5)[0],
            Altitude: 10000,
          },
          Altitude: 10000,
        });
      }

      if (geomType === "Polygon") {
        const points: Point[] = [];
        const poly = cm.geometry as Polygon;
        for (const ring of poly.getCoordinates()) {
          for (const coord of ring) {
            const [long, lat] = coord;
            points.push({
              Position: {
                Latitude: lat,
                Longitude: long,
                Altitude: 0,
              },
            });
          }
        }

        const centroid = poly.getInteriorPoint().getCoordinates();

        // Add a label
        tree.Objects.Object.push({
          Name: cm.name,
          Color: colorMap[cm.name],
          Position: {
            Latitude: centroid[1],
            Longitude: centroid[0],
            Altitude: 10000,
          },
          Altitude: 10000,
        });

        tree.Objects.Border.push({
          Color: cm.color,
          Height: 10000,
          Point: points,
        });
      }

      if (geomType === "Circle") {
        const circ = cm.geometry as Circle;
        const [long, lat] = circ.getCenter();

        const center = circ.getCenter();
        const rad = circ.getRadius();

        const edge = [center[0] + rad, center[1]];

        // meters
        const radius = getDistance(center, edge);

        tree.Objects.Object.push({
          Name: cm.name,
          Shape: "Tube",
          //   Add opacity
          Color: `${cm.color}79`,
          Position: {
            Latitude: lat,
            Longitude: long,
            Altitude: 0,
          },
          Size: {
            Width: radius * 2,
            Height: radius * 2,
            Length: 10000,
          },
          Orientation: {
            Pitch: 90,
          },
          BaseSize: {
            Width: radius * 2,
            Height: radius * 2,
            Length: 10000,
          },
        } as Shape);

        // Add a label
        tree.Objects.Object.push({
          Name: cm.name,
          Color: colorMap[cm.name],
          Position: {
            Latitude: center[1],
            Longitude: center[0],
            Altitude: 10000,
          },
          Altitude: 10000,
        });
      }

      if (geomType === "Point") {
        const point = cm.geometry as GeomPoint;
        const [long, lat] = point.getCoordinates();

        tree.Objects.Object.push({
          Name: cm.name,
          Color: cm.color,
          Position: {
            Latitude: lat,
            Longitude: long,
            Altitude: 0,
          },
          Altitude: 1000,
        });
      }
    }
  }

  let result = xml.js2xml(tree, { compact: true, spaces: 4 });

  result = `<?xml version="1.0" encoding="utf-8" standalone="yes"?>\n${result}`;
  return result;
}
