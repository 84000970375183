import { saveAs } from "file-saver";
// https://openlayers.org/en/latest/examples/export-map.html
export function downloadImage(width, height, filename) {
  var mapCanvas = document.createElement("canvas");
  mapCanvas.width = width;
  mapCanvas.height = height;
  var mapContext = mapCanvas.getContext("2d");

  document.querySelectorAll(".ol-layer canvas").forEach((canvas: any) => {
    if (canvas.width > 0) {
      var transform = canvas.style.transform;
      mapContext.fillStyle = "#525252";
      mapContext.fillRect(0, 0, canvas.width, canvas.height);
      // Get the transform parameters from the style's transform matrix
      var matrix = transform
        .match(/^matrix\(([^\\(]*)\)$/)[1]
        .split(",")
        .map(Number);
      // Apply the transform to the export map context
      CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
      mapContext.drawImage(canvas, 0, 0);
    }
  });

  const img = mapCanvas.toDataURL("image/png");
  saveAs(img, `${filename}.png`);
}
