import EditIcon from "@mui/icons-material/Edit";
import InputIcon from "@mui/icons-material/Input";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useCopyWaypoints } from "../../contexts/PlanManagerContext";
import {
  TaskingMapActionTypes,
  TaskingMapContext,
} from "../../contexts/TaskingMapContext";
import { getModuleForGroup } from "../../lib/data/modules";
import { LatLongDecimal } from "../../lib/map";
import WaypointFeature from "../../lib/models/WaypointFeature";
import { parseMizTime } from "../../lib/time";
import { FeatureClass, TaskingMapLayerName } from "../../lib/types";
import CoordinatesInput from "../CoordinatesInput";
import Flex from "../Flex";
import ModalContent from "../ModalContent";
import DownloadDTCFileButton from "../NavigationButtons/DownloadDTCFileButton";
import NavTargetPointsButton from "../NavigationButtons/NavTargetPointsButton";
import NoShadowStateButton from "../NavigationButtons/NoShadowStateButton";
import OpenDataWindowButton from "../NavigationButtons/OpenDataWindowButton";
import PlanningDataEntryButton from "../NavigationButtons/PlanningDataEntryButton";
import PlanningModeButton from "../NavigationButtons/PlanningModeButton";
import SoloGroupRouteButton from "../NavigationButtons/SoloGroupRouteButton";
import Section from "../Section";
import WaypointInput from "./WaypointInput/WaypointInput";

type Props = {
  className?: string;
  feature?: WaypointFeature;
};

function PlanningHopupTools({ className, feature }: Props) {
  const [coordsEntryOpen, setCoordsEntryOpen] = React.useState(false);
  const { dispatch, state, controller } = React.useContext(TaskingMapContext);
  const copy = useCopyWaypoints();

  const [importAnchor, setImportAnchor] = React.useState<null | HTMLElement>(
    null
  );
  const n: number = feature?.properties().number;
  const isGroupIconFeature = !n;
  const isWaypoint = feature?.class() === FeatureClass.Waypoint;
  const isEditableFeature =
    isWaypoint &&
    _.includes(state.userPlannableGroups, feature?.group()) &&
    n > 0;

  const renderButtons = isGroupIconFeature || isEditableFeature;
  const isFirst = n === 0;
  const disableDelete = isGroupIconFeature || isFirst;
  const renderInputs = isEditableFeature && !isFirst;
  const featureNumber = isGroupIconFeature ? 0 : n;
  const wps = isWaypoint
    ? controller?.current?.getWaypointsForGroup(feature?.group())
    : controller?.current?.getWaypointsForGroup(feature?.name()) || [];

  const handleAdd = () => {
    controller.current.toggleAddMode(
      true,
      feature.group ? feature?.group() : feature.name(),
      n || 0
    );
    dispatch({ type: TaskingMapActionTypes.blurFeature });
  };

  const handleArrowClick = (inc: number) => {
    const num = featureNumber + inc;

    if (num === 0) {
      dispatch({
        type: TaskingMapActionTypes.focusFeature,
        layerName: TaskingMapLayerName.PlayerGroups,
        name: feature?.group(),
        otherFeatures: [],
      });
      return;
    }

    if (isGroupIconFeature) {
      // We are looking at the group details here.
      // Find the first waypoint and set as next feature.
      const wp = controller.current.findWaypointForGroup(feature?.name(), 1);

      if (!wp) {
        return;
      }

      dispatch({
        type: TaskingMapActionTypes.focusFeature,
        layerName: feature?.name(),
        name: wp.get("name"),
        otherFeatures: [],
      });
      return;
    }

    const next = controller.current.findWaypointForGroup(feature?.group(), num);

    if (!next) {
      throw new Error("Could not find next waypoint");
    }

    dispatch({
      type: TaskingMapActionTypes.focusFeature,
      layerName: feature?.group(),
      name: next.get("name"),
      otherFeatures: [],
    });
  };

  const handleRemove = () => {
    controller.current.removeWaypoint(feature.group(), n);
    dispatch({ type: TaskingMapActionTypes.blurFeature });
  };

  const group = _.find(state.tasking.plannedGroups, {
    name: feature?.name() || feature?.group(),
  });

  const handleImportClick = React.useCallback(
    (sourceGroupName: string) => {
      if (!group) {
        return;
      }

      copy(sourceGroupName, group.name);
      setImportAnchor(null);
    },
    [group]
  );

  const handleCoordsSubmit = (coords: LatLongDecimal) => {
    controller.current.setWaypointCoords(feature.group(), featureNumber, [
      coords.long,
      coords.lat,
    ]);

    dispatch({
      type: TaskingMapActionTypes.focusFeature,
      layerName: feature.group(),
      name: feature.name(),
      otherFeatures: [],
    });

    setCoordsEntryOpen(false);
  };

  const mod = getModuleForGroup(group);

  const renderNavPointInput = isGroupIconFeature && mod?.supportsNavPoints;

  return (
    <div className={className}>
      {!state.planningMode && <Box>{feature?.render()}</Box>}
      <div style={{ position: "absolute", right: 8, top: 24 }}>
        <IconButton
          disabled={featureNumber === 0}
          onClick={() => handleArrowClick(-1)}
        >
          <NavigateBeforeIcon fontSize="small" />
        </IconButton>
        <IconButton
          disabled={featureNumber === wps?.length - 1}
          onClick={() => handleArrowClick(1)}
        >
          <NavigateNextIcon fontSize="small" />
        </IconButton>
      </div>

      {/* Hack to ensure the 'add/delete' buttons don't render for anythign but waypoints */}
      {renderButtons && (
        <Flex align className={className}>
          <Box marginRight={1}>
            <Button onClick={handleAdd} size="small" variant="contained">
              Add Waypoint
            </Button>
          </Box>

          <Box marginRight={1}>
            <Button
              disabled={disableDelete}
              onClick={handleRemove}
              size="small"
              variant="outlined"
            >
              Delete
            </Button>
          </Box>
          {isEditableFeature && (
            <Box>
              <Tooltip title="Edit Coordinates">
                <NoShadowStateButton
                  style={{ height: 36, width: 36 }}
                  onClick={() => setCoordsEntryOpen(true)}
                >
                  <EditIcon />
                </NoShadowStateButton>
              </Tooltip>
            </Box>
          )}
        </Flex>
      )}
      {renderInputs && (
        <WaypointInput
          controller={controller.current}
          feature={feature}
          missionStart={parseMizTime(state.tasking.time)}
          theater={state.tasking.theater}
        />
      )}

      <Box marginTop={1}>
        <Flex align>
          <OpenDataWindowButton group={group} />
          <SoloGroupRouteButton group={group} />
          <DownloadDTCFileButton group={group} />

          <PlanningModeButton />

          {!renderInputs && (
            <CopyButton onClick={(e) => setImportAnchor(e.target)} />
          )}

          {!renderInputs && <PlanningDataEntryButton groupName={group?.name} />}
          {renderNavPointInput && (
            <NavTargetPointsButton groupName={group?.name} />
          )}
        </Flex>
      </Box>

      <Menu
        anchorEl={importAnchor}
        open={Boolean(importAnchor)}
        onClose={() => setImportAnchor(null)}
      >
        {_.map(
          // Remove the current group from the list of groups to import from
          _.filter(state.tasking.plannedGroups, (g) => g.name !== group?.name),
          (g, i) => (
            <MenuItem key={i} onClick={() => handleImportClick(g.name)}>
              {g.name}
            </MenuItem>
          )
        )}
      </Menu>
      <Modal
        open={coordsEntryOpen}
        onClose={() => {
          setCoordsEntryOpen(false);
        }}
      >
        <ModalContent>
          <Section title="Edit Coordinates">
            <CoordinatesInput
              initialValue={feature}
              onSubmit={handleCoordsSubmit}
              onClose={() => setCoordsEntryOpen(false)}
            />
          </Section>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default styled(PlanningHopupTools).attrs({
  className: PlanningHopupTools.name,
})`
  .KeyValueTable {
    table td {
      padding-left: 0;
      border-bottom: 0;
    }
  }
`;

const CopyButton = styled(({ className, onClick }) => {
  return (
    <Tooltip className={className} title="Import Waypoints from Group">
      <NoShadowStateButton onClick={onClick}>
        <InputIcon />
      </NoShadowStateButton>
    </Tooltip>
  );
})``;
