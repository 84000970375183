import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, TextField } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import DiscordGuildSelect from "../components/DiscordGuildSelect";
import Flex from "../components/Flex";
import MissionFileDrop from "../components/MissionFileDrop";
import MizFileDetails from "../components/MizFileDetails/MizFileStatistics";
import Page from "../components/Page";
import Section from "../components/Section";
import { useGetDiscordGuilds } from "../contexts/DiscordClientContext";
import { useSaveFragOrder } from "../hooks/frag_orders";
import { fragOrderNavURL } from "../lib/models/FragOrder";
import { MizMetadata } from "../lib/tasking";

type Props = {
  className?: string;
};

function NewFragOrder({ className }: Props) {
  const history = useHistory();
  const save = useSaveFragOrder();
  const { isLoading } = useGetDiscordGuilds();
  const [nameVal, setNameVal] = React.useState("");
  const [mizDetails, setMizDetails] = React.useState<{
    data: MizMetadata;
    file: File;
  }>(null);
  const [discordServer, setDiscordServer] = React.useState<string>("");
  const [saving, setSaving] = React.useState(false);

  const handleFragOrderSubmit = (e) => {
    e.preventDefault();
    setSaving(true);

    save(
      nameVal,
      { metadata: mizDetails.data, file: mizDetails.file },
      discordServer
    ).then((fo) => {
      history.push(fragOrderNavURL(fo));
    });
  };

  const handleMizClear = (e) => {
    e.preventDefault();
    setMizDetails(null);
  };

  return (
    <Page loading={isLoading} title="New Frag Order" className={className}>
      <Section>
        <form>
          <Box paddingBottom={2}>
            <TextField
              onChange={(e) => setNameVal(e.target.value)}
              value={nameVal}
              helperText="The name of the Frag Order"
              required
              id="outlined-basic"
              label="Name"
              variant="outlined"
            />
          </Box>

          <Box paddingBottom={2}>
            <DiscordGuildSelect
              value={discordServer}
              onChange={setDiscordServer}
            />
          </Box>

          <Box paddingBottom={2}>
            {mizDetails ? (
              <MizFileDetails
                miz={{ metadata: mizDetails.data, file: mizDetails.file }}
              />
            ) : (
              <MissionFileDrop
                onDrop={(file, data) => {
                  if (!nameVal) {
                    setNameVal(file.name);
                  }
                  setMizDetails({ file, data });
                }}
              />
            )}
          </Box>
          <Flex>
            <LoadingButton
              onClick={handleFragOrderSubmit}
              disabled={!mizDetails}
              variant="contained"
              type="submit"
              loading={saving}
            >
              Submit
            </LoadingButton>
            <Button
              onClick={handleMizClear}
              disabled={!mizDetails}
              variant="outlined"
              type="button"
            >
              Clear Mission File
            </Button>
          </Flex>
        </form>
      </Section>
    </Page>
  );
}

export default styled(NewFragOrder).attrs({ className: NewFragOrder.name })`
  .MuiInputBase-input {
    width: 320px;
  }

  .MuiButton-root {
    margin-right: 8px;
  }
`;
