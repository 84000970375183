import * as React from "react";
import { Theater } from "../../lib/map";
import { DCSGroupData, TaskingMapLayerName } from "../../lib/types";
import GroupLayer from "./GroupLayer";

type Props = {
  theater: Theater;
  groups: DCSGroupData[];
};

export default function OPFORGroundUnits({ theater, groups }: Props) {
  return (
    <GroupLayer
      size={24}
      name={TaskingMapLayerName.OPFORGroups}
      theater={theater}
      groups={groups}
      isOpfor
      titleOverride="Enemy Group"
    />
  );
}
