import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { discordURL } from "../contexts/UserContext";
import { AppRoutes } from "../lib/types";
import DiscordLogo from "./DiscordLogo";
import Flex from "./Flex";

type Props = {
  className?: string;
  style?: any;
  text?: string;
};

function DiscordLoginButton({ className, style }: Props) {
  const [authUrl, setAuthURL] = React.useState(null);

  React.useEffect(() => {
    discordURL().then((url) => setAuthURL(url));
  }, []);

  const loginRouteMatch = useRouteMatch(AppRoutes.Login);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // We use this button in different places.
    // If on a public FO, we want to take them back to where they came from.
    // For admin login, we want to take the to /home.
    localStorage.setItem(
      "callbackNext",
      loginRouteMatch?.path === AppRoutes.Login
        ? AppRoutes.Home
        : `${window.location.pathname}${window.location.search}`
    );
    window.location.href = authUrl;
  };

  return (
    <Box
      style={style}
      className={className}
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <LoadingButton
        loading={!authUrl}
        type="submit"
        fullWidth
        variant="outlined"
      >
        <Flex align>
          <div style={{ marginRight: 12 }}>Sign In with Discord</div>
          <DiscordLogo height={24} width={24} />
        </Flex>
      </LoadingButton>
    </Box>
  );
}

export default styled(DiscordLoginButton).attrs({
  className: DiscordLoginButton.name,
})`
  white-space: nowrap;
  background-color: white;
`;
