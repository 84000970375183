import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { TaskingMapContext } from "../../contexts/TaskingMapContext";
import { Airbase } from "../../lib/data/airbases";
import { getAirbaseExtent, getParkingExtent } from "../../lib/extent";
import { Theater } from "../../lib/map";
import { DCSGroupData } from "../../lib/types";
import ParkingLayer from "../MapCanvas/ParkingLayer";
import MiniMap from "../MiniMap";

type Props = {
  className?: string;
  airbase: Airbase;
  groups: DCSGroupData[];
  theater: Theater;
};

function AirportDiagram({ className, theater, airbase, groups }: Props) {
  const hasGroups = groups.length > 0;
  const extent = hasGroups
    ? getParkingExtent(theater, groups)
    : getAirbaseExtent(theater, [airbase]);

  return (
    <div className={className}>
      <MiniMap zoomAdjust={hasGroups ? -0.2 : -14.5} extent={extent}>
        <ParkingLayer groups={groups} airbase={airbase} theater={theater} />
      </MiniMap>
    </div>
  );
}

function WrappedAirportDiagram() {
  const {
    state: { tasking, focusedFeature },
  } = React.useContext(TaskingMapContext);
  // Set once on initial render
  const ab = React.useRef<Airbase | null>(
    _.find(tasking.airbases, { Name: focusedFeature?.name as string })
  );

  const airbase = ab.current;

  if (!airbase) {
    return null;
  }

  const groups = _.filter(tasking.plannedGroups, {
    airdromeId: airbase?.AirdromeId,
  });

  return (
    <AirportDiagram
      airbase={airbase}
      groups={groups}
      theater={tasking.theater}
    />
  );
}

export default styled(WrappedAirportDiagram).attrs({
  className: AirportDiagram.name,
})``;
