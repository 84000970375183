import { Checkbox, FormControlLabel } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import Flex from "../Flex";

export type Val = { value: string; label: string; enabled: boolean };

type Props = {
  className?: string;
  items: Val[];
  selectedLayers: Val[];
  onChange: (selected: any) => void;
  heading?: string;
};

function LayerSelector({
  className,
  items,
  selectedLayers,
  onChange,
  heading = "Layers",
}: Props) {
  const handleChange = (checked, l: Val) => {
    onChange(l);
  };

  return (
    <Flex className={className} column>
      <div style={{ marginRight: 16 }}>{heading}</div>
      <div>
        <ul>
          {}
          {_.map(items, (l) => {
            const on = !!_.find(selectedLayers, l);
            return (
              <li key={l.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(ev) => handleChange(ev.target.checked, l)}
                      checked={on}
                    />
                  }
                  label={l.label}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </Flex>
  );
}

export default styled(LayerSelector)`
  background-color: ${(props) => props.theme.palette.background.paper};
  z-index: 2;
  padding: 8px;
  opacity: 0.8;
  border-radius: 4px;
  min-width: 150px;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    padding-right: 16px;
  }

  .MuiCheckbox-root {
    padding: 0 9px;
  }

  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    & {
      display: none;
    }
  }
`;
