import _, { uniqueId } from "lodash";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { convertDCSToLatLong, Theater } from "../map";
import { cumulativeDistance, distance } from "../time";
import { DCSWaypoint, FeatureClass } from "../types";
import { BaseFeature } from "./BaseFeature";

export default class WaypointFeature extends BaseFeature {
  constructor(f: Feature<Point>) {
    super(f);

    this.title = this.title.bind(this);
    this.group = this.group.bind(this);
  }

  title(): string {
    return `${this.feature.get("group")} Waypoint ${this.feature.get(
      "number"
    )}`;
  }

  group(): string {
    return this.feature.get("group");
  }

  toOLPointFeature(theater: Theater, allwp: DCSWaypoint[]) {
    const wp = this.properties() as DCSWaypoint;
    const { long, lat } = convertDCSToLatLong(theater, wp);
    const geometry = new Point([long, lat]);
    // Show waypoint names if they are meaninful.
    // If they are just numbers, hide them.
    const name = wp.name || wp.number.toString();

    const last = _.find(allwp, { number: wp.number - 1 });

    let d: number = null;
    let total: number = null;

    if (last) {
      d = distance(theater, last, wp);
      total = cumulativeDistance(theater, wp, allwp);
    }

    return {
      geometry,
      group: this.group(),
      featureClass: FeatureClass.Waypoint,
      name,
      number: wp.number,
      type: wp.type,
      title: `${this.group()} Waypoint ${wp.number}`,
      uniqID: uniqueId(this.group()),
      altitude: wp.alt,
      altitudeType: wp.alt_type,
      eta: wp.ETA,
      speed: wp.speed,
      waypoint: wp,
      legDistance: d,
      totalDistance: total,
    };
  }
}
