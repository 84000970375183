import { Coalition, FirebaseMetadata } from "../types";

export enum RecordType {
  GroupAdminDetails = "GroupAdminDetails",
  AircraftAdminDetails = "AircraftAdminDetails",
}

export enum TACANBand {
  X = "X",
  Y = "Y",
}

export interface GroupDetails {
  groupName: string;
  auxFrequency: number;
  tacanChannelLow: string;
  remarks: string;
}

export interface AircraftDetails {
  groupName: string;
  flightNumber: number;
  type: string;
  playerName?: string;
  iffMode1?: number;
  iffMode3?: number;
  tailNumber?: number;
  laserCode?: number;
  stn?: number;
  voiceCallsignLabel?: string;
  voiceCallsignNumber?: number;
}

export class ExternalDataRecord {
  coalition: Coalition;
  fragOrderID: string;
  type: RecordType;
  contentType: string;
  payload: string;
}

export function recordData(record: ExternalDataRecord) {
  switch (record.type) {
    case RecordType.AircraftAdminDetails:
      return JSON.parse(record.payload) as AircraftDetails[];
    case RecordType.GroupAdminDetails:
      return JSON.parse(record.payload) as GroupDetails[];

    default:
      throw new Error(`Unknown record type: ${record.type}`);
  }
}

export type ExternalDataRecordDoc = ExternalDataRecord & FirebaseMetadata;
