import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useAdminListControlMeasures } from "../../hooks/control_measures";
import { useGetDiscordGuildMembers } from "../../hooks/discord";
import {
  useAdminGetCurrentFragOrder,
  useAdminGetManifestsForFragOrder,
} from "../../hooks/frag_orders";
import { ControlMeasureDoc } from "../../lib/models/ControlMeasure";
import DataTable from "../DataTable";

type Props = {
  className?: string;
};

function SubmittedDrawingList({ className }: Props) {
  const { data: fragOrder } = useAdminGetCurrentFragOrder();
  const { data: manifests } = useAdminGetManifestsForFragOrder();
  const { data: controlMeasures } = useAdminListControlMeasures(manifests);
  const { data: users } = useGetDiscordGuildMembers(fragOrder.discordGuildID);

  return (
    <div className={className}>
      <DataTable
        columns={[
          { value: "name", label: "Name" },
          {
            value: (cm: ControlMeasureDoc) => cm.geometry.getType(),
            label: "Geometry Type",
          },
          {
            label: "Color",
            value: (cm: ControlMeasureDoc) => (
              <div
                style={{
                  width: 24,
                  height: 24,
                  backgroundColor: cm.color,
                }}
              />
            ),
          },
          {
            label: "Last Edited By",
            value: (cm: ControlMeasureDoc) => {
              const user = _.find(
                users,
                (u) => u.user.id === cm.created_by_uid
              );

              return user?.nick || user?.user?.username || "-";
            },
          },
        ]}
        rows={controlMeasures || []}
      />
    </div>
  );
}

export default styled(SubmittedDrawingList).attrs({
  className: SubmittedDrawingList.name,
})``;
