import * as React from "react";
import { AppMode } from "../App";
import { removeMissionFromLocalStorage } from "../lib/storage";

export const AppContext = React.createContext(null as any);

type Props = {
  mode: AppMode;
  children?: any;
};

function AppStateProvider(props: Props) {
  const [mission, setMissionData] = React.useState();
  const [dictionary, setDictionary] = React.useState();
  const [warehouses, setWarehouses] = React.useState();
  const [zipObj, setMizFile] = React.useState({
    name: "",
    entries: null,
  });

  const value = {
    mission,
    zipObj,
    dictionary,
    warehouses,
    setMizFile,
    setMissionData,
    setDictionary,
    setWarehouses,
    removeMission: () => {
      setMissionData(null);
      setDictionary({} as any);
      setMizFile(null);
      removeMissionFromLocalStorage();
    },

    mode: props.mode,
  };

  return <AppContext.Provider value={value} {...props} />;
}

export default AppStateProvider;
