export const preset_defaults = [
  [
    {
      frequency: "305.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "264.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "265.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "256.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "254.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "250.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "270.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "257.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "255.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "262.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "259.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "268.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "269.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "260.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "263.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "261.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "267.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "251.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "253.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "266.000",
      modulation: 0,
      description: "",
    },
  ],
  [
    {
      frequency: "127.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "135.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "136.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "127.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "125.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "121.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "141.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "128.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "126.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "133.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "130.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "139.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "140.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "131.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "134.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "132.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "138.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "122.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "124.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "137.000",
      modulation: 0,
      description: "",
    },
  ],
  [
    {
      frequency: "305.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "264.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "265.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "256.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "254.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "250.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "270.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "257.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "255.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "262.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "259.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "268.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "269.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "260.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "263.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "261.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "267.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "251.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "253.000",
      modulation: 0,
      description: "",
    },
    {
      frequency: "266.000",
      modulation: 0,
      description: "",
    },
  ],
];
