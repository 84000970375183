import { Alert, AlertTitle, Box, Divider } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useUserCanDrawControlMeasures } from "../../contexts/TaskingMapContext";
import { useUser } from "../../contexts/UserContext";
import { useSubmitControlMeasures } from "../../hooks/control_measures";
import { ControlMeasure } from "../../lib/models/ControlMeasure";
import DiscordLoginButton from "../DiscordLoginButton";
import Flex from "../Flex";
import ControlMeasureInput from "./ControlMeasureInput";
import ControlMeasureList from "./ControlMeasureList";

type Props = {
  className?: string;
};

function ControlMeasuresWindow({ className }: Props) {
  const { currentUser } = useUser();
  const isLoggedIn = !!currentUser;
  const isDrawingAllowed = useUserCanDrawControlMeasures();
  const add = useSubmitControlMeasures();
  const { success } = useNotification();

  const handleAdd = (cm: ControlMeasure) => {
    add.mutateAsync(cm).then(() => {
      success("Control Measure added successfully");
    });
  };

  return (
    <div className={className}>
      {isDrawingAllowed && (
        <>
          <Box p={2}>
            <ControlMeasureInput onAddControlMeasure={handleAdd} />
          </Box>
          <Divider />
        </>
      )}

      <Box p={2}>
        <ControlMeasureList />
      </Box>
      {!isLoggedIn && (
        <Box m={2}>
          <Alert style={{ justifyContent: "center" }} severity="info">
            <AlertTitle>Not Logged In</AlertTitle>
            You must be logged in to add control measures. The Frag Order admin
            must give permission for editing control measures.
            <Flex wide center>
              <DiscordLoginButton style={{ maxWidth: 240 }} />
            </Flex>
          </Alert>
        </Box>
      )}
    </div>
  );
}

export default styled(ControlMeasuresWindow).attrs({
  className: ControlMeasuresWindow.name,
})``;
