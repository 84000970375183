import _ from "lodash";
import mgrs from "mgrs";
import { Extent, boundingExtent } from "ol/extent";
import { Point, Polygon } from "ol/geom";
import { Projection, getPointResolution } from "ol/proj";
import proj4 from "proj4";
import { DCSDrawingObject } from "./types";

export type LatLongMinutes = {
  lat: [number, number];
  long: [number, number];
};

export type LatLongDecimal = {
  lat: number;
  long: number;
};

export type DCSCoords = {
  x: number;
  y: number;
};

export enum Theater {
  PG = "PG",
  CAUCASUS = "CAUCASUS",
  SYRIA = "SYRIA",
  NEVADA = "NEVADA",
  MARIANAS = "MARIANAS",
  SOUTH_ATLANTIC = "SOUTH_ATLANTIC",
  SINAI = "SINAI",
  NORMANDY = "Normandy2",
  KOLA = "KOLA",
  AFGHANISTAN = "AFGHANISTAN",
}

// To figure out a new projection:
// Move the lon_0 until all diff values line up,
// then correct the +y_0 until diff drops to zero.
// lon_0 controls xdiff and should be rougly mid-map.
// COPY THE COORDS CORRECTLY FROM THE CSV
export function getProjectionString(t: Theater): string {
  switch (t) {
    // Backwards compat after fixing a spelling error
    // @ts-ignore
    case "CAUCASES":
    case Theater.CAUCASUS:
      return "+proj=tmerc +lon_0=33 +k_0=0.9996 +x_0=-99517 +y_0=-4998115";

    case Theater.PG:
      return "+proj=tmerc +lon_0=57 +k_0=0.9996 +x_0=75757 +y_0=-2894931";

    case Theater.SYRIA:
      return "+proj=tmerc +lon_0=39 +k_0=0.9996 +x_0=282801 +y_0=-3879865";

    case Theater.NEVADA:
      return "+proj=tmerc +lon_0=-117 +k_0=0.9996 +x_0=-193996 +y_0=-4410028";

    case Theater.MARIANAS:
      return `+proj=tmerc +lon_0=147 +k_0=0.9996 +x_0=238418 +y_0=-1491840`;

    case Theater.SOUTH_ATLANTIC:
      return `+proj=tmerc +lon_0=-57 +k_0=0.9996 +x_0=147640 +y_0=5815417`;

    case Theater.SINAI:
      return `+proj=tmerc +lon_0=33 +k_0=0.9996 +x_0=169222 +y_0=-3325313`;

    case Theater.NORMANDY:
      return `+proj=tmerc +lon_0=-3 +k_0=0.9996 +x_0=-195526.00000000204 +y_0=-5484812.999999951`;

    case Theater.KOLA:
      return `+proj=tmerc +lon_0=21 +k=0.9996 +x_0=-62702 +y_0=-7543625`;

    case Theater.AFGHANISTAN:
      return `+proj=tmerc +lon_0=63 +k=0.9996 +x_0=-300150 +y_0=-3759657`;
  }
}

// Arbitrary center point that the map will use to center on initial load.
export function getCentroid(t: Theater): number[] {
  switch (t) {
    // Backwards compat after fixing a spelling error
    // @ts-ignore
    case "CAUCASES":
    case Theater.CAUCASUS:
      return [41.7302, 42.12121];

    case Theater.PG:
      return [54.98095, 25.01897];

    case Theater.SYRIA:
      return [35.98095, 35.01897];

    case Theater.NEVADA:
      return [35.98095, -114];

    case Theater.MARIANAS:
      return [14.174905098823, -145];

    case Theater.NORMANDY:
      return [49.174905098823, -0.145];
  }
}

// https://github.com/DCS-Web-Editor/dcs-web-editor-mono/blob/main/packages/map-projection/src/mapCoordinates.ts
const coords = {
  ["CAUCASES"]: [
    {
      lat: 48.387663480938,
      long: 26.778743595881,
    },
    // bottom left
    {
      lat: 39.608931903399,
      long: 27.637331401126,
    },
    // bottom right
    {
      lat: 38.86511140611,
      long: 47.142314272867,
    },
    // top right
    {
      lat: 47.382221906262,
      long: 49.309787386754,
    },
  ],
  [Theater.CAUCASUS]: [
    // top left
    {
      lat: 48.387663480938,
      long: 26.778743595881,
    },
    // bottom left
    {
      lat: 39.608931903399,
      long: 27.637331401126,
    },
    // bottom right
    {
      lat: 38.86511140611,
      long: 47.142314272867,
    },
    // top right
    {
      lat: 47.382221906262,
      long: 49.309787386754,
    },
  ],
  [Theater.SYRIA]: [
    // top left
    {
      lat: 37.470301761465,
      long: 29.480123666167,
    },
    // bottom left
    {
      lat: 31.683960285685,
      long: 30.123622480902,
    },
    // bottom right
    {
      lat: 31.960960214436,
      long: 41.932899899137,
    },
    // top right
    {
      lat: 37.814134114831,
      long: 42.148931009427,
    },
  ],
  [Theater.PG]: [
    {
      lat: 32.955527544002,
      long: 46.583433745255,
    },
    {
      lat: 21.749230188233,
      long: 47.594358099874,
    },
    {
      lat: 21.869681127563,
      long: 63.997389263298,
    },
    {
      lat: 33.150981840679,
      long: 64.756585025318,
    },
  ],
  [Theater.NORMANDY]: [
    // top left
    {
      lat: 51.853053209954,
      long: -3.5005307234326,
    },
    {
      lat: 48.345555267203,
      long: -3.4652619527823,
    },
    {
      lat: 48.182820700457,
      long: 3.1296001999935,
    },
    {
      lat: 51.668977027237,
      long: 3.5903264200692,
    },
    {
      lat: 51.853053209954,
      long: -3.5005307234326,
    },
  ],
  [Theater.NEVADA]: [
    {
      lat: 39.801712624973,
      long: -119.9902311096,
    },
    {
      lat: 34.400025213159,
      long: -119.78488669575,
    },
    {
      lat: 34.346907399159,
      long: -112.44599267994,
    },
    {
      lat: 39.737162541546,
      long: -112.11118674647,
    },
  ],
  [Theater.MARIANAS]: [
    {
      lat: 22.220143285088,
      long: 136.96126049266,
    },
    {
      lat: 10.637681299806,
      long: 137.54638410345,
    },
    {
      lat: 10.739229846557,
      long: 152.12973515767,
    },
    {
      lat: 22.44081213808,
      long: 152.4517401234,
    },
  ],
  [Theater.SOUTH_ATLANTIC]: [
    {
      lat: -45.850907963742,
      long: -84.733179722768,
    },
    {
      lat: -53.241290032056,
      long: -89.780310307149,
    },
    {
      lat: -56.442360340952,
      long: -38.172247338514,
    },
    {
      lat: -48.278746783249,
      long: -41.444185881767,
    },
  ],
  [Theater.SINAI]: [
    {
      lat: 34.55404881,
      long: 25.448642,
    },
    {
      lat: 25.06909856,
      long: 25.448642,
    },
    {
      lat: 25.06909856,
      long: 41.69756169,
    },
    {
      lat: 34.55404881,
      long: 41.69756169,
    },
  ],
  [Theater.KOLA]: [
    {
      lat: 72.0,
      long: -4.0,
    },
    {
      lat: 71.0,
      long: 48.0,
    },
    {
      lat: 63.0,
      long: 39.0,
    },
    {
      lat: 64.0,
      long: -3,
    },
  ],
  [Theater.AFGHANISTAN]: [
    {
      lat: 39,
      long: 60,
    },
    {
      lat: 29.4,
      long: 60.2,
    },
    {
      lat: 29,
      long: 74.5,
    },
    {
      lat: 38.6,
      long: 75.34,
    },
  ],
};

export function getMapCorners(t: Theater): LatLongDecimal[] {
  return coords[t];
}

export function getDefaultExtent(t: Theater): Extent {
  const corners = getMapCorners(t);

  const m = _.map(corners, (c) => [c.long, c.lat]);

  const extent = boundingExtent(m);

  return extent;
}

export function degreesMinutesToDecimal(ary: [number, number]): number {
  // Minutes and seconds are just degrees plus their decimal place divided by 60
  const isPositive = Math.sign(ary[0]) === 1;
  const f = isPositive
    ? parseFloat(`${ary[0] + ary[1] / 60}`)
    : parseFloat(`${ary[0] - ary[1] / 60}`);

  return Number(f.toFixed(6));
}

export function decimalToDegreesMinutes(dec: number): [number, number] {
  const [degStr, decStr] = dec.toString().split(".");

  const decimal = parseFloat(`0.${decStr}`);

  const f = decimal * 60;

  return [parseInt(degStr), Number(f.toFixed(4))];
}

export function decimalToDMS(decimal: number, isLatitude: boolean) {
  var indicator = isLatitude
    ? decimal >= 0
      ? "N"
      : "S"
    : decimal >= 0
    ? "E"
    : "W";
  var degrees = Math.floor(decimal);
  var minutesAndSeconds = (decimal - degrees) * 60;
  var minutes = Math.floor(minutesAndSeconds);
  var seconds = (minutesAndSeconds - minutes) * 60;

  return (
    indicator +
    " " +
    degrees +
    "° " +
    minutes +
    "' " +
    seconds.toFixed(2) +
    "''"
  );
}

export function decimalToSeconds(decimal) {
  var minutesAndSeconds = (decimal - Math.floor(decimal)) * 60;
  var seconds = minutesAndSeconds * 60;
  return seconds.toFixed(2);
}

export type LatLongDecimalArray = [number, number];

export function formatLatLong(
  [longDec, latDec]: LatLongDecimalArray,
  decLength: number = 4
) {
  const northingText = Math.sign(latDec) === -1 ? "S" : "N";
  const eastingText = Math.sign(longDec) === -1 ? "W" : "E";

  const [ltDeg, ltDec] = decimalToDegreesMinutes(latDec);
  const [lnDeg, lnDec] = decimalToDegreesMinutes(longDec);

  const ltStr = "0" + ltDec.toFixed(decLength);
  const lnStr = "0" + lnDec.toFixed(decLength);

  // Trims or doesn't trim the leading 0
  const sliceLen = (decLength + 3) * -1;

  return [
    `${northingText} ${Math.abs(ltDeg)}° ${ltStr.slice(sliceLen)}`,
    `${eastingText} ${Math.abs(lnDeg)}° ${lnStr.slice(sliceLen)}`,
    latLongToMGRS({ lat: latDec, long: longDec }),
  ];
}

// Don't use array return args...
export function formatLatLong2(
  [longDec, latDec]: LatLongDecimalArray,
  decLength: number = 4
) {
  const [lat, long, mgrs] = formatLatLong([longDec, latDec], decLength);

  return {
    lat,
    long,
    mgrs,
  };
}

export function latLongToMGRS(coords: LatLongDecimal) {
  const m = mgrs.forward([coords.long, coords.lat], 5);
  const formatted: string[] = m.split("");
  const ref = formatted.slice(0, 3).join("");
  const square = formatted.slice(3, 5).join("");
  const northing = formatted.slice(5, 10).join("");
  const easting = formatted.slice(10, 15).join("");

  return [ref, square, northing, easting].join(" ");
}

export function mgrsToLatLong(mgrsString: string): LatLongDecimal {
  const str = mgrsString.replace(/\s/g, "");
  const m = mgrs.toPoint(str);

  return { lat: m[1], long: m[0] };
}

export function convertLatLongToDCS(
  map: Theater,
  { lat, long }: LatLongDecimal
): DCSCoords {
  const projectionString = getProjectionString(map);

  const [y, x] = proj4(projectionString, [long, lat]);

  return { x: Number(x.toFixed(5)), y: Number(y.toFixed(5)) };
}

export function convertDCSToLatLong(
  map: Theater,
  coords: DCSCoords
): LatLongDecimal {
  if (!coords) {
    throw new Error(
      "This Frag Order was created with an older version of Frag Orders. Please re-publish/update this Frag Order."
    );
  }
  const { x, y } = coords;
  if (!map) {
    throw new Error("Theater was undefined");
  }
  const projectionString = getProjectionString(map);

  const [long, lat] = proj4(projectionString).inverse([y, x]);

  return { lat, long };
}

export function metersToFeet(meters: number) {
  return meters * 3.28084;
}

export function feetToMeters(feet: number) {
  return feet * 0.3048;
}

export function verticiesToLatLong(theater: Theater, vertices: DCSCoords[]) {
  return _.map(vertices, (v) => {
    const { lat, long } = convertDCSToLatLong(theater, v);

    return [long, lat];
  });
}

export function nauticalMilesToMeters(nm: number) {
  return nm * 1852;
}

export function toDrawingRectangle(
  theater: Theater,
  d: DCSDrawingObject
): Polygon {
  const origin = convertDCSToLatLong(theater, { x: d.mapX, y: d.mapY });
  const projection = new Projection({
    code: "EPSG:4326",
    units: "m",
  });

  const pointRes = getPointResolution(projection, 1, [origin.long, origin.lat]);

  const point1 = new Point([origin.long, origin.lat]);
  point1.translate(-d.width / 2 / pointRes, -d.height / 2 / pointRes);

  const point2 = point1.clone();
  point2.translate(d.width / pointRes, 0);

  const point3 = point1.clone();
  point3.translate(d.width / pointRes, d.height / pointRes);

  const point4 = point1.clone();
  point4.translate(0, d.height / pointRes);

  const coords = [
    point1.getCoordinates(),
    point2.getCoordinates(),
    point3.getCoordinates(),
    point4.getCoordinates(),
  ];

  return new Polygon([coords]);
}

export function parseLatLong(latLongStr) {
  const regex = /([NS])?\s*(\d{1,2})°\s*(\d{1,2}\.\d+)'?\s*([NS])?\s*([EW])?\s*(\d{1,3})°\s*(\d{1,2}\.\d+)'?\s*([EW])?/;
  const match = latLongStr.match(regex);

  if (!match) {
    return null;
  }

  let latSign = 1,
    lonSign = 1;

  if (match[1] === "S" || match[4] === "S") {
    latSign = -1;
  }
  if (match[5] === "W" || match[8] === "W") {
    lonSign = -1;
  }

  const latitude = latSign * (parseInt(match[2]) + parseFloat(match[3]) / 60);
  const longitude = lonSign * (parseInt(match[6]) + parseFloat(match[7]) / 60);

  return { latitude, longitude };
}
