import { Box, Tab, Tabs, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useGroupsWithPlans } from "../../contexts/PlanManagerContext";
import {
  TaskingMapContext,
  userCanPlanGroup,
} from "../../contexts/TaskingMapContext";
import { PlanStatus } from "../../lib/models/Plan";
import Flex from "../Flex";
import PlanningDataEntry from "./PlanningDataEntry";

type Props = {
  className?: string;
};

const StyledTab = styled(Tab)`
  &.MuiButtonBase-root {
    white-space: nowrap;
    justify-content: center;
    align-items: flex-start;
  }

  .tab-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function DataEntryWindow({ className }: Props) {
  const {
    state: { tasking, manifest },
  } = React.useContext(TaskingMapContext);

  const { data: groups, isLoading } = useGroupsWithPlans(
    manifest.fragOrderID,
    tasking.plannedGroups,
    { status: PlanStatus.Submitted }
  );

  const plannable = _.filter(groups, (g) => userCanPlanGroup(g.name));
  const q = new URLSearchParams(window.location.search);
  const groupName = decodeURIComponent(q.get("group"));
  const initialGroup = _.findIndex(plannable, { name: groupName });
  const [selected, setSelected] = React.useState(
    initialGroup === -1 ? 0 : initialGroup
  );

  if (isLoading) {
    return null;
  }

  const currentGroup = _.find(plannable, {
    name: _.get(plannable, [selected, "name"]),
  });

  if (plannable.length === 0) {
    return (
      <Flex wide center>
        <Box marginTop={4}>
          <Typography fontStyle="italic">
            You currently not permitted to plan any groups
          </Typography>
        </Box>
      </Flex>
    );
  }

  return (
    <div className={className}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selected}
        onChange={(ev, v) => setSelected(v)}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider", overflow: "auto" }}
      >
        {_.map(plannable, (a, i) => (
          <StyledTab
            value={i}
            style={{ textAlign: "left" }}
            key={a.name}
            label={<span className="tab-text">{a.name}</span>}
            {...a11yProps(i)}
          />
        ))}
      </Tabs>

      <PlanningDataEntry group={currentGroup} />
    </div>
  );
}

export default styled(DataEntryWindow).attrs({
  className: DataEntryWindow.name,
})`
  display: flex;

  .MuiTabs-root {
    min-width: 200px;
    width: 200px;
  }
`;
