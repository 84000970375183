import _ from "lodash";
import { Feature } from "ol";
import { Polygon } from "ol/geom";
import { circular } from "ol/geom/Polygon";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Fill, Stroke, Style, Text as TextStyle } from "ol/style";
import { Theater, convertDCSToLatLong, verticiesToLatLong } from "../map";
import { DCSZoneData, ZoneType } from "../types";

export function createZoneLayer(theater: Theater, zones: DCSZoneData[]) {
  const features = _.map(zones, (zone) => {
    const { lat, long } = convertDCSToLatLong(theater, zone.origin);
    const point = [long, lat];

    let geometry = circular(point, zone.radius);

    if (zone.type === ZoneType.Quad) {
      const zoneCoords = verticiesToLatLong(theater, zone.verticies);
      geometry = new Polygon([zoneCoords]);
    }

    const f = new Feature({
      type: "Polygon",
      geometry,
      color: zone.color,
      name: zone.name,
    });

    return f;
  });

  const layer = new VectorLayer({
    declutter: true,
    source: new VectorSource({
      features,
    }),
    style: (feature) => {
      const [r, g, b, a] = _.map(feature.get("color"), (c) => {
        const k = c * 255;
        return k;
      });

      const colorFill = new Fill({
        color: `rgb(${r},${g},${b}, 1)`,
      });
      return [
        new Style({
          stroke: new Stroke({
            color: `rgb(${r},${g},${b}, 1)`,
            width: 2,
          }),
          fill: new Fill({
            color: `rgb(${r},${g},${b}, ${a / 255})`,
          }),
          text: new TextStyle({
            overflow: true,
            font: 'bold 14px "Arial", "Arial Unicode MS", "sans-serif"',
            placement: "point",
            fill: colorFill,
            text: feature.get("name"),
          }),
        }),
      ];
    },
  });

  layer.set("name", "Zones");

  return layer;
}
