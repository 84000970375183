import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useTaskingState,
  useToggleLayer,
} from "../../contexts/TaskingMapContext";
import { layerID, layerNameFromID } from "../../lib/layer_utils";
import { TaskingMapLayerName } from "../../lib/types";
import NoShadowStateButton from "./NoShadowStateButton";

type Props = {
  className?: string;
};

function TogglePlayerLayersButton({ className }: Props) {
  const {
    state: {
      disabledLayers,
      tasking: { plannedGroups },
    },
  } = useTaskingState();

  const toggle = useToggleLayer();

  const handleToggleAll = () => {
    const allChecked = relevantLayers?.length === 0;

    const layers = _.map(plannedGroups, (g) => {
      return layerID(TaskingMapLayerName.PlayerGroups, [g.name]);
    });

    toggle(layers, !allChecked);
  };

  const relevantLayers = _.filter(disabledLayers, (l) => {
    return layerNameFromID(l) === TaskingMapLayerName.PlayerGroups;
  });

  return (
    <Tooltip title="Toggle All Routes">
      <NoShadowStateButton className={className} onClick={handleToggleAll}>
        {relevantLayers.length > 0 ? <LayersIcon /> : <LayersClearIcon />}
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(TogglePlayerLayersButton).attrs({
  className: TogglePlayerLayersButton.name,
})``;
