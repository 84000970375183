import { Theater } from "../map";
import { AirbaseType } from "../types";

export type Airbase = {
  Name: string;
  X: number;
  Y: number;
  Z: number;
  Theater?: string;
  Runways: string;
  UHF: number | string;
  VHF: number | string;
  TACAN: string;
  VOR: string | number;
  ILS: string[];
  Elevation: string;
  "MF/HF": string;
  ICAO: string;
  UnitId?: number;
  // Type: AirbaseType;
  AirdromeId?: number;
  RunwayLength?: number | string;
  Lat?: number;
  Long?: number;
  Type?: AirbaseType;
};

const bases: { [t: string]: { [id: string]: any } } = {
  [Theater.NORMANDY]: {
    "1": {
      Name: "Saint Pierre du Mont",
      X: -11997.431640625,
      Y: 31.479330062866,
      Z: -46557.78515625,
    },
    "2": {
      Name: "Lignerolles",
      X: -35319.11328125,
      Y: 123.42208099365,
      Z: -34889.20703125,
    },
    "3": {
      Name: "Cretteville",
      X: -19148.455078125,
      Y: 28.970609664917,
      Z: -77273.8359375,
    },
    "4": {
      Name: "Maupertus",
      X: 15844.725585938,
      Y: 134.4774017334,
      Z: -84150.796875,
    },
    "5": {
      Name: "Brucheville",
      X: -15041.91015625,
      Y: 13.913147926331,
      Z: -66514.4609375,
    },
    "6": {
      Name: "Meautis",
      X: -24563.125,
      Y: 25.325464248657,
      Z: -72508.1171875,
    },
    "7": {
      Name: "Cricqueville-en-Bessin",
      X: -14390.693359375,
      Y: 24.763647079468,
      Z: -50853.4375,
    },
    "8": {
      Name: "Lessay",
      X: -33566.88671875,
      Y: 20.000019073486,
      Z: -85784.15625,
    },
    "9": {
      Name: "Sainte-Laurent-sur-Mer",
      X: -14429.124023438,
      Y: 17.835691452026,
      Z: -41785.3203125,
    },
    "10": {
      Name: "Biniville",
      X: -7265.7392578125,
      Y: 32.473197937012,
      Z: -84835.0078125,
    },
    "11": {
      Name: "Cardonville",
      X: -17144.2890625,
      Y: 30.951379776001,
      Z: -53703.78125,
    },
    "12": {
      Name: "Deux Jumeaux",
      X: -16994.240234375,
      Y: 37.679191589355,
      Z: -48197.32421875,
    },
    "13": {
      Name: "Chippelle",
      X: -28812.5546875,
      Y: 38.041233062744,
      Z: -48519.23046875,
    },
    "14": {
      Name: "Beuzeville",
      X: -9576.9912109375,
      Y: 34.831809997559,
      Z: -72591.578125,
    },
    "15": {
      Name: "Azeville",
      X: -2686.3544921875,
      Y: 22.806873321533,
      Z: -74181.3984375,
    },
    "16": {
      Name: "Picauville",
      X: -11851.96484375,
      Y: 22.10747718811,
      Z: -80781.453125,
    },
    "17": {
      Name: "Le Molay",
      X: -25702.712890625,
      Y: 31.954740524292,
      Z: -40995.66015625,
    },
    "18": {
      Name: "Longues-sur-Mer",
      X: -16468.4140625,
      Y: 68.611747741699,
      Z: -29311.9453125,
    },
    "19": {
      Name: "Carpiquet",
      X: -35224.9609375,
      Y: 57.000102996826,
      Z: -9368.1083984375,
    },
    "20": {
      Name: "Bazenville",
      X: -21037.55859375,
      Y: 60.873630523682,
      Z: -18979.0703125,
    },
    "21": {
      Name: "Sainte-Croix-sur-Mer",
      X: -18815.318359375,
      Y: 48.796699523926,
      Z: -14521.333007812,
    },
    "22": {
      Name: "Beny-sur-Mer",
      X: -21520.779296875,
      Y: 60.732841491699,
      Z: -8397.8515625,
    },
    "23": {
      Name: "Rucqueville",
      X: -26621.591796875,
      Y: 58.80305480957,
      Z: -18748.99609375,
    },
    "24": {
      Name: "Sommervieu",
      X: -21383.123046875,
      Y: 56.978862762451,
      Z: -26792.662109375,
    },
    "25": {
      Name: "Lantheuil",
      X: -24512.44921875,
      Y: 53.291923522949,
      Z: -16956.955078125,
    },
    "26": {
      Name: "Evreux",
      X: -45534.58984375,
      Y: 129.00012207031,
      Z: 112927.1640625,
    },
    "27": {
      Name: "Chailey",
      X: 164349.671875,
      Y: 29.000028610229,
      Z: 11626.734375,
    },
    "28": {
      Name: "Needs Oar Point",
      X: 140493.015625,
      Y: 6.0000061988831,
      Z: -85719.9765625,
    },
    "29": {
      Name: "Funtington",
      X: 153106.53125,
      Y: 38.000038146973,
      Z: -45889.23828125,
    },
    "30": {
      Name: "Tangmere",
      X: 149796.25,
      Y: 14.517134666443,
      Z: -33471.78515625,
    },
    "31": {
      Name: "Ford",
      X: 148097.921875,
      Y: 8.8593835830688,
      Z: -26195.72265625,
    },
    "32": {
      Name: "Argentan",
      X: -79065.4296875,
      Y: 195.00019836426,
      Z: 23095.654296875,
    },
    "33": {
      Name: "Goulet",
      X: -81589.6875,
      Y: 188.00018310547,
      Z: 16541.515625,
    },
    "34": {
      Name: "Barville",
      X: -110392.796875,
      Y: 141.0001373291,
      Z: 49691.72265625,
    },
    "35": {
      Name: "Essay",
      X: -105601.96875,
      Y: 152.43608093262,
      Z: 45523.95703125,
    },
    "36": {
      Name: "Hauterive",
      X: -107583.2109375,
      Y: 149.32998657227,
      Z: 40573.76953125,
    },
    "37": {
      Name: "Lymington",
      X: 139258.75,
      Y: 6.0000061988831,
      Z: -90410.7734375,
    },
    "38": {
      Name: "Vrigny",
      X: -89071.8046875,
      Y: 180.00018310547,
      Z: 24835.84375,
    },
    "39": {
      Name: "Odiham",
      X: 193002.359375,
      Y: 111.60011291504,
      Z: -51107.12109375,
    },
    "40": {
      Name: "Conches",
      X: -56519.49609375,
      Y: 165.00016784668,
      Z: 95087.1796875,
    },
    "41": {
      Name: "West Malling",
      X: 200894.3125,
      Y: 93.000091552734,
      Z: 42662.09765625,
    },
    "42": {
      Name: "Villacoublay",
      X: -70473.4296875,
      Y: 170.00015258789,
      Z: 187341.640625,
    },
    "43": {
      Name: "Kenley",
      X: 203016.6875,
      Y: 171.00016784668,
      Z: 6592.0600585938,
    },
    "44": {
      Name: "Beauvais-Tille",
      X: 6109.880859375,
      Y: 101.00009155273,
      Z: 174345.8125,
    },
    "45": {
      Name: "Cormeilles-en-Vexin",
      X: -34185.2265625,
      Y: 95.00008392334,
      Z: 172538.96875,
    },
    "46": {
      Name: "Creil",
      X: -15022.7109375,
      Y: 82.00008392334,
      Z: 205003.515625,
    },
    "47": {
      Name: "Guyancourt",
      X: -71430.5625,
      Y: 160.00009155273,
      Z: 177497.109375,
    },
    "48": {
      Name: "Lonrai",
      X: -112353.7265625,
      Y: 157.00016784668,
      Z: 28436.138671875,
    },
    "49": {
      Name: "Dinan-Trelivan",
      X: -118942.5234375,
      Y: 115.00011444092,
      Z: -129568.703125,
    },
    "50": {
      Name: "Heathrow",
      X: 221173.203125,
      Y: 27.000028610229,
      Z: -20096.12890625,
    },
    "51": {
      Name: "Fecamp-Benouville",
      X: 30532.82421875,
      Y: 90.000091552734,
      Z: 46208.15625,
    },
    "52": {
      Name: "Farnborough",
      X: 198911.625,
      Y: 75.000076293945,
      Z: -40367.8046875,
    },
    "53": {
      Name: "Friston",
      X: 143096.859375,
      Y: 94.084091186523,
      Z: 27691.28125,
    },
    "54": {
      Name: "Deanland",
      X: 157193.46875,
      Y: 22.000022888184,
      Z: 27264.55078125,
    },
    "55": {
      Name: "Triqueville",
      X: -14693.797851562,
      Y: 122.55814361572,
      Z: 55842.53125,
    },
    "56": {
      Name: "Poix",
      X: 44984.21484375,
      Y: 166.77716064453,
      Z: 161936.03125,
    },
    "57": {
      Name: "Orly",
      X: -72915.4765625,
      Y: 83.00008392334,
      Z: 200388.4375,
    },
    "58": {
      Name: "Stoney Cross",
      X: 155617.53125,
      Y: 117.10011291504,
      Z: -101963.296875,
    },
    "59": {
      Name: "Amiens-Glisy",
      X: 53802.65234375,
      Y: 58.523963928223,
      Z: 191132.3125,
    },
    "60": {
      Name: "Ronai",
      X: -73490.546875,
      Y: 262.0002746582,
      Z: 13349.24609375,
    },
    "61": {
      Name: "Rouen-Boos",
      X: -5539.1752929688,
      Y: 150.00015258789,
      Z: 108042.7109375,
    },
    "62": {
      Name: "Deauville",
      X: -11855.204101562,
      Y: 140.02659606934,
      Z: 33318.3515625,
    },
    "63": {
      Name: "Saint-Aubin",
      X: 48750.61328125,
      Y: 95.027503967285,
      Z: 97910.1875,
    },
    "64": {
      Name: "Flers",
      X: -82171.296875,
      Y: 198.28337097168,
      Z: -18382.314453125,
    },
    "65": {
      Name: "Avranches Le Val-Saint-Pere",
      X: -92669.3203125,
      Y: 14.258261680603,
      Z: -76678.0859375,
    },
    "66": {
      Name: "Gravesend",
      X: 217765.109375,
      Y: 54.430534362793,
      Z: 40635.1171875,
    },
    "67": {
      Name: "Beaumont-le-Roger",
      X: -40018.515625,
      Y: 148.98263549805,
      Z: 81845,
    },
    "68": {
      Name: "Broglie",
      X: -49977.37890625,
      Y: 181.26385498047,
      Z: 60717.1953125,
    },
    "69": {
      Name: "Bernay Saint Martin",
      X: -39024.96875,
      Y: 159.67486572266,
      Z: 67126.3359375,
    },
    "70": {
      Name: "Saint-Andre-de-lEure",
      X: -61135.62109375,
      Y: 142.88969421387,
      Z: 117901.4921875,
    },
    "71": {
      Name: "Biggin Hill",
      X: 206709.21875,
      Y: 173.00016784668,
      Z: 15977.452148438,
    },
    "72": {
      Name: "Manston",
      X: 211852.875,
      Y: 48.000049591064,
      Z: 108427.2734375,
    },
    "73": {
      Name: "Detling",
      X: 205568.90625,
      Y: 180.80578613281,
      Z: 55873.59375,
    },
    "74": {
      Name: "Lympne",
      X: 181717.59375,
      Y: 61.834384918213,
      Z: 86430.46875,
    },
    "75": {
      Name: "Abbeville Drucat",
      X: 80017.75,
      Y: 66.139770507812,
      Z: 150582.71875,
    },
    "76": {
      Name: "Saint-Omer Wizernes",
      X: 147955.984375,
      Y: 65.000061035156,
      Z: 173364.9375,
    },
    "77": {
      Name: "Merville Calonne",
      X: 137816.65625,
      Y: 40.000038146973,
      Z: 203570.015625,
    },
  },
  [Theater.SYRIA]: {
    "1": {
      Name: "Abu al-Duhur",
      X: 75929.77344,
      Y: 250.0002441,
      Z: 112670.3281,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 9496,
      UHF: 250.35,
      VHF: 122.2,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "820'",
      "MF/HF": "3.950 / 38.80",
      ICAO: "OS57",
    },
    "2": {
      Name: "Adana Sakirpasa",
      X: 220139.0313,
      Y: 17.00001717,
      Z: -47292.88672,
      Theater: "SYRIA",
      Runways: "05,23",
      RunwayLength: 9071,
      UHF: 250.9,
      VHF: 121.1,
      TACAN: "",
      VOR: "112.70 (ADA)",
      ILS: ["05/108.70/056˚"],
      Elevation: "55'",
      "MF/HF": "4.225/39.350",
      ICAO: "LTAF",
    },
    "3": {
      Name: "Al Qusayr",
      X: -52203.73047,
      Y: 527.0005493,
      Z: 61287.85938,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: 9600,
      UHF: 251.25,
      VHF: 119.2,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1729'",
      "MF/HF": "4.400 / 39.70",
      ICAO: "OS70",
    },
    "4": {
      Name: "An Nasiriyah",
      X: -123819.2891,
      Y: 837.1625366,
      Z: 86407.89063,
      Theater: "SYRIA",
      Runways: "04,22",
      RunwayLength: 8318,
      UHF: 251.35,
      VHF: 122.3,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2746'",
      "MF/HF": "4.450 / 39.80",
      ICAO: "OS64",
    },
    "5": {
      Name: "Tha'lah",
      X: -259061.4219,
      Y: 725.7504272,
      Z: 39317.87891,
      Theater: "SYRIA",
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "6": {
      Name: "Beirut-Rafic Hariri",
      X: -130182.1641,
      Y: 12.0000124,
      Z: -42278.93359,
      Theater: "SYRIA",
      Runways: "17,35,03,21,16,34",
      RunwayLength: 10566,
      UHF: 251.4,
      VHF: 118.9,
      TACAN: "",
      VOR: "112.60 (KAD)",
      ILS: ["17/109.50/179˚", "16/110.10/169˚", "03/110.70/035˚"],
      Elevation: "39'",
      "MF/HF": "4.475 / 39.85",
      ICAO: "OLBA",
    },
    "7": {
      Name: "Damascus",
      X: -179748.3281,
      Y: 612.0006104,
      Z: 50728.26563,
      Theater: "SYRIA",
      Runways: "05L,23R,05R,23L",
      RunwayLength: 11903,
      UHF: 251.45,
      VHF: 118.5,
      TACAN: "",
      VOR: "116.0 (DAM)",
      ILS: ["23R/109.90/230˚", "05R/111.10/050˚"],
      Elevation: "2007'",
      "MF/HF": "4.500 / 39.90",
      ICAO: "OSDI",
    },
    "8": {
      Name: "Marj as Sultan South",
      X: -171681.1719,
      Y: 611.9667358,
      Z: 48217.06641,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 681,
      UHF: 251.5,
      VHF: 122.9,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2007'",
      "MF/HF": "4.525 / 39.95",
      ICAO: "-",
    },
    "9": {
      Name: "Al-Dumayr",
      X: -159245.375,
      Y: 630.0006104,
      Z: 72725.04688,
      Theater: "SYRIA",
      Runways: "06,24",
      RunwayLength: 9843,
      UHF: 251.55,
      VHF: 120.3,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2066'",
      "MF/HF": "4.550 / 40.00",
      ICAO: "OS61",
    },
    "10": {
      Name: "Eyn Shemer",
      X: -283415.875,
      Y: 28.57603073,
      Z: -93149.375,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 3826,
      UHF: 250,
      VHF: 123.4,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "93'",
      "MF/HF": "3.750 / 38.40",
      ICAO: "LLES",
    },
    "11": {
      Name: "Gaziantep",
      X: 210716.109375,
      Y: 697.21142578125,
      Z: 146087.90625,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: 0,
      UHF: 250.05,
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "13": {
      Name: "Haifa",
      X: -242139.0156,
      Y: 6.000006676,
      Z: -87825.28906,
      Theater: "SYRIA",
      Runways: "16,34",
      RunwayLength: 3639,
      UHF: 250.05,
      VHF: 127.8,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "19'",
      "MF/HF": "3.775 / 38.45",
      ICAO: "LLHA",
    },
    "14": {
      Name: "Hama",
      X: 8419.59375,
      Y: 299.9153748,
      Z: 75521.71094,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 8719,
      UHF: 250.1,
      VHF: 118.05,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "983'",
      "MF/HF": "3.800 / 38.50",
      ICAO: "OS58",
    },
    "15": {
      Name: "Hatay",
      X: 148653.7656,
      Y: 77.17556763,
      Z: 40403.94531,
      Theater: "SYRIA",
      Runways: "04,22",
      RunwayLength: 9867,
      UHF: 250.15,
      VHF: 128.5,
      TACAN: "",
      VOR: "112.05 (HTY)",
      ILS: ["04/108.90/044˚", "22/108.15/224˚"],
      Elevation: "253'",
      "MF/HF": "3.825 / 38.55",
      ICAO: "LTDA",
    },
    "16": {
      Name: "Incirlik",
      X: 220387.5469,
      Y: 47.56959915,
      Z: -36463.6875,
      Theater: "SYRIA",
      Runways: "05,23",
      RunwayLength: 9928,
      UHF: 360.1,
      VHF: 129.4,
      TACAN: "21X (DAN)",
      VOR: "108.40 (DAN)",
      ILS: ["05/109.30/055˚", "23/111.70/235˚"],
      Elevation: "156'",
      "MF/HF": "3.850 / 38.60",
      ICAO: "LTAG",
    },
    "17": {
      Name: "Jirah",
      X: 115071.7578,
      Y: 356.7168579,
      Z: 188376.1563,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: 9770,
      UHF: 250.2,
      VHF: 118.1,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1170'",
      "MF/HF": "3.875 / 38.65",
      ICAO: "OS62",
    },
    "18": {
      Name: "Khalkhalah",
      X: -218879.4375,
      Y: 712.3335571,
      Z: 54981.88281,
      Theater: "SYRIA",
      Runways: "07,25,15,33",
      RunwayLength: 9855,
      UHF: 250.25,
      VHF: 122.5,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2337'",
      "MF/HF": "3.900 / 38.70",
      ICAO: "OS69",
    },
    "19": {
      Name: "King Hussein Air College",
      X: -297491.9375,
      Y: 672.0006714,
      Z: 25897.08789,
      Theater: "SYRIA",
      Runways: "13,31",
      RunwayLength: 9819,
      UHF: 250.3,
      VHF: 118.3,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2204'",
      "MF/HF": "3.925 / 38.75",
      ICAO: "OJMF",
    },
    "20": {
      Name: "Kiryat Shmona",
      X: -199857.7188,
      Y: 100.0000916,
      Z: -34831.57422,
      Theater: "SYRIA",
      Runways: "03,21",
      RunwayLength: 3737,
      UHF: 250.4,
      VHF: 118.4,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "238'",
      "MF/HF": "3.975 / 38.85",
      ICAO: "LLKS",
    },
    "21": {
      Name: "Bassel Al-Assad",
      X: 42994.66406,
      Y: 28.45734406,
      Z: 5658.36084,
      Theater: "SYRIA",
      Runways: "17,35",
      RunwayLength: 9168,
      UHF: 250.45,
      VHF: 118.1,
      TACAN: "",
      VOR: "114.80 (LTK)",
      ILS: ["17/109.10/179˚"],
      Elevation: "93'",
      "MF/HF": "4.000 / 38.90",
      ICAO: "OSLK",
    },
    "22": {
      Name: "Marj as Sultan North",
      X: -170221.7969,
      Y: 611.7497559,
      Z: 47457.74219,
      Theater: "SYRIA",
      Runways: "08,26",
      RunwayLength: 632,
      UHF: 250.5,
      VHF: 122.7,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2007'",
      "MF/HF": "4.025 / 38.95",
      ICAO: "-",
    },
    "23": {
      Name: "Marj Ruhayyil",
      X: -194683.9063,
      Y: 658.5941772,
      Z: 45031.90625,
      Theater: "SYRIA",
      Runways: "06,24",
      RunwayLength: 9770,
      UHF: 250.55,
      VHF: 120.8,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2160'",
      "MF/HF": "4.050 / 39.00",
      ICAO: "OS63",
    },
    "24": {
      Name: "Megiddo",
      X: -266879.7188,
      Y: 55.00004959,
      Z: -71994.76563,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 6471,
      UHF: 250.6,
      VHF: 119.9,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "180'",
      "MF/HF": "4.075 / 39.05",
      ICAO: "LLMG",
    },
    "25": {
      Name: "Mezzeh",
      X: -171641.8438,
      Y: 718.0916748,
      Z: 25888.5332,
      Theater: "SYRIA",
      Runways: "06,24",
      RunwayLength: 9661,
      UHF: 250.65,
      VHF: 120.7,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2355'",
      "MF/HF": "4.100 / 39.10",
      ICAO: "OS67",
    },
    "26": {
      Name: "Minakh",
      X: 163870.9219,
      Y: 492.0004578,
      Z: 106743.0313,
      Theater: "SYRIA",
      Runways: "10,28,04,22",
      RunwayLength: 4648,
      UHF: 250.7,
      VHF: 120.6,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1614'",
      "MF/HF": "4.125 / 39.15",
      ICAO: "OS71",
    },
    "27": {
      Name: "Aleppo",
      X: 125776.7031,
      Y: 382.19104,
      Z: 121870.9531,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 9496,
      UHF: 250.75,
      VHF: 119.1,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1253'",
      "MF/HF": "4.150 / 39.20",
      ICAO: "OSAP",
    },
    "28": {
      Name: "Palmyra",
      X: -55607.97266,
      Y: 386.2240295,
      Z: 221438.1563,
      Theater: "SYRIA",
      Runways: "08,26",
      RunwayLength: 9485,
      UHF: 250.8,
      VHF: 121.9,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1267'",
      "MF/HF": "4.175 / 39.25",
      ICAO: "OSPR",
    },
    "29": {
      Name: "Qabr as Sitt",
      X: -174610.6719,
      Y: 650.5708008,
      Z: 37142.67578,
      Theater: "SYRIA",
      Runways: "06,24",
      RunwayLength: 1003,
      UHF: 250.85,
      VHF: 122.6,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2134'",
      "MF/HF": "4.200 / 39.30",
      ICAO: "-",
    },
    "30": {
      Name: "Ramat David",
      X: -259097.3438,
      Y: 32.23926926,
      Z: -76862.32031,
      Theater: "SYRIA",
      Runways: "14,32,09,27,11,29",
      RunwayLength: 8008,
      UHF: 251.05,
      VHF: 118.6,
      TACAN: "84X",
      VOR: "113.70",
      ILS: [""],
      Elevation: "105'",
      "MF/HF": "4.250 / 39.40",
      ICAO: "LLRD",
    },
    "31": {
      Name: "Kuweires",
      X: 126034.1172,
      Y: 366.0003662,
      Z: 154262.7656,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: 7838,
      UHF: 251,
      VHF: 120.5,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1200'",
      "MF/HF": "4.275 / 39.45",
      ICAO: "OS66",
    },
    "32": {
      Name: "Rayak",
      X: -131016.3047,
      Y: 894.3814697,
      Z: 3064.66748,
      Theater: "SYRIA",
      Runways: "04,22",
      RunwayLength: 9600,
      UHF: 251.1,
      VHF: 124.4,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "2934'",
      "MF/HF": "4.300 / 39.50",
      ICAO: "OLRA",
    },
    "33": {
      Name: "Rene Mouawad",
      X: -48864.37109,
      Y: 4.410843372,
      Z: 7502.123535,
      Theater: "SYRIA",
      Runways: "06,24",
      RunwayLength: 9332,
      UHF: 251.1,
      VHF: 119.5,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "14'",
      "MF/HF": "4.325 / 39.55",
      ICAO: "OLKA",
    },
    "37": {
      Name: "Tabqa",
      X: 77069.83594,
      Y: 330.3158875,
      Z: 242231.7656,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 9509,
      UHF: 251.15,
      VHF: 118.5,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1083'",
      "MF/HF": "4.350 / 39.60",
      ICAO: "OS59",
    },
    "38": {
      Name: "Taftanaz",
      X: 103379.0234,
      Y: 311.0003052,
      Z: 83165.92969,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: 3366,
      UHF: 251.2,
      VHF: 112.8,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1020'",
      "MF/HF": "4.375 / 39.65",
      ICAO: "-",
    },
    "40": {
      Name: "Wujah Al Hajar",
      X: -80912.17188,
      Y: 188.6738739,
      Z: -22454.4668,
      Theater: "SYRIA",
      Runways: "02,20",
      RunwayLength: 5045,
      UHF: 251.3,
      VHF: 121.5,
      TACAN: "",
      VOR: "116.20 (CAK)",
      ILS: [""],
      Elevation: "619'",
      "MF/HF": "4.425 / 39.75",
      ICAO: "Z19O",
    },
    "41": {
      Name: "Gazipasa",
      X: 158094.9375,
      Y: 11.10494041,
      Z: -320441.0625,
      Theater: "SYRIA",
      Runways: "08,26",
      RunwayLength: "6885'",
      UHF: 251.65,
      VHF: 119.25,
      TACAN: "",
      VOR: "",
      ILS: ["08/108.50"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "43": {
      Name: "Nicosia",
      X: 24923.6543,
      Y: 218.4187775,
      Z: -238861.7188,
      Theater: "SYRIA",
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "44": {
      Name: "Akrotiri",
      X: -35297.09375,
      Y: 18.946064,
      Z: -270072.2813,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: "8276'",
      UHF: 251.7,
      VHF: 128.0,
      TACAN: "107X",
      VOR: "",
      ILS: ["28/109.70"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "45": {
      Name: "Kingsfield",
      X: 7776.000488,
      Y: 82.47331238,
      Z: -198993.4063,
      Theater: "SYRIA",
      Runways: "06,24",
      RunwayLength: "3069'",
      UHF: 251.75,
      VHF: 121,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "46": {
      Name: "Paphos",
      X: -18147.3125,
      Y: 12.30508232,
      Z: -315369.5,
      Theater: "SYRIA",
      Runways: "11,29",
      RunwayLength: "8425'",
      UHF: 251.8,
      VHF: 119.9,
      TACAN: "79X",
      VOR: "117.90",
      ILS: ["29/108.90"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "47": {
      Name: "Larnaca",
      X: -8466.051758,
      Y: 5.000004768,
      Z: -209773.4688,
      Theater: "SYRIA",
      Runways: "04,22",
      RunwayLength: "8009'",
      UHF: 251.85,
      VHF: 121.2,
      TACAN: "",
      VOR: "112.80",
      ILS: ["22/110.30"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "48": {
      Name: "Lakatamia",
      X: 19750.61133,
      Y: 231.0002441,
      Z: -234973.5156,
      Theater: "SYRIA",
      Runways: "17,35",
      RunwayLength: "1230'",
      UHF: 251.9,
      VHF: 120.2,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "49": {
      Name: "Ercan",
      X: 24764.47656,
      Y: 117.0001144,
      Z: -219271.6875,
      Theater: "SYRIA",
      Runways: "11,29",
      RunwayLength: "7559'",
      UHF: 251.95,
      VHF: 120.2,
      TACAN: "",
      VOR: "117.00",
      ILS: ["29/108.30"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "50": {
      Name: "Gecitkale",
      X: 32254.64063,
      Y: 45.00004578,
      Z: -199005.9844,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: "8156'",
      UHF: 252.05,
      VHF: 120.0,
      TACAN: "",
      VOR: "114.30",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "51": {
      Name: "Pinarbashi",
      X: 39146.83203,
      Y: 235.0002289,
      Z: -238857.2344,
      Theater: "SYRIA",
      Runways: "16,34",
      RunwayLength: "3364'",
      UHF: 252.1,
      VHF: 121,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "52": {
      Name: "Naqoura",
      X: -209938.1875,
      Y: 115.2789459,
      Z: -78642.60938,
      Theater: "SYRIA",
      Runways: "",
      RunwayLength: "",
      UHF: 252.15,
      VHF: 122.0,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "12": {
      Name: "H4",
      X: -279644.28125,
      Y: 688.00067138672,
      Z: 208278.046875,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: "7179'",
      UHF: 250.1,
      VHF: 122.6,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
  },
  [Theater.PG]: {
    "1": {
      Name: "Abu Musa Island",
      X: -31593.10547,
      Y: 5.000004768,
      Z: -122493.0547,
      Theater: "PG",
      Runways: "13L,31R,13R,31L",
      RunwayLength: "",
      UHF: 250.5,
      VHF: 119.2,
      TACAN: "",
      VOR: 114.25,
      ILS: [""],
      Elevation: "55'",
      "MF/HF": "4.225/39.350",
      ICAO: "LTAF",
    },
    "2": {
      Name: "Bandar Abbas Intl",
      X: 114214.0547,
      Y: 5.587854385,
      Z: 13397.94141,
      Theater: "PG",
      Runways: "03L,21R,03R,21L",
      RunwayLength: "",
      UHF: 251,
      VHF: 118.1,
      TACAN: "78X",
      VOR: 117.2,
      ILS: ["21L/109.90"],
      Elevation: "39'",
      "MF/HF": "4.475 / 39.85",
      ICAO: "OLBA",
    },
    "3": {
      Name: "Bandar Lengeh",
      X: 41395.73828,
      Y: 24.6532383,
      Z: -142141.3125,
      Theater: "PG",
      Runways: "08,26",
      RunwayLength: "",
      UHF: 251.05,
      VHF: 121.7,
      TACAN: "",
      VOR: 114.8,
      ILS: [""],
      Elevation: "2007'",
      "MF/HF": "4.500 / 39.90",
      ICAO: "OSDI",
    },
    "4": {
      Name: "Al Dhafra AFB",
      X: -209935.4844,
      Y: 16.00001526,
      Z: -174592.0625,
      Theater: "PG",
      Runways: "13L,31R,13R,31L",
      RunwayLength: "",
      UHF: 251.1,
      VHF: 126.5,
      TACAN: "96X",
      VOR: 114.9,
      ILS: ["13L/111.10˚", "31R/109.10˚", "13R/108.70˚", "31L/108.70˚"],
      Elevation: "2066'",
      "MF/HF": "4.550 / 40.00",
      ICAO: "OS61",
    },
    "5": {
      Name: "Dubai Intl",
      X: -101509.8438,
      Y: 5.000004768,
      Z: -87472.57813,
      Theater: "PG",
      Runways: "12L,30R,12R,30L",
      RunwayLength: "",
      UHF: 251.05,
      VHF: 118.75,
      TACAN: "",
      VOR: "",
      ILS: ["30R/110.90˚", "30L/111.30˚", "12L/110.10˚", "12R/109.50"],
      Elevation: "19'",
      "MF/HF": "3.775 / 38.45",
      ICAO: "LLHA",
    },
    "6": {
      Name: "Al Maktoum Intl",
      X: -141081.625,
      Y: 37.61849976,
      Z: -108598.2422,
      Theater: "PG",
      Runways: "12,30",
      RunwayLength: "",
      UHF: 251.2,
      VHF: 118.6,
      TACAN: "",
      VOR: "",
      ILS: ["30/109.75/302˚", "12/111.75/122˚"],
      Elevation: "1253'",
      "MF/HF": "4.150 / 39.20",
      ICAO: "OSAP",
    },
    "7": {
      Name: "Fujairah Intl",
      X: -118114.7813,
      Y: 18.52036858,
      Z: 9254.498047,
      Theater: "PG",
      Runways: "11,29",
      RunwayLength: "",
      UHF: 251.25,
      VHF: 124.6,
      TACAN: "",
      VOR: 113.8,
      ILS: ["29/111.10/293˚"],
      Elevation: "983'",
      "MF/HF": "3.800 / 38.50",
      ICAO: "OS58",
    },
    "8": {
      Name: "Tunb Island AFB",
      X: 9707.209961,
      Y: 13.0000124,
      Z: -92900.58594,
      Theater: "PG",
      Runways: "03,21",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "105'",
      "MF/HF": "4.250 / 39.40",
      ICAO: "LLRD",
    },
    "9": {
      Name: "Havadarya",
      X: 109479.2813,
      Y: 15.45833778,
      Z: -5180.572754,
      Theater: "PG",
      Runways: "08,26",
      RunwayLength: "",
      UHF: 251.3,
      VHF: 123.15,
      TACAN: "47X",
      VOR: "",
      ILS: ["08/108.90/079˚"],
      Elevation: "253'",
      "MF/HF": "3.825 / 38.55",
      ICAO: "LTDA",
    },
    "10": {
      Name: "Khasab",
      X: 883.8808594,
      Y: 14.49072838,
      Z: 128.6996765,
      Theater: "PG",
      Runways: "01,19",
      RunwayLength: "",
      UHF: 250,
      VHF: 124.35,
      TACAN: "",
      VOR: "",
      ILS: ["19/110.30/194˚"],
      Elevation: "2337'",
      "MF/HF": "3.900 / 38.70",
      ICAO: "OS69",
    },
    "11": {
      Name: "Lar",
      X: 169016.75,
      Y: 803.3289185,
      Z: -183822.5781,
      Theater: "PG",
      Runways: "09,27",
      RunwayLength: "",
      UHF: 250.05,
      VHF: 127.35,
      TACAN: "",
      VOR: 117.9,
      ILS: [""],
      Elevation: "238'",
      "MF/HF": "3.975 / 38.85",
      ICAO: "LLKS",
    },
    "12": {
      Name: "Al Minhad AFB",
      X: -126043.7813,
      Y: 58.14745712,
      Z: -87325.16406,
      Theater: "PG",
      Runways: "09,27",
      RunwayLength: "",
      UHF: 250.1,
      VHF: 118.55,
      TACAN: "99X",
      VOR: "",
      ILS: ["27/110.75/270˚", "09/110.70/090˚"],
      Elevation: "2746'",
      "MF/HF": "4.450 / 39.80",
      ICAO: "OS64",
    },
    "13": {
      Name: "Qeshm Island",
      X: 66079.17188,
      Y: 8.028849602,
      Z: -31812.67969,
      Theater: "PG",
      Runways: "05,23",
      RunwayLength: "",
      UHF: 250.15,
      VHF: 118.05,
      TACAN: "",
      VOR: 117.1,
      ILS: [""],
      Elevation: "2007'",
      "MF/HF": "4.525 / 39.95",
      ICAO: "-",
    },
    "14": {
      Name: "Sharjah Intl",
      X: -93431.32031,
      Y: 29.99998283,
      Z: -72062,
      Theater: "PG",
      Runways: "12L,30R,12R,30L",
      RunwayLength: "",
      UHF: 250.2,
      VHF: 118.6,
      TACAN: "",
      VOR: 117.8,
      ILS: ["30R/111.95/303", "˚12L/108.55/123˚"],
      Elevation: "2355'",
      "MF/HF": "4.100 / 39.10",
      ICAO: "OS67",
    },
    "15": {
      Name: "Sirri Island",
      X: -27656.47461,
      Y: 5.44174099,
      Z: -169860.0781,
      Theater: "PG",
      Runways: "12,30",
      RunwayLength: "",
      UHF: 250.25,
      VHF: 135.05,
      TACAN: "",
      VOR: 113.75,
      ILS: [""],
      Elevation: "2134'",
      "MF/HF": "4.200 / 39.30",
      ICAO: "-",
    },
    "16": {
      Name: "Tunb Kochak",
      X: 9049.399414,
      Y: 4.642024517,
      Z: -109088.5781,
      Theater: "PG",
      Runways: "08,26",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "89X",
      VOR: "",
      ILS: [""],
      Elevation: "2934'",
      "MF/HF": "4.300 / 39.50",
      ICAO: "OLRA",
    },
    "17": {
      Name: "Sir Abu Nuayr",
      X: -103189.0469,
      Y: 7.682799816,
      Z: -202643.0313,
      Theater: "PG",
      Runways: "10,28",
      RunwayLength: "",
      UHF: 250.8,
      VHF: 118,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1267'",
      "MF/HF": "4.175 / 39.25",
      ICAO: "OSPR",
    },
    "18": {
      Name: "Kerman",
      X: 452408.4063,
      Y: 1751.425659,
      Z: 71741.58594,
      Theater: "PG",
      Runways: "16,34",
      RunwayLength: "",
      UHF: 250.3,
      VHF: 118.25,
      TACAN: "97X",
      VOR: 112,
      ILS: [""],
      Elevation: "1170'",
      "MF/HF": "3.875 / 38.65",
      ICAO: "OS62",
    },
    "19": {
      Name: "Shiraz Intl",
      X: 380165.5313,
      Y: 1487.001465,
      Z: -349843.0313,
      Theater: "PG",
      Runways: "11L,29R,11R,29L",
      RunwayLength: "",
      UHF: 250.35,
      VHF: 121.9,
      TACAN: "94X",
      VOR: "",
      ILS: [""],
      Elevation: "1614'",
      "MF/HF": "4.125 / 39.15",
      ICAO: "OS71",
    },
    "20": {
      Name: "Sas Al Nakheel",
      X: -188819.25,
      Y: 2.945518017,
      Z: -176195.9844,
      Theater: "PG",
      Runways: "16,34",
      RunwayLength: "",
      UHF: 250.45,
      VHF: 128.9,
      TACAN: "",
      VOR: 128.93,
      ILS: [""],
      Elevation: "180'",
      "MF/HF": "4.075 / 39.05",
      ICAO: "LLMG",
    },
    "21": {
      Name: "Bandar-e-Jask",
      X: -57716.9375,
      Y: 7.950510025,
      Z: 155264.7813,
      Theater: "PG",
      Runways: "06,24",
      RunwayLength: "",
      UHF: 250.5,
      VHF: 118.15,
      TACAN: "110X",
      VOR: 116.3,
      ILS: [""],
      Elevation: "93'",
      "MF/HF": "3.750 / 38.40",
      ICAO: "LLES",
    },
    "22": {
      Name: "Abu Dhabi Intl",
      X: -187211.25,
      Y: 28.00002861,
      Z: -163535.5156,
      Theater: "PG",
      Runways: "08,26",
      RunwayLength: "",
      UHF: 250.4,
      VHF: 122.9,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "820'",
      "MF/HF": "3.950 / 38.80",
      ICAO: "OS57",
    },
    "23": {
      Name: "Al-Bateen",
      X: -190269.1094,
      Y: 3.650626898,
      Z: -182714.7969,
      Theater: "PG",
      Runways: "13,31",
      RunwayLength: "",
      UHF: 250.6,
      VHF: 119.9,
      TACAN: "",
      VOR: 114,
      ILS: [""],
      Elevation: "93'",
      "MF/HF": "4.000 / 38.90",
      ICAO: "OSLK",
    },
    "24": {
      Name: "Kish Intl",
      X: 42988.85938,
      Y: 35.00003433,
      Z: -226699.4063,
      Theater: "PG",
      Runways: "10L,28R,10R,28L",
      RunwayLength: "",
      UHF: 250.65,
      VHF: 121.65,
      TACAN: "112X",
      VOR: "",
      ILS: [""],
      Elevation: "2204'",
      "MF/HF": "3.925 / 38.75",
      ICAO: "OJMF",
    },
    "25": {
      Name: "Al Ain Intl",
      X: -209361.9688,
      Y: 248.04953,
      Z: -65151.40625,
      Theater: "PG",
      Runways: "01,19",
      RunwayLength: "",
      UHF: 250.7,
      VHF: 119.85,
      TACAN: "",
      VOR: 112.6,
      ILS: [""],
      Elevation: "1729'",
      "MF/HF": "4.400 / 39.70",
      ICAO: "OS70",
    },
    "26": {
      Name: "Lavan Island",
      X: 76294.11719,
      Y: 22.91065216,
      Z: -287950.9375,
      Theater: "PG",
      Runways: "11,29",
      RunwayLength: "",
      UHF: 250.75,
      VHF: 128.55,
      TACAN: "",
      VOR: 116.85,
      ILS: [""],
      Elevation: "1200'",
      "MF/HF": "4.275 / 39.45",
      ICAO: "OS66",
    },
    "27": {
      Name: "Jiroft",
      X: 283496.7188,
      Y: 812.0008545,
      Z: 140608.3125,
      Theater: "PG",
      Runways: "13,31",
      RunwayLength: "",
      UHF: 250.85,
      VHF: 136,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "156'",
      "MF/HF": "3.850 / 38.60",
      ICAO: "LTAG",
    },
    "28": {
      Name: "Ras Al Khaimah Intl",
      X: -62870.88281,
      Y: 21.58902168,
      Z: -30497.56641,
      Theater: "PG",
      Runways: "17,35",
      RunwayLength: "",
      UHF: 250.9,
      VHF: 121.6,
      TACAN: "",
      VOR: 113.6,
      ILS: [""],
      Elevation: "2160'",
      "MF/HF": "4.050 / 39.00",
      ICAO: "OS63",
    },
    "29": {
      Name: "Liwa AFB",
      X: -274638.75,
      Y: 121.9301147,
      Z: -249407.125,
      Theater: "PG",
      Runways: "13,31",
      RunwayLength: "",
      UHF: 250.95,
      VHF: 119.3,
      TACAN: "121X",
      VOR: 117.4,
      ILS: [""],
      Elevation: "2007'",
      "MF/HF": "4.025 / 38.95",
      ICAO: "-",
    },
    "33": {
      Name: "Rene Mouawad",
      X: -48864.37109,
      Y: 4.410843372,
      Z: 7502.123535,
      Theater: "SYRIA",
      Runways: "06,24",
      RunwayLength: 9332,
      UHF: 251.1,
      VHF: 119.5,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "14'",
      "MF/HF": "4.325 / 39.55",
      ICAO: "OLKA",
    },
    "37": {
      Name: "Tabqa",
      X: 77069.83594,
      Y: 330.3158875,
      Z: 242231.7656,
      Theater: "SYRIA",
      Runways: "09,27",
      RunwayLength: 9509,
      UHF: 251.15,
      VHF: 118.5,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1083'",
      "MF/HF": "4.350 / 39.60",
      ICAO: "OS59",
    },
    "38": {
      Name: "Taftanaz",
      X: 103379.0234,
      Y: 311.0003052,
      Z: 83165.92969,
      Theater: "SYRIA",
      Runways: "10,28",
      RunwayLength: 3366,
      UHF: 251.2,
      VHF: 112.8,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "1020'",
      "MF/HF": "4.375 / 39.65",
      ICAO: "-",
    },
    "40": {
      Name: "Wujah Al Hajar",
      X: -80912.17188,
      Y: 188.6738739,
      Z: -22454.4668,
      Theater: "SYRIA",
      Runways: "02,20",
      RunwayLength: 5045,
      UHF: 251.3,
      VHF: 121.5,
      TACAN: "",
      VOR: "116.20 (CAK)",
      ILS: [""],
      Elevation: "619'",
      "MF/HF": "4.425 / 39.75",
      ICAO: "Z19O",
    },
  },
  [Theater.CAUCASUS]: {
    "12": {
      Name: "Anapa-Vityazevo",
      X: -4447.790527,
      Y: 43.00004196,
      Z: 244021.9844,
      Theater: "",
      Runways: "04,22",
      RunwayLength: "(8202')",
      UHF: 250,
      VHF: 121,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "13": {
      Name: "Krasnodar-Center",
      X: 11652.91309,
      Y: 30.01003265,
      Z: 366766.3125,
      Theater: "",
      Runways: "09,27",
      RunwayLength: "(8200')",
      UHF: 251,
      VHF: 122,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "14": {
      Name: "Novorossiysk",
      X: -40299.02344,
      Y: 40.01004028,
      Z: 279853.5,
      Theater: "",
      Runways: "04,22",
      RunwayLength: "(5905')",
      UHF: 252,
      VHF: 123,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "15": {
      Name: "Krymsk",
      X: -7349.032715,
      Y: 20.0103035,
      Z: 293711.9688,
      Theater: "",
      Runways: "04,22",
      RunwayLength: "(8530')",
      UHF: 253,
      VHF: 124,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "16": {
      Name: "Maykop-Khanskaya",
      X: -27625.94336,
      Y: 180.0101929,
      Z: 457047.6875,
      Theater: "",
      Runways: "04,22",
      RunwayLength: "(10495')",
      UHF: 254,
      VHF: 125,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "17": {
      Name: "Gelendzhik",
      X: -50995.63281,
      Y: 22.00992393,
      Z: 297848.8438,
      Theater: "",
      Runways: "01,19",
      RunwayLength: "(8050')",
      UHF: 255,
      VHF: 126,
      TACAN: "",
      VOR: "",
      ILS: ["01/110.10/007˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "18": {
      Name: "Sochi-Adler",
      X: -165163.2813,
      Y: 30.01003456,
      Z: 460901.5313,
      Theater: "",
      Runways: "06,24",
      RunwayLength: "(10170')",
      UHF: 256,
      VHF: 127,
      TACAN: "",
      VOR: "",
      ILS: ["06/111.10/056˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "19": {
      Name: "Krasnodar-Pashkovsky",
      X: 8706.819336,
      Y: 34.01003647,
      Z: 388985.7188,
      Theater: "",
      Runways: "05R,23",
      RunwayLength: "(10170')",
      UHF: 257,
      VHF: 128,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "20": {
      Name: "Sukhumi-Babushara",
      X: -221381.6875,
      Y: 13.3385067,
      Z: 565908.75,
      Theater: "",
      Runways: "12,30",
      RunwayLength: "(11610')",
      UHF: 258,
      VHF: 129,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "21": {
      Name: "Gudauta",
      X: -195650.5156,
      Y: 21.01003075,
      Z: 515898.75,
      Theater: "",
      Runways: "15,33",
      RunwayLength: "(8200')",
      UHF: 259,
      VHF: 130,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "22": {
      Name: "Batumi",
      X: -356437.1563,
      Y: 10.04403782,
      Z: 618210.875,
      Theater: "",
      Runways: "13,31",
      RunwayLength: "(8050')",
      UHF: 260,
      VHF: 131,
      TACAN: "16X",
      VOR: "",
      ILS: ["13/110.30/126˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "23": {
      Name: "Senaki-Kolkhi",
      X: -281903.125,
      Y: 13.23994255,
      Z: 648379.125,
      Theater: "",
      Runways: "09,27",
      RunwayLength: "(7790')",
      UHF: 261,
      VHF: 132,
      TACAN: "31X",
      VOR: "",
      ILS: ["09/108.90/088˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "24": {
      Name: "Kobuleti",
      X: -317604.9063,
      Y: 18.0100174,
      Z: 636704.125,
      Theater: "",
      Runways: "07,25",
      RunwayLength: "(7870')",
      UHF: 262,
      VHF: 133,
      TACAN: "67X",
      VOR: "",
      ILS: ["07/111.50/064˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "25": {
      Name: "Kutaisi",
      X: -284582.8125,
      Y: 45.01004791,
      Z: 685029.625,
      Theater: "",
      Runways: "08,26",
      RunwayLength: "(8202')",
      UHF: 263,
      VHF: 134,
      TACAN: "44X",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "26": {
      Name: "Mineralnye Vody",
      X: -52089.54688,
      Y: 320.0103149,
      Z: 707418.0625,
      Theater: "",
      Runways: "12,30",
      RunwayLength: "(13350')",
      UHF: 264,
      VHF: 135,
      TACAN: "",
      VOR: "",
      ILS: ["12/111.70/109˚", "30/109.30/289˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "27": {
      Name: "Nalchik",
      X: -125499.5391,
      Y: 430.010437,
      Z: 759543.375,
      Theater: "",
      Runways: "06,24",
      RunwayLength: "(7545')",
      UHF: 265,
      VHF: 136,
      TACAN: "",
      VOR: "",
      ILS: ["24/110.50/229˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "28": {
      Name: "Mozdok",
      X: -83329.78906,
      Y: 154.6118469,
      Z: 835634.6875,
      Theater: "",
      Runways: "08,26",
      RunwayLength: "(10325')",
      UHF: 266,
      VHF: 137,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "29": {
      Name: "Tbilisi-Lochini",
      X: -314926.25,
      Y: 479.6948242,
      Z: 895724,
      Theater: "",
      Runways: "13,31",
      RunwayLength: "(8120')",
      UHF: 267,
      VHF: 138,
      TACAN: "25X",
      VOR: "",
      ILS: ["13/110.30/126˚", "31/108.90/306˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "30": {
      Name: "Soganlug",
      X: -318656.9688,
      Y: 449.4102478,
      Z: 896274.9375,
      Theater: "",
      Runways: "14,32",
      RunwayLength: "(8120')",
      UHF: 268,
      VHF: 139,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "31": {
      Name: "Vaziani",
      X: -318191.7188,
      Y: 464.5004578,
      Z: 902331.75,
      Theater: "",
      Runways: "13,31",
      RunwayLength: "(8150')",
      UHF: 269,
      VHF: 140,
      TACAN: "22X",
      VOR: "",
      ILS: ["13/108.75/129˚", "31/108.75/309˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "32": {
      Name: "Beslan",
      X: -148472.4063,
      Y: 524.0057983,
      Z: 842251.75,
      Theater: "",
      Runways: "10,28",
      RunwayLength: "(9890')",
      UHF: 270,
      VHF: 141,
      TACAN: "",
      VOR: "",
      ILS: ["10/110.50/087˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
  },
  [Theater.NEVADA]: {
    "1": {
      Name: "Creech AFB",
      X: -360452.1563,
      Y: 952.9445801,
      Z: -76518.58594,
      Lat: 36.58442988,
      Long: -115.6868106,
      Runways: "8,26",
      RunwayLength: "6100'",
      UHF: 360.6,
      VHF: 118.3,
      TACAN: "87X",
      VOR: "",
      ILS: ["08/108.70/078˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "2": {
      Name: "Groom Lake AFB",
      X: -290154.4063,
      Y: 1369.867676,
      Z: -86227.29688,
      Lat: 37.21913894,
      Long: -115.7853107,
      Runways: "14,32",
      RunwayLength: "11008'",
      UHF: 250.05,
      VHF: 118,
      TACAN: "18X",
      VOR: "",
      ILS: ["32/109.30/323˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "3": {
      Name: "McCarran International Airport",
      X: -416012.5313,
      Y: 661.3760986,
      Z: -28511.07227,
      Lat: 36.07667007,
      Long: -115.1621901,
      Runways: "7,25",
      RunwayLength: "10377'",
      UHF: 257.8,
      VHF: 119.9,
      TACAN: "116X",
      VOR: "",
      ILS: ["25/110.30/255˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "4": {
      Name: "Nellis AFB",
      X: -399275.8438,
      Y: 561.3091431,
      Z: -18183.10938,
      Lat: 36.22566202,
      Long: -115.0438076,
      Runways: "03,21",
      RunwayLength: "9437'",
      UHF: 327,
      VHF: 132.5,
      TACAN: "12X",
      VOR: "",
      ILS: ["3R/109.10/029˚", "21L/109.10/210˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "5": {
      Name: "Beatty Airport",
      X: -329733.625,
      Y: 967.133667,
      Z: -174930.5625,
      Lat: 36.8683943,
      Long: -116.7860831,
      Runways: "16,34",
      RunwayLength: "5380'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "6": {
      Name: "Boulder City Airport",
      X: -430210.6875,
      Y: 646.6636353,
      Z: -1022.358459,
      Lat: 35.94368164,
      Long: -114.8605518,
      Runways: "15,33",
      RunwayLength: "3702'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "7": {
      Name: "Echo Bay",
      X: -388670.5,
      Y: 472.0336914,
      Z: 33210.67188,
      Lat: 36.31047139,
      Long: -114.4693376,
      Runways: "6,24",
      RunwayLength: "3228'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "8": {
      Name: "Henderson Executive Airport",
      X: -428077.3438,
      Y: 759.4368896,
      Z: -25692.61914,
      Lat: 35.96746879,
      Long: -115.1334711,
      Runways: "17,35",
      RunwayLength: "4753'",
      UHF: 250.1,
      VHF: 125.1,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "9": {
      Name: "Jean Airport",
      X: -449876.7813,
      Y: 860.8758545,
      Z: -42659.71875,
      Lat: 35.77378555,
      Long: -115.3257027,
      Runways: "2,20",
      RunwayLength: "4053'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "10": {
      Name: "Laughlin Airport",
      X: -515859.9063,
      Y: 200.0001984,
      Z: 28242.04688,
      Lat: 35.16590527,
      Long: -114.5598643,
      Runways: "16,34",
      RunwayLength: "7139'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "11": {
      Name: "Lincoln County",
      X: -223999.0781,
      Y: 1467.689941,
      Z: 33240.82031,
      Lat: 37.79338959,
      Long: -114.419237,
      Runways: "17,35",
      RunwayLength: "4408'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "13": {
      Name: "Mesquite",
      X: -330275,
      Y: 566.4873657,
      Z: 68185.86719,
      Lat: 36.82734525,
      Long: -114.0603351,
      Runways: "1,19",
      RunwayLength: "4937'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "14": {
      Name: "Mina Airport 3Q0",
      X: -162089.0781,
      Y: 1390.512207,
      Z: -289514.125,
      Lat: 38.37454685,
      Long: -118.0934993,
      Runways: "13,31",
      RunwayLength: "4222'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "15": {
      Name: "North Las Vegas",
      X: -400889.9063,
      Y: 679.2798462,
      Z: -31004.8418,
      Lat: 36.21336457,
      Long: -115.1867376,
      Runways: "7,25",
      RunwayLength: "4734'",
      UHF: 360.75,
      VHF: 125.7,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "16": {
      Name: "Pahute Mesa Airstrip",
      X: -304416.4375,
      Y: 1541.363525,
      Z: -133159.0938,
      Lat: 37.09483135,
      Long: -116.3153996,
      Runways: "18,36",
      RunwayLength: "5420'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "17": {
      Name: "Tonopah Airport",
      X: -197772.3594,
      Y: 1644.139648,
      Z: -200656.875,
      Lat: 38.05802445,
      Long: -117.0759171,
      Runways: "11,29",
      RunwayLength: "5314'",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "18": {
      Name: "Tonopah Test Range Airfield",
      X: -228149.6719,
      Y: 1686.814331,
      Z: -174035.1719,
      Lat: 37.78403952,
      Long: -116.7733029,
      Runways: "14,32",
      RunwayLength: "11633'",
      UHF: 257.95,
      VHF: 124.75,
      TACAN: "77X",
      VOR: "",
      ILS: ["14/108.30/142˚", "32/111.70/321˚"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
  },
  [Theater.MARIANAS]: {
    "1": {
      Name: "Rota Intl",
      X: 75950.77344,
      Y: 173.3801727,
      Z: 47661.18359,
      Lat: 14.1749051,
      Long: 145.2324856,
      Runways: "09,27",
      RunwayLength: "6105'",
      UHF: 250.0,
      VHF: 123.6,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "2": {
      Name: "Saipan Intl",
      X: 179620.4531,
      Y: 65.00006866,
      Z: 100743.2344,
      Lat: 15.11512163,
      Long: 145.7187983,
      Runways: "07,25",
      RunwayLength: "7790'",
      UHF: 256.9,
      VHF: 125.7,
      TACAN: "",
      VOR: "",
      ILS: ["07/109.90"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "3": {
      Name: "Tinian Intl",
      X: 166672,
      Y: 73.15426636,
      Z: 88785.89063,
      Lat: 14.99742938,
      Long: 145.6083061,
      Runways: "08,26",
      RunwayLength: "7777'",
      UHF: 250.05,
      VHF: 123.65,
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "4": {
      Name: "Antonio B. Won Pat Intl",
      X: -588.5232544,
      Y: 77.79207611,
      Z: -1388.050415,
      Lat: 13.479569,
      Long: 144.78477,
      Runways: "06,24",
      RunwayLength: "9359'",
      UHF: 340.2,
      VHF: 118.1,
      TACAN: "",
      VOR: "",
      ILS: ["06/110.30"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "5": {
      Name: "Olf Orote",
      X: -5213.99707,
      Y: 28.36422539,
      Z: -16965.1543,
      Lat: 13.43646945,
      Long: 144.641373,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "6": {
      Name: "Andersen AFB",
      X: 9961.662109,
      Y: 166.1221466,
      Z: 13072.15527,
      Lat: 13.57604707,
      Long: 144.9174589,
      Runways: "06,24",
      RunwayLength: "10490'",
      UHF: 250.1,
      VHF: 126.2,
      TACAN: "54X",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "7": {
      Name: "Pagan Airstrip",
      X: 512369.6875,
      Y: 16.033027648926,
      Z: 107433.7421875,
      Lat: 18.122779139405,
      Long: 145.76191440812,
      Runways: "06,24",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "8": {
      Name: "North West Field",
      X: 15909.4765625,
      Y: 159.00015258789,
      Z: 7619.5615234375,
      Lat: 18.122779139405,
      Long: 145.76191440812,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
  },
  [Theater.SOUTH_ATLANTIC]: {
    "1": {
      Name: "Port Stanley",
      X: 89009.42969,
      Y: 20.55744362,
      Z: 94537.625,
      Lat: -51.68611764,
      Long: -57.76816164,
      Runways: "27,09",
      RunwayLength: "",
      UHF: "250.150",
      VHF: "118.100",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "2": {
      Name: "Mount Pleasant",
      X: 73598.5625,
      Y: 74.13807678,
      Z: 46176.41406,
      Lat: -51.81798045,
      Long: -58.4720474,
      Runways: "10,28",
      RunwayLength: "",
      UHF: "250.200",
      VHF: "133.350",
      TACAN: "59X",
      VOR: "114.70",
      ILS: ["111.90"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "3": {
      Name: "San Carlos FOB",
      X: 107859.1875,
      Y: 19.96663284,
      Z: 8631.193359,
      Lat: -51.50203963,
      Long: -59.00282194,
      Runways: "11,27",
      RunwayLength: "",
      UHF: "250.250",
      VHF: "134.350",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "5": {
      Name: "Rio Gallegos",
      X: 26083.36328,
      Y: 15.45477486,
      Z: -701836.5,
      Lat: -51.60800384,
      Long: -69.28920478,
      Runways: "25,07",
      RunwayLength: "",
      UHF: "250.300",
      VHF: "119.100",
      TACAN: "",
      VOR: "116.70",
      ILS: ["110.30"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "6": {
      Name: "Rio Grande",
      X: -197290.6563,
      Y: 18.52179146,
      Z: -560378.75,
      Lat: -53.77768328,
      Long: -67.76350106,
      Runways: "08,26",
      RunwayLength: "",
      UHF: "250.350",
      VHF: "117.30",
      TACAN: "31X",
      VOR: "",
      ILS: ["RWY 26 109.50"],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "7": {
      Name: "Ushuaia",
      X: -320821.5625,
      Y: 18.49926949,
      Z: -577284.25,
      Lat: -54.84373642,
      Long: -68.31383249,
      Runways: "07,25",
      RunwayLength: "",
      UHF: "250.400",
      VHF: "118.100",
      TACAN: "",
      VOR: "113.70",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "8": {
      Name: "Ushuaia Helo Port",
      X: -317643.0313,
      Y: 12.84391403,
      Z: -577155.5625,
      Lat: -54.81591238,
      Long: -68.30393645,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "9": {
      Name: "Punta Arenas",
      X: -148447.125,
      Y: 37.38114929,
      Z: -778803.625,
      Lat: -53.01269434,
      Long: -70.84715544,
      Runways: "30,12",
      RunwayLength: "",
      UHF: "250.500",
      VHF: "118.700",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "10": {
      Name: "Pampa Guanaco",
      X: -238376.9531,
      Y: 158.3751068,
      Z: -623417,
      Lat: -54.05023552,
      Long: -68.80356711,
      Runways: "26,8",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "11": {
      Name: "San Julian",
      X: 297758.4063,
      Y: 44.00004578,
      Z: -636012.375,
      Lat: -49.30537186,
      Long: -67.78993822,
      Runways: "25,07",
      RunwayLength: "",
      UHF: "250.000",
      VHF: "118.000",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "12": {
      Name: "Puerto Williams",
      X: -323578.4375,
      Y: 13.99996471,
      Z: -532581.4375,
      Lat: -54.93062329,
      Long: -67.63640861,
      Runways: "08,26",
      RunwayLength: "",
      UHF: "250.050",
      VHF: "118.050",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "13": {
      Name: "Puerto Natales",
      X: -24327.92773,
      Y: 66.13660431,
      Z: -922249.125,
      Lat: -51.67447612,
      Long: -72.51843416,
      Runways: "28,10",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "14": {
      Name: "El Calafate",
      X: 136666.4375,
      Y: 199.6387939,
      Z: -921364.6875,
      Lat: -50.27955475,
      Long: -72.03679295,
      Runways: "25,07",
      RunwayLength: "",
      UHF: "250.100",
      VHF: "119.950",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
  },
  [Theater.SINAI]: {
    "1": {
      Name: "Difarsuwar Airfield",
      X: 40625.1640625,
      Y: 2.0000019073486,
      Z: 105362.5546875,
      Lat: 30.423828562277,
      Long: 32.335048697909,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "2": {
      Name: "Abu Suwayr",
      X: 57019.3125,
      Y: 5.6534752845764,
      Z: 83860.0390625,
      Lat: 30.570417983294,
      Long: 32.109822645849,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "3": {
      Name: "As Salihiyah",
      X: 80638.28125,
      Y: 2.2865109443665,
      Z: 77155.0625,
      Lat: 30.783020972132,
      Long: 32.037799068387,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "4": {
      Name: "Al Ismailiyah",
      X: 59102.58203125,
      Y: 3.5000035762787,
      Z: 96999.0546875,
      Lat: 30.590080139459,
      Long: 32.24668362756,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "5": {
      Name: "Melez",
      X: 37918.48828125,
      Y: 305.94686889648,
      Z: 184226.4375,
      Lat: 30.401003417138,
      Long: 33.156202467285,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "6": {
      Name: "Fayed",
      X: 30116.763671875,
      Y: 8.4882583618164,
      Z: 100213.671875,
      Lat: 30.328723193332,
      Long: 32.282131415295,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "7": {
      Name: "Hatzerim",
      X: 131615.359375,
      Y: 209.16006469727,
      Z: 328237.03125,
      Lat: 31.235724997339,
      Long: 34.669682340172,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "8": {
      Name: "Nevatim",
      X: 128765,
      Y: 397.19345092773,
      Z: 359368.625,
      Lat: 31.205363057243,
      Long: 34.995874044725,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "9": {
      Name: "Ramon Airbase",
      X: 80339.390625,
      Y: 619.02374267578,
      Z: 327491.28125,
      Lat: 30.773346519205,
      Long: 34.653849887603,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "10": {
      Name: "Ovda",
      X: -12648.833984375,
      Y: 437.17709350586,
      Z: 355426.9375,
      Lat: 29.930639915861,
      Long: 34.929106872332,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "11": {
      Name: "Kibrit Air Base",
      X: 19641.556640625,
      Y: 4.0000038146973,
      Z: 121117.6171875,
      Lat: 30.235208931832,
      Long: 32.500058570759,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "12": {
      Name: "Kedem",
      X: 134111.25,
      Y: 193.26135253906,
      Z: 326173.71875,
      Lat: 31.258515236062,
      Long: 34.648415986261,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "13": {
      Name: "Wadi al Jandali",
      X: 434.43112182617,
      Y: 228.25518798828,
      Z: 56276.01953125,
      Lat: 30.05760913971,
      Long: 31.828304106519,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "14": {
      Name: "Al Mansurah",
      X: 100415.7734375,
      Y: 2.0000019073486,
      Z: 20298.685546875,
      Lat: 30.955645437317,
      Long: 31.440851709277,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "15": {
      Name: "AzZaqaziq",
      X: 58754.62109375,
      Y: 2.0000019073486,
      Z: 41092.86328125,
      Lat: 30.582259984221,
      Long: 31.663705462571,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "16": {
      Name: "Bilbeis Air Base",
      X: 37095.92578125,
      Y: 28.000028610229,
      Z: 35651.41015625,
      Lat: 30.38627603978,
      Long: 31.609749173047,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "17": {
      Name: "Cairo International Airport",
      X: 5399.4194335938,
      Y: 120.00010681152,
      Z: 16962.88671875,
      Lat: 30.098133059692,
      Long: 31.419872765816,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "18": {
      Name: "Cairo West",
      X: 5664.8173828125,
      Y: 134.0313873291,
      Z: -33175.203125,
      Lat: 30.093241009478,
      Long: 30.899751236877,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "19": {
      Name: "Inshas Airbase",
      X: 31109.359375,
      Y: 47.1042137146,
      Z: 18786.103515625,
      Lat: 30.330287198002,
      Long: 31.435120951601,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "20": {
      Name: "Hatzor",
      X: 189237.921875,
      Y: 30.605157852173,
      Z: 331719.21875,
      Lat: 31.754913729124,
      Long: 34.715696596211,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "21": {
      Name: "Palmahim",
      X: 204512.3125,
      Y: 10.000009536743,
      Z: 328835.9375,
      Lat: 31.893066615134,
      Long: 34.687770236765,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "22": {
      Name: "Sde Dov",
      X: 229793.375,
      Y: 6.0000061988831,
      Z: 337688.125,
      Lat: 32.119759383142,
      Long: 34.785751671538,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "23": {
      Name: "Tel Nof",
      X: 197285.875,
      Y: 43.283580780029,
      Z: 341181.34375,
      Lat: 31.826102387607,
      Long: 34.816978448507,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "24": {
      Name: "Ben-Gurion",
      X: 216344.328125,
      Y: 30.125030517578,
      Z: 347410.78125,
      Lat: 31.996996567521,
      Long: 34.886274956492,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "25": {
      Name: "St Catherine",
      X: -152696.75,
      Y: 1297.2513427734,
      Z: 273158.125,
      Lat: 28.676570073217,
      Long: 34.0637788636,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "26": {
      Name: "Abu Rudeis",
      X: -127799.015625,
      Y: 0.89797097444534,
      Z: 188272.4375,
      Lat: 28.905340977704,
      Long: 33.195411869275,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "27": {
      Name: "Baluza",
      X: 104538.2265625,
      Y: 36.500038146973,
      Z: 127337.171875,
      Lat: 31.001504106029,
      Long: 32.561254631972,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "28": {
      Name: "Bir Hasanah",
      X: 14379.502929688,
      Y: 373.25036621094,
      Z: 210189.71875,
      Lat: 30.187985774894,
      Long: 33.425568649715,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "29": {
      Name: "El Arish",
      X: 107455.1328125,
      Y: 94.929046630859,
      Z: 247926.53125,
      Lat: 31.025935036084,
      Long: 33.824634027005,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
    "30": {
      Name: "El Gora",
      X: 112702.203125,
      Y: 128.82461547852,
      Z: 277753.84375,
      Lat: 31.070896202002,
      Long: 34.137670492733,
      Runways: "",
      RunwayLength: "",
      UHF: "",
      VHF: "",
      TACAN: "",
      VOR: "",
      ILS: [""],
      Elevation: "",
      "MF/HF": "",
      ICAO: "",
    },
  },
  [Theater.KOLA]: {
    "1": {
      Id: "1",
      Name: "Lakselv",
      X: 233623.484375,
      Y: 5.2753481864929,
      Z: 88526.1171875,
      Lat: 70.057759938201,
      Lon: 24.975276128064,
    },
    "2": {
      Id: "2",
      Name: "Rovaniemi",
      X: -151314.21875,
      Y: 186.5428314209,
      Z: 152281.609375,
      Lat: 66.574566219304,
      Lon: 25.849959054083,
    },
    "3": {
      Id: "3",
      Name: "Kemi Tornio",
      X: -242203.125,
      Y: 14.260339736938,
      Z: 101322.7734375,
      Lat: 65.792105051259,
      Lon: 24.586346222607,
    },
    "4": {
      Id: "4",
      Name: "Bas 100",
      X: -92977.59375,
      Y: 224.3041229248,
      Z: 178726.296875,
      Lat: 67.075946867342,
      Lon: 26.560454027496,
    },
    "5": {
      Id: "5",
      Name: "Kiruna",
      X: -19536.68359375,
      Y: 437.3486328125,
      Z: -89975.9921875,
      Lat: 67.830309878359,
      Lon: 20.352317498997,
    },
    "6": {
      Id: "6",
      Name: "Severomorsk3",
      X: 147695.09375,
      Y: 172.87286376953,
      Z: 446049,
      Lat: 68.85677411136,
      Lon: 33.715938376222,
    },
    "7": {
      Id: "7",
      Name: "Bodo",
      X: -67068.453125,
      Y: 7.8878412246704,
      Z: -349646.53125,
      Lat: 67.266972958478,
      Lon: 14.335477876481,
    },
    "8": {
      Id: "8",
      Name: "Severomorsk1",
      X: 165326.0625,
      Y: 79.83479309082,
      Z: 429605.875,
      Lat: 69.041038945947,
      Lon: 33.404833413526,
    },
    "9": {
      Id: "9",
      Name: "Olenegorsk",
      X: 70022.5234375,
      Y: 220.00268554688,
      Z: 452243.71875,
      Lat: 68.165641193625,
      Lon: 33.478248615765,
    },
    "10": {
      Id: "10",
      Name: "Monchegorsk",
      X: 45776.3046875,
      Y: 166.96284484863,
      Z: 436966.53125,
      Lat: 67.980068235154,
      Lon: 33.00554585351,
    },
    "11": {
      Id: "11",
      Name: "Jokkmokk",
      X: -167324.4375,
      Y: 271.79202270508,
      Z: -101186.4296875,
      Lat: 66.503506337049,
      Lon: 20.134963877353,
    },
    "12": {
      Id: "12",
      Name: "Murmansk International",
      X: 132609.640625,
      Y: 76.017112731934,
      Z: 408650.03125,
      Lat: 68.790779721545,
      Lon: 32.735392362593,
    },
    "13": {
      Id: "13",
      Name: "Kalixfors",
      X: -26226.8671875,
      Y: 473.07836914062,
      Z: -94382.59375,
      Lat: 67.769859948236,
      Lon: 20.249609559672,
    },
  },
  [Theater.AFGHANISTAN]: {
    "1": {
      Id: "1",
      Name: "Herat",
      X: 27163.974609375,
      Y: 969.9853515625,
      Z: -371062.8125,
      Lat: 34.220031212738,
      Lon: 62.230129665702,
    },
    "2": {
      Id: "2",
      Name: "Farah",
      X: -179584.328125,
      Y: 682.77307128906,
      Z: -377949.8125,
      Lat: 32.354876963981,
      Lon: 62.173144056959,
    },
    "3": {
      Id: "3",
      Name: "Shindand",
      X: -65664.1640625,
      Y: 1132.6168212891,
      Z: -368944.5625,
      Lat: 33.383007536994,
      Lon: 62.260362260821,
    },
    "4": {
      Id: "4",
      Name: "Maymana Zahiraddin Faryabi",
      X: 217346.609375,
      Y: 840.72406005859,
      Z: -140807.25,
      Lat: 35.924391322221,
      Lon: 64.76619558641,
    },
    "5": {
      Id: "5",
      Name: "Chaghcharan",
      X: 63560.796875,
      Y: 2271.1975097656,
      Z: -90877.8984375,
      Lat: 34.529394368153,
      Lon: 65.280155710858,
    },
    "6": {
      Id: "6",
      Name: "Qala i Naw",
      X: 112515.5546875,
      Y: 889.08715820312,
      Z: -288784.59375,
      Lat: 34.992086349078,
      Lon: 63.124538116342,
    },
    "7": {
      Id: "7",
      Name: "Kandahar",
      X: -269628.46875,
      Y: 1016.9556884766,
      Z: -28470.814453125,
      Lat: 31.513217729616,
      Lon: 65.860714738417,
    },
    "8": {
      Id: "8",
      Name: "Bost",
      X: -268069.53125,
      Y: 776.12280273438,
      Z: -170755.5625,
      Lat: 31.55201665208,
      Lon: 64.363255135089,
    },
    "9": {
      Id: "9",
      Name: "Tarinkot",
      X: -148011.875,
      Y: 1333.1419677734,
      Z: -32091.802734375,
      Lat: 32.609904004956,
      Lon: 65.856509652753,
    },
    "10": {
      Id: "10",
      Name: "Camp Bastion",
      X: -236770.609375,
      Y: 879.70733642578,
      Z: -184686.5,
      Lat: 31.835819789783,
      Lon: 64.22020303609,
    },
    "11": {
      Id: "11",
      Name: "Dwyer",
      X: -320083.34375,
      Y: 731.04968261719,
      Z: -199276.921875,
      Lat: 31.085639060294,
      Lon: 64.05755617642,
    },
    "12": {
      Id: "12",
      Name: "Nimroz",
      X: -332894.40625,
      Y: 483.00051879883,
      Z: -390513.4375,
      Lat: 30.970914944055,
      Lon: 62.053757073272,
    },
    "13": {
      Id: "13",
      Name: "Camp Bastion Heliport",
      X: -234788.125,
      Y: 884.36590576172,
      Z: -185436.375,
      Lat: 31.853777562069,
      Lon: 64.212513895988,
    },
    "14": {
      Id: "14",
      Name: "Shindand Heliport",
      X: -63020.765625,
      Y: 1157.8818359375,
      Z: -368217.8125,
      Lat: 33.406894837978,
      Lon: 62.267975429692,
    },
    "15": {
      Id: "15",
      Name: "Kandahar Heliport",
      X: -268791.6875,
      Y: 1017.0010375977,
      Z: -29800.087890625,
      Lat: 31.521070343338,
      Lon: 65.84696137969,
    },
  },
};

export default bases;
