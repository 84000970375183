import * as React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  colorMap: Record<string, string>;
  children: React.ReactNode;
};

function TimelineStyleWrapper({ className, children }: Props) {
  return <div className={className}>{children}</div>;
}

export default styled(TimelineStyleWrapper).attrs({
  className: TimelineStyleWrapper.name,
})`
  ${({ colorMap }) =>
    Object.keys(colorMap)
      .map(
        (key) => `
        .${key} .vis-inner {
            color: ${colorMap[key]};
        }

        .${key} .vis-item {
            border-color: ${colorMap[key]};
        }

        .${key} .vis-range {
            background-color: ${colorMap[key]};
            color: white;
        }
    `
      )
      .join("\n")}
`;
