import { LuaFunc } from "../../lua";
import { LuaTable } from "../../types";
import { JSLuaRuntime } from "./JSLuaRuntime";

// TDOD: create an implemenation for this.
// Getting and setting via array have never really worked the way we want.
// This is the goal interface that we have never achieved.
export interface LuaTableClient {
  get(key: Array<string | number>): LuaTable | string | number;
  set(key: Array<string | number>, value: LuaTable | string | number);
}

export interface LuaRuntime {
  init(luaStr: string, varName?: string);
  run(customFunc: LuaFunc, luaVar?: string);
  print(varName?: string): string;
  client(varName: string): LuaTable;
}

export function NewLuaRuntime(): LuaRuntime {
  return new JSLuaRuntime();
}
