import { Alert } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useSubmitExternalData } from "../../hooks/external_data";
import { useAdminGetFragOrderID } from "../../hooks/frag_orders";
import {
  ExternalDataRecordDoc,
  RecordType,
} from "../../lib/models/ExternalDataRecord";
import EditableTable from "../EditableTable";
import Flex from "../Flex";
import {
  validateIFF,
  validateLaserCode,
  validateUniqLaserCodes,
} from "./utils";

type Props = {
  className?: string;
  record: ExternalDataRecordDoc;
  filter: string;
};

function DataView({ className, record, filter }: Props) {
  const fragOrderID = useAdminGetFragOrderID();
  const upsert = useSubmitExternalData(fragOrderID);
  const { success, error } = useNotification();

  const rows = JSON.parse(record.payload);

  const filteredRows = _.filter(rows, (row) => {
    return _.includes(row.groupName.toLowerCase(), filter.toLowerCase());
  });

  const cols = _.keys(_.first(rows));

  if (!rows || rows.length === 0) {
    return (
      <Flex wide center>
        <Alert style={{ width: "100%" }} severity="info">
          No records found.
        </Alert>
      </Flex>
    );
  }

  const formattedCols = _.map(cols, (col) => ({
    value: col,
    label: _.startCase(col),
    fieldType: formatType(typeof _.get(_.first(rows), col)),
    editable: _.includes(getEditableFields(record.type), col),
    validators: getValidators(col),
  }));

  const handleEdit = (rows: any[]) => {
    return upsert(record.type, record.coalition, JSON.stringify(rows))
      .then(() => {
        success("External data updated.");
      })
      .catch((err) => {
        error(err.message);
      });
  };

  return (
    <div className={className}>
      <EditableTable
        onSubmit={handleEdit}
        columns={formattedCols}
        rows={filteredRows || []}
        tableProps={{ stickyHeader: true }}
        globalValidators={[validateUniqLaserCodes, validateIFF]}
      />
    </div>
  );
}

export default styled(DataView).attrs({ className: DataView.name })`
  tbody td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  tbody td:last-child {
    max-width: 240px;
  }

  table thead .MuiTableCell-stickyHeader {
    background-color: #fff;
  }
`;

function formatType(type: string): "text" | "number" | "boolean" {
  if (type === "string") {
    return "text";
  }

  return type as "number" | "boolean";
}

function getEditableFields(recordType: RecordType) {
  switch (recordType) {
    case RecordType.GroupAdminDetails:
      return ["aux"];
    case RecordType.AircraftAdminDetails:
      return [
        "playerName",
        "iffMode1",
        "iffMode3",
        "tailNumber",
        "laserCode",
        "stn",
        "voiceCallsignLabel",
        "voiceCallsignNumber",
      ];
  }
}

function getValidators(field: string): any[] {
  switch (field) {
    // case "iffMode1":
    //   return [validateIFFMode1];
    // case "iffMode3":
    //   return [validateIFFMode3];
    // case "tailNumber":
    //   return [validateTailNumber];
    case "laserCode":
      return [validateLaserCode];
    // case "stn":
    //   return [validateSTN];
    // case "voiceCallsignLabel":
    //   return [validateVoiceCallsignLabel];
    // case "voiceCallsignNumber":
    //   return [validateVoiceCallsignNumber];
    default:
      return [];
  }
}
