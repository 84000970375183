import { useEffect } from "react";
import { createZoneLayer } from "../../lib/layers/zones";
import { useMap } from "./MapCanvas";

export default function ZoneLayer({ theater, zones }) {
  const map = useMap();
  useEffect(() => {
    if (!map) {
      return;
    }
    const layer = createZoneLayer(theater, zones);

    layer.set("hideable", true);

    map.addLayer(layer);
  }, [map]);

  return null;
}
