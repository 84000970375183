import * as React from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  UserPreferenceDoc,
  UserPreferenceType,
} from "../lib/models/UserPreference";
import { UserPreferenceManager } from "../lib/services/UserPreferenceManager";
import { useUser } from "./UserContext";

const UserPreferencesManagerContext = React.createContext<UserPreferenceManager | null>(
  null
);

type Props = {
  manager: UserPreferenceManager;
  children?: any;
};

export function UserPreferencesManagerProvider({ manager, children }: Props) {
  return (
    <UserPreferencesManagerContext.Provider value={manager}>
      {children}
    </UserPreferencesManagerContext.Provider>
  );
}

function useUserPreferencesManager() {
  const manager = React.useContext(UserPreferencesManagerContext);

  if (!manager) {
    throw new Error(
      "useUserPreferencesManager must be used within a UserPreferencesManagerProvider"
    );
  }

  return manager;
}

const USER_PREFS_KEY = "userPreferences";

export function useListUserPreferences() {
  const { currentUser } = useUser();
  const manager = useUserPreferencesManager();

  return useQuery([USER_PREFS_KEY], () => manager.list(currentUser.id));
}

export function useUpsertUserPreference() {
  const { currentUser } = useUser();
  const qc = useQueryClient();
  const manager = useUserPreferencesManager();

  return (name: string, type: UserPreferenceType, payload: any) =>
    manager
      .upsert(currentUser.id, {
        name,
        type,
        payload: JSON.stringify(payload),
      })
      .then(() => {
        qc.invalidateQueries(USER_PREFS_KEY);
      });
}

export function useDeleteUserPreference() {
  const qc = useQueryClient();
  const manager = useUserPreferencesManager();

  return (preference: UserPreferenceDoc) =>
    manager.remove(preference).then(() => {
      qc.invalidateQueries(USER_PREFS_KEY);
    });
}
