import { FirebaseMetadata } from "../types";
import { JobSettings, JobType } from "./Job";

export enum AssetType {
  Kneeboard = "Kneeboard",
  GeneratedKneeboard = "GeneratedKneeboard",
}

export class Asset {
  id?: string;
  name: string;
  type: AssetType;
  manifestID: string;
  jobType?: JobType;
  jobData?: string;
  jobSettings?: JobSettings;
  displayName: string;
}

export type AssetDoc = Asset & FirebaseMetadata;
