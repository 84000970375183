import * as React from "react";
import styled from "styled-components";

import Flex from "./Flex";

// @ts-ignore
import logo from "../img/logo.svg";
// @ts-ignore
import solo from "../img/fo_solo.svg";

export enum LogoVariant {
  LeftAligned = "LeftAligned",
  Solo = "Solo",
}

type Props = {
  className?: string;
  height?: number;
  width?: number;
  variant?: LogoVariant;
};

function Logo({ className, variant = LogoVariant.LeftAligned }: Props) {
  let src = "";

  switch (variant) {
    case LogoVariant.LeftAligned:
      src = logo;
      break;

    case LogoVariant.Solo:
      src = solo;
      break;
    default:
      break;
  }

  return (
    <Flex column center className={className}>
      <img src={src} />
    </Flex>
  );
}

export default styled(Logo).attrs({ className: Logo.name })`
  img {
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
  }
`;
