import JSZip from "jszip";
import { getMission } from "../lua";
import { FragOrderDoc } from "../models/FragOrder";
import { Job, JobType } from "../models/Job";
import { JSLuaRuntime } from "./LuaRuntime/JSLuaRuntime";
import { LuaRuntime } from "./LuaRuntime/LuaRuntime";
import { MizStorer } from "./MizStorer";

export const JobFileNameLookup = {
  [JobType.InjectAirbasesKneeboard]: () => "FragOrders_Airbase_Kneeboard",
  [JobType.BriefingText]: () => "FragOrders_Briefing_Text_Kneeboard",
  [JobType.GroupLineupCard]: (groupName: string) =>
    `FragOrders_Lineup_Card_${groupName}`,
  [JobType.GroupCommsCard]: (groupName: string) =>
    `FragOrders_Comms_Card_${groupName}`,
  [JobType.RadioPresetConfigKneeboard]: () => "FragOrders_Radio_Presets",
  [JobType.ControlPointsCard]: () => "FragOrders_Control_Points",
  [JobType.InjectRouteDetailCards]: () => "FragOrders_Route_Detail_Cards",
  [JobType.InjectSupportAssetsKneeboard]: () => "FragOrders_Support_Assets",
};

export const JobFileDisplayNameLookup = {
  [JobType.InjectAirbasesKneeboard]: () => "Airbases",
  [JobType.BriefingText]: () => "Briefing",
  [JobType.GroupLineupCard]: (groupName: string) =>
    `Lineup Card - ${groupName}`,
  [JobType.GroupCommsCard]: (groupName: string) => `Comms Card - ${groupName}`,
  [JobType.RadioPresetConfigKneeboard]: () => "Radio Presets",
  [JobType.ControlPointsCard]: () => "Control Points",
  [JobType.InjectRouteDetailCards]: (groupName: string) =>
    `${groupName} - Route Detail`,
  [JobType.InjectSupportAssetsKneeboard]: () => "Support Assets",
};

export default interface JobRunner {
  add(job: Job);
  run(fo: FragOrderDoc): Promise<JSZip>;
}

type RuntimeFactory = () => LuaRuntime;

class runner {
  ms: MizStorer;
  // TODO: currently not used. This should b e a factory.
  lua: RuntimeFactory;

  private jobs: Job[] = [];
  private missionStr: string;
  private miz: JSZip;

  add(job: Job) {
    this.jobs.push(job);
  }

  async run(fragOrder: FragOrderDoc): Promise<JSZip> {
    await this.setup(fragOrder.id);

    for (const job of this.jobs) {
      if (!job) {
        continue;
        // throw new Error("Job was undefined");
      }

      if (job.luaFunc) {
        const lua = new JSLuaRuntime();
        lua.init(this.missionStr);
        lua.run(job.luaFunc);
        this.missionStr = lua.print();
      }

      if (job.zipFunc) {
        this.miz = await job.zipFunc(this.miz);
      }
    }

    return await this.assembleMiz();
  }

  private async setup(id: string) {
    this.miz = await this.ms.download(id);

    const [missionStr] = await getMission(this.miz);
    this.missionStr = missionStr;
  }

  private async assembleMiz(): Promise<JSZip> {
    this.miz.file("mission", this.missionStr);

    return this.miz;
  }
}

export function NewJobRunner(ms: MizStorer, fact: RuntimeFactory): JobRunner {
  const r = new runner();
  r.ms = ms;
  r.lua = fact;

  return r;
}
