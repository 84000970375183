import { FirebaseError } from "firebase/app";
import * as React from "react";
import { useQuery } from "react-query";
import { useGetCurrentManifestID } from "../hooks/frag_orders";
import { PublishManifestDoc } from "../lib/models/PublishManifest";
import { PublishReader } from "../lib/services/PublishManager";
import { useGetBundleForManifest } from "./PublishManagerContext";

type PublishReaderContextType = PublishReader;

type Props = {
  children?: any;
  reader: PublishReader;
};

export const PublishReaderContext = React.createContext<
  PublishReaderContextType
>({} as PublishReaderContextType);

export function PublishReaderProvider({ reader, children }: Props) {
  const value = reader;

  return (
    <PublishReaderContext.Provider value={value}>
      {children}
    </PublishReaderContext.Provider>
  );
}

export function usePublishReader() {
  return React.useContext(PublishReaderContext);
}

export function useListManifesets(manifestID: string) {
  const reader = usePublishReader();
  return useQuery<PublishManifestDoc, FirebaseError>(
    ["manifests", manifestID],
    () => reader.get(manifestID),
    { retry: false }
  );
}

export function useGetCurrentManifest() {
  const id = useGetCurrentManifestID();

  return useListManifesets(id);
}

export function useGetCurrentTaskingData() {
  const { data: m } = useGetCurrentManifest();

  return useGetBundleForManifest(m);
}
