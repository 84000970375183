import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  ModalProps,
  Select,
  TextField,
} from "@mui/material";
import { APIRole } from "discord-api-types/v10";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  DiscordID,
  SignupRoleType,
  SignupRule,
} from "../../lib/models/SignupRecord";
import Flex from "../Flex";
import ModalContent from "../ModalContent";
import Section from "../Section";

type Props = {
  className?: string;
  members: APIRole[];
  onSubmit: (rule: SignupRule) => Promise<void>;
  rule: SignupRule;
  modalProps: ModalProps;
};

function SignupRuleForm({
  className,
  members,
  onSubmit,
  modalProps,
  rule: initialRule,
}: Props) {
  const [busy, setBusy] = React.useState(false);
  const [rule, setRule] = React.useState<SignupRule>(initialRule);
  const { success, error } = useNotification();

  const handleSubmit = (e) => {
    e.preventDefault();

    setBusy(true);

    onSubmit(rule)
      .then(() => {
        modalProps.onClose({}, "escapeKeyDown");
        success("Signup rule updated successfully");
      })
      .catch((e) => {
        error("Error updating signup rule: " + e.message);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  if (!initialRule) {
    return null;
  }

  return (
    <div className={className}>
      <Modal {...modalProps}>
        <ModalContent style={{ width: 480 }}>
          <Section title="Add/Edit Signup Rule">
            <form onSubmit={handleSubmit}>
              <Box className={className} marginTop={2} paddingTop={2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControl>
                      <TextField
                        onChange={(ev) =>
                          setRule({ ...rule, missionRole: ev.target.value })
                        }
                        value={rule.missionRole}
                        style={{ width: 180 }}
                        label="Role Name"
                        required
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <InputLabel id="role-type-select-label">
                        Role Type
                      </InputLabel>
                      <Select
                        onChange={(ev) =>
                          setRule({
                            ...rule,
                            signupRoleType: ev.target.value as SignupRoleType,
                          })
                        }
                        value={rule.signupRoleType}
                        label="Role Type"
                        required
                        sx={{ width: 180 }}
                      >
                        <MenuItem value={SignupRoleType.Aircrew}>
                          {SignupRoleType.Aircrew}
                        </MenuItem>
                        <MenuItem value={SignupRoleType.Controller}>
                          {SignupRoleType.Controller}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl>
                      <InputLabel id="discord-select-label">
                        Discord Role
                      </InputLabel>
                      <Select
                        onChange={(ev) =>
                          setRule({
                            ...rule,
                            discordID: ev.target.value as DiscordID,
                            discordLabel: _.find(members, {
                              id: ev.target.value,
                            }).name,
                          })
                        }
                        value={rule.discordID}
                        label="Discord Role"
                        sx={{ width: 180 }}
                        required
                      >
                        {_.map(members, (m) => (
                          <MenuItem key={m.id} value={m.id}>
                            {m.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ width: 180 }}>
                      <TextField
                        onChange={(ev) => {
                          if (ev.target.value) {
                            setRule({
                              ...rule,
                              slotCount: parseInt(ev.target.value),
                            });
                          }
                        }}
                        value={rule.slotCount}
                        style={{ width: 180 }}
                        type="number"
                        label="Slot Count"
                        required
                        placeholder="Count"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Box marginTop={2}>
                  <Flex wide>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        multiline
                        fullWidth
                        label="Notes"
                        rows={12}
                        onChange={(ev) =>
                          setRule({ ...rule, notes: ev.target.value })
                        }
                        value={rule.notes || ""}
                      />
                    </FormControl>
                  </Flex>
                </Box>
              </Box>
              <Box marginTop={2}>
                <Flex wide between>
                  <LoadingButton
                    loading={busy}
                    variant="contained"
                    type="submit"
                  >
                    Add/Edit Rule
                  </LoadingButton>
                  <Button
                    onClick={() => modalProps.onClose({}, "escapeKeyDown")}
                    variant="outlined"
                  >
                    Close
                  </Button>
                </Flex>
              </Box>
            </form>
          </Section>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default styled(SignupRuleForm).attrs({
  className: SignupRuleForm.name,
})``;
