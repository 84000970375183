import { createGlobalStyle } from "styled-components";

const Style = createGlobalStyle`
  html {
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-size: 16px;
    padding: 0;
    margin: 0;
  }

  nav > div {
    width: 100%;
  }

  body {
    height: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => {
      return props.theme.palette.background.default;
    }}
  }


  nav {
    padding: 60px 0 !important;
  }

  .hidden-textarea {
    visibility: none;
  }


  .ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 32px;
    left: -50px;
    min-width: 280px;
    z-index: 2;
  }
  .ol-popup:after,
  .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "✖";
  }

  .MuiTooltip-root {
    font-size: 16px;
  }

  #planning-drawer .MuiPaper-root {
    left: 428px !important;
    width: calc(100vw - 428px) !important;
  }

  .wb-header {
    font-family: 'Roboto', sans-serif;

  }
`;

export default Style;
