import AddCircleIcon from "@mui/icons-material/AddCircle";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Box, Button, FormGroup } from "@mui/material";
// @ts-ignore
import { stringify } from "csv-stringify";
import { saveAs } from "file-saver";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  useGetBundleForManifest,
  useUpdateSignupRules,
} from "../../contexts/PublishManagerContext";
import { useListGuildRoles } from "../../hooks/discord";
import { useAdminGetCurrentFragOrder } from "../../hooks/frag_orders";
import { getLabelForModule } from "../../lib/data/modules";
import { PublishManifestDoc } from "../../lib/models/PublishManifest";
import { SignupRoleType, SignupRule } from "../../lib/models/SignupRecord";
import DataTable from "../DataTable";
import Flex from "../Flex";
import CSVImportDialog from "./CSVImportDialog";
import ImportGroupsDialog from "./ImportGroupsDialog";
import SignupRuleForm from "./SignupRuleForm";

type Props = {
  className?: string;
  manifest: PublishManifestDoc;
};

function SignupManagementForm({ className, manifest }: Props) {
  const { data: fragOrder } = useAdminGetCurrentFragOrder();
  const [updating, setUpdating] = React.useState(false);
  const [newRules, setNewRules] = React.useState<SignupRule[]>([]);
  const [removing, setRemoving] = React.useState<SignupRule>(null);
  const [csvOpen, setCsvOpen] = React.useState(false);
  const [editing, setEditing] = React.useState<SignupRule>(null);
  const [importOpen, setImportOpen] = React.useState(false);
  const update = useUpdateSignupRules();
  const { error, success } = useNotification();
  const { data: members } = useListGuildRoles(fragOrder.discordGuildID, true);

  const everyoneRole = _.find(members, { name: "@everyone" });

  const { data: tasking } = useGetBundleForManifest(manifest);
  const formattedMembers = React.useMemo(() => {
    if (!members) {
      return [];
    }

    return _.map(members, (m) => ({
      ...m,
      id: `r_${m.id}`,
    }));
  }, [members]);

  const rulesSubmittable =
    newRules.length > 0 &&
    _.every(newRules, (r: SignupRule) => !!r.missionRole);

  const handleImportSubmit = (rules: SignupRule[]) => {
    const manifestRules = manifest.signupRules || [];
    const u = _.uniqBy([...rules, ...manifestRules], "missionRole");
    return update(manifest, u);
  };

  const handleRemove = (rule: SignupRule) => {
    setRemoving(rule);

    const next = _.filter(
      manifest.signupRules,
      (r) => r.missionRole !== rule.missionRole
    );

    update(manifest, next)
      .then(() => {
        success("Signup rule removed successfully");
      })
      .catch((e) => {
        error("Error removing signup rule: " + e.message);
      })
      .finally(() => {
        setRemoving(null);
      });
  };

  const handleAdd = () => {
    setEditing({
      missionRole: "",
      discordID: `r_${everyoneRole.id}`,
      discordLabel: everyoneRole.name,
      signupRoleType: "" as SignupRoleType,
      slotCount: 0,
    });
  };

  const handleEdit = (r: SignupRule) => {
    setEditing(r);
  };

  const handleSubmit = () => {
    setUpdating(true);
    const next = [...(manifest.signupRules || []), ...newRules];

    const hasDuplicates = _.uniqBy(next, "missionRole").length !== next.length;

    if (hasDuplicates) {
      error("Role names must be unique");
      setUpdating(false);
      return;
    }

    update(manifest, next)
      .then(() => {
        success("Signup rules updated successfully");
        setNewRules([]);
      })
      .catch((e) => {
        error("Error updating signup rules: " + e.message);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleCSVImportDialog = () => {
    setCsvOpen(true);
  };

  const handleCSVDownload = () => {
    stringify(manifest.signupRules, { header: true }, (err, output) => {
      if (err) {
        error(err.message);
        return;
      }

      saveAs(new Blob([output]), `${fragOrder.name}_SignupRules.csv`);
    });
  };

  const sorted = _.sortBy(manifest?.signupRules || [], "missionRole");

  return (
    <div className={className}>
      <DataTable
        columns={[
          {
            label: "Mission Role",
            value: "missionRole",
          },
          {
            label: "Role Type",
            value: "signupRoleType",
          },
          {
            label: "Allowed Discord Role",
            value: "discordLabel",
          },
          {
            value: (r: SignupRule) => getLabelForModule(r.module),
            label: "Module",
          },
          {
            value: "slotCount",
            label: "Slot Count",
          },
          {
            value: (r: SignupRule) => (
              <Button variant="outlined" onClick={() => handleEdit(r)}>
                Edit
              </Button>
            ),
            label: "",
          },

          {
            label: "",
            value: (rule: SignupRule) => (
              <LoadingButton
                onClick={() => handleRemove(rule)}
                variant="outlined"
                color="error"
                loading={removing?.missionRole === rule.missionRole}
              >
                Remove
              </LoadingButton>
            ),
          },
        ]}
        rows={sorted}
      />
      <FormGroup>
        <Box marginTop={2}>
          <Flex wide between>
            <div>
              <Button
                startIcon={<AddCircleIcon />}
                variant="outlined"
                color="primary"
                onClick={handleAdd}
              >
                Add Role
              </Button>
            </div>

            <div>
              <Button
                style={{ marginRight: 8 }}
                variant="outlined"
                color="primary"
                onClick={handleCSVImportDialog}
              >
                Import from CSV
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setImportOpen(true)}
              >
                Import From Miz
              </Button>
            </div>
          </Flex>
          <Box marginTop={2}>
            <Flex wide between>
              <LoadingButton
                loading={updating}
                disabled={!rulesSubmittable}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </LoadingButton>
              <Button
                disabled={
                  !manifest.signupRules || manifest.signupRules.length === 0
                }
                onClick={handleCSVDownload}
                variant="outlined"
              >
                Save CSV
              </Button>
            </Flex>
          </Box>
        </Box>
      </FormGroup>

      {members && (
        <ImportGroupsDialog
          onImportSubmit={handleImportSubmit}
          tasking={tasking}
          members={members}
          modalProps={
            {
              open: importOpen,
              onClose: () => setImportOpen(false),
            } as any
          }
        />
      )}

      <CSVImportDialog
        // @ts-ignore
        modalProps={{ open: csvOpen, onClose: () => setCsvOpen(false) }}
        onSubmit={handleImportSubmit}
      />
      {editing && (
        <SignupRuleForm
          // @ts-ignore
          modalProps={
            { open: !!editing, onClose: () => setEditing(null) } as any
          }
          rule={editing}
          members={formattedMembers}
          onSubmit={(rule) => handleImportSubmit([rule])}
        />
      )}
    </div>
  );
}

export default styled(SignupManagementForm).attrs({
  className: SignupManagementForm.name,
})`
  ${DataTable} {
    th,
    td {
      padding: 4px;
    }
  }
`;
