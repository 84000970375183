import { Container } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import Flex from "./Flex";
import LoadingPage from "./LoadingPage";

type Props = {
  className?: string;
  title?: string | React.ReactElement;
  actions?: any;
  loading?: boolean;
  children?: any;
};

function Page(props: Props) {
  if (props.loading) {
    return <LoadingPage />;
  }
  return (
    <Container className={props.className}>
      <Flex wide align between>
        <h2>{props.title}</h2>
        {props.actions}
      </Flex>
      {props.children}
    </Container>
  );
}
export default styled(Page).attrs({
  className: Page.name,
})`
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;

  nav {
    padding: 0 !important;
    padding-bottom: 16px;
  }
`;
