import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useGetPlanItem } from "../../contexts/PlanManagerV2";
import { TaskingMapContext } from "../../contexts/TaskingMapContext";
import { Plan } from "../../lib/models/Plan";
import { PlanItemType, PlanNote } from "../../lib/models/PlanItem";

type Props = {
  className?: string;
  onSubmit: (notes: string) => Promise<void>;
  plan: Plan;
  groupName: string;
};

function MissionNotesInput({ className, onSubmit, plan, groupName }: Props) {
  const {
    state: { manifest },
  } = React.useContext(TaskingMapContext);
  const [submitting, setSubmitting] = React.useState(false);
  const { data: item } = useGetPlanItem(
    manifest.id,
    groupName,
    PlanItemType.PlanNote
  );
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    if (manifest.version >= 3) {
      const p = JSON.parse(item?.payload || "{}") as PlanNote;
      setText(p?.text || "");
      return;
    }

    setText(plan?.plannerNotes || "");
  }, [plan?.groupName, plan?.plannerNotes, item]);

  const handleSubmit = () => {
    setSubmitting(true);

    onSubmit(text).finally(() => setSubmitting(false));
  };

  return (
    <div className={className}>
      <p>
        Add notes that will be displayed in the Frag Order and generated
        kneeboards
      </p>
      <TextField
        multiline
        fullWidth
        rows={12}
        onChange={(ev) => setText(ev.target.value)}
        value={text || ""}
      />
      <Box my={1}>
        <LoadingButton
          loading={submitting}
          onClick={handleSubmit}
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Box>
    </div>
  );
}

export default styled(MissionNotesInput).attrs({
  className: MissionNotesInput.name,
})`
  width: 100%;
  min-height: 820px;

  .MuiTextField-root {
    width: 100%;

    textarea {
      font-family: monospace;
    }
  }
`;
