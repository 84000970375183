import { Typography } from "@mui/material";
import _ from "lodash";
import { Map as MapOl } from "ol";
import MousePosition from "ol/control/MousePosition";
import * as React from "react";
import * as ReactDOMServer from "react-dom/server";
import {
  decimalToDegreesMinutes,
  latLongToMGRS,
  metersToFeet,
} from "../../map";
import { getTerrainHeightAtCoords } from "../../measurements";

function padDec(dec: string) {
  const s = dec.toString();
  const [first, second] = s.split(".");
  const [deg, int] = first.split(" ");
  const padded = _.padStart(int, 2, "0");
  const decPadded = _.padEnd(second, 4, "0");

  return `${deg} ${padded}.${decPadded}`;
}

export function addMousePositionControl(map: MapOl, ref: HTMLDivElement) {
  const mousePositionControl = new MousePosition({
    coordinateFormat: (coords) => {
      const northingText = Math.sign(coords[1]) === -1 ? "S" : "N";
      const eastingText = Math.sign(coords[0]) === -1 ? "W" : "E";
      const lat = decimalToDegreesMinutes(coords[1]).join(" ");
      const long = decimalToDegreesMinutes(coords[0]).join(" ");

      const elevation = getTerrainHeightAtCoords(map, coords);

      const out = (
        <div>
          <span>
            <Typography fontSize={14} component="span" fontWeight={600}>
              LAT:{" "}
            </Typography>
          </span>
          <span>
            {northingText} {padDec(lat)}
          </span>
          <span>
            <Typography fontSize={14} component="span" fontWeight={600}>
              {" "}
              LON:{" "}
            </Typography>
          </span>
          <span>
            {eastingText} {padDec(long)}
          </span>
          <span>
            <Typography fontSize={14} component="span" fontWeight={600}>
              {" "}
              MGRS:{" "}
            </Typography>
          </span>
          <span>{latLongToMGRS({ lat: coords[1], long: coords[0] })}</span>
          <span>
            <Typography fontSize={14} component="span" fontWeight={600}>
              {" "}
              ELEVATION:{" "}
            </Typography>
            <span>
              <>{metersToFeet(elevation).toFixed(0)} FT</>
            </span>
          </span>
        </div>
      );

      return ReactDOMServer.renderToString(out);
    },
    projection: "EPSG:4326",
    className: "custom-mouse-position",
    target: ref,
  });

  map.addControl(mousePositionControl);
  return mousePositionControl;
}
