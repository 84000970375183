import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGetBundleForManifest } from "../../contexts/PublishManagerContext";
import {
  useDeleteExternalData,
  useListExternalData,
  useSubmitExternalData,
} from "../../hooks/external_data";
import {
  useAdminGetCurrentManifests,
  useAdminGetFragOrderID,
} from "../../hooks/frag_orders";
import { RecordType } from "../../lib/models/ExternalDataRecord";
import { Coalition } from "../../lib/types";
import Flex from "../Flex";
import LoadingPage from "../LoadingPage";
import Section from "../Section";
import TabPanel from "../TabPanel";
import DataView from "./DataView";
import DownloadTemplateButton from "./DownloadTemplateButton";
import ImportModal from "./ImportModal";
import { rowsFromTasking } from "./utils";

type Props = {
  className?: string;
};

function ExternalData({ className }: Props) {
  const { success, error } = useNotification();
  const [busy, setBusy] = React.useState(false);
  const [filter, setFilter] = React.useState("");

  const [importOpen, setImportOpen] = React.useState(false);
  // only blue supported for now
  const [formData /*, setFormData*/] = React.useState({
    coalition: Coalition.Blue,
    recordType: RecordType.AircraftAdminDetails,
  });

  const fragOrderID = useAdminGetFragOrderID();

  const submit = useSubmitExternalData(fragOrderID);
  const remove = useDeleteExternalData(fragOrderID);

  const { data, isLoading } = useListExternalData(fragOrderID, Coalition.Blue);

  const {
    data: manifests,
    isLoading: manifestsLoading,
  } = useAdminGetCurrentManifests();

  const manifest = _.find(manifests, { coalition: formData.coalition });

  const { data: tasking, isLoading: isLoadingBundle } = useGetBundleForManifest(
    manifest
  );

  const hasData = data && data.length > 0;

  const record = _.find(data, { type: RecordType.AircraftAdminDetails });

  const handleBatchEditToggle = () => {
    if (hasData) {
      if (
        window.confirm("Are you sure you want to remove all batch edit data?")
      ) {
        setBusy(true);
        // Only working with AdminDetails for now
        const record = _.find(data, { type: RecordType.AircraftAdminDetails });

        return remove(record)
          .then(() => {
            success("Disabled batch edit.");
          })
          .catch((e) => {
            console.error(e);
            error(e.message);
          })
          .finally(() => {
            setBusy(false);
          });
      }

      return;
    }

    const rows = rowsFromTasking(tasking);
    setBusy(true);
    return submit(
      RecordType.AircraftAdminDetails,
      formData.coalition,
      JSON.stringify(rows)
    )
      .then(() => {
        success("Enabled batch edit.");
      })
      .catch((e) => {
        console.error(e);
        error(e.message);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const handleImportClick = () => {
    setImportOpen(true);
  };

  if (isLoading || manifestsLoading || isLoadingBundle) {
    return <LoadingPage />;
  }

  return (
    <div className={`${className} ${hasData ? "takeover" : ""}`}>
      <Section
        title="Batch Edit"
        actions={[
          <Flex align key="actions">
            <Flex align>
              <Box mr={2}>
                <FormControl>
                  <TextField
                    size="small"
                    placeholder="Filter"
                    onChange={(ev) => setFilter(ev.target.value)}
                    value={filter}
                  />
                </FormControl>
              </Box>
            </Flex>
            <DownloadTemplateButton
              recordType={formData.recordType}
              coalition={formData.coalition}
            />
            <Box ml={2}>
              <Button
                key="import"
                disabled={!hasData}
                onClick={handleImportClick}
                variant="outlined"
                color="primary"
                style={{ marginRight: 16 }}
              >
                Import from CSV
              </Button>
            </Box>

            {/* </Box> */}
            <LoadingButton
              loading={busy}
              key="button"
              variant="contained"
              color={hasData ? "error" : "primary"}
              onClick={handleBatchEditToggle}
            >
              {hasData ? "Remove all" : "Import from Miz File"}
            </LoadingButton>
            {/* <FormControl key="enable">
              <Flex align>
                <FormLabel>Batch Edit Enabled</FormLabel>
                <Switch checked={hasData} onChange={handleBatchEditToggle} />
                <div style={{ width: 24 }}>
                  {busy && <CircularProgress size={24} />}
                </div>
              </Flex>
            </FormControl> */}
          </Flex>,
        ]}
      >
        {!hasData && (
          <Alert severity="info">
            <AlertTitle>No batch edit data</AlertTitle>
            No batch edit data has been added to this Frag Order. Click the{" "}
            <b>Import from Miz File</b> button to get started.
          </Alert>
        )}
        {hasData && (
          <Flex wide center>
            <DataView record={record} filter={filter} />
          </Flex>
        )}
      </Section>
      <ImportModal open={importOpen} onClose={() => setImportOpen(false)} />
    </div>
  );
}

export default styled(ExternalData).attrs({ className: ExternalData.name })`
  ${TabPanel} .Flex {
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  .MuiTabs-root {
    width: 200px;
  }
  .MuiButtonBase-root {
    white-space: nowrap;
    justify-content: center;
    align-items: flex-start;
  }

  /* Full width takeover */
  padding: 0 24px;
  padding-bottom: 24px;

  &.takeover {
    position: absolute;
    left: 0;
    right: 0;
  }

  ${Section} {
    box-sizing: border-box;
    overflow: auto;
  }
`;
