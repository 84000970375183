import { Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { userCanPlanGroup } from "../../contexts/TaskingMapContext";
import { NavTargetPoint } from "../../lib/types";
import Flex from "../Flex";
import PlanningModeButton from "../NavigationButtons/PlanningModeButton";

type Props = {
  className?: string;
  point?: NavTargetPoint;
  group: string;
};

function NavPoint({ className, point, group }: Props) {
  const isPlannable = userCanPlanGroup(group);

  return (
    <div className={className}>
      <Box mb={2}>{point.textComment}</Box>

      {isPlannable && (
        <Flex align>
          <PlanningModeButton />
        </Flex>
      )}
    </div>
  );
}

export default styled(NavPoint).attrs({ className: NavPoint.name })``;
