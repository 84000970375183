import { FirebaseMetadata } from "../types";
export enum SignupRoleType {
  Aircrew = "Pilot/Crew",
  Controller = "Controller",
}


// String must have a `r` for roles or a `u` for user ID
// prettier-ignore
export type DiscordID = `${"r_" | "u_"}${string}`;

export type SignupRule = {
  discordID: DiscordID;
  discordLabel: string;
  signupRoleType: SignupRoleType;
  missionRole: string;
  module?: string;
  slotCount: number;
  notes?: string;
}

export type SignupRecord = {
  manifestID: string;
  discordID: string;
  discordNickname: string;
  missionRole: string;
  copilot: boolean;
}

export type SignupRecordDoc = SignupRecord & FirebaseMetadata;
