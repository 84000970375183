import * as React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  column?: boolean;
  align?: boolean;
  height?: string;
  between?: boolean;
  center?: boolean;
  wide?: boolean;
  wrap?: boolean;
  children?: any;
  style?: any;
  tall?: boolean;
  start?: boolean;
  end?: boolean;
  around?: boolean;
};

function Flex({ className, children, style }: Props) {
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
}

export default styled(Flex).attrs({
  className: Flex.name,
})`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  align-items: ${(props) => (props.align ? "center" : "start")};
  ${({ tall }) => tall && `height: 100%`};
  ${({ wide }) => wide && "width: 100%"};
  ${({ wrap }) => wrap && "flex-wrap: wrap"};
  ${({ start }) => start && "justify-content: flex-start"};
  ${({ end }) => end && "justify-content: flex-end"};
  ${({ between }) => between && "justify-content: space-between"};
  ${({ center }) => center && "justify-content: center"};
  ${({ around }) => around && "justify-content: space-around"};
`;
