import _ from "lodash";
import { Feature } from "ol";
import { Point } from "ol/geom";
import VectorSource from "ol/source/Vector";
import * as React from "react";
import { convertDCSToLatLong } from "../../lib/map";
import { TaskingState } from "../../lib/models/PublishManifest";
import { NewHTMLImageGenerator } from "../../lib/services/HTMLImageGenerator";
import { NewImageComposer } from "../../lib/services/ImageComposer";
import ControlPointsLayer from "../MapCanvas/ControlPointsLayer";
import KneeboardControlPointsTable from "./KneeboardControlPointsTable";
import {
  KNEEBOARD_HEIGHT,
  KNEEBOARD_WIDTH,
  renderMap,
} from "./kneeboard_utils";

export async function renderControlPointsCard(tasking: TaskingState) {
  const trimmed = _.take(tasking.controlPoints, 15);

  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);

  if (trimmed.length === 0) {
    throw new Error(
      `No control points to render. Disable the "Inject Control Points" kneeboard job to resolve this issue.`
    );
  }

  const el = (
    <KneeboardControlPointsTable
      theater={tasking.theater}
      controlPoints={trimmed}
    />
  );

  const g = NewHTMLImageGenerator();

  const blob = await g.renderToBlob(el, {
    height: KNEEBOARD_HEIGHT,
    width: KNEEBOARD_WIDTH - 8,
  });

  const features = _.map(trimmed, (cp) => {
    const { long, lat } = convertDCSToLatLong(tasking.theater, cp);

    return new Feature({
      geometry: new Point([long, lat]),
    });
  });

  const source = new VectorSource({
    features,
  });

  const extent = source.getExtent();

  const height = KNEEBOARD_HEIGHT / 3;
  const width = KNEEBOARD_WIDTH;

  const mapImg = (await renderMap(
    extent,
    { height: height, width, zoomAdjust: -0.3 },
    <>
      <ControlPointsLayer points={trimmed} theater={tasking.theater} />
    </>
  )) as Blob;

  composer.add(blob, { x: 4, y: 0 });
  composer.add(mapImg, {
    x: KNEEBOARD_WIDTH - width,
    y: KNEEBOARD_HEIGHT - height,
  });

  return composer;
}
