import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Box } from "@mui/material";
import { parse } from "csv-parse";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import DataTable from "../DataTable";
import FileDropzone from "../Dropzone";
import Flex from "../Flex";

type Props = {
  className?: string;
  onSubmit: <T>(rows: T[]) => Promise<void>;
  onError: (error: Error) => void;
  additionalActions?: React.ReactNode;
  children?: React.ReactNode;
  style: any;
  previewStyle: any;
};

function CSVImport({
  className,
  onSubmit,
  onError,
  children,
  additionalActions,
  style = {},
  previewStyle = {},
}: Props) {
  const [busy, setBusy] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);

  const handleSubmit = () => {
    setBusy(true);

    return onSubmit(rows)
      .catch(onError)
      .finally(() => {
        setBusy(false);
      });
  };

  const handleFileDrop = async (files: File[]) => {
    const file = _.first(files);

    if (!file) {
      return;
    }

    const txt = await file.text();

    parse(txt, { columns: true, cast: true }, (err, output) => {
      if (err) {
        onError(err);
        return;
      }

      setRows(output);
    });
  };

  return (
    <div className={className} style={style}>
      <Box my={2}>
        {children}
        <Box my={2}>
          {rows.length === 0 && (
            <FileDropzone
              text="Drag and Drop your .csv file here"
              onFileDrop={handleFileDrop}
            />
          )}
          <Box style={previewStyle}>
            {rows.length > 0 && (
              <DataTable
                columns={_.map(_.keys(_.first(rows)), (c) => {
                  return {
                    label: c,
                    value: c,
                  };
                })}
                rows={rows}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box marginTop={2}>
        <Flex wide between>
          <LoadingButton
            loading={busy}
            disabled={rows.length === 0}
            variant="contained"
            onClick={handleSubmit}
          >
            Import
          </LoadingButton>
          {additionalActions}
        </Flex>
      </Box>
    </div>
  );
}

export default styled(CSVImport).attrs({
  className: CSVImport.name,
})`
  tbody td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  tbody td:last-child {
    max-width: 80px;
  }
`;
