import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import {
  useBlurFeature,
  useNavPointController,
} from "../../contexts/TaskingMapContext";
import NavPointFeature from "../../lib/models/NavPointFeature";
import Flex from "../Flex";
import NavTargetPointsButton from "../NavigationButtons/NavTargetPointsButton";
import PlanningModeButton from "../NavigationButtons/PlanningModeButton";

type Props = {
  className?: string;
  feature: NavPointFeature;
};

function NavPointPlanningTools({ className, feature }: Props) {
  const [form, setForm] = React.useState({
    textComment: feature?.textComment(),
  });
  const blur = useBlurFeature();

  const renderInput = feature?.textComment();
  const nav = useNavPointController();

  const handleBlur = () => {
    nav.updateNavPointText(
      feature?.group(),
      feature?.index(),
      form.textComment
    );
  };

  const handleDelete = () => {
    nav.deleteNavPoint(feature?.group(), feature?.index());
    blur();
  };

  React.useEffect(() => {
    setForm({
      textComment: feature?.textComment(),
    });
  }, [feature]);

  return (
    <div className={className}>
      {renderInput && (
        <Box mb={1}>
          <Box py={1}>
            <Flex wide align>
              <Button
                onClick={handleDelete}
                size="small"
                variant="outlined"
                color="primary"
              >
                Delete
              </Button>
            </Flex>
          </Box>
          <FormGroup>
            <FormControl>
              <FormLabel>Text Comment</FormLabel>
              <TextField
                multiline
                onBlur={handleBlur}
                value={form.textComment}
                onChange={(e) =>
                  setForm({
                    ...form,
                    textComment: e.target.value,
                  })
                }
              />
            </FormControl>
          </FormGroup>
        </Box>
      )}

      <PlanningModeButton />
      <NavTargetPointsButton groupName={feature.group()} />
    </div>
  );
}

export default styled(NavPointPlanningTools).attrs({
  className: NavPointPlanningTools.name,
})``;
