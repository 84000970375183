import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, AlertTitle, Button } from "@mui/material";
import { parse } from "query-string";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useUser } from "../../contexts/UserContext";
import {
  useGetVolumeRecordByCode,
  useRedeemVolumeCode,
} from "../../hooks/services";
import DiscordLoginButton from "../DiscordLoginButton";
import Flex from "../Flex";
import Page from "../Page";
import Section from "../Section";

type Props = {
  className?: string;
};

function RedeemSub({ className }: Props) {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [redeeming, setRedeeming] = React.useState(false);
  const [redeemSuccess, setRedeemSuccess] = React.useState(false);
  const { currentUser, loading: isLoadingUser } = useUser();
  const { code } = parse(location.search);
  const { success, error } = useNotification();

  const invalidCode = !code || Array.isArray(code);

  const { data: record, isLoading: isLoadingRecord } = useGetVolumeRecordByCode(
    code as string
  );
  const redeem = useRedeemVolumeCode();

  const handleRedeem = React.useCallback(() => {
    setRedeeming(true);
    redeem(record?.oneTimeCode)
      .then(() => {
        setRedeemSuccess(true);
        success("License redeemed successfully");
      })
      .catch((e) => {
        switch (e.message) {
          case "already_redeemed":
            return setErrorMessage("This code has already been redeemed");
          case "not_found":
            return setErrorMessage("Invalid code");

          case "already_subscribed":
            return setErrorMessage(
              "You already have a subscription. Please cancel it first."
            );
          default:
            break;
        }
        error(e.message);
      })
      .finally(() => {
        setRedeeming(false);
      });
  }, [record]);

  const isAlreadyRedeemed = record && record.redeemedAt;
  const isRedeemable = record && !isAlreadyRedeemed;
  const isRecordFound = record && !isLoadingRecord;

  return (
    <Page className={className}>
      <Section title="Redeem Group Subscription">
        {invalidCode && <Alert severity="error">Invalid code</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {!isLoadingRecord && !record && (
          <Alert severity="error">Code not found</Alert>
        )}
        <Flex wide center>
          <div>
            {isRecordFound && isRedeemable && (
              <p>
                <b>{record?.createdByDisplayName} </b>
                has invited you to redeem a group subscription license for Frag
                Orders
              </p>
            )}

            {!isLoadingUser && !currentUser && <DiscordLoginButton />}
            {isRedeemable && !redeemSuccess && (
              <Flex wide center>
                <LoadingButton
                  loading={redeeming}
                  onClick={handleRedeem}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Redeem License
                </LoadingButton>
              </Flex>
            )}

            {redeemSuccess && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                License redeemed successfully. You can now access Frag Orders
                Premium features.
                <Flex center>
                  <Button href={process.env.FRAG_ORDER_PUBLIC_HOST}>
                    Go To Frag Orders
                  </Button>
                  <Button target="_blank" href="https://docs.fragorders.com">
                    Read the Docs
                  </Button>
                </Flex>
              </Alert>
            )}

            {!redeemSuccess && isAlreadyRedeemed && (
              <Alert severity="info">
                This code has already been redeemed.
              </Alert>
            )}
          </div>
        </Flex>
      </Section>
    </Page>
  );
}

export default styled(RedeemSub).attrs({ className: RedeemSub.name })`
  width: 100%;
`;
