import styled from "styled-components";
import StateButton from "../FragOrderNavigation/StateButton";

const NoShadowStateButton = styled(StateButton)`
  &.MuiIconButton-root {
    box-shadow: none;
    margin-right: 8px;
  }

  border: 1px solid gray !important;
`;

export default NoShadowStateButton;
