import _ from "lodash";
import { Geometry, LineString, Point } from "ol/geom";
import { useContext } from "react";
import { TaskingMapContext } from "../../contexts/TaskingMapContext";
import {
  Theater,
  convertDCSToLatLong,
  toDrawingRectangle,
} from "../../lib/map";
import {
  DCSDrawingObject,
  DCSDrawingPrimitive,
  TaskingMapLayerName,
} from "../../lib/types";

import { circular } from "ol/geom/Polygon";
import { Fill, Stroke, Style, Text as TextStyle } from "ol/style";
import * as React from "react";
import MapLayer from "./MapLayer";

type Props = {
  drawings: DCSDrawingObject[];
  theater: Theater;
};

const pathStyle = (feature) => {
  const color = "#" + _.slice(feature.get("colorString"), 2).join("");
  const fill = "#" + _.slice(feature.get("fillColorString"), 2).join("");
  const dashed = feature.get("style") === "dash";

  const primitiveType = feature.get("primitiveType");

  const styles = [];

  styles.push(
    new Style({
      // image: new Circle({
      //   radius: 5,
      //   fill: new Fill({
      //     color: "orange",
      //   }),
      // }),
      stroke: new Stroke({
        color,
        width: feature.get("thickness") / 2,
        lineDash: dashed ? [10, 10] : null,
      }),
      fill: new Fill({
        color: fill,
      }),
    })
  );

  if (primitiveType === DCSDrawingPrimitive.TextBox) {
    const text = feature.get("text").toString();
    const border = feature.get("borderThickness");
    const fontSize = feature.get("fontSize");

    styles.push(
      new Style({
        text: new TextStyle({
          overflow: true,
          padding: [4, 4, 4, 4],
          font: `${fontSize}px Roboto, sans-serif`,
          placement: "point",
          text,
          fill: new Fill({ color }),
          backgroundFill: new Fill({
            color: fill,
          }),
          backgroundStroke:
            border > 0
              ? new Stroke({
                  color,
                  width: border / 2,
                })
              : null,
          rotation: feature.get("angle") * (Math.PI / 180),
          textAlign: "left",
        }),
      })
    );
  }
  return styles;
};

export const MizDrawingLayer = ({ drawings, theater }: Props) => {
  const features = _.map(drawings, (d) => ({
    ...d,
    geometry: typeToGeo(theater, d),
    unselectable: true,
  }));

  return (
    <MapLayer
      name={TaskingMapLayerName.DrawingObjects}
      displayName={TaskingMapLayerName.DrawingObjects}
      foLayerID="miz-drawing-layer"
      filterTag={TaskingMapLayerName.DrawingObjects}
      features={features}
      hideable
      style={pathStyle}
    />
  );
};

export default function WrappedMizDrawingLayer() {
  const {
    state: { tasking },
  } = useContext(TaskingMapContext);

  return (
    <MizDrawingLayer drawings={tasking.drawingData} theater={tasking.theater} />
  );
}

function toLinePoints(theater: Theater, d: DCSDrawingObject) {
  // const origin = convertDCSToLatLong(theater, { x: d.mapX, y: d.mapY });
  const points = _.map(d.points, (p) => {
    const { lat, long } = convertDCSToLatLong(theater, {
      x: p.x + d.mapX,
      y: p.y + d.mapY,
    });
    const coords = [long, lat];
    return coords;
  });

  return points;
}

function toPolyPoints(theater: Theater, d: DCSDrawingObject) {
  const origin = convertDCSToLatLong(theater, { x: d.mapX, y: d.mapY });

  let geometry;

  switch (d.polygonMode) {
    case "rect":
      geometry = toDrawingRectangle(theater, d);

      geometry.rotate(
        -d.angle * (Math.PI / 180),
        geometry.getInteriorPoint().getCoordinates()
      );
      break;

    case "circle":
      geometry = circular([origin.long, origin.lat], d.radius);
      break;

    case "oval":
      geometry = circular([origin.long, origin.lat], d.r1);
      geometry.scale(d.r2 / d.r1, 1);
      geometry.rotate(
        -d.angle * (Math.PI / 180),
        geometry.getInteriorPoint().getCoordinates()
      );

      break;
  }

  return geometry;
}

function toPoint(theater, d: DCSDrawingObject) {
  const { long, lat } = convertDCSToLatLong(theater, {
    x: d.mapX,
    y: d.mapY,
  });

  return new Point([long, lat]);
}

export function typeToGeo(theater: Theater, d: DCSDrawingObject): Geometry {
  switch (d.primitiveType) {
    case DCSDrawingPrimitive.Line:
      return new LineString(toLinePoints(theater, d));

    case DCSDrawingPrimitive.Polygon:
      return toPolyPoints(theater, d);

    case DCSDrawingPrimitive.TextBox:
      return toPoint(theater, d);
  }
}
