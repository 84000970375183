import Tile from "ol/layer/Tile";
import { TileJSON } from "ol/source";
import { useEffect } from "react";
import { useMap } from "./MapCanvas";

export function createOSM() {
  const source = new TileJSON({
    url: `https://api.maptiler.com/maps/openstreetmap/tiles.json?key=${process.env.MAPTILER_KEY}`,
    tileSize: 512,
    crossOrigin: "anonymous",
  });

  const layer = new Tile({
    source,
  });

  return layer;
}

export default function OSMLayer() {
  const map = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    const layer = createOSM();

    layer.set("name", "Map");
    layer.set("hideable", true);

    map.addLayer(layer);
  }, [map]);

  return null;
}
