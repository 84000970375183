import Tile from "ol/layer/Tile";
import { TileJSON } from "ol/source";
import { useEffect } from "react";
import { useMap } from "./MapCanvas";

export default function TerrainLayer() {
  const map = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }
    const layer = new Tile({
      source: new TileJSON({
        url:
          "https://api.maptiler.com/tiles/hillshades/tiles.json?key=" +
          process.env.MAPTILER_KEY,
        tileSize: 256,
        crossOrigin: "anonymous",
      }),
    });

    layer.set("name", "Terrain");
    layer.set("hideable", true);
    map.addLayer(layer);
  }, [map]);

  return null;
}
