import {
  faArrowLeft,
  faArrowRight,
  faBroadcastTower,
  faCaretRight,
  faCheckCircle,
  faCrosshairs,
  faDraftingCompass,
  faFileImport,
  faMap,
  faSatelliteDish,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import * as React from "react";

export enum IconType {
  Satellite,
  Check,
  Map,
  Crosshairs,
  Drafting,
  Import,
  BroadCast,
  CaretRight,
  ArrowRight,
  ArrowLeft,
  Close,
}

function getIcon(icon: IconType) {
  switch (icon) {
    case IconType.Satellite:
      return faSatelliteDish;

    case IconType.Check:
      return faCheckCircle;

    case IconType.Map:
      return faMap;

    case IconType.Crosshairs:
      return faCrosshairs;

    case IconType.Drafting:
      return faDraftingCompass;

    case IconType.Import:
      return faFileImport;

    case IconType.BroadCast:
      return faBroadcastTower;

    case IconType.CaretRight:
      return faCaretRight;

    case IconType.ArrowRight:
      return faArrowRight;

    case IconType.ArrowLeft:
      return faArrowLeft;

    case IconType.Close:
      return faWindowClose;

    default:
      break;
  }
}

type Props = {
  icon: IconType;
  className?: string;
} & Omit<FontAwesomeIconProps, "icon">;

export default function Icon({ icon, className, ...rest }: Props) {
  return (
    <FontAwesomeIcon {...rest} className={className} icon={getIcon(icon)} />
  );
}
