import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { AirbasesKneeboardSettings } from "../../lib/models/Job";

type Props = {
  className?: string;
  onChange?: (opts: AirbasesKneeboardSettings) => void;
  settings: AirbasesKneeboardSettings;
};

function AirbaseNavCardSettings({ className, onChange, settings }: Props) {
  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showAirbaseFreqs}
            onChange={(ev) =>
              onChange({ ...settings, showAirbaseFreqs: ev.target.checked })
            }
          />
        }
        label="Display Airbase Frequencies"
      />
    </FormGroup>
  );
}

export default styled(AirbaseNavCardSettings).attrs({
  className: AirbaseNavCardSettings.name,
})``;
