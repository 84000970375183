import { createBrowserHistory } from "history";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";

const history = createBrowserHistory();

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App history={history} mode="admin" />);
