import { ButtonProps, IconButton } from "@mui/material";
import cx from "classnames";
import * as React from "react";
import styled, { css } from "styled-components";

import { forwardRef } from "react";

type Props = {
  className?: string;
  active?: boolean;
} & ButtonProps;

const StateButton = forwardRef<HTMLButtonElement, Props>(function StateButton(
  { active, className, ...rest }: Props,
  ref
) {
  return (
    <IconButton {...rest} ref={ref} className={cx({ active }, className)} />
  );
});

export const activeMixin = css`
  &.MuiIconButton-root {
    background-color: ${(props) => props.theme.palette.background.paper};
    margin-right: 24px;
    box-shadow: ${(props) => props.theme.shadows[15]};

    &:hover,
    &.active {
      & svg {
        color: ${(props) => props.theme.palette.primary.contrastText};
      }
      background-color: ${(props) => props.theme.palette.primary.dark};
    }

    &.active {
      & svg {
        color: ${(props) => props.theme.palette.secondary.contrastText};
      }
      background-color: ${(props) => props.theme.palette.secondary.main};
    }

    &.Mui-disabled {
      background-color: ${(props) => props.theme.palette.background.paper};
    }
  }
`;

export default styled(StateButton).attrs({ className: StateButton.name })`
  ${activeMixin}
`;
