import {
  Box,
  CircularProgress,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import Flex from "./Flex";

type Props = {
  className?: string;
  children?: any;
  title?: any;
  loading?: boolean;
  actions?: any;
} & PaperProps;

function Section({ className, children, title, loading, actions }: Props) {
  return (
    <Paper className={className}>
      <Flex wide>
        <Box style={{ width: "100%" }} p={3}>
          <Box style={{ padding: 0 }} marginBottom={2} p={0}>
            <Flex align wide between>
              <Typography fontSize={24} variant="h3">
                {title}
              </Typography>
              {actions}
            </Flex>
          </Box>
          {loading ? (
            <Flex wide center align>
              <CircularProgress />
            </Flex>
          ) : (
            children
          )}
        </Box>
      </Flex>
    </Paper>
  );
}

export default styled(Section).attrs({ className: Section.name })``;
