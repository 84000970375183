import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button } from "@mui/material";
import { saveAs } from "file-saver";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGetPublishStatus } from "../../contexts/PublishManagerContext";
import { useUpdateFragOrder } from "../../hooks/frag_orders";
import { useDownloadMiz } from "../../hooks/miz_file";
import { FragOrderDoc } from "../../lib/models/FragOrder";
import { MizFile } from "../../lib/models/MizFile";
import { MizMetadata } from "../../lib/tasking";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";
import MissionFileDrop from "../MissionFileDrop";
import Section from "../Section";
import Timestamp from "../Timestamp";
import MizFileStatistics from "./MizFileStatistics";

type Props = {
  className?: string;
  fragOrder: FragOrderDoc;
};

function MizFileDetails({ className, fragOrder }: Props) {
  const download = useDownloadMiz();
  const { data: publishStatus } = useGetPublishStatus(fragOrder.id);
  const [isUpdateMode, setUpdateMode] = React.useState(false);
  const [newMiz, setNewMiz] = React.useState<MizFile>(null);
  const [updateFetching, setUpdateFetching] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const { success, error } = useNotification();
  const update = useUpdateFragOrder();

  const handleDownload = async (e) => {
    e.preventDefault();
    setDownloading(true);

    const zip = await download(fragOrder);

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => {
        saveAs(blob, fragOrder.mizFileName);
        success("Mission file downloaded.");
      })
      .finally(() => setDownloading(false));
  };

  const handleUpdateClicked = () => {
    setUpdateMode(true);
  };

  const handleMizDropped = (file: File, metadata: MizMetadata) => {
    setNewMiz({ file, metadata });
  };

  const handleUpdateMiz = () => {
    setUpdateFetching(true);
    const next: FragOrderDoc = {
      ...fragOrder,
      mizFileName: newMiz.file.name,
      mizFileSize: newMiz.file.size,
      mizMetadata: newMiz.metadata,
    };

    update(next, _.map(publishStatus, "coalition"), newMiz)
      .then(() => success(`New Miz file ${newMiz.file.name}`))
      .catch((err) => error(err.message))
      .finally(() => {
        setUpdateMode(false);
        setUpdateFetching(false);
        setNewMiz(null);
      });
  };

  return (
    <Section title="Mission File Details" className={className}>
      <Box
        marginTop={2}
        marginBottom={2}
        style={{ borderBottom: "1px solid #b0b0b0" }}
      >
        <KeyValueTable
          columns={3}
          pairs={[
            { value: fragOrder?.id, key: "ID" },
            {
              value: <Timestamp time={fragOrder?.created_at_timestamp} />,
              key: "Created",
            },
            {
              value: fragOrder?.mizFileName,
              key: "Filename",
            },
          ]}
        />
      </Box>
      <Box marginBottom={2}>
        {!newMiz && isUpdateMode ? (
          <MissionFileDrop onDrop={handleMizDropped} />
        ) : (
          <MizFileStatistics
            miz={{
              metadata: fragOrder?.mizMetadata,
              file: {
                name: fragOrder?.mizFileName,
              } as File,
            }}
            diff={newMiz}
          />
        )}
      </Box>
      <Box marginBottom={2}>
        <Flex start>
          {isUpdateMode ? (
            <Flex align start>
              <LoadingButton
                loading={updateFetching}
                disabled={!newMiz}
                variant="contained"
                onClick={handleUpdateMiz}
              >
                Update Frag Order
              </LoadingButton>
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => {
                  setNewMiz(null);
                  setUpdateMode(false);
                }}
                variant="outlined"
              >
                Cancel
              </Button>

              <Alert style={{ marginLeft: 8 }} severity="warning">
                Changing the mission file for this Frag Order may invalidate
                existing plans
              </Alert>
            </Flex>
          ) : (
            <Button
              style={{ marginRight: 8 }}
              onClick={handleUpdateClicked}
              variant="outlined"
              type="button"
            >
              Update Mission File
            </Button>
          )}

          {!isUpdateMode && (
            <LoadingButton
              loading={downloading}
              onClick={handleDownload}
              variant="outlined"
              type="button"
            >
              Download Original
            </LoadingButton>
          )}
        </Flex>
      </Box>
    </Section>
  );
}

export default styled(MizFileDetails).attrs({
  className: MizFileDetails.name,
})``;
