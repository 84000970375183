const missionKey = "missionFile";
const missionNameKey = "missionName";

// https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //New Code
  return new Blob([ab], { type: mimeString });
}

export function saveMissionToLocalStorage(raw, name) {
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      try {
        localStorage.setItem(missionKey, reader.result as string);
        localStorage.setItem(missionNameKey, name);
      } catch (e) {
        // TODO: let the user know
        console.error(
          "Mission file is too big, could not save to browser storage"
        );
      }
    },
    false
  );

  reader.readAsDataURL(raw);
}

export function loadMissionFromLocalStorage() {
  const dataUrl = localStorage.getItem(missionKey);

  if (!dataUrl) {
    return null;
  }

  return new File(
    [dataURItoBlob(dataUrl)],
    localStorage.getItem(missionNameKey),
    {
      type: "application/zip",
    }
  );
}

export function removeMissionFromLocalStorage() {
  localStorage.removeItem(missionKey);
  localStorage.removeItem(missionNameKey);
}

export function copyToClipboard(text: string) {
  // Create a textarea element in which the text of the code will be copied.
  // Set the value of the textarea, select it to set it as the active element,
  // then copy the value of that element to the clipboard.
  // It's 2017 and this is still the best way to copy text on button click...

  const selection = document.getSelection();
  const selectionString = selection.toString();
  // if user has selected text, save that to state so it can be reselected
  if (selectionString !== "") {
    this.setState({
      selectedRange: selection.getRangeAt(0),
    });
  }

  const txtArea = document.createElement("textarea");
  // Safari doesn't allow for assigning an object literal to `style`.
  // @ts-ignore
  txtArea.class = "hidden-textarea";
  // Make sure the code ends in a newline to execute all the commands in multiline code.
  txtArea.value = `${text}\n`;

  document.body.appendChild(txtArea);

  txtArea.select();

  document.execCommand("copy");

  document.body.removeChild(txtArea);
}
