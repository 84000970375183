import _ from "lodash";
import * as lua from "../../vendor/lua-in-js";
import { GroupWithPlan } from "../contexts/PlanManagerContext";
import { Modules } from "./data/modules";
import {
  forEachGroup,
  formatRadioData,
  get,
  setChannelPresetsForRadios,
  setChannelPresetsForUnit,
  setFreqForGroup,
} from "./mission";
import { RadioChannelOverride } from "./models/Plan";
import { RadioPresetConfig } from "./models/RadioPresetConfig";
import {
  Coalition,
  DCSGroupData,
  DCSRadioModulation,
  FreqBand,
  LuaTable,
  RadioInteractable,
  UnitCategory,
} from "./types";

export enum InputType {
  Dynamic = "Dynamic",
  Automated = "Automated",
  Manual = "Manual",
}

export type Channel = {
  type: InputType;
  value: string;
  band: FreqBand;
  name: string;
  number: number;
};

export enum DynamicChannel {
  ATC = "dynamic_atc",
}

export type DCSPreset = {
  modulations: number[];
  channels: number[];
  channelsNames: string[];
};

export const setRadioPresetForGroup = (
  coalition: Coalition,
  cat: UnitCategory,
  groupName: string,
  presets: DCSPreset[]
) => (mission: LuaTable) => {
  forEachGroup(mission, coalition, cat, (group) => {
    const gName = get(group, ["name"]);

    if (gName === groupName) {
      setFreqForGroup(group, presets);
      const units: LuaTable = get(group, ["units"]);
      for (const unit of units.numValues) {
        if (!unit) {
          continue;
        }

        setChannelPresetsForUnit(unit, presets);
      }
    }
  });
};

export const channelsToPresets = (
  chans: Channel[],
  modules: Modules,
  group: DCSGroupData,
  interactable: RadioInteractable[]
): DCSPreset[] => {
  const uhfChans = _.filter(chans, (c) => c.band === FreqBand.UHF);
  const vhfChans = _.filter(
    chans,
    (c) => c.band === FreqBand.VHF || c.band === FreqBand.VHF_FM
  );
  const type = _.get(group, ["units", 0, "type"]);
  if (!type) {
    console.warn("no type");
    return;
  }
  const module = modules[type];

  if (!module) {
    console.warn("no module: " + type);
    return;
  }

  const firstUHFRadio = _.findIndex(module.radios, (r) =>
    _.includes(r.bands, FreqBand.UHF)
  );

  const presets = [[], []];

  for (let index = 0; index < uhfChans.length; index++) {
    const c = uhfChans[index];

    if (c.type === InputType.Dynamic) {
      if (c.value === DynamicChannel.ATC) {
        const base = _.find(interactable, (b) => {
          if (group.airdromeId && !group.linkedUnitId) {
            return _.isEqual(b.AirdromeId, group.airdromeId);
          }

          if (group.linkedUnitId) {
            return _.isEqual(b.UnitID, group.linkedUnitId);
          }

          return false;
        });

        if (base) {
          c.value = base[c.band];
        }
      }
    }

    if (c.type === InputType.Automated) {
      const target = _.find(interactable, { name: c.name });
      const freq = target[c.band];

      c.value = freq;
    }
  }

  const uhfVals: { value: string; band: FreqBand }[] = _.map(uhfChans, (c) => ({
    value: c.value,
    band: c.band,
  }));

  const vhfVals: { value: string; band: FreqBand }[] = _.map(vhfChans, (c) => ({
    value: c.value,
    band: c.band,
  }));

  if (firstUHFRadio > -1) {
    presets[firstUHFRadio] = uhfVals;
  }

  const lastVHFRadio = _.findLastIndex(module.radios, (r) =>
    _.includes(r.bands, FreqBand.VHF)
  );

  if (lastVHFRadio > -1) {
    presets[lastVHFRadio] = vhfVals;
  }

  const out: DCSPreset[] = [
    { modulations: [], channels: [], channelsNames: [] },
    { modulations: [], channels: [], channelsNames: [] },
  ];

  _.each(presets, (chans, p) => {
    _.each(chans, (chan, c) => {
      out[p].modulations[c] =
        chan.band === FreqBand.VHF_FM
          ? DCSRadioModulation.FM
          : DCSRadioModulation.AM;
      out[p].channels[c] = parseFloat(chan.value);
    });
  });

  return out;
};

interface Stringable {
  toString(): string;
}

export function formatFreq(freq: Stringable) {
  if (!freq) {
    return "";
  }
  const [int, dec] = freq.toString().split(".");

  return `${int}.${_.padEnd(dec, 3, "0")}`;
}

export function overridesToPresets(ovr: RadioChannelOverride[][]): DCSPreset[] {
  const radioCfg: DCSPreset[] = [];

  _.each(ovr, (list, i) => {
    const p: DCSPreset = { channels: [], modulations: [], channelsNames: [] };
    _.each(list, (o, j) => {
      const f = parseFloat(o.frequency);
      if (_.isNaN(f)) {
        console.error(new Error(`invalid float for frequency: ${o.frequency}`));
        return;
      }
      p.channels[j] = f;
      p.modulations[j] = o.modulation;
      p.channelsNames[j] = o.description;
    });
    radioCfg[i] = p;
  });

  return radioCfg;
}

// NOT USED RN
export function setRadio(
  unit: LuaTable,
  overrides: RadioChannelOverride[],
  radioIndex: number
) {
  const presets = _.get(overridesToPresets([overrides]), 0);
  let radio = get(unit, ["Radio", radioIndex + 1]);
  if (!radio) {
    radio = new lua.Table({
      channels: new lua.Table(),
      modulations: new lua.Table(),
      channelsNames: new lua.Table(),
    }) as LuaTable;
    unit.strValues.Radio = radio;
  }

  for (let j = 0; j < presets.channels.length + 1; j++) {
    const chanIdx = j + 1;

    radio.strValues.channels.numValues[chanIdx] = presets.channels[j];

    if (radio.strValues.modulations) {
      radio.strValues.modulations.numValues[chanIdx] = presets.modulations[j];
    }

    if (radio.strValues.channelsNames) {
      radio.strValues.channelsNames.numValues[chanIdx] =
        presets.channelsNames[j];
    }
  }
}

export function formatTACAN(tacan: string) {
  const re = /(.*)(.)$/g;
  const match = re.exec(tacan);
  const chan = match[1];
  const low = parseInt(chan);
  const high = low + 63;
  const band = match[2];

  return _.isNaN(low) || _.isNaN(high) ? "N/A" : `${low}/${high}${band}`;
}

// We use the exsiting lua editing machinery to figure out what *will* happen
// when the job is run. The lua editing is the source of truth for this.
export function predictRadioPresets(
  group: GroupWithPlan,
  presets: RadioPresetConfig
): RadioChannelOverride[][] {
  const unit = new lua.Table({}) as LuaTable;

  if (!presets) {
    return [[]];
  }

  const formatted = [
    presets?.UHF,
    presets?.VHF,
    presets?.["V/UHF"],
    presets.FM,
  ];
  const p = overridesToPresets(formatted);

  const type = _.first(group.units).type;
  // @ts-ignore
  unit.strValues.type = type;

  setChannelPresetsForRadios(unit, p);

  const out: RadioChannelOverride[][] = [];
  const d = formatRadioData(unit);

  _.each(d, (v, i) => {
    out[i] = [];
    _.each(v, (c, j) => {
      out[i][j] = {
        frequency: c?.frequency?.toString(),
        description: c.name,
        modulation: c.modulation,
      };
    });
  });

  return out;
}
