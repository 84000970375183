import _ from "lodash";
import * as React from "react";
import { createContext } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { PlanItem, PlanItemDoc, PlanItemType } from "../lib/models/PlanItem";
import PlanManagerV2 from "../lib/services/PlanManagerV2/PlanManagerV2";
import PlanNavPointManager from "../lib/services/PlanManagerV2/PlanNavPointManager";
import PlanWaypointManager from "../lib/services/PlanManagerV2/PlanWaypointManager";
import { useUser } from "./UserContext";

const PlanManagerV2Context = createContext<{
  manager: PlanManagerV2;
  waypointManager: PlanWaypointManager;
  navPointManager: PlanNavPointManager;
}>(null);

export const PlanManagerV2Provider = ({
  manager,
  waypointManager,
  navPointManager,
  children,
}: {
  manager: PlanManagerV2;
  waypointManager: PlanWaypointManager;
  navPointManager: PlanNavPointManager;
  children: React.ReactNode;
}) => {
  return (
    <PlanManagerV2Context.Provider
      value={{ manager, waypointManager, navPointManager }}
    >
      {children}
    </PlanManagerV2Context.Provider>
  );
};

export const usePlanManagerV2 = () => {
  const ctx = React.useContext(PlanManagerV2Context);

  if (!ctx) {
    throw new Error("PlanManagerV2Context not found");
  }

  return ctx.manager;
};

export function useWaypointManager() {
  const mgr = React.useContext(PlanManagerV2Context).waypointManager;
  if (!mgr) {
    throw new Error("Waypoint manager not available");
  }
  return mgr;
}

export function useNavPointManager() {
  const mgr = React.useContext(PlanManagerV2Context).navPointManager;
  if (!mgr) {
    throw new Error("NavPoint manager not available");
  }
  return mgr;
}

const PLAN_ITEMS_QUERY_KEY = "planItems";

export const useAddPlanItem = (manifestID: string) => {
  const pmV2 = usePlanManagerV2();
  const qc = useQueryClient();

  const { currentUser } = useUser();

  return React.useCallback(
    async (item: PlanItem) => {
      await pmV2.add(currentUser, item);

      return qc.invalidateQueries([PLAN_ITEMS_QUERY_KEY, manifestID]);
    },
    [pmV2, manifestID]
  );
};

export const useUpdatePlanItem = (manifestID: string) => {
  const pmV2 = usePlanManagerV2();
  const qc = useQueryClient();
  return React.useCallback(
    async (item: PlanItemDoc) => {
      await pmV2.update(item);

      return qc.invalidateQueries([PLAN_ITEMS_QUERY_KEY, manifestID]);
    },
    [pmV2, manifestID]
  );
};

export const useUpsertPlanItem = (manifestID: string) => {
  const { data: items, isLoading, error } = useListPlanItems(manifestID);
  const add = useAddPlanItem(manifestID);
  const update = useUpdatePlanItem(manifestID);

  return React.useCallback(
    async (item: PlanItemDoc) => {
      const existing = _.find(items, {
        group: item.group,
        uniqID: item.uniqID,
        type: item.type,
      });

      if (existing) {
        return update({ ...existing, ...item });
      } else {
        return add(item);
      }
    },
    [add, update, isLoading, error]
  );
};

export const useListPlanItems = (
  manifestID: string,
  opts?: UseQueryOptions
) => {
  const pmV2 = usePlanManagerV2();
  return useQuery<PlanItem[]>(
    [PLAN_ITEMS_QUERY_KEY, manifestID],
    async () => {
      if (!manifestID) {
        return [];
      }
      return pmV2.list(manifestID);
    },
    opts as any
  );
};

export const useListPlanItemsByGroup = (manifestID: string, group?: string) => {
  const { data: d, ...rest } = useListPlanItems(manifestID);

  const data = _.filter(d, { group }) as PlanItemDoc[];

  return {
    data,
    ...rest,
  };
};

export const useGetPlanItem = (
  manifestID: string,
  groupName: string,
  type: PlanItemType
) => {
  const { data: d, ...rest } = useListPlanItems(manifestID);

  const data = _.find(d, { group: groupName, type: type });

  return {
    data,
    ...rest,
  };
};

export function isPermissionError(msg: string) {
  return _.includes(msg, "Missing or insufficient permissions");
}
