import { FormGroup, FormHelperText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import styled from "styled-components";
import { PublishOpts, defaultPublishOpts } from "../lib/models/PublishManifest";
import Form from "./Form";
import FormCheckbox from "./FormCheckbox";

export type FormState = PublishOpts & {
  publishRed: boolean;
  publishBlue: boolean;
  publishNeutral: boolean;
};

type Props = {
  className?: string;
  onSubmit: (opts: PublishOpts) => void;
  children?: any;
  initialState?: FormState;
};

function PublishOptionsForm({
  className,
  onSubmit,
  children,
  initialState,
}: Props) {
  const init = initialState || defaultPublishOpts();

  return (
    <div className={className}>
      <Form initialState={init} onSubmit={onSubmit}>
        <FormGroup>
          <FormCheckbox
            label="Show units marked as 'Hidden'"
            name="showHidden"
          />
          <FormHelperText>
            Groups marked as "Hidden" in the miz file will be visible in the
            Frag Order
          </FormHelperText>
        </FormGroup>
        <FormGroup>
          <FormCheckbox label="Show OPFOR Groups" name="showGroups" />
          <FormHelperText>
            Show enemy groups on the Frag Order map
          </FormHelperText>
        </FormGroup>
        {/* <FormGroup>
          <FormCheckbox 
            label="Show individual OPFOR units"
            name="showIndividualUnits"
          />
          <FormHelperText>
            Show individual enemy vehicles on the Frag Order map.
          </FormHelperText>
        </FormGroup> */}
        <FormGroup>
          <FormCheckbox
            label="Show groups marked as 'Hidden on MFD'"
            name="showHiddenOnMFD"
          />
          <FormHelperText>
            Groups marked as "Hidden on MFD" in the miz file will be visible in
            the Frag Order
          </FormHelperText>
        </FormGroup>
        <FormGroup>
          <FormCheckbox label="Show trigger zones" name="showZones" />
          <FormHelperText>
            Zones in the miz file will be visible on the Frag Order
          </FormHelperText>
        </FormGroup>
        <FormGroup>
          <FormCheckbox
            label="Show trigger zones marked as 'Hidden'"
            name="showHiddenZones"
          />
          <FormHelperText>
            Zones in the miz file marked as "Hidden" will appear in the Frag
            Order
          </FormHelperText>
        </FormGroup>
        <FormGroup>
          <FormCheckbox label="Show drawing objects" name="showDrawing" />
          <FormHelperText>Show miz file drawings.</FormHelperText>
          <Box paddingLeft={4}>
            <FormCheckbox label="Author" name="showDrawingLayerAuthor" />
            <FormCheckbox label="Common" name="showDrawingLayerCommon" />
            <FormCheckbox label="Coalition" name="showDrawingLayerCoalition" />
          </Box>
          <FormHelperText>
            <Typography component="span" fontWeight={600}>
              Note: Only certain types of drawing objects are currently
              supported:
              <br />
              Disk, Line, Polygon, Text
            </Typography>
          </FormHelperText>
        </FormGroup>
        <FormGroup>
          <FormCheckbox
            label="Show friendly groups"
            name="showFriendlyGroups"
          />
          <FormHelperText>
            Groups on friendly coalitions will be visible. Example: BLUE players
            cannot see RED units
          </FormHelperText>
        </FormGroup>
        <FormGroup>
          <FormCheckbox label="Show OPFOR statics" name="showOpforStatics" />
          <FormHelperText>Show enemy static objects</FormHelperText>
        </FormGroup>
        <FormGroup>
          <div>Published Coalitions</div>
          <FormHelperText>
            Publish separate Frag Orders for different coalitions
          </FormHelperText>
          <Box>
            <FormCheckbox label="Blue" name="publishBlue" />
            <FormCheckbox label="Red" name="publishRed" />
            {/* <FormCheckbox label="Neutral" name="publishNeutral" /> */}
          </Box>
        </FormGroup>
        {children}
      </Form>
    </div>
  );
}

export default styled(PublishOptionsForm).attrs({
  className: PublishOptionsForm.name,
})`
  .MuiFormGroup-root {
    margin-bottom: 32px;
  }

  .MuiFormHelperText-root {
    font-size: 14px;
    color: ${(props) => props.theme.palette.text.primary};
  }
`;
