import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    background: {
      default: "#e3e3e3",
    },
    // mode: "dark",
  },
});
