import ClipboardIcon from "@mui/icons-material/CopyAll";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import { Feature, Overlay } from "ol";
import Map from "ol/Map";
import { EventsKey } from "ol/events";
import { LineString, Point, Polygon } from "ol/geom";
import * as React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import {
  DrawMode,
  TaskingMapActionTypes,
  TaskingMapContext,
  userCanPlanGroup,
} from "../../contexts/TaskingMapContext";
import { getFeatureByName } from "../../lib/layers/map";
import {
  formatLatLong,
  formatLatLong2,
  LatLongDecimalArray,
  metersToFeet,
} from "../../lib/map";
import { getTerrainHeightAtCoords } from "../../lib/measurements";
import {
  FragOrderMapFeature,
  NewMapFeature,
} from "../../lib/models/FragOrderMapFeature";
import NavPointFeature from "../../lib/models/NavPointFeature";
import WaypointFeature from "../../lib/models/WaypointFeature";
import { FeatureClass, TaskingMapLayerName } from "../../lib/types";
import Flex from "../Flex";
import NavPointPlanningTools from "../Planning/NavPointPlanningTools";
import PlanningHopupTools from "../Planning/PlanningHopupTools";

type Props = {
  className?: string;
  mapRef: React.MutableRefObject<Map>;
};

const overlay = document.createElement("div");
document.body.appendChild(overlay);

export default function FeatureHopup({ className, mapRef }: Props) {
  const overlayRef = React.useRef<Overlay>();
  const mapClickHanderRef = React.useRef<EventsKey>();
  const { state, dispatch } = React.useContext(TaskingMapContext);

  const [featureData, setFeatureData] = React.useState<FragOrderMapFeature>(
    null
  );

  const visible = !!state.focusedFeature;
  const pageableFeaures =
    _.filter(state.focusedFeature?.pageableFeatures, (f) =>
      f.feature.selectable()
    ) || [];

  const featureIndex = _.findIndex(
    pageableFeaures,
    (f) =>
      f.layerName === state.focusedFeature?.layerName &&
      f.feature.name() === state.focusedFeature.name
  );
  // Battling a stale closure here
  const drawMode = React.useRef(state.drawMode);
  React.useEffect(() => {
    drawMode.current = state.drawMode;
  }, [state.drawMode]);

  const isPlannableGroupSymbol = _.includes(
    state.userPlannableGroups,
    state?.focusedFeature?.name
  );

  const isWaypoint = featureData?.class() === FeatureClass.Waypoint;
  const isNavPoint = featureData?.class() === FeatureClass.NavPoint;

  const canPlan = userCanPlanGroup(
    isWaypoint || isNavPoint
      ? (featureData as WaypointFeature)?.group()
      : featureData?.name()
  );

  const isPlannableWaypoint = isWaypoint && canPlan;
  const isPlannableNavPoint = isNavPoint && canPlan;

  const renderPlanningTools =
    featureData &&
    state.planningMode &&
    (isPlannableGroupSymbol || isPlannableWaypoint);

  const renderNavPointPlanningTools =
    featureData && state.planningMode && isPlannableNavPoint;

  React.useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const map = mapRef.current;

    if (!overlayRef.current) {
      const ovr = new Overlay({
        element: overlay,
        stopEvent: true,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });

      map.addOverlay(ovr);
      overlayRef.current = ovr;
    }

    // We need to remake the click handler to avoid a stale closure
    // There needs to be an effect to remake the handler based on the internal closure state,
    // in this case state.waypointDropMode.
    // On state change, remove the old one and add the new one with the updated closure state.

    if (mapClickHanderRef.current) {
      map.un("click", mapClickHanderRef.current.listener);
    }

    const key = map.on("click", (e) => {
      const features = [];
      map.forEachFeatureAtPixel(
        e.pixel,
        (feature: Feature<Point | LineString | Polygon>, layer) => {
          if (!layer) {
            return;
          }
          const layerName = layer.get("name");

          if (!layerName) {
            return;
          }

          const f = {
            layerName,
            feature: NewMapFeature(feature as Feature<Point | LineString>),
          };

          if (f) {
            features.push(f);
          }
        }
      );

      if (drawMode.current !== DrawMode.None || state.waypointDropMode) {
        return null;
      }

      if (features.length > 0) {
        const f = _.find(features, (f) => f.feature.selectable());

        if (
          f?.layerName === TaskingMapLayerName.DrawingObjects ||
          !f?.layerName
        ) {
          return;
        }

        dispatch({
          type: TaskingMapActionTypes.focusFeature,
          layerName: f.layerName,
          name: f.feature.name(),
          feature: f.feature,
          otherFeatures: features,
        });
      } else {
        dispatch({
          type: TaskingMapActionTypes.blurFeature,
        });
      }

      mapClickHanderRef.current = key;

      return () => {
        map.un("click", mapClickHanderRef.current?.listener);
      };
    });
  }, [mapRef.current, state.waypointDropMode]);

  const handleFeaturePageClick = (index: number) => {
    const next = _.get(pageableFeaures, index);

    dispatch({
      type: TaskingMapActionTypes.focusFeature,
      layerName: next.layerName,
      name: next.feature.name(),
      otherFeatures: pageableFeaures,
    });
  };

  React.useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const map = mapRef.current;

    if (!state.focusedFeature) {
      overlayRef.current?.setPosition(null);
      return;
    }

    const feature = getFeatureByName(
      map,
      state.focusedFeature.layerName,
      state.focusedFeature.name,
      state.focusedFeature.featureClass
    );

    if (!feature) {
      return;
    }

    const coordinates = feature.coordinates();

    if (overlayRef.current) {
      overlayRef.current.setPosition(coordinates);
    }

    // Schooch the map up a bit so the popup doesn't disappear off the top of the screen
    const offsetY = -160;

    const view = map.getView();
    const targetPixel = map.getPixelFromCoordinate(coordinates);

    const offsetPixel = [targetPixel[0], targetPixel[1] + offsetY];

    const offsetCoordinates = map.getCoordinateFromPixel(offsetPixel);

    view.animate({
      center: offsetCoordinates,
      duration: 500,
    });

    setFeatureData(feature);
  }, [overlayRef.current, state.planningMode, state.focusedFeature]);

  const handleCopyCoords = () => {
    const coords = featureData?.coordinates();
    if (!coords) {
      return;
    }

    const text = formatLatLong([coords[0], coords[1]]);

    const elevation = getTerrainHeightAtCoords(mapRef.current, coords);

    text.push(`${metersToFeet(elevation).toFixed(0)} ft`);

    navigator.clipboard.writeText(text.join("\n"));
  };

  const coords: ReturnType<typeof formatLatLong2> | null = featureData
    ? formatLatLong2(featureData?.coordinates() as LatLongDecimalArray)
    : null;

  // https://github.com/openlayers/openlayers/issues/12848
  return createPortal(
    <div className={className}>
      <div
        style={{ display: visible ? "block" : "none" }}
        id="popup"
        className="ol-popup"
      >
        <a
          onClick={() => {
            dispatch({ type: TaskingMapActionTypes.blurFeature });
          }}
          href="#"
          id="popup-closer"
          className="ol-popup-closer"
        ></a>
        <div>
          <Flex align wide between>
            <Typography fontWeight={600}>{featureData?.title()}</Typography>
            <div
              style={{
                display:
                  !state.planningMode && pageableFeaures?.length > 0
                    ? "block"
                    : "none",
                // Turn off paging controls for now.
                // We need to .setPosition on the overlay to do it properly.
                // display: "none",
              }}
            >
              <IconButton
                disabled={featureIndex === 0}
                onClick={() => handleFeaturePageClick(featureIndex - 1)}
              >
                <NavigateBeforeIcon fontSize="small" />
              </IconButton>
              <IconButton
                disabled={featureIndex === pageableFeaures.length - 1}
                onClick={() => handleFeaturePageClick(featureIndex + 1)}
              >
                <NavigateNextIcon fontSize="small" />
              </IconButton>
            </div>
          </Flex>
        </div>
        <div>
          <Typography fontSize={12}>
            {state.focusedFeature?.layerName}
          </Typography>
        </div>
        {renderNavPointPlanningTools && (
          <NavPointPlanningTools feature={featureData as NavPointFeature} />
        )}

        {renderPlanningTools && (
          <Box my={1}>
            <PlanningHopupTools feature={featureData as WaypointFeature} />
          </Box>
        )}

        {!renderPlanningTools && !renderNavPointPlanningTools && (
          <Box my={1}>{featureData?.render()}</Box>
        )}

        <Flex align wide between>
          {featureData && (
            <div>
              <Typography fontSize={12}>
                {coords.lat} {coords.long}
              </Typography>
              <Typography fontSize={12}>{coords.mgrs}</Typography>
            </div>
          )}
          <CopyCoordsButton onClick={handleCopyCoords} />
        </Flex>
      </div>
    </div>,
    overlay
  );
}

const CopyCoordsButton = styled(({ onClick, className }) => (
  <div className={className}>
    <Tooltip title="Copy Coordinates">
      <IconButton onClick={onClick}>
        <ClipboardIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  </div>
))`
  position: absolute;
  right: 0;
  bottom: 0;
`;
