import DeleteIcon from "@mui/icons-material/Delete";
import GestureIcon from "@mui/icons-material/Gesture";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import * as React from "react";
import styled from "styled-components";
import {
  DrawMode,
  useSetDrawMode,
  useTaskingState,
} from "../../contexts/TaskingMapContext";
import useManageDrawings from "../../hooks/drawing";
import LargeTooltip from "../LargeTooltip";
import StateButton from "./StateButton";

type Props = {
  className?: string;
};

const DrawingTools = React.forwardRef<HTMLDivElement, Props>(function MyInput(
  props,
  ref
) {
  const {
    state: { drawMode },
  } = useTaskingState();
  const setDrawMode = useSetDrawMode();

  const handleDrawClick = (e, mode: DrawMode) => {
    e.stopPropagation();
    setDrawMode(mode);
  };

  const clearDrawings = useManageDrawings();

  return (
    <div {...props} ref={ref}>
      <LargeTooltip title="Freehand Draw">
        <StateButton
          active={drawMode && drawMode === DrawMode.Freehand}
          onClick={(e) => handleDrawClick(e, DrawMode.Freehand)}
        >
          <GestureIcon />
        </StateButton>
      </LargeTooltip>
      <LargeTooltip title="Measure">
        <StateButton
          active={drawMode && drawMode === DrawMode.Ruler}
          onClick={(e) => handleDrawClick(e, DrawMode.Ruler)}
        >
          <SquareFootIcon />
        </StateButton>
      </LargeTooltip>
      <LargeTooltip title="Drop Point">
        <StateButton
          active={drawMode && drawMode === DrawMode.PointDrop}
          onClick={(e) => handleDrawClick(e, DrawMode.PointDrop)}
        >
          <LocationOnIcon />
        </StateButton>
      </LargeTooltip>
      <LargeTooltip title="Clear All Drawings">
        <StateButton
          onClick={(e) => {
            e.stopPropagation();

            clearDrawings();
          }}
        >
          <DeleteIcon />
        </StateButton>
      </LargeTooltip>
    </div>
  );
});

export default styled(DrawingTools).attrs({ className: "DrawingTools" })`
  ${StateButton}, .MuiIconButton-root {
    height: 48px;
    width: 48px;
  }

  .MuiIconButton-root svg {
    font-size: 32px;
  }

  @media only screen and (max-width: 1300px) {
    display: none;
  }
`;
