import qs from "query-string";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { AppMode } from "../App";
import { AppContext } from "../components/AppStateProvider";
import LoadingPage from "../components/LoadingPage";
import Page from "../components/Page";
import { useUser } from "../contexts/UserContext";
import { AppRoutes } from "../lib/types";

export default function DiscordCallback() {
  const { mode } = React.useContext(AppContext);
  const { login } = useUser();
  const history = useHistory();
  const query = qs.parse(window.location.search);

  React.useEffect(() => {
    login(query.code as string)
      .then(() => {
        const next = localStorage.getItem("callbackNext");
        localStorage.removeItem("callbackNext");

        if (mode === AppMode.Admin) {
          history.push(next || AppRoutes.Home);
          return;
        }

        if (mode === AppMode.Public) {
          history.push(next);
        }
      })
      .catch(() => history.push(AppRoutes.Login));
  }, []);

  return (
    <Page title="">
      <LoadingPage />
    </Page>
  );
}
