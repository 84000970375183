import { Box, Paper, Typography } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import * as React from "react";
import styled from "styled-components";
import { useTaskingState } from "../../contexts/TaskingMapContext";
import { getTimeZoneOffset } from "../../lib/tasking";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";

type Props = {
  className?: string;
};

function MapTitle({ className }: Props) {
  const {
    state: { manifest, tasking },
  } = useTaskingState();

  let d = DateTime.fromISO(tasking.time.toString(), { zone: "utc" });
  const zulu = d
    .minus({ hour: getTimeZoneOffset(tasking.theater) })
    .toLocaleString(DateTime.TIME_24_SIMPLE);

  return (
    <div className={className}>
      <Paper>
        <Box padding={1}>
          <Flex align>
            <Box mr={4}>
              <Typography fontSize={24}>{manifest.title}</Typography>
            </Box>
            <KeyValueTable
              columns={3}
              pairs={[
                {
                  key: "Coalition",
                  value: (
                    <span style={{ color: manifest.coalition }}>
                      {_.capitalize(manifest.coalition)}
                    </span>
                  ),
                },
                {
                  key: "Theater",
                  value: _.upperCase(_.lowerCase(tasking.theater)),
                },
                {
                  key: "Start Time (zulu/local)",
                  value: `${zulu}/${d.toLocaleString(DateTime.TIME_24_SIMPLE)}`,
                },
              ]}
            />
          </Flex>
        </Box>
      </Paper>
    </div>
  );
}

export default styled(MapTitle).attrs({ className: MapTitle.name })`
  opacity: 0.8;

  ${KeyValueTable} {
    .MuiTableCell-root {
      padding: 4px 6px;
      border-bottom: 0;
    }
  }

  @media only screen and (max-width: 844px) {
    .KeyValueTable {
      display: none;
    }
  }
`;
