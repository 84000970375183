import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Modal } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useListKneeboards,
  useUploadKneeboard,
} from "../../contexts/KneeboardManagerContext";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGetPublishStatus } from "../../contexts/PublishManagerContext";
import { AssetType } from "../../lib/models/Asset";
import { FragOrderDoc } from "../../lib/models/FragOrder";
import AssetTable from "../Assets/AssetTable";
import DataTable from "../DataTable";
import FileDropzone from "../Dropzone";
import Flex from "../Flex";
import Icon, { IconType } from "../Icon";
import ModalContent from "../ModalContent";
import Section from "../Section";

type Props = {
  className?: string;
  fragOrder: FragOrderDoc;
};

// async function getImage(file: File): Promise<HTMLImageElement> {
//   return new Promise((accept) => {
//     const img = document.createElement("img");

//     const blob = URL.createObjectURL(file);
//     img.src = blob;
//     img.onload = function () {
//       accept(img);
//     };
//   });
// }

// https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio
// function greatestCommonDivisor(a, b) {
//   return b == 0 ? a : greatestCommonDivisor(b, a % b);
// }

// function aspectRatio(w, h) {
//   const r = greatestCommonDivisor(w, h);
//   const aspect = `${w / r}:${h / r}`;

//   return aspect;
// }

function KneeboardUpload({ className, fragOrder }: Props) {
  const { data: manifests } = useGetPublishStatus(fragOrder.id);
  const [uploading, setUploading] = React.useState(false);
  const [toBeUploaded, setToBeUploaded] = React.useState<File[]>([]);
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [validationErrors, setErrors] = React.useState({});
  const { success, error } = useNotification();
  const upload = useUploadKneeboard(fragOrder);
  const manifestID = manifests && manifests[0]?.id;
  const { data: assets } = useListKneeboards(manifestID);

  React.useEffect(() => {
    if (toBeUploaded.length === 0) {
      return;
    }

    (async () => {
      const next = { ...validationErrors };

      for (const kneeboard of toBeUploaded) {
        const existing = _.find(assets, { name: kneeboard.name });
        if (existing) {
          next[kneeboard.name] = "Duplicate name";
        } else {
          delete next[kneeboard.name];
        }
      }

      setErrors(next);
    })();
  }, [toBeUploaded]);

  const submittable =
    toBeUploaded.length > 0 && _.keys(validationErrors).length === 0;

  const renderTable = toBeUploaded.length > 0;

  const handleKneeboardAdd = (files: File[]) => {
    setToBeUploaded(_.uniqBy([...toBeUploaded, ...files], "name"));
  };

  const handleKneeboardRemove = (name: string) => {
    setToBeUploaded(_.filter(toBeUploaded, (f) => f.name !== name));
    const next = { ...validationErrors };

    delete next[name];
    setErrors(next);
  };

  const handleKneeboardSubmit = () => {
    setUploading(true);
    upload(manifests, toBeUploaded)
      .then(() => success("Kneeboards Uploaded successfully"))
      .catch((err) => {
        error(err.message);
        console.error(err);
      })
      .finally(() => {
        setUploading(false);
        setUploadOpen(false);
      });
  };

  const handleClose = () => {
    setUploadOpen(false);
    setToBeUploaded([]);
  };

  return (
    <div className={className}>
      <AssetTable assets={_.filter(assets, { type: AssetType.Kneeboard })} />

      <Modal
        open={uploadOpen}
        onClose={handleClose}
        aria-labelledby="kneeboard-upload-modal"
        aria-describedby="Upload a kneeboard to this Frag Order"
      >
        <ModalContent style={{ minWidth: "60vw" }} padding={2}>
          <Section title="Upload Kneeboards">
            <FileDropzone
              text="Drag and Drop a kneeboard to add it to the mission"
              onFileDrop={(...args) => {
                handleKneeboardAdd(_.flatten(args));
              }}
            />

            {renderTable && (
              <Box marginTop={2}>
                <DataTable
                  columns={[
                    { label: "Filename", value: "name" },
                    { label: "Type", value: "type" },
                    {
                      label: "Valid",
                      value: (f) => {
                        const error = validationErrors[f.name];

                        if (error) {
                          return (
                            <Flex align>
                              <Box marginRight={1}>
                                <Icon color="red" icon={IconType.Close} />
                              </Box>

                              <span style={{ color: "red" }}>{error}</span>
                            </Flex>
                          );
                        }

                        return <Icon color="green" icon={IconType.Check} />;
                      },
                    },
                    {
                      label: "",
                      value: (f) => (
                        <Button
                          onClick={() => handleKneeboardRemove(f.name)}
                          variant="text"
                          color="error"
                        >
                          Remove
                        </Button>
                      ),
                    },
                  ]}
                  rows={toBeUploaded}
                />
              </Box>
            )}

            <Box marginTop={2}>
              <Flex>
                <Box marginRight={2}>
                  <LoadingButton
                    loading={uploading}
                    disabled={!submittable}
                    variant="contained"
                    onClick={handleKneeboardSubmit}
                  >
                    Submit
                  </LoadingButton>
                </Box>

                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
              </Flex>
            </Box>
          </Section>
        </ModalContent>
      </Modal>
      <Box marginTop={2}>
        <Button onClick={() => setUploadOpen(true)} variant="contained">
          Upload Kneeboards...
        </Button>
      </Box>
    </div>
  );
}

export default styled(KneeboardUpload).attrs({
  className: KneeboardUpload.name,
})``;
