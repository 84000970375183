import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { Subscription, SubscriptionOrigin } from "../lib/models/Subscription";
import { Fetch } from "../lib/types";

interface SuperAdminServicesContextType {
  fetch: Fetch;
}

export const SuperAdminServicesContext = React.createContext<
  SuperAdminServicesContextType
>({} as SuperAdminServicesContextType);

interface Props {
  fetch: Fetch;
  children?: any;
}

export function SuperAdminServicesProvider({ fetch, children }: Props) {
  return (
    <SuperAdminServicesContext.Provider value={{ fetch }}>
      {children}
    </SuperAdminServicesContext.Provider>
  );
}

export function useFetch() {
  const { fetch } = React.useContext(SuperAdminServicesContext);

  return fetch;
}

function fetchIsSuperAdmin(fetch) {
  return fetch("/.netlify/functions/is_super_admin")
    .then((res) => res.json())
    .then((res: { isSuperAdmin: boolean }) => {
      return res.isSuperAdmin;
    });
}

export function useIsSuperAdmin() {
  const fetch = useFetch();

  return useQuery(["isSuperAdmin"], () => fetchIsSuperAdmin(fetch), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

type SubmitBody = {
  ownerDiscordID: string;
  ownerDisplayName: string;
  discordGuildID?: string;
  origin: SubscriptionOrigin;
};

function submitSub(
  fetch: Fetch,
  body: SubmitBody
): Promise<string | Subscription> {
  return fetch("/.netlify/functions/add_sub", {
    method: "POST",
    body: JSON.stringify({
      ...body,
    }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
}

type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

export function useSubmitSubscription(): OmitFirstArg<typeof submitSub> {
  const fetch = useFetch();
  const qc = useQueryClient();

  return async (...args) => {
    const res = await submitSub(fetch, ...args);

    await qc.invalidateQueries(["subs"]);

    return res;
  };
}

function listSubs(fetch: Fetch): Promise<Subscription[]> {
  return fetch("/.netlify/functions/list_subs").then((res) => res.json());
}

export function useListSubscriptions() {
  const fetch = useFetch();

  return useQuery(["subs"], () => listSubs(fetch), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}

function removeSub(fetch: Fetch, ownerDiscordID: string) {
  return fetch("/.netlify/functions/remove_sub", {
    method: "POST",
    body: JSON.stringify({ ownerDiscordID }),
  }).then((res) => res.json());
}

export function useRemoveSub(): OmitFirstArg<typeof removeSub> {
  const fetch = useFetch();
  const qc = useQueryClient();

  return async (...args) => {
    const r = await removeSub(fetch, ...args);

    await qc.invalidateQueries(["subs"]);

    return r;
  };
}

type SuperAdminInfo = {
  firebaseDashboardURL: string;
  stripeDashboardURL: string;
  s3Address: string;
};

export function useFetchSuperAdminInfo() {
  const fetch = useFetch();

  return useQuery<SuperAdminInfo>(["superAdminInfo"], () => {
    return fetch("/.netlify/functions/superadmin_info").then((res) =>
      res.json()
    );
  });
}
