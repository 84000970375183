import { Alert, Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import Page from "../components/Page";
import Section from "../components/Section";
import SubscriptionCTA from "../components/Subscription/SubscriptionCTA";
import SubscriptionManagement from "../components/Subscription/SubscriptionManagement";
import VolumeLicense from "../components/Subscription/VolumeLicense";
import SavedRadioConfigs from "../components/UserSettings/SavedRadioConfigs";
import { useGetSubscriptionDetails } from "../hooks/services";
import { SubscriptionOrigin } from "../lib/models/Subscription";

type Props = {
  className?: string;
};

function UserSettings({ className }: Props) {
  const { data: sub, isLoading, error, isError } = useGetSubscriptionDetails();

  const grantedByAdmin =
    sub && sub?.subscription?.origin === SubscriptionOrigin.SuperAdmin;

  const is404 =
    error?.message.includes("not_found") ||
    error?.message.includes("Not Found");

  const isExistingCustomer = !is404;

  const renderCTA = grantedByAdmin || !sub?.subscription?.enabled || is404;

  return (
    <Page
      loading={isLoading && !isError}
      title="Settings"
      className={className}
    >
      <Section title="Subscription">
        {grantedByAdmin && (
          <Alert severity="info">
            Your subscription has been granted by a Frag Orders site admin. No
            payment is required at this time. Enjoy!
          </Alert>
        )}

        <Box marginTop={2}>
          {!isExistingCustomer && renderCTA ? (
            <SubscriptionCTA />
          ) : (
            <SubscriptionManagement
              subscription={sub?.subscription}
              stripeDetails={sub?.details}
            />
          )}
        </Box>
      </Section>
      <Box marginTop={2}>
        <Section title="Preferences">
          <SavedRadioConfigs />
        </Section>
      </Box>
      {sub?.subscription?.isVolume && sub?.subscription.enabled && (
        <Box marginTop={2}>
          <VolumeLicense parent={sub?.subscription} />
        </Box>
      )}
    </Page>
  );
}

export default styled(UserSettings).attrs({ className: UserSettings.name })``;
