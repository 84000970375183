// Ideally, we would keep this as lua and write a custom parcel bundler
// to handle converting it to a JS String. But the parcel plugin/asset documentation
// is very lacking, so skipping it for now.

export const SerializeLuaTable = (varName: string) => {
  return `
    function SerializeTable(t, f)
        local str = ""
        local function printTableHelper(obj, cnt, key)
    
            local cnt = cnt or 0
    
            if type(obj) == "table" then
                str = str .. "\\n" .. string.rep("\\t", cnt) .. "{\\n"
                cnt = cnt + 1
    
                for k, v in pairs(obj) do
    
                    if type(k) == "string" then
                        str = str .. string.rep("\\t", cnt) .. '["' .. k .. '"]' .. " = "
                    end
    
                    if type(k) == "number" then
                        str = str .. string.rep("\\t", cnt) .. "[" .. k .. "]" ..  " = "
                    end
    
                    printTableHelper(v, cnt, k)
                    str = str .. ",\\n"
                end
    
                cnt = cnt - 1
                local lastChars = cnt > 0 and "}," or "}"
    
                local commentKey = ""
                if type(key) == "string" then
                    commentKey = '["' .. key .. '"]'
                end
    
                if type(key) == "number" then
                    commentKey = "[" .. key .. "]"
                end
    
                str =
                    -- str .. string.rep("\\t", cnt) .. lastChars .. ' -- end of ["' .. key .. '"]'
                    str .. string.rep("\\t", cnt) .. lastChars .. ' -- end of ' .. commentKey
    
            elseif type(obj) == "string" then
                str = str .. string.format("%q", obj)
    
            else
                str = str .. tostring(obj)
            end
    
            return str
        end
    
        return printTableHelper(t, 0, "${varName}")
    end
    
    `;
};
