import { Feature } from "ol";
import { Geometry, LineString, Point } from "ol/geom";
import Polygon, { circular } from "ol/geom/Polygon";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import * as sphere from "ol/sphere";
import { Fill, Stroke, Style, Text } from "ol/style";
import * as React from "react";
import { useTaskingState } from "../../contexts/TaskingMapContext";
import {
  Theater,
  convertDCSToLatLong,
  nauticalMilesToMeters,
} from "../../lib/map";
import { useMap } from "./MapCanvas";

const color = "grey";

const style = () => (feature) => {
  let geometry = feature.getGeometry();

  const text = new Text({
    font: "14px Roboto bold,sans-serif",
    fill: new Fill({ color: "black" }),
    stroke: new Stroke({ color: "black", width: 1 }),
    text: feature.get("label"),
    backgroundFill: new Fill({ color: "#ffffff93" }),
    offsetX: 0,
    offsetY: 0,
  });

  const textStyle = new Style({
    text,
  });

  const textPosition = feature.get("textPosition");
  if (textPosition) {
    textStyle.setGeometry(new Point(textPosition));
  }

  const styles = [];

  if (geometry instanceof LineString) {
    const bearing = feature.get("bearing");
    if (bearing === 0) {
      textStyle.getText().setText("");
    }

    textStyle.setGeometry(new Point(geometry.getLastCoordinate()));
  }

  if (geometry instanceof Polygon) {
    textStyle.getText().setOffsetX(12);
    textStyle.getText().setOffsetY(-10);
  }

  styles.push(
    new Style({
      geometry,
      stroke: new Stroke({ color, width: 2 }),
    })
  );

  styles.push(textStyle);

  return styles;
};

export const stickMapVectorLayer = ([long, lat]) =>
  new VectorLayer({
    source: new VectorSource({
      features: [
        ...([0, 45, 90, 135, 180, 225, 270, 315] as number[]).map((bearing) => {
          return new Feature({
            geometry: new LineString([
              [long, lat],
              getEnd(long, lat, 120, bearing),
            ]),
            bearing,
            label: `${bearing}°`,
          });
        }),
        ...[20, 40, 60, 80, 100, 120].map((distance) => {
          const circle = circular([long, lat], nauticalMilesToMeters(distance));
          return new Feature({
            geometry: circle,
            label: `${distance}`,
            textPosition: sphere.offset(
              [long, lat],
              nauticalMilesToMeters(distance),
              degressToRadians(0)
            ),
          });
        }),
      ] as Feature<Geometry>[],
    }),
    style: style(),
  });

type Props = {
  theater: Theater;
  bulls: { x: number; y: number };
  visible: boolean;
};

export const StickMapLayer = ({ bulls, visible, theater }: Props) => {
  const layerRef = React.useRef<VectorLayer<VectorSource>>(null);
  const map = useMap();

  React.useEffect(() => {
    if (!map) {
      return;
    }

    const { long, lat } = convertDCSToLatLong(theater, bulls);

    const vectorLayer = stickMapVectorLayer([long, lat]);

    // addLayer turns on visibility by default, so we need to turn it off
    vectorLayer.setStyle(null);

    layerRef.current = vectorLayer;

    map.addLayer(vectorLayer);

    return () => {
      if (map && layerRef.current) {
        map.removeLayer(layerRef.current);
      }
    };
  }, [map]);

  React.useEffect(() => {
    if (!layerRef.current) {
      return;
    }

    if (visible) {
      layerRef.current.setStyle(style());
    } else {
      layerRef.current.setStyle(null);
    }
  }, [map, visible]);

  return null;
};

export default function WrappedStickMapLayer() {
  const {
    state: {
      compassVisible,
      tasking: { theater, bullseye },
    },
  } = useTaskingState();

  return (
    <StickMapLayer
      bulls={bullseye}
      visible={compassVisible}
      theater={theater}
    />
  );
}

function degressToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

// https://chatgpt.com/c/c931d546-921d-4f3c-b57b-3df2c7904102
function getEnd(long: number, lat: number, distance: number, bearing: number) {
  // const startPoint = [long, lat]; // Example: Philadelphia, PA

  // const d = nauticalMilesToMeters(distance); // Convert miles to meters
  // const headingRadians = degressToRadians(bearing);

  // // Calculate changes in coordinates
  // const deltaX = d * Math.cos(headingRadians); // West is negative in the Cartesian plane
  // const deltaY = d * Math.sin(headingRadians); // South is negative in the Cartesian plane

  // // Convert distance delta from meters to degrees (approximation)
  // // Note: This approximation works reasonably for small distances
  // const deltaLongitude =
  //   deltaX / (111320 * Math.cos(degressToRadians(startPoint[1])));
  // const deltaLatitude = deltaY / 110540;

  // // Calculate the new position
  // const newLongitude = startPoint[0] + deltaLongitude;
  // const newLatitude = startPoint[1] + deltaLatitude;

  // const end = [newLongitude, newLatitude];

  // Old way that uses earth's sphere
  const end = sphere.offset(
    [long, lat],
    nauticalMilesToMeters(distance),
    degressToRadians(bearing)
  );

  return end;
}
