import { Alert, Snackbar } from "@mui/material";
import * as React from "react";

type NotificationContextType = {
  success: (messsage: string) => void;
  error: (message: string) => void;
  rawError: (e: Error) => void;
};

type Props = {
  children?: any;
};

export const NotificationContext = React.createContext<NotificationContextType>(
  {} as NotificationContextType
);

enum NotificationType {
  Success = "success",
  Info = "info",
  Error = "error",
}

export function NotificationProvider({ children }: Props) {
  const [message, setMessage] = React.useState<{
    type: NotificationType;
    text: string;
  }>({ type: NotificationType.Info, text: "" });
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (message.text) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = () => setOpen(false);

  const value: NotificationContextType = {
    success: (message: string) => {
      setMessage({ type: NotificationType.Success, text: message });
    },
    error: (message: string) => {
      setMessage({ type: NotificationType.Error, text: message });
    },

    rawError: (error: Error) => {
      console.error(error);
      setMessage({ type: NotificationType.Error, text: error.message });
    },
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <>
        <Snackbar open={open} onClose={handleClose} autoHideDuration={6000}>
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={message.type}
            sx={{ width: "100%" }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      </>
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return React.useContext(NotificationContext);
}
