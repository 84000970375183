import { useQuery } from "react-query";

export const PMTILES_HOST = "https://d1ofmpho8aplip.cloudfront.net";

const url = `${PMTILES_HOST}/test.json`;

export const useGetStyles = (pmtiles) => {
  return useQuery(["map_styles"], async () => {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    data.sources.protomaps.url = `pmtiles://${pmtiles}`;

    return data;
  });
};
