import JSZip from "jszip";

import { renderSupportAssetsKneeboard } from "../../components/Kneeboards/kneeboard_support_assets";
import { MizJobFunc } from "../models/Job";
import { TaskingState } from "../models/PublishManifest";

export const injectSupportAssetsKneeboard = (
  tasking: TaskingState
): MizJobFunc => async (zip: JSZip) => {
  const composer = await renderSupportAssetsKneeboard(tasking);
  const blob = await composer.renderToBlob();

  return zip.file("KNEEBOARD/IMAGES/SupportAssets.png", blob);
};
