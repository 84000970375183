import { Box, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useGroupsWithPlans } from "../../contexts/PlanManagerContext";
import {
  TaskingMapContext,
  useFocusFeature,
  useToggleLayer,
  useTogglePlanningMode,
} from "../../contexts/TaskingMapContext";
import { useUser } from "../../contexts/UserContext";
import { layerID } from "../../lib/layer_utils";
import { DCSGroupData, TaskingMapLayerName } from "../../lib/types";
import DiscordLoginButton from "../DiscordLoginButton";
import Flex from "../Flex";
import { PlayerGroups } from "../TaskingMap/PlayerGroups";

type Props = {
  className?: string;
};

function PlanningMenu({ className }: Props) {
  const { currentUser } = useUser();
  const plan = useTogglePlanningMode();
  const {
    state: {
      manifest,
      userPlannableGroups,
      disabledLayers,
      tasking: { plannedGroups },
    },
  } = React.useContext(TaskingMapContext);

  const focus = useFocusFeature();
  const toggle = useToggleLayer();

  React.useEffect(() => {
    // Enable planning mode on window open
    plan(true);

    return () => {
      plan(false);
    };
  }, []);

  const { data: groups, isLoading } = useGroupsWithPlans(
    manifest.fragOrderID,
    plannedGroups,
    { user: currentUser }
  );

  if (isLoading) {
    return null;
  }

  const handleLinkClick = (ev, group) => {
    ev.preventDefault();
    focus("Player Groups", group);
  };

  const handleToggle = (group: DCSGroupData) => {
    toggle([layerID(TaskingMapLayerName.PlayerGroups, [group.name])]);
  };

  const handleToggleAll = (checked: boolean) => {
    const layers = _.map(groups, (g) => {
      return [layerID(TaskingMapLayerName.PlayerGroups, [g.name])];
    });

    toggle(_.flatten(layers), !checked);
  };

  const rows = _.sortBy(
    _.filter(groups, (g) => _.includes(userPlannableGroups, g.name)),
    "name"
  );

  return (
    <Box className={className}>
      <Flex wide align between>
        <Box p={2}>
          <Typography variant="h6">Plannable Groups</Typography>
        </Box>
      </Flex>

      {!currentUser && (
        <Box p={2}>
          <Flex wide center>
            <DiscordLoginButton />
          </Flex>
        </Box>
      )}

      {currentUser && (
        <PlayerGroups
          disabledLayers={disabledLayers}
          onGroupSelect={handleToggle}
          onLinkClick={handleLinkClick}
          onToggleAll={handleToggleAll}
          groups={rows}
          layerName={TaskingMapLayerName.PlayerGroups}
        />
      )}
    </Box>
  );
}

export default styled(PlanningMenu).attrs({ className: PlanningMenu.name })`
  .diff {
    font-weight: 600;
    color: ${(props) => props.theme.palette.success.main};
  }

  .row-selected {
    td,
    .MuiButton-root,
    .MuiButton-textPrimary p {
      color: white !important;
    }

    background: ${(props) => props.theme.palette.grey[400]};
  }
`;
