import { Map } from "ol";
import { LineString, Point } from "ol/geom";
import { Draw, Modify } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import "ol/ol.css";
import { Vector as VectorSource } from "ol/source";
import { getArea, getLength } from "ol/sphere";
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text,
} from "ol/style";
import { METERS_TO_NAUTICAL_MILES } from "../../types";
import { eventIsLeftClick, eventIsMouseMove } from "./helpers";

function radians(n) {
  return n * (Math.PI / 180);
}
function degrees(n) {
  return n * (180 / Math.PI);
}

// https://gis.stackexchange.com/questions/29239/calculate-bearing-between-two-decimal-gps-coordinates
function getBearing(startLat, startLong, endLat, endLong) {
  startLat = radians(startLat);
  startLong = radians(startLong);
  endLat = radians(endLat);
  endLong = radians(endLong);

  var dLong = endLong - startLong;

  var dPhi = Math.log(
    Math.tan(endLat / 2.0 + Math.PI / 4.0) /
      Math.tan(startLat / 2.0 + Math.PI / 4.0)
  );
  if (Math.abs(dLong) > Math.PI) {
    if (dLong > 0.0) dLong = -(2.0 * Math.PI - dLong);
    else dLong = 2.0 * Math.PI + dLong;
  }

  return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
}

// https://openlayers.org/en/latest/examples/measure-style.html

const style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 1)",
    lineDash: [10, 10],
    width: 2,
  }),
  image: new RegularShape({
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 1)",
      width: 2,
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    points: 4,
    radius: 10,
    radius2: 0,
    angle: 0,
  }),
});

const labelStyle = new Style({
  text: new Text({
    font: "14px Roboto,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    padding: [2, 2, 2, 2],
    textBaseline: "bottom",
    offsetY: -15,
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
  }),
});

const tipStyle = new Style({
  text: new Text({
    font: "12px Roboto,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
    padding: [4, 4, 4, 4],
    textAlign: "left",
    offsetX: 15,
  }),
});

const modifyStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
  }),
  text: new Text({
    text: "Drag to modify",
    font: "12px Roboto,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    padding: [2, 2, 2, 2],
    textAlign: "left",
    offsetX: 15,
  }),
});

const segmentStyle = new Style({
  text: new Text({
    font: "12px Roboto,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
    padding: [2, 2, 2, 2],
    textBaseline: "bottom",
    offsetY: -12,
  }),
  image: new RegularShape({
    radius: 6,
    points: 3,
    angle: Math.PI,
    displacement: [0, 8],
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
  }),
});

const segmentStyles = [segmentStyle];

const formatLength = function (line) {
  let az;
  const coords = line.getCoordinates();
  if (coords.length === 2) {
    const [startLon, startLat] = coords[0];
    const [endLon, endLat] = coords[1];

    az = getBearing(startLat, startLon, endLat, endLon);
  }

  const length = getLength(line, { projection: "EPSG:4326" });
  const fixed = (length * METERS_TO_NAUTICAL_MILES).toFixed(2);

  return az ? `${az.toFixed(0)}° for ${fixed}NM` : `${fixed}NM`;
};

const formatArea = function (polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " km\xB2";
  } else {
    output = Math.round(area * 100) / 100 + " m\xB2";
  }
  return output;
};

const source = new VectorSource();

const modify = new Modify({ source: source, style: modifyStyle });

let tipPoint;

function styleFunction(feature, segments, drawType?, tip?) {
  const styles = [style];
  const geometry = feature.getGeometry();
  const type = geometry.getType();

  let point, label, line;
  if (!drawType || drawType === type) {
    if (type === "Polygon") {
      point = geometry.getInteriorPoint();
      label = formatArea(geometry);
      line = new LineString(geometry.getCoordinates()[0]);
    } else if (type === "LineString") {
      point = new Point(geometry.getLastCoordinate());
      label = formatLength(geometry);
      line = geometry;
    }
  }
  if (segments && line) {
    let count = 0;
    line.forEachSegment(function (a, b) {
      const segment = new LineString([a, b]);
      const label = formatLength(segment);
      if (segmentStyles.length - 1 < count) {
        segmentStyles.push(segmentStyle.clone());
      }
      const segmentPoint = new Point(segment.getCoordinateAt(0.5));
      segmentStyles[count].setGeometry(segmentPoint);
      segmentStyles[count].getText().setText(label);
      styles.push(segmentStyles[count]);
      count++;
    });
  }
  if (label) {
    labelStyle.setGeometry(point);
    labelStyle.getText().setText(label);
    styles.push(labelStyle);
  }
  if (
    tip &&
    type === "Point" &&
    !modify.getOverlay().getSource().getFeatures().length
  ) {
    tipPoint = geometry;
    tipStyle.getText().setText(tip);
    styles.push(tipStyle);
  }
  return styles;
}

export default function addMeasure(map: Map) {
  const vector = new VectorLayer({
    source: source,
    style: function (feature) {
      return styleFunction(feature, true);
    },
    zIndex: 2,
  });

  const drawType = "LineString";
  const activeTip = "Click to continue drawing the line";

  const idleTip = "Click to start measuring";
  let tip = idleTip;

  let draw = new Draw({
    source: source,
    type: "LineString",
    style: function (feature) {
      return styleFunction(feature, true, drawType, tip);
    },
    condition: (event) => eventIsLeftClick(event) || eventIsMouseMove(event),
  });

  draw.on("drawstart", function () {
    modify.setActive(false);
    tip = activeTip;
  });

  draw.on("drawend", function () {
    modifyStyle.setGeometry(tipPoint);
    modify.setActive(true);
    map.once("pointermove", function () {
      // @ts-ignore
      modifyStyle.setGeometry();
    });
    tip = idleTip;
  });

  modify.setActive(true);
  map.addInteraction(modify);
  map.addInteraction(draw);
  map.addLayer(vector);

  return { draw, clear: () => source.clear() };
}
