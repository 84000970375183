import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import {
  Theater,
  convertDCSToLatLong,
  decimalToDMS,
  formatLatLong,
} from "../../lib/map";
import { DCSNavPoint } from "../../lib/types";
import { styles } from "./kneeboard_utils";

const divStyle = {
  display: "flex",
  margin: 8,
  fontFamily: "sans-serif",
  height: "100%",
  width: "98%",
  fontSize: 14,
  flexWrap: "wrap",
  justifyContent: "space-between",
};

const cellStyle = {
  borderBottom: "1px solid gray",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

const trStyle = (index: number) => ({
  backgroundColor: index % 2 === 1 ? "#ffe194" : "#ffffff",
});

type Props = {
  theater: Theater;
  controlPoints: DCSNavPoint[];
};

export default function KneeboardControlPointsTable({
  theater,
  controlPoints,
}: Props) {
  return (
    <div style={divStyle as any}>
      <div style={{ textAlign: "center", width: "100%" }}>
        <h2 style={{ fontSize: 18 }}>Control Measures</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Table style={styles.table as any}>
            <TableHead style={styles.th as any}>
              <TableRow>
                <TableCell style={{ width: 24 }}>Name</TableCell>
                <TableCell style={{ width: 48 }}>Comment</TableCell>
                <TableCell style={{ width: 36 }}>LL (DM)</TableCell>
                <TableCell style={{ width: 36 }}>LL (DMS)</TableCell>
                <TableCell style={{ width: 36 }}>MGRS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(controlPoints, (cp, i) => {
                const coords = convertDCSToLatLong(theater, cp);

                const { lat, long } = coords;

                const str = formatLatLong([long, lat], 3);
                const mgrs = str[2];

                const longDMS = decimalToDMS(long, false);
                const latDMS = decimalToDMS(lat, true);

                return (
                  <TableRow key={cp.id} style={trStyle(i)}>
                    <TableCell style={cellStyle as any}>
                      {cp.callsignStr}
                    </TableCell>
                    <TableCell style={cellStyle as any}>{cp.comment}</TableCell>
                    <TableCell style={cellStyle as any}>
                      <div>
                        <div>{_.padStart(str[0], 13, " ")}</div>
                        <div>{str[1]}</div>
                      </div>
                    </TableCell>
                    <TableCell style={cellStyle as any}>
                      <div>{latDMS}</div>
                      <div>{longDMS}</div>
                    </TableCell>
                    <TableCell style={cellStyle as any}>{mgrs}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
