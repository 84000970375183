import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { APIGuild } from "discord-api-types/v10";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";

import { useGetDiscordGuilds } from "../contexts/DiscordClientContext";
import DiscordGuildLabel from "./DiscordGuildLabel";

type Props = {
  className?: string;
  value: string;
  onChange: (guildID: string, guild: APIGuild) => void;
};

function DiscordGuildSelect({ className, value, onChange }: Props) {
  const { data: guilds, isLoading } = useGetDiscordGuilds();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div className={className}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Discord Server</InputLabel>
        <Select
          label="Discord Server"
          id="server"
          variant="outlined"
          value={value || ""}
          onChange={(ev) =>
            onChange(ev.target.value, _.find(guilds, { id: ev.target.value }))
          }
        >
          {_.map(guilds, (g, i) => (
            <MenuItem key={i} value={g.id}>
              <DiscordGuildLabel guild={g} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          Associate this Frag Order with a Discord Server for planning and
          sharing integration
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default styled(DiscordGuildSelect).attrs({
  className: DiscordGuildSelect.name,
})``;
