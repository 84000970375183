import React, { createContext } from "react";
import { ExternalDataManager } from "../lib/services/ExternalDataManager";

export const ExternalDataManagerContext = createContext<ExternalDataManager | null>(
  null
);

interface ExternalDataManagerProviderProps {
  children: React.ReactNode;
  manager: ExternalDataManager;
}

export function ExternalDataManagerProvider({
  manager,
  children,
}: ExternalDataManagerProviderProps) {
  return (
    <ExternalDataManagerContext.Provider value={manager}>
      {children}
    </ExternalDataManagerContext.Provider>
  );
}
