import * as React from "react";
import { useContext } from "react";
import { FragOrder } from "../lib/models/FragOrder";
import { MizStorer } from "../lib/services/MizStorer";

type MizFileContextType = MizStorer;

type Props = {
  children?: any;
  storer: MizStorer;
};

export const MizFileContext = React.createContext<MizFileContextType>(
  {} as MizFileContextType
);

export function MizFileProvider({ storer, children }: Props) {
  return (
    <MizFileContext.Provider value={storer}>{children}</MizFileContext.Provider>
  );
}

export function useDownloadMiz() {
  const storer = useContext(MizFileContext);

  return (fo: FragOrder) => storer.download(fo.id);
}
