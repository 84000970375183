import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useListUserPreferences } from "../../../contexts/UserPreferencesManagerContext";
import ModalContent from "../../ModalContent";
import Section from "../../Section";

type Props = {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string, existing: boolean) => Promise<any>;
  saving: boolean;
};

function RadioPreferenceDialog({
  className,
  open,
  onClose,
  saving,
  onSubmit,
}: Props) {
  const { data: prefs } = useListUserPreferences();
  const [prefName, setPrefName] = React.useState("");

  const handleSubmit = () => {
    const existing = _.find(prefs, { name: prefName });

    if (existing) {
      if (window.confirm("Overwrite existing preference?")) {
        onSubmit(prefName, true);
      }
    } else {
      onSubmit(prefName, false);
    }
  };

  return (
    <Modal className={className} open={open} onClose={onClose}>
      <ModalContent style={{ minWidth: 480 }}>
        <Section title="Save Presets">
          <FormControl></FormControl>
          <Box marginBottom={2}>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <TextField
                required
                value={prefName}
                onChange={(e) => {
                  setPrefName(e.target.value);
                }}
              />
            </FormGroup>
          </Box>
          <LoadingButton
            loading={saving}
            style={{ marginRight: 8 }}
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            disabled={!prefName}
          >
            Save
          </LoadingButton>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </Section>
      </ModalContent>
    </Modal>
  );
}

export default styled(RadioPreferenceDialog).attrs({
  className: RadioPreferenceDialog.name,
})``;
