import * as React from "react";
import styled from "styled-components";
import { convertDictionaryToJS } from "../lib/lua";
import { getMizMetadata, MizMetadata } from "../lib/tasking";
import { extractMission } from "../lib/zip";
import Dropzone from "./Dropzone";

type Props = {
  className?: string;
  onDrop: (file: File, data: MizMetadata) => void;
};

function MissionFileDrop({ className, onDrop }: Props) {
  const handleDrop = (fileData) => {
    extractMission(
      fileData,
      (mission, dictionary, name, entries, warehouses) => {
        const t = getMizMetadata(
          mission,
          warehouses,
          convertDictionaryToJS(dictionary)
        );

        onDrop(fileData, t);
      }
    );
  };
  return (
    <div className={className}>
      <Dropzone
        text="Drag and drop a DCS .miz file"
        onFileDrop={(files: File[]) => {
          handleDrop(files[0]);
        }}
      />
    </div>
  );
}

export default styled(MissionFileDrop).attrs({
  className: MissionFileDrop.name,
})`
  box-sizing: border-box;
`;
