import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { Tooltip } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useTaskingState,
  useToggleLayer,
} from "../../contexts/TaskingMapContext";
import { layerID } from "../../lib/layer_utils";
import { DCSGroupData, TaskingMapLayerName } from "../../lib/types";
import NoShadowStateButton from "./NoShadowStateButton";

type Props = {
  className?: string;
  group: DCSGroupData;
};

function PlayerLayerControlButton({ className, group }: Props) {
  const { state } = useTaskingState();
  const toggle = useToggleLayer();

  const handleClick = () => {
    toggle([layerID(TaskingMapLayerName.PlayerGroups, [group.name])]);
  };

  const isVisible = !_.includes(
    state.disabledLayers,
    layerID(TaskingMapLayerName.PlayerGroups, [group.name])
  );

  return (
    <Tooltip title={`${isVisible ? "Hide" : "Show"} Route`}>
      <NoShadowStateButton
        className={className}
        active={!isVisible}
        onClick={handleClick}
      >
        {!isVisible ? <LayersIcon /> : <LayersClearIcon />}
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(PlayerLayerControlButton).attrs({
  className: PlayerLayerControlButton.name,
})``;
