import _ from "lodash";
import * as React from "react";
import WinBox from "react-winbox";
import styled from "styled-components";
import "winbox/dist/css/winbox.min.css";
import { TaskingMapContext } from "../../contexts/TaskingMapContext";
import { modules } from "../../lib/data/modules";
import {
  FOWindow,
  WindowRecord,
  useWindowManager,
} from "../../lib/services/WindowManager";
import { DCSGroupData } from "../../lib/types";
import AirbasesTable from "../AirbasesTable";
import AircraftList from "../AircraftList/AircraftList";
import AssetPreview from "../Assets/AssetPreview";
import ControlMeasuresWindow from "../ControlMeasures/ControlMeasuresWindow";
import AirportDiagram from "../FragOrderNavigation/AirportDiagram";
import { WrappedGroupDataTabs } from "../FragOrderNavigation/GroupDataTabs";
import SelectedGroupDataTabs from "../FragOrderNavigation/SelectedGroupDataTabs";
import DataEntryWindow from "../Planning/DataEntryWindow";
import PlanningMenu from "../Planning/PlanningMenu";
import PublicSignup from "../PublicSignup/PublicSignup";
import SearchWindow from "../SearchWindow/SearchWindow";
import SupportAssetsTable from "../SupportAssetsTable";
import TacviewExport from "../TacviewExport";
import PlayerGroups from "../TaskingMap/PlayerGroups";
import TimelineEditor from "../TimelineEditor/TimelineEditor";

type Props = {
  className?: string;
};

function moduleName(group?: DCSGroupData) {
  if (!group) {
    return "";
  }

  return _.get(modules, [group.units[0].type, "label"], group.units[0].type);
}

function windowData(
  w: WindowRecord,
  index: number
): { height: number; width: number; x?: number; y?: number; title?: string } {
  switch (w.type) {
    case FOWindow.Signups:
    case FOWindow.DataEntry:
    case FOWindow.Assets:
    case FOWindow.AircraftData:
      return { height: 720, width: 1280 };

    case FOWindow.FlyableGroup:
      return {
        height: 300,
        width: document.body.clientWidth / 2,
        x: 32 + (index + 1) * 16,
        y: document.body.clientHeight - 500 + (index + 1) * 16,
        title: `${w.props?.group?.name} - ${moduleName(w.props?.group)}`,
      };

    case FOWindow.SelectedFeature:
      return {
        height: 300,
        width: document.body.clientWidth,
        x: 32,
        y: document.body.clientHeight - 500,
        title: `${w.props?.group?.name} - ${moduleName(w.props?.group)}`,
      };

    case FOWindow.AirportDiagram:
      return {
        height: 480,
        width: 480,
        title: `${w.props.name} - Parking Diagram`,
      };

    case FOWindow.Planning:
      return { height: 540, width: 480 };

    case FOWindow.TimelineEditor:
      return {
        height: 400,
        width: document.body.clientWidth - 64,
        x: 32,
        y: document.body.clientHeight - 500,
      };

    case FOWindow.TacviewExport:
      return { height: 540, width: 640 };

    default:
      return { height: 300, width: 500 };
  }
}

function NavWindow({ children, index, ...rest }: any) {
  const offset = 64 + (index + 1) * 16;
  // Using a ref to prevent the window from re-positioning on re-render
  const initialPos = React.useRef<number>(offset);

  return (
    <WinBox
      x={initialPos.current}
      y={initialPos.current / 2}
      background="black"
      {...rest}
    >
      {children}
    </WinBox>
  );
}

function cmp(w: FOWindow, props?: any) {
  switch (w) {
    case FOWindow.Players:
      return <PlayerGroups />;

    case FOWindow.SupportAssets:
      return <SupportAssetsTable />;

    case FOWindow.Bases:
      return <AirbasesTable />;

    case FOWindow.Assets:
      return <AssetPreview />;

    case FOWindow.DataEntry:
      return <DataEntryWindow />;

    case FOWindow.SelectedFeature:
      return <SelectedGroupDataTabs />;

    case FOWindow.AirportDiagram:
      return <AirportDiagram {...props} />;

    case FOWindow.Planning:
      return <PlanningMenu />;

    case FOWindow.FlyableGroup:
      return <WrappedGroupDataTabs name={props.group?.name} />;

    case FOWindow.AircraftData:
      return <AircraftList />;

    case FOWindow.ControlMeasures:
      return <ControlMeasuresWindow />;

    case FOWindow.Signups:
      return <PublicSignup />;

    case FOWindow.TacviewExport:
      return <TacviewExport />;

    case FOWindow.Search:
      return <SearchWindow />;
    case FOWindow.TimelineEditor:
      return <TimelineEditor />;

    default:
      return null;
  }
}

function WindowManager({ className }: Props) {
  const { state } = React.useContext(TaskingMapContext);
  const { windows, handleClose } = useWindowManager();

  let group = _.find(state?.tasking?.plannedGroups, {
    name: state.focusedFeature?.name,
  });

  if (!group) {
    group = _.find(state?.tasking?.plannedGroups, {
      name: state?.focusedFeature?.layerName,
    });
  }

  return (
    <div className={className}>
      {_.map(windows, (w, i) => {
        const data = windowData(w, i);

        return (
          <NavWindow
            index={i}
            title={w.id}
            key={w.id}
            {...data}
            onClose={() => {
              handleClose(w);
            }}
          >
            {cmp(w.type, w.props)}
          </NavWindow>
        );
      })}
    </div>
  );
}

export default styled(WindowManager).attrs({ className: WindowManager.name })``;
