import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  useBlurFeature,
  useFocusFeature,
  useTaskingState,
  useUserCanDrawControlMeasures,
} from "../../contexts/TaskingMapContext";
import {
  useDeleteControlMeasure,
  useIsControlMeasureOwner,
  useListControlMeasures,
} from "../../hooks/control_measures";
import { useGetCurrentManifestID } from "../../hooks/frag_orders";
import { ControlMeasure } from "../../lib/models/ControlMeasure";
import { TaskingMapLayerName } from "../../lib/types";
import DataTable from "../DataTable";

type Props = {
  className?: string;
};

function ControlMeasureList({ className }: Props) {
  const isDrawingAllowed = useUserCanDrawControlMeasures();
  const isOwner = useIsControlMeasureOwner();
  const { success, error } = useNotification();
  const manifestID = useGetCurrentManifestID();
  const {
    state: { focusedFeature },
  } = useTaskingState();
  const focus = useFocusFeature();
  const blur = useBlurFeature();
  const { data: controlMeasures } = useListControlMeasures(manifestID);

  const [deleting, setDeleting] = React.useState<ControlMeasure | null>(null);
  const remove = useDeleteControlMeasure();

  const handleDelete = (cm) => {
    if (
      window.confirm("Are you sure you want to delete this control measure?")
    ) {
      setDeleting(cm);

      remove(cm)
        .then(() => success(`${cm.name} deleted`))
        .catch((e) => {
          console.error(e);
          error(`Error deleting control measure: ${e.message}`);
        })
        .finally(() => {
          setDeleting(null);
        });
    }

    if (
      focusedFeature?.layerName === TaskingMapLayerName.ControlMeasures &&
      focusedFeature?.name === cm.name
    ) {
      blur();
    }
  };

  return (
    <div className={className}>
      <DataTable
        columns={[
          {
            label: "Name",
            value: (cm: ControlMeasure) => (
              <Button
                style={{ color: cm.color }}
                onClick={() => {
                  focus(TaskingMapLayerName.ControlMeasures, cm.name);
                }}
              >
                {cm.name}
              </Button>
            ),
          },
          {
            value: (cm) =>
              isDrawingAllowed && isOwner(cm) ? (
                <LoadingButton
                  loading={deleting === cm}
                  color="error"
                  onClick={() => handleDelete(cm)}
                  variant="outlined"
                >
                  Delete
                </LoadingButton>
              ) : null,
            label: "",
          },
          // { value: "status", label: "Status" },
        ]}
        rows={controlMeasures}
      />
    </div>
  );
}

export default styled(ControlMeasureList).attrs({
  className: ControlMeasureList.name,
})``;
