import _ from "lodash";
import { DateTime } from "luxon";
import { Airbase } from "./data/airbases";
import { DCSDrawingLayers, getDrawingObjects } from "./drawings";
import { DCSI18n, getASTByVarName, getMissionAST } from "./lua";
import { Theater } from "./map";
import {
  findPlannable,
  get,
  getAirbasesForCoalition,
  getBriefingForCoalition,
  getBullseyeForCoalition,
  getCarriersAndFARPs,
  getControlPointsForCoalition,
  getGroupsOfType,
  getSupportAssets,
  getTriggerZones,
} from "./mission";
import { TaskingState } from "./models/PublishManifest";
import {
  Coalition,
  DCSGroupData,
  LuaTable,
  SharedRadioChannel,
  UnitCategory,
} from "./types";

export function getTheater(missionAST: LuaTable) {
  const t = get(missionAST, ["theatre"]);

  switch (t) {
    case "Caucasus":
      return Theater.CAUCASUS;

    case "PersianGulf":
      return Theater.PG;
    case "Syria":
      return Theater.SYRIA;
    case "Nevada":
      return Theater.NEVADA;
    case "MarianaIslands":
      return Theater.MARIANAS;
    case "Falklands":
      return Theater.SOUTH_ATLANTIC;
    case "SinaiMap":
      return Theater.SINAI;

    case "Normandy":
      return Theater.NORMANDY;

    case "Kola":
      return Theater.KOLA;

    case "Afghanistan":
      return Theater.AFGHANISTAN;

    default:
      throw new Error("theater not found: " + t);
  }
}

export function getTimeZoneOffset(theater: Theater) {
  switch (theater) {
    case Theater.CAUCASUS:
      return 3;

    case Theater.MARIANAS:
      return 10;

    case Theater.NEVADA:
      return -8;

    case Theater.PG:
      return 4;

    case Theater.SYRIA:
      return 3;

    case Theater.SOUTH_ATLANTIC:
      return -3;

    case Theater.SINAI:
      return 2;

    case Theater.NORMANDY:
      return 1;

    case Theater.KOLA:
      return 2;

    case Theater.AFGHANISTAN:
      return 3.5;

    default:
      throw new Error("invalid theater: " + theater);
  }
}

export function getMissionTime(
  theater: Theater,
  missionAST: LuaTable
): DateTime {
  const date = get(missionAST, ["date"]);
  const day = date.strValues.Day;
  const month = date.strValues.Month;
  const year = date.strValues.Year;
  const s = get(missionAST, ["start_time"]);

  let d = DateTime.fromObject(
    {
      day,
      month,
      year,
    },
    { zone: "utc" }
  );

  d = d.plus({ seconds: s });

  return d;
}

export function getTaskingData(
  coalition: Coalition,
  theater: Theater,
  missionAST: LuaTable,
  warehouseAST: LuaTable,
  dictionary: DCSI18n
) {
  const planes = findPlannable(
    coalition,
    UnitCategory.Plane,
    missionAST,
    dictionary
  );

  const helos = findPlannable(
    coalition,
    UnitCategory.Helicopter,
    missionAST,
    dictionary
  );

  const airfields = getAirbasesForCoalition(coalition, warehouseAST, theater);

  const carriersAndFARPs: Airbase[] = getCarriersAndFARPs(
    coalition,
    missionAST,
    dictionary
  );

  const bluforVehicles = getGroupsOfType(
    coalition,
    UnitCategory.Vehicle,
    missionAST,
    dictionary
  );

  const bluforStatics = getGroupsOfType(
    coalition,
    UnitCategory.Static,
    missionAST,
    dictionary
  );

  const bluforShips = getGroupsOfType(
    coalition,
    UnitCategory.Ship,
    missionAST,
    dictionary
  );

  return {
    playerGroups: [...planes, ...helos],
    airfields: [...airfields, ...carriersAndFARPs],
    bluforVehicles,
    bluforStatics,
    bluforShips,
  };
}

// Re-implimenting what the hook does
export function getTaskingState(
  coalition: Coalition,
  mission: string,
  warehouses: string,
  dictionary: DCSI18n
): TaskingState {
  const missionAST = getMissionAST(mission);

  const warehouseAST = getASTByVarName(warehouses, "warehouses");
  const theater = getTheater(missionAST);

  const time = getMissionTime(theater, missionAST);

  const drawingData = getDrawingObjects(missionAST, [
    DCSDrawingLayers.Common,
    DCSDrawingLayers.Author,
    DCSDrawingLayers.Neutrals,
    DCSDrawingLayers.Blue,
    DCSDrawingLayers.Red,
  ]);

  const opposingCoalition =
    coalition === Coalition.Blue ? Coalition.Red : Coalition.Blue;

  const {
    playerGroups: plannedGroups,
    airfields,
    bluforVehicles,
    bluforStatics,
    bluforShips,
  } = getTaskingData(coalition, theater, missionAST, warehouseAST, dictionary);

  const {
    opforAirbases,
    opforVehicles,
    opforPlanes,
    opforStatics,
    opforShips,
  } = getOpforData(
    opposingCoalition,
    theater,
    missionAST,
    warehouseAST,
    dictionary
  );

  const supportAssets = getSupportAssets(coalition, missionAST, dictionary);

  const zones = getTriggerZones(missionAST);

  const bullseye = getBullseyeForCoalition(coalition, missionAST);

  const controlPoints = getControlPointsForCoalition(coalition, missionAST);

  const briefingText = getBriefingForCoalition(
    coalition,
    missionAST,
    dictionary
  );

  return {
    theater,
    plannedGroups,
    airbases: airfields,
    opforAirbases,
    opforVehicles,
    supportAssets,
    zones,
    opforPlanes,
    bluforVehicles,
    drawingData,
    time: time.toJSON(),
    weather: null,
    bluforStatics,
    opforStatics,
    bluforShips,
    opforShips,
    bullseye,
    briefingText,
    controlPoints,
  };
}

export function getOpforData(
  coalition: Coalition,
  theater: Theater,
  missionAST: LuaTable,
  warehouseAST: LuaTable,
  dictionary: DCSI18n
) {
  const opforAirbases = getAirbasesForCoalition(
    coalition,
    warehouseAST,
    theater
  );

  const opforVehicles = getGroupsOfType(
    coalition,
    UnitCategory.Vehicle,
    missionAST,
    dictionary
  );

  // We currently aren't doing anything with this,
  // and it can put users over the 1000MB limit for the serialized taskingData field.
  const opforPlanes = [];
  // const opforPlanes = getGroupsOfType(
  //   coalition,
  //   UnitCategory.Plane,
  //   missionAST,
  //   dictionary
  // );

  const opforStatics = getGroupsOfType(
    coalition,
    UnitCategory.Static,
    missionAST,
    dictionary
  );

  const opforShips = getGroupsOfType(
    coalition,
    UnitCategory.Ship,
    missionAST,
    dictionary
  );

  return {
    opforAirbases,
    opforVehicles,
    opforPlanes,
    opforStatics,
    opforShips,
  };
}

export function createCommsTable(
  group: DCSGroupData,
  airbases: Airbase[],
  supportAssets: DCSGroupData[]
): SharedRadioChannel[][] {
  const radios = group.units[0].radios;

  const out = [];
  for (let r = 0; r < radios.length; r++) {
    const radio = radios[r];
    out[r] = [];

    for (let c = 0; c < radio.length; c++) {
      const chan = radio[c];

      if (!chan) {
        continue;
      }
      const record: SharedRadioChannel = {
        ...chan,
        description: null,
        channel: c + 1,
      };

      for (const base of airbases) {
        if (base.UHF === chan.frequency || base.VHF === chan.frequency) {
          record.description = base.Name;
        }
      }

      for (const group of supportAssets) {
        if (group.channel.frequency === chan.frequency) {
          record.description = group.name;
        }
      }

      out[r][c] = record;
    }
  }

  return out;
}

// https://chat.openai.com/share/e53fec80-2140-45f8-9d07-9b69d0c867f2
const highContrastColors = [
  "#000000", // Black
  "#CC0000", // Darker Red
  "#00CC00", // Darker Lime Green
  "#0000CC", // Darker Blue
  "#E6B800", // Darker Amber
  "#CC00CC", // Darker Magenta
  "#00CCCC", // Darker Cyan
  "#CC8000", // Darker Orange
  "#660066", // Darker Purple
  "#006600", // Darker Dark Green
  "#CCAF00", // Darker Gold
  "#CC3D00", // Darker Orange Red
  "#009999", // Darker Dark Turquoise
  "#CC0A69", // Darker Deep Pink
  "#5B005B", // Darker Dark Magenta
  "#1E661E", // Darker Forest Green
  "#CC4782", // Darker Hot Pink
  "#196DCC", // Darker Dodger Blue
  "#CC3F2C", // Darker Tomato
  "#802C99", // Darker Dark Orchid
];

export function getHighContrastColor(idx: number) {
  return highContrastColors[idx % highContrastColors.length];
}

// Create a map to ensure that each group name always gets assigned the same color
export function createHighContrastColorMap(groupNames: string[]) {
  const map: { [name: string]: string } = {};

  for (const name of groupNames) {
    const idx = groupNames.indexOf(name);
    map[name] = getHighContrastColor(idx);
  }

  return map;
}

export type MizMetadata = {
  flyableBLUFOR: number;
  flyableREDFOR: number;
  vehiclesBLUFOR: number;
  vehiclesREDFOR: number;
  drawingObjects: number;
  zones: number;
};

export function getMizMetadata(
  mission: string,
  warehouses: string,
  dictionary: DCSI18n
): MizMetadata {
  const missionAST = getMissionAST(mission);
  const drawingData = getDrawingObjects(missionAST, [
    DCSDrawingLayers.Common,
    DCSDrawingLayers.Author,
    DCSDrawingLayers.Neutrals,
    DCSDrawingLayers.Blue,
    DCSDrawingLayers.Red,
  ]);

  const coaLookup: {
    [coa: string]: {
      planes: DCSGroupData[];
      helos: DCSGroupData[];
      flyable: DCSGroupData[];
      vehicles: DCSGroupData[];
    };
  } = {};

  [Coalition.Blue, Coalition.Red, Coalition.Neutrals].forEach((c) => {
    const planes = getGroupsOfType(
      c,
      UnitCategory.Plane,
      missionAST,
      dictionary
    );
    const helos = getGroupsOfType(
      c,
      UnitCategory.Helicopter,
      missionAST,
      dictionary
    );

    const flyable = _.filter([...planes, ...helos], "isPlayer");

    const vehicles = getGroupsOfType(
      Coalition.Blue,
      UnitCategory.Vehicle,
      missionAST,
      dictionary
    );

    coaLookup[c] = {
      planes,
      helos,
      flyable,
      vehicles,
    };
  });

  return {
    flyableBLUFOR: coaLookup.blue.flyable.length,
    flyableREDFOR: coaLookup.red.flyable.length,
    vehiclesBLUFOR: coaLookup.blue.vehicles.length,
    vehiclesREDFOR: coaLookup.red.vehicles.length,
    drawingObjects: drawingData.length,
    zones: getTriggerZones(missionAST).length,
  };
}
