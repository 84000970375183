import { ThemeProvider as MuiTheme } from "@mui/material";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import AppHeader from "./components/AppHeader";
import AppStateProvider from "./components/AppStateProvider";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ErrorPage from "./components/ErrorPage";
import Flex from "./components/Flex";
import Style from "./components/Style";
import SuperAdmin from "./components/SuperAdmin/SuperAdmin";
import MissionSignupsApp from "./MissionSignupsApp";

import RedeemSub from "./components/RedeemSub/RedeemSub";
import { BundleClientContextProvider } from "./contexts/BundleClientContext";
import { ControlMeasureProvider } from "./contexts/ControlMeasureManagerContext";
import DiscordClientProvider from "./contexts/DiscordClientContext";
import { ExternalDataManagerProvider } from "./contexts/ExternalDataManagerContext";
import { FeatureFlagsProvider } from "./contexts/FeatureFlagsContext";
import FirebaseProvider from "./contexts/FirebaseContext";
import { FragOrderProvider } from "./contexts/FragOrderContext";
import { KneeboardManagerProvider } from "./contexts/KneeboardManagerContext";
import { MizFileProvider } from "./contexts/MizFileContext";
import { NotificationProvider } from "./contexts/NotifcationContext";
import { PlanManagerProvider } from "./contexts/PlanManagerContext";
import { PlanManagerV2Provider } from "./contexts/PlanManagerV2";
import { PublishManagerProvider } from "./contexts/PublishManagerContext";
import { PublishReaderProvider } from "./contexts/PublishReaderContext";
import { ServicesProvider } from "./contexts/ServicesContext";
import { SuperAdminServicesProvider } from "./contexts/SuperAdminServicesContext";
import { UserProvider } from "./contexts/UserContext";
import { UserPreferencesManagerProvider } from "./contexts/UserPreferencesManagerContext";
import { SignupClientProvider } from "./hooks/signups";
import { init } from "./lib/firestore";
import { NewAssetManager } from "./lib/services/AssetManager";
import { S3BundleClient } from "./lib/services/BundleClient";
import { DefaultControlMeasureManager } from "./lib/services/ControlMeasureManager";
import { NewDiscordClient } from "./lib/services/DiscordClient";
import { DefaultExternalDataManager } from "./lib/services/ExternalDataManager";
import { NewFetcher } from "./lib/services/Fetcher";
import { NewFragOrderClient } from "./lib/services/FragOrderClient";
import { NewMizStorer } from "./lib/services/MizStorer";
import { NewPlanManager } from "./lib/services/PlanManager";
import PlanManagerV2 from "./lib/services/PlanManagerV2/PlanManagerV2";
import PlanNavPointManager from "./lib/services/PlanManagerV2/PlanNavPointManager";
import PlanWaypointManager from "./lib/services/PlanManagerV2/PlanWaypointManager";
import {
  NewPublishManager,
  NewPublishReader,
} from "./lib/services/PublishManager";
import { SignupClient } from "./lib/services/SignupManager";
import { NewSubscriptionManager } from "./lib/services/SubscriptionManager";
import { UserPreferenceManager } from "./lib/services/UserPreferenceManager";
import { withContext } from "./lib/testutils";
import { theme as muiTheme } from "./lib/theme";
import { AppRoutes, Fetch } from "./lib/types";
import DiscordCallback from "./pages/DiscordCallback";
import FragOrderDetail from "./pages/FragOrderDetail";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import MyFragOrders from "./pages/MyFragOrders";
import NewFragOrder from "./pages/NewFragOrder";
import PublicFragOrder from "./pages/PublicFragOrder";
import StripeCallback from "./pages/StripeCallback";
import UserSettings from "./pages/UserSettings";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn:
    "https://0bd7aacb110bb168950d0876b2e3b632@o4507952514531328.ingest.us.sentry.io/4507952517414912",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export enum AppMode {
  Admin = "admin",
  Public = "public",
}

type Props = {
  mode: AppMode;
};

const fetcher = NewFetcher(fetch);

const { app, auth, db } = init();
const ms = NewMizStorer(fetch as Fetch);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

const bc = new S3BundleClient(fetch as Fetch);
const am = NewAssetManager(fetcher, db);
const cm = new DefaultControlMeasureManager(db);
const pub = NewPublishManager(db, ms, am, bc);
const reader = NewPublishReader(db, bc);
const discord = NewDiscordClient(fetch as Fetch);
const signups = new SignupClient(fetcher, db);
const fo = NewFragOrderClient(db, ms, pub);
const external = new DefaultExternalDataManager(db, fo);
const prefs = new UserPreferenceManager(db);
const planMgrV2 = new PlanManagerV2(db, queryClient);
const waypointManager = new PlanWaypointManager(db, queryClient);
const navPointManager = new PlanNavPointManager(db, queryClient);

function Public() {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }: any) => (
        <ErrorPage message={error.message} stack={error.stack} />
      )}
    >
      {/* @ts-ignore */}
      <Style />
      {/* @ts-ignore */}
      <Switch>
        {/* @ts-ignore */}
        <Route
          path={`${AppRoutes.PublicFragOrder}/:id`}
          component={PublicFragOrder}
        />
        {/* @ts-ignore */}
        <Route
          exact
          path={AppRoutes.DiscordOauth}
          component={DiscordCallback}
        />
      </Switch>
    </Sentry.ErrorBoundary>
  );
}

const WrappedPublic = withContext([
  [MuiTheme, { theme: muiTheme }],
  [ThemeProvider, { theme: muiTheme }],
  [NotificationProvider, {}],
  [FirebaseProvider, { app, auth, db }],
  [QueryClientProvider, { client: queryClient }],
  [UserProvider, {}],
  [FeatureFlagsProvider, { db }],
  [PublishReaderProvider, { reader }],
  [ExternalDataManagerProvider, { manager: external }],
  [PlanManagerProvider, { manager: NewPlanManager(db, fetcher) }],
  [KneeboardManagerProvider, { manager: am }],
  [BundleClientContextProvider, { client: bc }],
  [ControlMeasureProvider, { manager: cm }],
  [SignupClientProvider, { api: signups }],
  [
    PlanManagerV2Provider,
    { manager: planMgrV2, waypointManager, navPointManager },
  ],
])(<Public />);

const WrappedAdmin = withContext([
  [MuiTheme, { theme: muiTheme }],
  [ThemeProvider, { theme: muiTheme }],
  [NotificationProvider, {}],
  [DiscordClientProvider, { discord }],
  [FirebaseProvider, { app, auth, db }],
  [QueryClientProvider, { client: queryClient }],
  [UserProvider, {}],
  [FeatureFlagsProvider, { db }],
  [FragOrderProvider, { client: fo }],
  [ServicesProvider, { subs: NewSubscriptionManager(fetcher) }],
  [PublishManagerProvider, { manager: pub }],
  [PublishReaderProvider, { reader }],
  [ExternalDataManagerProvider, { manager: external }],
  [BundleClientContextProvider, { client: bc }],
  [ControlMeasureProvider, { manager: cm }],
  [PlanManagerProvider, { manager: NewPlanManager(db, fetcher) }],
  [KneeboardManagerProvider, { manager: am }],
  [SignupClientProvider, { api: signups }],
  [MizFileProvider, { storer: ms }],
  [SuperAdminServicesProvider, { fetch: fetch }],
  [UserPreferencesManagerProvider, { manager: prefs }],
  [
    PlanManagerV2Provider,
    { manager: planMgrV2, waypointManager, navPointManager },
  ],
])(<Admin />);

function Admin() {
  return (
    <>
      {/* @ts-ignore */}
      <Style />
      <AppHeader mode={AppMode.Admin} />
      <Sentry.ErrorBoundary
        fallback={({ error }: any) => (
          <ErrorPage message={error.message} stack={error.stack} />
        )}
      >
        <Switch>
          <AuthenticatedRoute
            exact
            path={AppRoutes.Home}
            component={MyFragOrders}
          />
          <AuthenticatedRoute
            exact
            path={AppRoutes.NewFragOrder}
            component={NewFragOrder}
          />
          <AuthenticatedRoute
            path={`${AppRoutes.FragOrderDetail}/:id`}
            component={FragOrderDetail}
          />

          <Route exact path={AppRoutes.RedeemCode} component={RedeemSub} />

          <Route exact path={AppRoutes.Login} component={Login} />

          <Route
            exact
            path={AppRoutes.DiscordOauth}
            component={DiscordCallback}
          />

          <Route exact path={AppRoutes.Logout} component={Logout} />

          <AuthenticatedRoute
            exact
            path={AppRoutes.UserSettings}
            component={UserSettings}
          />

          <Route
            path={`${AppRoutes.PublicFragOrder}/:id`}
            component={PublicFragOrder}
          />
          <Route
            path={AppRoutes.StandaloneSignupsPage}
            component={MissionSignupsApp}
          />

          <Route path={AppRoutes.StripeCallback} component={StripeCallback} />
          <SuperAdminServicesProvider fetch={window.fetch}>
            <Route path={`${AppRoutes.SuperAdmin}`} component={SuperAdmin} />
          </SuperAdminServicesProvider>
          {/* @ts-ignore */}
          <Route
            path="*"
            component={() => (
              <h4>
                <Flex center>404</Flex>
              </h4>
            )}
          />
        </Switch>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default function App({ mode }: Props) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }: any) => (
        <ErrorPage message={error.message} stack={error.stack} />
      )}
    >
      {/* @ts-ignore */}
      <BrowserRouter>
        <AppStateProvider mode={mode}>
          {mode === AppMode.Admin ? WrappedAdmin : WrappedPublic}
        </AppStateProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}
