import SettingsIcon from "@mui/icons-material/Settings";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
} from "@mui/material";
import saveAs from "file-saver";
import JSZip from "jszip";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGroupsWithPlans } from "../../contexts/PlanManagerContext";
import { useListPlanItems } from "../../contexts/PlanManagerV2";
import { useGetBundleForManifest } from "../../contexts/PublishManagerContext";
import { useListExternalData } from "../../hooks/external_data";
import {
  useKneeboardInjectionProhibited,
  useUpdateFragOrder,
} from "../../hooks/frag_orders";
import { FragOrderDoc } from "../../lib/models/FragOrder";
import { JobSettings, JobType } from "../../lib/models/Job";
import {
  PublishManifestDoc,
  TaskingState,
} from "../../lib/models/PublishManifest";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import { defaultJobSettings } from "../../lib/services/FragOrderClient";
import { Coalition } from "../../lib/types";
import DataTable from "../DataTable";
import Flex from "../Flex";
import Section from "../Section";
import KneeboardSettings from "./KneeboardSettings";
import KneeboardUpload from "./KneeboardUpload";
import { getPreview } from "./kneeboard_preview";
import { generateKneeboardPack } from "./kneeboard_utils";

type Props = {
  className?: string;
  manifests: PublishManifestDoc[];
  fragOrder: FragOrderDoc;
};

type KneeboardRow = {
  name: string;
  job: JobType;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

function KneeboardManagement({ className, manifests, fragOrder }: Props) {
  const [preview, setPreview] = React.useState<{ img: string; job: JobType }>({
    img: null,
    job: null,
  });
  const [downloading, setDownloading] = React.useState(false);
  const coas = _.map(manifests, "coalition");
  const hasBlue = _.includes(coas, Coalition.Blue);
  const hasRed = _.includes(coas, Coalition.Red);

  const [selectedCoalition, setSelectedCoalition] = React.useState<Coalition>(
    hasBlue ? Coalition.Blue : Coalition.Red
  );
  const [generating, setGenerating] = React.useState(null);
  const [updating, setUpdating] = React.useState<JobType>(null);
  const [carouselIndex, setCarouselIndex] = React.useState(0);
  const [settings, setSettings] = React.useState<{
    el: HTMLElement;
    job: JobType;
  }>(null);
  const { rawError, success } = useNotification();
  const update = useUpdateFragOrder();
  const isWarning = useKneeboardInjectionProhibited(fragOrder.id);

  const man = _.find(manifests, { coalition: selectedCoalition });

  const { data: tasking } = useGetBundleForManifest(man);
  const { data: externalData } = useListExternalData(
    fragOrder.id,
    man?.coalition
  );
  const { data: planItems } = useListPlanItems(man?.id);

  const { data: groups } = useGroupsWithPlans(
    fragOrder.id,
    tasking?.plannedGroups
  );

  const previewGroup = _.get(groups, carouselIndex);

  const handlePreview = React.useCallback(
    async (k: KneeboardRow) => {
      setGenerating(k.job);
      let img: string;

      try {
        if (!previewGroup) {
          throw new Error("No groups in mission");
        }
        img = await getPreview(
          k.job,
          tasking,
          {
            title: man?.title,
            group: previewGroup,
            radioConfig: fragOrder.jobSettings[
              JobType.RadioPresetConfig
            ] as RadioPresetConfig,
          },
          fragOrder.jobSettings[k.job] || defaultJobSettings[k.job],
          externalData,
          planItems
        );

        setPreview({ img, job: k.job });
      } catch (e) {
        rawError(e);
      }

      setGenerating(null);
    },
    [man, fragOrder, externalData, previewGroup]
  );

  const handleAdd = async (k: KneeboardRow, jobSettings?: JobSettings) => {
    setUpdating(k.job);

    const next = {
      ...fragOrder,
      jobs: _.concat(fragOrder.jobs || [], k.job),
      jobSettings: {
        ...fragOrder.jobSettings,
        [k.job]: jobSettings || defaultJobSettings[k.job],
      },
    };

    try {
      await update(next, _.map(manifests, "coalition"));
      success(`Added ${k.name} kneeboard to Frag Order`);
    } catch (e) {
      rawError(e);
    }

    setUpdating(null);
  };

  const handleRemove = async (k: KneeboardRow) => {
    setUpdating(k.job);

    const jobs = _.filter(fragOrder.jobs || [], (j) => j !== k.job);

    const next = {
      ...fragOrder,
      jobs,
    };

    update(next, _.map(manifests, "coalition"))
      .then(() => {
        success(`Removed ${k.name} kneeboard from Frag Order`);
      })
      .catch(rawError)
      .finally(() => {
        setUpdating(null);
      });
  };

  const handleSettingChange = async (job: JobType, settings: JobSettings) => {
    setUpdating(job);
    const next = {
      ...fragOrder,
      jobSettings: {
        ...fragOrder.jobSettings,
        [job]: settings,
      },
    };

    try {
      await update(next, _.map(manifests, "coalition"));
      success(`Updated ${job} kneeboard settings`);
    } catch (e) {
      rawError(e);
    }

    setUpdating(null);
  };

  React.useEffect(() => {
    if (!preview?.img) {
      return;
    }

    (async () => {
      const img = await getPreview(
        preview.job,
        tasking,
        {
          group: previewGroup,
          title: man?.title,
          radioConfig: fragOrder.jobSettings[
            JobType.RadioPresetConfig
          ] as RadioPresetConfig,
        },
        fragOrder.jobSettings[preview.job],
        externalData,
        planItems
      );

      setPreview({ ...preview, img });
    })();
  }, [carouselIndex, externalData, planItems]);

  const handleDownloadKneeboardPack = React.useCallback(async () => {
    setDownloading(true);
    const zip = new JSZip();

    await generateKneeboardPack(
      fragOrder,
      selectedCoalition,
      man.title,
      tasking,
      externalData,
      planItems,
      zip
    );

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => {
        saveAs(blob, `${fragOrder.name}_${man.coalition}_Kneeboards.zip`);
        success("Kneeboard pack downloaded");
      })
      .catch(rawError)
      .finally(() => {
        setDownloading(false);
      });
  }, [man, tasking]);

  return (
    <div className={className}>
      <Box marginBottom={2}>
        <Section title="Uploaded Kneeboards">
          <KneeboardUpload fragOrder={fragOrder} />
        </Section>
      </Box>
      <Box marginBottom={2}>
        <Section
          title="Generated Kneeboards"
          actions={[
            <Flex key="actions" align>
              <FormControl>
                <InputLabel id="coalition-select-label">Coalition</InputLabel>
                <Select
                  size="small"
                  style={{ minWidth: 160 }}
                  key="coalition-select"
                  label="Coalition"
                  value={selectedCoalition}
                  onChange={(ev) =>
                    setSelectedCoalition(ev.target.value as Coalition)
                  }
                >
                  <MenuItem disabled={!hasBlue} value="blue">
                    Blue
                  </MenuItem>
                  <MenuItem disabled={!hasRed} value="red">
                    Red
                  </MenuItem>
                </Select>
              </FormControl>

              <LoadingButton
                loading={downloading}
                style={{ marginLeft: 8 }}
                size="medium"
                variant="outlined"
                onClick={handleDownloadKneeboardPack}
              >
                Download Kneeboards
              </LoadingButton>
            </Flex>,
          ]}
        >
          {isWarning && (
            <Alert severity="warning">
              <AlertTitle>Multiple Coalition Kneeboard Warning</AlertTitle>
              <br />
              DCS does not allow for coalition-specific kneeboards. Enabling
              injection could leak information to the opposing coalition.
            </Alert>
          )}
          <DataTable
            columns={[
              { label: "Name", value: "name" },
              {
                label: "",
                value: (k: KneeboardRow) => (
                  <LoadingButton
                    type="button"
                    loading={generating === k.job}
                    onClick={() => handlePreview(k)}
                    variant="outlined"
                    color="primary"
                    disabled={
                      disableFreqJob(k, fragOrder) ||
                      disablControlPoints(k, tasking)
                    }
                  >
                    Preview
                  </LoadingButton>
                ),
              },

              {
                label: "Enabled",
                value: (k: KneeboardRow) => (
                  <Flex align>
                    <Switch
                      disabled={
                        !!updating ||
                        disableFreqJob(k, fragOrder) ||
                        disablControlPoints(k, tasking)
                      }
                      onChange={(ev) => {
                        ev.target.checked
                          ? handleAdd(
                              k,
                              k.job === JobType.RadioPresetConfigKneeboard
                                ? _.get(
                                    fragOrder.jobSettings,
                                    JobType.RadioPresetConfig
                                  )
                                : null
                            )
                          : handleRemove(k);
                      }}
                      inputProps={{
                        "aria-label": "Add to mission",
                        // @ts-ignore
                        "data-testid": `kneeboard-${k.job}`,
                      }}
                      checked={_.includes(fragOrder?.jobs, k.job)}
                    />
                    {updating === k.job && <CircularProgress size={24} />}
                  </Flex>
                ),
              },
              {
                label: "Settings",
                value: (k: KneeboardRow) => (
                  <IconButton
                    style={{
                      display:
                        _.keys(defaultJobSettings[k.job]).length === 0
                          ? "none"
                          : "inherit",
                    }}
                    onClick={(ev) =>
                      setSettings({ el: ev.currentTarget, job: k.job })
                    }
                    disabled={!_.includes(fragOrder?.jobs, k.job)}
                  >
                    <SettingsIcon />
                  </IconButton>
                ),
              },
            ]}
            rows={[
              {
                name: "Airfield Navigation Card",
                job: JobType.InjectAirbasesKneeboard,
              },
              {
                name: "Support Asset Navigation Card",
                job: JobType.InjectSupportAssetsKneeboard,
              },
              {
                name: "Lineup Cards",
                job: JobType.GroupLineupCard,
              },
              // {
              //   name: "Comms Cards",
              //   job: JobType.GroupCommsCard,
              // },
              {
                name: "Briefing Text",
                job: JobType.BriefingText,
              },
              {
                name: "Radio Preset Table",
                job: JobType.RadioPresetConfigKneeboard,
              },
              {
                name: "Control Points Card",
                job: JobType.ControlPointsCard,
              },
              {
                name: "Route Detail",
                job: JobType.InjectRouteDetailCards,
              },
            ]}
          />
        </Section>
      </Box>
      <Modal
        open={!!preview.img}
        onClose={() => setPreview({ img: null, job: null })}
        aria-labelledby="kneeboard-preview-modal"
        aria-describedby="A preview of automated kneeboard generation"
      >
        <Box sx={modalStyle}>
          <Flex column>
            <img src={preview.img} />

            {/* <div style={{ height: 957, width: 717 }}>
              <KneeboardLineupCard group={tasking.plannedGroups[0]} />
            </div> */}
          </Flex>
          <Flex
            style={{
              borderTop: "1px solid black",
              display:
                preview?.job === JobType.GroupLineupCard ||
                JobType.InjectRouteDetailCards ||
                preview?.job === JobType.GroupCommsCard
                  ? "flex"
                  : "none",
            }}
            wide
            between
          >
            <Button
              disabled={carouselIndex === 0}
              onClick={() => setCarouselIndex(carouselIndex - 1)}
            >
              Prev
            </Button>
            <Button
              disabled={carouselIndex === groups?.length - 1}
              onClick={() => setCarouselIndex(carouselIndex + 1)}
            >
              Next
            </Button>
          </Flex>
        </Box>
      </Modal>
      <KneeboardSettings
        anchorEl={settings?.el}
        job={_.get(fragOrder, ["jobSettings", settings?.job])}
        jobType={settings?.job}
        open={!!settings}
        onClose={() => setSettings(null)}
        onSettingChange={handleSettingChange}
      />
    </div>
  );
}

export default styled(KneeboardManagement).attrs({
  className: KneeboardManagement.name,
})``;

function disableFreqJob(k: KneeboardRow, fragOrder: FragOrderDoc) {
  if (k.job !== JobType.RadioPresetConfigKneeboard) {
    return false;
  }

  return !_.get(fragOrder, ["jobSettings", JobType.RadioPresetConfig]);
}

function disablControlPoints(k: KneeboardRow, tasking?: TaskingState) {
  if (k.job === JobType.ControlPointsCard) {
    return !tasking?.controlPoints || tasking?.controlPoints.length === 0;
  }

  return false;
}
