import { Box, Card, CardActions, CardMedia, Typography } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { Module, getLabelForModule, modules } from "../../lib/data/modules";
import {
  SignupRecord,
  SignupRoleType,
  SignupRule,
} from "../../lib/models/SignupRecord";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";

// @ts-ignore
import hornetImg from "../../img/card_hornet.png";
// @ts-ignore
import viperImg from "../../img/card_viper.png";
// @ts-ignore
import eagleImg from "../../img/card_eagle.png";
// @ts-ignore
import tomcatImg from "../../img/card_tomcat.jpg";
// @ts-ignore
import apacheImage from "../../img/card_apache.png";
// @ts-ignore
import hawgImg from "../../img/card_hawg.jpg";
// @ts-ignore
import awacsImg from "../../img/card_controller.png";
// @ts-ignore
import unknownImg from "../../img/card_unknown.png";

type Props = {
  className?: string;
  rule: SignupRule;
  actions?: React.ReactNode;
  signups?: SignupRecord[];
};

const cardImages = {
  "F-16C_50": viperImg,
  "FA-18C_hornet": hornetImg,
  "F-15ESE": eagleImg,
  "F-14B": tomcatImg,
  "AH-64D_BLK_II": apacheImage,
  "A-10C_2": hawgImg,
  Controller: awacsImg,
};

function SignupCard({ className, rule: r, actions, signups }: Props) {
  const isPilot = r.signupRoleType === SignupRoleType.Aircrew && r.module;
  let img = unknownImg;

  if (isPilot) {
    img = _.get(cardImages, r.module);
  } else if (r.signupRoleType === SignupRoleType.Controller) {
    img = _.get(cardImages, "Controller");
  }

  img = img || unknownImg;

  const m: Module = modules[r.module];

  return (
    <Card className={className} sx={{ width: 360 }} elevation={2}>
      <CardMedia sx={{ height: 140 }} image={img} title="Fighter Jet" />
      <Box p={2}>
        <Flex wide between>
          <div>
            <Typography variant="h6">{r.missionRole}</Typography>
          </div>
          <div>
            <Typography variant="h6">{getLabelForModule(r.module)}</Typography>
          </div>
        </Flex>
        <Flex wide between>
          <Box p={1} paddingLeft={0}>
            <div style={{ minHeight: "1em" }}>{r.notes || " "}</div>
          </Box>
        </Flex>

        <Flex wide>
          <KeyValueTable
            columns={2}
            pairs={[
              { key: "Role Type", value: r.signupRoleType },
              { key: "Discord Role", value: r.discordLabel },
              {
                key: "Signups",
                value: _.map(signups, (s, i) => (
                  <div key={i}>
                    {s.discordNickname}{" "}
                    {m?.hasMultiCrew && s.copilot ? "(Copilot)" : ""}
                  </div>
                )),
              },
            ]}
          />
        </Flex>
      </Box>

      <CardActions>
        <Flex wide between>
          {actions}
        </Flex>
      </CardActions>
    </Card>
  );
}

export default styled(SignupCard).attrs({ className: SignupCard.name })``;
