import _ from "lodash";
import { Point } from "ol/geom";
import { Circle, Fill, Stroke, Style, Text as TextStyle } from "ol/style";
import * as React from "react";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import {
  DCSNavPoint,
  FeatureClass,
  TaskingMapLayerName,
} from "../../lib/types";
import MapLayer from "./MapLayer";

const color = "#0037ff";

const styles = () => (feature) => {
  return [
    new Style({
      image: new Circle({
        // Transparent so clicks work
        fill: new Fill({ color }),
        stroke: new Stroke({ color, width: 4 }),
        radius: 5,
      }),
      text: new TextStyle({
        overflow: true,
        font: 'bold 14px "Roboto", "Arial Unicode MS", "sans-serif"',
        placement: "point",
        fill: new Fill({ color }),
        text: feature.get("label"),
        offsetY: 24,
        backgroundFill: new Fill({ color: "#ffffffbd" }),
      }),
    }),
  ];
};

type Props = {
  theater: Theater;
  points: DCSNavPoint[];
};

export default function ControlPointsLayer({ theater, points }: Props) {
  const features = _.map(points, (p) => {
    const { long, lat } = convertDCSToLatLong(theater, p);
    const geometry = new Point([long, lat]);

    return {
      geometry,
      point: p,
      featureClass: FeatureClass.ControlPoint,
      label: p.callsignStr,
      name: p.callsignStr,
    };
  });

  return (
    <MapLayer
      features={features}
      foLayerID={TaskingMapLayerName.ControlPoints}
      style={styles()}
      name={TaskingMapLayerName.ControlPoints}
      hideable
    />
  );
}
