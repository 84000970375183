import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import LargeTooltip from "../../components/LargeTooltip";
import { FOWindow, useWindowManager } from "../../lib/services/WindowManager";
import StateButton from "./StateButton";

type Props = {
  className?: string;
  tooltip: string;
  children: any;
  foWindow: FOWindow;
};

function NavButton({ className, children, foWindow, tooltip }: Props) {
  const { handleClick, windows } = useWindowManager();

  const active = !!_.find(windows, { id: foWindow });

  return (
    <div className={className}>
      <LargeTooltip placement="right" title={tooltip}>
        <StateButton
          size="small"
          active={active}
          onClick={() => handleClick(foWindow)}
        >
          {children}
        </StateButton>
      </LargeTooltip>
    </div>
  );
}

export default styled(NavButton).attrs({ className: NavButton.name })`
  margin-top: 16px;
`;
