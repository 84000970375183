import _ from "lodash";
import { FeatureFlag, useFeatureFlags } from "../contexts/FeatureFlagsContext";

type Props = {
  flag?: FeatureFlag;
  children?: any;
};

export default function FeatureGate({ flag, children }: Props) {
  const userFlags = useFeatureFlags();

  if (!_.includes(userFlags, flag)) {
    return null;
  }

  return children;
}
