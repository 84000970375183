import JSZip from "jszip";
import { renderRouteDetail } from "../../components/Kneeboards/kneeboard_route_detail";
import { mergePlansWithGroups } from "../../contexts/PlanManagerContext";
import { MizJobFunc, RouteDetailKneeboardSettings } from "../models/Job";
import { Plan, PlanStatus } from "../models/Plan";
import { TaskingState } from "../models/PublishManifest";

export const injectRouteDetails = (
  tasking: TaskingState,
  plans: Plan[],
  jobSettings?: RouteDetailKneeboardSettings
): MizJobFunc => async (zip: JSZip) => {
  const groups = mergePlansWithGroups(plans, tasking.plannedGroups, {
    status: PlanStatus.Submitted,
  });

  for (const group of groups) {
    const type = group.units[0].type;
    const composer = await renderRouteDetail(tasking, group, jobSettings);
    const blob = await composer.renderToBlob();
    zip = zip.file(
      `KNEEBOARD/${type}/IMAGES/${group.name}_RouteDetail.png`,
      blob
    );
  }

  return zip;
};
