import _ from "lodash";
import { DateTime } from "luxon";
import { getTypeForGroup } from "./data/modules";
import {
  Theater,
  convertDCSToLatLong,
  formatLatLong,
  metersToFeet,
} from "./map";
import { RadioChannelOverride } from "./models/Plan";
import { totFromEta } from "./time";
import { DCSGroupData } from "./types";

interface RadioChannel {
  Type: number;
  Channel: number;
  Frequency: string;
  ToBeUpdated: boolean;
}

interface Radio {
  Name: string;
  Type: number;
  Channels: RadioChannel[];
  SelectedChannel: RadioChannel;
}

interface CMSProgram {
  Number: number;
  FlareBurstQty: number;
  FlareBurstInterval: number;
  FlareSalvoQty: number;
  FlareSalvoInterval: number;
  ChaffBurstQty: number;
  ChaffBurstInterval: number;
  ChaffSalvoQty: number;
  ChaffSalvoInterval: number;
  ToBeUpdated: boolean;
}

interface MFDConfiguration {
  Mode: number;
  LeftMFD: {
    SelectedPage: number;
    Page1: number;
    Page2: number;
    Page3: number;
  };
  RightMFD: {
    SelectedPage: number;
    Page1: number;
    Page2: number;
    Page3: number;
  };
  ToBeUpdated: boolean;
}

interface HARMTable {
  TableNumber: number;
  ToBeUpdated: boolean;
  Emitters: number[];
}

interface Waypoint {
  Sequence: number;
  Name: string;
  Latitude: string;
  Longitude: string;
  Elevation: number;
  TimeOverSteerpoint: string;
  IsCoordinateBlank: false;
}

export interface DTCFile {
  Waypoints?: {
    Waypoints: Waypoint[];
    SteerpointStart: number;
    SteerpointEnd: number;
    OverrideRange: boolean;
    EnableUploadCoordsElevation: boolean;
    EnableUploadTOS: boolean;
    EnableUpload: boolean;
  };
  Radios?: {
    COM1: Radio;
    COM2: Radio;
    EnableUpload: boolean;
  };
  CMS?: {
    Programs: CMSProgram[];
    ChaffBingo: number;
    FlareBingo: number;
    EnableUpload: boolean;
  };
  MFD?: {
    Configurations: MFDConfiguration[];
    EnableUpload: boolean;
  };
  HARM?: {
    Tables: HARMTable[];
    EnableUpload: boolean;
  };
  HTS?: {
    ManualTableEnabled: boolean;
    ManualEmitters: number[];
    ManualEmittersToBeUpdated: boolean;
    EnabledClasses: boolean[];
    ManualTableEnabledToBeUpdated: boolean;
    EnableUpload: boolean;
  };
  Misc?: {
    Bingo: number;
    BingoToBeUpdated: boolean;
    EnableBullseye: boolean;
    BullseyeToBeUpdated: boolean;
    BullseyeWP: number;
    CARAALOW: number;
    CARAALOWToBeUpdated: boolean;
    MSLFloor: number;
    MSLFloorToBeUpdated: boolean;
    TGPCode: number;
    TGPCodeToBeUpdated: boolean;
    LSTCode: number;
    LSTCodeToBeUpdated: boolean;
    TACANChannel: number;
    TACANBand: number;
    TACANToBeUpdated: boolean;
    ILSFrequency: number;
    ILSCourse: number;
    ILSToBeUpdated: boolean;
    EnableUpload: boolean;
  };
  Version?: number;
}

const re = /(.\d)°\s/g;

export function convertGroupToDTCFile(
  theater: Theater,
  startTime: DateTime,
  g: DCSGroupData,
  presets?: RadioChannelOverride[][]
): DTCFile {
  const type = getTypeForGroup(g);

  const Waypoints: Waypoint[] = _.map(g.waypoints, (w, i) => {
    const { long, lat } = convertDCSToLatLong(theater, w);

    const [n, e] = formatLatLong([long, lat], 3);

    let latFormatted = n + "’";
    let longFormatted = e + "’";

    if (type === "F-16C_50") {
      // DTC requires a different format for the lat/long only on the viper for some reason
      latFormatted = n.replace(re, "$1.");
      longFormatted = e.replace(re, "$1.");
    }

    const tot = totFromEta(startTime, w.ETA);

    return {
      Sequence: i + 1,
      Name: w.name,
      Latitude: latFormatted,
      Longitude: longFormatted,
      Elevation: Math.floor(metersToFeet(w.alt)),
      TimeOverSteerpoint: tot.toFormat("HH:mm:ss"),
      IsCoordinateBlank: false,
      PointType: "WP",
      Identifier: "WP",
      Free: "",
    };
  });

  const result: DTCFile = {
    Waypoints: {
      Waypoints,
      SteerpointStart: 1,
      SteerpointEnd: 20,
      OverrideRange: false,
      EnableUploadCoordsElevation: true,
      EnableUploadTOS: true,
      EnableUpload: true,
    },
  };

  if (presets && presets.length > 0) {
    const r1c1 = _.get(presets, [0, 0], []) as RadioChannelOverride;
    const r2c1 = _.get(presets, [1, 0], []) as RadioChannelOverride;
    const Radios = {
      COM1: {
        Name: "COM1",
        Type: 0,
        Channels: _.map(presets?.[0], (c, i) => {
          return {
            Type: 1,
            Channel: i + 1,
            Frequency: c.frequency,
            ToBeUpdated: false,
          };
        }),
        SelectedChannel: {
          Type: 2,
          Channel: 1,
          Frequency: r1c1?.frequency,
          ToBeUpdated: false,
        },
      },
      COM2: {
        Name: "COM2",
        Type: 1,
        Channels: _.map(presets?.[1], (c, i) => {
          return {
            Type: 2,
            Channel: i + 1,
            Frequency: c.frequency,
            ToBeUpdated: false,
          };
        }),
        SelectedChannel: {
          Type: 2,
          Channel: 1,
          Frequency: r2c1.frequency,
          ToBeUpdated: false,
        },
      },
      EnableUpload: true,
    };

    result.Radios = Radios;
  }

  if (type === "AH-64D_BLK_II") {
    // DTC needs a version set here for the AH-64D
    result.Version = 2;
  }

  return result;
}
