import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { RouteDetailKneeboardSettings } from "../../lib/models/Job";

type Props = {
  className?: string;
  onChange?: (opts: RouteDetailKneeboardSettings) => void;
  settings: RouteDetailKneeboardSettings;
};

function RouteDetailSettings({ className, onChange, settings }: Props) {
  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showBullsCompass}
            onChange={(ev) =>
              onChange({ ...settings, showBullsCompass: ev.target.checked })
            }
          />
        }
        label="Display Bullseye Compass"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={settings?.showDistances}
            onChange={(ev) =>
              onChange({ ...settings, showDistances: ev.target.checked })
            }
          />
        }
        label="Display Distances"
      />
    </FormGroup>
  );
}

export default styled(RouteDetailSettings).attrs({
  className: RouteDetailSettings.name,
})``;
