import { APIUser } from "discord-api-types/v10";
import _ from "lodash";
import { PlanItem, PlanItemDoc, PlanItemType } from "../../models/PlanItem";
import { DCSWaypoint } from "../../types";
import PlanManagerV2 from "./PlanManagerV2";

export default class PlanWaypointManager extends PlanManagerV2 {
  async listWaypoints(
    manifestID: string,
    group: string
  ): Promise<DCSWaypoint[]> {
    const items = await this.list(manifestID);

    const waypoints = _.filter(items, { group, type: PlanItemType.Waypoint });

    const result = _.map(
      waypoints,
      (w) => JSON.parse(w.payload) as DCSWaypoint
    );

    return result;
  }

  async getWaypoint(
    manifestID: string,
    group: string,
    waypointIndex: number
  ): Promise<DCSWaypoint> {
    const item = await this.get(
      manifestID,
      PlanItemType.Waypoint,
      group,
      waypointIndex.toString()
    );

    if (!item) {
      throw new Error("Waypoint not found");
    }

    return JSON.parse(item.payload) as DCSWaypoint;
  }

  public async upsertWaypoint(
    manifestID: string,
    user: APIUser,
    group: string,
    waypoint: DCSWaypoint
  ) {
    let existing: PlanItemDoc;

    try {
      existing = await this.get(
        manifestID,
        PlanItemType.Waypoint,
        group,
        waypoint.number.toString()
      );
    } catch (e) {
      existing = null;
    }

    if (existing) {
      return this.updateWaypoint(existing, group, waypoint);
    } else {
      return this.addWaypoint(manifestID, user, group, waypoint);
    }
  }

  private async addWaypoint(
    manifestID: string,
    user: APIUser,
    group: string,
    waypoint: DCSWaypoint
  ) {
    const item: PlanItem = {
      group,
      type: PlanItemType.Waypoint,
      manifestID: manifestID,
      uniqID: waypoint.number.toString(),
      payload: JSON.stringify(waypoint),
    };

    return this.add(user, item);
  }

  private async updateWaypoint(
    existing: PlanItemDoc,
    group: string,
    waypoint: DCSWaypoint
  ) {
    const item = {
      ...existing,
      payload: JSON.stringify(waypoint),
    } as PlanItemDoc;

    return this.update(item);
  }

  async removeWaypoint(manifestID: string, group: string, number: number) {
    const existing = await this.get(
      manifestID,
      PlanItemType.Waypoint,
      group,
      number.toString()
    );

    // Renumber the rest of the waypoints
    const waypoints = await this.listWaypoints(manifestID, group);

    const toUpdate = _.filter(waypoints, (w) => w.number > number);

    for (const w of toUpdate) {
      let item: PlanItemDoc;
      try {
        item = await this.get(
          manifestID,
          PlanItemType.Waypoint,
          group,
          w.number.toString()
        );
      } catch (e) {
        continue;
      }

      item.payload = JSON.stringify({
        ...JSON.parse(item.payload),
        number: w.number - 1,
      });
      item.uniqID = (w.number - 1).toString();

      await this.update(item);
    }

    return this.remove(existing);
  }
}
