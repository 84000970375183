import _ from "lodash";
import {
  createContext,
  createElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { DCSGroupData } from "../types";

interface WindowManagerContextType {
  windows: WindowRecord[];
  addWindow: (w: WindowRecord) => void;
  handleClick: (w: FOWindow) => void;
  handleClose: (w: WindowRecord) => void;
}

const WindowManagerContext = createContext<WindowManagerContextType>(null);

export type WindowRecord = {
  id: string;
  type: FOWindow;
  props?: any;
};

export function useWindowManager() {
  return useContext(WindowManagerContext);
}

export function WindowManagerProvider({ children }) {
  const [windows, setWindows] = useState<WindowRecord[]>([]);
  const history = useHistory();

  useEffect(() => {
    const q = new URLSearchParams(window.location.search);
    const w = q.get("window");
    const group = q.get("group");
    if (w) {
      let props = {};
      if (group) {
        props = { group: decodeURIComponent(group) };
      }

      addWindow({ id: w, type: w as FOWindow, props });
    }
  }, [window.location.search]);

  const addWindow = (win: WindowRecord) => {
    const exists = !!_.find(windows, { id: win.id });
    if (exists) {
      // remove the existing window
      const filtered = _.filter(windows, (w) => w.id !== win.id);
      setWindows([...filtered, win]);
    }

    if (!exists) {
      setWindows([...windows, win]);
    }
  };

  const handleClick = (type: FOWindow, id?: string, props?: any) => {
    let next: WindowRecord[] = [...windows];

    const win = _.find(next, { type });

    if (win) {
      // remove the existing window
      next = _.filter(next, (w) => w.type !== type);
      setWindows(next);
      return;
    }

    const q = new URLSearchParams(window.location.search);
    q.set("window", type);
    if (props?.group) {
      q.set("group", encodeURIComponent(props.group));
    }
    history.replace({ search: q.toString() });

    next.push({ id: type, type, props });

    setWindows(next);
  };

  const handleClose = (win: WindowRecord) => {
    const filtered = _.filter(windows, (w) => w.id !== win.id);

    const q = new URLSearchParams(window.location.search);
    q.delete("window");
    q.delete("group");
    history.replace({ search: q.toString() });
    setWindows(filtered);
  };

  return createElement(WindowManagerContext.Provider, {
    value: { windows, addWindow, handleClick, handleClose },
    children,
  });
}

export enum FOWindow {
  Players = "Players",
  SupportAssets = "Support Assets",
  Bases = "Bases",
  Assets = "Kneeboards",
  DataEntry = "Data Entry",
  SelectedFeature = "Selected Feature",
  AirportDiagram = "Airport Diagram",
  Planning = "Planning",
  AircraftData = "Aircraft Data",
  Singups = "Signups",
  FlyableGroup = "Flyable Group",
  ControlMeasures = "Control Measures",
  Signups = "Signups",
  TacviewExport = "Tacview Export",
  Search = "Search",
  TimelineEditor = "Timeline Editor",
}

export function useToggleGroupDataWindow() {
  const win = useWindowManager();

  return (group: DCSGroupData) => {
    const existing = _.find(win.windows, { id: group.name });

    if (existing) {
      win.handleClose(existing);
      return;
    }

    win.addWindow({
      id: group.name,
      type: FOWindow.FlyableGroup,
      props: { group },
    });
  };
}
