import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  GroupWithPlan,
  useGroupsWithPlans,
} from "../../contexts/PlanManagerContext";
import { useListPlanItemsByGroup } from "../../contexts/PlanManagerV2";
import {
  TaskingMapContext,
  useFocusWaypoint,
} from "../../contexts/TaskingMapContext";
import GroupDataTabs from "./GroupDataTabs";

type Props = {
  className?: string;
};

function SelectedGroupDataTabs({ className }: Props) {
  const {
    state: { tasking, manifest, focusedFeature },
  } = React.useContext(TaskingMapContext);

  const { data: groups, isLoading } = useGroupsWithPlans(
    manifest.fragOrderID,
    tasking.plannedGroups
  );

  const name = focusedFeature.name;

  const { data: planItems } = useListPlanItemsByGroup(
    manifest.fragOrderID,
    name as string
  );

  const select = useFocusWaypoint();

  let group = _.find(groups, { name }) as GroupWithPlan;

  if (!group) {
    group = _.find(groups, { name: focusedFeature.layerName });
  }

  const handleWaypointClick = React.useCallback(
    (n: number) => {
      const wp = _.find(group?.waypoints, { number: n });
      select(group?.name, wp);
    },
    [group, select]
  );

  if (isLoading || !focusedFeature) {
    return null;
  }

  return (
    <GroupDataTabs
      className={className}
      group={group}
      tasking={tasking}
      onWaypointClick={handleWaypointClick}
      planItems={planItems}
    />
  );
}

export default styled(SelectedGroupDataTabs).attrs({
  className: SelectedGroupDataTabs.name,
})``;
