import {
  Box,
  FormControl,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import {
  TaskingMapActionTypes,
  useTaskingState,
} from "../../contexts/TaskingMapContext";
import Flex from "../Flex";

type Props = {
  className?: string;
};

function VizControls({ className }: Props) {
  const { state, dispatch } = useTaskingState();

  const handleDistancesClick = () => {
    dispatch({
      type: TaskingMapActionTypes.disableDistances,
      enabled: !state.disableDistances,
    });
  };

  const handleWaypointLabelsClick = () => {
    dispatch({
      type: TaskingMapActionTypes.disableWaypointLabels,
      enabled: !state.disableWaypointLabels,
    });
  };

  const handleToggleCompass = () => {
    dispatch({
      type: TaskingMapActionTypes.toggleCompassViz,
      enabled: !state.compassVisible,
    });
  };

  return (
    <div className={className}>
      <Paper>
        <Box p={2}>
          <div>
            <Typography style={{ marginTop: 0 }} fontSize={16}>
              Map Controls
            </Typography>
          </div>
          <Flex column>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={!state.disableDistances}
                    onChange={handleDistancesClick}
                  />
                }
                label="Show Distances"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={!state.disableWaypointLabels}
                    onChange={handleWaypointLabelsClick}
                  />
                }
                label="Show Waypoint Labels"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.compassVisible}
                    onChange={handleToggleCompass}
                  />
                }
                label="Show Bulls Compass"
              />
            </FormControl>
          </Flex>
        </Box>
      </Paper>
    </div>
  );
}

export default styled(VizControls).attrs({ className: VizControls.name })`
  position: absolute;
  left: 64px;
  bottom: 48px;
  z-index: 1;

  opacity: 0.8;

  @media only screen and (max-width: 1300px) {
    display: none;
  }

  @media only screen and (max-height: 700px) {
    left: 32px;
  }
`;
