import Weapons from "./weapons";

const weaponIDs = {
  "{AB_250_2_SD_2}":
    Weapons.AB_250_2___144_x_SD_2__250kg_CBU_with_HE_submunitions,
  "{AB_250_2_SD_10A}":
    Weapons.AB_250_2___17_x_SD_10A__250kg_CBU_with_10kg_Frag_HE_submunitions,
  "{AB_500_1_SD_10A}":
    Weapons.AB_500_1___34_x_SD_10A__500kg_CBU_with_10kg_Frag_HE_submunitions,
  "{ADEN_GUNPOD}": Weapons.ADEN_GUNPOD,
  "{BRU42_ADM141}": Weapons.ADM_141A,
  "{BRU3242_ADM141}": Weapons.ADM_141A_,
  "{ADM_141A}": Weapons.ADM_141A_TALD,
  "{ADM_141B}": Weapons.ADM_141B_TALD,
  "{AV8BNA_AERO1D}": Weapons.AERO_1D_300_Gallons_Fuel_Tank_,
  "{AV8BNA_AERO1D_EMPTY}": Weapons.AERO_1D_300_Gallons_Fuel_Tank__Empty_,
  AGM114x2_OH_58: Weapons.AGM114x2_OH_58,
  "{ee368869-c35a-486a-afe7-284beb7c5d52}": Weapons.AGM_114K,
  "{88D18A5E-99C8-4B04-B40B-1C02F2018B6E}": Weapons.AGM_114K___4,
  "{7B8DCEB4-820B-4015-9B48-1028A4195692}": Weapons.AGM_119B_Penguin_ASM,
  "{AGM_122_SIDEARM}": Weapons.AGM_122_Sidearm,
  "{LAU_7_AGM_122_SIDEARM}": Weapons.AGM_122_Sidearm_,
  "{AGM_122}": Weapons.AGM_122_Sidearm___light_ARM,
  "{AGM-154A}": Weapons.AGM_154A___JSOW_CEB__CBU_type_,
  "{AGM-154B}": Weapons.AGM_154B___JSOW_Anti_Armour,
  "{9BCC2A2B-5708-4860-B1F1-053A18442067}":
    Weapons.AGM_154C___JSOW_Unitary_BROACH,
  "{AGM_45A}": Weapons.AGM_45A_Shrike_ARM,
  "{3E6B632D-65EB-44D2-9501-1C2D04515404}": Weapons.AGM_45B_Shrike_ARM__Imp_,
  "{C40A1E3A-DD05-40D9-85A4-217729E37FAE}":
    Weapons.AGM_62_Walleye_II___Guided_Weapon_Mk_5__TV_Guided_,
  "{444BA8AE-82A7-4345-842E-76154EFCCA47}":
    Weapons.AGM_65D___Maverick_D__IIR_ASM_,
  "{F16A4DE0-116C-4A71-97F0-2CF85B0313EF}":
    Weapons.AGM_65E___Maverick_E__Laser_ASM___Lg_Whd_,
  "{69DC8AE7-8F77-427B-B8AA-B19D3F478B65}":
    Weapons.AGM_65K___Maverick_K__CCD_Imp_ASM_,
  AGM_84: Weapons.AGM_84,
  "{8B7CADF9-4954-46B3-8CFB-93F2F5B90B03}": Weapons.AGM_84A_Harpoon_ASM,
  "{AGM_84D}": Weapons.AGM_84D_Harpoon_AShM,
  "{AF42E6DF-9A60-46D8-A9A0-1708B241AADB}":
    Weapons.AGM_84E_Harpoon_SLAM__Stand_Off_Land_Attack_Missile_,
  "{AGM_84E}": Weapons.AGM_84E_Harpoon_SLAM__Stand_Off_Land_Attack_Missile__,
  "{AGM_84H}": Weapons.AGM_84H_SLAM_ER__Expanded_Response_,
  "{769A15DF-6AFB-439F-9B24-5B7A45C59D16}": Weapons.AGM_86C_ALCM,
  "{B06DD79A-F21E-4EB9-BD9D-AB3844618C9C}":
    Weapons.AGM_88C_HARM___High_Speed_Anti_Radiation_Missile,
  "{B06DD79A-F21E-4EB9-BD9D-AB3844618C93}":
    Weapons.AGM_88C_HARM___High_Speed_Anti_Radiation_Missile_,
  "{C8E06185-7CD6-4C90-959F-044679E90751}":
    Weapons.AIM_120B_AMRAAM___Active_Rdr_AAM,
  "{40EF17B7-F508-45de-8566-6FFECC0C1AB8}":
    Weapons.AIM_120C_5_AMRAAM___Active_Rdr_AAM,
  "{AIM_54A_Mk47}": Weapons.AIM_54A_Mk47,
  "{SHOULDER AIM_54A_Mk47 L}": Weapons.AIM_54A_Mk47_,
  "{SHOULDER AIM_54A_Mk47 R}": Weapons.AIM_54A_Mk47__,
  "{AIM_54A_Mk60}": Weapons.AIM_54A_Mk60,
  "{SHOULDER AIM_54A_Mk60 L}": Weapons.AIM_54A_Mk60_,
  "{SHOULDER AIM_54A_Mk60 R}": Weapons.AIM_54A_Mk60__,
  "{AIM_54C_Mk47}": Weapons.AIM_54C_Mk47,
  "{SHOULDER AIM_54C_Mk47 L}": Weapons.AIM_54C_Mk47_,
  "{7575BA0B-7294-4844-857B-031A144B2595}":
    Weapons.AIM_54C_Mk47_Phoenix_IN__Semi_Active_Radar,
  "{SHOULDER AIM_54C_Mk47 R}": Weapons.AIM_54C_Mk47__,
  "{AIM-7E}": Weapons.AIM_7E_Sparrow_Semi_Active_Radar,
  "{SHOULDER AIM-7F}": Weapons.AIM_7F,
  "{BELLY AIM-7F}": Weapons.AIM_7F_,
  "{AIM-7F}": Weapons.AIM_7F_Sparrow_Semi_Active_Radar,
  "{SHOULDER AIM-7M}": Weapons.AIM_7M,
  "{SHOULDER AIM-7MH}": Weapons.AIM_7MH,
  "{BELLY AIM-7MH}": Weapons.AIM_7MH_,
  "{AIM-7H}": Weapons.AIM_7MH_Sparrow_Semi_Active_Radar,
  "{BELLY AIM-7M}": Weapons.AIM_7M_,
  "{8D399DDA-FF81-4F14-904D-099B34FE7918}":
    Weapons.AIM_7M_Sparrow_Semi_Active_Radar,
  "{AIM-9B}": Weapons.AIM_9B_Sidewinder_IR_AAM,
  "{AIM-9L}": Weapons.AIM_9L_Sidewinder_IR_AAM,
  "{6CEB49FC-DED8-4DED-B053-E1F033FF72D3}": Weapons.AIM_9M_Sidewinder_IR_AAM,
  "{AIM-9P5}": Weapons.AIM_9P5_Sidewinder_IR_AAM,
  "{9BFD8C90-F7AE-4e90-833B-BFD0CED0E536}": Weapons.AIM_9P_Sidewinder_IR_AAM,
  "{5CE2FF2A-645A-4197-B48D-8720AC69394F}": Weapons.AIM_9X_Sidewinder_IR_AAM,
  "{VIGGEN_X-TANK}": Weapons.AJS_External_tank_1013kg_fuel,
  "{AKAN}": Weapons.AKAN_M_55_Gunpod__150_rnds_MINGR55_HE,
  "{E6747967-B1F0-4C77-977B-AB2E6EB0C102}": Weapons.ALARM,
  "{6D21ECEA-F85B-4E8D-9D51-31DC9B8AA4EF}": Weapons.ALQ_131___ECM_Pod,
  ALQ_184: Weapons.ALQ_184,
  "{A111396E-D3E8-4b9c-8AC9-2432489304D5}":
    Weapons.AN_AAQ_28_LITENING___Targeting_Pod,
  "{AAQ-28_LEFT}": Weapons.AN_AAQ_28_LITENING___Targeting_Pod_,
  "{ALQ_164_RF_Jammer}": Weapons.AN_ALQ_164_DECM_Pod,
  "{1C2B16EB-8EB0-43de-8788-8EBB2D70B8BC}":
    Weapons.AN_ASQ_173_Laser_Spot_Tracker_Strike_CAMera__LST_SCAM_,
  "{AN_ASQ_228}": Weapons.AN_ASQ_228_ATFLIR___Targeting_Pod,
  "{AIS_ASQ_T50}": Weapons.AN_ASQ_T50_TCTS_Pod___ACMI_Pod,
  "{AN-M3}": Weapons.AN_M3,
  "{AN_M30A1}": Weapons.AN_M30A1___100lb_GP_Bomb_LD,
  "{AN_M57}": Weapons.AN_M57___250lb_GP_Bomb_LD,
  "{AN-M64}": Weapons.AN_M64___500lb_GP_Bomb_LD,
  "{F86ANM64}": Weapons.AN_M64___500lb_GP_Bomb_LD_,
  "{AN_M65}": Weapons.AN_M65___1000lb_GP_Bomb_LD,
  "{AN_M66}": Weapons.AN_M66___2000lb_GP_Bomb_LD,
  "{APU-60-1_R_60M}": Weapons.APU_60_1M_with_R_60M__AA_8_Aphid____Infra_Red,
  "{B0DBC591-0F52-4F7D-AD7B-51E67725FB81}":
    Weapons.APU_60_2M_with_2_x_R_60M__AA_8_Aphid____Infra_Red,
  "{275A2855-4A79-4B2D-B082-91EA2ADF4691}":
    Weapons.APU_60_2M_with_2_x_R_60M__AA_8_Aphid____Infra_Red_,
  "{APU_68_S-24}": Weapons.APU_68___S_24B,
  "{A6FD14D3-6D30-4C85-88A7-8D17BEE120E2}": Weapons.APU_6___6_9A4172_Vikhr,
  "{F789E86A-EE2E-4E6B-B81E-D5E5F903B6ED}": Weapons.APU_8___8_9A4172_Vikhr,
  "{ARAKM70BAP}": Weapons.ARAK_M_70B_AP_6x_135mm_UnGd_Rkts__Pshu70_HEAT,
  "{ARAKM70BHE}": Weapons.ARAK_M_70B_HE_6x_135mm_UnGd_Rkts__Shu70_HE_FRAG,
  "{ASO-2}": Weapons.ASO_2,
  "{M2KC_RAFAUT_BLG66}": Weapons.AUF2_BLG_66_AC_x_2,
  "{M2KC_RAFAUT_GBU12}": Weapons.AUF2_GBU_12_x_2,
  "{M2KC_RAFAUT_MK82S}": Weapons.AUF2_MK_82_Snakeyes_x_2,
  "{M2KC_RAFAUT_MK82}": Weapons.AUF2_MK_82_x_2,
  "{M2KC_RAFAUT_ROCKEYE}": Weapons.AUF2_ROCKEYE_x_2,
  "{AWW-13}": Weapons.AWW_13_DATALINK_POD,
  "{BDU-33}": Weapons.BDU_33___25lb_Practice_Bomb_LD,
  "{BDU_45}": Weapons.BDU_45,
  "{BDU_45B}": Weapons.BDU_45B,
  "{BDU-50HD}": Weapons.BDU_50HD___500lb_Inert_Practice_Bomb_HD,
  "{BDU-50LD}": Weapons.BDU_50LD___500lb_Inert_Practice_Bomb_LD,
  "{BDU-50LGB}": Weapons.BDU_50LGB___500lb_Laser_Guided_Inert_Practice_Bomb_LD,
  "{BETAB-500M}": Weapons.BETAB_500M,
  "{BETAB-500S}": Weapons.BETAB_500S,
  "{BEER_BOMB}": Weapons.Beer_Bomb,
  "Beer_Bomb_(D)_on_LH_Spitfire_Wing_Carrier":
    Weapons.Beer_Bomb__D__on_LH_Spitfire_Wing_Carrier,
  "Beer_Bomb_(D)_on_RH_Spitfire_Wing_Carrier":
    Weapons.Beer_Bomb__D__on_RH_Spitfire_Wing_Carrier,
  "Beer_Bomb_(L)_on_LH_Spitfire_Wing_Carrier":
    Weapons.Beer_Bomb__L__on_LH_Spitfire_Wing_Carrier,
  "Beer_Bomb_(L)_on_RH_Spitfire_Wing_Carrier":
    Weapons.Beer_Bomb__L__on_RH_Spitfire_Wing_Carrier,
  "{BLG66_BELOUGA}": Weapons.Belouga,
  "{BD289E34-DF84-4C5E-9220-4B14C346E79D}":
    Weapons.BetAB_500ShP___500kg_Concrete_Piercing_HD_w_booster_Bomb,
  "{35B698AC-9FEF-4EC4-AD29-484A0085F62B}":
    Weapons.BetAB_500___500kg_Concrete_Piercing_Bomb_LD,
  BF109K_4_FUEL_TANK: Weapons.BF109K_4_FUEL_TANK,
  BGM_109: Weapons.BGM_109,
  "BGM-109B": Weapons.BGM_109B,
  BIN_200: Weapons.BIN_200,
  "{BK90}": Weapons.BK_90_MJ12__12x_MJ2_HEAT___36x_MJ1_HE_FRAG_Bomblets_,
  "{BK90MJ1}": Weapons.BK_90_MJ1__72_x_MJ1_HE_FRAG_Bomblets_,
  "{BK90MJ2}": Weapons.BK_90_MJ2__24_x_MJ2_HEAT_Bomblets_,
  "{BLG66_BELOUGA_AC}": Weapons.BLG_66_AC_Belouga,
  "{BLG66_AC}": Weapons.BLG_66_Belouga___290kg_CBU__151_Frag_Pen_bomblets,
  "{752B9781-F962-11d5-9190-00A0249B6F00}":
    Weapons.BLU_107___440lb_Anti_Runway_Penetrator_Bomb,
  "{08164777-5E9C-4B08-B48E-5AA7AFB246E2}":
    Weapons.BL_755_CBU___450kg__147_Frag_Pen_bomblets,
  "{8C3F26A1-FA0F-11d5-9190-00A0249B6F00}":
    Weapons.BOZ_107___Countermeasure_Dispenser,
  "{BRU33_LAU10}":
    Weapons.BRU_33_with_1_x_LAU_10_pod___4_x_127mm_ZUNI__UnGd_Rkts_Mk71__HE_FRAG,
  "{BRU33_LAU61}":
    Weapons.BRU_33_with_1_x_LAU_61_pod___19_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{BRU33_LAU68}":
    Weapons.BRU_33_with_1_x_LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{BRU33_LAU68_MK5}":
    Weapons.BRU_33_with_1_x_LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk5__HEAT,
  "{BRU33_2X_BDU-45B}": Weapons.BRU_33_with_2_x_BDU_45B___500lb_Practice_Bomb,
  "{BRU33_2X_BDU-45}": Weapons.BRU_33_with_2_x_BDU_45___500lb_Practice_Bomb,
  "{BRU33_2X_CBU-99}":
    Weapons.BRU_33_with_2_x_CBU_99___490lbs__247_x_HEAT_Bomblets,
  "{BRU33_2X_GBU-12}": Weapons.BRU_33_with_2_x_GBU_12___500lb_Laser_Guided_Bomb,
  "{BRU33_2X_GBU-16}":
    Weapons.BRU_33_with_2_x_GBU_16___1000lb_Laser_Guided_Bomb,
  "{BRU33_2*LAU10}":
    Weapons.BRU_33_with_2_x_LAU_10_pod___4_x_127mm_ZUNI__UnGd_Rkts_Mk71__HE_FRAG,
  "{BRU33_2*LAU61}":
    Weapons.BRU_33_with_2_x_LAU_61_pod___19_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{BRU33_2*LAU68}":
    Weapons.BRU_33_with_2_x_LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{BRU33_2*LAU68_MK5}":
    Weapons.BRU_33_with_2_x_LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk5__HEAT,
  "{BRU33_2X_ROCKEYE}":
    Weapons.BRU_33_with_2_x_Mk_20_Rockeye___490lbs_CBU__247_x_HEAT_Bomblets,
  "{BRU33_2X_MK-82Y}":
    Weapons.BRU_33_with_2_x_Mk_82Y___500lb_GP_Chute_Retarded_HD,
  "{BRU33_2X_MK-82_Snakeye}":
    Weapons.BRU_33_with_2_x_Mk_82_Snakeye___500lb_GP_Bomb_HD,
  "{BRU33_2X_MK-82}": Weapons.BRU_33_with_2_x_Mk_82___500lb_GP_Bomb_LD,
  "{BRU33_2X_MK-83}": Weapons.BRU_33_with_2_x_Mk_83___1000lb_GP_Bomb_LD,
  "{BRU41_6X_BDU-33}": Weapons.BRU_41A_with_6_x_BDU_33___25lb_Practice_Bomb_LD,
  "{BRU41_6X_MK-82}": Weapons.BRU_41A_with_6_x_Mk_82___500lb_GP_Bomb_LD,
  "BRU-42_3*BDU-33": Weapons.BRU_42_3_BDU_33,
  "BRU-42_3*GBU-12": Weapons.BRU_42_3_GBU_12,
  "BRU-42_LS": Weapons.BRU_42_LS,
  "{62BE78B1-9258-48AE-B882-279534C0D278}":
    Weapons.BRU_42_with_2_x_GBU_10___2000lb_Laser_Guided_Bombs,
  "{EB969276-1922-4ED1-A5CB-18590F45D7FE}":
    Weapons.BRU_42_with_2_x_GBU_27___2000lb_Laser_Guided_Penetrator_Bombs,
  "{88D49E04-78DF-4F08-B47E-B81247A9E3C5}":
    Weapons.BRU_42_with_3_x_GBU_16___1000lb_Laser_Guided_Bombs,
  "{LAU-131x3 - 7 AGR-20A}":
    Weapons.BRU_42_with_3_x_LAU_131_pods___7_x_2_75_Hydra__Laser_Guided_Rkts_M151__HE_APKWS,
  "{LAU-131x3 - 7 AGR-20 M282}":
    Weapons.BRU_42_with_3_x_LAU_131_pods___7_x_2_75_Hydra__Laser_Guided_Rkts_M282__MPP_APKWS,
  "{64329ED9-B14C-4c0b-A923-A3C911DA1527}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{C2593383-3CA8-4b18-B73D-0E750BCA1C85}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_M156__Wht_Phos,
  "{E6966004-A525-4f47-AF94-BCFEDF8FDBDA}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_M257__Para_Illum,
  "{4C044B08-886B-46c8-9B1F-AB05B3ED9C1D}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_M274__Practice_Smk,
  "{443364AE-D557-488e-9499-45EDB3BA6730}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_Mk1__Practice,
  "{9BC82B3D-FE70-4910-B2B7-3E54EFE73262}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_Mk5__HEAT,
  "{C0FA251E-B645-4ce5-926B-F4BC20822F8B}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_Mk61__Practice,
  "{A1853B38-2160-4ffe-B7E9-9BF81E6C3D77}":
    Weapons.BRU_42_with_3_x_LAU_68_pods___21_x_2_75_Hydra__UnGd_Rkts_WTU_1_B__Practice,
  "{B83CB620-5BBE-4BEA-910C-EB605A327EF9}":
    Weapons.BRU_42_with_3_x_Mk_20_Rockeye___490lbs_CBUs__247_x_HEAT_Bomblets,
  "{7B34E0BB-E427-4C2A-A61A-8407CE18B54D}":
    Weapons.BRU_42_with_3_x_Mk_81___250lb_GP_Bombs_LD,
  "{BRU-42_3*Mk-82AIR}":
    Weapons.BRU_42_with_3_x_Mk_82_AIR_Ballute___500lb_GP_Bombs_HD,
  "{60CC734F-0AFA-4E2E-82B8-93B941AB11CF}":
    Weapons.BRU_42_with_3_x_Mk_82___500lb_GP_Bombs_LD,
  "{BRU-42_LS_3*SUU-25_8*LUU-2}":
    Weapons.BRU_42_with_3_x_SUU_25_x_8_LUU_2___Target_Marker_Flares,
  "{BRU55_2*AGM-154A}": Weapons.BRU_55_with_2_x_AGM_154A___JSOW_CEB__CBU_type_,
  "{BRU55_2*AGM-154C}": Weapons.BRU_55_with_2_x_AGM_154C___JSOW_Unitary_BROACH,
  "{BRU55_2*GBU-38}":
    Weapons.BRU_55_with_2_x_GBU_38___JDAM__500lb_GPS_Guided_Bomb,
  "{BRU57_2*AGM-154A}": Weapons.BRU_57_with_2_x_AGM_154A___JSOW_CEB__CBU_type_,
  "{BRU57_2*CBU-103}": Weapons.BRU_57_with_2_x_CBU_103,
  "{BRU57_2*CBU-105}": Weapons.BRU_57_with_2_x_CBU_105,
  "{BRU57_2*GBU-38}":
    Weapons.BRU_57_with_2_x_GBU_38___JDAM__500lb_GPS_Guided_Bomb,
  BR_250: Weapons.BR_250,
  BR_500: Weapons.BR_500,
  British_GP_250LBS_Bomb_MK4_on_LH_Spitfire_Wing_Carrier:
    Weapons.British_GP_250LBS_Bomb_MK4_on_LH_Spitfire_Wing_Carrier,
  British_GP_250LBS_Bomb_MK4_on_RH_Spitfire_Wing_Carrier:
    Weapons.British_GP_250LBS_Bomb_MK4_on_RH_Spitfire_Wing_Carrier,
  British_GP_500LBS_Bomb_MK4_on_British_UniversalBC_MK3:
    Weapons.British_GP_500LBS_Bomb_MK4_on_British_UniversalBC_MK3,
  "{FC56DF80-9B09-44C5-8976-DCFAFF219062}":
    Weapons.B_13L_pod___5_x_S_13_OF__122mm_UnGd_Rkts__Blast_Frag,
  "B-1B_Mk-84*8": Weapons.B_1B_Mk_84_8,
  "{F72F47E5-C83A-4B85-96ED-D3E46671EE9A}":
    Weapons.B_8M1_pod___20_x_S_8KOM__80mm_UnGd_Rkts__HEAT_AP,
  "{3DFB7320-AB0E-11d7-9897-000476191836}":
    Weapons.B_8M1_pod___20_x_S_8TsM__80mm_UnGd_Rkts__Smk,
  "B-8M1 - 20 S-8OFP2": Weapons.B_8M1___20_S_8OFP2,
  B_8V20A_CM: Weapons.B_8V20A_CM,
  B_8V20A_CM_BU: Weapons.B_8V20A_CM_BU,
  B_8V20A_CM_GN: Weapons.B_8V20A_CM_GN,
  B_8V20A_CM_RD: Weapons.B_8V20A_CM_RD,
  B_8V20A_CM_VT: Weapons.B_8V20A_CM_VT,
  B_8V20A_CM_WH: Weapons.B_8V20A_CM_WH,
  B_8V20A_CM_YE: Weapons.B_8V20A_CM_YE,
  B_8V20A_OFP2: Weapons.B_8V20A_OFP2,
  B_8V20A_OM: Weapons.B_8V20A_OM,
  "{6A4B9E69-64FE-439a-9163-3A87FB6A4D81}":
    Weapons.B_8V20A_pod___20_x_S_8KOM__80mm_UnGd_Rkts__HEAT_AP,
  "CATM-9M": Weapons.CATM_9M,
  "CBLS-200": Weapons.CBLS_200,
  "CBU87*10": Weapons.CBU87_10,
  "CBU97*10": Weapons.CBU97_10,
  "{CBU_103}": Weapons.CBU_103___202_x_CEM__CBU_with_WCMD,
  "{CBU_105}": Weapons.CBU_105___10_x_CEM__CBU_with_WCMD,
  "{CBU-52B}": Weapons.CBU_52B___220_x_HE_Frag_bomblets,
  "{CBU-87}": Weapons.CBU_87___202_x_CEM_Cluster_Bomb,
  "{5335D97A-35A5-4643-9D9B-026C75961E52}":
    Weapons.CBU_97___10_x_CEM_Cluster_Bomb,
  "{CBU_99}": Weapons.CBU_99___490lbs__247_x_HEAT_Bomblets,
  "{C-101-DEFA553}": Weapons.DEFA_553,
  "DIS_AKD-10": Weapons.DIS_AKD_10,
  DIS_AKG_DLPOD: Weapons.DIS_AKG_DLPOD,
  DIS_BRM1_90: Weapons.DIS_BRM1_90,
  "DIS_CM-802AKG": Weapons.DIS_CM_802AKG,
  "DIS_C-701IR": Weapons.DIS_C_701IR,
  "DIS_C-701T": Weapons.DIS_C_701T,
  "DIS_C-802AK": Weapons.DIS_C_802AK,
  DIS_GB6: Weapons.DIS_GB6,
  DIS_GB6_HE: Weapons.DIS_GB6_HE,
  DIS_GB6_TSP: Weapons.DIS_GB6_TSP,
  DIS_GBU_10: Weapons.DIS_GBU_10,
  DIS_GBU_12: Weapons.DIS_GBU_12,
  DIS_GBU_12_DUAL_GDJ_II19_L: Weapons.DIS_GBU_12_DUAL_GDJ_II19_L,
  DIS_GBU_12_DUAL_GDJ_II19_R: Weapons.DIS_GBU_12_DUAL_GDJ_II19_R,
  DIS_GBU_16: Weapons.DIS_GBU_16,
  DIS_LAU68_MK5_DUAL_GDJ_II19_L: Weapons.DIS_LAU68_MK5_DUAL_GDJ_II19_L,
  DIS_LAU68_MK5_DUAL_GDJ_II19_R: Weapons.DIS_LAU68_MK5_DUAL_GDJ_II19_R,
  "DIS_LD-10": Weapons.DIS_LD_10,
  "DIS_LD-10_DUAL_L": Weapons.DIS_LD_10_DUAL_L,
  "DIS_LD-10_DUAL_R": Weapons.DIS_LD_10_DUAL_R,
  DIS_LS_6_500: Weapons.DIS_LS_6_500,
  DIS_MK_20: Weapons.DIS_MK_20,
  DIS_MK_20_DUAL_GDJ_II19_L: Weapons.DIS_MK_20_DUAL_GDJ_II19_L,
  DIS_MK_20_DUAL_GDJ_II19_R: Weapons.DIS_MK_20_DUAL_GDJ_II19_R,
  DIS_MK_82S_DUAL_GDJ_II19_L: Weapons.DIS_MK_82S_DUAL_GDJ_II19_L,
  DIS_MK_82S_DUAL_GDJ_II19_R: Weapons.DIS_MK_82S_DUAL_GDJ_II19_R,
  DIS_MK_82_DUAL_GDJ_II19_L: Weapons.DIS_MK_82_DUAL_GDJ_II19_L,
  DIS_MK_82_DUAL_GDJ_II19_R: Weapons.DIS_MK_82_DUAL_GDJ_II19_R,
  "DIS_PL-12": Weapons.DIS_PL_12,
  "DIS_PL-5EII": Weapons.DIS_PL_5EII,
  "DIS_PL-5EII_TIP": Weapons.DIS_PL_5EII_TIP,
  "DIS_PL-8A": Weapons.DIS_PL_8A,
  "DIS_PL-8B": Weapons.DIS_PL_8B,
  DIS_RKT_90_UG: Weapons.DIS_RKT_90_UG,
  "DIS_SD-10": Weapons.DIS_SD_10,
  "DIS_SD-10_DUAL_L": Weapons.DIS_SD_10_DUAL_L,
  "DIS_SD-10_DUAL_R": Weapons.DIS_SD_10_DUAL_R,
  DIS_SMOKE_GENERATOR_B: Weapons.DIS_SMOKE_GENERATOR_B,
  DIS_SMOKE_GENERATOR_G: Weapons.DIS_SMOKE_GENERATOR_G,
  DIS_SMOKE_GENERATOR_O: Weapons.DIS_SMOKE_GENERATOR_O,
  DIS_SMOKE_GENERATOR_R: Weapons.DIS_SMOKE_GENERATOR_R,
  DIS_SMOKE_GENERATOR_W: Weapons.DIS_SMOKE_GENERATOR_W,
  DIS_SMOKE_GENERATOR_Y: Weapons.DIS_SMOKE_GENERATOR_Y,
  DIS_SPJ_POD: Weapons.DIS_SPJ_POD,
  DIS_TANK1100: Weapons.DIS_TANK1100,
  DIS_TANK800: Weapons.DIS_TANK800,
  DIS_TYPE200: Weapons.DIS_TYPE200,
  DIS_TYPE200_DUAL_L: Weapons.DIS_TYPE200_DUAL_L,
  DIS_TYPE200_DUAL_R: Weapons.DIS_TYPE200_DUAL_R,
  DIS_WMD7: Weapons.DIS_WMD7,
  "{DWS39_MJ1}": Weapons.DWS39_MJ1,
  "{DWS39_MJ1_MJ2}": Weapons.DWS39_MJ1_MJ2,
  "{DWS39_MJ2}": Weapons.DWS39_MJ2,
  "{Eclair}": Weapons.Eclair,
  ER_4_SC50: Weapons.ER_4_SC50,
  "{0519A261-0AB6-11d6-9193-00A0249B6F00}": Weapons.ETHER,
  FAB_100M: Weapons.FAB_100M,
  "{FAB-100-4}": Weapons.FAB_100_x_4,
  "{FB3CE165-BF07-4979-887C-92B87F13276B}": Weapons.FAB_100___100kg_GP_Bomb_LD,
  "{40AA4ABE-D6EB-4CD6-AEFE-A1A0477B24AB}":
    Weapons.FAB_1500_M_54___1500kg_GP_Bomb_LD,
  "{FAB-250-M54}": Weapons.FAB_250_M54,
  "{FAB-250-M54-TU}": Weapons.FAB_250_M54_TU,
  "{3C612111-C7AD-476E-8A8E-2485812F4E5C}": Weapons.FAB_250___250kg_GP_Bomb_LD,
  FAB_50: Weapons.FAB_50,
  "{FAB-500-M54}": Weapons.FAB_500_M54,
  "{FAB-500-M54-TU}": Weapons.FAB_500_M54_TU,
  "{37DCC01E-9E02-432F-B61D-10C166CA2798}":
    Weapons.FAB_500_M_62___500kg_GP_Bomb_LD,
  "{FAB-500-SL}": Weapons.FAB_500_SL,
  "{FAB-500-TA}": Weapons.FAB_500_TA,
  FIM_92: Weapons.FIM_92,
  "{FPU_8A_FUEL_TANK}": Weapons.FPU_8A_Fuel_Tank_330_gallons,
  "{PTB_120_F86F35}": Weapons.Fuel_Tank_120_gallons,
  "{PTB_150L_L39}": Weapons.Fuel_Tank_150_liters,
  "{PTB_200_F86F35}": Weapons.Fuel_Tank_200_gallons,
  "{PTB_350L_L39}": Weapons.Fuel_Tank_350_liters,
  "{PTB_490C_MIG21}": Weapons.Fuel_Tank_490_L_Central__21_,
  "{PTB_490_MIG21}": Weapons.Fuel_Tank_490_L__21_,
  "{PTB_800_MIG21}": Weapons.Fuel_Tank_800_L__21_,
  Fuel_Tank_FT600: Weapons.Fuel_Tank_FT600,
  "{414E383A-59EB-41BC-8566-2B5E0788ED1F}": Weapons.Fuel_tank_1150L,
  "{C0FF4842-FBAC-11d5-9190-00A0249B6F00}": Weapons.Fuel_tank_1150L_MiG_29,
  "{2BEC576B-CDF5-4B7F-961F-B0FA4312B841}": Weapons.Fuel_tank_1400L,
  "{16602053-4A12-40A2-B214-AB60D481B20E}": Weapons.Fuel_tank_2000L,
  "{7D7EC917-05F6-49D4-8045-61FC587DD019}": Weapons.Fuel_tank_3000L,
  "{8A0BE8AE-58D4-4572-9263-3144C0D06364}": Weapons.Fuel_tank_300_gal,
  "{F14-300gal}": Weapons.Fuel_tank_300_gal_,
  "{F14-300gal-empty}": Weapons.Fuel_tank_300_gal__empty_,
  "{EFEC8200-B922-11d7-9897-000476191836}": Weapons.Fuel_tank_330_gal,
  "{EFEC8201-B922-11d7-9897-000476191836}": Weapons.Fuel_tank_330_gal_,
  "{82364E69-5564-4043-A866-E13032926C3E}": Weapons.Fuel_tank_367_gal,
  "{F376DBEE-4CAE-41BA-ADD9-B2910AC95DEC}": Weapons.Fuel_tank_370_gal,
  "{0855A3A1-FA50-4C89-BDBB-5D5360ABA071}": Weapons.Fuel_tank_5000L,
  "{E1F29B21-F291-4589-9FD8-3272EEC69506}": Weapons.Fuel_tank_610_gal,
  "{A5BAEAB7-6FAF-4236-AF72-0FD900F493F9}": Weapons.Fuel_tank_800L,
  "{E8D4652F-FD48-45B7-BA5B-2AE05BB5A9CF}": Weapons.Fuel_tank_800L_Wing,
  "{B99EE8A8-99BC-4a8d-89AC-A26831920DCE}": Weapons.Fuel_tank_Ka_50,
  "{A504D93B-4E80-4B4F-A533-0D9B65F2C55F}": Weapons.Fuel_tank_S_3,
  FW109_FUEL_TANK: Weapons.FW109_FUEL_TANK,
  "{8B9E3FD0-F034-4A07-B6CE-C269884CC71B}": Weapons.F_4_Fuel_tank_C,
  "{7B4B122D-C12C-4DB4-834E-4D8BB4D863A8}": Weapons.F_4_Fuel_tank_W,
  "{PTB-150GAL}": Weapons.F_5_150Gal_Fuel_tank,
  "{0395076D-2F77-4420-9D33-087A4398130B}": Weapons.F_5_275Gal_Fuel_tank,
  "{GAU_12_Equalizer}": Weapons.GAU_12_Gunpod,
  "{BRU-32 GBU-10}": Weapons.GBU_10,
  "{51F9AAE5-964F-4D21-83FB-502E3BFE5F8A}":
    Weapons.GBU_10___2000lb_Laser_Guided_Bomb,
  "{BRU-32 GBU-12}": Weapons.GBU_12,
  "{DB769D48-67D7-42ED-A2BE-108D566C8B1E}":
    Weapons.GBU_12___500lb_Laser_Guided_Bomb,
  "{BRU-32 GBU-16}": Weapons.GBU_16,
  "{0D33DDAE-524F-4A4E-B5B8-621754FE3ADE}":
    Weapons.GBU_16___1000lb_Laser_Guided_Bomb,
  "{BRU-32 GBU-24}": Weapons.GBU_24,
  "{34759BBC-AF1E-4AEE-A581-498FF7A6EBCE}":
    Weapons.GBU_24_Paveway_III___2000lb_Laser_Guided_Bomb,
  "{GBU-24}": Weapons.GBU_24_Paveway_III___2000lb_Laser_Guided_Bomb_,
  "{EF0A9419-01D6-473B-99A3-BEBDB923B14D}":
    Weapons.GBU_27___2000lb_Laser_Guided_Penetrator_Bomb,
  "{F06B775B-FC70-44B5-8A9F-5B5E2EB839C7}":
    Weapons.GBU_28___5000lb_Laser_Guided_Penetrator_Bomb,
  "GBU-31V3B*8": Weapons.GBU_31V3B_8,
  "GBU-31*8": Weapons.GBU_31_8,
  "{GBU-31}": Weapons.GBU_31_V_1_B___JDAM__2000lb_GPS_Guided_Bomb,
  "{GBU_31_V_2B}": Weapons.GBU_31_V_2_B___JDAM__2000lb_GPS_Guided_Bomb,
  "{GBU-31V3B}": Weapons.GBU_31_V_3_B___JDAM__2000lb_GPS_Guided_Penetrator_Bomb,
  "{GBU_31_V_4B}":
    Weapons.GBU_31_V_4_B___JDAM__2000lb_GPS_Guided_Penetrator_Bomb,
  "{GBU_32_V_2B}": Weapons.GBU_32_V_2_B___JDAM__1000lb_GPS_Guided_Bomb,
  "GBU-38*16": Weapons.GBU_38_16,
  "{GBU-38}": Weapons.GBU_38___JDAM__500lb_GPS_Guided_Bomb,
  "{GBU_54_V_1B}": Weapons.GBU_54B___LJDAM__500lb_Laser__GPS_Guided_Bomb_LD,
  GUV_VOG: Weapons.GUV_VOG,
  GUV_YakB_GSHP: Weapons.GUV_YakB_GSHP,
  "{HOT3G}": Weapons.HOT3,
  "{HOT3D}": Weapons.HOT3_,
  "{4CD2BB0F-5493-44EF-A927-9760350F7BA1}":
    Weapons.HSAB_with_9_x_Mk_20_Rockeye___490lbs_CBUs__247_x_HEAT_Bomblets,
  "{696CFFC4-0BDE-42A8-BE4B-0BE3D9DD723C}":
    Weapons.HSAB_with_9_x_Mk_83___1000lb_GP_Bombs_LD,
  "{HVAR_SMOKE_2}": Weapons.HVAR_SMOKE__UnGd_Rkt,
  "{HVAR_SMOKE_GENERATOR}": Weapons.HVAR_Smoke_Generator,
  "{HVAR}": Weapons.HVAR__UnGd_Rkt,
  I16_DROP_FUEL_TANK: Weapons.I16_DROP_FUEL_TANK,
  I16_FAB_100SV: Weapons.I16_FAB_100SV,
  I16_RS_82: Weapons.I16_RS_82,
  "{IAB-500}": Weapons.IAB_500,
  "{IR_Deflector}": Weapons.IR_Deflector,
  "{KAB_1500Kr_LOADOUT}": Weapons.KAB_1500Kr___1500kg_TV_Guided_Bomb,
  "{KAB_1500LG_LOADOUT}":
    Weapons.KAB_1500LG_Pr___1500kg_Laser_Guided_Penetrator_Bomb,
  "{39821727-F6E2-45B3-B1F0-490CC8921D1E}":
    Weapons.KAB_1500L___1500kg_Laser_Guided_Bomb,
  "{E2C426E3-8B10-4E09-B733-9CDC26520F48}":
    Weapons.KAB_500Kr___500kg_TV_Guided_Bomb,
  "{BA565F89-2373-4A84-9502-A0E017D3A44A}":
    Weapons.KAB_500LG___500kg_Laser_Guided_Bomb,
  "{KAB_500S_LOADOUT}": Weapons.KAB_500S___500kg_GPS_Guided_Bomb,
  "{KB}": Weapons.KB_Flare_Chaff_dispenser_pod,
  "{12429ECF-03F0-4DF6-BCBD-5D38B6343DE1}":
    Weapons.Kh_22__AS_4_Kitchen____1000kg__AShM__IN__Act_Pas_Rdr,
  "{9F390892-E6F9-42C9-B84E-1136A881DCB2}":
    Weapons.Kh_23L_Grom__AS_7_Kerry____286kg__ASM__Laser_Guided,
  "{6DADF342-D4BA-4D8A-B081-BA928C4AF86D}":
    Weapons.Kh_25ML__AS_10_Karen____300kg__ASM__Semi_Act_Laser,
  "{79D73885-0801-45a9-917F-C90FE1CE3DFC}":
    Weapons.Kh_25ML__AS_10_Karen____300kg__ASM__Semi_Act_Laser_,
  "{X-25ML}": Weapons.Kh_25ML__AS_10_Karen____300kg__ASM__Semi_Act_Laser__,
  "{E86C5AA5-6D49-4F00-AD2E-79A62D6DDE26}":
    Weapons.Kh_25MPU__Updated_AS_12_Kegler____320kg__ARM__IN__Pas_Rdr,
  "{752AF1D2-EBCC-4bd7-A1E7-2357F5601C70}":
    Weapons.Kh_25MPU__Updated_AS_12_Kegler____320kg__ARM__IN__Pas_Rdr_,
  "{X-25MPU}":
    Weapons.Kh_25MPU__Updated_AS_12_Kegler____320kg__ARM__IN__Pas_Rdr__,
  "{Kh-25MP}": Weapons.Kh_25MP__AS_12_Kegler____320kg__ARM__Pas_Rdr,
  "{292960BB-6518-41AC-BADA-210D65D5073C}":
    Weapons.Kh_25MR__AS_10_Karen____300kg__ASM__10km__RC_Guided,
  "{X-25MR}": Weapons.Kh_25MR__AS_10_Karen____300kg__ASM__RC_Guided,
  "{Kh-28}": Weapons.Kh_28__AS_9_Kyle____720kg__ARM__Pas_Rdr,
  "{3468C652-E830-4E73-AFA9-B5F260AB7C3D}":
    Weapons.Kh_29L__AS_14_Kedge____657kg__ASM__Semi_Act_Laser,
  "{D4A8D9B9-5C45-42e7-BBD2-0E54F8308432}":
    Weapons.Kh_29L__AS_14_Kedge____657kg__ASM__Semi_Act_Laser_,
  "{X-29L}": Weapons.Kh_29L__AS_14_Kedge____657kg__ASM__Semi_Act_Laser__,
  "{B4FC81C9-B861-4E87-BBDC-A1158E648EBF}":
    Weapons.Kh_29T__AS_14_Kedge____670kg__ASM__TV_Guided,
  "{601C99F7-9AF3-4ed7-A565-F8B8EC0D7AAC}":
    Weapons.Kh_29T__AS_14_Kedge____670kg__ASM__TV_Guided_,
  "{X-29T}": Weapons.Kh_29T__AS_14_Kedge____670kg__ASM__TV_Guided__,
  "{4D13E282-DF46-4B23-864A-A9423DFDE504}":
    Weapons.Kh_31A__AS_17_Krypton____610kg__AShM__IN__Act_Rdr,
  "{4D13E282-DF46-4B23-864A-A9423DFDE50A}":
    Weapons.Kh_31A__AS_17_Krypton____610kg__AShM__IN__Act_Rdr_,
  "{X-31A}": Weapons.Kh_31A__AS_17_Krypton____610kg__AShM__IN__Act_Rdr__,
  "{D8F2C90B-887B-4B9E-9FE2-996BC9E9AF03}":
    Weapons.Kh_31P__AS_17_Krypton____600kg__ARM__IN__Pas_Rdr,
  "{D8F2C90B-887B-4B9E-9FE2-996BC9E9AF0A}":
    Weapons.Kh_31P__AS_17_Krypton____600kg__ARM__IN__Pas_Rdr_,
  "{X-31P}": Weapons.Kh_31P__AS_17_Krypton____600kg__ARM__IN__Pas_Rdr__,
  "{2234F529-1D57-4496-8BB0-0150F9BDBBD2}":
    Weapons.Kh_35__AS_20_Kayak____520kg__AShM__IN__Act_Rdr,
  "{2234F529-1D57-4496-8BB0-0150F9BDBBD3}":
    Weapons.Kh_35__AS_20_Kayak____520kg__AShM__IN__Act_Rdr_,
  "{3F26D9C5-5CC3-4E42-BC79-82FAA54E9F26}":
    Weapons.Kh_41__SS_N_22_Sunburn____4500kg__AShM__IN__Act_Rdr,
  "{FE382A68-8620-4AC0-BDF5-709BFE3977D7}":
    Weapons.Kh_58U__AS_11_Kilter____640kg__ARM__IN__Pas_Rdr,
  "{B5CA9846-776E-4230-B4FD-8BCC9BFB1676}":
    Weapons.Kh_58U__AS_11_Kilter____640kg__ARM__IN__Pas_Rdr_,
  "{40AB87E8-BEFB-4D85-90D9-B2753ACF9514}":
    Weapons.Kh_59M__AS_18_Kazoo____930kg__ASM__IN,
  "{BADAF2DE-68B5-472A-8AAC-35BAEFF6B4A1}":
    Weapons.Kh_65__AS_15B_Kent____1250kg__ASM__IN__MCC,
  "{Kh-66_Grom}": Weapons.Kh_66_Grom__21__APU_68,
  "{96A7F676-F956-404A-AD04-F33FB2C74884}":
    Weapons.KMGU_2___96_x_AO_2_5RT_Dispenser__CBU__HE_Frag,
  "{96A7F676-F956-404A-AD04-F33FB2C74881}":
    Weapons.KMGU_2___96_x_PTAB_2_5KO_Dispenser__CBU__HEAT_AP,
  KORD_12_7: Weapons.KORD_12_7,
  "{F4920E62-A99A-11d8-9897-000476191836}": Weapons.Kopyo_radar_pod,
  "{7210496B-7B81-4B52-80D6-8529ECF847CD}": Weapons.Kormoran___ASM,
  "{K-13A}": Weapons.K_13A,
  "{44EE8698-89F9-48EE-AF36-5FD31896A82F}":
    Weapons.L005_Sorbtsiya_ECM_pod__left_,
  "{44EE8698-89F9-48EE-AF36-5FD31896A82A}":
    Weapons.L005_Sorbtsiya_ECM_pod__right_,
  "{ECM_POD_L_175V}": Weapons.L175V_Khibiny_ECM_pod,
  "{F14-LANTIRN-TP}": Weapons.LANTIRN_Targeting_Pod,
  LAU3_HE151: Weapons.LAU3_HE151,
  LAU3_HE5: Weapons.LAU3_HE5,
  LAU3_WP156: Weapons.LAU3_WP156,
  LAU3_WP1B: Weapons.LAU3_WP1B,
  LAU3_WP61: Weapons.LAU3_WP61,
  "LAU-105": Weapons.LAU_105,
  "LAU-105_1*AIM-9L_L": Weapons.LAU_105_1_AIM_9L_L,
  "LAU-105_1*AIM-9L_R": Weapons.LAU_105_1_AIM_9L_R,
  "LAU-105_1*AIM-9M_L": Weapons.LAU_105_1_AIM_9M_L,
  "LAU-105_1*AIM-9M_R": Weapons.LAU_105_1_AIM_9M_R,
  "LAU-105_1*CATM-9M_L": Weapons.LAU_105_1_CATM_9M_L,
  "LAU-105_1*CATM-9M_R": Weapons.LAU_105_1_CATM_9M_R,
  "LAU-105_2*AIM-9L": Weapons.LAU_105_2_AIM_9L,
  "LAU-105_2*AIM-9P5": Weapons.LAU_105_2_AIM_9P5,
  "LAU-105_2*CATM-9M": Weapons.LAU_105_2_CATM_9M,
  "LAU-105_AIS_ASQ_T50_L": Weapons.LAU_105_AIS_ASQ_T50_L,
  "LAU-105_AIS_ASQ_T50_R": Weapons.LAU_105_AIS_ASQ_T50_R,
  "{DB434044-F5D0-4F1F-9BA9-B73027E18DD3}":
    Weapons.LAU_105_with_2_x_AIM_9M_Sidewinder_IR_AAM,
  "{3C0745ED-8B0B-42eb-B907-5BD5C1717447}":
    Weapons.LAU_105_with_2_x_AIM_9P_Sidewinder_IR_AAM,
  "{LAU_10R}": Weapons.LAU_10R_pod___4_x_127mm_ZUNI__UnGd_Rkts_Mk71__HE_FRAG,
  "{F3EFE0AB-E91A-42D8-9CA2-B63C91ED570A}":
    Weapons.LAU_10_pod___4_x_127mm_ZUNI__UnGd_Rkts_Mk71__HE_FRAG,
  "{BRU42_LAU10}": Weapons.LAU_10___4_ZUNI_MK_71,
  "{BRU3242_LAU10}": Weapons.LAU_10___4_ZUNI_MK_71_,
  "{LAU-115 - AIM-7E}": Weapons.LAU_115C_with_AIM_7E_Sparrow_Semi_Active_Radar,
  "{LAU-115 - AIM-7F}": Weapons.LAU_115C_with_AIM_7F_Sparrow_Semi_Active_Radar,
  "{LAU-115 - AIM-7H}": Weapons.LAU_115C_with_AIM_7MH_Sparrow_Semi_Active_Radar,
  "LAU-115_2*LAU-127_AIM-120B": Weapons.LAU_115_2_LAU_127_AIM_120B,
  "LAU-115_2*LAU-127_AIM-120C": Weapons.LAU_115_2_LAU_127_AIM_120C,
  "LAU-115_2*LAU-127_AIM-9L": Weapons.LAU_115_2_LAU_127_AIM_9L,
  "LAU-115_2*LAU-127_AIM-9M": Weapons.LAU_115_2_LAU_127_AIM_9M,
  "LAU-115_2*LAU-127_AIM-9X": Weapons.LAU_115_2_LAU_127_AIM_9X,
  "LAU-115_2*LAU-127_CATM-9M": Weapons.LAU_115_2_LAU_127_CATM_9M,
  "LAU-115_LAU-127_AIM-9L": Weapons.LAU_115_LAU_127_AIM_9L,
  "LAU-115_LAU-127_AIM-9L_R": Weapons.LAU_115_LAU_127_AIM_9L_R,
  "LAU-115_LAU-127_AIM-9M": Weapons.LAU_115_LAU_127_AIM_9M,
  "LAU-115_LAU-127_AIM-9M_R": Weapons.LAU_115_LAU_127_AIM_9M_R,
  "LAU-115_LAU-127_AIM-9X": Weapons.LAU_115_LAU_127_AIM_9X,
  "LAU-115_LAU-127_AIM-9X_R": Weapons.LAU_115_LAU_127_AIM_9X_R,
  "LAU-115_LAU-127_CATM-9M": Weapons.LAU_115_LAU_127_CATM_9M,
  "LAU-115_LAU-127_CATM-9M_R": Weapons.LAU_115_LAU_127_CATM_9M_R,
  "{LAU-115 - AIM-120B}":
    Weapons.LAU_115_with_1_x_LAU_127_AIM_120B_AMRAAM___Active_Rdr_AAM,
  "{LAU-115 - AIM-120B_R}":
    Weapons.LAU_115_with_1_x_LAU_127_AIM_120B_AMRAAM___Active_Rdr_AAM_,
  "{LAU-115 - AIM-120C}":
    Weapons.LAU_115_with_1_x_LAU_127_AIM_120C_5_AMRAAM___Active_Rdr_AAM,
  "{LAU-115 - AIM-120C_R}":
    Weapons.LAU_115_with_1_x_LAU_127_AIM_120C_5_AMRAAM___Active_Rdr_AAM_,
  "{LAU-115 - AIM-7M}": Weapons.LAU_115_with_AIM_7M_Sparrow_Semi_Active_Radar,
  LAU_117_AGM_65A: Weapons.LAU_117_AGM_65A,
  LAU_117_AGM_65B: Weapons.LAU_117_AGM_65B,
  LAU_117_AGM_65F: Weapons.LAU_117_AGM_65F,
  LAU_117_AGM_65G: Weapons.LAU_117_AGM_65G,
  LAU_117_AGM_65H: Weapons.LAU_117_AGM_65H,
  LAU_117_AGM_65L: Weapons.LAU_117_AGM_65L,
  LAU_117_CATM_65K: Weapons.LAU_117_CATM_65K,
  LAU_117_TGM_65D: Weapons.LAU_117_TGM_65D,
  LAU_117_TGM_65G: Weapons.LAU_117_TGM_65G,
  LAU_117_TGM_65H: Weapons.LAU_117_TGM_65H,
  "{444BA8AE-82A7-4345-842E-76154EFCCA46}":
    Weapons.LAU_117_with_AGM_65D___Maverick_D__IIR_ASM_,
  "{F16A4DE0-116C-4A71-97F0-2CF85B0313EC}":
    Weapons.LAU_117_with_AGM_65E___Maverick_E__Laser_ASM___Lg_Whd_,
  "{69DC8AE7-8F77-427B-B8AA-B19D3F478B66}":
    Weapons.LAU_117_with_AGM_65K___Maverick_K__CCD_Imp_ASM_,
  "{3E6B632D-65EB-44D2-9501-1C2D04515405}":
    Weapons.LAU_118a_with_AGM_45B_Shrike_ARM__Imp_,
  "LAU-127_AIM-9L": Weapons.LAU_127_AIM_9L,
  "LAU-127_AIM-9M": Weapons.LAU_127_AIM_9M,
  "LAU-127_AIM-9X": Weapons.LAU_127_AIM_9X,
  "LAU-127_CATM-9M": Weapons.LAU_127_CATM_9M,
  LAU_131x3_HYDRA_70_M151: Weapons.LAU_131x3_HYDRA_70_M151,
  LAU_131x3_HYDRA_70_M156: Weapons.LAU_131x3_HYDRA_70_M156,
  LAU_131x3_HYDRA_70_M257: Weapons.LAU_131x3_HYDRA_70_M257,
  LAU_131x3_HYDRA_70_M274: Weapons.LAU_131x3_HYDRA_70_M274,
  LAU_131x3_HYDRA_70_MK1: Weapons.LAU_131x3_HYDRA_70_MK1,
  LAU_131x3_HYDRA_70_MK5: Weapons.LAU_131x3_HYDRA_70_MK5,
  LAU_131x3_HYDRA_70_MK61: Weapons.LAU_131x3_HYDRA_70_MK61,
  LAU_131x3_HYDRA_70_WTU1B: Weapons.LAU_131x3_HYDRA_70_WTU1B,
  "{LAU-131 - 7 AGR-20A}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__Laser_Guided_Rkts_M151__HE_APKWS,
  "{LAU-131 - 7 AGR-20 M282}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__Laser_Guided_Rkts_M282__MPP_APKWS,
  "{69926055-0DA8-4530-9F2F-C86B157EA9F6}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{2AF2EC3F-9065-4de5-93E1-1739C9A71EF7}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_M156__Wht_Phos,
  "{DAD45FE5-CFF0-4a2b-99D4-5D044D3BC22F}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_M257__Para_Illum,
  "{6D6D5C07-2A90-4a68-9A74-C5D0CFFB05D9}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_M274__Practice_Smk,
  "{D22C2D63-E5C9-4247-94FB-5E8F3DE22B71}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk1__Practice,
  "{319293F2-392C-4617-8315-7C88C22AF7C4}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk5__HEAT,
  "{1CA5E00B-D545-4ff9-9B53-5970E292F14D}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk61__Practice,
  "{DDCE7D70-5313-4181-8977-F11018681662}":
    Weapons.LAU_131_pod___7_x_2_75_Hydra__UnGd_Rkts_WTU_1_B__Practice,
  "{LAU-138 wtip - AIM-9L}": Weapons.LAU_138_AIM_9L,
  "{LAU-138 wtip - AIM-9M}": Weapons.LAU_138_AIM_9M,
  "{LAU3_FFAR_WP156}":
    Weapons.LAU_3_pod___19_x_2_75_FFAR__UnGd_Rkts_M156__Wht_Phos,
  "{LAU3_FFAR_MK1HE}": Weapons.LAU_3_pod___19_x_2_75_FFAR__UnGd_Rkts_Mk1__HE,
  "{LAU3_FFAR_MK5HEAT}":
    Weapons.LAU_3_pod___19_x_2_75_FFAR__UnGd_Rkts_Mk5__HEAT,
  "{LAU_61R}": Weapons.LAU_61R_pod___19_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{FD90A1DC-9147-49FA-BF56-CB83EF0BD32B}":
    Weapons.LAU_61_pod___19_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{3DFB7321-AB0E-11d7-9897-000476191836}":
    Weapons.LAU_61_pod___19_x_2_75_Hydra__UnGd_Rkts_M156__Wht_Phos,
  "{LAU68_FFAR_WP156}":
    Weapons.LAU_68_pod___7_x_2_75_FFAR__UnGd_Rkts_M156__Wht_Phos,
  "{LAU68_FFAR_MK1HE}": Weapons.LAU_68_pod___7_x_2_75_FFAR__UnGd_Rkts_Mk1__HE,
  "{LAU68_FFAR_MK5HEAT}":
    Weapons.LAU_68_pod___7_x_2_75_FFAR__UnGd_Rkts_Mk5__HEAT,
  "{A021F29D-18AB-4d3e-985C-FC9C60E35E9E}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "{4F977A2A-CD25-44df-90EF-164BFA2AE72F}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_M156__Wht_Phos,
  "{647C5F26-BDD1-41e6-A371-8DE1E4CC0E94}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_M257__Para_Illum,
  "{0877B74B-5A00-4e61-BA8A-A56450BA9E27}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_M274__Practice_Smk,
  "{FC85D2ED-501A-48ce-9863-49D468DDD5FC}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk1__Practice,
  "{174C6E6D-0C3D-42ff-BCB3-0853CB371F5C}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk5__HEAT,
  "{65396399-9F5C-4ec3-A7D2-5A8F4C1D90C4}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_Mk61__Practice,
  "{1F7136CB-8120-4e77-B97B-945FF01FB67C}":
    Weapons.LAU_68_pod___7_x_2_75_Hydra__UnGd_Rkts_WTU_1_B__Practice,
  "{LAU-7 - AIM-9L}": Weapons.LAU_7_AIM_9L,
  "{LAU-7 - AIM-9M}": Weapons.LAU_7_AIM_9M,
  "{F4-2-AIM9B}": Weapons.LAU_7_with_2_x_AIM_9B_Sidewinder_IR_AAM,
  "{F4-2-AIM9L}": Weapons.LAU_7_with_2_x_AIM_9L_Sidewinder_IR_AAM,
  "{9DDF5297-94B9-42FC-A45E-6E316121CD85}":
    Weapons.LAU_7_with_2_x_AIM_9M_Sidewinder_IR_AAM,
  "{F4-2-AIM9P5}": Weapons.LAU_7_with_2_x_AIM_9P5_Sidewinder_IR_AAM,
  "{773675AB-7C29-422f-AFD8-32844A7B7F17}":
    Weapons.LAU_7_with_2_x_AIM_9P_Sidewinder_IR_AAM,
  "{GAR-8}": Weapons.LAU_7_with_AIM_9B_Sidewinder_IR_AAM,
  "{AIM-9M-ON-ADAPTER}": Weapons.LAU_7_with_AIM_9M_Sidewinder_IR_AAM,
  "{AIM-9P5-ON-ADAPTER}": Weapons.LAU_7_with_AIM_9P5_Sidewinder_IR_AAM,
  "{AIM-9P-ON-ADAPTER}": Weapons.LAU_7_with_AIM_9P_Sidewinder_IR_AAM,
  "{AIM-9X-ON-ADAPTER}": Weapons.LAU_7_with_AIM_9X_Sidewinder_IR_AAM,
  "{LAU-7_AIS_ASQ_T50}": Weapons.LAU_7_with_AN_ASQ_T50_TCTS_Pod___ACMI_Pod,
  LAU_88_AGM_65D_ONE: Weapons.LAU_88_AGM_65D_ONE,
  LAU_88_AGM_65H: Weapons.LAU_88_AGM_65H,
  LAU_88_AGM_65H_2_L: Weapons.LAU_88_AGM_65H_2_L,
  LAU_88_AGM_65H_2_R: Weapons.LAU_88_AGM_65H_2_R,
  LAU_88_AGM_65H_3: Weapons.LAU_88_AGM_65H_3,
  "{E6A6262A-CA08-4B3D-B030-E1A993B98452}":
    Weapons.LAU_88_with_2_x_AGM_65D___Maverick_D__IIR_ASM_,
  "{E6A6262A-CA08-4B3D-B030-E1A993B98453}":
    Weapons.LAU_88_with_2_x_AGM_65D___Maverick_D__IIR_ASM__,
  "{2CC29C7A-E863-411C-8A6E-BD6F0E730548}":
    Weapons.LAU_88_with_2_x_AGM_65E___Maverick_E__Laser_ASM___Lg_Whd_,
  "{2CC29C7A-E863-411C-8A6E-BD6F0E730547}":
    Weapons.LAU_88_with_2_x_AGM_65E___Maverick_E__Laser_ASM___Lg_Whd__,
  "{D7670BC7-881B-4094-906C-73879CF7EB28}":
    Weapons.LAU_88_with_2_x_AGM_65K___Maverick_K__CCD_Imp_ASM_,
  "{D7670BC7-881B-4094-906C-73879CF7EB27}":
    Weapons.LAU_88_with_2_x_AGM_65K___Maverick_K__CCD_Imp_ASM__,
  "{DAC53A2F-79CA-42FF-A77A-F5649B601308}":
    Weapons.LAU_88_with_3_x_AGM_65D___Maverick_D__IIR_ASM_,
  "{71AAB9B8-81C1-4925-BE50-1EF8E9899271}":
    Weapons.LAU_88_with_3_x_AGM_65E___Maverick_E__Laser_ASM___Lg_Whd_,
  "{907D835F-E650-4154-BAFD-C656882555C0}":
    Weapons.LAU_88_with_3_x_AGM_65K___Maverick_K__CCD_Imp_ASM_,
  "{LAU_SNEB68G}": Weapons.LAU_SNEB68G___8xSNEB68_EAP,
  "{LAU_SNEB68_WP}": Weapons.LAU_SNEB68G___8xSNEB68_WP,
  "{CAAC1CFD-6745-416B-AFA4-CB57414856D0}": Weapons.Lantirn_F_16,
  "{D1744B93-2A8A-4C4D-B004-7A09CD8C8F3F}": Weapons.Lantirn_Target_Pod,
  "{0519A264-0AB6-11d6-9193-00A0249B6F00}":
    Weapons.L_081_Fantasmagoria_ELINT_pod,
  "{US_M10_SMOKE_TANK_BLUE}": Weapons.M10_Smoke_Tank___blue,
  "{US_M10_SMOKE_TANK_GREEN}": Weapons.M10_Smoke_Tank___green,
  "{US_M10_SMOKE_TANK_ORANGE}": Weapons.M10_Smoke_Tank___orange,
  "{US_M10_SMOKE_TANK_RED}": Weapons.M10_Smoke_Tank___red,
  "{US_M10_SMOKE_TANK_WHITE}": Weapons.M10_Smoke_Tank___white,
  "{US_M10_SMOKE_TANK_YELLOW}": Weapons.M10_Smoke_Tank___yellow,
  "{00F5DAC4-0466-4122-998F-B1A298E34113}": Weapons.M117___750lb_GP_Bomb_LD,
  M134_L: Weapons.M134_L,
  M134_R: Weapons.M134_R,
  M134_SIDE_L: Weapons.M134_SIDE_L,
  M134_SIDE_R: Weapons.M134_SIDE_R,
  "{414DA830-B61A-4F9E-B71B-C2F6832E1D7A}": Weapons.M2000_Fuel_tank,
  M260_HYDRA: Weapons.M260_HYDRA,
  M260_HYDRA_WP: Weapons.M260_HYDRA_WP,
  M261_MK151: Weapons.M261_MK151,
  M261_MK156: Weapons.M261_MK156,
  M60_SIDE_L: Weapons.M60_SIDE_L,
  M60_SIDE_R: Weapons.M60_SIDE_R,
  "{MAK79_MK20 2L}": Weapons.MAK79_2_MK_20,
  "{MAK79_MK20 2R}": Weapons.MAK79_2_MK_20_,
  "{MAK79_BDU33 3L}": Weapons.MAK79_3_BDU_33,
  "{MAK79_BDU33 3R}": Weapons.MAK79_3_BDU_33_,
  "{MAK79_MK81 3L}": Weapons.MAK79_3_Mk_81,
  "{MAK79_MK81 3R}": Weapons.MAK79_3_Mk_81_,
  "{MAK79_MK82 3L}": Weapons.MAK79_3_Mk_82,
  "{MAK79_MK82AIR 3L}": Weapons.MAK79_3_Mk_82AIR,
  "{MAK79_MK82AIR 3R}": Weapons.MAK79_3_Mk_82AIR_,
  "{MAK79_MK82 3R}": Weapons.MAK79_3_Mk_82_,
  "{MAK79_MK82SE 3L}": Weapons.MAK79_3_Mk_82_SnakeEye,
  "{MAK79_MK82SE 3R}": Weapons.MAK79_3_Mk_82_SnakeEye_,
  "{MAK79_MK83 3L}": Weapons.MAK79_3_Mk_83,
  "{MAK79_MK83 3R}": Weapons.MAK79_3_Mk_83_,
  "{MAK79_BDU33 4}": Weapons.MAK79_4_BDU_33,
  "{MAK79_MK81 4}": Weapons.MAK79_4_Mk_81,
  "{MAK79_MK82 4}": Weapons.MAK79_4_Mk_82,
  "{MAK79_MK82AIR 4}": Weapons.MAK79_4_Mk_82AIR,
  "{MAK79_MK82SE 4}": Weapons.MAK79_4_Mk_82_SnakeEye,
  "{MAK79_MK20 1R}": Weapons.MAK79_MK_20,
  "{MAK79_MK20 1L}": Weapons.MAK79_MK_20_,
  "{MAK79_MK83 1R}": Weapons.MAK79_Mk_83,
  "{MAK79_MK83 1L}": Weapons.MAK79_Mk_83_,
  "{MMagicII}": Weapons.Matra_Magic_II,
  "{Matra_S530D}": Weapons.Matra_Super_530D,
  "{Matra155RocketPod}": Weapons.Matra_Type_155_Rocket_Pod,
  "{5A1AC2B4-CA4B-4D09-A1AF-AC52FBC4B60B}":
    Weapons.MBD2_67U_with_4_x_FAB_100___100kg_GP_Bombs_LD,
  "{29A828E2-C6BB-11d8-9897-000476191836}":
    Weapons.MBD2_67U_with_4_x_FAB_100___100kg_GP_Bombs_LD_,
  "{7C5F0F5F-0A0B-46E8-937C-8922303E39A8}":
    Weapons.MBD3_U2T_with_2_x_FAB_1500_M_54___1500kg_GP_Bombs_LD,
  "{6A367BB4-327F-4A04-8D9E-6D86BDC98E7E}":
    Weapons.MBD3_U4T_with_4_x_FAB_250___250kg_GP_Bombs_LD,
  "{02B81892-7E24-4795-84F9-B8110C641AF0}":
    Weapons.MBD3_U4T_with_4_x_RBK_250___42_x_PTAB_2_5M__250kg_CBUs_Medium_HEAT_AP,
  "{E659C4BE-2CD8-4472-8C08-3F28ACB61A8A}":
    Weapons.MBD3_U6_68_with_2_x_FAB_250___250kg_GP_Bombs_LD,
  "{MBD3_U6_3*FAB-250_fwd}":
    Weapons.MBD3_U6_68_with_3_x_FAB_250___250kg_GP_Bombs_LD,
  "{3E35F8C1-052D-11d6-9191-00A0249B6F00}":
    Weapons.MBD3_U6_68_with_4_x_FAB_250___250kg_GP_Bombs_LD,
  "{MBD3_U6_4*FAB-250_fwd}":
    Weapons.MBD3_U6_68_with_4_x_FAB_250___250kg_GP_Bombs_LD_,
  "{MBD3_U6_5*FAB-250}":
    Weapons.MBD3_U6_68_with_5_x_FAB_250___250kg_GP_Bombs_LD,
  "{E96E1EDD-FF3F-47CF-A959-576C3B682955}":
    Weapons.MBD3_U6_68_with_6_x_BetAB_500ShP___500kg_Concrete_Piercing_HD_w_booster_Bombs,
  "{436C6FB9-8BF2-46B6-9DC4-F55ABF3CD1EC}":
    Weapons.MBD3_U6_68_with_6_x_BetAB_500___500kg_Concrete_Piercing_Bombs_LD,
  "{F99BEC1A-869D-4AC7-9730-FBA0E3B1F5FC}":
    Weapons.MBD3_U6_68_with_6_x_FAB_100___100kg_GP_Bombs_LD,
  "{53BE25A4-C86C-4571-9BC0-47D668349595}":
    Weapons.MBD3_U6_68_with_6_x_FAB_250___250kg_GP_Bombs_LD,
  "{FA673F4C-D9E4-4993-AA7A-019A92F3C005}":
    Weapons.MBD3_U6_68_with_6_x_FAB_500_M_62___500kg_GP_Bombs_LD,
  "{0D945D78-542C-4E9B-9A17-9B5008CC8D39}":
    Weapons.MBD3_U6_68_with_6_x_FAB_500_M_62___500kg_GP_Bombs_LD_,
  "{F503C276-FE15-4C54-B310-17B50B735A84}":
    Weapons.MBD3_U6_68_with_6_x_RBK_500_255___30_x_PTAB_10_5__500kg_CBUs_Heavy_HEAT_AP,
  "{4D459A95-59C0-462F-8A57-34E80697F38B}":
    Weapons.MBD3_U6_68_with_6_x_RBK_500_255___30_x_PTAB_10_5__500kg_CBUs_Heavy_HEAT_AP_,
  "{5F1C54C0-0ABD-4868-A883-B52FF9FCB422}":
    Weapons.MBD3_U9M_with_9_x_FAB_100___100kg_GP_Bombs_LD,
  "{E1AAE713-5FC3-4CAA-9FF5-3FDCFB899E33}":
    Weapons.MBD3_U9M_with_9_x_FAB_250___250kg_GP_Bombs_LD,
  "{BF83E8FD-E7A2-40D2-9608-42E13AFE2193}":
    Weapons.MBD3_U9M_with_9_x_RBK_250___42_x_PTAB_2_5M__250kg_CBUs_Medium_HEAT_AP,
  "{005E70F5-C3EA-4E95-A148-C1044C42D845}":
    Weapons.MBD3_with_3_x_BetAB_500___500kg_Concrete_Piercing_Bombs_LD,
  "{CEE04106-B9AA-46B4-9CD1-CD3FDCF0CE78}":
    Weapons.MBD3_with_3_x_FAB_100___100kg_GP_Bombs_LD,
  "{D109EE9C-A1B7-4F1C-8D87-631C293A1D26}":
    Weapons.MBD3_with_3_x_FAB_250___250kg_GP_Bombs_LD,
  "{A1E85991-B58E-4E92-AE91-DED6DC85B2E7}":
    Weapons.MBD3_with_3_x_FAB_500_M_62___500kg_GP_Bombs_LD,
  "{EAD9B2C1-F3BA-4A7B-A2A5-84E2AF8A1975}":
    Weapons.MBD3_with_3_x_RBK_250___42_x_PTAB_2_5M__250kg_CBUs_Medium_HEAT_AP,
  "{919CE839-9390-4629-BAF7-229DE19B8523}":
    Weapons.MBD3_with_3_x_RBK_500_255___30_x_PTAB_10_5__500kg_CBUs_Heavy_HEAT_AP,
  "{574EDEDF-20DE-4942-B2A2-B2EDFD621562}":
    Weapons.MER12_with_12_x_M117___750lb_GP_Bombs_LD,
  "{585D626E-7F42-4073-AB70-41E728C333E2}":
    Weapons.MER12_with_12_x_Mk_82___500lb_GP_Bombs_LD,
  "{0B9ABA77-93B8-45FC-9C63-82AFB2CB50A4}":
    Weapons.MER2_with_2_x_Mk_20_Rockeye___490lbs_CBUs__247_x_HEAT_Bomblets,
  "{D5D51E24-348C-4702-96AF-97A714E72697}":
    Weapons.MER2_with_2_x_Mk_82___500lb_GP_Bombs_LD,
  "{18617C93-78E7-4359-A8CE-D754103EDF63}":
    Weapons.MER2_with_2_x_Mk_83___1000lb_GP_Bombs_LD,
  "{82F90BEC-0E2E-4CE5-A66E-1E4ADA2B5D1E}":
    Weapons.MER3_with_3_x_M117___750lb_GP_Bombs_LD,
  "{752B9782-F962-11d5-9190-00A0249B6F00}":
    Weapons.MER6_with_6_x_BLU_107___440lb_Anti_Runway_Penetrator_Bombs,
  "{6CDB6B36-7165-47D0-889F-6625FB333561}":
    Weapons.MER6_with_6_x_M117___750lb_GP_Bombs_LD,
  "{3C7CD675-7D39-41C5-8735-0F4F537818A8}":
    Weapons.MER6_with_6_x_Mk_20_Rockeye___490lbs_CBUs__247_x_HEAT_Bomblets,
  "{1C97B4A0-AA3B-43A8-8EE7-D11071457185}":
    Weapons.MER6_with_6_x_Mk_82___500lb_GP_Bombs_LD,
  "{B1EF6B0E-3D91-4047-A7A5-A99E7D8B4A8B}": Weapons.Mercury_LLTV_Pod,
  "{0DA03783-61E4-40B2-8FAE-6AEE0A5C5AAE}": Weapons.MICA_IR,
  "{6D778860-7BB8-4ACB-9E95-BA772C6BBC2C}": Weapons.MICA_RF,
  MIM_104: Weapons.MIM_104,
  MIM_72: Weapons.MIM_72,
  "{MBDA_MistralG}": Weapons.Mistral,
  "{MBDA_MistralD}": Weapons.Mistral_,
  "MK_82*28": Weapons.MK_82_28,
  "{BRU-32 MK-20}": Weapons.Mk_20,
  "{ACADB374-6D6C-45A0-BA7C-B22B2E108AE4}": Weapons.Mk_20_18,
  "{ADD3FAE1-EBF6-4EF9-8EFC-B36B5DDF1E6B}":
    Weapons.Mk_20_Rockeye___490lbs_CBU__247_x_HEAT_Bomblets,
  "{90321C8E-7ED1-47D4-A160-E074D5ABD902}": Weapons.Mk_81___250lb_GP_Bomb_LD,
  "{BRU-32 MK-82}": Weapons.Mk_82,
  "{BRU-32 MK-82AIR}": Weapons.Mk_82AIR,
  "{Mk_82B}": Weapons.Mk_82B,
  "{Mk_82BT}": Weapons.Mk_82BT,
  "{Mk_82P}": Weapons.Mk_82P,
  "{Mk_82PT}": Weapons.Mk_82PT,
  "{Mk_82SB}": Weapons.Mk_82SB,
  "{Mk_82SP}": Weapons.Mk_82SP,
  "{Mk_82YT}": Weapons.Mk_82YT,
  "{Mk_82Y}": Weapons.Mk_82Y___500lb_GP_Chute_Retarded_HD,
  "{Mk82AIR}": Weapons.Mk_82_AIR_Ballute___500lb_GP_Bomb_HD,
  "{BRU-32 MK-82SE}": Weapons.Mk_82_SnakeEye,
  "{Mk82SNAKEYE}": Weapons.Mk_82_Snakeye___500lb_GP_Bomb_HD,
  "{BCE4E030-38E9-423E-98ED-24BE3DA87C32}": Weapons.Mk_82___500lb_GP_Bomb_LD,
  "{BRU-32 MK-83}": Weapons.Mk_83,
  "{Mk_83BT}": Weapons.Mk_83BT,
  "{Mk_83CT}": Weapons.Mk_83CT,
  "{Mk_83P}": Weapons.Mk_83P,
  "{Mk_83PT}": Weapons.Mk_83PT,
  "{BRU42_MK83 RS}": Weapons.Mk_83_,
  "{BRU3242_MK83 RS}": Weapons.Mk_83__,
  "{PHXBRU3242_MK83 RS}": Weapons.Mk_83___,
  "{7A44FF09-527C-4B7E-B42B-3F111CFE50FB}": Weapons.Mk_83___1000lb_GP_Bomb_LD,
  "{BRU42_MK83 LS}": Weapons.Mk_83____,
  "{BRU3242_MK83 LS}": Weapons.Mk_83_____,
  "{PHXBRU3242_MK83 LS}": Weapons.Mk_83______,
  "{BRU-32 MK-84}": Weapons.Mk_84,
  "{Mk_84P}": Weapons.Mk_84P,
  "{Mk_84T}": Weapons.Mk_84T,
  "{F092B80C-BB54-477E-9408-66DEEF740008}": Weapons.Mk_84_18,
  "{D3ABF208-FA56-4D56-BB31-E0D931D57AE3}": Weapons.Mk_84_28,
  "{AB8B8299-F1CC-4359-89B5-2172E0CF4A5A}": Weapons.Mk_84___2000lb_GP_Bomb_LD,
  "{44EE8698-89F9-48EE-AF36-5FD31896A82D}": Weapons.MPS_410,
  "{44EE8698-89F9-48EE-AF36-5FD31896A82C}": Weapons.MPS_410_,
  "MXU-648-TP": Weapons.MXU_648_TP,
  "{ODAB-500PM}": Weapons.ODAB_500PM,
  "{OFAB-100-120-TU}": Weapons.OFAB_100_120_TU_x_4,
  "{OFAB_100_Jupiter}": Weapons.OFAB_100_Jupiter___100kg_GP_Bomb_LD,
  "{ORO57K_S5M1_HEFRAG}": Weapons.ORO_57K___S_5M1_HE_FRAG_FFAR_x_8,
  "{ORO57K_S5MO_HEFRAG}": Weapons.ORO_57K___S_5MO_HE_FRAG_FFAR_x_8,
  "{ORO57K_S5M_HEFRAG}": Weapons.ORO_57K___S_5M_x_8,
  "oh-58-brauning": Weapons.oh_58_brauning,
  "{199D6D51-1764-497E-9AE5-7D07C8D4D87E}": Weapons.Pavetack_F_111,
  PKT_7_62: Weapons.PKT_7_62,
  "{PK-3}": Weapons.PK_3___7_62mm_GPMG,
  PTB300_MIG15: Weapons.PTB300_MIG15,
  PTB400_MIG15: Weapons.PTB400_MIG15,
  PTB400_MIG19: Weapons.PTB400_MIG19,
  PTB600_MIG15: Weapons.PTB600_MIG15,
  PTB760_MIG19: Weapons.PTB760_MIG19,
  "{P-50T}": Weapons.P_50T___50kg_Practice_Bomb_LD,
  "{RBK_250_275_AO_1SCH}":
    Weapons.RBK_250_275___150_x_AO_1SCh__250kg_CBU_HE_Frag,
  "{4203753F-8198-4E85-9924-6F8FF679F9FF}":
    Weapons.RBK_250___42_x_PTAB_2_5M__250kg_CBU_Medium_HEAT_AP,
  "{RBK_500U_OAB_2_5RT}": Weapons.RBK_500U___126_x_OAB_2_5RT__500kg_CBU_HE_Frag,
  "{D5435F26-F120-4FA3-9867-34ACE562EF1B}":
    Weapons.RBK_500_255___30_x_PTAB_10_5__500kg_CBU_Heavy_HEAT_AP,
  "{7AEC222D-C523-425e-B714-719C0D1EB14D}":
    Weapons.RBK_500___268_x_PTAB_1M__500kg_CBU_Light_HEAT_AP,
  "{Rb04AI}": Weapons.RB_04E__for_A_I___with_launcher,
  "{Rb15AI}": Weapons.RB_15F__for_A_I___with_launcher,
  "{Rb04}": Weapons.Rb_04E_Anti_ship_Missile,
  "{Robot05}": Weapons.Rb_05A_MCLOS_ASM_AShM_AAM,
  "{Rb15}": Weapons.Rb_15F_Programmable_Anti_ship_Missile,
  "{Robot24J}": Weapons.Rb_24J__AIM_9P__Sidewinder_IR_AAM,
  "{Robot24}": Weapons.Rb_24__AIM_9B__Sidewinder_IR_AAM,
  "{Robot74}": Weapons.Rb_74__AIM_9L__Sidewinder_IR_AAM,
  "{RB75}": Weapons.Rb_75A__AGM_65A_Maverick___TV_ASM_,
  "{RB75B}": Weapons.Rb_75B__AGM_65B_Maverick___TV_ASM_,
  "{RB75T}": Weapons.Rb_75T__AGM_65A_Maverick___TV_ASM_Lg_Whd_,
  REFLEX_9M119: Weapons.REFLEX_9M119,
  "{RKL609_L}": Weapons.RKL609_ECM_Pod__Left_,
  "{RKL609_R}": Weapons.RKL609_ECM_Pod__Right_,
  "{RN-24}": Weapons.RN_24,
  "{RN-28}": Weapons.RN_28,
  ROLAND: Weapons.ROLAND,
  "{M2KC_RPL_522}": Weapons.RPL_522_1300_liters_Fuel_Tank,
  "{M2KC_RPL_522_EMPTY}": Weapons.RPL_522_1300_liters_Fuel_Tank__Empty_,
  "{M2KC_02_RPL541}": Weapons.RPL_541_2000_liters_Fuel_Tank_,
  "{M2KC_08_RPL541}": Weapons.RPL_541_2000_liters_Fuel_Tank__,
  "{M2KC_02_RPL541_EMPTY}": Weapons.RPL_541_2000_liters_Fuel_Tank__Empty_,
  "{M2KC_08_RPL541_EMPTY}": Weapons.RPL_541_2000_liters_Fuel_Tank__Empty__,
  "{RS-2US}": Weapons.RS2US,
  "{R-13M}": Weapons.R_13M,
  "{R-13M1}": Weapons.R_13M1,
  "{CCF898C9-5BC7-49A4-9D1E-C3ED3D5166A1}":
    Weapons.R_24R__AA_7_Apex_SA____Semi_Act_Rdr,
  "{6980735A-44CC-4BB9-A1B5-591532F1DC69}":
    Weapons.R_24T__AA_7_Apex_IR____Infra_Red,
  "{E8069896-8435-4B90-95C0-01A03AE6E400}":
    Weapons.R_27ER__AA_10_Alamo_C____Semi_Act_Extended_Range,
  "{B79C379A-9E87-4E50-A1EE-7F7E29C2E87A}":
    Weapons.R_27ET__AA_10_Alamo_D____IR_Extended_Range,
  "{9B25D316-0434-4954-868F-D51DB1A38DF0}":
    Weapons.R_27R__AA_10_Alamo_A____Semi_Act_Rdr,
  "{88DAC840-9F75-4531-8689-B46E64E42E53}":
    Weapons.R_27T__AA_10_Alamo_B____Infra_Red,
  "{F1243568-8EF0-49D4-9CB5-4DA90D92BC1D}":
    Weapons.R_33__AA_9_Amos____Semi_Act_Rdr,
  "{R-3R}": Weapons.R_3R,
  "{R-3S}": Weapons.R_3S,
  "{4EDBA993-2E34-444C-95FB-549300BF7CAF}":
    Weapons.R_40R__AA_6_Acrid____Semi_Act_Rdr,
  "{5F26DBC2-FB43-4153-92DE-6BBCE26CB0FF}":
    Weapons.R_40T__AA_6_Acrid____Infra_Red,
  "{R-55}": Weapons.R_55,
  "{FC23864E-3B80-48E3-9C03-4DA8B1D7497B}": Weapons.R_550_Magic_2,
  "{R-60}": Weapons.R_60,
  "{R-60M}": Weapons.R_60M,
  "{R-60M 2L}": Weapons.R_60M_x_2,
  "{R-60M 2R}": Weapons.R_60M_x_2_,
  "{682A481F-0CB5-4693-A382-D00DD4A156D7}":
    Weapons.R_60M__AA_8_Aphid____Infra_Red,
  "{R-60 2L}": Weapons.R_60_x_2,
  "{R-60 2R}": Weapons.R_60_x_2_,
  "{FBC29BFE-3D24-4C64-B81D-941239D12249}":
    Weapons.R_73__AA_11_Archer____Infra_Red,
  "{CBC29BFE-3D24-4C64-B81D-941239D12249}":
    Weapons.R_73__AA_11_Archer____Infra_Red_,
  "{B4C01D60-A8A3-4237-BD72-CA7655BC0FE9}":
    Weapons.R_77__AA_12_Adder____Active_Rdr,
  "{B4C01D60-A8A3-4237-BD72-CA7655BC0FEC}":
    Weapons.R_77__AA_12_Adder____Active_Rdr_,
  "{0511E528-EA28-4caf-A212-00D1408DF10A}":
    Weapons.SAB_100___100kg_flare_illumination_Bomb,
  "{FAS}": Weapons.Sand_Filter,
  "{SC_250_T1_L2}": Weapons.SC_250_Type_1_L2___250kg_GP_Bomb_LD,
  "{Schloss500XIIC1_SC_250_T3_J}": Weapons.SC_250_Type_3_J___250kg_GP_Bomb_LD,
  "{SC_500_L2}": Weapons.SC_500_L2___500kg_GP_Bomb_LD,
  SC_501_SC250: Weapons.SC_501_SC250,
  SC_501_SC500: Weapons.SC_501_SC500,
  "{SC_50}": Weapons.SC_50___50kg_GP_Bomb_LD,
  "{SD_250_Stg}": Weapons.SD_250_Stg___250kg_GP_Bomb_LD,
  "{SD_500_A}": Weapons.SD_500_A___500kg_GP_Bomb_LD,
  SEASPARROW: Weapons.SEASPARROW,
  "{1461CD18-429A-42A9-A21F-4C621ECD4573}": Weapons.Sea_Eagle___ASM,
  "{0519A263-0AB6-11d6-9193-00A0249B6F00}":
    Weapons.Shpil_2_Laser_Recon__Intel_Pod,
  "{8C3F26A2-FA0F-11d5-9190-00A0249B6F00}": Weapons.Sky_Shadow_ECM_Pod,
  SM2: Weapons.SM2,
  "{A4BCC903-06C8-47bb-9937-A30FEDB4E743}": Weapons.Smokewinder___blue,
  "{A4BCC903-06C8-47bb-9937-A30FEDB4E742}": Weapons.Smokewinder___green,
  "{A4BCC903-06C8-47bb-9937-A30FEDB4E746}": Weapons.Smokewinder___orange,
  "{A4BCC903-06C8-47bb-9937-A30FEDB4E741}": Weapons.Smokewinder___red,
  "{A4BCC903-06C8-47bb-9937-A30FEDB4E744}": Weapons.Smokewinder___white,
  "{A4BCC903-06C8-47bb-9937-A30FEDB4E745}": Weapons.Smokewinder___yellow,
  "{CE2_SMOKE_WHITE}": Weapons.Smoke_for_Christen_Eagle_II__white,
  "{D3F65166-1AB8-490f-AF2F-2FB6E22568B3}": Weapons.Smoke_Generator___blue,
  "{INV-SMOKE-BLUE}": Weapons.Smoke_Generator___blue_,
  "{D3F65166-1AB8-490f-AF2F-2FB6E22568B2}": Weapons.Smoke_Generator___green,
  "{INV-SMOKE-GREEN}": Weapons.Smoke_Generator___green_,
  "{D3F65166-1AB8-490f-AF2F-2FB6E22568B6}": Weapons.Smoke_Generator___orange,
  "{INV-SMOKE-ORANGE}": Weapons.Smoke_Generator___orange_,
  "{D3F65166-1AB8-490f-AF2F-2FB6E22568B1}": Weapons.Smoke_Generator___red,
  "{INV-SMOKE-RED}": Weapons.Smoke_Generator___red_,
  "{D3F65166-1AB8-490f-AF2F-2FB6E22568B4}": Weapons.Smoke_Generator___white,
  "{INV-SMOKE-WHITE}": Weapons.Smoke_Generator___white_,
  "{D3F65166-1AB8-490f-AF2F-2FB6E22568B5}": Weapons.Smoke_Generator___yellow,
  "{INV-SMOKE-YELLOW}": Weapons.Smoke_Generator___yellow_,
  "{SMOKE-RED-AVIOJET}": Weapons.Smoke_System_red_colorant,
  "{SMOKE-YELLOW-AVIOJET}": Weapons.Smoke_System_yellow_colorant,
  "{SMOKE-SYSTEM-AVIOJET}": Weapons.Smoke_System__White_Smoke_,
  "{MIG21_SMOKE_RED}": Weapons.Smoke___red___21__t,
  "{SMOKE_WHITE}": Weapons.Smoke___white___21,
  "{MIG21_SMOKE_WHITE}": Weapons.Smoke___white___21_,
  SPITFIRE_45GAL_SLIPPER_TANK: Weapons.SPITFIRE_45GAL_SLIPPER_TANK,
  SPITFIRE_45GAL_TORPEDO_TANK: Weapons.SPITFIRE_45GAL_TORPEDO_TANK,
  "{E92CBFE5-C153-11d8-9897-000476191836}":
    Weapons.SPPU_22_1___2_x_23mm__GSh_23L_Autocannon_Pod,
  "{SPRD}": Weapons.SPRD_99,
  "{SPS-141-100}": Weapons.SPS_141_100__21_,
  "{F75187EF-1D9E-4DA9-84B4-1A1A14A3973A}": Weapons.SPS_141___ECM_Jamming_Pod,
  "{CAE48299-A294-4bad-8EE6-89EFC5DCDF00}":
    Weapons.SUU_25_x_8_LUU_2___Target_Marker_Flares,
  "{BRU42_SUU25}": Weapons.SUU_25___8_LUU_2,
  "{BRU3242_SUU25}": Weapons.SUU_25___8_LUU_2_,
  "{FD21B13E-57F3-4C2A-9F78-C522D0B5BCE1}": Weapons.Super_530D,
  SVIR_9M119: Weapons.SVIR_9M119,
  "{S-24A}": Weapons.S_24A__21_,
  "{S-24B}": Weapons.S_24B__21_,
  "{1FA14DEA-8CDB-45AD-88A8-EC068DF1E65A}":
    Weapons.S_24B___240mm_UnGd_Rkt__235kg__HE_Frag___Low_Smk_,
  "{3858707D-F5D5-4bbb-BDD8-ABB0530EBC7C}":
    Weapons.S_24B___240mm_UnGd_Rkt__235kg__HE_Frag___Low_Smk__,
  "{0180F983-C14A-11d8-9897-000476191836}":
    Weapons.S_25L___320Kg__340mm_Laser_Guided_Rkt,
  "{A0648264-4BC0-4EE8-A543-D119F6BA4257}":
    Weapons.S_25_OFM___340mm_UnGd_Rkt__480kg_Penetrator,
  "{0519A262-0AB6-11d6-9193-00A0249B6F00}": Weapons.Tangazh_ELINT_pod,
  "{TER_9A_2L*CBU-87}":
    Weapons.TER_9A_with_2_x_CBU_87___202_x_Anti_Armor_Skeet_SFW_Cluster_Bomb,
  "{TER_9A_2R*CBU-87}":
    Weapons.TER_9A_with_2_x_CBU_87___202_x_Anti_Armor_Skeet_SFW_Cluster_Bomb_,
  "{TER_9A_2L*CBU-97}":
    Weapons.TER_9A_with_2_x_CBU_97___10_x_Anti_Armor_Skeet_SFW_Cluster_Bomb,
  "{TER_9A_2R*CBU-97}":
    Weapons.TER_9A_with_2_x_CBU_97___10_x_Anti_Armor_Skeet_SFW_Cluster_Bomb_,
  "{TER_9A_2L*GBU-12}":
    Weapons.TER_9A_with_2_x_GBU_12___500lb_Laser_Guided_Bomb,
  "{TER_9A_2R*GBU-12}":
    Weapons.TER_9A_with_2_x_GBU_12___500lb_Laser_Guided_Bomb_,
  "{TER_9A_2L*MK-82AIR}":
    Weapons.TER_9A_with_2_x_Mk_82_AIR_Ballute___500lb_GP_Bomb_HD,
  "{TER_9A_2R*MK-82AIR}":
    Weapons.TER_9A_with_2_x_Mk_82_AIR_Ballute___500lb_GP_Bomb_HD_,
  "{TER_9A_2L*MK-82_Snakeye}":
    Weapons.TER_9A_with_2_x_Mk_82_Snakeye___500lb_GP_Bomb_HD,
  "{TER_9A_2R*MK-82_Snakeye}":
    Weapons.TER_9A_with_2_x_Mk_82_Snakeye___500lb_GP_Bomb_HD_,
  "{TER_9A_2L*MK-82}": Weapons.TER_9A_with_2_x_Mk_82___500lb_GP_Bomb_LD,
  "{TER_9A_2R*MK-82}": Weapons.TER_9A_with_2_x_Mk_82___500lb_GP_Bomb_LD_,
  "{TER_9A_3*BDU-33}": Weapons.TER_9A_with_3_x_BDU_33___25lb_Practice_Bomb_LD,
  "{TER_9A_3*CBU-87}":
    Weapons.TER_9A_with_3_x_CBU_87___202_x_Anti_Armor_Skeet_SFW_Cluster_Bomb,
  "{TER_9A_3*CBU-97}":
    Weapons.TER_9A_with_3_x_CBU_97___10_x_Anti_Armor_Skeet_SFW_Cluster_Bomb,
  "{TER_9A_3*MK-82AIR}":
    Weapons.TER_9A_with_3_x_Mk_82_AIR_Ballute___500lb_GP_Bomb_HD,
  "{TER_9A_3*MK-82_Snakeye}":
    Weapons.TER_9A_with_3_x_Mk_82_Snakeye___500lb_GP_Bomb_HD,
  "{TER_9A_3*MK-82}": Weapons.TER_9A_with_3_x_Mk_82___500lb_GP_Bomb_LD,
  TEST_ROTARY_LAUNCHER_MK82: Weapons.TEST_ROTARY_LAUNCHER_MK82,
  TGM_65H: Weapons.TGM_65H,
  "{EF124821-F9BB-4314-A153-E0E2FE1162C4}": Weapons.TORNADO_Fuel_tank,
  TOW: Weapons.TOW,
  "{U22A}": Weapons.U22_A_Jammer,
  "{UB-16-57UMP}": Weapons.UB_16UM_pod___16_x_S_5KO__57mm_UnGd_Rkts__HEAT_Frag,
  "{UB-16_S5M}": Weapons.UB_16UM___16_S_5M,
  "{637334E4-AB5A-47C0-83A6-51B7F1DF3CD5}":
    Weapons.UB_32A_pod___32_x_S_5KO__57mm_UnGd_Rkts__HEAT_Frag,
  "{UB-32_S5M}": Weapons.UB_32M___32_S_5M,
  "{UPK-23-250 MiG-21}": Weapons.UPK_23_250,
  "{05544F1A-C39C-466b-BC37-5BD1D52E57BB}":
    Weapons.UPK_23_250___2_x_23mm__GSh_23L_Autocannon_Pod,
  "{U22}": Weapons.U_22_Jammer_pod,
  "{WGr21}": Weapons.Werfer_Granate_21___21_cm_UnGd_air_to_air_rocket,
  XM158_M151: Weapons.XM158_M151,
  XM158_M156: Weapons.XM158_M156,
  XM158_M257: Weapons.XM158_M257,
  XM158_M274: Weapons.XM158_M274,
  XM158_MK1: Weapons.XM158_MK1,
  XM158_MK5: Weapons.XM158_MK5,
  "{US_108GAL_PAPER_FUEL_TANK}": Weapons._108_US_gal__Paper_Fuel_Tank,
  "{US_110GAL_FUEL_TANK}": Weapons._110_US_gal__Fuel_Tank,
  "{12xM64}": Weapons._12_AN_M64___500lb_GP_Bomb_LD,
  "{D6A0441E-6794-4FEB-87F7-E68E2290DFAB}":
    Weapons._12_x_BetAB_500___500kg_Concrete_Piercing_Bombs_LD,
  "{E70446B7-C7E6-4B95-B685-DEA10CAD1A0E}":
    Weapons._12_x_FAB_500_M_62___500kg_GP_Bombs_LD,
  "{FW_190_R4M_LEFT_WING}": Weapons._13_R4M_3_2kg_UnGd_air_to_air_rocket,
  "{FW_190_R4M_RGHT_WING}": Weapons._13_R4M_3_2kg_UnGd_air_to_air_rocket_,
  "{US_150GAL_FUEL_TANK}": Weapons._150_US_gal__Fuel_Tank,
  "{22906569-A97F-404B-BA4F-D96DBF94D05E}": Weapons._20_x_AGM_86C_ALCM,
  "{B0241BD2-5628-47E0-954C-A8675B7E698E}":
    Weapons._24_x_FAB_250___250kg_GP_Bombs_LD,
  "{British_GP_250LB_Bomb_Mk4}": Weapons._250lb_Mk_IV___GP_Bomb_LD,
  "{British_GP_250LB_Bomb_Mk1}": Weapons._250lb_Mk_I___GP_Bomb_LD,
  "{British_GP_250LB_Bomb_Mk5}": Weapons._250_lb_GP_Mk_V,
  "{British_MC_250LB_Bomb_Mk1}": Weapons._250_lb_MC_Mk_I,
  "{British_MC_250LB_Bomb_Mk2}": Weapons._250_lb_MC_Mk_II,
  "{British_SAP_250LB_Bomb_Mk5}": Weapons._250_lb_S_A_P_,
  "{B58F99BA-5480-4572-8602-28B0449F5260}":
    Weapons._27_x_M117___750lb_GP_Bombs_LD,
  "{6C47D097-83FF-4FB2-9496-EAB36DDF0B05}":
    Weapons._27_x_Mk_82___500lb_GP_Bombs_LD,
  "{89D000B0-0360-461A-AD83-FB727E2ABA98}":
    Weapons._2xGBU_12___500lb_Laser_Guided_Bomb,
  "{BRU-42_2xGBU-12_right}": Weapons._2xGBU_12___500lb_Laser_Guided_Bomb_,
  "{LYSBOMB}": Weapons._2x_80kg_LYSB_71_Illumination_Bomb,
  "{BRU-42_2*GBU-12_LEFT}": Weapons._2_GBU_12,
  "{BRU-42_2*GBU-12_RIGHT}": Weapons._2_GBU_12_,
  "{BRU-42_2*GBU-16_LEFT}": Weapons._2_GBU_16,
  "{BRU-42_2*GBU-16_RIGHT}": Weapons._2_GBU_16_,
  "{BRU-42_2*GBU-38_LEFT}": Weapons._2_GBU_38,
  "{BRU-42_2*GBU-38_RIGHT}": Weapons._2_GBU_38_,
  "{BRU-70A_2*GBU-54_LEFT}": Weapons._2_GBU_54_V_1_B,
  "{BRU-70A_2*GBU-54_RIGHT}": Weapons._2_GBU_54_V_1_B_,
  "{BRU42_2*LAU10 L}": Weapons._2_LAU_10___4_ZUNI_MK_71,
  "{BRU3242_2*LAU10 L}": Weapons._2_LAU_10___4_ZUNI_MK_71_,
  "{BRU42_2*LAU10 R}": Weapons._2_LAU_10___4_ZUNI_MK_71__,
  "{BRU3242_2*LAU10 R}": Weapons._2_LAU_10___4_ZUNI_MK_71___,
  "{BRU42_2*LAU10 RS}": Weapons._2_LAU_10___4_ZUNI_MK_71____,
  "{BRU3242_2*LAU10 RS}": Weapons._2_LAU_10___4_ZUNI_MK_71_____,
  "{PHXBRU3242_2*LAU10 RS}": Weapons._2_LAU_10___4_ZUNI_MK_71______,
  "{BRU42_2*LAU10 LS}": Weapons._2_LAU_10___4_ZUNI_MK_71_______,
  "{BRU3242_2*LAU10 LS}": Weapons._2_LAU_10___4_ZUNI_MK_71________,
  "{PHXBRU3242_2*LAU10 LS}": Weapons._2_LAU_10___4_ZUNI_MK_71_________,
  "{BRU42_2*LUU2 R}": Weapons._2_LUU_2,
  "{BRU3242_2*LUU2 R}": Weapons._2_LUU_2_,
  "{BRU42_2*LUU2 L}": Weapons._2_LUU_2__,
  "{BRU3242_2*LUU2 L}": Weapons._2_LUU_2___,
  "{BRU42_2*MK20 RS}": Weapons._2_MK_20,
  "{BRU3242_2*MK20 RS}": Weapons._2_MK_20_,
  "{PHXBRU3242_2*MK20 RS}": Weapons._2_MK_20__,
  "{BRU42_2*MK20 LS}": Weapons._2_MK_20___,
  "{BRU3242_2*MK20 LS}": Weapons._2_MK_20____,
  "{PHXBRU3242_2*MK20 LS}": Weapons._2_MK_20_____,
  "{BRU-42_2*MK-20_LEFT}": Weapons._2_Mk_20_Rockeye,
  "{BRU-42_2*MK-20_RIGHT}": Weapons._2_Mk_20_Rockeye_,
  "{BRU42_2*MK81 RS}": Weapons._2_Mk_81,
  "{BRU3242_2*MK81 RS}": Weapons._2_Mk_81_,
  "{PHXBRU3242_2*MK81 RS}": Weapons._2_Mk_81__,
  "{BRU42_2*MK81 LS}": Weapons._2_Mk_81___,
  "{BRU3242_2*MK81 LS}": Weapons._2_Mk_81____,
  "{PHXBRU3242_2*MK81 LS}": Weapons._2_Mk_81_____,
  "{BRU-42_2*Mk-82_LEFT}": Weapons._2_Mk_82,
  "{BRU42_2*MK82AIR RS}": Weapons._2_Mk_82AIR,
  "{BRU3242_2*MK82AIR RS}": Weapons._2_Mk_82AIR_,
  "{PHXBRU3242_2*MK82AIR RS}": Weapons._2_Mk_82AIR__,
  "{BRU42_2*MK82AIR LS}": Weapons._2_Mk_82AIR___,
  "{BRU3242_2*MK82AIR LS}": Weapons._2_Mk_82AIR____,
  "{PHXBRU3242_2*MK82AIR LS}": Weapons._2_Mk_82AIR_____,
  "{BRU-42_2*Mk-82_RIGHT}": Weapons._2_Mk_82_,
  "{BRU-42_2*Mk-82AIR_LEFT}": Weapons._2_Mk_82_AIR,
  "{BRU-42_2*Mk-82AIR_RIGHT}": Weapons._2_Mk_82_AIR_,
  "{BRU42_2*MK82SE RS}": Weapons._2_Mk_82_SnakeEye,
  "{BRU3242_2*MK82SE RS}": Weapons._2_Mk_82_SnakeEye_,
  "{PHXBRU3242_2*MK82SE RS}": Weapons._2_Mk_82_SnakeEye__,
  "{BRU42_2*MK82SE LS}": Weapons._2_Mk_82_SnakeEye___,
  "{BRU3242_2*MK82SE LS}": Weapons._2_Mk_82_SnakeEye____,
  "{PHXBRU3242_2*MK82SE LS}": Weapons._2_Mk_82_SnakeEye_____,
  "{BRU-42_2*Mk-82SNAKEYE_LEFT}": Weapons._2_Mk_82_Snakeye,
  "{BRU-42_2*Mk-82SNAKEYE_RIGHT}": Weapons._2_Mk_82_Snakeye_,
  "{BRU42_2*MK82 RS}": Weapons._2_Mk_82__,
  "{BRU3242_2*MK82 RS}": Weapons._2_Mk_82___,
  "{PHXBRU3242_2*MK82 RS}": Weapons._2_Mk_82____,
  "{BRU42_2*MK82 LS}": Weapons._2_Mk_82_____,
  "{BRU3242_2*MK82 LS}": Weapons._2_Mk_82______,
  "{PHXBRU3242_2*MK82 LS}": Weapons._2_Mk_82_______,
  "{BRU-42_2*Mk-83_LEFT}": Weapons._2_Mk_83,
  "{BRU-42_2*Mk-83_RIGHT}": Weapons._2_Mk_83_,
  "{BRU42_2*SUU25 L}": Weapons._2_SUU_25___8_LUU_2,
  "{BRU3242_2*SUU25 L}": Weapons._2_SUU_25___8_LUU_2_,
  "{BRU42_2*SUU25 R}": Weapons._2_SUU_25___8_LUU_2__,
  "{BRU3242_2*SUU25 R}": Weapons._2_SUU_25___8_LUU_2___,
  "{07BE2D19-0E48-4B0B-91DA-5F6C8F9E3C75}": Weapons._2_x_ALARM,
  "{C535596E-F7D2-4301-8BB4-B1658BB87ED7}":
    Weapons._2_x_BL_755_CBUs___450kg__147_Frag_Pen_bomblets,
  "{TWIN_B13L_5OF}":
    Weapons._2_x_B_13L_pods___10_x_S_13_OF__122mm_UnGd_Rkts__Blast_Frag,
  "{B13_5_S13OF_DUAL_L}": Weapons._2_x_B_13L___5_S_13_OF,
  "{B13_5_S13OF_DUAL_R}": Weapons._2_x_B_13L___5_S_13_OF_,
  "{TWIN_B_8M1_S_8KOM}":
    Weapons._2_x_B_8M1_pods___40_x_S_8KOM__80mm_UnGd_Rkts__HEAT_AP,
  "{B8M1_20_S8KOM_DUAL_L}": Weapons._2_x_B_8M1___20_S_8KOM,
  "{B8M1_20_S8KOM_DUAL_R}": Weapons._2_x_B_8M1___20_S_8KOM_,
  "{B8M1_20_S8OFP2_DUAL_L}": Weapons._2_x_B_8M1___20_S_8OFP2,
  "{B8M1_20_S8OFP2_DUAL_R}": Weapons._2_x_B_8M1___20_S_8OFP2_,
  "{B8M1_20_S8TsM_DUAL_L}": Weapons._2_x_B_8M1___20_S_8TsM,
  "{B8M1_20_S8TsM_DUAL_R}": Weapons._2_x_B_8M1___20_S_8TsM_,
  "{TWIN_B_8M1_S_8_OFP2}":
    Weapons._2_x_B_8V20A_pods___40_x_S_8OFP2__80mm_UnGd_Rkts__HE_Frag_AP,
  "{TWIN_B_8M1_S_8TsM}":
    Weapons._2_x_B_8V20A_pods___40_x_S_8TsM__80mm_UnGd_Rkts__Smk,
  "{FAB_250_DUAL_L}": Weapons._2_x_FAB_250,
  "{FAB_250_DUAL_R}": Weapons._2_x_FAB_250_,
  "{FAB_500_DUAL_L}": Weapons._2_x_FAB_500,
  "{FAB_500_DUAL_R}": Weapons._2_x_FAB_500_,
  "{HVARx2}": Weapons._2_x_HVAR__UnGd_Rkts,
  "{FAB-100x2}": Weapons._2_x_OFAB_100_Jupiter___100kg_GP_Bombs_LD,
  "{RBK_250_PTAB25M_DUAL_L}": Weapons._2_x_RBK_250_PTAB_2_5M,
  "{RBK_250_PTAB25M_DUAL_R}": Weapons._2_x_RBK_250_PTAB_2_5M_,
  "{RBK_500_PTAB105_DUAL_L}": Weapons._2_x_RBK_500_255_PTAB_10_5,
  "{RBK_500_PTAB105_DUAL_R}": Weapons._2_x_RBK_500_255_PTAB_10_5_,
  "{S25_DUAL_L}": Weapons._2_x_S_25,
  "{S25_DUAL_R}": Weapons._2_x_S_25_,
  "{TWIN_S25}": Weapons._2_x_S_25_OFM___340mm_UnGdrocket__480kg_Penetrator,
  "{BDAD04AA-4D4A-4E51-B958-180A89F963CF}":
    Weapons._33_x_FAB_250___250kg_GP_Bombs_LD,
  "{AD5E5863-08FC-4283-B92C-162E2B2BD3FF}":
    Weapons._33_x_FAB_500_M_62___500kg_GP_Bombs_LD,
  "3M45": Weapons._3M45,
  "{BRU42_3*BDU33}": Weapons._3_BDU_33,
  "{BRU3242_3*BDU33}": Weapons._3_BDU_33_,
  "{BRU42_3*BDU33_N}": Weapons._3_BDU_33__,
  "{BRU3242_3*BDU33_N}": Weapons._3_BDU_33___,
  "{PHXBRU3242_BDU33}": Weapons._3_BDU_33____,
  "{BRU-42A_3*GBU-12}": Weapons._3_GBU_12,
  "{BRU-42A_3*GBU-16}": Weapons._3_GBU_16,
  "{BRU-42_3*GBU-38}": Weapons._3_GBU_38,
  "{BRU-70A_3*GBU-54}": Weapons._3_GBU_54_V_1_B,
  "{BRU-42_3*MK-20}": Weapons._3_Mk_20_Rockeye,
  "{BRU-42_3*Mk-81LD}": Weapons._3_Mk_81,
  "{BRU-42_3*Mk-82LD}": Weapons._3_Mk_82,
  "{BRU-42_3_MK82AIR}": Weapons._3_Mk_82_AIR,
  "{BRU-42_3*Mk-82SNAKEYE}": Weapons._3_Mk_82_Snakeye,
  "{BRU-42_3*Mk-83}": Weapons._3_Mk_83,
  "{3xM8_ROCKETS_IN_TUBES}": Weapons._3_x_4_5_inch_M8_UnGd_Rocket,
  "{639DB5DD-CB7E-4E42-AC75-2112BC397B97}":
    Weapons._3_x_FAB_1500_M_54___1500kg_GP_Bombs_LD,
  "{A76344EB-32D2-4532-8FA2-0C1BDC00747E}":
    Weapons._3_x_LAU_61_pods___57_x_2_75_Hydra__UnGd_Rkts_M151__HE,
  "48N6E2": Weapons._48N6E2,
  _4M80: Weapons._4M80,
  "{M71BOMBD}": Weapons._4x_SB_M_71_120kg_GP_Bomb_High_drag,
  "{M71BOMB}": Weapons._4x_SB_M_71_120kg_GP_Bomb_Low_drag,
  "{AABA1A14-78A1-4E85-94DD-463CF75BD9E4}":
    Weapons._4_x_AGM_154C___JSOW_Unitary_BROACH,
  "{4xAN-M64_on_InvCountedAttachmentPoints}":
    Weapons._4_x_AN_M64___500lb_GP_Bomb_LD,
  "{3EA17AB0-A805-4D9E-8732-4CE00CB00F17}": Weapons._4_x_BGM_71D_TOW_ATGM,
  "{B8C99F40-E486-4040-B547-6639172A5D57}":
    Weapons._4_x_GBU_27___2000lb_Laser_Guided_Penetrator_Bombs,
  "{British_GP_500LB_Bomb_Mk4}": Weapons._500lb_Mk_IV___GP_Bomb_LD,
  "{British_GP_500LB_Bomb_Mk1}": Weapons._500lb_Mk_I___GP_Bomb_LD,
  "{British_GP_500LB_Bomb_Mk5}": Weapons._500_lb_GP_Mk_V,
  "{British_GP_500LB_Bomb_Mk4_Short}": Weapons._500_lb_GP_Short_tail,
  "{British_MC_500LB_Bomb_Mk2}": Weapons._500_lb_MC_Mk_II,
  "{British_MC_500LB_Bomb_Mk1_Short}": Weapons._500_lb_MC_Short_tail,
  "{British_SAP_500LB_Bomb_Mk5}": Weapons._500_lb_S_A_P_,
  "{72CAC282-AE18-490B-BD4D-35E7EE969E73}":
    Weapons._51_x_M117___750lb_GP_Bombs_LD,
  "{B84DFE16-6AC7-4854-8F6D-34137892E166}":
    Weapons._51_x_Mk_82___500lb_GP_Bombs_LD,
  "5V55": Weapons._5V55,
  "{P47_5_HVARS_ON_LEFT_WING_RAILS}": Weapons._5_x_HVAR__UnGd_Rkt,
  "{P47_5_HVARS_ON_RIGHT_WING_RAILS}": Weapons._5_x_HVAR__UnGd_Rkt_,
  "{MER-5E_Mk82SNAKEYEx5}": Weapons._5_x_Mk_82_Snakeye___500lb_GP_Bomb_HD,
  "{MER-5E_MK82x5}": Weapons._5_x_Mk_82___500lb_GP_Bombs_LD,
  "{45447F82-01B5-4029-A572-9AAD28AF0275}": Weapons._6_x_AGM_86C_ALCM_on_MER,
  "{2B7BDB38-4F45-43F9-BE02-E7B3141F3D24}":
    Weapons._6_x_BetAB_500___500kg_Concrete_Piercing_Bombs_LD,
  "{D9179118-E42F-47DE-A483-A6C2EA7B4F38}":
    Weapons._6_x_FAB_1500_M_54___1500kg_GP_Bombs_LD,
  "{26D2AF37-B0DF-4AB6-9D61-A150FF58A37B}":
    Weapons._6_x_FAB_500_M_62___500kg_GP_Bombs_LD,
  "{C42EE4C3-355C-4B83-8B22-B39430B8F4AE}":
    Weapons._6_x_Kh_35__AS_20_Kayak____520kg__AShM__IN__Act_Rdr,
  "{0290F5DE-014A-4BB1-9843-D717749B1DED}":
    Weapons._6_x_Kh_65__AS_15B_Kent____1250kg__ASM__IN__MCC,
  "{E79759F7-C622-4AA4-B1EF-37639A34D924}":
    Weapons._6_x_Mk_20_Rockeye___490lbs_CBUs__247_x_HEAT_Bomblets,
  "{027563C9-D87E-4A85-B317-597B510E3F03}":
    Weapons._6_x_Mk_82___500lb_GP_Bombs_LD,
  "{DT75GAL}": Weapons._75_US_gal__Fuel_Tank,
  "{46ACDCF8-5451-4E26-BDDB-E78D5830E93C}": Weapons._8_x_AGM_84A_Harpoon_ASM,
  "{8DCAF3A3-7FCF-41B8-BB88-58DEDA878EDE}": Weapons._8_x_AGM_86C_ALCM,
  "{CD9417DF-455F-4176-A5A2-8C58D61AA00B}":
    Weapons._8_x_Kh_65__AS_15B_Kent____1250kg__ASM__IN__MCC,
  _9M111: Weapons._9M111,
  "{B919B0F4-7C25-455E-9A02-CEA51DB895E3}":
    Weapons._9M114_Shturm_V_2__AT_6_Spiral____ATGM__SACLOS,
  "{57232979-8B0F-4db7-8D9A-55197E06B0F5}":
    Weapons._9M114_Shturm_V_8__AT_6_Spiral____ATGM__SACLOS,
  _9M117: Weapons._9M117,
  "9M133": Weapons._9M133,
  "9M14": Weapons._9M14,
  "9M31": Weapons._9M31,
  "9M311": Weapons._9M311,
  "9M33": Weapons._9M33,
  _9M331: Weapons._9M331,
  _9M37: Weapons._9M37,
  _9M38: Weapons._9M38,
  _9M39: Weapons._9M39,
  _NiteHawk_FLIR: Weapons._NiteHawk_FLIR,
};

export default weaponIDs;
