import { APIGuildMember } from "discord-api-types/v10";
import _ from "lodash";
import * as React from "react";
import {
  PlanningRule,
  PublishManifestDoc,
} from "../../lib/models/PublishManifest";
import { Coalition } from "../../lib/types";

export type PlanningRuleWithManifestRef = PlanningRule & {
  manifestID: string;
  coalition: Coalition;
};

export type PlanningRuleInput = {
  discordUser: APIGuildMember;
  groups: string[];
};

export type PlanningManagementState = {
  manifests: PublishManifestDoc[];

  newRules: PlanningRuleWithManifestRef[];
};

export enum PlanningManagementActionTypes {
  loadManifests,
  addNewRule,
  removeNewRule,
  reset,
}

export type PlanningManagementAction =
  | {
      type: PlanningManagementActionTypes.loadManifests;
      manifests: PublishManifestDoc[];
    }
  | {
      type: PlanningManagementActionTypes.addNewRule;
      rule: PlanningRuleWithManifestRef;
    }
  | {
      type: PlanningManagementActionTypes.removeNewRule;
      index: number;
    }
  | {
      type: PlanningManagementActionTypes.reset;
    };

type PlanningManagementContextType = {
  state: PlanningManagementState;
  dispatch: React.Dispatch<PlanningManagementAction>;
};

export function planningInputReducer(
  state: PlanningManagementState,
  action: PlanningManagementAction
): PlanningManagementState {
  switch (action.type) {
    case PlanningManagementActionTypes.loadManifests:
      return {
        ...state,
        manifests: action.manifests,
      };

    case PlanningManagementActionTypes.addNewRule:
      return {
        ...state,
        newRules: [...state.newRules, action.rule],
      };

    case PlanningManagementActionTypes.removeNewRule:
      return {
        ...state,
        newRules: _.filter(state.newRules, (r, i) => i !== action.index),
      };

    case PlanningManagementActionTypes.reset:
      return {
        ...state,
        newRules: [],
      };

    default:
      return state;
  }
}

export const PlanningManagementContext = React.createContext<
  PlanningManagementContextType
>({} as PlanningManagementContextType);

export function defaultState(): PlanningManagementState {
  return {
    manifests: [],
    newRules: [],
  };
}

export function PlanningManagementProvider({ children }) {
  const initialState = defaultState();

  const [state, dispatch] = React.useReducer(
    planningInputReducer,
    initialState
  );

  return (
    <PlanningManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </PlanningManagementContext.Provider>
  );
}
