import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "../../contexts/UserContext";
import { AppRoutes } from "../../lib/types";
import DiscordLoginButton from "../DiscordLoginButton";
import Flex from "../Flex";
import Link from "../Link";
import Logo, { LogoVariant } from "../Logo";
import MapTitle from "./MapTitle";

type Props = {
  className?: string;
};

function PublicHeader({ className }: Props) {
  const { currentUser, logout } = useUser();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
    history.push(AppRoutes.Login);
  };

  return (
    <Flex className={className} wide between>
      <Flex wide between align className="grid-container">
        <Box mr={1}>
          <Link to={AppRoutes.Home}>
            <Logo className="full-logo" width={140} height={84} />
            <Logo
              className="mobile-logo"
              variant={LogoVariant.Solo}
              height={56}
            />
          </Link>
        </Box>

        <MapTitle className="item1" />
        <div className="item2">
          {currentUser ? (
            <IconButton onClick={handleClick}>
              <Avatar
                alt={currentUser.username}
                src={`https://cdn.discordapp.com/avatars/${currentUser?.id}/${currentUser.avatar}.png`}
              />
            </IconButton>
          ) : (
            <DiscordLoginButton />
          )}
        </div>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push(AppRoutes.UserSettings);
              handleClose();
            }}
          >
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Flex>
    </Flex>
  );
}

export default styled(PublicHeader).attrs({ className: PublicHeader.name })`
  position: absolute;
  top: 0;
  height: 96px;
  width: 100%;
  z-index: 2;

  ${DiscordLoginButton} {
    margin-right: 16px;

    @media only screen and (max-width: 1300px) {
      display: none;
    }
  }

  ${DiscordLoginButton} .MuiButton-root {
    margin: 0 auto;
    background-color: white;
  }

  .mobile-logo {
    display: none;
  }

  @media only screen and (max-width: 1300px) {
    .full-logo {
      display: none;
    }

    .mobile-logo {
      display: block;
    }
  }
`;
