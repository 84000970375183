import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { getTACAN } from "../../lib/mission";
import { formatFreq } from "../../lib/radios";
import { DCSGroupData } from "../../lib/types";
import KeyValueTable from "../KeyValueTable";

type Props = {
  className?: string;
  group: DCSGroupData;
};

function SupportAsset({ className, group }: Props) {
  const { channel, supportType } = group;

  return (
    <div className={className}>
      <KeyValueTable
        columns={2}
        pairs={[
          { key: "Support Type", value: supportType },
          { key: "Aircraft", value: _.get(group, "units[0].type") },
          {
            key: "Frequency",
            value: `${formatFreq(channel?.frequency)} ${
              channel?.modulation === 0 ? "AM" : "FM"
            }`,
          },
          { key: "TACAN", value: getTACAN(group.units[0], group) },
        ]}
      />
    </div>
  );
}

export default styled(SupportAsset).attrs({ className: SupportAsset.name })`
  td {
    padding: 0;
    border-bottom: 0px;
  }
`;
