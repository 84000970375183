import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import Flex from "../Flex";

type Props = {
  className?: string;
  onChange: (groups: string[]) => void;
  groups: string[];
  value: string[];
};

function GroupSelect({ className, onChange, groups, value }: Props) {
  const [filter, setFilter] = React.useState("");

  const filtered = _.filter(groups, (g) =>
    g.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <>
      <div>
        <FormLabel>Groups</FormLabel>
      </div>
      <Box mt={1}>
        <Input
          size="small"
          placeholder="Filter"
          onChange={(ev) => {
            setFilter(ev.target.value);
          }}
          endAdornment={<Button onClick={() => setFilter("")}>Clear</Button>}
        />
      </Box>

      <FormControl
        style={{ width: "100%" }}
        component="fieldset"
        className={className}
      >
        <Box my={1}>
          <Flex align>
            <Button onClick={() => onChange([])}>Deselect All</Button>
            <Button onClick={() => onChange(groups)}>Select All</Button>
          </Flex>
        </Box>

        <div
          style={{
            minWidth: "100%",

            overflowY: "auto",
          }}
        >
          {_.map(filtered, (g) => (
            <FormGroup key={g}>
              <Flex align>
                <FormControlLabel
                  label={g}
                  control={
                    <Checkbox
                      checked={_.includes(value, g)}
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          onChange([...value, g]);
                        } else {
                          onChange(_.without(value, g));
                        }
                      }}
                    />
                  }
                />
              </Flex>
            </FormGroup>
          ))}
        </div>
      </FormControl>
    </>
  );
}

export default styled(GroupSelect).attrs({ className: GroupSelect.name })``;
