import _ from "lodash";
import milsymbol from "milsymbol";
import { Point } from "ol/geom";
import { Fill, Icon, Style, Text as TextStyle } from "ol/style";
import * as React from "react";
import { Airbase } from "../../lib/data/airbases";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import { toMilSymbolString } from "../../lib/symbols";
import { DCSGroupData } from "../../lib/types";
import MapLayer from "./MapLayer";

type Props = {
  airbase: Airbase;
  groups: DCSGroupData[];
  theater: Theater;
};

const color = "#000";

const styles = () => (feature) => {
  return [
    new Style({
      text: new TextStyle({
        overflow: true,
        font: 'bold 14px "Roboto", "Arial Unicode MS", "sans-serif"',
        placement: "point",
        fill: new Fill({ color }),
        text: feature.get("elementNum"),
        offsetY: 24,
        backgroundFill: new Fill({ color: "#ffffffbd" }),
      }),
    }),
    new Style({
      image: new Icon({
        opacity: 1,
        src:
          "data:image/svg+xml;utf8," +
          new milsymbol.Symbol(
            toMilSymbolString(false, feature.get("category")),
            {
              size: 16,
            },
            { infoSize: 48 }
          ).asSVG(),
        scale: 1,
      }),
      text: new TextStyle({
        overflow: true,
        font: 'bold 14px "Roboto", "Arial Unicode MS", "sans-serif"',
        placement: "point",
        fill: new Fill({ color }),
        text: feature.get("group"),
        offsetY: -24,
        backgroundFill: new Fill({ color: "#ffffffbd" }),
      }),
    }),
  ];
};

export default function ParkingLayer({ groups, theater, airbase }: Props) {
  const features = [];

  _.each(groups, (group) => {
    _.each(group.units, (unit) => {
      const { lat, long } = convertDCSToLatLong(theater, {
        x: unit.x,
        y: unit.y,
      });

      const geometry = new Point([long, lat]);

      const numbersRegex = /\d+/g;

      const [num] = unit.callsign.match(numbersRegex);
      const elementNum = _.get(num?.split(""), [1]);

      const f = {
        geometry,
        name: unit.name,
        callsign: unit.callsign,
        type: unit.type,
        group: group.name,
        elementNum,
        category: group.category,
      };

      features.push(f);
    });
  });

  return (
    <MapLayer
      style={styles()}
      features={features}
      name={`${airbase.Name}/Parking`}
    />
  );
}
