import * as React from "react";
import styled from "styled-components";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { DCSPlayerGroup } from "../../lib/models/DCSPlayerGroup";
import { LaserConfig } from "../../lib/models/Plan";
import DataTable from "../DataTable";

type Props = {
  className?: string;
  group: GroupWithPlan;
};

function LaserConfigTable({ className, group }: Props) {
  const g = new DCSPlayerGroup(group);

  const m = g.dcsModule();

  const laser = g.laserCodes();

  if (!m) {
    return null;
  }

  const cols = [
    {
      label: "Flight Member",
      value: (l, c, r) => `${group.name}-${r + 1}`,
    },
  ] as any[];

  for (const field in m.laserConfigFields) {
    const f = m.laserConfigFields[field];

    cols.push({
      label: field,
      value: (l: keyof LaserConfig) => l[f.fieldName],
    });
  }

  return (
    <DataTable className={className} columns={cols} rows={laser}></DataTable>
  );
}

export default styled(LaserConfigTable).attrs({
  className: LaserConfigTable.name,
})``;
