import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { docs } from "../db_util";
import { Asset, AssetDoc, AssetType } from "../models/Asset";
import { JobSettings, JobSettingsData, JobType } from "../models/Job";
import { Fetcher } from "./Fetcher";
import { JobFileDisplayNameLookup, JobFileNameLookup } from "./JobRunner";

export interface AssetManager {
  upload(userID: string, asset: Asset, file?: File): Promise<AssetDoc>;
  list(manifestID: string): Promise<Asset[]>;
  url(manifestUserID: string, asset: Asset): Promise<string>;
  remove(asset: Asset): Promise<any>;
  getByName(manifestID: string, name: string): Promise<Asset>;
  updateGeneratedAssets(
    userID: string,
    manifestID: string,
    desiredJobs: JobType[],
    groups: GroupWithPlan[],
    jobSettings: JobSettingsData
  ): Promise<void>;
}

const re = /(?:\.([^.]+))?$/;
// https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
function getExtention(filename: string) {
  const match = re.exec(filename);

  return match && match[1];
}

export const AssetsCollection = "Assets";

class manager implements AssetManager {
  db: Firestore;
  fetch: Fetcher;

  constructor(f: Fetcher) {
    this.fetch = f;
  }

  private async fileUpload(asset: Asset, file: File) {
    const ext = getExtention(file?.name);

    const contentType = ext ? `image/${ext}` : "image";

    const { uploadURL } = await this.fetch.req<{ uploadURL: string }>(
      "/.netlify/functions/asset_upload_url",
      {
        method: "POST",
        body: JSON.stringify({
          manifestID: asset.manifestID,
          filename: file?.name,
          contentType,
          asset,
        }),
      }
    );

    await this.fetch.req(uploadURL, {
      method: "PUT",
      // @ts-ignore
      body: file,
    });
  }

  async upload(userID: string, asset: Asset, file?: File): Promise<AssetDoc> {
    if (asset.type !== AssetType.GeneratedKneeboard) {
      // We don't upload files for generated assets.
      // We render them on-request to make sure they have the current data.
      await this.fileUpload(asset, file);
    }
    const t = new Date().getTime();

    let d = {
      ...asset,
      created_by_uid: userID,
      created_at_timestamp: t,
      updated_at_timestamp: t,
    };

    const assetID = asset.id;

    let docRef = { id: assetID };
    if (assetID) {
      await setDoc(doc(this.db, AssetsCollection, assetID), d);
    } else {
      docRef = await addDoc(collection(this.db, AssetsCollection), d);
    }

    return { ...d, id: docRef.id } as AssetDoc;
  }

  async list(manifestID: string): Promise<Asset[]> {
    const q = query(
      collection(this.db, AssetsCollection),
      where("manifestID", "==", manifestID),
      orderBy("name", "asc")
    );

    const snaps = await getDocs(q);

    return docs<Asset>(snaps);
  }

  async getByName(manifestID: string, name: string): Promise<Asset> {
    const q = query(
      collection(this.db, AssetsCollection),
      where("manifestID", "==", manifestID),
      where("name", "==", name),
      orderBy("name", "asc")
    );

    const snaps = await getDocs(q);

    if (snaps.docs.length > 1) {
      throw new Error(`Found multiple assets with the name ${name}`);
    }

    const doc = _.get(snaps, ["docs", 0]);

    return doc?.data() as Asset;
  }

  async url(manifestUserID: string, asset: Asset): Promise<string> {
    const res = await this.fetch.req<{
      status?: number;
      statusText?: string;
      url: string;
    }>("/.netlify/functions/asset_url", {
      method: "POST",
      body: JSON.stringify({ asset, manifestUserID }),
    });

    if (res.status >= 400) {
      throw new Error(res.statusText);
    }

    return res.url;
  }

  async remove(asset: Asset): Promise<any> {
    try {
      await deleteDoc(doc(this.db, AssetsCollection, asset.id));
    } catch (e) {
      console.trace();
      console.error("Error removing asset", e);
    }
  }

  async updateGeneratedAssets(
    userID: string,
    manifestID: string,
    desiredJobs: JobType[] = [],
    groups: GroupWithPlan[],
    settings: JobSettingsData
  ): Promise<void> {
    const assets = await this.list(manifestID);
    const autoGend = _.filter(assets, (a) => {
      if (a.type === AssetType.GeneratedKneeboard) {
        if (
          a.jobType !== JobType.GroupCommsCard &&
          a.jobType !== JobType.GroupLineupCard &&
          a.jobType !== JobType.InjectRouteDetailCards
        ) {
          return true;
        }
      }

      return !_.includes(desiredJobs, a.jobType);
    });

    // Special remove logic for group kneeboards
    if (!_.includes(desiredJobs, JobType.GroupLineupCard)) {
      const lineupAssets = _.filter(assets, {
        jobType: JobType.GroupLineupCard,
      });

      for (const lineup of lineupAssets) {
        await this.remove(lineup);
      }
    }

    if (!_.includes(desiredJobs, JobType.GroupCommsCard)) {
      const commsCards = _.filter(assets, {
        jobType: JobType.GroupCommsCard,
      });

      for (const card of commsCards) {
        await this.remove(card);
      }
    }

    // Remove deselected auto-gen jobs
    for (const a of autoGend) {
      // Avoiding removing an asset that has already been removed above.
      if (!_.includes(desiredJobs, a.jobType) && !_.includes(autoGend, a)) {
        await this.remove(a);
      }
    }

    for (const j of desiredJobs) {
      let jobSettings: JobSettings = _.get(settings, j) || null;
      const existing = _.filter(assets, { jobType: j });

      if (existing.length > 0) {
        // Update with new settings
        for (const e of existing) {
          const d = JSON.parse(e.jobData || "{}");

          const g = _.find(groups, { name: d.groupName });

          if (
            !g &&
            (e.jobType === JobType.GroupLineupCard ||
              e.jobType === JobType.GroupCommsCard)
          ) {
            // Group was deleted, remove asset
            await this.remove(e);

            continue;
          }

          // Special case where we want to store presets on the jobData field.
          // This also copies the presets from the RadioConfig job to the kneeboard job.
          if (e.jobType === JobType.RadioPresetConfigKneeboard) {
            jobSettings = _.get(settings, JobType.RadioPresetConfig);
            await this.upload(userID, {
              ...e,
              jobData: JSON.stringify(jobSettings as string),
            });
            continue;
          }

          await this.upload(userID, { ...e, jobSettings });
        }

        continue;
      }
      const name = JobFileNameLookup[j];
      if (!name) {
        continue;
      }
      const displayName = JobFileDisplayNameLookup[j];

      // Special logic for group-specific kneeboards.
      // We need one asset per group.

      if (
        j === JobType.GroupLineupCard ||
        j === JobType.GroupCommsCard ||
        j === JobType.InjectRouteDetailCards
      ) {
        for (const group of groups) {
          await this.upload(userID, {
            name: name(group.name),
            type: AssetType.GeneratedKneeboard,
            manifestID,
            jobType: j,
            jobData: JSON.stringify({ groupName: group.name }),
            displayName: displayName(group.name),
            jobSettings,
          });
        }

        continue;
      }

      if (j === JobType.RadioPresetConfigKneeboard) {
        const s = settings[JobType.RadioPresetConfig];

        const jobData = JSON.stringify(s);

        await this.upload(userID, {
          name: name(),
          type: AssetType.GeneratedKneeboard,
          manifestID,
          jobType: j,
          jobData,
          displayName: displayName(),
          jobSettings: null,
        });

        continue;
      }

      const newAsset: Asset = {
        name: name(),
        type: AssetType.GeneratedKneeboard,
        manifestID,
        jobType: j,
        displayName: displayName(),
        jobSettings,
      };

      await this.upload(userID, newAsset);
    }
  }
}

export function NewAssetManager(fetch: Fetcher, db: Firestore): AssetManager {
  const m = new manager(fetch);
  m.db = db;

  return m;
}
