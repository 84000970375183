import { DateTime } from "luxon";
import * as React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  time: number;
  relative?: boolean;
  format?: Intl.DateTimeFormatOptions;
};

function Timestamp({
  className,
  time,
  relative = true,
  format = DateTime.DATETIME_SHORT,
}: Props) {
  const t = DateTime.fromJSDate(new Date(time));
  return (
    <span
      title={t.toLocaleString(DateTime.DATETIME_SHORT)}
      className={className}
    >
      {relative ? t.toRelative() : t.toLocaleString(format)}
    </span>
  );
}

export default styled(Timestamp)``;
