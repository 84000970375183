import { Box, Grid } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { FeatureFlag } from "../../contexts/FeatureFlagsContext";
import { useCreateCheckoutSession } from "../../hooks/services";
import { PriceID } from "../../lib/services/SubscriptionManager";
import FeatureGate from "../FeatureGate";
import Flex from "../Flex";
import Icon, { IconType } from "../Icon";
import PriceCard from "./PriceCard";

type Props = {
  trialUnavailable?: boolean;
  className?: string;
};

function SubscriptionCTA({ className, trialUnavailable }: Props) {
  const paymentURL = useCreateCheckoutSession();

  const handleClick = async (p: PriceID) => {
    const u = await paymentURL(p);

    window.location.href = u.url;
  };

  return (
    <div className={className}>
      <Flex center wide>
        <Flex align column>
          <h3>Subscribe to Frag Orders Premium</h3>
          <Box marginY={2}>
            Your subscription will unlock these premium features and help
            support the ongoing development of Frag Orders:
          </Box>
          <Box marginY={2}>
            <div>
              <Icon
                className="Icon"
                style={{ color: "green" }}
                icon={IconType.Check}
              />
              Flight planning for mission participants
            </div>
            <div>
              <Icon
                className="Icon"
                style={{ color: "green" }}
                icon={IconType.Check}
              />
              Auto-generated kneeboards injected directly into your .miz file
            </div>
            <div>
              <Icon
                className="Icon"
                style={{ color: "green" }}
                icon={IconType.Check}
              />
              Radio preset and laser code configuration
            </div>
            <div>
              <Icon
                className="Icon"
                style={{ color: "green" }}
                icon={IconType.Check}
              />
              Countrol measure drawing for mission participants
            </div>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <PriceCard
                priceID={PriceID.Monthly}
                amount="$5 per month"
                priceTitle="Frag Orders Premium - Monthly"
                trialUnavailable={trialUnavailable}
                onClick={handleClick}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PriceCard
                priceID={PriceID.Yearly}
                amount="$50 per year"
                priceTitle="Frag Orders Premium - Yearly"
                trialUnavailable={trialUnavailable}
                onClick={handleClick}
              />
            </Grid>
            <FeatureGate flag={FeatureFlag.VolumeLicense}>
              <Grid item xs={12} sm={12}>
                <PriceCard
                  priceID={PriceID.Volume}
                  priceTitle="Frag Orders Premium - Volume License"
                  amount="$20 per month for 5 users"
                  discountText="Save 20% per user"
                  trialUnavailable={true}
                  onClick={handleClick}
                />
              </Grid>
            </FeatureGate>
          </Grid>
        </Flex>
      </Flex>
    </div>
  );
}

export default styled(SubscriptionCTA).attrs({
  className: SubscriptionCTA.name,
})`
  .Icon {
    margin-right: 4px;
  }
`;
