import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import milsymbol from "milsymbol";
import * as React from "react";
import styled from "styled-components";
import { SubCategory, SymbolSet, toNATOSymbol } from "../../lib/symbols";
import Flex from "../Flex";

interface Props {
  className?: string;
  icon?: string;
  ident?: string;
  free?: string;
  hostile?: boolean;
  ring?: number;
  onChange: (key: string, value: any) => void;
}

function PointDetailsForm({
  className,
  hostile,
  ident,
  free,
  icon,
  ring,
  onChange,
}: Props) {
  const symbol = React.useCallback(
    (sub: SubCategory, set: SymbolSet = SymbolSet.Ground) =>
      new milsymbol.Symbol(toNATOSymbol(hostile, set, sub), {
        size: 20,
      }).asSVG(),
    [hostile]
  );

  React.useEffect(() => {
    const str = `${icon}`.split("");
    str[1] = hostile ? "H" : "F";
    onChange("icon", str.join(""));
  }, [hostile]);

  React.useEffect(() => {
    if (icon !== toNATOSymbol(true, SymbolSet.Ground, SubCategory.AirDefense)) {
      onChange("ring", 0);
    }
  }, [icon]);

  return (
    <div className={className}>
      <Box mt={2}>
        <Flex align>
          <FormControl>
            <TextField
              onChange={(ev) => onChange("ident", ev.target.value)}
              size="small"
              label="IDENT"
              value={ident}
            />
          </FormControl>
          <Box ml={1}>
            <FormControl>
              <TextField
                onChange={(ev) => onChange("free", ev.target.value)}
                label="FREE"
                size="small"
                value={free}
              />
            </FormControl>
          </Box>
        </Flex>
      </Box>
      <Box mt={2}>
        <Flex align>
          <FormControl fullWidth>
            <InputLabel>Icon</InputLabel>
            <Select
              style={{ height: 52 }}
              value={icon}
              onChange={(ev) => {
                onChange("icon", ev.target.value);
              }}
              size="small"
              label="Icon"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem
                style={{ display: "flex" }}
                value={toNATOSymbol(
                  hostile,
                  SymbolSet.Ground,
                  SubCategory.Armor
                )}
              >
                <ListItemIcon>
                  <img
                    src={"data:image/svg+xml;utf8," + symbol(SubCategory.Armor)}
                  />
                </ListItemIcon>
                <ListItemText>Armor</ListItemText>
              </MenuItem>
              <MenuItem
                value={toNATOSymbol(
                  hostile,
                  SymbolSet.Ground,
                  SubCategory.AirDefense
                )}
              >
                <ListItemIcon>
                  <img
                    src={
                      "data:image/svg+xml;utf8," +
                      symbol(SubCategory.AirDefense)
                    }
                  />
                </ListItemIcon>
                <ListItemText>Air Defense</ListItemText>
              </MenuItem>
              <MenuItem
                value={toNATOSymbol(
                  hostile,
                  SymbolSet.Ground,
                  SubCategory.Artillery
                )}
              >
                <ListItemIcon>
                  <img
                    src={
                      "data:image/svg+xml;utf8," + symbol(SubCategory.Artillery)
                    }
                  />
                </ListItemIcon>
                <ListItemText>Artillery</ListItemText>
              </MenuItem>
              <MenuItem
                value={toNATOSymbol(
                  hostile,
                  SymbolSet.Ground,
                  SubCategory.Infantry
                )}
              >
                <ListItemIcon>
                  <img
                    src={
                      "data:image/svg+xml;utf8," + symbol(SubCategory.Infantry)
                    }
                  />
                </ListItemIcon>
                <ListItemText>Infantry</ListItemText>
              </MenuItem>
              <MenuItem
                value={toNATOSymbol(
                  hostile,
                  SymbolSet.Air,
                  SubCategory.Helicopter
                )}
              >
                <ListItemIcon>
                  <img
                    src={
                      "data:image/svg+xml;utf8," +
                      symbol(SubCategory.Helicopter, SymbolSet.Air)
                    }
                  />
                </ListItemIcon>
                <ListItemText>Helicopter</ListItemText>
              </MenuItem>
              <MenuItem
                value={toNATOSymbol(
                  hostile,
                  SymbolSet.Air,
                  SubCategory.Fighter
                )}
              >
                <ListItemIcon>
                  <img
                    src={
                      "data:image/svg+xml;utf8," +
                      symbol(SubCategory.Fighter, SymbolSet.Air)
                    }
                  />
                </ListItemIcon>
                <ListItemText>Fighter</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
        </Flex>
      </Box>
      <Box mt={2}>
        <Flex align wide between>
          {icon && (
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    value={hostile}
                    onChange={() => {
                      onChange("hostile", !hostile);
                    }}
                  />
                }
                label="Hostile"
              />
            </FormControl>
          )}

          {icon ===
            toNATOSymbol(true, SymbolSet.Ground, SubCategory.AirDefense) && (
            <FormControl style={{ width: 136 }}>
              <TextField
                label="Ring"
                type="number"
                size="small"
                value={ring}
                onChange={(ev) => onChange("ring", ev.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">NM</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          )}
        </Flex>
      </Box>
    </div>
  );
}

export default styled(PointDetailsForm).attrs({
  className: PointDetailsForm.name,
})`
  .MuiInputBase-inputSizeSmall {
    display: flex !important;
  }

  .MuiListItemIcon-root {
    min-width: unset;
    margin-right: 8px;
  }
`;
