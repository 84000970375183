import _ from "lodash";
import { AircraftDetails } from "../../lib/models/ExternalDataRecord";
import { TaskingState } from "../../lib/models/PublishManifest";
import { ValidationError } from "../EditableTable";

export function rowsFromTasking(tasking: TaskingState): AircraftDetails[] {
  const rows = [];
  _.each(tasking.plannedGroups, (group) => {
    _.each(group.units, (unit, i) => {
      rows.push({
        groupName: group.name,
        playerName: "",
        flightNumber: i + 1,
        type: unit.type,
        iffMode1: 11,
        iffMode3: 11,
        tailNumber: unit.tailNumber,
        laserCode: _.padStart(_.compact(unit.laserCode).join(""), 4, "1"),
        stn: unit.aircraftProps.STN_L16 || unit.aircraftProps.OwnshipCallSign,
        voiceCallsignLabel: unit.aircraftProps.VoiceCallsignLabel,
        VoiceCallsignNumber: unit.aircraftProps.VoiceCallsignNumber,
      });
    });
  });

  return rows;
}

export const validateLength = (length: number) => (value: string) => {
  if (value.length !== length) {
    return `Field must be ${length} characters`;
  }
};

export const validateLaserCode = (value: string) => {
  const str = value.toString();
  if (validateLength(4)(str)) {
    return "Field must be 4 characters";
  }

  // Check that the last digit is between 1 and 8
  const last = str.charAt(value.length - 1);

  if (last < "1" || last > "8") {
    return "Last digit must be between 1 and 8";
  }
};

export const validateUniqLaserCodes = (
  rows: AircraftDetails[]
): ValidationError[] => {
  const laserCodes = _.compact(_.map(rows, "laserCode"));

  if (laserCodes.length !== _.uniq(laserCodes).length) {
    // Find the values that are duplicated
    const duplicateGroups = _.chain(laserCodes)
      .countBy()
      .pickBy((v) => v > 1)
      .keys()
      .value();

    return [
      {
        severity: "warning",
        message: `Duplicate laser codes found: ${duplicateGroups.join(", ")}`,
      },
    ];
  }

  return [];
};

export const validateIFF = (rows: AircraftDetails[]): ValidationError[] => {
  const iffs = _.map(rows, (r) => `${r.iffMode1}${r.iffMode3}`);

  if (iffs.length !== _.uniq(iffs).length) {
    const duplicateGroups = _.chain(iffs)
      .countBy()
      .pickBy((v) => v > 1)
      .keys()
      .value();
    return [
      {
        severity: "warning",
        message: `Duplicate IFF Mode 3 codes found: ${duplicateGroups.join(
          ", "
        )}`,
      },
    ];
  }

  return [];
};
