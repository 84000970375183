import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { Airbase as AirbaseData } from "../../lib/data/airbases";
import { formatFreq } from "../../lib/radios";
import { FOWindow, useWindowManager } from "../../lib/services/WindowManager";
import Flex from "../Flex";
import KeyValueTable from "../KeyValueTable";

type Props = {
  className?: string;
  airbase: AirbaseData;
};

function Airbase({ className, airbase }: Props) {
  const { addWindow } = useWindowManager();
  const pairs = [
    { key: "UHF", value: formatFreq(airbase.UHF) },
    {
      key: "VHF",
      value: formatFreq(airbase.VHF),
    },
    { key: "TACAN", value: airbase.TACAN },
  ];

  if (airbase.ILS) {
    pairs.push({ key: "ILS", value: airbase.ILS.join("\n") });
  }

  return (
    <div className={className}>
      <KeyValueTable columns={3} pairs={pairs} />
      {!airbase.UnitId && (
        <Flex wide center>
          <Button
            variant="outlined"
            onClick={() =>
              addWindow({
                id: `${airbase.Name} - Parking Diagram`,
                type: FOWindow.AirportDiagram,
                props: {
                  name: airbase.Name,
                },
              })
            }
          >
            Parking Diagram
          </Button>
        </Flex>
      )}
    </div>
  );
}

export default styled(Airbase).attrs({ className: Airbase.name })`
  td {
    padding: 0;
    border-bottom: 0px;
  }

  tr:nth-child(2) td:nth-child(1) {
    max-width: 20px;
  }
`;
