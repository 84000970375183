import JSZip from "jszip";
import { MizJobFunc } from "../models/Job";
import { AssetManager } from "../services/AssetManager";

export default function injectAssets(
  currentUserID: string,
  manifestID: string,
  am: AssetManager
): MizJobFunc {
  return async (zip: JSZip) => {
    const list = await am.list(manifestID);

    for (const asset of list) {
      if (asset.jobType) {
        // If the asset is generated by a job,
        // it will be injected by other jobs.
        continue;
      }

      const url = await am.url(currentUserID, asset);
      const file = await fetch(url).then((res) => res.blob());

      zip = zip.file(`KNEEBOARD/IMAGES/${asset.name}`, file);
    }

    return zip;
  };
}
