import { APIUser } from "discord-api-types/v10";
import _ from "lodash";
import { PlanItemType } from "../../models/PlanItem";
import { NavTargetPoint } from "../../types";
import PlanManagerV2 from "./PlanManagerV2";

export default class PlanNavPointManager extends PlanManagerV2 {
  async listNavPoints(
    manifestID: string,
    group: string
  ): Promise<NavTargetPoint[]> {
    const items = await this.list(manifestID);
    const navPoints = _.filter(items, { group, type: PlanItemType.NavPoint });
    const result = _.map(
      navPoints,
      (w) => JSON.parse(w.payload) as NavTargetPoint
    );
    return result;
  }

  async upsertNavPoint(
    manifestID: string,
    user: APIUser,
    group: string,
    navPoint: NavTargetPoint
  ) {
    validateNavPoint(navPoint);
    try {
      const item = await this.get(
        manifestID,
        PlanItemType.NavPoint,
        group,
        navPoint.index.toString()
      );
      return this.update({
        ...item,
        payload: JSON.stringify(navPoint),
      });
    } catch (e) {
      return this.add(user, {
        group,
        manifestID,
        type: PlanItemType.NavPoint,
        uniqID: navPoint.index.toString(),
        payload: JSON.stringify(navPoint),
        createdByUserName: user.username,
      });
    }
  }

  async deleteNavPoint(
    manifestID: string,
    group: string,
    navPointIndex: number
  ) {
    const existing = await this.get(
      manifestID,
      PlanItemType.NavPoint,
      group,
      navPointIndex.toString()
    );

    if (!existing) {
      return;
    }

    return this.remove(existing);
  }
}

function validateNavPoint(navPoint: NavTargetPoint) {
  if (!navPoint.textComment) {
    throw new Error("NavPoint must have textComment");
  }

  if (!navPoint.index) {
    throw new Error("NavPoint must have index");
  }

  if (!navPoint.x) {
    throw new Error("NavPoint must have x");
  }

  if (!navPoint.y) {
    throw new Error("NavPoint must have y");
  }
}
