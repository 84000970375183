import _ from "lodash";
import { MizLaserFormatter, Module, modules } from "../data/modules";
import { get } from "../mission";
import {
  AircraftDetails,
  ExternalDataRecord,
  GroupDetails,
  RecordType,
  recordData,
} from "../models/ExternalDataRecord";
import { LuaJobFunc } from "../models/Job";
import { LuaTable, UnitCategory } from "../types";

export const applyExternalData = (
  records: ExternalDataRecord[]
): LuaJobFunc => (mission: LuaTable) => {
  for (const record of records) {
    const data = recordData(record);

    const countries = get(mission, ["coalition", record.coalition, "country"]);

    for (const country of countries.numValues) {
      if (!country) {
        continue;
      }

      const categories = [UnitCategory.Plane, UnitCategory.Helicopter];

      for (const cat of categories) {
        const category = country.strValues[cat];

        if (!category) {
          continue;
        }

        for (const group of category.strValues.group.numValues) {
          if (!group) {
            continue;
          }

          if (record.type === RecordType.GroupAdminDetails) {
            const d = data as GroupDetails[];

            for (const g of d) {
              const match = g.groupName === group.strValues.name;

              if (match) {
                // Do nothing for now!
                continue;
              }
            }
          }

          if (record.type === RecordType.AircraftAdminDetails) {
            const d = data as AircraftDetails[];

            for (const a of d) {
              const match = a.groupName === group.strValues.name;

              if (match) {
                for (
                  let index = 0;
                  index < group.strValues.units.numValues.length;
                  index++
                ) {
                  const unit: LuaTable = group.strValues.units.numValues[index];
                  if (!unit) {
                    continue;
                  }

                  //   @ts-ignore
                  const type = unit.strValues.type as string;
                  const mod = modules[type] as Module;

                  if (a.flightNumber === index) {
                    // We have a match. Mutate values on the unit.
                    if (a.tailNumber) {
                      // @ts-ignore
                      unit.strValues.onboard_num = a.tailNumber.toString();
                    }

                    if (a.stn) {
                      // @ts-ignore
                      unit.strValues.AddPropAircraft.strValues.STN_L16 = a.stn;
                    }

                    if (a.voiceCallsignLabel) {
                      // @ts-ignore
                      unit.strValues.AddPropAircraft.strValues.VoiceCallsignLabel =
                        a.voiceCallsignLabel;
                    }

                    if (a.voiceCallsignNumber) {
                      // @ts-ignore
                      unit.strValues.AddPropAircraft.strValues.VoiceCallsignNumber =
                        a.voiceCallsignNumber;
                    }

                    if (_.keys(mod?.laserConfigFields).length > 0) {
                      if (mod?.mizLaserFormatter) {
                        const format = mod.mizLaserFormatter as MizLaserFormatter;

                        const entry = {};

                        const slicedCode = a.laserCode.toString().slice(1, 4);

                        if (mod.laserConfigFields) {
                          for (const k in mod.laserConfigFields) {
                            const field = mod.laserConfigFields[k];
                            if (field.fieldName) {
                              entry[field.fieldName] = slicedCode;
                            }
                          }
                        }

                        const props = unit.strValues.AddPropAircraft.strValues;
                        format(props, entry);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
