import _ from "lodash";
import * as React from "react";
import { useNotification } from "../../contexts/NotifcationContext";
import { useGroupsWithPlans } from "../../contexts/PlanManagerContext";
import {
  useNavPointManager,
  useWaypointManager,
} from "../../contexts/PlanManagerV2";
import { TaskingMapContext } from "../../contexts/TaskingMapContext";
import { useUser } from "../../contexts/UserContext";
import { PlanStatus } from "../../lib/models/Plan";
import { NavPointController } from "../../lib/services/NavPointController";
import { NewPlanningController } from "../../lib/services/PlanningController";
import { useMap } from "./MapCanvas";

export default function PlanningLayer() {
  const map = useMap();
  const { currentUser } = useUser();
  const { state, controller, dispatch, navPointController } = React.useContext(
    TaskingMapContext
  );
  const { success, error } = useNotification();

  const waypointManager = useWaypointManager();
  const navPointManager = useNavPointManager();

  // If the plan has been invalidated, we don't want to show it to most users.
  // We only want to sho invalidated plans to the plan submitter so they can resubmit.
  const planOpts: any = state.planningMode
    ? { user: currentUser }
    : { status: PlanStatus.Submitted };

  const { data: groups } = useGroupsWithPlans(
    state.manifest.fragOrderID,
    state.tasking.plannedGroups,
    planOpts
  );

  React.useEffect(() => {
    if (!map) {
      return;
    }

    if (!state.planningMode) {
      controller.current?.toggleModify(false);
      navPointController.current?.toggleModify(false);
      return;
    } else {
      controller.current?.toggleModify(true);
      navPointController.current?.toggleModify(true);
    }

    const userPlannable = _.filter(groups, (g) =>
      _.includes(state.userPlannableGroups, g.name)
    );

    if (!controller.current || groups.length === 0) {
      controller.current = NewPlanningController(
        map,
        dispatch,
        state.colorMap,
        state.manifest,
        waypointManager,
        currentUser,
        () => success("Plan saved"),
        (e: string) => error(e)
      );
      controller.current.init(state.tasking.theater, userPlannable);
    }

    if (!navPointController.current) {
      navPointController.current = new NavPointController(
        map,
        dispatch,
        state.colorMap,
        state.manifest,
        navPointManager,
        currentUser,
        state.tasking.theater,
        () => success("Plan saved"),
        (e: any) => error(e)
      );

      navPointController.current.init(userPlannable);
    }
  }, [map, groups, state.planningMode]);

  React.useEffect(() => {
    if (!state.waypointDropMode && controller.current?.inAddMode) {
      controller.current.toggleAddMode(false);
    }
  }, [state.waypointDropMode]);

  return null;
}
