import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { DateTime } from "luxon";
import { GeoJSON } from "ol/format";
import { Circle, Point } from "ol/geom";
import { docs } from "../db_util";
import { ControlMeasure, ControlMeasureDoc } from "../models/ControlMeasure";

export interface ControlMeasureReader {
  list(manifestID: string): Promise<ControlMeasure[]>;
}

export interface ControlMeasureWriter {
  save(userID: string, cm: ControlMeasure): Promise<void>;
  delete(userID: string, cm: ControlMeasure): Promise<void>;
  update(userID: string, cm: ControlMeasure): Promise<void>;
}

const ControlMeasureCollection = "ControlMeasures";

export interface ControlMeasureManager
  extends ControlMeasureReader,
    ControlMeasureWriter {}

export class DefaultControlMeasureManager implements ControlMeasureManager {
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  async list(manifestID: string): Promise<ControlMeasure[]> {
    const ref = collection(this.db, ControlMeasureCollection);
    const q = query(
      ref,
      where("manifestID", "==", manifestID),
      orderBy("name")
    );

    const querySnapshot = await getDocs(q);

    const d = await docs<ControlMeasure>(querySnapshot);

    return _.map(d, convertGeom);
  }

  async save(userID: string, cm: ControlMeasure): Promise<void> {
    const g = new GeoJSON();

    const t = DateTime.now().toSeconds();

    let geometry: string;

    if (cm.geometry.getType() === "Circle") {
      const circle = cm.geometry as Circle;

      geometry = convertCircleToJSON(circle);
    } else {
      geometry = g.writeGeometry(cm.geometry);
    }

    const next = {
      ...cm,
      geometry,
      updated_at_timestamp: t,
      created_at_timestamp: t,
      created_by_uid: userID,
    };

    await addDoc(collection(this.db, ControlMeasureCollection), next);
  }

  async delete(userID: string, cm: ControlMeasureDoc): Promise<void> {
    await deleteDoc(doc(this.db, ControlMeasureCollection, cm.id));
  }

  async update(userID: string, cm: ControlMeasure): Promise<void> {
    const t = DateTime.now().toSeconds();

    const g = new GeoJSON();
    let geometry: string;

    if (cm.geometry.getType() === "Circle") {
      const circle = cm.geometry as Circle;

      geometry = convertCircleToJSON(circle);
    } else {
      geometry = g.writeGeometry(cm.geometry);
    }

    let next = {
      ...cm,
      geometry,
      updated_at_timestamp: t,
      updated_by_uid: userID,
    } as any;

    next = _.omit(next, ["originalGeometry"]);

    const ref = doc(this.db, ControlMeasureCollection, cm.id);

    await setDoc(ref, next);
  }
}

function convertCircleToJSON(circle: Circle): string {
  const g = new GeoJSON();
  const r = circle.getRadius();

  const point = new Point(circle.getCenter());
  point.set("radius", r);
  point.set("type", "Circle");

  const geom = g.writeGeometry(point);

  // Convert to JSON, set some extra properties,
  // and then write back to the geometry field.
  const j = JSON.parse(geom);
  j.radius = r;
  j.type = "Circle";
  return JSON.stringify(j);
}

export function convertGeom(cm: ControlMeasure): ControlMeasure {
  // @ts-ignore
  const f = JSON.parse(cm.geometry as string);

  if (f.type === "Circle") {
    // GeoJSON doesn't support circles, so we have to do this.
    const c = new Circle(f.coordinates, f.radius);

    return {
      ...cm,
      geometry: c,
    };
  }

  const g = new GeoJSON();

  return { ...cm, geometry: g.readGeometry(f) };
}
