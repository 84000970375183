import CircleIcon from "@mui/icons-material/Circle";
import PentagonIcon from "@mui/icons-material/Pentagon";
import SquareIcon from "@mui/icons-material/Square";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Tooltip } from "@mui/material";
import { Draw, Modify } from "ol/interaction";
import { createBox } from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";
import * as React from "react";
import styled from "styled-components";
import { useGetCurrentManifestID } from "../../hooks/frag_orders";
import { eventIsRightClick } from "../../lib/layers/interactions/helpers";
import {
  ControlMeasure,
  ControlMeasureStatus,
} from "../../lib/models/ControlMeasure";
import Flex from "../Flex";
import StateButton from "../FragOrderNavigation/StateButton";
import { useMap } from "../MapCanvas/MapCanvas";

import PlaceIcon from "@mui/icons-material/Place";
type Props = {
  className?: string;
  onAddControlMeasure: (cm: ControlMeasure) => void;
};

enum ControlMeasureTool {
  None = "None",
  Line = "LineString",
  Polygon = "Polygon",
  Circle = "Circle",
  Box = "Box",
  Point = "Point",
}

// Ensures that each control measure has a unique name
function makeID(length = 5) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function generateControlMeasureName() {
  const name = `Control Measure-${makeID()}`;

  return name;
}

function ControlMeasureInput({ className, onAddControlMeasure }: Props) {
  const map = useMap();
  const manifestID = useGetCurrentManifestID();
  const interaction = React.useRef<Draw | null>(null);
  const sourceRef = React.useRef<VectorSource | null>(null);
  const [selectedTool, setSelectedTool] = React.useState<ControlMeasureTool>(
    ControlMeasureTool.None
  );

  React.useEffect(() => {
    if (!map) {
      return;
    }

    if (selectedTool === ControlMeasureTool.None) {
      map.removeInteraction(interaction.current);
      return;
    }

    const source = new VectorSource();

    let draw;

    if (selectedTool === ControlMeasureTool.Box) {
      // We want the resize behavior to be more like the circle tool
      // https://openlayers.org/en/latest/examples/draw-shapes.html
      draw = new Draw({
        type: "Circle",
        source,
        geometryFunction: createBox(),
        condition: (e) => !eventIsRightClick(e) || !e.dragging,
      });
    } else {
      draw = new Draw({
        type: selectedTool as any,
        source,
        condition: (e) => !eventIsRightClick(e) || !e.dragging,
      });
    }

    draw.on("drawend", (e) => {
      const feature = e.feature;

      const cm = {
        manifestID,
        name: generateControlMeasureName(),
        geometry: feature.getGeometry(),
        status: ControlMeasureStatus.Draft,
        color: "#000000",
        description: "",
      } as ControlMeasure;

      onAddControlMeasure(cm);
    });

    map.addInteraction(draw);

    const modify = new Modify({ source });
    map.addInteraction(modify);

    // @ts-ignore
    map.on("pointerup", (e) => {
      if (eventIsRightClick(e)) {
        map.removeInteraction(draw);
        map.removeInteraction(modify);
        setSelectedTool(ControlMeasureTool.None);
        return;
      }
    });

    interaction.current = draw;
    sourceRef.current = source;
  }, [selectedTool]);

  const handleSelectTool = (tool: ControlMeasureTool) => {
    if (tool === selectedTool) {
      setSelectedTool(ControlMeasureTool.None);
      return;
    }
    setSelectedTool(tool);
  };

  return (
    <div className={className}>
      <Flex align>
        <Tooltip title="Draw Line">
          <StateButton
            active={selectedTool === ControlMeasureTool.Line}
            onClick={() => handleSelectTool(ControlMeasureTool.Line)}
          >
            <TimelineIcon />
          </StateButton>
        </Tooltip>
        <Tooltip title="Draw Polygon">
          <StateButton
            active={selectedTool === ControlMeasureTool.Polygon}
            onClick={() => handleSelectTool(ControlMeasureTool.Polygon)}
          >
            <PentagonIcon />
          </StateButton>
        </Tooltip>
        <Tooltip title="Draw Circle">
          <StateButton
            active={selectedTool === ControlMeasureTool.Circle}
            onClick={() => handleSelectTool(ControlMeasureTool.Circle)}
          >
            <CircleIcon />
          </StateButton>
        </Tooltip>
        <Tooltip title="Draw Rectangle">
          <StateButton
            active={selectedTool === ControlMeasureTool.Box}
            onClick={() => handleSelectTool(ControlMeasureTool.Box)}
          >
            <SquareIcon />
          </StateButton>
        </Tooltip>
        <Tooltip title="Draw Point">
          <StateButton
            active={selectedTool === ControlMeasureTool.Point}
            onClick={() => handleSelectTool(ControlMeasureTool.Point)}
          >
            <PlaceIcon />
          </StateButton>
        </Tooltip>
      </Flex>
    </div>
  );
}

export default styled(ControlMeasureInput).attrs({
  className: ControlMeasureInput.name,
})`
  ${StateButton} {
    box-shadow: none;
    border: 1px solid #818080;
  }
`;
