import { FormHelperText, InputProps } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import Input from "../Input";
import Label from "../Label";

type Props = {
  name: string;
  className?: string;
  label?: string;
  component?: any;
  valuePropName?: string;
  formatter?: (v: string) => string;
  step?: any;
  defaultValue?: string;
  validator?: (v: string) => string | null;
} & InputProps;

const debounced = _.debounce((fn, v) => fn(v), 250);

function FastInput({
  className,
  component,
  label,
  formatter,
  name,
  defaultValue,
  validator,
  ...props
}: Props) {
  const [value, setValue] = React.useState({
    text: defaultValue,
    invalid: null,
  });
  const Component = component || Input;

  React.useEffect(() => {
    if (defaultValue !== value.text) {
      setValue({ ...value, text: defaultValue });
    }
  }, [defaultValue]);

  React.useEffect(() => {
    debounced((v) => {
      let nextValid = value.invalid;
      if (validator) {
        nextValid = validator(v);
      }
      setValue({ ...value, invalid: nextValid });
    }, value.text);
  }, [value.text]);

  return (
    <div className={className}>
      <Label name={name} required={props.required}>
        {label}
      </Label>
      <Component
        error={!!value.invalid}
        key={name}
        id={`${name}-input`}
        variant="outlined"
        {...props}
        onChange={(val) => {
          setValue({ text: val, invalid: value.invalid });
        }}
        onBlur={() => {
          if (formatter) {
            setValue({ ...value, text: formatter(value.text) });
          }
        }}
        value={value.text}
        inputProps={{ name, ...props.inputProps }}
      />
      {value.invalid && <FormHelperText error>{value.invalid}</FormHelperText>}
    </div>
  );
}

export default styled(FastInput).attrs({ className: FastInput.name })``;
