import _ from "lodash";
import { Feature } from "ol";
import { LineString, Point } from "ol/geom";
import { Layer, Vector as VectorLayer } from "ol/layer";
import "ol/ol.css";
import VectorSource from "ol/source/Vector";
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text,
  Text as TextStyle,
} from "ol/style";
import * as React from "react";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import { bearing, getWaypointDistance, rotation } from "../../lib/measurements";
import { DCSWaypoint, FeatureClass } from "../../lib/types";
import { useMap } from "./MapCanvas";

export interface RouteData {
  name: string;
  waypoints: DCSWaypoint[];
}

type Props = {
  group: RouteData;
  theater: Theater;
  color?: string;
  filterTag?: string;
  dotted?: boolean;
  disableWaypointLabels?: boolean;
  disableDistanceLabels?: boolean;
  declutter?: boolean;
};

const shapes = (shape, radius, color) => {
  const s = {
    circle: new CircleStyle({
      radius,
      fill: new Fill({
        color,
      }),
    }),
    diamond: new RegularShape({
      fill: new Fill({ color }),
      stroke: new Stroke({ color, width: 2 }),
      points: 4,
      radius: 10,
      rotation: Math.PI / 2,
      angle: 0,
    }),
  };

  return s[shape];
};

export const waypointStyle = (
  colorOverride?,
  radius: number = 5,
  shape: "circle" | "diamond" = "circle",
  disableWaypointLabels: boolean = false
) => (feature: Feature<Point>) => {
  let txt = feature.get("number").toString();

  const name = feature.get("name");
  const nameIsNumber = !_.isNaN(parseInt(name));
  if (name && !nameIsNumber) {
    txt = name;
  }

  if (disableWaypointLabels) {
    txt = "";
  }

  if (feature.get("number") === 0) {
    return;
  }

  const color = colorOverride;

  var styles = [
    new Style({
      image: shapes(shape, radius, color),
      text: new TextStyle({
        overflow: true,
        font: '18px "Roboto", "Arial Unicode MS", "sans-serif"',
        placement: "point",
        fill: new Fill({ color }),
        stroke: new Stroke({ color: "black", width: 1 }),
        text: txt,
        offsetY: 20,
      }),
    }),
  ];

  return styles;
};

const uniqID = (group: g) => `${group.name}_waypoints`;

interface g {
  name: string;
}

export const toPoint = (group: g, theater: Theater) => (
  wp: DCSWaypoint,
  i: number,
  allwp: DCSWaypoint[]
) => {
  const { long, lat } = convertDCSToLatLong(theater, wp);
  const geometry = new Point([long, lat]);
  // Show waypoint names if they are meaninful.
  // If they are just numbers, hide them.
  const name = wp.name || wp.number.toString();

  const { leg, total } = getWaypointDistance(theater, wp, allwp);

  return {
    geometry,
    group: group.name,
    featureClass: FeatureClass.Waypoint,
    name,
    number: wp.number,
    type: wp.type,
    title: `${group.name} Waypoint ${wp.number}`,
    uniqID: uniqID(group),
    altitude: wp.alt,
    altitudeType: wp.alt_type,
    eta: wp.ETA,
    speed: wp.speed,
    waypoint: wp,
    legDistance: leg,
    totalDistance: total,
  };
};

// const debounced = _.debounce(
//   (theater: Theater) => (ev, layer: VectorLayer<VectorSource>) => {
//     const source = layer.getSource() as VectorSource;
//     const features = source.getFeatures() as Feature<Point>[];

//     for (const feature of features) {
//       configurePointFeature(feature.get("group"), theater)(
//         feature,
//         feature.get("number"),
//         features
//       );
//     }
//   },
//   1000
// );

function PointLayer({
  group,
  theater,
  color,
  filterTag,
  disableWaypointLabels,
  declutter,
}: Props) {
  const map = useMap();
  const layerRef = React.useRef<VectorLayer<VectorSource>>();
  const sourceRef = React.useRef<VectorSource>();

  const features = _.map(group.waypoints, toPoint(group, theater));

  React.useEffect(() => {
    if (!map) {
      return;
    }

    const source = new VectorSource({
      features: features.map((f) => new Feature(f)),
    });

    const layer = new VectorLayer({
      source: source,
      style: waypointStyle(color, 5, "circle", disableWaypointLabels),
      renderBuffer: 100000,
      declutter,
    });

    layerRef.current = layer;
    sourceRef.current = source;

    layer.set("filterTag", filterTag || group.name);
    layer.set("uniqID", uniqID(group));
    layer.set("name", group.name);

    map.addLayer(layer);
  }, [map]);

  React.useEffect(() => {
    if (!layerRef.current || !sourceRef.current) {
      return;
    }

    const next = waypointStyle(
      color,
      5,
      "circle",
      disableWaypointLabels
    ) as any;

    layerRef.current?.setStyle(next);
  }, [disableWaypointLabels]);

  return null;
}

export const pathStyle = (
  theater: Theater,
  source: VectorSource,
  colorOverride?: string,
  dotted?: boolean,
  disableDistance?: boolean
) => (feature) => {
  const styles = [];
  const coords = feature.getGeometry().getCoordinates();
  const num = feature.get("number");
  const features = source.getFeatures();
  const prev = _.find(features, (f) => f.get("number") === num - 1);
  const color = colorOverride;

  if (prev) {
    const prevCoords = (prev.getGeometry() as Point).getCoordinates();

    const dist = feature.get("legDistance");

    const rot = rotation(
      prevCoords,
      coords,
      bearing(prevCoords[1], prevCoords[0], coords[1], coords[0])
    );

    const ls = new LineString([prevCoords, coords]);
    styles.push(
      new Style({
        geometry: ls,
        stroke: new Stroke({
          color,
          width: 2,
          lineDash: dotted ? [10, 10] : null,
        }),
      })
    );

    if (!disableDistance && dist) {
      styles.push(
        new Style({
          geometry: new Point(ls.getCoordinateAt(0.5)),
          text: new Text({
            rotation: -rot,
            text: `${dist?.toFixed(1).toString()}NM`,
            font: "14px sans-serif",
            fill: new Fill({ color }),
            stroke: new Stroke({ color: "black", width: 1 }),
            offsetY: -8,
            // backgroundFill: new Fill({ color: "#ffffffc2" }),
          }),
        })
      );
    }

    // Couldn't get the triangle to point the right way...
    // styles.push(
    //   new Style({
    //     geometry: new Point(ls.getCoordinateAt(0.25)),
    //     image: new RegularShape({
    //       fill: new Fill({ color }),
    //       stroke: new Stroke({ color, width: 2 }),
    //       points: 3,
    //       radius: 20,
    //       rotateWithView: true,
    //       angle: 0,
    //       rotation: -rot,
    //       // displacement: [0, 200],
    //     }),
    //   })
    // );
  }

  return styles;
};

function PathLayer({
  theater,
  group,
  color,
  filterTag,
  dotted,
  disableDistanceLabels,
}: Props) {
  const map = useMap();
  const layerRef = React.useRef<VectorLayer<VectorSource>>();
  const sourceRef = React.useRef<VectorSource>();

  React.useEffect(() => {
    if (!map) {
      return;
    }

    // This path layer needs to be kept in sync with the point source.
    const pointLayer = _.find(
      map.getLayers().getArray(),
      (l) => l.get("uniqID") === uniqID(group)
    );

    const source = (pointLayer as Layer).getSource() as VectorSource;

    const layer = new VectorLayer({
      source: source,
      style: pathStyle(theater, source, color, dotted, disableDistanceLabels),
      renderBuffer: 100000,
      declutter: true,
    });

    layerRef.current = layer;
    sourceRef.current = source;

    layer.set("filterTag", filterTag || group.name);
    map.addLayer(layer);
  }, [map, dotted]);

  React.useEffect(() => {
    if (!layerRef.current || !sourceRef.current) {
      return;
    }

    const next = pathStyle(
      theater,
      sourceRef.current,
      color,
      dotted,
      disableDistanceLabels
    );

    layerRef.current?.setStyle(next);
  }, [disableDistanceLabels]);

  return null;
}

export default function RouteLayer(props: Props) {
  return (
    <>
      <PointLayer {...props} />
      <PathLayer {...props} />
    </>
  );
}
