import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import {
  useGetColorMap,
  useTaskingState,
} from "../../contexts/TaskingMapContext";
import { modules } from "../../lib/data/modules";
import { DCSUnit } from "../../lib/types";

type Props = {
  className?: string;
};

function AircraftList({ className }: Props) {
  const {
    state: {
      tasking: { plannedGroups },
    },
  } = useTaskingState();
  const colors = useGetColorMap();

  const rows: (DCSUnit & {
    groupName: string;
    index: number;
    color: string;
  })[] = [];

  for (const group of plannedGroups) {
    for (let index = 0; index < group.units.length; index++) {
      const unit = group.units[index];
      rows.push({
        ...unit,
        groupName: group.name,
        index,
        color: colors[group.name],
      });
    }
  }

  const elrows = _.map(rows, (u) => {
    const rowSpan = _.find(plannedGroups, { name: u.groupName }).units.length;

    return (
      <TableRow key={u.name}>
        {u.index === 0 && (
          <>
            <TableCell style={{ color: u.color }} rowSpan={rowSpan}>
              {u.groupName}
            </TableCell>
            <TableCell style={{ color: u.color }} rowSpan={rowSpan}>
              {_.get(modules, [u.type, "label"]) || u.type}
            </TableCell>
          </>
        )}

        <TableCell style={{ color: u.color }}>
          {u.groupName}-{u.index + 1}
        </TableCell>
        <TableCell style={{ color: u.color }}>
          {u?.aircraftProps?.STN_L16 || u?.aircraftProps?.OwnshipCallSign}
        </TableCell>
        <TableCell style={{ color: u.color }}>{u.laserCode}</TableCell>
      </TableRow>
    );
  });

  return (
    <div className={className}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Group</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Flight Member</TableCell>
            <TableCell>Datalink STN</TableCell>
            <TableCell>Laser Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{elrows}</TableBody>
      </Table>
    </div>
  );
}

export default styled(AircraftList).attrs({ className: AircraftList.name })``;
