import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Box, Button, Modal, ModalProps, Typography } from "@mui/material";
import { parse } from "csv-parse";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import { getLabelForModule } from "../../lib/data/modules";
import { SignupRule } from "../../lib/models/SignupRecord";
import DataTable from "../DataTable";
import FileDropzone from "../Dropzone";
import Flex from "../Flex";
import ModalContent from "../ModalContent";
import Section from "../Section";

type Props = {
  className?: string;
  modalProps: ModalProps;
  onSubmit: (rows: SignupRule[]) => Promise<void>;
};

function CSVImportDialog({ className, modalProps, onSubmit }: Props) {
  const [busy, setBusy] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const { error, success } = useNotification();

  const handleSubmit = () => {
    setBusy(true);

    onSubmit(rows)
      .then(() => {
        success("Imported roles from CSV");
      })
      .finally(() => {
        setBusy(false);
        modalProps.onClose({}, "escapeKeyDown");
      });
  };

  const handleFileDrop = async (files: File[]) => {
    const file = _.first(files);

    if (!file) {
      return;
    }

    const txt = await file.text();

    parse(txt, { columns: true }, (err, output) => {
      if (err) {
        error("Error parsing CSV: " + err.message);
        return;
      }

      setRows(output);
    });
  };

  return (
    <div className={className}>
      <Modal {...modalProps}>
        <ModalContent>
          <Section title="Import Roles from CSV">
            <Box my={2}>
              <Typography>
                Importing roles from CSV will create a new signup rule for each
                row in the CSV file.
              </Typography>
              <Box my={2}>
                {rows.length === 0 && (
                  <FileDropzone
                    text="Drag and Drop your .csv file here"
                    onFileDrop={handleFileDrop}
                  />
                )}
                {rows.length > 0 && (
                  <DataTable
                    columns={[
                      {
                        label: "Mission Role",
                        value: "missionRole",
                      },
                      {
                        label: "Role Type",
                        value: "signupRoleType",
                      },
                      {
                        label: "Allowed Discord Role",
                        value: "discordLabel",
                      },
                      {
                        value: (r: SignupRule) => getLabelForModule(r.module),
                        label: "Module",
                      },
                      {
                        value: "slotCount",
                        label: "Slot Count",
                      },
                    ]}
                    rows={rows}
                  />
                )}
              </Box>
            </Box>
            <Box marginTop={2}>
              <Flex wide between>
                <LoadingButton
                  loading={busy}
                  disabled={rows.length === 0}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Import
                </LoadingButton>
                <Button
                  onClick={() => modalProps.onClose({}, "escapeKeyDown")}
                  variant="outlined"
                >
                  Close
                </Button>
              </Flex>
            </Box>
          </Section>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default styled(CSVImportDialog).attrs({
  className: CSVImportDialog.name,
})``;
