import { FirebaseApp } from "firebase/app";
import { Auth } from "firebase/auth";
import { Firestore } from "firebase/firestore";
import * as React from "react";

type Props = {
  app: FirebaseApp;
  db: Firestore;
  auth: Auth;
  children?: any;
};

type FirebaseContextType = {
  app: FirebaseApp;
  db: Firestore;
  auth: Auth;
};

const FirebaseContext = React.createContext<FirebaseContextType>(null);

export function useFirebase() {
  return React.useContext(FirebaseContext);
}

export default function FirebaseProvider({ app, db, auth, children }: Props) {
  return (
    <FirebaseContext.Provider value={{ app, db, auth }}>
      {children}
    </FirebaseContext.Provider>
  );
}
