import _ from "lodash";
import "ol/ol.css";
import * as React from "react";
import { Theater } from "../../lib/map";
import {
  DCSGroupData,
  FeatureClass,
  TaskingMapLayerName,
} from "../../lib/types";
import { milIconLayerStyle } from "./GroupLayer";
import { toGroupFeature } from "./GroupWithRoute";
import MapLayer from "./MapLayer";
import RouteLayer from "./RouteLayer";

type Props = {
  groups: DCSGroupData[];
  theater: Theater;
  color?: string;
  disableWaypointLabels?: boolean;
};

// Match NATO symbol blue
const SUPPORT_ASSET_COLOR = "#54B7D9";

export default function SupportAssetLayer({
  groups,
  theater,
  color = SUPPORT_ASSET_COLOR,
  disableWaypointLabels = false,
}: Props) {
  const features = _.map(
    groups,
    toGroupFeature(theater, FeatureClass.SupportAsset)
  );
  return (
    <>
      {_.map(groups, (g) => (
        <RouteLayer
          key={g.name}
          group={g}
          theater={theater}
          filterTag={TaskingMapLayerName.SupportAssets}
          color={color}
          disableWaypointLabels={disableWaypointLabels}
          disableDistanceLabels
        />
      ))}

      <MapLayer
        features={features}
        style={milIconLayerStyle(24, true)}
        name={TaskingMapLayerName.SupportAssets}
        hideable
      />
    </>
  );
}
