import * as Sentry from "@sentry/browser";
import { APIUser } from "discord-api-types/v10";
import { Auth, signInWithCustomToken } from "firebase/auth";
import qs from "query-string";
import * as React from "react";
import { useHistory } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import { Subscription, SubscriptionDoc } from "../lib/models/Subscription";
import { AppRoutes } from "../lib/types";
import { useFirebase } from "./FirebaseContext";

type UserContextType = {
  currentUser: APIUser;
  subscription: SubscriptionDoc;
  login: (code: string) => Promise<void>;
  logout: () => Promise<any>;
  loading: boolean;
};

export const UserContext = React.createContext<UserContextType>(
  {} as UserContextType
);

export function useUser() {
  return React.useContext(UserContext);
}

export async function discordURL(
  scope = "identify guilds",
  permissions = null,
  intents = null
) {
  // Get this from the backend to ensure we always have the right one.
  // The FE env vars are set at build time, but the BE env is set at runtime.
  // We want the runtime env vars to take advantage of deploy-preview environment variables.
  let redirect_uri;
  try {
    const res = await fetch(
      "/.netlify/functions/discord_redirect_url"
    ).then((res) => res.json());
    redirect_uri = res.redirect_uri;
  } catch (e) {
    console.error(e);
    throw e;
  }

  const params = {
    client_id: process.env.DISCORD_APP_ID,
    response_type: "code",
    redirect_uri,
    scope,
    permissions,
    intents,
  };

  const query = qs.stringify(params, { skipNull: true });

  return `https://discord.com/api/oauth2/authorize?${query}`;
}

export async function discordBotInviteURL() {
  const params = {
    client_id: process.env.DISCORD_APP_ID,
    scope: "bot",
  };
  const query = qs.stringify(params, { skipNull: true });

  return await `https://discord.com/api/oauth2/authorize?${query}`;
}

type DiscordUserResponse = {
  user: APIUser;
  subscription: SubscriptionDoc;
};

async function fetchUser(): Promise<DiscordUserResponse> {
  const res = await fetch("/.netlify/functions/discord_user");

  if (res.status >= 400) {
    throw { code: res.status, message: res.statusText };
  }

  return await res.json();
}

async function authFirebase(auth: Auth, user: APIUser) {
  const tokenRes = await fetch("/.netlify/functions/firebase_token", {
    method: "POST",
    body: JSON.stringify({ uid: user?.id }),
  }).then(async (res) => {
    if (res.status >= 400) {
      const msg = await res.json();
      throw new Error(res.statusText + JSON.stringify(msg, null, 2));
    }

    return res.json();
  });

  return signInWithCustomToken(auth, tokenRes.token);
}

export async function authenticate(auth: Auth, { code }) {
  await fetch(`/.netlify/functions/discord_oauth?code=${code}`).then((res) =>
    res.json()
  );

  const res = await fetchUser();

  await authFirebase(auth, res.user);

  return res;
}

function doLogout(history) {
  return fetch("/.netlify/functions/discord_logout").then(() =>
    history.push(AppRoutes.Home)
  );
}

enum ActionType {
  fetchUser,
  fetchUserSuccess,
  fetchUserError,
}

const initialState = () => ({
  loading: true,
  user: null,
  subscription: null,
  error: null,
});

type Action =
  | { type: ActionType.fetchUser }
  | {
      type: ActionType.fetchUserSuccess;
      user: APIUser;
      subscription: Subscription;
    }
  | { type: ActionType.fetchUserError; error: Error };

const reducer: React.Reducer<ReturnType<typeof initialState>, Action> = (
  state,
  action: Action
) => {
  switch (action.type) {
    case ActionType.fetchUser:
      return {
        ...state,
        loading: true,
      };

    case ActionType.fetchUserSuccess:
      return {
        ...state,
        loading: false,
        user: action.user,
        subscription: action.subscription,
      };

    case ActionType.fetchUserError:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      break;
  }
};

export function UserProvider({ children }) {
  const { auth } = useFirebase();
  const history = useHistory();
  const [state, dispatch] = React.useReducer<typeof reducer>(
    reducer,
    initialState()
  );

  const doAuthError = (history, dispatch, e) => {
    dispatch({ type: ActionType.fetchUserError, error: e });
  };

  const login = async (code) => {
    try {
      const { user, subscription } = await authenticate(auth, { code });
      dispatch({ type: ActionType.fetchUserSuccess, user, subscription });
    } catch (e) {
      doAuthError(history, dispatch, e);
      return;
    }
  };

  const logout = async () => {
    await doLogout(history);
    await auth.signOut();
    Sentry.setUser(null);
    dispatch({
      type: ActionType.fetchUserSuccess,
      user: null,
      subscription: null,
    });
  };

  const getCurrentUser = async () => {
    let res: DiscordUserResponse;
    try {
      res = await fetchUser();
      await authFirebase(auth, res.user);
    } catch (e) {
      doAuthError(history, dispatch, e);
      return;
    }

    Sentry.setUser({ discordID: res.user.id, username: res.user.username });
    dispatch({ type: ActionType.fetchUserSuccess, ...res });
  };

  React.useEffect(() => {
    getCurrentUser();
  }, []);

  if (state.loading) {
    return <LoadingPage />;
  }

  const value = {
    currentUser: state.user,
    subscription: state.subscription,
    loading: state.loading,
    login,
    logout,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useIsSubscribed() {
  const { subscription } = useUser();

  return subscription?.enabled;
}
