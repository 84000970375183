import { Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import FormInput, { FormInputProps } from "./FormInput";

type Props = FormInputProps;

function FormCheckbox({ label, ...props }: Props) {
  return (
    <FormInput
      {...props}
      //   Covert to bool to satisfy mui Checkbox props
      component={(p) => (
        <FormControlLabel
          control={
            <Checkbox
              {...p}
              checked={p.checked === "true" || Boolean(p.checked)}
            />
          }
          label={label}
        ></FormControlLabel>
      )}
      valuePropName="checked"
    />
  );
}

export default styled(FormCheckbox).attrs({ className: FormCheckbox.name })``;
