import { Box, Modal } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { Asset } from "../../lib/models/Asset";
import { PublishManifestDoc } from "../../lib/models/PublishManifest";
import ModalContent from "../ModalContent";
import AssetViewer from "./AssetViewer";

type Props = {
  className?: string;
  asset: Asset;
  open: boolean;
  onClose: () => void;
  manifest?: PublishManifestDoc;
};

function AssetViewerModal({
  className,
  asset,
  open,
  onClose,
  manifest,
}: Props) {
  return (
    <Modal onClose={onClose} open={open}>
      <ModalContent style={{ minHeight: 957 / 2, minWidth: 717 / 2 }}>
        <Box className={className}>
          <AssetViewer asset={asset} manifest={manifest} />
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default styled(AssetViewerModal).attrs({
  className: AssetViewerModal.name,
})``;
