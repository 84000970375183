import styled from "styled-components";

type Props = {
  className?: string;

  alignStart?: boolean;
  grow?: number;
};

const FlexChild = styled.div<Props>`
  ${({ alignStart }) => alignStart && "align-self: flex-start"};
  ${({ grow }) => grow && `flex-grow: ${grow}`};
`;

export default FlexChild;
