import { Geometry } from "ol/geom";
import { FirebaseMetadata } from "../types";

export enum ControlMeasureStatus {
  Draft = "draft",
  Published = "published",
}
export class ControlMeasure {
  id: string;
  manifestID: string;
  name: string;
  geometry: Geometry;
  status: ControlMeasureStatus;
  description: string;
  color: string;
  icon?: string;
  ident?: string;
  free?: string;
  hostile?: boolean;
  ring?: number;
}

export type ControlMeasureDoc = ControlMeasure & FirebaseMetadata;
