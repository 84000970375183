import _ from "lodash";
import * as React from "react";
import { useUser } from "./UserContext";

type Props = {
  children?: any;
};

const FeatureFlagsContext = React.createContext(null);

export enum FeatureFlag {
  CoalitionPublish = "publish:coalition",
  KneeboardGen = "jobs:kneeboards",
  Signups = "signups",
  ExternalData = "external_data",
  VolumeLicense = "volume_license",
}

const getFlags = () =>
  fetch("/.netlify/functions/feature_flags", {
    credentials: "same-origin",
  }).then((res) => res.json());

export function FeatureFlagsProvider({ children }: Props) {
  const { currentUser } = useUser();
  const [flags, setFlags] = React.useState([]);

  React.useEffect(() => {
    if (!currentUser) {
      return;
    }

    getFlags().then((flags) => setFlags(flags));
  }, [currentUser]);

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlags() {
  return _.get(React.useContext(FeatureFlagsContext), "flags");
}
