import _ from "lodash";
import { Collection, Map } from "ol";
import { Geometry } from "ol/geom";
import { Modify, Translate } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStlye, Fill, Stroke, Style, Text } from "ol/style";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMap } from "../components/MapCanvas/MapCanvas";
import { ControlMeasureContext } from "../contexts/ControlMeasureManagerContext";
import {
  useTaskingState,
  useToggleControlMeasureEdit,
} from "../contexts/TaskingMapContext";
import { useUser } from "../contexts/UserContext";
import {
  ControlMeasure,
  ControlMeasureDoc,
} from "../lib/models/ControlMeasure";
import { PublishManifestDoc } from "../lib/models/PublishManifest";
import {
  ControlMeasureReader,
  ControlMeasureWriter,
} from "../lib/services/ControlMeasureManager";
import { TaskingMapLayerName } from "../lib/types";
import { useGetCurrentManifestID } from "./frag_orders";

function useControlMeasureReader(): ControlMeasureReader {
  return useContext(ControlMeasureContext);
}

function useControlMeasureWriter(): ControlMeasureWriter {
  return useContext(ControlMeasureContext);
}

const queryKey = "control-measures";

export function useListControlMeasures(manifestID: string) {
  const reader = useControlMeasureReader();

  return useQuery<ControlMeasure[], Error>([queryKey], () =>
    reader.list(manifestID)
  );
}

export function useAdminListControlMeasures(manifests: PublishManifestDoc[]) {
  const reader = useControlMeasureReader();
  const ids = _.map(manifests, "id");

  return useQuery<ControlMeasure[], Error>(
    ["submitted-control-measures", ...ids],
    () => {
      if (!manifests) {
        return Promise.resolve([]);
      }

      const work = _.map(manifests, (m) => reader.list(m.id));

      return Promise.all(work).then((results) => {
        const list = [];

        for (let i = 0; i < results.length; i++) {
          const coalition = manifests[i].coalition;
          const items = results[i];

          for (let j = 0; j < items.length; j++) {
            const item = items[j];
            list.push({
              ...item,
              coalition,
            });
          }
        }

        return list;
      });
    }
  );
}

export function useSubmitControlMeasures() {
  const { currentUser } = useUser();
  const writer = useControlMeasureWriter();
  const qc = useQueryClient();
  // Going for optimistic updates here so that the layer updates immediately.
  // https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
  const mu = useMutation({
    mutationFn: (cm: ControlMeasure) => {
      return writer.save(currentUser.id, cm);
    },

    onMutate: async (cm: ControlMeasure) => {
      await qc.cancelQueries({ queryKey: [queryKey] });

      // Snapshot the previous value
      const previousCMs = qc.getQueryData([queryKey]);

      // Optimistically update to the new value

      qc.setQueryData([queryKey], (old: ControlMeasure[]) => [...old, cm]);

      // Return a context object with the snapshotted value
      return { previousCMs };
    },
    onSettled: () => {
      qc.invalidateQueries({ queryKey: [queryKey] });
    },
  });

  return mu;
}

export function useDeleteControlMeasure() {
  const { currentUser } = useUser();
  const writer = useControlMeasureWriter();
  const qc = useQueryClient();
  const manifestID = useGetCurrentManifestID();
  const { data: controlMeasures } = useListControlMeasures(manifestID);

  return (cm: ControlMeasure) => {
    const existing = _.find(controlMeasures, { name: cm.name });

    if (!currentUser) {
      return Promise.reject(new Error("Not logged in"));
    }

    if (!existing) {
      return Promise.resolve();
    }

    return writer.delete(currentUser.id, existing).then(() => {
      qc.invalidateQueries([queryKey]);
    });
  };
}

export function useUpdateControlMeasure() {
  const { currentUser } = useUser();
  const writer = useControlMeasureWriter();
  const qc = useQueryClient();

  return (cm: ControlMeasureDoc) => {
    return writer.update(currentUser.id, cm).then(() => {
      qc.invalidateQueries([queryKey]);
    });
  };
}

const modifyTipStyle = (text: string = "Drag to Modify") =>
  new Style({
    image: new CircleStlye({
      radius: 5,
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.7)",
      }),
      fill: new Fill({
        color: "rgba(0, 0, 0, 0.4)",
      }),
    }),
    text: new Text({
      text,
      font: "12px Roboto,sans-serif",
      fill: new Fill({
        color: "rgba(255, 255, 255, 1)",
      }),
      backgroundFill: new Fill({
        color: "rgba(0, 0, 0, 0.7)",
      }),
      padding: [2, 2, 2, 2],
      textAlign: "left",
      offsetX: 15,
    }),
  });

export function useHandleEditControlMeasure(
  onChange: (geom: Geometry) => void
) {
  const map = useMap();
  const modifyRef = useRef<Modify | null>();
  const translateRef = useRef<Translate | null>();
  const {
    state: { editingControlMeasure },
  } = useTaskingState();
  const toggle = useToggleControlMeasureEdit();

  useEffect(() => {
    if (!map) {
      return;
    }

    if (!editingControlMeasure) {
      if (modifyRef.current) {
        map.removeInteraction(modifyRef.current);
      }

      if (translateRef.current) {
        map.removeInteraction(translateRef.current);
      }
      return;
    }

    const layer = findLayer(map);

    if (!layer) {
      return;
    }

    const feature = findFeature(layer, editingControlMeasure);

    const modify = new Modify({
      features: new Collection([feature]),
      style: modifyTipStyle(),
    });

    // We can move shapes, but not lines.
    // The  translate interaction doesn't work on thin lines (can't grab it).
    if (feature.getGeometry().getType() !== "LineString") {
      const translate = new Translate({
        features: new Collection([feature]),
      });

      translate.on("translateend", (e) => {
        const feature = e.features.getArray()[0];
        const geom = feature.getGeometry();
        onChange(geom);
      });

      translateRef.current = translate;
      map.addInteraction(translate);
    }

    modify.on("modifyend", (e) => {
      const feature = e.features.getArray()[0];
      const geom = feature.getGeometry();

      onChange(geom);
    });

    modifyRef.current = modify;

    map.addInteraction(modify);

    return () => {
      map.removeInteraction(modifyRef.current);
      map.removeInteraction(translateRef.current);
    };
  }, [map, editingControlMeasure]);

  // Reset function
  return () => {
    toggle(null);

    if (translateRef.current) {
      map.removeInteraction(translateRef.current);
    }

    if (modifyRef.current) {
      map.removeInteraction(modifyRef.current);
    }

    const layer = findLayer(map);
    const prev = findFeature(layer, editingControlMeasure);
    prev?.setGeometry(prev.get("originalGeometry").clone());
  };
}

function findLayer(map: Map) {
  return map
    .getLayers()
    .getArray()
    .find(
      (l) => l.get("name") === TaskingMapLayerName.ControlMeasures
    ) as VectorLayer<VectorSource>;
}

function findFeature(layer: VectorLayer<VectorSource>, cm: ControlMeasure) {
  const source = layer.getSource();
  const features = source.getFeatures();

  return _.find(features, (f) => f.get("id") === cm?.id);
}

export function useIsControlMeasureOwner() {
  const { currentUser } = useUser();

  return useCallback(
    (cm: ControlMeasureDoc) => {
      if (!currentUser) {
        return false;
      }

      return cm.created_by_uid === currentUser.id;
    },
    [currentUser]
  );
}
