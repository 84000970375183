import _ from "lodash";
import { DateTime, Duration } from "luxon";
import { DCSCoords, LatLongDecimal, Theater, convertDCSToLatLong } from "./map";
import { TaskingState } from "./models/PublishManifest";
import { getTimeZoneOffset } from "./tasking";
import {
  DCSWaypoint,
  METERS_TO_NAUTICAL_MILES,
  MinimalWaypoint,
} from "./types";

function toLocale(d: DateTime) {
  return d.toLocaleString(DateTime.TIME_24_SIMPLE);
}

function militaryTime(tasking: TaskingState) {
  // @ts-ignore
  const d = DateTime.fromISO(tasking.time, { zone: "utc" });

  return [d, d.minus({ hour: getTimeZoneOffset(tasking.theater) })];
}

export function parseMizTime(time: string) {
  return DateTime.fromISO(time, { zone: "utc" });
}

export function zuluToLocalTime(d: DateTime, theater: Theater) {
  return d.minus({ hour: getTimeZoneOffset(theater) });
}

export function formatMilitaryTime(tasking: TaskingState) {
  const [local, zulu] = militaryTime(tasking);

  return [toLocale(local), toLocale(zulu)];
}

export function timeOnTarget(tasking: TaskingState, delta: number) {
  const [, zulu] = militaryTime(tasking);

  return zulu.plus({ seconds: delta });
}

// export function absETAFromTimeInput(
//   start: DateTime,
//   { hour, minute, second }: { hour: number; minute: number; second: number }
// ) {
//   const tot = DateTime.fromObject(
//     {
//       hour,
//       minute,
//       second,
//       day: start.day,
//       year: start.year,
//       month: start.month,
//     },
//     { zone: "utc" }
//   );

//   const { milliseconds } = tot.diff(start).toObject();

//   return Math.abs(milliseconds / 1000);
// }

export function totFromEta(zulu: DateTime, eta: number) {
  return zulu.plus({ hours: 0, minutes: 0, seconds: eta });
}

export function durationFromLast(prev: DCSWaypoint, next: DCSWaypoint) {
  const d = Duration.fromObject({ hours: 0, minutes: 0, seconds: next.ETA });

  return d.minus({ seconds: prev.ETA });
}

export function etaBySpeed(start: DateTime, knots: number, distance: number) {
  const hours = distance / knots;

  return start.plus({ hours });
}

// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function distance(theater: Theater, p1: DCSCoords, p2: DCSCoords) {
  const p1LL = convertDCSToLatLong(theater, p1);
  const p2LL = convertDCSToLatLong(theater, p2);

  return getDistanceFromLatLonInNautical(p1LL, p2LL);
}

export function getDistanceFromLatLonInNautical(
  p1: LatLongDecimal,
  p2: LatLongDecimal
) {
  const km = getDistanceFromLatLonInKm(p1.lat, p1.long, p2.lat, p2.long);

  const nauticalMiles = METERS_TO_NAUTICAL_MILES * (km * 1000);

  return nauticalMiles;
}

export function cumulativeDistance(
  theater: Theater,
  wp: MinimalWaypoint,
  allwp: DCSWaypoint[]
) {
  // Slice of all the waypoints up to and including the current
  const sl = _.filter(allwp, (w) => w.number <= wp.number);

  // Add them all up for "distance so far"
  const total = _.sum(
    _.map(sl, (w, i, all) => {
      const l = all[w.number - 1];
      return l ? distance(theater, l, w) : 0;
    })
  );

  return total;
}
