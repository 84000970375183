import { Alert, AlertTitle } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import DiscordLoginButton from "./components/DiscordLoginButton";
import Page from "./components/Page";
import PublicSignup from "./components/PublicSignup/PublicSignup";
import Section from "./components/Section";
import { useGetCurrentManifest } from "./contexts/PublishReaderContext";
import { ServicesProvider } from "./contexts/ServicesContext";
import { UserProvider, useUser } from "./contexts/UserContext";
import { NewFetcher } from "./lib/services/Fetcher";
import { NewSubscriptionManager } from "./lib/services/SubscriptionManager";

type Props = {
  className?: string;
};

const fetcher = NewFetcher(fetch);

const contexts = [
  [
    ServicesProvider,
    {
      subs: NewSubscriptionManager(fetcher),
    },
  ],
  [UserProvider, {}],
];

function wrapComponentWithProviders(providerPropsTuples, Component) {
  return providerPropsTuples.reduceRight(
    (WrappedComponent, [Provider, providerProps]) => {
      return ({ children, ...props }) => (
        <Provider {...providerProps}>
          <WrappedComponent {...props}>{children}</WrappedComponent>
        </Provider>
      );
    },
    Component
  );
}

function MissionSignupsPage({ className }: Props) {
  const { currentUser } = useUser();
  const { data: manifest, isLoading } = useGetCurrentManifest();

  if (isLoading) {
    return <Page loading />;
  }

  return (
    <div className={className}>
      <Page>
        <Section title={`${manifest?.title} - Mission Signups`}>
          {!currentUser && (
            <Alert severity="info">
              <AlertTitle>
                Please sign in to Discord to sign up for missions
              </AlertTitle>
              <DiscordLoginButton />
            </Alert>
          )}
          <PublicSignup />
        </Section>
      </Page>
    </div>
  );
}

const WrappedMissionSignupsPage = wrapComponentWithProviders(
  contexts,
  MissionSignupsPage
);

export default styled(WrappedMissionSignupsPage).attrs({
  className: MissionSignupsPage.name,
})`
  ${PublicSignup} {
    background-color: white !important;
  }
`;
